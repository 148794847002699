import { boqDrawingTypes } from "../action/boqDrawingAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case boqDrawingTypes.BOQ_DRAWING_LOADED:
      return action.payload;

    case boqDrawingTypes.BOQ_DRAWING_UNLOADED:
      return initialState;
    case boqDrawingTypes.ADD_BOQ_DRAWING:
      return { ...state };
    case boqDrawingTypes.EDIT_BOQ_DRAWING:
      return state;
    case boqDrawingTypes.DELETE_BOQ_DRAWING:
      return state;

    default:
      return state;
  }
};
