import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadStaticPage,
  unloadStaticPage,
} from "../../redux/action/staticPageAction";
import "react-quill/dist/quill.snow.css";

interface Props {
  match: {
    params: {
      id: any;
    };
  };
}

interface RootState {
  static_pages: {
    static_pages: {
      title: string;
      title_lang: string;
      content: string;
      content_lang: string;
      tile_id: number;
      app_id: number;
      updated_at: string;
    };
    error?: string;
  };
}

export default function StaticPageInfo(props: Props) {
  const [apiUrl] = useState(`/static_page/${props.match.params.id}`);
  const static_pages = useSelector((state: RootState) => state.static_pages);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadStaticPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadStaticPage());
    };
  }, [apiUrl, dispatch, props.match.params.id]);

  useEffect(() => {
    if (
      static_pages !== undefined &&
      static_pages &&
      static_pages.static_pages &&
      static_pages.static_pages.title
    ) {
      document.title = static_pages.static_pages.title + " | BCTAP";

      // Image Click Events:
      document.querySelectorAll("img").forEach((node) => {
        node.style.cursor = "zoom-in";
        node.addEventListener("click", (e) => {
          e.preventDefault();
          if (
            e.target &&
            // @ts-ignore
            e.target.getAttribute("src")
          ) {
            var newTab = window.open("","_blank");
            // @ts-ignore
            newTab.document.body.innerHTML = '<img src="'+e.target.getAttribute("src")+'">';
            // window.open(e.target.getAttribute("src"));
          }
        });
      });
    }
  }, [apiUrl, static_pages, props.match.params.id]);

  return static_pages === undefined ||
    !static_pages ||
    !static_pages.static_pages ? (
    <div className="loader-overlay" style={{ zIndex: 1 }}>
      {static_pages.error ? <p>NO CONTENT</p> : "LOADING..."}
    </div>
  ) : (
    <div
      style={{
        padding: "10px",
        animation: "fade 1s ease forwards",
      }}
    >
      <h3>{static_pages.static_pages.title + ' / ' + static_pages.static_pages.title_lang}</h3>
      <small>
        <i className="fa fa-history"></i> {static_pages.static_pages.updated_at}
      </small>
      <hr />
      <div className="quill">
        <div className="ql-container ql-snow">
          <div className="ql-editor" style={{cursor: "pointer"}}>
            <div
              dangerouslySetInnerHTML={{
                __html: static_pages.static_pages.content,
              }}
            ></div>
          </div>
        </div>
      </div>
      <hr />
      <div className="quill">
        <div className="ql-container ql-snow">
          <div className="ql-editor" style={{cursor: "pointer"}}>
            <div
              dangerouslySetInnerHTML={{
                __html: static_pages.static_pages.content_lang,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
