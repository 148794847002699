import { dashboardTypes } from "../action/dashboardaction";


export default (state ={}, action:any) => {
    switch (action.type) {
        case dashboardTypes.FETCH_MENU:
            
            return action.payload;
              
        case dashboardTypes.DASHBOARD_UNLOADED:
            return [];
        
        default:
            return state;
    }
};
