import { floorTypes } from "../action/floorAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case floorTypes.FLOOR_LOADED:
      return action.payload;

    case floorTypes.FLOOR_UNLOADED:
      return initialState;
    case floorTypes.ADD_FLOOR:
      return { ...state };
    case floorTypes.EDIT_FLOOR:
      return state;
    case floorTypes.DELETE_FLOOR:
      return state;

    default:
      return state;
  }
};
