import React, { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import TopMenu from "./TopMenu";
import TrainingStart from "../../views/StartTraining/start";

import "../../assets/css/index.css";
//import '../../assets/css/NavMenu.css'

import { Route, Redirect, useHistory } from "react-router-dom";
import { Image, Loader } from "semantic-ui-react";
import feedackImage from "../../assets/images/bot.gif";
import FeedbackModal from "../Modal/FeedbackModal";
import { apiService } from "../../services";

import * as Sentry from "@sentry/react";

interface Props {
  component: any;
  exact?: boolean;
  path: string;
  roles?: string[];
  module?: string;
}

export interface RootState {
  authentication: {
    user: {
      data: {
        first_name: string;
        last_name: string;
        email: string;
        id: number;
      };
      modules: string[];
    };
  };
}

export const DefaultLayout = ({ component: Component, ...rest }: Props) => {
  const currentUser = localStorage.getItem("user");
  const Userinfo = currentUser ? JSON.parse(currentUser) : null;

  // Create Custom Sentry Route component
  // const SentryRoute = Sentry.withSentryRouting(Route);

  // If Not Logged in only return the component
  if (!currentUser || !Userinfo || !Userinfo.access_token || !Userinfo.data) {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <div style={{ padding: "15px" }}>
            <Component {...matchProps} />
          </div>
        )}
      />
    );
  }

  // Else If Logged in render the component inside Dashboard layout.
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className="grid1">
          <div className="menu">
            <TopMenu dark />
          </div>

          <div className="main-content">
            <SideMenu dark>
              <Component {...matchProps} />
            </SideMenu>
          </div>
        </div>
      )}
    />
  );
};

export const PrivateRoute = ({
  component: Component,
  roles,
  module,
  ...rest
}: Props) => {
  const [dark, setDark] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [verified, setVerified] = useState(
    sessionStorage.getItem("verified") ? true : false
  );
  const history = useHistory();

  // Create Custom Sentry Route component
  // const SentryRoute = Sentry.withSentryRouting(Route);

  const verifyByPinging = (force = false): void => {
    // VERIFY USER BEFORE RENDERING
    if (!verified || force) {
      apiService.getAll(`/ping`).then(
        (data) => {
          setVerified(true);
          sessionStorage.setItem("verified", "true");
        },
        (error) => {
          // Logs the user out via API Service handleResponse (due to 401)
          history.push("/login");
        }
      );
    }
  };

  useEffect(() => {
    verifyByPinging();
  }, [rest.path]);

  useEffect(() => {
    function needsReVerification() {
      const item = sessionStorage.getItem("verified");

      if (!item) {
        setVerified(false);
        verifyByPinging(true);
      }
    }

    window.addEventListener("storage", needsReVerification);

    return () => {
      window.removeEventListener("storage", needsReVerification);
    };
  }, []);

  const themeSwitch = (e: any): void => {
    setDark(!dark);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = localStorage.getItem("user");
        const Userinfo = currentUser ? JSON.parse(currentUser) : null;

        if (
          !currentUser ||
          !Userinfo ||
          !Userinfo.access_token ||
          !Userinfo.data
        ) {
          // not logged in so redirect to login page with the return url
          localStorage.removeItem("user");

          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(Userinfo.role) === -1) {
          // role not authorised so redirect to home page

          return <Redirect to={{ pathname: "/" }} />;
        }
        if (module && Userinfo.modules.indexOf(module) === -1) {
          return <Redirect to={{ pathname: "/" }} />;
        }

        if (!verified) {
          return (
            <>
              <Loader size="large" style={{ zoom: "1.4" }} active />
              <div className="first-loader">
                Authorizing...
                <br />
                Loading...
              </div>
            </>
          );
        }

        return Component === TrainingStart ? (
          <Component {...props} />
        ) : (
          <div className="grid1">
            <div className="topmenu">
              <FeedbackModal
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
              <div
                className="fab"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <Image circular src={feedackImage} />
              </div>
              <TopMenu themeSwitch={themeSwitch} dark={dark} />
            </div>

            <div className="main-content">
              <SideMenu dark={dark}>
                <Component {...props} />
              </SideMenu>
            </div>
          </div>
        );
      }}
    />
  );
};
