import React, { useEffect, useState } from "react";

import {
  Segment,
  Grid,
  Card,
  Header,
  Icon,
  Image,
  Button,
  Modal,
  Label,
} from "semantic-ui-react";

import NumberCard from "../../components/layout/DashboardCard";
import defaultImg from '../../assets/images/image.png';
//import { Map, TileLayer } from "react-leaflet";
//import Leaflet from "leaflet";
import { useSelector, useDispatch } from "react-redux";
import { fetchMenu } from "../../redux/action/dashboardaction";
import { Link } from "react-router-dom";
// import { sideMenuAction } from "../../redux/action/sideMenuAction";

//Leaflet.Icon.Default.imagePath = "../../node_modules/leaflet";
// @ts-ignore {doesNotExist}
//delete Leaflet.Icon.Default.prototype._getIconUrl;
// Leaflet.Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
// });

export default function UserDashboard() {
  const dashboard = useSelector((state: any) => state.dashboard);
  // const { user } = useSelector((state: any) => state.authentication);
  // const { modules } = user;
  const { dashboard_info } = dashboard;

  const [openInfo, setOpenInfo]: any = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMenu());
    // eslint-disable-next-line
  }, []);

  // const ModuleHandler = (module: string) => {
  //   dispatch(sideMenuAction.ModuleChange(module));
  // };

  // const GetModuleCard = ({ data }: any) => {
  //   return data.map((item: string, index: number) => (
  //     <Card
  //       key={index}
  //       onClick={() => ModuleHandler(item)}
  //       style={{
  //         borderRadius: "5px",
  //         padding: "3px",
  //         width: "185px",
  //         backgroundColor: "#EAE0E0",
  //       }}
  //     >
  //       <Card.Content>
  //         <Card.Description
  //           style={{ textTransform: "capitalize", fontWeight: "bold" }}
  //           textAlign="center"
  //           as="p"
  //         >
  //           {item.replace("_", " ")}
  //         </Card.Description>
  //       </Card.Content>
  //     </Card>
  //   ));
  // };

  const GetAppCard = ({ data }: any) => {
    return data.map((item: any, index: number) => (
      <Grid celled="internally" stackable key={index}>
        <Grid.Row className={"m-3"}>
          <Grid.Column width={3} className={"mx-auto"}>
            <Grid divided="vertically" textAlign="center">
              <Grid.Row>
                <Image
                  src={defaultImg}
                  avatar
                />
              </Grid.Row>
              <Grid.Row>
                <Header as="h4">{item.app_name}</Header>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column width={13}>
            <Grid.Row>
              <Grid.Column width={10} className={"mx-auto"}>
                <Button
                  basic
                  size="large"
                  primary
                  content="Total Modules"
                  icon="clone"
                  label={{
                    as: "a",
                    basic: true,
                    color: "blue",
                    pointing: "left",
                    content: item.awareness_module_count,
                  }}
                />
                <Button
                  basic
                  size="large"
                  color="green"
                  content="Total Tiles"
                  icon="align justify"
                  label={{
                    as: "a",
                    basic: true,
                    color: "green",
                    pointing: "left",
                    content: item.TilesCount,
                  }}
                />
                <Button
                  basic
                  size="large"
                  color="red"
                  content="Total Downloads"
                  icon="download"
                  label={{
                    as: "a",
                    basic: true,
                    color: "red",
                    pointing: "left",
                    content: item.TotalDownloads,
                  }}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className={"mt-3"}>
              <Grid.Column width={10} className={"mx-auto"}>
                <Button color="facebook">
                  <Icon name="chart bar" /> Stats
                </Button>

                <Button color="twitter" onClick={() => setOpenInfo(item)}>
                  <Icon name="info" /> Info
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ));
  };

  const GetProjectCard = ({ data }: any) => {
    return data.map((item: any, index: number) => (
      <Card
        color="blue"
        className="p-3"
        style={{ width: "280px" }}
        as={Link}
        to={`/project/projectReport`}
      >
        <Grid columns={2}>
          <Grid.Row
            stretched
            verticalAlign="middle"
            relaxed="true"
            className="text-center"
          >
            <Grid.Column>
              <Icon
                name="briefcase"
                size="huge"
                color="blue"
                className="ml-5"
              />
            </Grid.Column>
            <Grid.Column>
              <Header as="h3" color="blue">
                {item.name}
              </Header>
              <Card.Content style={{ borderRadius: "3px" }}>
                <Label
                  as="a"
                  style={{
                    backgroundColor: "var(--color-blue)",
                    borderColor: "var(--color-blue)",
                    color: "white",
                  }}
                  size="small"
                >
                  <Icon name="building" />
                  {item.organization.name}
                </Label>
              </Card.Content>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    ));
  };

  const OpenModal = ({ item }: any) => {
    return (
      <Modal
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        onOpen={() => setOpenInfo(item)}
        size="tiny"
      >
        <Header icon="info" content={item.app_name} />
        <Modal.Content>
          <p>
            <small>
              <b>Unique ID:</b> {item.unique_app_id}
            </small>
          </p>
          <p>
            <b>Total Modules:</b> {item.awareness_module_count}
          </p>
          <p>
            <b>Total Tiles:</b> {item.TilesCount}
          </p>
          <p>
            <b>Total Downloads:</b> {item.TotalDownloads}
          </p>
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <Segment key={1}>
        <h4>User Dashboard</h4>
      </Segment>
      <Segment.Group key={2}>
        {/* <Segment color="blue">
          <Grid style={{ backgroundColor: "#c3e3fe" }}>
            <Grid.Column width={13} key={"gc_" + 1}>
              <Header as="h3">Modules</Header>
            </Grid.Column>
            <Grid.Column
              floated="right"
              width={3}
              textAlign="right"
              key={"gc_" + 2}
            >
              <Label as="a" color="blue" tag>
                {modules && modules.length} Total Modules
              </Label>
            </Grid.Column>
          </Grid>
        </Segment> */}

        {/* <Segment>
          <Card.Group>
            <Card
              key="main"
              onClick={() => ModuleHandler("main")}
              style={{
                borderRadius: "5px",
                padding: "3px",
                width: "185px",
                backgroundColor: "#EAE0E0",
              }}
            >
              <Card.Content>
                <Card.Description
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                  textAlign="center"
                  as="p"
                >
                  Main Dashboard
                </Card.Description>
              </Card.Content>
            </Card>

            {modules && <GetModuleCard data={modules} />}

          </Card.Group>
        </Segment> */}
      </Segment.Group>

      <Card.Group key={3}>
        <NumberCard
          cardHeader={"PROJECTS"}
          number={dashboard_info && dashboard_info.total_projects}
          iconName={"briefcase"}
          color={"green"}
          headerColor="bg-success"
        />

        <NumberCard
          cardHeader={"APP"}
          number={dashboard_info && dashboard_info.total_apps}
          iconName={"home"}
          color={"teal"}
          headerColor="bg-info"
        />
      </Card.Group>

      {/* {console.log(dashboard_info.apps)} */}

      {dashboard_info && dashboard_info.projects.length > 0 ? (
        <Segment.Group style={{ backgroundColor: "cadetblue" }}>
          <Segment style={{ backgroundColor: "aliceblue" }}>
            <Header as="h2" icon="flag checkered" content="Assigned Projects" />
          </Segment>
          <Segment.Group>
            <Segment key={4} className={"mt-3"}>
              {dashboard_info ? (
                <GetProjectCard data={dashboard_info.projects} />
              ) : (
                <p style={{ textAlign: "center", color: "white" }}>Loading..</p>
              )}
              <OpenModal item={openInfo} />
            </Segment>
          </Segment.Group>
        </Segment.Group>
      ) : (
        ""
      )}

      {dashboard_info && dashboard_info.apps.length > 0 ? (
        <Segment.Group style={{ backgroundColor: "#5071b2" }}>
          <Segment style={{ backgroundColor: "aliceblue" }}>
            <Header as="h2" icon="tasks" content="Assigned Apps" />
          </Segment>
          <Segment.Group>
            <Segment key={4} className={"mt-3"}>
              {dashboard_info ? (
                <GetAppCard data={dashboard_info.apps} />
              ) : (
                <p style={{ textAlign: "center", color: "white" }}>Loading..</p>
              )}
              <OpenModal item={openInfo} />
            </Segment>
          </Segment.Group>
        </Segment.Group>
      ) : (
        ""
      )}

      {/* <Segment key="5">
        <div style={{ height: "400px" }}>
          <Map center={[0, 0]} zoom={3} worldCopyJump={true} minZoom={2}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </Map>
        </div>
      </Segment> */}
    </React.Fragment>
  );
}
