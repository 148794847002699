import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Form,
  Button,
  Icon,
  Loader,
  Divider,
  Header,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editUser, addUser } from "../../redux/action/userAction";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { FormattedMessage } from "react-intl";
import { Module } from "../../helper/modules";

interface fdataType {
  newData: {
    id: number;
    first_name: string;
    last_name: string;
    password: string;
    confirm_password: string;
    email: string;

    role: number;
    org: string;
    other_org: string[];
    modules: number[];

    external_user_credentials: {
      id: number;
      user_id: number;
      provider: string;
      website_url: string;
      username: string;
      password: string;
      description: string;
    }[];

    formbuilder_role: number;
    awareness_role: number;
    training_role: number;
    library_role: number;
    planner_role: number;
    contact_trace_role: number;
  };
}
interface Props {
  type: string;
  data?: undefined | Data;
}

interface Data {
  id: number;
  first_name: string;
  last_name: string;

  email: string;

  role: number;
  other_org: string[];
  organization_id: string;
  modules: {
    id: number;
  }[];
  formbuilder_role: number;
  awareness_role: number;
  training_role: number;
  library_role: number;
  planner_role: number;
  contact_trace_role: number;

  external_user_credentials: {
    id: number;
    user_id: number;
    provider: string;
    website_url: string;
    username: string;
    password: string;
    description: string;
  }[];
}
export interface RootState {
  authentication: {
    user: {
      data: {
        first_name: string;
        last_name: string;
        email: string;
        id: number;
        organization_id: number;
      };
      role: string;
      organization: string;
    };
  };
}
type OptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

export default function UserForm(props: Props) {
  const [fdata, setData] = useState<fdataType>({
    newData: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      role: 0,
      org: "",
      other_org: [],
      modules: [],

      formbuilder_role: 0,
      awareness_role: 0,
      training_role: 0,
      library_role: 0,
      planner_role: 0,
      contact_trace_role: 0,

      external_user_credentials: [],
    },
  });

  const { user } = useSelector((state: RootState) => state.authentication);
  const [required, SetRequired] = useState(true);
  const [orgOptions, setOrgOptions] = useState<OptionType>([]);
  const [otherorgOptions, setOtherOrgOptions] = useState<OptionType>([]);
  const [moduleOptions, setModuleOptions] = useState<OptionType>([]);
  const [roleOptions, setRoleOptions] = useState<OptionType>([]);
  const [moduleroleOptions, setModuleRoleOptions] = useState([{}]);
  const [moduleIdsOptions, setModuleIdsOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiUrl, setApiUrl] = useState<string>("/users");

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    if (props.type === "Edit" && props.data !== undefined) {
      //  console.log(props.data)
      SetRequired(false);
      var ndata = props.data;
      // console.log({orgOptions})

      setData({
        newData: {
          id: ndata.id,

          first_name: ndata.first_name,
          last_name: ndata.last_name,
          email: ndata.email,
          role: ndata.role,
          password: "",
          confirm_password: "",
          org: ndata.organization_id,
          other_org: ndata.other_org !== null ? ndata.other_org : [],
          modules: ndata.modules.map((data) => {
            return data.id;
          }),

          formbuilder_role: ndata.formbuilder_role,
          awareness_role: ndata.awareness_role,
          training_role: ndata.training_role,
          library_role: ndata.library_role,
          planner_role: ndata.planner_role,
          contact_trace_role: ndata.contact_trace_role,

          external_user_credentials: ndata.external_user_credentials ?? [],
        },
      });

      //  console.log(ndata)
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    apiService.getAll(`/users/create`).then(
      (data) => {
        var final_org: OptionType = data.organizations.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );
        var final_modules: OptionType = data.modules.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );
        var final_roles: OptionType = data.roles.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );
        let final_module_roles: OptionType = [];
        Object.keys(data.modules_roles).map((key) => {
          final_module_roles[key] = data.modules_roles[key].map(
            (data: { id: number; name: string }) => {
              return {
                key: data.id,
                value: data.id,
                text: data.name,
              };
            }
          );
          return final_module_roles;
        });

        setOrgOptions(final_org);
        if (fdata.newData.org !== "") {
          let new_org = final_org.filter(function (val, index, arr) {
            return val.value !== fdata.newData.org;
          });
          console.log({ final_org });
          console.log(fdata.newData.org);
          console.log({ new_org });
          setOtherOrgOptions(new_org);
        } else {
          setOtherOrgOptions(final_org);
        }

        setModuleOptions(final_modules);
        setRoleOptions(final_roles);
        setModuleRoleOptions(final_module_roles);
        setModuleIdsOptions(data.modules_ids);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",

        role: 0,
        org: "",
        modules: [],
        other_org: [],
        formbuilder_role: 0,
        awareness_role: 0,
        training_role: 0,
        library_role: 0,
        planner_role: 0,
        contact_trace_role: 0,
        external_user_credentials: [],
      },
    });
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleTypeChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    var value = result.type === "checkbox" ? result.checked : result.value;
    var name = result.name;
    if (name === "org") {
      let new_org = orgOptions.filter(function (val, index, arr) {
        return val.value !== value;
      });

      setOtherOrgOptions(new_org);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleExternalUserChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any,
    index: number
  ) => {
    var value = result.type === "checkbox" ? result.checked : result.value;
    var name = result.name;

    if (name === "provider" && value) {
      value = value.toLowerCase();
    }

    var external_user_credentials = fdata.newData.external_user_credentials;
    external_user_credentials[index][name] = value;

    setData({
      newData: Object.assign({}, fdata.newData, {
        external_user_credentials: external_user_credentials,
      }),
    });
  };

  const addExternalUserCredential = () => {
    var external_user_credentials = fdata.newData.external_user_credentials;
    external_user_credentials.push({
      id: 0,
      user_id: 0,
      username: "",
      password: "",
      provider: "",
      description: "",
      website_url: "",
    });

    setData({
      newData: Object.assign({}, fdata.newData, {
        external_user_credentials: external_user_credentials,
      }),
    });
  };

  const deleteExternalUserCredential = (index: number) => {
    var external_user_credentials = fdata.newData.external_user_credentials;
    external_user_credentials.splice(index, 1);

    setData({
      newData: Object.assign({}, fdata.newData, {
        external_user_credentials: external_user_credentials,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) {
      dispatch(
        addUser(
          { ...fdata.newData },
          () => {
            clearForm();
            dispatch(alertActions.success("Data added successfully"));
            history.push(`/user`);
          },
          () => {
            setLoading(false);
          }
        )
      );
    } else
      dispatch(
        editUser(
          { ...fdata.newData },
          () => {
            clearForm();
            dispatch(alertActions.success("Data Edited successfully"));
            history.push(`/user`);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  return (
    <div>
      <div key={1}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to={`/user`}>
            {" "}
            <FormattedMessage id="User" defaultMessage="User" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            {" "}
            <FormattedMessage id="User" defaultMessage="User" /> {props.type}
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <div className="app-card-org" key={2}>
        <Loader active disabled={!loading} inverted size="big" />
        <Form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
          <Form.Group>
            <Form.Input
              fluid
              required
              type="text"
              name="first_name"
              label="First Name"
              value={fdata.newData.first_name}
              onChange={handleChange}
              placeholder="Enter First name..."
              width={5}
            />

            <Form.Input
              fluid
              required
              type="text"
              name="last_name"
              label="Last Name"
              value={fdata.newData.last_name}
              onChange={handleChange}
              placeholder="Enter Last name..."
              width={5}
            />

            <Form.Input
              fluid
              required
              type="email"
              name="email"
              label="Email"
              value={fdata.newData.email}
              onChange={handleChange}
              placeholder="Enter email..."
              width={5}
              autoComplete="off"
              autoCorrect="off"
              autoSave="off"
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              fluid
              required={required}
              type="password"
              name="password"
              label="Password"
              value={fdata.newData.password}
              onChange={handleChange}
              placeholder="Enter password..."
              width={5}
            />

            <Form.Input
              fluid
              required={required}
              type="password"
              name="confirm_password"
              label="Password Confirmation"
              value={fdata.newData.confirm_password}
              onChange={handleChange}
              placeholder="Enter password Again..."
              width={5}
            />

            <Form.Select
              fluid
              placeholder="Select Role"
              required
              clearable
              options={roleOptions}
              label="Role"
              value={fdata.newData.role}
              name="role"
              onChange={handleTypeChange}
              width={5}
            />
          </Form.Group>

          <Form.Group>
            <Form.Select
              fluid
              placeholder="Select Organization"
              required
              clearable
              options={orgOptions}
              label="Organization"
              value={fdata.newData.org}
              name="org"
              onChange={handleTypeChange}
              width={5}
            />
            <Form.Select
              fluid
              disabled={
                (user ? (user.role === "Admin" ? false : true) : true) ||
                fdata.newData.role !== 2
              }
              placeholder="Select Additional Organizations"
              required
              clearable
              multiple
              options={otherorgOptions}
              label="Additional Organizations"
              value={fdata.newData.other_org}
              name="other_org"
              onChange={handleChange}
              width={5}
            />

            <Form.Select
              fluid
              placeholder="Select Module"
              required
              search
              clearable
              multiple
              selection
              options={moduleOptions}
              label="Module"
              value={fdata.newData.modules}
              name="modules"
              onChange={handleChange}
              width={5}
            />
          </Form.Group>

          <Form.Group>
            <Form.Select
              fluid
              placeholder="Select Formbuilder Role"
              required={
                moduleroleOptions[Module.Formbuilder] !== undefined
                  ? moduleroleOptions[Module.Formbuilder][0] !== undefined
                    ? true
                    : false
                  : false
              }
              clearable
              disabled={
                !fdata.newData.modules.includes(
                  moduleIdsOptions[Module.Formbuilder]
                ) && moduleroleOptions[Module.Formbuilder] !== undefined
              }
              options={moduleroleOptions[Module.Formbuilder] || []}
              label="Formbuilder Role"
              value={fdata.newData.formbuilder_role}
              name="formbuilder_role"
              onChange={handleTypeChange}
              width={5}
            />

            <Form.Select
              fluid
              placeholder="Select Awareness Role"
              required={
                moduleroleOptions[Module.Awareness] !== undefined
                  ? moduleroleOptions[Module.Awareness][0] !== undefined
                    ? true
                    : false
                  : false
              }
              disabled={
                !fdata.newData.modules.includes(
                  moduleIdsOptions[Module.Awareness]
                ) && moduleroleOptions[Module.Awareness] !== undefined
              }
              clearable
              options={moduleroleOptions[Module.Awareness] || []}
              label="Awareness Role"
              value={fdata.newData.awareness_role}
              name="awareness_role"
              onChange={handleTypeChange}
              width={5}
            />

            <Form.Select
              fluid
              placeholder="Select Library Role"
              required={
                moduleroleOptions[Module.Library] !== undefined
                  ? moduleroleOptions[Module.Library][0] !== undefined
                    ? true
                    : false
                  : false
              }
              disabled={
                !fdata.newData.modules.includes(
                  moduleIdsOptions[Module.Library]
                ) && moduleroleOptions[Module.Library] !== undefined
              }
              clearable
              options={moduleroleOptions[Module.Library] || []}
              label="Library Role"
              value={fdata.newData.library_role}
              name="library_role"
              onChange={handleTypeChange}
              width={5}
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              fluid
              placeholder="Select Training Role"
              required={
                moduleroleOptions[Module.Training] !== undefined
                  ? moduleroleOptions[Module.Training][0] !== undefined
                    ? true
                    : false
                  : false
              }
              disabled={
                !fdata.newData.modules.includes(
                  moduleIdsOptions[Module.Training]
                ) && moduleroleOptions[Module.Training] !== undefined
              }
              clearable
              options={moduleroleOptions[Module.Training] || []}
              label="Training Role"
              value={fdata.newData.training_role}
              name="training_role"
              onChange={handleTypeChange}
              width={5}
            />

            <Form.Select
              fluid
              placeholder="Select Planner Role"
              required={
                moduleroleOptions[Module.Planner] !== undefined
                  ? moduleroleOptions[Module.Planner][0] !== undefined
                    ? true
                    : false
                  : false
              }
              disabled={
                !fdata.newData.modules.includes(
                  moduleIdsOptions[Module.Planner]
                ) && moduleroleOptions[Module.Planner] !== undefined
              }
              clearable
              options={moduleroleOptions[Module.Planner] || []}
              label="Planner Role"
              value={fdata.newData.planner_role}
              name="planner_role"
              onChange={handleTypeChange}
              width={5}
            />

            <Form.Select
              fluid
              placeholder="Select Contact Trace Role"
              required={
                moduleroleOptions[Module.Contact_trace] !== undefined
                  ? moduleroleOptions[Module.Contact_trace][0] !== undefined
                    ? true
                    : false
                  : false
              }
              disabled={
                !fdata.newData.modules.includes(
                  moduleIdsOptions[Module.Contact_trace]
                ) && moduleroleOptions[Module.Contact_trace] !== undefined
              }
              clearable
              options={moduleroleOptions[Module.Contact_trace] || []}
              label="Contact Trace Role"
              value={fdata.newData.contact_trace_role}
              name="contact_trace"
              onChange={handleTypeChange}
              width={5}
            />
          </Form.Group>

          <Divider />

          <Header>External User Credentials</Header>

          {fdata.newData.external_user_credentials.map((item, index) => {
            return (
              <div key={index} style={{ marginBottom: "25px" }}>
                <Form.Group widths={16}>
                  <Form.Input
                    fluid
                    placeholder="Enter Provider"
                    required
                    label="Provider"
                    value={item.provider}
                    name="provider"
                    onChange={(e, result) =>
                      handleExternalUserChange(e, result, index)
                    }
                    style={{ fontWeight: "bold" }}
                    width={5}
                    maxLength={255}
                  />

                  <Form.Input
                    fluid
                    placeholder="Enter Username"
                    required
                    label="Username"
                    value={item.username}
                    name="username"
                    onChange={(e, result) =>
                      handleExternalUserChange(e, result, index)
                    }
                    width={5}
                    maxLength={255}
                    autoComplete="off"
                    autoCorrect="off"
                    autoSave="off"
                  />

                  <Form.Input
                    fluid
                    placeholder="Enter Password"
                    required
                    label="Password"
                    value={item.password}
                    name="password"
                    onChange={(e, result) =>
                      handleExternalUserChange(e, result, index)
                    }
                    onMouseEnter={(e: any) => (e.target.type = "text")}
                    onMouseLeave={(e: any) => (e.target.type = "password")}
                    type="password"
                    width={5}
                    maxLength={255}
                    autoComplete="off"
                    autoCorrect="off"
                    autoSave="off"
                  />
                </Form.Group>

                <Form.Group widths={16}>
                  <Form.Input
                    fluid
                    placeholder="Enter Website URL"
                    label="Website URL"
                    value={item.website_url}
                    name="website_url"
                    onChange={(e, result) =>
                      handleExternalUserChange(e, result, index)
                    }
                    width={5}
                    maxLength={255}
                  />

                  <Form.Input
                    fluid
                    placeholder="Enter Description"
                    label="Description"
                    value={item.description}
                    name="description"
                    onChange={(e, result) =>
                      handleExternalUserChange(e, result, index)
                    }
                    width={5}
                    maxLength={1000}
                  />

                  <Form.Field
                    width={5}
                    style={{ marginTop: "25px", textAlign: "center" }}
                  >
                    <Button
                      type="button"
                      onClick={() => deleteExternalUserCredential(index)}
                      negative
                      size="small"
                      circular
                      icon="minus"
                      title="Delete Credential Record"
                    />
                  </Form.Field>
                </Form.Group>

                <Divider />
              </div>
            );
          })}

          <Button
            icon
            type="button"
            onClick={() => addExternalUserCredential()}
            labelPosition="left"
            positive
            size="small"
          >
            Add New Credential
            <Icon name="plus" />
          </Button>

          <br />
          <br />

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>

          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>
        </Form>
      </div>
    </div>
  );
}
