import { appPointTypes } from "../action/appPointsAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  
  switch (action.type) {
    case appPointTypes.APP_POINT_LOADED:
      return action.payload;
    case appPointTypes.APP_POINT_UNLOADED:
      return initialState;
    case appPointTypes.ADD_APP_POINT:
      return state;
    case appPointTypes.EDIT_APP_POINT:
      return state;
    case appPointTypes.DELETE_APP_POINT:
      return state;
    default:
      return state;
  }
};
