import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../redux/action/alertAction";
import { apiService } from "../../services";
import {
  Header,
  Button,
  Checkbox,
  Segment,
  Popup as SPopup,
  Image,
  List,
  Icon,
  Dimmer,
  Loader,
  Table,
  Pagination,
  Input,
  Label,
  Grid,
  Dropdown,
  Modal,
  Message,
} from "semantic-ui-react";
import defaultImg from "../../assets/images/image.png";
import { Link, useHistory } from "react-router-dom";
import { formBuilderReportPageRouteGenerate } from "../../helper/routing";

export default function SimpleTable(props: {
  slug: string;
  id?: any;
  status?: string;
  filter_by_status?: boolean;
  hideStepForms?(data: object): boolean;
  hideFormTitle?: boolean;
  status_field?: string;
  containers_to_show?: string[];
  finance_meta?: {
    finance_id: number;
    tranche_id: number;
    tranche_amount: number | string;
  };
  step_name?: string;
  setForm?: Function;
}) {
  const filteringStatus =
    (props.filter_by_status ? props.status : null) ?? null;

  const status_field = props.status_field ?? "status";

  const [data, setData] = useState<{
    columns: { key: string; label: string }[];
    form: {
      _id: string;
      name: string;
      title: string;
      slug: string;
      fulcrum_id?: string;
      is_feedback_form?: boolean;
    };
    related?: boolean;
    slug: string;
    data: {
      current_page: number;
      data: { [key: string]: any }[];
      from: number;
      to: number;
      last_page: number;
      per_page: number;
      total: number;
    };
  } | null>(null);

  const [loading, setLoading] = useState(false);

  const [orderBy, setOrderBy] = useState("updated_at");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [page, setPage] = useState<any>(1);
  const [perPage, setPerPage] = useState<any>(25);
  const [search, setSearch] = useState<string>("");

  const [deactivating, setDeactivating] = useState<any>(undefined);
  const [deactivationLoading, setDeactivationLoading] =
    useState<boolean>(false);

  useEffect(() => {
    const timer: any = setTimeout(
      () => {
        fetchData();
      },
      data === null ? 50 : 500
    );

    return () => clearTimeout(timer);
  }, [
    orderBy,
    orderDirection,
    page,
    perPage,
    search,
    props.id,
    props.slug,
    props.filter_by_status,
    props.status,
    props.status_field,
    props.finance_meta,
  ]);

  const dispatch = useDispatch();

  const fetchData = () => {
    setLoading(true);

    let params = new URLSearchParams();
    params.append("order_by", orderBy);
    params.append("order_direction", orderDirection);
    params.append("search", search);
    params.append("page", page);
    params.append("per_page", perPage);

    if (filteringStatus) {
      params.append("status", props.status ?? "");
      params.append("status_field", status_field);
    }

    if (
      props.finance_meta &&
      props.finance_meta.finance_id &&
      props.finance_meta.tranche_id
    ) {
      params.append("finance_id", String(props.finance_meta.finance_id));
      params.append("tranche_id", String(props.finance_meta.tranche_id));
    }

    apiService
      .getAll(`/formBuilder/v2-data/${props.slug}?${params.toString()}`)
      .then((res) => {
        setData(res);
        if (props.setForm) {
          props.setForm(res);
        }
      })
      .catch((err) => {
        dispatch(alertActions.error("Failed to load records."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const computedColumns = () => {
    if (!data || !data.columns || data.columns.length === 0) {
      return [];
    }

    let columns = [...data.columns];

    if (!data?.form?.fulcrum_id) {
      columns.push({
        key: "_action",
        label: "Action",
      });
    }

    if (
      data &&
      data.form &&
      data.form.is_feedback_form &&
      data.form.slug === "rhiab-feedback-master-form"
    ) {
      columns.push({
        key: "_feedback_project_slug",
        label: "Project Slug",
      });
    }

    return columns;
  };

  const performRecordDeactivation = (id: any) => {
    setDeactivationLoading(true);

    apiService
      .save(`/formData/deactivate`, { toDelete: [id], slug: props.slug })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          fetchData();
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      )
      .finally(() => {
        setDeactivationLoading(false);
        setDeactivating(undefined);
      });
  };

  const SafePrint = (data: any) => {
    let type = typeof data;

    if (!data) {
      return "-";
    } else if (type === "string") {
      return data.replace(/<(.|\n)*?>/g, "");
    } else if (Array.isArray(data)) {
      return data.length === 0 ? "-" : JSON.stringify(data);
    } else if (type === "object") {
      return data?.display_name ?? data?.name ?? JSON.stringify(data);
    } else {
      return data ?? "";
    }
  };

  return (
    <div>
      {props.hideFormTitle !== false ? (
        <div className="app-card-org">
          {data ? (
            <div>
              <List.Content>
                <List.Header>
                  {data?.form?.name ?? "..."} / {data?.form?.slug ?? ""}
                </List.Header>
              </List.Content>
            </div>
          ) : (
            <small>...</small>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="app-card-org">
        <Dimmer className="sm-dimmer" inverted active={loading}>
          <Loader size="large" />
        </Dimmer>

        {data ? (
          <div>
            <Input
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              iconPosition="left"
              icon="search"
              placeholder="Search Records ..."
            />

            <div className="w-full my-2 overflow-auto">
              <Table sortable celled singleLine selectable striped color="teal">
                <Table.Header>
                  <Table.Row>
                    {computedColumns().map((col) => (
                      <Table.HeaderCell
                        key={col.key}
                        sorted={
                          orderBy === col.key
                            ? orderDirection === "DESC"
                              ? "descending"
                              : "ascending"
                            : undefined
                        }
                        onClick={() => {
                          setOrderDirection(
                            orderBy === col.key ? "ASC" : "DESC"
                          );
                          setOrderBy(col.key);
                        }}
                      >
                        {col.key === "_transactions"
                          ? props.step_name &&
                            props.step_name ===
                              "Construction Supervision Review & Approval"
                            ? "Tranche Amount"
                            : col.label
                          : col.label}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                {!data?.data?.data || data?.data?.data.length <= 0 ? (
                  <Table.Footer>
                    <div className="p-3 font-bold">No Records</div>
                  </Table.Footer>
                ) : (
                  ""
                )}

                <Table.Body>
                  {data?.data?.data?.map((record, index: number, list) => (
                    <Table.Row key={record._id}>
                      {computedColumns().map((col) => {
                        if (col.key === "_id") {
                          // let next = list?.[index + 1]?.[col.key] ?? null;
                          // let previous = list?.[index - 1]?.[col.key] ?? null;
                          // console.log(next, previous);

                          return (
                            <Table.Cell key={col.key + record._id}>
                              <Link
                                to={formBuilderReportPageRouteGenerate(
                                  props.slug,
                                  record[col.key],
                                  props.id,
                                  props.hideStepForms
                                    ? props.hideStepForms(record)
                                    : false,
                                  props.containers_to_show ?? [],
                                  props.finance_meta
                                  // next,
                                  // previous
                                )}
                                className="flex items-center justify-start no-underline decoration-0 hover:text-cyan-500"
                                style={{ textDecoration: "none" }}
                              >
                                <Icon size="small" name="eye" />
                                {record[col.key]}
                              </Link>
                            </Table.Cell>
                          );
                        } else if (col.key === "_transactions") {
                          return (
                            <Table.Cell key={col.key + record._id}>
                              <ul
                                className="flex flex-col m-0 gap-y-1"
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                {record[col.key].map(
                                  (transaction: any, index: number) => (
                                    <li
                                      title={transaction.description}
                                      className="flex items-center justify-between hover:bg-white"
                                      style={
                                        {
                                          // marginLeft: index * 4 + "px",
                                        }
                                      }
                                      key={transaction.id}
                                    >
                                      <b
                                        className={`${
                                          transaction.amount > 0
                                            ? "text-green-500"
                                            : "text-red-500"
                                        }`}
                                      >
                                        {transaction.amount}
                                      </b>

                                      <Label
                                        style={{
                                          marginLeft: "5px",
                                          padding: "3px 5px",
                                          fontSize: "0.7rem",
                                        }}
                                        color={
                                          transaction.status === "paid"
                                            ? "green"
                                            : transaction.status === "reviewed"
                                            ? "blue"
                                            : transaction.status ===
                                                "not_paid" ||
                                              transaction.status ===
                                                "not_reviewed"
                                            ? "red"
                                            : "yellow"
                                        }
                                      >
                                        {transaction.status.toUpperCase()}
                                      </Label>
                                    </li>
                                  )
                                )}

                                {/* {record[col.key].length > 0 ? (
                                  <span style={{"display":"inline-block","textAlign":"right","fontWeight":"900","letterSpacing":"1px","position":"absolute","right":"0px","top":"-80%","fontSize":"0.7rem"}}>
                                    + {record[col.key].length - 1} more
                                  </span>
                                ) : (
                                  ""
                                )} */}

                                {!record[col.key] || record[col.key].length <= 0
                                  ? "-"
                                  : ""}
                              </ul>
                            </Table.Cell>
                          );
                        } else if (col.key === "_action") {
                          return (
                            <Table.Cell key={col.key + record._id}>
                              <div className="flex items-center justify-start">
                                <Button
                                  size="mini"
                                  icon="edit outline"
                                  color="blue"
                                  as={Link}
                                  to={`/formbuilder/data/${props.slug}/edit/${
                                    record._id ?? record.id
                                  }${props.id ? `?step_id=${props.id}` : ""}`}
                                />
                                <Button
                                  size="mini"
                                  icon="trash"
                                  color="red"
                                  onClick={() => setDeactivating(record)}
                                />
                              </div>
                            </Table.Cell>
                          );
                        } else if (col.key === "created_by_name") {
                          return (
                            <Table.Cell key={col.key + record._id}>
                              <span>
                                {record.created_by_name ??
                                  record._feedback_user_name ??
                                  "-"}
                              </span>
                              {record._feedback_user_email ? (
                                <small style={{ display: "block" }}>
                                  {record._feedback_user_email}
                                </small>
                              ) : (
                                ""
                              )}
                            </Table.Cell>
                          );
                        } else if (col.key === "_feedback_project_slug") {
                          return (
                            <Table.Cell key={col.key + record._id}>
                              <span>
                                <Link
                                  to={
                                    record._feedback_project_id
                                      ? `/project/projectReport/${record._feedback_project_id}`
                                      : "#!"
                                  }
                                >
                                  {record._feedback_project_slug ?? "-"}
                                </Link>
                              </span>
                              {record._feedback_organization_slug ? (
                                <Link
                                  to={
                                    record._feedback_organization_id
                                      ? `/organization/organizationReport/${record._feedback_organization_id}`
                                      : "#!"
                                  }
                                >
                                  <small style={{ display: "block" }}>
                                    Organization:{" "}
                                    {record._feedback_organization_slug}
                                  </small>
                                </Link>
                              ) : (
                                ""
                              )}
                            </Table.Cell>
                          );
                        } else {
                          return (
                            <Table.Cell key={col.key + record._id}>
                              <span
                                style={
                                  col.key === "address"
                                    ? {
                                        width: "350px",
                                        whiteSpace: "pre-wrap",
                                        display: "inline-block",
                                      }
                                    : {}
                                }
                              >
                                {SafePrint(record[col.key])}
                              </span>
                            </Table.Cell>
                          );
                        }
                      })}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>

            <div className="flex items-center justify-between">
              <div style={{ marginRight: "5px", display: "inline-block" }}>
                <p style={{ display: "inline-block" }}>Per Page: </p>
                <Dropdown
                  options={[
                    { key: 5, text: "5", value: 5 },
                    { key: 10, text: "10", value: 10 },
                    { key: 15, text: "15", value: 15 },
                    { key: 25, text: "25", value: 25 },
                    { key: 50, text: "50", value: 50 },
                    { key: 100, text: "100", value: 100 },
                  ]}
                  placeholder="Per Page"
                  compact
                  selection
                  upward
                  onChange={(e, d) => setPerPage(d.value)}
                  style={{ marginLeft: "5px" }}
                  value={perPage}
                />
              </div>

              <Pagination
                defaultActivePage={data.data.current_page}
                totalPages={data.data.last_page}
                onPageChange={(e, { activePage }) => setPage(activePage)}
              />
            </div>
          </div>
        ) : (
          <Icon size="large" name="spinner" />
        )}
      </div>

      <Modal
        open={deactivating ? true : false}
        size="small"
        onClose={() => setDeactivating(undefined)}
        centered={true}
        style={{ position: "relative" }}
      >
        <Modal.Header>Deactivating / Deleting Record</Modal.Header>
        <Modal.Content>
          <Message negative>
            <Message.Header>
              Are you sure you want to delete this record?{" "}
            </Message.Header>

            <small>
              {deactivating && deactivating._id
                ? `ID: ${deactivating._id}`
                : ""}

              <br />

              {deactivating && deactivating.homeowner_name
                ? `Homeowner Name: ${deactivating.homeowner_name}`
                : ""}
            </small>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            inverted
            onClick={() => {
              performRecordDeactivation(deactivating._id ?? deactivating.id);
            }}
            loading={deactivationLoading}
            disabled={deactivationLoading}
          >
            Delete
          </Button>

          <Button
            color="green"
            inverted
            onClick={() => setDeactivating(undefined)}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
