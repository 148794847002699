import React, { useEffect, useState, useCallback } from "react";
import {
  Breadcrumb,
  Header,
  Button,
  Icon,
  Image,
  Message,
  Grid,
  Pagination,
  Loader,
  Popup,
  Modal,
  Form,
  List,
  Divider,
  Input,
  Label,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  loadFormbuilderPage,
  unloadFormbuilderPage,
  removeFormbuilder,
} from "../../redux/action/formbuilderAction";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";

import { AppConfig } from "../../config";
import { FacebookShareButton } from "react-share";
import DataCount from "./DataCount";
import moment from "moment";
import { debounce, filter } from "lodash";
import defaultImg from "../../assets/images/image.png";
import { apiRouteGenerate } from "../../helper/routing";

type indFormbuilderType = {
  writable_data_share?: any;
  name: string;
  _id: string;
  slug: string;
  organization_id: string;
  module?: string;
  project?: string;
  order: number;
  title: string;
  library?: boolean;
  share?: boolean;
  data_share?: boolean;
  fulcrum_id?: string;
  fulcrum_data_share_link?: string;
  fulcrum_api_token?: string;
  kobo_id?: string | number;
  kobo_api_token?: string;
  kobo_id_string?: string;
  kobo_base_url?: string;
  users: {
    id: number;
  }[];
  tags?: string[];
};
interface RootState {
  formbuilder: {
    forms: {
      data: Array<indFormbuilderType>;
      last_page: number;
      per_page: number;
    };
    organization: any[];
    modules?: any[];
    projects?: any[];
  };
}

interface RenderPropsType {
  data: {
    data: indFormbuilderType[];
  };
}

export default function Formbuilder(props: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [slug, setSlug] = useState<string>();
  const [textlink, setTextLink] = useState<any>();
  const [api_token, setApiToken] = useState<any>();
  const [fulcrumImporting, setFulcrumImporting] = useState<boolean>(false);
  const [fulcrumFormId, setFulcrumFormId] = useState<string | number>("");
  const [fulcrumFormListLoading, setFulcrumFormListLoading] =
    useState<boolean>(false);
  const [fulcrumFormList, setFulcrumFormList] = useState<[]>([]);

  const [koboBaseUrl, setKoboBaseUrl] = useState<string>(
    "https://kc.kobotoolbox.org"
  );
  const [koboApiToken, setKoboApiToken] = useState<string>("");
  const [koboFormId, setKoboFormId] = useState<string | number>("");
  const [koboImporting, setKoboImporting] = useState<boolean>(false);
  const [koboFormListLoading, setKoboFormListLoading] =
    useState<boolean>(false);
  const [koboFormList, setKoboFormList] = useState<[]>([]);

  const [totalImported, setTotalImported] = useState<any>(null);

  const [openImport, setOpenImport] = useState<boolean>(false);
  const [openFulcrum, setOpenFulcrum] = useState<boolean>(false);
  const [openKobo, setOpenKobo] = useState<boolean>(false);
  const [openDataImport, setOpenDataImport] = useState<boolean>(false);

  const [openImportGSheet, setOpenImportGSheet] = useState<boolean>(false);
  const [gSheetUrl, setGSheetUrl] = useState<string>("");
  const [importGSheetLoading, setImportGSheetLoading] =
    useState<boolean>(false);

  const [deleteId, setDeleteId] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);

  const [organization_id, setOrganizationId] = useState<string | null>(
    new URLSearchParams(props.location.search).get("organization_id") || null
  );
  const [module_id, setModuleId] = useState<string | null>(
    new URLSearchParams(props.location.search).get("module_id") || null
  );
  const [project_id, setProjectId] = useState<string | null>(
    new URLSearchParams(props.location.search).get("project_id") || null
  );
  const [apiUrl, setApiUrl] = useState<string>(
    apiRouteGenerate(
      "formBuilder", // route name
      { organization_id, module_id, project_id } // params
    )
  );

  const [total, setTotal] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [filterFlag, setFilterFlag] = useState<string>("");
  const [timer, setTimer] = useState<any>(null);
  const [timerCount, setTimerCount] = useState<any>(0);
  const formbuilders = useSelector((state: RootState) => state.formbuilder);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    dispatch(
      loadFormbuilderPage(apiUrl, () => {
        setLoading(false);
      })
    );
    return () => {
      dispatch(unloadFormbuilderPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  const debounceLoadKoboForms = useCallback(
    debounce((koboApiToken) => {
      try {
        let url = new URL(koboBaseUrl);
        setKoboFormListLoading(true);
        apiService
          .save("/formBuilderCrossLoadKoboFormDropdown", {
            url: url.origin,
            token: koboApiToken,
          })
          .then(
            (data) => {
              if (data && data.length > 0) {
                setKoboFormList(data);
                setKoboFormId(data[0].value);
              }
            },
            (error) => {
              dispatch(alertActions.error(error.toString()));
            }
          )
          .finally(() => {
            setKoboFormListLoading(false);
          });
      } catch (err) {
        //
      }
    }, 1000),
    []
  );

  const debounceLoadFulcrumForms = useCallback(
    debounce((fulcrum_api_token) => {
      try {
        setFulcrumFormListLoading(true);
        apiService
          .save("/formBuilderCrossLoadFulcrumFormDropdown", {
            token: fulcrum_api_token,
          })
          .then(
            (data) => {
              if (data && data.length > 0) {
                setFulcrumFormList(data);
                setFulcrumFormId(data[0].value);
              }
            },
            (error) => {
              dispatch(alertActions.error(error.toString()));
            }
          )
          .finally(() => {
            setFulcrumFormListLoading(false);
          });
      } catch (err) {
        //
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (koboApiToken && koboBaseUrl && koboApiToken.length > 35 && openKobo) {
      debounceLoadKoboForms(koboApiToken);
    }
  }, [koboApiToken, koboBaseUrl]);

  useEffect(() => {
    if (
      api_token &&
      api_token.length >= 80 &&
      openFulcrum &&
      !fulcrumImporting
    ) {
      debounceLoadFulcrumForms(api_token);
    }
  }, [api_token]);

  useEffect(() => {
    if (timer && timerCount > 0 && (fulcrumImporting || koboImporting)) {
      setTimeout(function () {
        setTimer(timer.add(1, "second"));
        setTimerCount(timerCount + 1);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [timerCount]);

  useEffect(() => {
    if (formbuilders.forms !== undefined) {
      //console.log(formbuilders.last_page)
      setTotal(formbuilders.forms.last_page);
      setPerPage(formbuilders.forms.per_page || 5);
    }
  }, [formbuilders]);

  const exportData = (slug: string, type: string, type1: string) => {
    if (type1 === "drive") {
      apiService
        .getAll(`/formBuilder/exportAll/${slug}/${type}?export_type=${type1}`)
        .then(
          (data) => {
            setTimeout(() => {
              alert(data.link);
            }, 100);
          },
          (error) => {
            dispatch(alertActions.error(error.toString()));
          }
        );
    } else {
      apiService
        .getfile(`/formBuilder/exportAll/${slug}/${type}?export_type=${type1}`)
        .then(
          (data) => {
            setTimeout(() => {
              window.open(data);
            }, 100);
          },
          (error) => {
            dispatch(alertActions.error(error.toString()));
          }
        );
    }
  };

  // On pagination change
  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);

    setApiUrl(
      apiRouteGenerate("formBuilder", {
        page: pageInfo.activePage,
        per_page: perPage || 5,
        q: encodeURIComponent(search),
        filter_flag: encodeURIComponent(filterFlag),
        organization_id,
        module_id,
        project_id,
      })
    );
  };

  const onSearch = (event: any) => {
    // console.log(event.key)
    if (event.key === "Enter") {
      setApiUrl(
        apiRouteGenerate("formBuilder", {
          page: 1,
          per_page: perPage || 5,
          q: encodeURIComponent(search),
          filter_flag: encodeURIComponent(filterFlag),
          organization_id,
          module_id,
          project_id,
        })
      );
    }
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage((data.value as number) || 5);

    setApiUrl(
      apiRouteGenerate("formBuilder", {
        page: activePage,
        per_page: data.value || 5,
        q: encodeURIComponent(search),
        filter_flag: encodeURIComponent(filterFlag),
        organization_id,
        module_id,
        project_id,
      })
    );
  };

  const importForm = () => {
    setOpenImport(true);
  };

  const importData = (slug: string) => {
    setSlug(slug);
    setOpenDataImport(true);
  };

  const processExcel = () => {
    if (!file) {
      dispatch(alertActions.error("Please select a valid file"));
      return;
    }

    apiService.save(`/formBuilderImport`, { file: file }).then(
      (data) => {
        //dispatch(alertActions.success(data.message));
        setOpenImport(false);
        // console.log({data})
        history.push("/formbuilder/create", data);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const importGSheet = () => {
    if (!gSheetUrl || !gSheetUrl.trim()) {
      dispatch(alertActions.error("Please enter google sheet url"));
      setImportGSheetLoading(false);
      return;
    }

    setImportGSheetLoading(true);

    apiService
      .save(`/formBuilderImportViaGoogleSheet`, { url: gSheetUrl })
      .then(
        (data) => {
          setOpenImportGSheet(false);
          setImportGSheetLoading(false);
          dispatch(alertActions.success(data.message));
          history.push("/formbuilder/create", data);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          setImportGSheetLoading(false);
        }
      );
  };

  const processExcelForData = () => {
    apiService.save(`/formBuilderDataImport`, { file: file, slug: slug }).then(
      (data) => {
        dispatch(alertActions.success("Data uploaded successfully"));
        setOpenDataImport(false);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const importFormFulcrum = () => {
    setOpenFulcrum(true);
    setTotalImported(0);
  };

  const formBuilderImportedCount = async (key: any, callback: Function) => {
    if (key) {
      apiService.save(`/formBuilderImportedCount`, { key: key }).then(
        (data) => {
          setTotalImported(data.count);
          callback();
        },
        (error) => {
          setTotalImported("Unable to Load");
          callback();
        }
      );
    }
  };

  const processFulcrum = async (
    link: any = null,
    token: any = null,
    form_id: any = null
  ) => {
    if ((link || form_id) && token) {
      setFulcrumImporting(true);
      setTimer(moment().startOf("day"));
      setTimerCount(1);
      setTotalImported(0);
      apiService
        .save(
          `/formBuilderImportFulcrum`,
          { textlink: link, api_token: token, form_id: form_id },
          true
        )
        .then(
          (data) => {
            formBuilderImportedCount(form_id, () => {
              dispatch(
                alertActions.success(
                  data.message || "Fulcrum Data Imported successfully"
                )
              );
              setFulcrumImporting(false);
              // setOpenFulcrum(false);
              setApiToken("");
              setLoading(true);
              dispatch(
                loadFormbuilderPage(apiUrl, () => {
                  setLoading(false);
                })
              );
              setTimerCount(0);
            });
          },
          (error) => {
            formBuilderImportedCount(form_id, () => {
              setFulcrumImporting(false);
              dispatch(alertActions.error(error.toString()));
              setTimerCount(0);
            });
          }
        );
    } else {
      dispatch(alertActions.error("Link and API Token is required."));
    }
  };

  const processKobo = async (
    base_url: any = null,
    token: any = null,
    form_id: any = null
  ) => {
    if (base_url && token && form_id) {
      setKoboImporting(true);
      setTimer(moment().startOf("day"));
      setTimerCount(1);
      setTotalImported(0);

      try {
        base_url = new URL(base_url);
        apiService
          .save(
            `/formBuilderImportKobo`,
            { url: base_url.origin, token: token, form_id: form_id },
            true
          )
          .then(
            (data) => {
              formBuilderImportedCount(form_id, () => {
                dispatch(
                  alertActions.success(
                    data.message || "KoboToolbox Data Imported successfully"
                  )
                );
                setKoboImporting(false);
                // setOpenKobo(false);
                setKoboApiToken("");
                setLoading(true);
                dispatch(
                  loadFormbuilderPage(apiUrl, () => {
                    setLoading(false);
                  })
                );
                setTimerCount(0);
              });
            },
            (error) => {
              formBuilderImportedCount(form_id, () => {
                setKoboImporting(false);
                dispatch(alertActions.error(error.toString()));
                setTimerCount(0);
              });
            }
          );
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(alertActions.error("Base URL, API Token & Form is required."));
    }
  };

  const handleFileChange = async (event: any, result: any) => {
    let value = event.target.files[0];
    setFile(value);
  };

  const handleLinkChange = async (event: any, result: any) => {
    let value = event.target.value;
    setTextLink(value);
  };

  const deleteData = (slug: string) => {
    setOpen(true);
    setDeleteId(slug);
  };
  /*
  const confirmDelete = () => {
    // alert(delteId)

    dispatch(removeFormbuilder(deleteId));

    setOpen(false);
    dispatch(loadFormbuilderPage(apiUrl));
  };
  */

  const confirmDelete = () => {
    // alert(delteId)
    setLoading(true);
    dispatch(
      removeFormbuilder(
        deleteId,
        () => {
          dispatch(
            loadFormbuilderPage(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  const addTemplate = (slug: string) => {
    apiService.getAll(`/formBuilder/template/${slug}`).then(
      (data) => {
        setLoading(true);
        dispatch(
          loadFormbuilderPage(apiUrl, () => {
            setLoading(false);
          })
        );
        dispatch(alertActions.success(data.message));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const enableShare = (slug: string) => {
    apiService.getAll(`/formBuilder/share/${slug}`).then(
      (data) => {
        setLoading(true);
        dispatch(
          loadFormbuilderPage(apiUrl, () => {
            setLoading(false);
          })
        );
        dispatch(alertActions.success(data.message));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const clearFilters = ({
    project_id,
    module_id,
    organization_id,
    updateApiUrl,
  }: any) => {
    setProjectId(project_id || null);
    setModuleId(module_id || null);
    setOrganizationId(organization_id || null);
    setSearch("");
    setFilterFlag("");

    if (updateApiUrl === true) {
      setApiUrl(
        apiRouteGenerate("formBuilder", {
          page: 1,
          per_page: perPage || 5,
          organization_id,
          module_id,
          project_id,
        })
      );
    }
  };

  const RenderList = useCallback((): any => {
    if (formbuilders.forms === undefined) {
      return <Message>No data Found</Message>;
    }
    if (formbuilders.forms.data.length === 0) {
      return <Message>No data Found</Message>;
    }

    return formbuilders.forms.data.map((item, index: number) => {
      return (
        <List divided verticalAlign="middle" key={index}>
          <List.Item>
            <Image size="tiny" rounded bordered src={defaultImg} />

            <List.Content>
              <List.Header
                as={Link}
                to={`/formbuilder/FormBuilderApp/${item.slug}`}
                className="text-uppercase"
              >
                {item.name}
              </List.Header>
              <List.Description
                as="a"
                onClick={() => {
                  clearFilters({
                    organization_id: item.organization_id,
                    updateApiUrl: true,
                  });
                }}
              >
                {formbuilders.organization[item.organization_id] ?? ""}
              </List.Description>
              <List.Description className="mt-2">
                <Label
                  style={{
                    backgroundColor: "var(--color-blue)",
                    color: "white",
                  }}
                  as="a"
                  onClick={() => {
                    setFilterFlag("!only_shared");
                    setApiUrl(
                      apiRouteGenerate("formBuilder", {
                        page: 1,
                        per_page: perPage || 5,
                        q: encodeURIComponent(search),
                        filter_flag: encodeURIComponent("!only_shared"),
                        organization_id,
                        module_id,
                        project_id,
                      })
                    );
                  }}
                  tag
                  size={"tiny"}
                  className={item.share ? "" : "d-none"}
                >
                  {item.share ? "Form Share Enabled" : ""}
                </Label>
                <Label
                  style={{
                    backgroundColor: "var(--color-blue)",
                    color: "white",
                  }}
                  as="a"
                  onClick={() => {
                    setFilterFlag("!only_data_shared");
                    setApiUrl(
                      apiRouteGenerate("formBuilder", {
                        page: 1,
                        per_page: perPage || 5,
                        q: encodeURIComponent(search),
                        filter_flag: encodeURIComponent("!only_data_shared"),
                        organization_id,
                        module_id,
                        project_id,
                      })
                    );
                  }}
                  tag
                  size={"tiny"}
                  className={item.data_share ? "" : "d-none"}
                >
                  {item.data_share ? "Data Share Enabled" : ""}
                  {item.writable_data_share ? "(+Write)" : ""}
                </Label>
                <Label
                  style={{
                    backgroundColor: "var(--color-red)",
                    color: "white",
                  }}
                  as="a"
                  onClick={() => {
                    setFilterFlag("!only_fulcrum");
                    setApiUrl(
                      apiRouteGenerate("formBuilder", {
                        page: 1,
                        per_page: perPage || 5,
                        q: encodeURIComponent(search),
                        filter_flag: encodeURIComponent("!only_fulcrum"),
                        organization_id,
                        module_id,
                        project_id,
                      })
                    );
                  }}
                  tag
                  size={"tiny"}
                  className={item.fulcrum_id ? "" : "d-none"}
                >
                  {item.fulcrum_id ? "Imported From Fulcrum" : ""}
                </Label>
                <Label
                  style={{
                    backgroundColor: "var(--cyan)",
                    color: "white",
                  }}
                  as="a"
                  onClick={() => {
                    setFilterFlag("!only_kobo");
                    setApiUrl(
                      apiRouteGenerate("formBuilder", {
                        page: 1,
                        per_page: perPage || 5,
                        q: encodeURIComponent(search),
                        filter_flag: encodeURIComponent("!only_kobo"),
                        organization_id,
                        module_id,
                        project_id,
                      })
                    );
                  }}
                  tag
                  size={"tiny"}
                  className={item.kobo_id ? "" : "d-none"}
                >
                  {item.kobo_id ? "Imported From KoboToolbox" : ""}
                </Label>
                {item.tags &&
                  item.tags.map((tag: string) => {
                    return (
                      <React.Fragment key={tag}>
                        <Label
                          size="tiny"
                          as="a"
                          onClick={() => {
                            setSearch("#" + tag);
                            setApiUrl(
                              apiRouteGenerate("formBuilder", {
                                page: 1,
                                per_page: perPage || 5,
                                q: encodeURIComponent("#" + tag),
                                filter_flag: encodeURIComponent(filterFlag),
                                organization_id,
                                module_id,
                                project_id,
                              })
                            );
                          }}
                          style={{
                            backgroundColor: "var(--color-blue)",
                            color: "white",
                            borderColor: "var(--color-blue)",
                          }}
                        >
                          {tag}
                        </Label>
                      </React.Fragment>
                    );
                  })}
              </List.Description>
            </List.Content>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              <p style={{ color: "grey", textAlign: "right" }}>
                {" "}
                <DataCount slug={item.slug} />{" "}
              </p>

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "var(--color-blue)",
                    }}
                    circular
                    icon="upload"
                    onClick={() => importData(item.slug)}
                    // color="instagram"
                  ></Button>
                }
                content="Upload"
                position="top center"
              />

              <Popup
                trigger={
                  <Button
                    circular
                    icon="download"
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "var(--color-green)",
                    }}
                  ></Button>
                }
                content={
                  <div>
                    <Grid key="g1">
                      <Grid.Column key="1" floated="left">
                        <Button
                          onClick={() =>
                            exportData(item.slug, "xls", "download")
                          }
                          content="EXCEL Download"
                        />
                      </Grid.Column>
                      <Grid.Column key="2" floated="left">
                        <Button
                          onClick={() =>
                            exportData(item.slug, "csv", "download")
                          }
                          content="CSV Download"
                        />
                      </Grid.Column>
                    </Grid>
                  </div>
                }
                on="click"
                position="top right"
              />

              <Popup
                trigger={
                  <Button
                    icon="pencil"
                    as={Link}
                    to={`/formbuilder/${item.slug}/edit`}
                    className="formbuilder-buttons"
                    circular
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  ></Button>
                }
                content="Edit this Form"
                position="top center"
              />

              <Popup
                trigger={
                  <Button
                    icon="code"
                    as={Link}
                    to={`/formbuilder/${item.slug}/function`}
                    className="formbuilder-buttons"
                    circular
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  ></Button>
                }
                content="Update Automated Functions & Calculations"
                position="top right"
              />

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "var(--color-red)",
                    }}
                    circular
                    onClick={() => deleteData(item.slug)}
                    icon="trash"
                  ></Button>
                }
                content="Delete this Form"
                position="top center"
              />
              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "grey",
                    }}
                    circular
                    as={Link}
                    to={`/formbuilder/fill/${item.slug}`}
                    icon="write square"
                  ></Button>
                }
                content="Fill This Form"
                position="top center"
              />

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "blue",
                    }}
                    circular
                    as={Link}
                    to={`/formbuilder/deactivated/${item.slug}`}
                    // style={{ marginTop: "10px" }}
                    icon="unlinkify"
                  ></Button>
                }
                content="Deactivated List"
                position="top center"
              />

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: `${item.library ? "red" : "var(--color-green)"}`,
                    }}
                    circular
                    onClick={() => addTemplate(item.slug)}
                    // style={{ marginTop: "10px" }}
                    icon={item.library ? "cancel" : "add"}
                  ></Button>
                }
                content={
                  item.library ? "Remove As Template" : "Add To Template"
                }
                position="top center"
              />

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: `${
                        item.library ? "var(--color-red)" : "var(--color-blue)"
                      }`,
                    }}
                    circular
                    onClick={() => enableShare(item.slug)}
                    // style={{ marginTop: "10px" }}
                    icon={item.share ? "paper plane" : "hide"}
                  ></Button>
                }
                content={
                  item.share ? "Disable Form Share" : "Enable Form Share"
                }
                position="top center"
              />

              {/* <FacebookShareButton
                disabled={item.share ? false : true}
                children={
                  <Button
                    circular
                    icon="facebook f"
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "blue",
                    }}
                  ></Button>
                }
                url={`${window.location.origin}/website/${item.slug}`}
              /> */}

              <Popup
                content="Link copied to clipboard."
                mouseEnterDelay={500}
                mouseLeaveDelay={500}
                on="click"
                trigger={
                  <Button
                    disabled={item.share ? false : true}
                    icon="copy"
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                    // style={{ marginTop: "10px" }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        window.location.host + "/website/" + item.slug
                      )
                    }
                    circular
                  ></Button>
                }
              />

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "var(--color-blue)",
                    }}
                    circular
                    as={Link}
                    to={`/formbuilder/data/${item.slug}`}
                    icon="eye"
                    // style={{ marginTop: "10px" }}
                  ></Button>
                }
                content="View Filled Data"
                position="top center"
              />

              <Popup
                trigger={
                  <Button
                    circular
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "grey",
                    }}
                    as={Link}
                    to={`/formbuilder/datatable/${item.slug}`}
                    icon="eye dropper"
                  ></Button>
                }
                content="View Filled Data in Datatable"
                position="top right"
              />

              {item.fulcrum_id ? (
                <Popup
                  trigger={
                    <Button
                      circular
                      className="formbuilder-buttons"
                      style={{
                        backgroundColor: "white",
                        color: "teal",
                      }}
                      icon="sync"
                      onClick={() => {
                        setFulcrumImporting(true);
                        setTextLink(item.fulcrum_data_share_link || "");
                        setApiToken(item.fulcrum_api_token || "");
                        setFulcrumFormId(item.fulcrum_id || "");
                        // @ts-ignore
                        setFulcrumFormList([
                          {
                            key: item.fulcrum_id || "",
                            value: item.fulcrum_id || "",
                            text: item.name + " (" + item.slug + ") (Default)",
                          },
                        ]);
                        setOpenFulcrum(true);
                        setTimeout(() => {
                          processFulcrum(
                            item.fulcrum_data_share_link || "",
                            item.fulcrum_api_token || "",
                            item.fulcrum_id || ""
                          );
                        }, 500);
                      }}
                    ></Button>
                  }
                  content="Sync with Fulcrum Form & Record"
                  position="top right"
                />
              ) : (
                ""
              )}

              {item.kobo_id ? (
                <Popup
                  trigger={
                    <Button
                      circular
                      className="formbuilder-buttons"
                      style={{
                        backgroundColor: "white",
                        color: "teal",
                      }}
                      icon="sync"
                      onClick={() => {
                        if (item.kobo_base_url) {
                          setKoboBaseUrl(item.kobo_base_url || koboBaseUrl);
                        }
                        setKoboApiToken(item.kobo_api_token || "");
                        if (item.kobo_id) {
                          // @ts-ignore
                          setKoboFormList([
                            {
                              key: item.kobo_id,
                              value: item.kobo_id,
                              text: item.kobo_id + " (ID Auto Loaded)",
                            },
                          ]);
                        }
                        setKoboFormId(item.kobo_id || "");
                        setTimeout(() => {
                          setOpenKobo(true);
                          processKobo(
                            item.kobo_base_url || koboBaseUrl,
                            item.kobo_api_token || "",
                            item.kobo_id || ""
                          );
                        }, 250);
                      }}
                    ></Button>
                  }
                  content="Sync with KoboToolbox Data"
                  position="top right"
                />
              ) : (
                ""
              )}
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
    // eslint-disable-next-line
  }, [formbuilders]);

  return [
    <div key={1}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          {" "}
          <FormattedMessage id="Formbuilder" defaultMessage="Formbuilder" />
        </Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
      />

      <Modal
        size="small"
        open={openImport}
        onClose={() => setOpenImport(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Import form using excel</h4>
            <Button
              onClick={() => {
                window.open(
                  `${AppConfig.apiUrl.replace("api", "")}static/bUXn1gEZ.xlsx`
                );
              }}
              className="createButton"
            >
              Download Sample
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              type="file"
              name="excel"
              label="Upload Excel file"
              onChange={handleFileChange}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button className="clearButton" onClick={() => setOpenImport(false)}>
            Cancel
          </Button>
          <Button className="submitButton" onClick={() => processExcel()}>
            Import
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openImportGSheet}
        onClose={() => setOpenImportGSheet(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Import form using Google Spreadsheet Public URL</h4>
            <Button
              onClick={() => {
                window.open(
                  `${AppConfig.apiUrl.replace("api", "")}static/bUXn1gEZ.xlsx`
                );
              }}
              className="createButton"
            >
              Download Sample
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              name="url"
              label="Google Sheet Public URL"
              onChange={(e) => setGSheetUrl(e.target.value)}
              placeholder="https://docs.google.com/spreadsheets/d/1234ABCD/edit"
              required
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            onClick={() => setOpenImportGSheet(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitButton"
            onClick={() => importGSheet()}
            loading={importGSheetLoading}
          >
            Import
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataImport}
        onClose={() => setOpenDataImport(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Import data using excel</Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              type="file"
              name="excel"
              label="Upload Excel file"
              onChange={handleFileChange}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            onClick={() => setOpenDataImport(false)}
          >
            <Icon name="trash" /> Cancel
          </Button>
          <Button
            className="submitButton"
            onClick={() => processExcelForData()}
          >
            <Icon name="checkmark" /> Import Form
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openFulcrum}
        onClose={() => {}}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Import Fulcrum Form & Data</h4>
          </div>
        </Modal.Header>
        <Modal.Content>
          {/* <Form.Field>
            <Form.Input
              fluid
              type="text"
              name="fulcrum"
              label="Enter Fulcrum Data Share Link (.json)"
              placeholder="https://web.fulcrumapp.com/shares/・・・・・・・・・.json"
              onChange={handleLinkChange}
              disabled={fulcrumImporting}
              value={textlink}
              required
            />
          </Form.Field> */}
          <br />
          <Form.Field>
            <Form.Input
              fluid
              type="text"
              name="api_token"
              label="Enter Fulcrum API Token"
              placeholder="・・・・・・・・・・・・・・・・・・・・・・・・・・・"
              onChange={(e) => {
                setApiToken(e.target.value);
              }}
              disabled={fulcrumImporting}
              value={api_token}
              required
            />
          </Form.Field>
          <br />
          <Form.Field>
            <Form.Select
              fluid
              label="Fulcrum Form"
              placeholder="Choose the Fulcrum Form to Import"
              search
              selection
              options={fulcrumFormList}
              loading={fulcrumFormListLoading}
              disabled={
                fulcrumFormListLoading ||
                fulcrumImporting ||
                fulcrumFormList.length <= 0
              }
              onChange={(e, { value }) => {
                // @ts-ignore
                setFulcrumFormId(value);
              }}
              value={fulcrumFormId || ""}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            disabled={fulcrumImporting}
            loading={fulcrumImporting}
            onClick={() => setOpenFulcrum(false)}
          >
            <Icon name="trash" /> Cancel
          </Button>
          <Button
            className="submitButton"
            disabled={fulcrumImporting || !fulcrumFormId || !api_token}
            loading={fulcrumImporting}
            onClick={() => processFulcrum(textlink, api_token, fulcrumFormId)}
          >
            <Icon name="checkmark" /> Import Form
          </Button>
        </Modal.Actions>
        <b className="custom-alert-message">
          Keep this page open until the import process is done.
          <br />
          It might take some time depending on total records and files.
          <br />
          {timer && timerCount > 0
            ? "Pending: " + timer.format("HH:mm:ss")
            : ""}
          {totalImported ? (
            <h4>
              <br />
              Total Imported: {totalImported}
            </h4>
          ) : (
            ""
          )}
        </b>
      </Modal>

      <Modal
        size="small"
        open={openKobo}
        onClose={() => {}}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Import KoboToolbox Form & Data</h4>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              type="text"
              label="KoboToolbox API Base URL"
              placeholder="https://kc.kobotoolbox.org"
              onChange={(e) => setKoboBaseUrl(e.target.value)}
              disabled={koboImporting}
              value={koboBaseUrl}
              required
            />
          </Form.Field>
          <br />
          <Form.Field>
            <Form.Input
              fluid
              type="text"
              label="Enter Kobo API Token"
              placeholder="・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・"
              onChange={(e) => {
                setKoboApiToken(e.target.value);
              }}
              disabled={koboImporting}
              value={koboApiToken}
              required
            />
          </Form.Field>
          <br />
          <Form.Field>
            <Form.Select
              fluid
              label="KoboToolbox Form"
              placeholder="Choose the KoboToolbox Form to Import"
              options={koboFormList}
              loading={koboFormListLoading}
              disabled={
                koboFormListLoading || koboImporting || koboFormList.length <= 0
              }
              onChange={(e, { value }) => {
                // @ts-ignore
                setKoboFormId(value);
              }}
              value={koboFormId || ""}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            disabled={koboImporting}
            loading={koboImporting}
            onClick={() => setOpenKobo(false)}
          >
            <Icon name="trash" /> Cancel
          </Button>
          <Button
            className="submitButton"
            disabled={
              koboImporting || !koboFormId || !koboApiToken || !koboBaseUrl
            }
            loading={koboImporting}
            onClick={() => processKobo(koboBaseUrl, koboApiToken, koboFormId)}
          >
            <Icon name="checkmark" /> Import Form
          </Button>
        </Modal.Actions>
        <b className="custom-alert-message">
          Keep this page open until the import process is done.
          <br />
          It might take some time depending on total records and files.
          <br />
          {timer && timerCount > 0
            ? "Pending: " + timer.format("HH:mm:ss")
            : ""}
          {totalImported ? (
            <h4>
              <br />
              Total Imported: {totalImported}
            </h4>
          ) : (
            ""
          )}
        </b>
      </Modal>
    </div>,

    <div className="app-card-org" key={3}>
      <Button.Group className="flex flex-wrap">
        <Button as={Link} to="/formbuilder/create" className="createButton">
          New Form
        </Button>

        <Button className="importButton" onClick={() => importForm()}>
          <Icon name="file excel outline" color="green" /> Import Form Excel
        </Button>

        <Button
          className="importButton"
          onClick={() => setOpenImportGSheet(true)}
        >
          <Icon name="table" color="green" /> Import Form Google Sheet
        </Button>

        <Button as={Link} to="/formbuilder/meta" className="createButton">
          <Icon name="plus square outline" color="red" /> Manage Meta Data
          Fields
        </Button>

        <Button className="importButton" onClick={() => importFormFulcrum()}>
          <Icon name="chevron circle up" color="red" /> Import from Fulcrum
        </Button>

        <Button
          className="importButton"
          onClick={() => {
            setOpenKobo(true);
            setTotalImported(0);
          }}
        >
          <Icon name="mobile alternate" color="blue" /> Import from KoboToolbox
        </Button>

        {/* <Button primary as={Link} to="/formbuilder/create">
          <FormattedMessage id="New" defaultMessage="New" /> &nbsp;
          <FormattedMessage id="Form" defaultMessage="Form" />
        </Button>

        <Button.Or />
        <Button positive onClick={() => importForm()}>
          Import
        </Button> */}
      </Button.Group>

      <Header>
        <FormattedMessage id="Form" defaultMessage="Form" />{" "}
        <FormattedMessage id="List" defaultMessage="List" />
        {organization_id ? (
          <FormattedMessage
            id="organization_id_filter"
            defaultMessage={
              " / Filtering By Organization: " +
              organization_id +
              (" (" +
                (formbuilders?.organization?.[organization_id] ?? "*") +
                ")")
            }
          />
        ) : (
          ""
        )}
        {project_id ? (
          <FormattedMessage
            id="project_id_filter"
            defaultMessage={
              " / Filtering By Project: " +
              project_id +
              (" (" + (formbuilders?.projects?.[project_id] ?? "*") + ")")
            }
          />
        ) : (
          ""
        )}
        {module_id ? (
          <FormattedMessage
            id="module_id_filter"
            defaultMessage={
              " / Filtering By Module: " +
              module_id +
              (" (" + (formbuilders?.modules?.[module_id] ?? "*") + ")")
            }
          />
        ) : (
          ""
        )}
        {filterFlag ? (
          <FormattedMessage
            id="filter_flag_id"
            defaultMessage={" / Filtering: " + filterFlag}
          />
        ) : (
          ""
        )}
        {organization_id || project_id || module_id || filterFlag ? (
          <Button
            size="mini"
            circular
            compact
            basic
            icon="close"
            onClick={() => clearFilters({ updateApiUrl: true })}
            title="Clear Filter"
            style={{
              padding: "4px",
              fontSize: "1em",
              marginLeft: "10px",
              marginTop: "-3px",
            }}
          />
        ) : (
          ""
        )}
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right"
          size="small"
          icon="search"
          value={search}
          loading={loading}
          placeholder="Search Name or #Tags ..."
        />
      </Header>

      <br />

      {formbuilders.forms === undefined ? (
        <Loader active inverted size="big" />
      ) : (
        <RenderList />
      )}

      <Grid>
        <Grid.Column>
          <div style={{ marginRight: "5px", display: "inline-block" }}>
            <p style={{ display: "inline-block" }}>Per Page: </p>
            <Dropdown
              options={[
                { key: 5, text: "5", value: 5 },
                { key: 10, text: "10", value: 10 },
                { key: 15, text: "15", value: 15 },
                { key: 25, text: "25", value: 25 },
                { key: 50, text: "50", value: 50 },
                { key: 100, text: "100", value: 100 },
              ]}
              placeholder="Per Page"
              compact
              selection
              onChange={onPerPageChange}
              style={{ marginLeft: "5px" }}
              value={perPage}
            />
          </div>

          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
  ];
}
