import React, { useEffect, useState } from "react";
import { Segment, Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { AppConfig } from "../../../config";
import { Formio } from "@formio/react";

import { useSelector, useDispatch } from "react-redux";
import { fetchData, unloadData } from "../../../redux/action/allAction";
import { FormattedMessage } from "react-intl";
import moment from "moment";

export default function ViewFormData(props: {
  match: { params: { slug: string; id: any } };
  history: any;
}) {
  const data_details = useSelector((state: any) => state.all_ind);
  const [parsedData, setParsedData] = useState<any>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchData(
        `/formData/${props.match.params.slug}/edit/${props.match.params.id}`
      )
    );

    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data_details.data !== undefined) {
      let data = JSON.parse(data_details.data);
      setParsedData(data);
      formio(data);
    }

    // eslint-disable-next-line
  }, [data_details]);

  const goBack = () => {
    props.history.goBack();
  };

  const formio = (data: any) => {
    //console.log({data})
    Formio.createForm(
      document.getElementById("formio"),
      `${AppConfig.apiUrl}/formBuilders/${props.match.params.slug}`,
      {
        readOnly: true,
      }
    ).then(function (form: {
      submission: { data: any };
      nosubmit: boolean;
      on: (arg0: string, arg1: (submission: any) => void) => void;
    }) {
      form.submission = {
        data: data,
      };
      // Prevent the submission from going to the form.io server.
      form.nosubmit = true;
    });
  };

  return (
    <React.Fragment>
      <div key={1}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>

          {/* <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section link as={Link} to="/formbuilder">Formbuilder</Breadcrumb.Section>

                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section link as={Link} to={`/formbuilder/data/${props.match.params.slug}`}>Formbuilder Data</Breadcrumb.Section> */}
          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>Formbuilder Data View</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <div className="app-card-org" key={2}>
        {data_details &&
          parsedData &&
          data_details.data &&
          data_details.form &&
          data_details.form.is_feedback_form === true &&
          data_details.form.slug === "rhiab-feedback-master-form" && (
            <div className="p-4 mb-2 text-center bg-gray-100">
              <h5 className="text-xl font-bold text-center">
                Feedback Form Submitted By:
              </h5>
              <p className="m-0">
                <b>User Name:</b> {parsedData._feedback_user_name ?? "-"}
              </p>
              <p className="m-0">
                <b>User Email:</b> {parsedData._feedback_user_email ?? "-"}
              </p>
              <p className="m-0">
                <b>Project:</b> {parsedData._feedback_project_slug ?? "-"}
              </p>
              <p className="m-0">
                <b>Organization:</b>{" "}
                {parsedData._feedback_organization_slug ?? "-"}
              </p>
              <p className="m-0">
                <b>Submitted:</b>{" "}
                {parsedData.created_at
                  ? moment.utc(parsedData.created_at).fromNow()
                  : "-"}
              </p>
            </div>
          )}

        <div id="formio"></div>
      </div>
    </React.Fragment>
  );
}
