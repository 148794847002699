import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const appPointTypes = {
  APP_POINT_LOADED: "APP_POINT_LOADED",
  APP_POINT_UNLOADED: "APP_POINT_UNLOADED",
  ADD_APP_POINT: "ADD_APP_POINT",
  EDIT_APP_POINT: "EDIT_APP_POINT",
  DELETE_APP_POINT: "DELETE_APP_POINT",
};

export const loadAppPointPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: appPointTypes.APP_POINT_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAppPointPage = () => {
  return {
    type: appPointTypes.APP_POINT_UNLOADED,
    payload: {},
  };
};

export const addAppPoint = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/app_point", payload).then(
      (data) => {
        dispatch({
          type: appPointTypes.ADD_APP_POINT,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAppPoint = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/app_point/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: appPointTypes.EDIT_APP_POINT,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAppPoint = (id: number, onSuccess: Function, onError: Function) => {
  // console.log('Remove App Point Action Reached');
  return async (dispatch: any) => {
    apiService._delete(`/app_point/delete/${id}`).then(
      (data) => {
        // console.log(data);
        dispatch({
          type: appPointTypes.DELETE_APP_POINT,
          payload: id,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
