import { tileTypes } from "../action/tileAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case tileTypes.TILE_LOADED:

            return action.payload;

        case tileTypes.TILE_UNLOADED:
            return initialState;
        case tileTypes.ADD_TILE:
           
             return { ...state};
        case tileTypes.EDIT_TILE:

            return state;
        case tileTypes.DELETE_TILE:
           
            return state;

        default:
            return state;
    }
};
