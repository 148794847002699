import React, { useEffect, useRef, useState } from "react";
import { AppConfig } from "../../config";
import {
  fetchFormdata,
  unloadFormdataPage,
} from "../../redux/action/formDataAction";
import { useDispatch, useSelector } from "react-redux";

import ReactDOM from "react-dom";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "./DataList.scss";
import { history } from "../../helper";
import { Header, Button, Checkbox } from "semantic-ui-react";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable = require("datatables.net-fixedcolumns-bs4/js/fixedColumns.bootstrap4");

require("datatables.net-buttons")();
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();

export default function DataListDuplicate(props: { slug: string; id: any }) {
  const formdata = useSelector((state: any) => state.formdata);

  const [user_info, set_user_info] = useState(JSON.parse(localStorage.getItem("user") ?? '{}'));

  const [selected, setSelected] = useState<string[]>([]);

  const dispatch = useDispatch();
  const inputEl = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if(props.slug){
      dispatch(fetchFormdata(`/formBuilder/data/${props.slug}`));
    }

    return () => {
      dispatch(unloadFormdataPage());
      $("#datatable1").find("table").DataTable().destroy(true);
    };
    // eslint-disable-next-line
  }, [props.slug]);

  useEffect(() => {
    if (formdata.field !== undefined) {
      callDatatable();
    }
    // eslint-disable-next-line
  }, [formdata]);

  const SelectData = (event: React.FormEvent<HTMLInputElement>, data: any) => {
    const value = data.checked;

    if (value) {
      setSelected((oldArray) => [...oldArray, data.name]);
    } else {
      setSelected((oldArray) => oldArray.filter((item) => item !== data.name));
    }
  };

  const DeactivateHandler = () => {
    apiService
      .save(`/formData/deactivate`, { toDelete: selected, slug: props.slug })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          $("#datatable1").DataTable().ajax.reload();
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const callDatatable = () => {
    $("#datatable1 thead tr").clone(true).appendTo("#datatable1 thead");
    $("#datatable1 thead tr:eq(1) th").each(function (this: any, i: number) {
      //var title = $(this).text();
      $(this).html(
        '<input class="form-control" type="text" placeholder="Search" />'
      );

      $("input", this).on("keyup change", function (this: HTMLInputElement) {
        if (table1.column(i).search() !== this.value) {
          table1.column(i).search(this.value).draw();
        }
      });
    });

    
    var table1 = $(inputEl.current)
      .on("preXhr.dt", function (e: any, settings: any, data: any) {
        if (settings.ajax.url.indexOf("duplicate") >= 0) {
          $("#duplicate-or-not").html("Showing Duplicate Results Only");
        } else {
          $("#duplicate-or-not").html("");
        }
      })
      .DataTable({
        autoWidth: true,
        lengthMenu: [
          [5, 10, 25, 500, 1000, 1500, -1],
          [5, 10, 25, 500, 1000, 1500, "All"],
        ],
        processing: true,
        serverSide: true,
        scrollX: true,
        responsive: true,
        scrollCollapse: true,

        order: [[2, "desc"]],
        fixedColumns: {
          leftColumns: 1,
        },
        columnDefs: [
          {
            targets: 1,
            createdCell: (
              td: any,
              cellData: any,
              rowData: any,
              row: any,
              col: any
            ) =>
              ReactDOM.render(
                <Checkbox
                  name={cellData.$oid}
                  onChange={SelectData}
                ></Checkbox>,
                td
              ),
          },

          {
            targets: 0,
            data: "id",
            render: function (data: any, type: any, full: any, meta: any) {
              return `<a  style={{ cursor: 'pointer' }} href="javascript:void(null);" >${data.$oid}</a>`;
            },
          },
        ],
        dom:
          "<'row'<'col-sm-12'<'pull-right'B>>>" +
          "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",

        orderCellsTop: true,
        buttons: [
          {
            extend: "collection",
            text: "Export",
            buttons: [
              {
                extend: "copyHtml5",
                exportOptions: {
                  columns: [0, ":visible"],
                },
              },
              {
                extend: "excelHtml5",
                exportOptions: {
                  columns: ":visible",
                },
              },
              {
                extend: "pdfHtml5",
                orientation: "landscape",
                pageSize: "A0",
                customize: function (doc: {
                  pageMargins: number[];
                  defaultStyle: { fontSize: number };
                  styles: { tableHeader: { fontSize: number } };
                  content: { layout: {} }[];
                }) {
                  //Remove the title created by datatTables

                  doc.pageMargins = [20, 60, 20, 30];
                  // Set the font size fot the entire document
                  doc.defaultStyle.fontSize = 7;
                  // Set the fontsize for the table header
                  doc.styles.tableHeader.fontSize = 7;

                  // Change dataTable layout (Table styling)
                  // To use predefined layouts uncomment the line below and comment the custom lines below
                  // doc.content[0].layout = 'lightHorizontalLines'; // noBorders , headerLineOnly
                  var objLayout: any = {};
                  objLayout["hLineWidth"] = function (i: any) {
                    return 0.5;
                  };
                  objLayout["vLineWidth"] = function (i: any) {
                    return 0.5;
                  };
                  objLayout["hLineColor"] = function (i: any) {
                    return "#aaa";
                  };
                  objLayout["vLineColor"] = function (i: any) {
                    return "#aaa";
                  };
                  objLayout["paddingLeft"] = function (i: any) {
                    return 4;
                  };
                  objLayout["paddingRight"] = function (i: any) {
                    return 4;
                  };
                  doc.content[0].layout = objLayout;
                },
              },
            ],
          },
          {
            extend: "colvis",
            text: "Column Visibility",
            collectionLayout: "fixed four-column",
          },
          {
            extend: "colvisGroup",
            text: "Show all",
            show: ":hidden",
          },
        ],
        ajax: {
          url: `${AppConfig.apiUrl}/serverSides/${props.slug}?duplicate=true`,
          type: "POST",
          dataType: "json",
          headers: { Authorization: "Bearer " + user_info.access_token },
          data: {
            fields: formdata.key,
            step: props.id,
            state: "submitted",
            type: "detail",
          },
        },
      });
    $("#datatable1 tbody").on("click", "a", function (this: any) {
      var data = table1.row($(this).parents("tr")).data();
      showDetail(data[0].$oid);
    });
  };


  function showDetail(data: any) {
    history.push(`/formbuilder/data/${props.slug}/view/${data}`);
  }

  function RenderHeader(datas: { data: { field: any[] | undefined } }): any {
    //console.log(datas.data)
    if (datas.data.field === undefined) {
      return <th> Loading.....</th>;
    }
    // console.log(datas.data)
    return datas.data.field.map((data, index) => {
      return <th key={index + 1}> {data}</th>;
    });
  }

  return (
    <div>
      <Header>
        <Button secondary onClick={DeactivateHandler}>
          {" "}
          Deactivate
        </Button>
      </Header>
      <table
        ref={inputEl}
        className="table  table-striped "
        id="datatable1"
        style={{ width: "100%", margin: "5px" }}
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <div className="pretty p-default">
                <div className="state">
                  <label>Check</label>
                </div>
              </div>
            </th>
            <th>Date Received</th>
            <th>Status</th>

            <th>Submitted By</th>
            {formdata && <RenderHeader data={formdata} />}
          </tr>
        </thead>
      </table>
    </div>
  );
}
