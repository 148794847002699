import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Modal,
  Popup,
  Image,
} from "semantic-ui-react";

import bytes from "bytes";

import { useSelector, useDispatch } from "react-redux";
import {
  loadFilesList,
  unloadFilesList,
  addFile,
  removeFile,
} from "../../redux/action/fileAction";
import { alertActions } from "../../redux/action/alertAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { ImageModalComponent } from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";

interface RootState {
  files: {
    data: {
      id: number;
      name: number;
      mime_type: string;
      size: string;
      disk: string;
      url: string;
      path: string;
      organization_id: string;
      user_id: string;
      user?: {
        id: number;
        first_name: string;
      } | null;
      created_at: string;
      updated_at: string;
    }[];
    last_page: number;
    current_page: number;
    total: number;
    per_page: number;
  };
}

export default function Files() {
  const authentication = useSelector((state: any) => state.authentication);

  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      file: null,
    },
  });
  const [open, setOpen] = useState(false);

  const [creating, setCreating] = useState(false);

  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/files");
  const [search, setSearch] = useState<string>("");

  const files = useSelector((state: RootState) => state.files);
  const [total, setTotal] = useState(1);

  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      loadFilesList(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadFilesList());
    };
  }, [apiUrl]);

  useEffect(() => {
    if (files !== undefined) {
      setTotal(files.last_page);
    }
  }, [files]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl("/files?page=" + pageInfo.activePage.toString() + "&q=" + search);
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/files?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        file: null,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let otherValue = {};

    if (result.type === "file") {
      value = event.target.files[0];

      if (!fdata.newData.name || fdata.newData.name === "") {
        otherValue = {
          name: event.target.files[0]?.name,
        };
      }
    }
    let name = result.name;

    setData({
      newData: Object.assign(
        {},
        fdata.newData,
        {
          [name]: value,
        },
        otherValue
      ),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        addFile(
          fdata.newData,
          () => {
            dispatch(
              loadFilesList(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Filed Uploaded Successfully"));
                setOpenInfo(false);
              })
            );
            clearForm();
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      removeFile(
        deleteId,
        () => {
          dispatch(
            loadFilesList(apiUrl, () => {
              dispatch(alertActions.success("File Deleted Successfully"));
              setLoading(false);
              setOpenInfo(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  function RenderList(datas: any) {
    if (!datas?.data) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (
      (Array.isArray(datas.data) && datas.data.length === 0) ||
      datas.data?.data.length === 0
    ) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            <Message>No files found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name ?? "-"}</Table.Cell>
          <Table.Cell>{data.mime_type ?? "-"}</Table.Cell>
          <Table.Cell>{bytes.format(data.size ?? 0)}</Table.Cell>
          <Table.Cell>
            {data.user?.first_name ?? "-"} {data.user?.last_name ?? ""}
          </Table.Cell>
          <Table.Cell>
            {data.updated_at ? new Date(data.updated_at).toLocaleString() : "-"}
          </Table.Cell>

          <Table.Cell>
            <div className="flex justify-between items-center">
              <div>
                <Button
                  as={"a"}
                  target="_blank"
                  href={data.url}
                  download
                  icon
                  circular
                  className="editButton"
                >
                  <Icon name="download" />
                </Button>

                <Popup
                  flowing
                  content="Link Copied to Clipboard 📋"
                  onOpen={() => navigator.clipboard.writeText(data.url)}
                  on="click"
                  trigger={
                    <Button icon circular className="editButton">
                      <Icon name="copy outline" />
                    </Button>
                  }
                />

                <Button
                  onClick={() => deleteData(data.id)}
                  icon
                  circular
                  className="deleteButton"
                >
                  <Icon name="trash" />
                </Button>
              </div>

              <div>
                {/* Show Image Preview */}
                {data.mime_type &&
                  data.url &&
                  data.mime_type.startsWith("image/") && (
                    <Image
                      className="w-16 opacity-90 hover:opacity-100"
                      bordered
                      centered
                      inline
                      src={data.url}
                    />
                  )}
              </div>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Files</Breadcrumb.Section>
      </Breadcrumb>

      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,

    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          if (
            authentication?.user &&
            authentication.user.role === "User" &&
            files.total >= 10
          ) {
            dispatch(
              alertActions.error(
                "You can't upload more than 10 files at the moment. We will increase this limit soon."
              )
            );
            return;
          }

          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Upload File
        <Icon name="plus" className="iconSize" />
      </Button>

      <Header>
        Files
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>Uploaded By</Table.HeaderCell>
            <Table.HeaderCell>Uploaded At</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{files && <RenderList data={files} />}</Table.Body>
      </Table>

      {files?.data === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}

      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      className="modal-customized"
      size="small"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Upload" : "Modify"} File
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form.Field>
          <Form.Input
            fluid
            required
            type="text"
            name="name"
            label="File name"
            value={fdata.newData.name}
            onChange={handleChange}
            placeholder="File Name"
          />
        </Form.Field>

        <Form.Field>
          <Form.Input
            required={fdata.newData.id === 0}
            fluid
            type="file"
            name="file"
            label="Select File"
            onChange={handleChange}
          />
        </Form.Field>
      </Modal.Content>

      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
