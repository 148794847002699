import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Select,
  Modal,
  Label
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadMessagePage,
  unloadMessagePage,
  // addMessage,
  // editMessage,
  removeMessage,
} from "../../redux/action/messageAction";
import { Link } from "react-router-dom";
import ModalComponent, {
  MultipleImageModalComponent,
} from "../../components/Modal";
import { ImageModalComponent } from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  messages: {
    messages: {
      data: {
        id: number;
        app_id: number;
        name: string;
        email: string;
        phone: string;
        message: string;
        photo_1: string;
        photo_2: string;
        photo_3: string;
        related_app_id: [];
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function AwarenessMessage() {
  const [open, setOpen] = useState(false);
  const [messageModalopen, setMessageModalopen] = useState(false);
  const [message, setMessage] = useState("");

  const [url, setUrl] = useState("");
  const [urlArray, setUrlArray] = useState([]);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/message");
  const [search, setSearch] = useState<string>("");
  const messages = useSelector((state: RootState) => state.messages);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [filter, setFilter] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadMessagePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadMessagePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (messages.messages !== undefined) {
      setTotal(messages.messages.last_page);
      var final_stage = messages.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(final_stage);
    }
  }, [messages]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/message?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        "&filter=" +
        filter
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/message?q=" + search + "&filter=" + filter);
    }
  };

  const handleFilterChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    setFilter(value);
    setApiUrl("/message?filter=" + value + "&q=" + search);
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    
    setLoading(true);

    dispatch(removeMessage(deleteId, () => {
      dispatch(loadMessagePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  function RenderList(datas: any) {
    if (datas.data.messages === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.messages.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.messages.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.email ? data.email : "N/A"}</Table.Cell>
          {/* <Table.Cell>{data.message}</Table.Cell> */}
          <Table.Cell>
            <Button
              primary
              onClick={() => {
                setMessageModalopen(true);
                setMessage(data.message);
              }}
              size={"small"}
            >
              View Message
            </Button>
          </Table.Cell>

          <Table.Cell>
            {data.photo_1 ? (
              <Button primary onClick={() => setUrl(data.photo_1)}>View</Button>
            ) : (
              <Label as='a' color='grey'>
                No Image
              </Label>
            )}
          </Table.Cell>
          <Table.Cell>
            {data.photo_2 ? (
              <Button primary onClick={() => setUrl(data.photo_2)}>View</Button>
            ) : (
              <Label as='a' color='grey'>
                No Image
              </Label>
            )}
          </Table.Cell>
          <Table.Cell>
            {data.photo_3 ? (
              <Button primary onClick={() => setUrl(data.photo_3)}>View</Button>
            ) : (
              <Label as='a' color='grey'>
                No Image
              </Label>
            )}
          </Table.Cell>

          <Table.Cell>
            <Button
              className="formbuilder-buttons"
              circular
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
              onClick={() => {
                setUrlArray([]);
                let arr: any = [];
                if (data.photo_1) {
                  //@ts-ignore
                  arr.push(data.photo_1);
                }
                if (data.photo_2) {
                  //@ts-ignore
                  arr.push(data.photo_2);
                }
                if (data.photo_3) {
                  //@ts-ignore
                  arr.push(data.photo_3);
                }
                setUrlArray(arr);
                // console.log(urlArray);
              }}
              icon
            >
              <Icon name="info circle" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Message</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />
      <MultipleImageModalComponent
        setUrlArray={setUrlArray}
        urlArray={urlArray}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Header>
        Message
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-2"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Select
        placeholder="Filter by App"
        multiple
        clearable
        options={appOptions}
        onChange={handleFilterChange}
      />

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Photo 1</Table.HeaderCell>
            <Table.HeaderCell>Photo 2</Table.HeaderCell>
            <Table.HeaderCell>Photo 3</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{messages && <RenderList data={messages} />}</Table.Body>
      </Table>

      {messages.messages === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
    <Modal
      onClose={() => setMessageModalopen(false)}
      onOpen={() => setMessageModalopen(true)}
      open={messageModalopen}
      size="small"
      style={{ position: "relative" }}
    >
      <Modal.Header>
        Recieved Message
        <Icon name="hand point down outline" size={"large"} />
      </Modal.Header>
      <Modal.Content>{message}</Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setMessageModalopen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
