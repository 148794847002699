export const toggleMenu = 'TOGGLE_MENU';
export const toggleModule = 'TOGGLE_MODULE';
export const openMenu = 'OPEN_MENU';
export const closeMenu = 'CLOSE_MENU';

export const sideMenuAction = {
  toggleSideMenu: () => ({ type: toggleMenu }),
  ModuleChange: (payload: string) => ({
    type: toggleModule,
    payload: payload
  }),
  openMenu: () => ({ type: openMenu }),
  closeMenu: () => ({ type: closeMenu }),
};


export const sideMenuChange = () => {
  return {
    type: toggleMenu
  };
}





