import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Popup,
  Input,
  Modal,
} from "semantic-ui-react";
import { AppConfig } from "../../config";

import { useSelector, useDispatch } from "react-redux";
import {
  loadPlannerPage,
  unloadPlannerPage,
  addPlanner,
  editPlanner,
  removePlanner,
} from "../../redux/action/plannerAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services/api.service";
import { alertActions } from "../../redux/action/alertAction";
import moment from "moment";
import { startCase } from "lodash";
interface RootState {
  planners: {
    planners: {
      data: {
        id: number;
        title: string;
        name: string;
        user_id: string;
        project_id: number;
        data: string;
        slug: string;
        form_id: string;
        data_id: string;
        post_processing_dll: string;
      }[];
      last_page: number;
    };
    projects: {
      id: number;
      name: string;
    }[];
    forms: {
      name: string;
      slug: string;
    }[];
  };
}

type OptionType = {
  key: string | number;
  value: string | number;
  text: string;
}[];

export default function Planner() {
  localStorage.setItem("api_url", AppConfig.apiUrl);
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      title: "",
      project_id: 0,
      name: "",
      user_id: "",
      share: false,
      form_id: "",
      data_id: "",
      post_processing_dll: null,
      has_post_processing_dll: false,
    },
  });
  const [open, setOpen] = useState(false);
  const [appBundleConfirm, setAppBundleConfirm] = useState(false);
  const [creating, setCreating] = useState(false);
  //const [url, setUrl] = useState("");
  const [deleteId, setDeleteId] = useState<number>(0);

  const [workItemTriggerConfirm, setWorkItemTriggerConfirm] = useState(false);
  const [workItemTriggeringFor, setWorkItemTriggeringFor] = useState<object>({});

  const [workItemPostProcessTriggerConfirm, setWorkItemPostProcessTriggerConfirm] = useState(false);
  const [workItemPostProcessTriggeringFor, setWorkItemPostProcessTriggeringFor] = useState<object>({});

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/planner");
  const [search, setSearch] = useState<string>("");
  const planners = useSelector((state: RootState) => state.planners);
  const [total, setTotal] = useState(1);
  const [projectOptions, setProjectOptions] = useState<OptionType>([]);
  const [formOptions, setFormOptions] = useState<OptionType>([]);
  const [dataOptions, setDataOptions] = useState<OptionType>([]);

  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading]: any = useState(false);
  const forge_viewer_url =
    process.env.NODE_ENV === "production"
      ? "https://viewer.bctap.net"
      : "http://localhost:3333";


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadPlannerPage(apiUrl));

    return () => {
      dispatch(unloadPlannerPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (planners.planners !== undefined) {
      setTotal(planners.planners.last_page);

      if (planners.projects !== undefined) {
        var projects = planners.projects.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.name,
          };
        });
        setProjectOptions(projects);
      }

      if (planners.forms !== undefined) {
        let forms = planners.forms.map((data) => {
          return {
            key: data.slug,
            value: data.slug,
            text: data.name,
          };
        });

        setFormOptions(forms);
      }
    }
  }, [planners]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/planner?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/planner?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        title: "",
        project_id: 0,
        name: "",
        user_id: "",
        share: false,
        form_id: "",
        data_id: "",
        post_processing_dll: null,
        has_post_processing_dll: false
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let planner = planners.planners.data.find((v) => v.id === id);

    if (planner?.form_id) {
      getFormData(planner?.form_id);
    }
    setData({
      newData: {
        id: planner!.id,
        title: planner!.title,
        project_id: planner!.project_id,
        name: planner!.name,
        user_id: planner!.user_id,
        share: planner!.slug ? true : false,
        form_id: planner!.form_id,
        data_id: planner!.data_id,
        post_processing_dll: null,
        has_post_processing_dll: planner!.post_processing_dll ? true : false
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;
    if (name === "form_id") {
      getFormData(value);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const getFormData = (value: string) => {
    apiService.getAll(`/GetData/form/${value}`).then((data) => {
      var final = data.map(
        (
          data: { _id?: { $oid: string }; _unique_id?: string; _mainLabel?:string },
          index: number
        ) => {
          return {
            key: index,
            value: data._id ? data._id.$oid || "" : "",
            text: (data._mainLabel)??(data._id ? data._id.$oid || "" : ""),
          };
        }
      );
      setDataOptions(final);
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        addPlanner(
          fdata.newData,
          () => {
            clearForm();
            setCreating(true);
            setOpenInfo(false);
            dispatch(
              loadPlannerPage(apiUrl, false, false, () => {
                setLoading(false);
              })
            );
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        editPlanner(
          fdata.newData,
          () => {
            clearForm();
            setCreating(true);
            setOpenInfo(false);
            dispatch(
              loadPlannerPage(apiUrl, false, false, () => {
                setLoading(false);
              })
            );
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);
    dispatch(
      removePlanner(deleteId, false, false, () => {
        dispatch(
          loadPlannerPage(apiUrl, false, false, () => {
            setLoading(false);
          })
        );
      })
    );
    setOpen(false);
  };

  const design_automation_upload = () => {
    setLoading(true);
    apiService.getAll(`/revit-design-automation-upload`).then((data) => {
      dispatch(
        loadPlannerPage(apiUrl, false, false, () => {
          setLoading(false);
        })
      );
      dispatch(alertActions.success(data.message || "Autodesk Design Automation Setup Complete"));
    }).catch((err) => {
      dispatch(alertActions.error(err.message || "Failed to Upload AppBundle & Set-Up Cloud environment."));
    }).finally(() => {
      setAppBundleConfirm(false)
      setLoading(false)
    });
  };

  const design_automation_trigger = () => {
    // @ts-ignore
    if(workItemTriggeringFor && workItemTriggeringFor.slug) {
      // @ts-ignore
      if(workItemTriggeringFor.last_automation_triggered_at) {
        // @ts-ignore
        if(moment().diff(moment(workItemTriggeringFor.last_automation_triggered_at), "minute") < 10) {
          dispatch(alertActions.error(`Work Item for this Planner was triggered recently. Please wait at least 10 minutes.`));
          return;
        }
      }
      setLoading(true);
      // @ts-ignore
      apiService.getAll(`/revit-design-automation-trigger/${workItemTriggeringFor.slug}`).then((data) => {
        dispatch(alertActions.success("Work Item triggered & responded successfully."));
      }).catch((err) => {
        dispatch(alertActions.success(`Work Item triggered. Output Status: ${err}`));
      }).finally(() => {
        setWorkItemTriggerConfirm(false)
        setWorkItemTriggeringFor({})
        dispatch(
          loadPlannerPage(apiUrl, false, false, () => {
            setLoading(false);
          })
        );
      });
    } else {
      setWorkItemTriggerConfirm(false);
      setLoading(false)
      dispatch(alertActions.error("Sorry, Planner Identifier was not available."));
    }
  };

  const design_automation_post_process_trigger = () => {
    // @ts-ignore
    if(workItemPostProcessTriggeringFor && workItemPostProcessTriggeringFor.slug) {
      // @ts-ignore
      if(workItemPostProcessTriggeringFor.post_processing_last_automation_triggered_at) {
        // @ts-ignore
        if(moment().diff(moment(workItemPostProcessTriggeringFor.post_processing_last_automation_triggered_at), "minute") < 10) {
          dispatch(alertActions.error(`Post Processing Work Item for this Planner was triggered recently. Please wait at least 10 minutes.`));
          return;
        }
      }
      setLoading(true);
      // @ts-ignore
      apiService.getAll(`/revit-design-automation-post-process-trigger/${workItemPostProcessTriggeringFor.slug}`).then((data) => {
        dispatch(alertActions.success("Post Process Work Item triggered & responded successfully."));
      }).catch((err) => {
        dispatch(alertActions.success(`Post Process Work Item triggered. Output Status: ${err}`));
      }).finally(() => {
        setWorkItemPostProcessTriggerConfirm(false)
        setWorkItemPostProcessTriggeringFor({})
        dispatch(
          loadPlannerPage(apiUrl, false, false, () => {
            setLoading(false);
          })
        );
      });
    } else {
      setWorkItemPostProcessTriggerConfirm(false);
      setLoading(false)
      dispatch(alertActions.error("Sorry, Planner Identifier was not available."));
    }
  };

  const design_automation_status = (slug:string, work_item_id:string) => {
    setLoading(true);
    apiService.getAll(`/revit-design-automation-status/${slug}/${work_item_id}`).then((data) => {
      dispatch(alertActions.success(`Automation was successfully. Work Item Status: ${data.message}`));
    }).catch((err) => {
      dispatch(alertActions.success(`Work Item Output Status: ${err}`));
    }).finally(() => {
      dispatch(
        loadPlannerPage(apiUrl, false, false, () => {
          setLoading(false);
        })
      );
    });
  };

  const design_automation_post_process_status = (slug:string, work_item_id:string) => {
    setLoading(true);
    apiService.getAll(`/revit-design-automation-post-process-status/${slug}/${work_item_id}`).then((data) => {
      dispatch(alertActions.success(`Post Process Automation was successfully. Work Item Status: ${data.message}`));
    }).catch((err) => {
      dispatch(alertActions.success(`Post Process Work Item Output Status: ${err}`));
    }).finally(() => {
      dispatch(
        loadPlannerPage(apiUrl, false, false, () => {
          setLoading(false);
        })
      );
    });
  };

  function RenderList(datas: any) {
    if (datas.data.planners === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.planners.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.planners.data.map((data: any) => {
      let project = datas.data.projects.find(
        (a: any) => a.id === data.project_id
      );
      return (
        <Table.Row id={"planner_" + data.id} key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell id={"planner_title_" + data.id}>{data.title}</Table.Cell>
          <Table.Cell>
            {(data.name ? data.name : "") +
              (data.name && data.user_id ? " / " : "") +
              (data.user_id ? data.user_id : "")}
          </Table.Cell>
          <Table.Cell>{project ? project.name : ""}</Table.Cell>
          <Table.Cell id={"planner_drawn_" + data.id}>
            {data.has_content ? <i>Yes</i> : <i>No</i>}
          </Table.Cell>

          <Table.Cell>
            {/* <Popup
              trigger={
                <Button
                  primary
                  size="mini"
                  as={Link}
                  to={`/planner/${data.id}`}
                  icon
                >
                  <Icon name="info circle" />
                </Button>
              }
              content="Readonly View Plan"
              position="top center"
            /> */}
            <Popup
              trigger={
                <Button
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-green)",
                  }}
                  as={"a"}
                  href={`/plan/?id=${data.id}`}
                  target={"_blank"}
                  icon
                >
                  <Icon name="home" />
                </Button>
              }
              content="Edit Plan 🔗"
              position="top center"
            />
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>

            <Popup
              content="Link copied to clipboard."
              mouseEnterDelay={500}
              mouseLeaveDelay={500}
              on="click"
              trigger={
                <Button
                  className="formbuilder-buttons"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  disabled={data.slug ? false : true}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      localStorage.getItem("api_url") + "/share/p/" + data.slug
                    )
                  }
                >
                  JSON
                </Button>
              }
            />

            <Popup
              content="Link copied to clipboard."
              mouseEnterDelay={500}
              mouseLeaveDelay={500}
              on="click"
              trigger={
                <Button
                  disabled={data.slug ? false : true}
                  className="formbuilder-buttons"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      localStorage.getItem("api_url") +
                        "/share/p/" +
                        data.slug +
                        "?format=xml"
                    )
                  }
                >
                  XML
                </Button>
              }
            />

            <Popup
              content="View Planner Thumbnail Image"
              mouseEnterDelay={0}
              mouseLeaveDelay={200}
              on="hover"
              trigger={
                <Button
                  disabled={data.photo ? false : true}
                  className="formbuilder-buttons"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  href={data.photo}
                  target="_blank"
                  icon="eye"
                ></Button>
              }
            />

            {/* ==== PRE ==== */}
            <div className={data.rvt_file ? "table-btn-group" : "d-inline-block"}>
              <Popup
                content={"Send to Autodesk Revit Design Automation & Generate Revit File." + (!data.slug ? " (Please enable Data Sharing for this to work)" :"")}
                mouseEnterDelay={0}
                mouseLeaveDelay={200}
                on="hover"
                trigger={
                  <Button
                    disabled={data.slug ? false : true}
                    className="formbuilder-buttons"
                    circular
                    style={{
                      backgroundColor: "white",
                      color: "var(--color-blue)",
                    }}
                    onClick={() => {
                      setWorkItemTriggeringFor(data)
                      setWorkItemTriggerConfirm(true)
                    }}
                    icon="cloud upload"
                  ></Button>
                }
              />
              {
                data.rvt_file ? (
                  <>
                    <Popup
                      content="Download Generated Revit File"
                      mouseEnterDelay={0}
                      mouseLeaveDelay={200}
                      on="hover"
                      trigger={
                        <Button
                          disabled={data.rvt_file ? false : true}
                          className="formbuilder-buttons"
                          circular
                          style={{
                            backgroundColor: "white",
                            color: "var(--color-blue)",
                          }}
                          href={
                            data.rvt_file
                          }
                          target="_blank"
                          icon="cloud download"
                        ></Button>
                      }
                    />
                    <Popup
                      trigger={
                        <Button
                          circular
                          disabled={data.slug ? false : true}
                          className="formbuilder-buttons"
                          style={{
                            backgroundColor: "white",
                            color: "var(--color-blue)",
                          }}
                          href={
                            forge_viewer_url+"/upload.html?slug=" +
                            data.slug +
                            "&name=" +
                            data.title +
                            "&type=before" +
                            "&api_url=" +
                            AppConfig.apiUrl +
                            "&token=" +
                            (JSON.parse(localStorage.getItem("user") || "{}")
                              .access_token || "") +
                            "&rvt_file=" +
                            data.rvt_file
                          }
                          target={"_blank"}
                          icon
                        >
                          <Icon name="cube" /> <Icon name="hourglass zero" />
                        </Button>
                      }
                      content={
                        data.viewer_3d
                          ? "Re-generate 3D View (Before)"
                          : "Generate 3D View (Before)"
                      }
                      position="top center"
                    />
                  </>
                ) : ""
              }
            </div>

            {/* ==== POST ==== */}
            {
              data.post_processing_dll ? (
                <div className={data.post_processing_rvt_file ? "table-btn-group table-btn-group-light" : "d-inline-block"}>
                  <Popup
                    content={"Post-Process Revit file with uploaded .dll" + (!data.slug ? " (Please enable Data Sharing for this to work)" :"")}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={200}
                    on="hover"
                    trigger={
                      <Button
                        disabled={data.slug ? false : true}
                        className="formbuilder-buttons"
                        circular
                        style={{
                          backgroundColor: "white",
                          color: "var(--color-blue)",
                        }}
                        onClick={() => {
                          setWorkItemPostProcessTriggeringFor(data)
                          setWorkItemPostProcessTriggerConfirm(true)
                        }}
                        color="vk"
                        icon="cloud upload"
                      ></Button>
                    }
                  />
                  {
                    data.post_processing_rvt_file ? (
                      <>
                        <Popup
                          content="Download Generated After Revit File (Post Processed)"
                          mouseEnterDelay={0}
                          mouseLeaveDelay={200}
                          on="hover"
                          trigger={
                            <Button
                              disabled={data.post_processing_rvt_file ? false : true}
                              className="formbuilder-buttons"
                              circular
                              style={{
                                backgroundColor: "white",
                                color: "var(--color-blue)",
                              }}
                              href={
                                data.post_processing_rvt_file
                              }
                              target="_blank"
                              color="vk"
                              icon="cloud download"
                            ></Button>
                          }
                        />
                        <Popup
                          trigger={
                            <Button
                              circular
                              disabled={(data.slug && data.viewer_3d) ? false : true}
                              className="formbuilder-buttons"
                              style={{
                                backgroundColor: "white",
                                color: "var(--color-blue)",
                              }}
                              href={
                                forge_viewer_url+"/upload.html?slug=" +
                                data.slug +
                                "&name=" +
                                data.title +
                                "&type=after" +
                                "&api_url=" +
                                AppConfig.apiUrl +
                                "&token=" +
                                (JSON.parse(localStorage.getItem("user") || "{}")
                                  .access_token || "") +
                                "&rvt_file=" +
                                data.post_processing_rvt_file
                              }
                              target={"_blank"}
                              icon
                            >
                              <Icon name="cube" /> <Icon name="check circle outline" />
                            </Button>
                          }
                          content={
                            data.viewer_3d_after
                              ? "Re-generate 3D View (After)"
                              : "Generate 3D View (After)"
                          }
                          position="top center"
                        />
                      </>
                    ) : ""
                  }
                </div>
              ) : ""
            }

            {
              data.viewer_3d || data.viewer_3d_after ? (
                <Popup
                  content={
                    "View Planner in 3D" +
                    (data.viewer_3d_after ? " (Before & After)" : " (Before)")
                  }
                  mouseEnterDelay={0}
                  mouseLeaveDelay={200}
                  on="hover"
                  trigger={
                    <Button
                      disabled={data.viewer_3d ? false : true}
                      className="formbuilder-buttons"
                      circular
                      style={{
                        backgroundColor: "white",
                        color: "var(--color-blue)",
                      }}
                      href={
                        data.viewer_3d_after
                          ? forge_viewer_url+"/viewer.html?urn=" +
                            data.viewer_3d +
                            "&urn_after=" +
                            data.viewer_3d_after +
                            "&slug=" +
                            data.slug
                          : forge_viewer_url+"/viewer.html?urn=" +
                            data.viewer_3d +
                            "&slug=" +
                            data.slug
                      }
                      target="_blank"
                    >
                      3D
                    </Button>
                  }
                />
              ):""
            }
          </Table.Cell>
          <Table.Cell>
            {
              data.work_item_id ? (
                <Popup
                  content={"Click to Re-Check Work Item Status"}
                  mouseEnterDelay={0}
                  mouseLeaveDelay={200}
                  on="hover"
                  trigger={
                    <Button style={{padding: "5px"}} disabled={!data.slug} size="tiny" color={data.work_item_status === "success" ? "green" : (data.work_item_status.indexOf("failed") >= 0 ? "red" : "black")} onClick={() => {
                      if(data.work_item_status === "success") {
                        dispatch(alertActions.success(`Automation was successfully. Work Item Status: ${data.work_item_status}`));
                      }
                      else if(data.work_item_status.indexOf("failed") >= 0) {
                        dispatch(alertActions.error(`Automation Failed. Work Item Status: ${data.work_item_status}`));
                      } else {
                        design_automation_status(data.slug, data.work_item_id)
                      }
                    }}>PRE: {startCase(data.work_item_status).toUpperCase()}</Button>
                  }
                />
              ) : (
                <Button style={{padding: "5px"}} disabled={!data.slug} size="tiny" color="teal">PRE: NOT RAN</Button>
              )
            }
            {
              data.post_processing_rvt_file ? (
                data.post_processing_work_item_id ? (
                  <Popup
                    content={"Click to Re-Check Post Processing Work Item Status"}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={200}
                    position="top right"
                    on="hover"
                    trigger={
                      <Button style={{padding: "5px"}} disabled={!data.slug} size="tiny" color={data.post_processing_work_item_status === "success" ? "green" : (data.post_processing_work_item_status.indexOf("failed") >= 0 ? "red" : "black")} onClick={() => {
                        if(data.post_processing_work_item_status === "success") {
                          dispatch(alertActions.success(`Post Processing Automation was successfully. Work Item Status: ${data.post_processing_work_item_status}`));
                        }
                        else if(data.post_processing_work_item_status.indexOf("failed") >= 0) {
                          dispatch(alertActions.error(`Post Processing Automation Failed. Work Item Status: ${data.post_processing_work_item_status}`));
                        } else {
                          design_automation_post_process_status(data.slug, data.post_processing_work_item_id)
                        }
                      }}>POST: {startCase(data.post_processing_work_item_status).toUpperCase()}</Button>
                    }
                  />
                ) : (
                  <Button style={{padding: "5px"}} disabled={!data.slug} size="tiny" color="teal">POST: NOT RAN</Button>
                )
               ) : ''
            }
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Planners</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
      />
      <ModalComponent
        open={appBundleConfirm}
        setOpen={setAppBundleConfirm}
        confirmDelete={design_automation_upload}
        task={"Upload"}
        loading={loading}
      />
      <ModalComponent
        open={workItemTriggerConfirm}
        setOpen={setWorkItemTriggerConfirm}
        confirmDelete={design_automation_trigger}
        task={"Trigger"}
        loading={loading}
      />
      <ModalComponent
        open={workItemPostProcessTriggerConfirm}
        setOpen={setWorkItemPostProcessTriggerConfirm}
        confirmDelete={design_automation_post_process_trigger}
        task={"(Post Process) Trigger"}
        loading={loading}
      />

      <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create Planner
          <Icon name="plus" className="iconSize" />
        </Button>
        <Button
          className="createButton"
          secondary
          icon
          onClick={() => {
            setAppBundleConfirm(true)
          }}
          labelPosition="left"
        >
          Update Revit Design Automation AppBundle
          <Icon name="upload" className="iconSize" />
        </Button>
        <Header>
          Planners
          <Input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={onSearch}
            className="pull-right small mb-3"
            icon="search"
            placeholder="Search..."
          />
        </Header>

        <Table basic="very" unstackable striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Homeowner</Table.HeaderCell>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Drawn</Table.HeaderCell>

              <Table.HeaderCell>Actions</Table.HeaderCell>
              <Table.HeaderCell>Revit Automation Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{planners && <RenderList data={planners} />}</Table.Body>
        </Table>

        {planners.planners === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column>
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>

      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="tiny"
        className="modal-customized"
      >
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Planner
        </Modal.Header>
        <Modal.Content className="modalContent">
          <Form>
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="title"
                  label="Planner Title"
                  value={fdata.newData.title}
                  onChange={handleChange}
                  placeholder="Enter Planner Title.."
                />
              </Form.Field>

              <Form.Field>
                <Form.Select
                  fluid
                  placeholder="Select Project"
                  required
                  options={projectOptions}
                  label="Project"
                  value={fdata.newData.project_id}
                  name="project_id"
                  onChange={handleChange}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  type="text"
                  name="name"
                  label="Name"
                  value={fdata.newData.name}
                  onChange={handleChange}
                  placeholder="Name"
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  type="text"
                  min="1"
                  name="user_id"
                  label="Homeowner Id"
                  value={fdata.newData.user_id}
                  onChange={handleChange}
                  placeholder="Homeowner Id"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <Form.Select
                  fluid
                  placeholder="Select Form"
                  required
                  options={formOptions}
                  label="Form"
                  value={fdata.newData.form_id}
                  name="form_id"
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <Form.Select
                  fluid
                  placeholder="Select Application"
                  required
                  search
                  options={dataOptions}
                  label="Application"
                  value={fdata.newData.data_id}
                  name="data_id"
                  onChange={handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <Form.Input
                  fluid
                  type="file"
                  name="post_processing_dll"
                  label={`${fdata.newData.has_post_processing_dll ? 'Update' : 'Provide'} Post Processing Revit Add-in DLL (For Design Automation)`}
                  onChange={handleChange}
                  accept=".dll"
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Checkbox
                toggle
                label={{ children: "Generate Share Link" }}
                name="share"
                checked={fdata.newData.share}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
