import React, { useEffect, useState } from "react";

import {
  Breadcrumb,
  Button,
  Icon,
  Step,
  Modal,
  Form,
  Select,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { Formio } from "@formio/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormdata,
  unloadFormdataPage,
} from "../../redux/action/formDataAction";
import { AppConfig } from "../../config";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { getLanguageName } from "../../helper/languages";

export default function FormbuilderFill(props: any) {
  const dispatch = useDispatch();
  const history = useHistory<any>();
  const formdata = useSelector((state: any) => state.formdata);
  const [latitude, setlatitude] = useState<number>(0.0);
  const [longitude, setlongitude] = useState<number>(0.0);
  const [openPdfExport, setOpenPdfExport] = useState<boolean>(false);
  const [multiple, setMultiple] = useState<number>(1);
  const [exportLanguage, setExportLanguage] = useState<string>("en");
  const [relatedForm, setRelatedForm] = useState([]);
  const [slug, setSlug] = useState<string>(props.match.params.slug);
  const [dataId, setDataId] = useState<string>("");

  const [translations, setTranslations] = useState<any>([]);
  const [language, setLanguage] = useState<string>("en");
  const [formInstance, setFormInstance] = useState<any>(null);

  useEffect(() => {
    if (history.location.state !== undefined) {
      let { id } = history.location.state;

      if (id !== undefined) {
        setDataId(id);
        checkRelatedForm(id);
      }
    } else {
      checkRelatedForm(dataId);
    }
    return () => {
      dispatch(unloadFormdataPage());
    };
  }, []);

  useEffect(() => {
    if (formdata.data_id !== undefined) {
      if (dataId === "") {
        setDataId(formdata.data_id);
        checkRelatedForm(formdata.data_id);
      } else {
        checkRelatedForm(dataId);
      }
    } else {
    }
  }, [formdata]);

  const checkRelatedForm = (id: string) => {
    apiService
      .getAll(
        `/formBuilder/findRelated/${props.match.params.slug}${
          id ? "/" + id : ""
        }`
      )
      .then(
        (data) => {
          if (data.length === 0) {
            if (latitude === 0 && longitude === 0) {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                  showPosition(props.match.params.slug, "", null, position);
                });
              }
            } else {
              formio(props.match.params.slug, "", null);
            }
          } else {
            setRelatedForm(data);
            let first = data[0] ?? [];
            if (first["pid"] !== undefined) {
              id = first["pid"];

              setDataId(id);
            }

            let final = data.find(
              (element: any) => element.status === "Active"
            );
            if (final !== undefined) {
              setSlug(final.slug);

              if (latitude === 0 && longitude === 0) {
                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition(function (position) {
                    showPosition(final.slug, id, final.data, position);
                  });
                }
              } else {
                formio(final.slug, id, final.data);
              }
            } else {
              //
            }
          }

          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const showPosition = (
    data_slug: string,
    id: string,
    data: any,
    position: any
  ) => {
    setlatitude(position.coords.latitude);
    setlongitude(position.coords.longitude);

    formio(
      data_slug,
      id,
      data,
      position.coords.latitude,
      position.coords.longitude
    );
  };
  const formio = (
    slug_temp: string,
    data_id: string,
    data: any,
    lat?: number,
    long?: number
  ) => {
    apiService
      .getAll(`/formBuilders/${slug_temp}/translations`)
      .then((translationResponse) => {
        setTranslations(translationResponse);

        let firstFoundLanguage = Object.keys(translationResponse)?.[0] ?? "en";
        let lastFormLanguage =
          localStorage.getItem("_form_language") ?? firstFoundLanguage;

        if (Object.keys(translationResponse).includes(lastFormLanguage)) {
          setLanguage(lastFormLanguage);
        } else {
          lastFormLanguage = firstFoundLanguage;
          setLanguage(firstFoundLanguage);
        }

        Formio.createForm(
          document.getElementById("formio"),
          `${AppConfig.apiUrl}/formBuilders/${slug_temp}`,
          {
            saveDraft: true,
            language: lastFormLanguage,
            i18n: translationResponse,
          }
        ).then(function (form: any) {
          setFormInstance(form);

          // Prevent the submission from going to the form.io server.
          if (data === undefined) data = null;
          if (data !== null) {
            form.submission = {
              data: data,
            };
          }

          form.nosubmit = true;

          // Triggered when they click the submit button.
          form.on("submit", function (submission: any) {
            if (lat) {
              submission["data"]["_latitude"] = lat;
              submission["data"]["_longitude"] = long;
            } else {
              submission["data"]["_latitude"] = latitude;
              submission["data"]["_longitude"] = longitude;
            }
            if (data === null && data_id !== "") {
              submission["data"]["_related_id"] = data_id;
            }

            dispatch(addFormdata(slug_temp, submission));
            if (data === null && data_id === "") {
              // checkRelatedForm(data_id);
              formio(slug_temp, data_id, null);
            }
          });
        });
      });
  };

  const rememberLastLanguage = (language: string) => {
    localStorage.setItem("_form_language", language);
  };

  const handleResetForm = () => {
    setDataId("");

    setSlug(props.match.params.slug);

    checkRelatedForm("");
  };

  const handleExportForm = () => {
    setOpenPdfExport(false);
    apiService
      .getfile(
        `/form/exportFormPdf/${props.match.params.slug}?multiple=${multiple}&language=${exportLanguage}`
      )
      .then(
        (data) => {
          setTimeout(() => {
            window.open(data);
          }, 200);

          // Download the blob PDF file
          let link = document.createElement("a");
          link.href = data;
          link.download = `${props.match.params.slug}.pdf`;
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const handleStepClick = (item: {
    name: string;
    slug: string;
    status: string;
    id?: string;
    data?: any;
  }) => {
    formio(item.slug, dataId, item.data);
  };

  const RenderRelatedForm = (): any => {
    if (relatedForm.length == 0) return null;

    return relatedForm.map(
      (
        item: {
          name: string;
          slug: string;
          status: string;
          id?: string;
        },
        index: number
      ) => (
        <Step
          active={item.status === "Active"}
          completed={item.status === "Completed" || item.status === "submitted"}
          disabled={item.status === "Disabled"}
          onClick={() => handleStepClick(item)}
          key={index}
        >
          <Step.Content>
            <Step.Title>
              {item.name}
              {dataId !== "" && index == 0 ? "(" + dataId + ")" : ""}
              {item.id ? "(" + item.id + ")" : ""}
            </Step.Title>
            <Step.Description style={{ textTransform: "capitalize" }}>
              {item.status.replace("submitted", "completed")}
            </Step.Description>
          </Step.Content>
        </Step>
      )
    );
  };

  return (
    <React.Fragment>
      <div>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to="/formbuilder">
            Formbuilder
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>Formbuilder Fill</Breadcrumb.Section>
        </Breadcrumb>

        <Button
          color="vk"
          as={Link}
          to={`/formbuilder/draft/${slug}`}
          floated="right"
        >
          <Icon name="vk" /> Draft Form
        </Button>
        <Button
          color="facebook"
          onClick={() => setOpenPdfExport(true)}
          floated="right"
        >
          <Icon name="file pdf" />
          Export to pdf
        </Button>

        <Modal
          size="small"
          open={openPdfExport}
          onClose={() => setOpenPdfExport(false)}
          style={{ position: "relative" }}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between">
              <h4>Export form in pdf format</h4>
            </div>
          </Modal.Header>
          <Modal.Content>
            <Form.Field>
              <Form.Input
                fluid
                type="number"
                name="multiple"
                value={multiple}
                label="Enter maximum number of datagrid to show"
                onChange={(
                  event: React.SyntheticEvent<HTMLElement, Event>,
                  result?: any
                ) => setMultiple(result.value)}
                required
              />
            </Form.Field>

            <br />

            <Form.Field>
              <Form.Select
                fluid
                options={Object.keys(translations ?? { en: {} }).map(
                  (lang) => ({
                    key: lang,
                    value: lang,
                    text: `${lang} - ${getLanguageName(lang, "-")}`,
                  })
                )}
                value={exportLanguage}
                label="Language to use in exported PDF"
                onChange={(e, { value }) => {
                  setExportLanguage(value as any);
                }}
              />
            </Form.Field>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="clearButton"
              onClick={() => setOpenPdfExport(false)}
            >
              Cancel
            </Button>
            <Button className="submitButton" onClick={() => handleExportForm()}>
              Export
            </Button>
          </Modal.Actions>
        </Modal>
        <Button color="twitter" onClick={handleResetForm} floated="right">
          <Icon name="refresh" />
          Reset Form
        </Button>
        <Step.Group ordered widths={3}>
          <RenderRelatedForm />
        </Step.Group>
      </div>

      {/* Translations Language Dropdown */}

      {translations && Object.keys(translations).length > 1 && (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <label style={{ display: "block", margin: 0 }}>Language:</label>
          <Select
            fluid
            options={Object.keys(translations).map((lang) => ({
              key: lang,
              value: lang,
              text: `${lang} - ${getLanguageName(lang, "-")}`,
            }))}
            simple
            compact
            basic
            value={language}
            style={{ zIndex: 5, width: "300px" }}
            onChange={(e, { value }) => {
              if (value) {
                setLanguage(value as any);
                if (formInstance) {
                  formInstance.language = value;
                }

                rememberLastLanguage(value as string);
              }
            }}
          />
        </div>
      )}

      <div className="app-card-org">
        <div id="formio"></div>
      </div>
    </React.Fragment>
  );
}
