import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const boqDrawingTypes = {
  BOQ_DRAWING_LOADED: "BOQ_DRAWING_LOADED",
  BOQ_DRAWING_UNLOADED: "BOQ_DRAWING_UNLOADED",
  ADD_BOQ_DRAWING: "ADD_BOQ_DRAWING",
  EDIT_BOQ_DRAWING: "EDIT_BOQ_DRAWING",
  DELETE_BOQ_DRAWING: "DELETE_BOQ_DRAWING",
};

export const loadBoqDrawingPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: boqDrawingTypes.BOQ_DRAWING_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadBoqDrawingPage = () => {
  return {
    type: boqDrawingTypes.BOQ_DRAWING_UNLOADED,
    payload: {},
  };
};

export const addBoqDrawing = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/boq_drawing", payload).then(
      (data) => {
        dispatch({
          type: boqDrawingTypes.ADD_BOQ_DRAWING,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editBoqDrawing = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/boq_drawing/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: boqDrawingTypes.EDIT_BOQ_DRAWING,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeBoqDrawing = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/boq_drawing/delete/${id}`).then(
      (data) => {
        dispatch({
          type: boqDrawingTypes.DELETE_BOQ_DRAWING,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
