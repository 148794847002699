import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
 
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Select,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadFaqPage,
  unloadFaqPage,
  addFaq,
  editFaq,
  removeFaq,
} from "../../redux/action/faqAction";
import { alertActions } from "../../redux/action/alertAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";
interface RootState {
  faqs: {
    faqs: {
      data: {
        id: number;
        app_id: number;
        tile_id: number;
        awareness_module_id: number;
        question: string;
        question_lang: string;
        answer: string;
        answer_lang: string;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      name: string;
    }[];
    tiles: {
      id: number;
      name: string;
      awareness_module_id: number;
    }[];
    awareness_modules: {
      id: number;
      name: string;
      app_id: number;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type tileOptionType = {
  key: string | number;
  value: number;
  text: string;
}[][];

export default function Faq() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      question: "",
      question_lang: "",
      answer: "",
      answer_lang: "",
    },
  });

  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/faq");
  const [search, setSearch] = useState<string>("");
  const faqs = useSelector((state: RootState) => state.faqs);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [awarenessModuleOptions, setAwarenessModuleOptions] =
    useState<tileOptionType>([]);
  const [tileOptions, setTileOptions] = useState<tileOptionType>([]);
  const [filter, setFilter] = useState<number[]>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputList, setInputList] = useState([
    { id: 0, app_id: 0, awareness_module_id: 0, tile_id: 0 },
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadFaqPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadFaqPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (faqs.faqs !== undefined) {
      setTotal(faqs.faqs.last_page);
    }
  }, [faqs]);
  useEffect(() => {
    getData();

    return () => {
      //
    };
    // eslint-disable-next-line
  }, []);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/faq?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        "&filter=" +
        filter
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/faq?q=" + search + "&filter=" + filter);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,

        question: "",
        question_lang: "",
        answer: "",
        answer_lang: "",
      },
    });

    setInputList([{ id: 0, app_id: 0, awareness_module_id: 0, tile_id: 0 }]);
  };

  const getData = () => {
    apiService.getAll(`/faq/create/faq`).then(
      (data) => {
        let final_awareness_module: any = [];
        let final_tiles: any = [];
        let final_stage = data.apps.map((app: any) => {
          final_awareness_module[app.id] = [];
          // eslint-disable-next-line
          app.awareness_module.map((mod: any, index: number) => {
            final_tiles[mod.id] = [];
            // eslint-disable-next-line
            mod.tiles.map((tile: any, index1: number) => {
              final_tiles[mod.id][index1] = {
                key: tile.id,
                value: tile.id,
                text: tile.name,
              };
            });

            final_awareness_module[app.id][index] = {
              key: mod.id,
              value: mod.id,
              text: mod.name,
            };
          });

          return {
            key: app.id,
            value: app.id,
            text: app.app_name,
          };
        });

        setAppOptions(final_stage);

        setAwarenessModuleOptions(final_awareness_module);
        setTileOptions(final_tiles);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const GetEditData = (id: number) => {
    apiService.getAll(`/faq/edit/${id}`).then(
      (data) => {
        let final = data.map((item: any) => {
          return {
            app_id: item.app_id,
            awareness_module_id: item.awareness_module_id,
            tile_id: item.tile_id,
            id: item.id,
          };
        });

        setInputList(final);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const selectDataForEditing = (id: number) => {
    let faq = faqs.faqs.data.find((v) => v.id === id);
    GetEditData(id);
    setData({
      newData: {
        id: faq!.id,
        question: faq!.question,
        question_lang: faq!.question_lang,
        answer: faq!.answer,
        answer_lang: faq!.answer_lang,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;

    if (result.type === "file") {
      value = event.target.files[0];
    }

    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addFaq(fdata.newData, inputList, () => {
      dispatch(loadFaqPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editFaq(fdata.newData, inputList, () => {
      dispatch(loadFaqPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
        
    setLoading(true);

    dispatch(removeFaq(deleteId, () => {
      dispatch(loadFaqPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  const handleFilterChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    setFilter(value);
    setApiUrl("/faq?filter=" + value + "&q=" + search);
  };

  // handle input change
  const handleInputChange =
    (index: number) =>
    (event: React.SyntheticEvent<HTMLElement, Event>, result: any) => {
      let value = result.value;
      let name = result.name;
      const list = [...inputList];
      list[index][name] = value;

      setInputList(list);
    };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { id: 0, app_id: 0, awareness_module_id: 0, tile_id: 0 },
    ]);
  };

  const AddNewFaq = (): any => {
    return inputList.map(
      (
        data: { app_id: number; awareness_module_id: number; tile_id: number },
        index: number
      ) => {
        return (
          <Form.Group widths={3} key={index}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                options={appOptions}
                label="App"
                value={data.app_id}
                name="app_id"
                onChange={handleInputChange(index)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Awareness Module"
                required
                disabled={
                  awarenessModuleOptions[data.app_id] !== undefined &&
                  awarenessModuleOptions[data.app_id].length <= 0
                    ? true
                    : false
                }
                options={
                  awarenessModuleOptions[data.app_id] !== undefined
                    ? awarenessModuleOptions[data.app_id]
                    : []
                }
                label="Awareness Module"
                value={data.awareness_module_id}
                name="awareness_module_id"
                onChange={handleInputChange(index)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Tile"
                required
                disabled={
                  tileOptions[data.awareness_module_id] !== undefined &&
                  tileOptions[data.awareness_module_id].length <= 0
                    ? true
                    : false
                }
                options={
                  tileOptions[data.awareness_module_id] !== undefined
                    ? tileOptions[data.awareness_module_id]
                    : []
                }
                label="Tile"
                value={data.tile_id}
                name="tile_id"
                onChange={handleInputChange(index)}
              />
            </Form.Field>

            {inputList.length !== 1 && (
              <Button
                icon="minus"
                type="button"
                onClick={() => handleRemoveClick(index)}
              />
            )}
            {inputList.length - 1 === index && (
              <Button
                icon="plus"
                color="green"
                floated="right"
                type="button"
                onClick={handleAddClick}
              />
            )}
          </Form.Group>
        );
      }
    );
  };

  function RenderList(datas: any) {
    if (datas.data.faqs === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.faqs.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.faqs.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.app ? data.app.app_name : ""}</Table.Cell>
          <Table.Cell>
            {data.awareness_module ? data.awareness_module.name : ""}
          </Table.Cell>
          <Table.Cell>{data.tile ? data.tile.name : ""}</Table.Cell>
          <Table.Cell>{data.question}</Table.Cell>
          <Table.Cell>{data.question_lang}</Table.Cell>
          <Table.Cell>{data.answer}</Table.Cell>
          <Table.Cell>{data.answer_lang}</Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>FAQs</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        <Icon name="plus" className="iconSize" />
        Create FAQs
      </Button>
      <Header>
        FAQs
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small"
          icon="search"
          placeholder="Search..."
        />
      </Header>
      <Select
        placeholder="Filter by App"
        multiple
        clearable
        options={appOptions}
        onChange={handleFilterChange}
      />

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>Awareness Module</Table.HeaderCell>
            <Table.HeaderCell>Tile</Table.HeaderCell>
            <Table.HeaderCell>Question</Table.HeaderCell>
            <Table.HeaderCell>Question Lang.</Table.HeaderCell>
            <Table.HeaderCell>Answer</Table.HeaderCell>
            <Table.HeaderCell>Answer Lang.</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{faqs && <RenderList data={faqs} />}</Table.Body>
      </Table>

      {faqs.faqs === undefined || loading ? <Loader active inverted size="big" /> : ""}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="large"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} FAQ
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <AddNewFaq />

          <br />

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="question"
                label="Question"
                value={fdata.newData.question}
                onChange={handleChange}
                placeholder="Question"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="question_lang"
                label="Question (In Preferred Language)"
                value={fdata.newData.question_lang}
                onChange={handleChange}
                placeholder="Question (In Preferred Language)"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="answer"
                label="Answer"
                value={fdata.newData.answer}
                onChange={handleChange}
                placeholder="Answer"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="answer_lang"
                label="Answer (In Preferred Language)"
                value={fdata.newData.answer_lang}
                onChange={handleChange}
                placeholder="Answer (In Preferred Language)"
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
