import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const boqReportTypes = {
  BOQ_REPORT_LOADED: "BOQ_REPORT_LOADED",
  BOQ_REPORT_UNLOADED: "BOQ_REPORT_UNLOADED",
  ADD_BOQ_REPORT: "ADD_BOQ_REPORT",
  EDIT_BOQ_REPORT: "EDIT_BOQ_REPORT",
  DELETE_BOQ_REPORT: "DELETE_BOQ_REPORT",
};

export const loadBoqReportPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: boqReportTypes.BOQ_REPORT_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadBoqReportPage = () => {
  return {
    type: boqReportTypes.BOQ_REPORT_UNLOADED,
    payload: {},
  };
};

export const addBoqReport = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/boq_report", payload).then(
      (data) => {
        dispatch({
          type: boqReportTypes.ADD_BOQ_REPORT,
          payload: data,
        });
        dispatch(alertActions.success("Data added successfully"));
        return data;
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editBoqReport = (payload: payloadProps) => {
  return async (dispatch: any) => {
    apiService.update(`/boq_report/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: boqReportTypes.EDIT_BOQ_REPORT,
          payload: data,
        });
        dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeBoqReport = (id: number) => {
  return async (dispatch: any) => {
    apiService._delete(`/boq_report/delete/${id}`).then(
      (data) => {
        dispatch({
          type: boqReportTypes.DELETE_BOQ_REPORT,
        });
        dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
