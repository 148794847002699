import { AppConfig } from "../config";
import { authHeader, sesmicHeader } from "../helper";

export const apiService = {
  login,
  logout,
  getAll,
  save,
  save2,
  update,
  save1,
  _delete,
  getfile,
  getfilePost,
  sesmic,
  getToken,
};

const config = {
  apiUrl: AppConfig.apiUrl,
};

function login(email: string, password: string): any {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${config.apiUrl}/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.message !== "error") {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem(
          "formioToken",
          "Bearer " + user.access_token || ""
        );
        localStorage.setItem(
          "sesmicToken",
          "Bearer " + user.sesmic_token || ""
        );
        sessionStorage.setItem("verified", "true");
      }
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("verified");
  localStorage.removeItem("user");
  localStorage.removeItem("formioToken");
  localStorage.removeItem("sesmicToken");
}

function getAll(url: string) {
  const requestOptions: object = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(handleResponse);
}

function getToken(url: string) {
  let headers = authHeader();
  headers["Content-Type"] = "application/json";
  const requestOptions: object = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(AppConfig.clientCred),
  };

  return fetch(
    `${config.apiUrl.replace("/api", "")}${url}`,
    requestOptions
  ).then(handleResponse);
}

function getfile(url: string) {
  const requestOptions: object = {
    crossDomain: true,
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(
    handleResponseFile
  );
}

function getfilePost(url: string, data: any) {
  let headers = authHeader();
  headers["Content-Type"] = "application/json";
  const requestOptions: object = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(
    handleResponseFile
  );
}
function save1(url: string, data: any) {
  let headers = authHeader();
  headers["Content-Type"] = "application/json";

  const requestOptions: object = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(handleResponse);
}

function save2(url: string, data: any) {
  let headers = authHeader();
  //headers['Content-Type'] = "application/json"

  const requestOptions: object = {
    method: "POST",
    headers: headers,
    body: data,
  };

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(handleResponse);
}

function save(url: string, data: any = {}, keepalive: boolean = false) {
  let headers = authHeader();
  let formdata = new FormData();

  Object.keys(data).forEach((key: any) => {
    if (typeof data[key] === "object") {
      if (data[key] instanceof File) {
        formdata.append(key, data[key]);
      } else if (data[key] instanceof FileList) {
        for (let i = 0; i < data[key].length; i++) {
          formdata.append(key, data[key][i]);
        }
      } else {
        if (data[key] && data[key] !== undefined && data[key] !== null) {
          formdata.append(key, JSON.stringify(data[key]));
        }
      }
    } else {
      if (data[key] && data[key] !== "" && data[key] !== null) {
        formdata.append(key, data[key]);
      }
    }
  });

  const requestOptions: object = {
    method: "POST",
    headers: headers,
    body: formdata,
    keepalive: keepalive,
  };

  let fileInputs = document.querySelectorAll("input[type=file]");
  fileInputs.forEach((i: any) => {
    i.value = null;
  });

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(handleResponse);
}

function update(url: string, data: any) {
  let headers = authHeader();
  let formdata = new FormData();

  Object.keys(data).forEach((key: any) => {
    // If Object
    if (typeof data[key] === "object") {
      // Check if file store file directly
      if (data[key] instanceof File) {
        formdata.append(key, data[key]);
      }
      // IF FileList i.e. multiple files
      else if (data[key] instanceof FileList) {
        for (let i = 0; i < data[key].length; i++) {
          formdata.append(key, data[key][i]);
        }
      }
      // Else store objects as string
      else {
        if (data[key] && data[key] !== undefined && data[key] !== null) {
          formdata.append(key, JSON.stringify(data[key]));
        }
      }
    } else {
      // If content are empty string then ignore
      if (data[key] && data[key] !== "" && data[key] !== null) {
        formdata.append(key, data[key]);
      }
    }
  });

  const requestOptions: object = {
    method: "POST",
    headers: headers,
    body: formdata,
  };

  let fileInputs = document.querySelectorAll("input[type=file]");
  fileInputs.forEach((i: any) => {
    i.value = null;
  });

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(handleResponse);
}

function _delete(url: string) {
  const requestOptions: object = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}${url}`, requestOptions).then(handleResponse);
}

function sesmic(url: string, data: any, method: string) {
  let headers = sesmicHeader();

  const requestOptions: object = {
    method: method,
    headers: headers,
    body: data,
  };

  return fetch(`${url}`, requestOptions).then(handleResponse);
}

function handleResponse(response: Response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    //////////
    // CODE TO KEEP LATEST 3 FETCH REQUEST LOGS (SHOW by pressing ` (tilde) anywhere)
    let fetchlog: any[] = [];
    try {
      fetchlog = JSON.parse(
        atob(sessionStorage.getItem("fetchlog") || "") || "[]"
      );
      if (!Array.isArray(fetchlog)) {
        fetchlog = [];
      } // IF sessionStorage was modified badly
    } catch (e) {
      fetchlog = [];
    }
    fetchlog.push({
      data: data,
      status: response.status,
      statusText: response.statusText,
    });

    if (fetchlog.length > 3) {
      fetchlog.shift();
    }

    try {
      sessionStorage.setItem("fetchlog", btoa(JSON.stringify(fetchlog)));
    } catch (e) {
      sessionStorage.setItem("fetchlog", btoa(JSON.stringify([])));
    }

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }
    return data;
  });
}
function handleResponseFile(response: Response) {
  return response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // window.location.reload(true);
      }

      const error = response.statusText;

      return Promise.reject(error);
    }
    return url;
  });
}
