import React from "react";
import { Accordion, Segment } from "semantic-ui-react";
import ImageComponent from "./ImageComponent";
import NormalComponent from "./NormalComponent";
import SignatureComponent from "./SignatureComponent";
import DataGridComponent from "./DataGridComponent";
import SelectBoxComponent from "./SelectBoxComponent";
import CheckBoxComponent from "./CheckBoxComponent";
import ResourceComponent from "./ResourceComponent";
import SelectComponent from "./SelectComponent";
import ContainerComponent from "./ContainerComponent";
import { isArray } from "lodash";
import AddressComponent from "./AddressComponent";
import moment from "moment";

interface Props {
  data: {
    data: { [x: string]: any };
    field: any[];
    steps: {}[];
    form?: {
      is_feedback_form?: boolean;
      component: string;
      components_to_hide?: string[];
      display: string;
      name: string;
      slug: string;
    };
  };
  containers_to_show?: string[];
}

interface RenderProps {
  data: {
    [x: string]: any;
  };
  field?: {
    [x: string]: any;
  };
  components_to_hide?: string[];
  containers_to_show?: string[];
}

export default function FormDataDetail({ data, containers_to_show }: Props) {
  // console.log("data_details", data);
  return (
    <div>
      <div className="mt-0 table-data">
        {data.data && (
          <RenderTableFormDetail
            data={data.data}
            field={data.field}
            components_to_hide={data?.form?.components_to_hide ?? []}
            containers_to_show={containers_to_show}
          />
        )}

        {data &&
          data.data &&
          data.form &&
          data.form.is_feedback_form === true &&
          data.form.slug === "rhiab-feedback-master-form" && (
            <div className="p-4 mb-2 text-center bg-gray-100">
              <h5 className="text-lg font-bold text-center">
                Feedback Form Submitted By:
              </h5>
              <p className="m-0">
                <b>User Name:</b> {data.data._feedback_user_name ?? "-"}
              </p>
              <p className="m-0">
                <b>User Email:</b> {data.data._feedback_user_email ?? "-"}
              </p>
              <p className="m-0">
                <b>Project:</b> {data.data._feedback_project_slug ?? "-"}
              </p>
              <p className="m-0">
                <b>Organization:</b>{" "}
                {data.data._feedback_organization_slug ?? "-"}
              </p>
              <p className="m-0">
                <b>Submitted:</b>{" "}
                {data.data.created_at
                  ? moment.utc(data.data.created_at).fromNow()
                  : "-"}
              </p>
            </div>
          )}
      </div>

      {/* <Table basic="very" unstackable className="formbuilder-report-table">
        <Table.Body>
          {data.data && (
            <RenderTableFormDetail data={data.data} field={data.field} />
          )}
        </Table.Body>
      </Table> */}

      {/* {data && <RenderStepData data={data} />} */}
    </div>
  );
}

/**
 * @deprecated Unused
 */
export const RenderStepData = ({ data }: RenderProps): any => {
  if (data.steps === undefined) {
    return <small className="block p-3">Not Available</small>;
  }
  if (data.data["step_data"] === undefined) {
    return <small className="block p-3">No Steps Record Submitted.</small>;
  }

  return Object.keys(data.steps).map((key, index) => {
    let status = data.steps[key]["status"] || "";

    let step_data =
      typeof data.data["step_data"] === "string"
        ? JSON.parse(data.data["step_data"])
        : data.data["step_data"];

    let current_status_as_x = status.replace(/_[0-9x]+_/, "_x_");
    let stored_statuses_as_x = Object.keys(step_data).map((k) =>
      k.replace(/_[0-9x]+_/, "_x_")
    );
    let is_finance_status =
      data.steps[key]["sub_type"] === "finance" &&
      stored_statuses_as_x.indexOf(current_status_as_x) >= 0;

    let next_tranche_status = null;

    if (is_finance_status) {
      let previous_tranche_number =
        parseInt(status.match(/_([0-9x]+)_/)[1] ?? 0) - 1;

      if (previous_tranche_number >= 0) {
        let previous_tranche_status = current_status_as_x.replace(
          "_x_",
          "_" + previous_tranche_number + "_"
        );
        if (previous_tranche_status in step_data) {
          status = previous_tranche_status;
        }
      }

      let next_tranche_status_guess = current_status_as_x.replace(
        "_x_",
        "_" + (parseInt(status.match(/_([0-9x]+)_/)[1] ?? 0) + 1) + "_"
      );

      if (next_tranche_status_guess in step_data) {
        next_tranche_status = next_tranche_status_guess;
      }
    }

    if (status in step_data) {
      return (
        <Segment attached key={index}>
          <div className="title-primary">
            <h4>{data.steps[key]["name"]} Details</h4>
          </div>

          <div className="table-data">
            {Object.keys(data.steps[key].fields).map((field_key, index1) => {
              let type = data.steps[key].fields[field_key]["type"] || "";
              let api_key = data.steps[key].fields[field_key]["key"] || "";

              let label = data.steps[key].fields[field_key]["label"] || "";
              let options = data.steps[key].fields[field_key]["values"] || [];
              let resource =
                data.steps[key].fields[field_key]["resource"] || "";
              let fld = data.steps[key].fields[field_key]["component"] || [];
              let final_data = step_data[status][api_key] || "";

              switch (type) {
                case "file":
                  return (
                    <ImageComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );
                case "select":
                  return (
                    <SelectComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      resource={resource}
                      options={options}
                    />
                  );
                case "selectboxes":
                  return (
                    <SelectBoxComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      options={options}
                    />
                  );
                case "resource":
                  return (
                    <ResourceComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                case "checkbox":
                  return (
                    <CheckBoxComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                case "datagrid":
                  return (
                    <DataGridComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      field={fld}
                    />
                  );

                case "editgrid":
                  return (
                    <DataGridComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      field={fld}
                    />
                  );

                case "container":
                  return (
                    <ContainerComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      field={fld}
                    />
                  );

                case "signature":
                  return (
                    <SignatureComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                case "address":
                  return (
                    <AddressComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                default:
                  return (
                    <NormalComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );
              }
            })}
          </div>

          {/* {next_tranche_status ? (
            <b>+ 1 more tranche logs available in finance module.</b>
          ) : (
            ""
          )} */}
        </Segment>
      );
    } else {
      return null;
    }
  });
};

/**
 * @deprecated Unused
 */
export const RenderStepDataForFinanceSpecific = ({
  data,
}: RenderProps): any => {
  if (data.steps === undefined) {
    return <small className="block p-3">Not Available</small>;
  }

  if (data.data["step_data"] === undefined) {
    return <small className="block p-3">No Steps Record Submitted.</small>;
  }

  let step_data =
    typeof data.data["step_data"] === "string"
      ? JSON.parse(data.data["step_data"])
      : data.data["step_data"];

  let tranche_steps = {};

  Object.keys(step_data).forEach((k) => {
    if (k.startsWith("tranche_")) {
      tranche_steps[k] = step_data[k];
    }
  });

  if (Object.keys(tranche_steps).length <= 0) {
    return <small className="block p-3">No Steps Record Submitted.</small>;
  }

  let renderAbleStepsInfo = Object.keys(tranche_steps)
    .map((key, index) => {
      let status = key || "";
      let status_as_x = status.replace(/_[0-9x]+_/, "_x_");
      let step = data.steps.find(
        (s: any) => s.status.replace(/_[0-9x]+_/, "_x_") === status_as_x
      );

      if (!step) {
        return "";
      }

      return (
        <Segment attached key={index}>
          <h3 className="m-0">{step.name} Details</h3>
          <small className="font-bold">Status: {status}</small>

          <div className="table-data">
            {Object.keys(step.fields).map((field_key, index1) => {
              let type = step.fields[field_key]["type"] || "";
              let api_key = step.fields[field_key]["key"] || "";

              let label = step.fields[field_key]["label"] || "";
              let options = step.fields[field_key]["values"] || [];
              let resource = step.fields[field_key]["resource"] || "";
              let fld = step.fields[field_key]["component"] || [];
              let final_data = tranche_steps[status][api_key] || "";

              let keysToHide = ["_tranche_instruction"];

              if (keysToHide.indexOf(api_key) >= 0) {
                return null;
              }

              switch (type) {
                case "file":
                  return (
                    <ImageComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );
                case "select":
                  return (
                    <SelectComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      resource={resource}
                      options={options}
                    />
                  );
                case "selectboxes":
                  return (
                    <SelectBoxComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      options={options}
                    />
                  );
                case "resource":
                  return (
                    <ResourceComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                case "checkbox":
                  return (
                    <CheckBoxComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                case "datagrid":
                  return (
                    <DataGridComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      field={fld}
                    />
                  );

                case "editgrid":
                  return (
                    <DataGridComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      field={fld}
                    />
                  );

                case "container":
                  return (
                    <ContainerComponent
                      key={index1}
                      data={final_data}
                      label={label}
                      field={fld}
                    />
                  );

                case "signature":
                  return (
                    <SignatureComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                case "address":
                  return (
                    <AddressComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );

                default:
                  return (
                    <NormalComponent
                      key={index1}
                      data={final_data}
                      label={label}
                    />
                  );
              }
            })}
          </div>
        </Segment>
      );
    })
    .filter((s: any) => !(typeof s === "string" && s === ""));

  if (renderAbleStepsInfo.length <= 0) {
    return <small className="block p-3">No Steps Record Submitted.</small>;
  }

  return renderAbleStepsInfo;
};

export const RenderAllStepData = ({ data }: RenderProps): any => {
  if (data.all_stages === undefined) {
    return <small className="block p-3">Not Available</small>;
  }

  if (data.data["step_data"] === undefined) {
    return <small className="block p-3">No Steps Record Submitted.</small>;
  }

  let step_data =
    typeof data.data["step_data"] === "string"
      ? JSON.parse(data.data["step_data"])
      : data.data["step_data"];

  let matching_step_data = {};

  for (const stage of data.all_stages) {
    // console.log(stage);

    for (const step of stage.step) {
      // console.log(step);

      if (step.type === "details_field") {
        // Compare logic specific for tranche/finance step forms (due to dynamic status string)
        if (step.status.startsWith("tranche_")) {
          let status_as_x = step.status.replace(/_[0-9x]+_/, "_x_");

          Object.keys(step_data).forEach((k) => {
            if (
              k.startsWith("tranche_") &&
              k.replace(/_[0-9x]+_/, "_x_") === status_as_x
            ) {
              matching_step_data[k] = {
                ...step_data[k],
                __step__: step,
                __stage__: stage,
              };
            }
          });
        } else {
          Object.keys(step_data).forEach((k) => {
            if (k === step.status) {
              matching_step_data[k] = {
                ...step_data[k],
                __step__: step,
                __stage__: stage,
              };
            }
          });
        }
      }
    }
  }

  if (Object.keys(matching_step_data).length <= 0) {
    return (
      <small className="block p-3">No Matching Steps Records Found.</small>
    );
  }

  const rendered = Object.keys(matching_step_data).map(
    (status_key: any, index: number) => {
      let data = matching_step_data[status_key];
      // console.log(status_key, data);

      return (
        <Segment
          attached
          key={status_key + data.created_date}
          className="accordion-top-lg"
        >
          <Accordion
            defaultActiveIndex={
              index === Object.keys(matching_step_data).length - 1
                ? 0
                : undefined
            }
            panels={[
              {
                key: status_key + data.created_date,
                title: {
                  content: (
                    <div>
                      <div
                        className={
                          "mb-0 " +
                          (status_key.startsWith("tranche_")
                            ? "title-tertiary"
                            : "title-secondary")
                        }
                      >
                        <h5>{data.__step__.name} Details</h5>
                      </div>

                      <span className="text-xs tracking-widest block pt-0.5 pb-0.5 pl-3 bg-blue-100 rounded-sm">
                        <span className="font-bold">Status: </span> {status_key}
                      </span>
                    </div>
                  ),
                },
                content: {
                  content: (
                    <div className="table-data">
                      {Object.keys(data.__step__.fields).map(
                        (field_key, index1) => {
                          let type =
                            data.__step__.fields[field_key]["type"] || "";
                          let api_key =
                            data.__step__.fields[field_key]["key"] || "";

                          let label =
                            data.__step__.fields[field_key]["label"] || "";
                          let options =
                            data.__step__.fields[field_key]["values"] || [];
                          let resource =
                            data.__step__.fields[field_key]["resource"] || "";
                          let fld =
                            data.__step__.fields[field_key]["component"] || [];
                          let final_data = data[api_key] || "";

                          let keysToHide = ["_tranche_instruction"];

                          if (keysToHide.indexOf(api_key) >= 0) {
                            return null;
                          }

                          switch (type) {
                            case "file":
                              return (
                                <ImageComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                />
                              );
                            case "select":
                              return (
                                <SelectComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                  resource={resource}
                                  options={options}
                                />
                              );
                            case "selectboxes":
                              return (
                                <SelectBoxComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                  options={options}
                                />
                              );
                            case "resource":
                              return (
                                <ResourceComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                />
                              );

                            case "checkbox":
                              return (
                                <CheckBoxComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                />
                              );

                            case "datagrid":
                              return (
                                <DataGridComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                  field={fld}
                                />
                              );

                            case "editgrid":
                              return (
                                <DataGridComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                  field={fld}
                                />
                              );

                            case "container":
                              return (
                                <ContainerComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                  field={fld}
                                />
                              );

                            case "signature":
                              return (
                                <SignatureComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                />
                              );

                            case "address":
                              return (
                                <AddressComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                />
                              );

                            default:
                              return (
                                <NormalComponent
                                  key={index1}
                                  data={final_data}
                                  label={label}
                                />
                              );
                          }
                        }
                      )}
                    </div>
                  ),
                },
              },
            ]}
          />
        </Segment>
      );
    }
  );

  return rendered;
};

/**
 * Filters fields by provided keys only.
 * The keys can be dot noted. E.g. ['assesment', 'assesment.elegibility']
 * Which causes filter to happen recursively.
 */
const filterContainersToShowRecursively = (
  containers_to_show: string[],
  field:
    | {
        [x: string]: any;
      }
    | undefined
) => {
  if (field && containers_to_show && containers_to_show.length > 0) {
    let filtered_field = field
      .filter((f: any) => {
        if (f.type !== "container") {
          return true;
        }
        return containers_to_show.some((s) => f.key.endsWith("_" + s)); // only return chosen container/section
      })
      .map((f: any) => {
        let parent_key = f.key.split("_")[f.key.split("_").length - 1];
        let filtered_containers_to_show = containers_to_show
          .filter((c: string) => c.startsWith(parent_key + "."))
          .map((c: string) => c.replace(parent_key + ".", ""));

        if (f.component && f.component.length > 0) {
          return {
            ...f,
            component: filterContainersToShowRecursively(
              filtered_containers_to_show,
              f.component ?? []
            ),
          };
        }

        return f;
      });

    return filtered_field;
  } else {
    return field;
  }
};

export const RenderTableFormDetail = ({
  data,
  field,
  containers_to_show,
  components_to_hide,
}: RenderProps): any => {
  components_to_hide = components_to_hide ?? [];

  if (data === undefined || field === undefined || data === null) {
    return null;
  }

  let old_wizard = "";
  let new_wizard = "";
  let first = false;

  field = filterContainersToShowRecursively(containers_to_show ?? [], field);

  return (field ?? []).map((value: any, index: number) => {
    let idata = data[value.key] !== undefined ? data[value.key] : null;

    // console.log(components_to_hide, value.key);
    // Hide components that are known to be hidden. (e.g. Fulcrum variables, helpers hidden translation etc.)
    // console.log(components_to_hide, value.key);
    if ((components_to_hide ?? []).some((s) => value.key.endsWith(s))) {
      return null;
    }

    if (idata === null || value.key === "_parent") {
      return null;
    }

    if (value === undefined) return null;
    first = false;
    if (value.wizard !== undefined) {
      new_wizard = value.wizard;
      if (old_wizard !== new_wizard) {
        old_wizard = new_wizard;
        first = true;
      }
    }

    // console.log(value);

    switch (value.type) {
      case "file":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <ImageComponent
              data={idata}
              label={value.label}
              data_name={value.key}
            />
          </div>
        );

      case "select":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <SelectComponent
              data={idata}
              label={value.label}
              resource={value.resource || ""}
              options={value.values || []}
            />
          </div>
        );
      case "selectboxes":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <SelectBoxComponent
              data={idata}
              label={value.label}
              options={value.values || []}
            />
          </div>
        );
      case "resource":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <ResourceComponent data={idata} label={value.label} />
          </div>
        );

      case "checkbox":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <CheckBoxComponent data={idata} label={value.label} />
          </div>
        );

      case "datagrid":
        return (
          <div key={index} className="">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <DataGridComponent
              data={idata}
              label={value.label}
              field={value.component}
              components_to_hide={components_to_hide}
            />
          </div>
        );

      case "editgrid":
        return (
          <div key={index} className="">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <DataGridComponent
              data={idata}
              label={value.label}
              field={value.component}
              components_to_hide={components_to_hide}
            />
          </div>
        );

      case "container":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <ContainerComponent
              data={idata}
              label={value.label}
              field={value.component}
              components_to_hide={components_to_hide}
            />
          </div>
        );

      case "signature":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <SignatureComponent data={idata} label={value.label} />
          </div>
        );

      case "address":
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <AddressComponent data={idata} label={value.label} />
          </div>
        );

      default:
        return (
          <div key={index} className="row-container">
            {first ? (
              <div className="title-primary">
                <h4>{new_wizard}</h4>
              </div>
            ) : (
              ""
            )}
            <NormalComponent
              data={idata}
              label={value.label}
              data_name={value.key}
            />
          </div>
        );
    }
  });
};

export const CheckIndividual = ({
  index,
  type,
  data,
  label,
  resource,
  data_name,
  components_to_hide,
  options,
}: any) => {
  components_to_hide = components_to_hide ?? [];

  if (
    data_name &&
    (components_to_hide ?? []).some((s: any) => data_name.endsWith(s))
  ) {
    return null; // Hide Components
  }

  switch (type) {
    case "file":
      return <ImageComponent key={index} data={data} label={label} />;

    case "select":
      return (
        <SelectComponent
          key={index}
          data={data}
          label={label}
          resource={resource || ""}
          options={options}
        />
      );

    case "selectboxes":
      return (
        <SelectBoxComponent
          key={index}
          data={data}
          label={label}
          options={options}
        />
      );

    case "resource":
      return <ResourceComponent key={index} data={data} label={label} />;

    case "checkbox":
      return <CheckBoxComponent key={index} data={data} label={label} />;

    case "signature":
      return <SignatureComponent key={index} data={data} label={label} />;

    case "address":
      return <AddressComponent key={index} data={data} label={label} />;

    default:
      return <NormalComponent key={index} data={data} label={label} />;
  }
};
