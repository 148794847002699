import {formdataTypes} from '../action/formDataAction'

const initialState:Array<any> =[];
export default (state =initialState, action:any) => {
    switch (action.type) {
     
        case formdataTypes.ADD_FORMDATA:
            return  action.payload ;
        case formdataTypes.FORMDATA_PAGE_UNLOADED:
            return  initialState ;
        case formdataTypes.FETCH_FORMDATA:
            return action.payload;    
        case formdataTypes.DELETE_FORMDATA:
            return state;   
        case formdataTypes.CLEAR_FORMDATA:
            return {};               
        default:
            return state;
    }
};
