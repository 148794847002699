import React, { useState, useEffect } from "react";
import { Menu, Grid, Dropdown, Icon, Label } from "semantic-ui-react";
//import Notification from './Notification'
import { history } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/action/authAction";
import { sideMenuAction } from "../../redux/action/sideMenuAction";
import { IntlContext } from "../../context/IntlContext";
import { Link } from "react-router-dom";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import logoIcon from "../../assets/images/logo-icon.png";
import usaFlag from "../../assets/images/usa-flag.png";
import colombiaFlag from "../../assets/images/colombia-flag.png";

interface Props {
  themeSwitch?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  dark?: boolean;
}
export interface RootState {
  authentication: {
    user: {
      data: {
        first_name: string;
        last_name: string;
        email: string;
        id: number;
      };
      modules: string[];
      role: string;
    };
  };
  sideMenu: { smallMenu: boolean; module?: string };
  dashboard: {
    languages: {
      id: number;
      language: string;
      language_code: string;
      language_logo: string;
    }[];
  };
}

type OptionType = { key: string; text: string; value: string; flag: string }[];
type activeLanguage = { name: string; flag: string; currency: string };

const TopMenu = (props: Props) => {
  const languageFlag = {
    spa: colombiaFlag,
    en: usaFlag,
  };

  //const [languageOptions, setlanguageOptions] = useState<OptionType>([]);
  const [activeLanguage, setActiveLanguage] = useState<activeLanguage>({
    name: "English",
    flag: languageFlag.en,
    currency: "USD",
  });
  const [enableSync, setenableSync] = useState(false);
  const version_detail = "Ver 1.0";

  const { switchLang } = React.useContext(IntlContext);

  const { module } = useSelector((state: RootState) => state.sideMenu);

  const { user } = useSelector((state: RootState) => state.authentication);

  //const { languages } = useSelector((state: RootState) => state.dashboard);

  const dispatch = useDispatch();

  const [displayLanguageDiv, setDisplayLanguageDiv] = useState<Boolean>(false);

  // useEffect(() => {
  //   if (languages !== undefined) {
  //     let lang = languages.map((item) => {
  //       return {
  //         key: item.language_code,
  //         text: item.language,
  //         value: item.language_code,
  //         flag: languageFlag[item.language_code],
  //       };
  //     });
  //     setlanguageOptions(lang);
  //   }
  // }, [languages]);

  useEffect(() => {
    if (user.role !== undefined) {
      if (user.role === "User") {
        setenableSync(true);
      }
    }
  }, [user]);

  const languageChangeHandler = (value?: any) => {
    if (value === "en") {
      setActiveLanguage({
        name: "English",
        flag: languageFlag.en,
        currency: "USD",
      });
    } else if (value === "spa") {
      setActiveLanguage({
        name: "Spanish",
        flag: languageFlag.spa,
        currency: "COP",
      });
    }
    setDisplayLanguageDiv((displayLanguageDiv) => !displayLanguageDiv);
    switchLang(value);
  };

  const sideMenuHandler = () => {
    dispatch(sideMenuAction.toggleSideMenu());
  };

  const ModuleHandler = (module: string) => {
    dispatch(sideMenuAction.ModuleChange(module));
  };

  const logOut = () => {
    dispatch(authActions.logout());
    history.push("/login");
  };

  const syncData = () => {
    apiService.getAll(`/worker/schedule`).then(
      (data) => {
        dispatch(alertActions.success("Sync started successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const MenuRightComponent = () => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <div className="version">
          <p>{version_detail}</p>
        </div>
        <div>
          <Label
            className="formbuilder-buttons"
            style={{ backgroundColor: "white" }}
          >
            <a
              href="/docs"
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Icon name="book" />
            </a>
          </Label>
        </div>

        {/* <div>
          <Label
            className="formbuilder-buttons"
            style={{ backgroundColor: "white" }}
            as={Link}
            to="/guide/"
          >
            <a href="#!" style={{ textDecoration: "none", color: "black" }}>
              <Icon name="question" />
            </a>
          </Label>
        </div> */}

        <div>
          <p>
            <b>{activeLanguage.currency}</b>
          </p>
        </div>
        <div>
          <img
            src={activeLanguage.flag}
            width="30px"
            alt="flag"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDisplayLanguageDiv(
                (displayLanguageDiv) => !displayLanguageDiv
              );
            }}
          />
          <div
            className="languageDiv"
            style={
              displayLanguageDiv ? { display: "block" } : { display: "none" }
            }
          >
            <ul>
              <li onClick={() => languageChangeHandler("en")}>
                <img width="30px" alt="flag" src={usaFlag} /> &nbsp;&nbsp;{" "}
                <span>English (USA)</span>
              </li>

              <li onClick={() => languageChangeHandler("spa")}>
                <img width="30px" alt="flag" src={colombiaFlag} /> &nbsp;&nbsp;{" "}
                <span>Español (CO)</span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <Dropdown
            trigger={
              <div className="display-inline">
                <Icon name="user" size="large" style={{ color: "#9ec74e" }} />{" "}
                &nbsp;
                {user && user.data.first_name} {user && user.data.last_name}
              </div>
            }
            pointing="top right"
          >
            <Dropdown.Menu>
              <Dropdown.Item text={version_detail} disabled />

              <Dropdown.Item as={Link} to="/userProfile" text="Profile" />

              {user.role === "Admin" && (
                <Dropdown.Item as={Link} to="/admin-settings" text="Setting" />
              )}

              <Dropdown.Item
                disabled={enableSync}
                onClick={syncData}
                text="Sync Form Data Received from mobile "
              />

              <Dropdown.Divider />
              <Dropdown.Item onClick={logOut}>
                {" "}
                Logout <Icon name="sign-out" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Grid padded className="tablet computer only">
        <div className="top-menu">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="logo-title" onClick={() => ModuleHandler("main")}>
              <img
                src={logoIcon}
                alt="build change logo icon"
                className="logo-icon"
              />{" "}
              &nbsp;
              <b>BCTAP</b>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => ModuleHandler("main")}
            >
              {module !== "main" ? <Icon name="angle left" /> : null}
            </div>{" "}
            &nbsp;&nbsp;&nbsp;
            <div onClick={sideMenuHandler}>
              <Icon name="bars" />
            </div>
          </div>
          <div>
            <MenuRightComponent />
          </div>
        </div>
      </Grid>

      <Grid padded className="mobile only">
        <Menu borderless fluid fixed="top" className="top-menu">
          <Menu.Item header as={Link} to="/#">
            <div onClick={() => ModuleHandler("main")}>
              <b>BCTAP</b>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => ModuleHandler("main")}
            >
              {module !== "main" ? <Icon name="angle left" /> : null}
            </div>
          </Menu.Item>

          <Menu.Item>
            <div onClick={sideMenuHandler}>
              <Icon name="bars" />
            </div>
          </Menu.Item>

          <Menu.Menu position="right">
            <Menu.Item className="no-border">
              <div>
                <img
                  src={activeLanguage.flag}
                  width="30px"
                  alt="flag"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDisplayLanguageDiv(
                      (displayLanguageDiv) => !displayLanguageDiv
                    );
                  }}
                />
                <div
                  className="languageDiv mobile"
                  style={
                    displayLanguageDiv
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <ul>
                    <li onClick={() => languageChangeHandler("en")}>
                      <img width="30px" src={usaFlag} alt="flag" /> &nbsp;&nbsp;{" "}
                      <span>English (USA)</span>
                    </li>

                    <li onClick={() => languageChangeHandler("spa")}>
                      <img width="30px" src={colombiaFlag} alt="flag" />{" "}
                      &nbsp;&nbsp; <span>Español (CO)</span>
                    </li>
                  </ul>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item className="no-border" position="right">
              <Dropdown
                trigger={
                  <div className="display-inline">
                    <Icon name="user circle outline" size="big" />
                    {user && user.data.first_name} {user && user.data.last_name}
                  </div>
                }
                pointing="top right"
              >
                <Dropdown.Menu>
                  <Dropdown.Item text={version_detail} disabled />
                  <Dropdown.Item as={Link} to="/userProfile" text="Profile" />
                  <Dropdown.Item text="Setting" />
                  <Dropdown.Divider />

                  <Dropdown.Item onClick={logOut}>
                    {" "}
                    Logout <Icon name="sign-out" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </Grid>
    </React.Fragment>
  );
};

export default TopMenu;
