import React from "react";

import {
  Button,
  Icon,
  Table,
  Modal,
  Form,
  List,
  Label,
  Divider,
  Message,
} from "semantic-ui-react";
import { YearInput } from "semantic-ui-calendar-react";
import {
  appendZeroAndALetterToSingleDigitNumber,
  appendZeroToSingleDigitNumber,
} from "../../../../helper";

export default function Stages(props: {
  project: any;
  stages: any;
  refresh: Function;
}) {
  const project = props.project;
  const stages = props.stages;
  const refresh = props.refresh;

  if (stages.length === 0) return <Message>No Stages Found</Message>;

  return (
    <div>
      <List>
        {stages.map((stage: any, index: number) => (
          <List.Item key={stage.id}>
            <List.Icon size="large" name="book" />
            <List.Content
              style={{
                ...(stage.disabled
                  ? {
                      "border-right": "3px solid #ff0000dd",
                      cursor: "not-allowed",
                    }
                  : { "border-right": "3px solid #44ff4455" }),
              }}
              title={stage.disabled ? "This Stage is Disabled" : ""}
            >
              <List.Header>
                {appendZeroToSingleDigitNumber(index)} - {stage.name}
              </List.Header>
              <List.Description>
                {stage.instruction}
                <br />
                <small>
                  <b>Total Stats:</b> {stage.stats?.length ?? 0}
                </small>
                <br />
                <small>
                  <b>Status Choices:</b>{" "}
                  <Label.Group size="mini">
                    {(stage.status_choices ?? []).map((choice: any) => (
                      <Label key={choice}>{choice}</Label>
                    ))}
                  </Label.Group>
                </small>
              </List.Description>

              {!stage.disabled
                ? (stage.step ?? []).map((step: any, step_index: number) => (
                    <List.List key={step.id}>
                      <List.Item>
                        <List.Icon size="large" name="copy outline" />
                        <List.Content>
                          <List.Header>
                            {appendZeroAndALetterToSingleDigitNumber(
                              index,
                              step_index
                            )}{" "}
                            - {step.name}
                          </List.Header>
                          <List.Description>
                            {step.filtering_status &&
                            step.filtering_status.length > 0 ? (
                              <small>
                                <b>Filtering Status:</b>{" "}
                                <Label.Group size="mini">
                                  {(step.filtering_status || "")
                                    .split(",")
                                    .map((status: any) => (
                                      <Label key={status}>{status}</Label>
                                    ))}
                                </Label.Group>
                              </small>
                            ) : (
                              ""
                            )}
                            <small>
                              <b>Status:</b> {step.pre_status}{" "}
                              {step.status ? (
                                <>
                                  <Icon name="arrow right" /> {step.status}
                                </>
                              ) : (
                                ""
                              )}
                            </small>
                            <br />
                            {step.form ? (
                              <small>
                                <b>Form:</b> {step.form.name} ({step.form.slug})
                              </small>
                            ) : (
                              ""
                            )}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List.List>
                  ))
                : ""}
            </List.Content>

            <Divider />
          </List.Item>
        ))}
      </List>
    </div>
  );
}
