import { transactionTypes } from "../action/transactionsActions";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case transactionTypes.TRANSACTION_LOADED:
      return action.payload;
    case transactionTypes.TRANSACTION_UNLOADED:
      return initialState;
    case transactionTypes.ADD_TRANSACTION:
      return { ...state };
    case transactionTypes.EDIT_TRANSACTION:
      return state;
    case transactionTypes.DELETE_TRANSACTION:
      return state;

    default:
      return state;
  }
};
