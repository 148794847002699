import { alertActions } from "./alertAction";
import { apiService } from "../../services";

export const allTypes = {
  SIMPLE_FETCH: "SIMPLE_FETCH",
  SIMPLE_POST: "SIMPLE_POST",
  SIMPLE_DELETE: "SIMPLE_DELETE",
  SIMPLE_UNLOAD: "SIMPLE_UNLOAD",
};

export const simpleFetch = (url: string, onSuccess: Function = () => {}, onError: Function = () => {}) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: allTypes.SIMPLE_FETCH,
          payload: data,
        });
        onSuccess(data);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onError(error);
      }
    );
  };
};

export const simplePost = (url: string, payload: object, onSuccess: Function = () => {}, onError: Function = () => {}, success_message: string = '') => {
  return async (dispatch: any) => {
    apiService.save(url, payload).then(
      (data) => {
        dispatch({
          type: allTypes.SIMPLE_POST,
          payload: data,
        });

        dispatch(alertActions.success((success_message ?? "Data Updated successfully")));
        onSuccess(data);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onError(error);
      }
    );
  };
};

export const simpleDelete = (
  url: string,
  onSuccess: Function = () => {},
  onError: Function = () => {}
) => {
  return async (dispatch: any) => {
    apiService._delete(url).then(
      (data) => {
        dispatch({
          type: allTypes.SIMPLE_DELETE,
          payload: data,
        });

        onSuccess(data);
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError(error);
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const simpleUnload = () => {
  return {
    type: allTypes.SIMPLE_UNLOAD,
    payload: {},
  };
};
