import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadStaticPage,
  unloadStaticPage,
  addStatic,
  editStatic,
  removeStatic,
} from "../../redux/action/staticPageAction";
import { alertActions } from "../../redux/action/alertAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { ImageModalComponent } from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
interface RootState {
  static_pages: {
    static_pages: {
      data: {
        id: number;
        title: string;
        title_lang: string;
        content: string;
        content_lang: string;
        tile_id: number;
        app_id: number;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
    tiles: {
      id: number;
      name: string;
      awareness_module_id: number;
      app_id: number;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function StaticPage() {
  let quillRef: any;
  let quillRefLang: any;
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      title: "",
      title_lang: "",
      content: "",
      content_lang: "",
      tile_id: 0,
      app_id: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [url, setUrl] = useState("");
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/static_page");
  const [search, setSearch] = useState<string>("");
  const static_pages = useSelector((state: RootState) => state.static_pages);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [tileOptions, setTileOptions] = useState<appOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadStaticPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadStaticPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (
      static_pages.static_pages !== undefined &&
      static_pages.apps &&
      static_pages.tiles
    ) {
      setTotal(static_pages.static_pages.last_page);
      var final_stage = static_pages.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(final_stage);

      var final_stage_tile = static_pages.tiles.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setTileOptions(final_stage_tile);
    }
  }, [static_pages]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/static_page?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/static_page?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        title: "",
        title_lang: "",
        content: "",
        content_lang: "",
        tile_id: 0,
        app_id: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let static_pages_item = static_pages.static_pages.data.find(
      (v) => v.id === id
    );
    setData({
      newData: {
        id: static_pages_item!.id,
        title: static_pages_item!.title,
        title_lang: static_pages_item!.title_lang,
        content: static_pages_item!.content,
        content_lang: static_pages_item!.content_lang,
        tile_id: static_pages_item!.tile_id,
        app_id: static_pages_item!.app_id,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;
    if (name === "app_id") {
      let tiles = static_pages.tiles.filter((a: any) => a.app_id === value);
      let final_stage_tile = tiles.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setTileOptions(final_stage_tile);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleContentChange = async (name: string, value: string) => {
    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.tile_id === 0 || fdata.newData.app_id === 0) {
      dispatch(alertActions.error("Select Valid Tile and App also."));
      return false;
    }

    if (fdata.newData.id === 0) await dispatch(addStatic(fdata.newData, () => {
      dispatch(loadStaticPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else await dispatch(editStatic(fdata.newData, () => {
      dispatch(loadStaticPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeStatic(deleteId, () => {
      dispatch(loadStaticPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  function RenderList(datas: any) {
    if (datas.data.static_pages === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (
      !datas.data.static_pages.data ||
      datas.data.static_pages.data.length === 0
    ) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.static_pages.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      let tile = datas.data.tiles.find((a: any) => a.id === data.tile_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.title}</Table.Cell>
          <Table.Cell>{data.title_lang}</Table.Cell>
          <Table.Cell>{tile ? tile.name : ""}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>

          <Table.Cell>
            <Button
              className="formbuilder-buttons"
              circular
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
              as={Link}
              to={`/page/${data.id}`}
              icon
            >
              <Icon name="info circle" />
            </Button>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Static Pages</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Static Page
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Static Pages
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Title Lang</Table.HeaderCell>
            <Table.HeaderCell>Tile</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {static_pages && <RenderList data={static_pages} />}
        </Table.Body>
      </Table>

      {static_pages.static_pages === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="large"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Static Page
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths="2">
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                options={appOptions}
                label="App"
                value={fdata.newData.app_id}
                name="app_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Tile"
                required
                options={tileOptions}
                label="Tile"
                value={fdata.newData.tile_id}
                name="tile_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field style={{ width: "100%" }}>
              <Form.Input
                fluid
                required
                type="text"
                name="title"
                label="Page Title"
                value={fdata.newData.title}
                onChange={handleChange}
                placeholder="Enter Static Page Title..."
              />
            </Form.Field>

            <Form.Field style={{ width: "100%" }}>
              <Form.Input
                fluid
                required
                type="text"
                name="title_lang"
                label="Page Title (In Preferred Language)"
                value={fdata.newData.title_lang}
                onChange={handleChange}
                placeholder="Enter Static Page Title (In Preferred Language)..."
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field className="required" style={{ width: "100%" }}>
              <label>Content</label>
              <ReactQuill
                theme="snow"
                placeholder="Content"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                ]}
                ref={(el) => {
                  quillRef = el;
                }}
                value={fdata.newData.content}
                onChange={(content, delta, source, editor) => {
                  if (source === "user") {
                    handleContentChange("content", content);
                  } else {
                    if (quillRef) {
                      quillRef.getEditor().history.clear();
                    }
                  }
                }}
              />
            </Form.Field>

            <Form.Field className="required" style={{ width: "100%" }}>
              <label>Content (In Preferred Language)</label>
              <ReactQuill
                theme="snow"
                placeholder="Content (In Preferred Language)"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                ]}
                ref={(el) => {
                  quillRefLang = el;
                }}
                value={fdata.newData.content_lang}
                onChange={(content, delta, source, editor) => {
                  if (source === "user") {
                    handleContentChange("content_lang", content);
                  } else {
                    if (quillRefLang) {
                      quillRefLang.getEditor().history.clear();
                    }
                  }
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
