import { galleryTypes } from "../action/galleryAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case galleryTypes.GALLERY_LOADED:
      return action.payload;

    case galleryTypes.GALLERY_UNLOADED:
      return initialState;
    case galleryTypes.ADD_GALLERY:
      return { ...state };
    case galleryTypes.EDIT_GALLERY:
      return state;
    case galleryTypes.DELETE_GALLERY:
      return state;

    default:
      return state;
  }
};
