import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const formbuilderTypes = {
  ADD_FORMBUILDER: "ADD_FORMBUILDER",
  EDIT_FORMBUILDER: "EDIT_FORMBUILDER",
  DELETE_FORMBUILDER: "DELETE_FORMBUILDER",
  FETCH_FORMBUILDER: "FETCH_FORMBUILDER",
  FETCH_FORMBUILDER_DETAIL: "FETCH_FORMBUILDER_DETAIL",
  FORMBUILDER_PAGE_LOADED: "FORMBUILDER_PAGE_LOADED",
  FORMBUILDER_PAGE_UNLOADED: "FORMBUILDER_PAGE_UNLOADED",
};
export const loadFormbuilderPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: formbuilderTypes.FORMBUILDER_PAGE_LOADED,
          payload: data,
        });

        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));

        onFinally();
      }
    );
  };
};

export const fetchFormbuilder = (slug: string) => {
  return async (dispatch: any) => {
    apiService.getAll(`/formBuilder/edit/${slug}`).then(
      (data) => {
        dispatch({
          type: formbuilderTypes.FETCH_FORMBUILDER,
          payload: data,
        });
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadFormbuilderPage = () => {
  return {
    type: formbuilderTypes.FORMBUILDER_PAGE_UNLOADED,
    payload: {},
  };
};

export const editFormbuilder = (payload: object, slug?: string) => {
  return async (dispatch: any) => {
    apiService.save1(`/formBuilder/update/${slug}`, payload).then(
      (data) => {
        dispatch({
          type: formbuilderTypes.EDIT_FORMBUILDER,
          payload: data,
        });
        dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const addFormbuilder = (payload: object) => {
  return async (dispatch: any) => {
    apiService.save1("/formBuilder/store", payload).then(
      (data) => {
        dispatch({
          type: formbuilderTypes.ADD_FORMBUILDER,
          payload: data,
        });
        dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
export const removeFormbuilder = (
  slug: string,
  onSuccess: Function,
  onError: Function
) => {
  return async (dispatch: any) => {
    apiService._delete(`/formBuilder/delete/${slug}`).then(
      (data) => {
        dispatch({
          type: formbuilderTypes.DELETE_FORMBUILDER,
        });
        onSuccess();
        //dispatch(alertActions.success('Data Deleted successfully'))
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
