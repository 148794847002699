import React, { ReactElement } from "react";

interface Props {
  data: {
    url: string;
  }[];
  label: string;
  options: {
    label: string;
    value: string;
  }[];
}

function SelectBoxComponent({ data, label, options }: Props): ReactElement {
  const findInOptions = (data: string | number) => {
    if (!options || options.length <= 0) {
      return data;
    }

    let option = options.find((o) => o.value === data);

    return option?.label ?? data;
  };

  const ListItem = () => (
    <ul className="m-0 ml-3 list-disc list-outside">
      {Object.keys(data).map((key, index) => {
        if (data[key]) {
          return <li key={index}>{findInOptions(key)}</li>;
        } else {
          return null;
        }
      })}
    </ul>
  );

  return (
    <div className="rows" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        <ListItem />
      </div>
    </div>
  );
}

export default SelectBoxComponent;
