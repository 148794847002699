import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const aboutUsTypes = {
  ABOUT_US_LOADED: "ABOUT_US_LOADED",
  ABOUT_US_UNLOADED: "ABOUT_US_UNLOADED",
  ADD_ABOUT_US: "ADD_ABOUT_US",
  EDIT_ABOUT_US: "EDIT_ABOUT_US",
  DELETE_ABOUT_US: "DELETE_ABOUT_US",
};

export const loadAboutUsPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: aboutUsTypes.ABOUT_US_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAboutUsPage = () => {
  return {
    type: aboutUsTypes.ABOUT_US_UNLOADED,
    payload: {},
  };
};

export const addAboutUs = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/aboutus", payload).then(
      (data) => {
        dispatch({
          type: aboutUsTypes.ADD_ABOUT_US,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAboutUs = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/aboutus/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: aboutUsTypes.EDIT_ABOUT_US,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAboutUs = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/aboutus/delete/${id}`).then(
      (data) => {
        dispatch({
          type: aboutUsTypes.DELETE_ABOUT_US,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
