import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const awarenessDocumentTypes = {
  AWARENESS_DOCUMENT_LOADED: "AWARENESS_DOCUMENT_LOADED",
  AWARENESS_DOCUMENT_UNLOADED: "AWARENESS_DOCUMENT_UNLOADED",
  ADD_AWARENESS_DOCUMENT: "ADD_AWARENESS_DOCUMENT",
  EDIT_AWARENESS_DOCUMENT: "EDIT_AWARENESS_DOCUMENT",
  DELETE_AWARENESS_DOCUMENT: "DELETE_AWARENESS_DOCUMENT",
};

export const loadAwarenessDocumentPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: awarenessDocumentTypes.AWARENESS_DOCUMENT_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAwarenessDocumentPage = () => {
  return {
    type: awarenessDocumentTypes.AWARENESS_DOCUMENT_UNLOADED,
    payload: {},
  };
};

export const addAwarenessDocument = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/awareness_document", {...payload,extra}).then(
      (data) => {
        dispatch({
          type: awarenessDocumentTypes.ADD_AWARENESS_DOCUMENT,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAwarenessDocument = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/awareness_document/update/${payload.id}`, {...payload,extra}).then(
      (data) => {
        dispatch({
          type: awarenessDocumentTypes.EDIT_AWARENESS_DOCUMENT,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAwarenessDocument = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/awareness_document/delete/${id}`).then(
      (data) => {
        dispatch({
          type: awarenessDocumentTypes.DELETE_AWARENESS_DOCUMENT,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
