import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const boqRoofTypes = {
  BOQ_ROOF_LOADED: "BOQ_ROOF_LOADED",
  BOQ_ROOF_UNLOADED: "BOQ_ROOF_UNLOADED",
  ADD_BOQ_ROOF: "ADD_BOQ_ROOF",
  EDIT_BOQ_ROOF: "EDIT_BOQ_ROOF",
  DELETE_BOQ_ROOF: "DELETE_BOQ_ROOF",
};

export const loadBoqRoofPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: boqRoofTypes.BOQ_ROOF_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadBoqRoofPage = () => {
  return {
    type: boqRoofTypes.BOQ_ROOF_UNLOADED,
    payload: {},
  };
};

export const addBoqRoof = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/boq_roof", payload).then(
      (data) => {
        dispatch({
          type: boqRoofTypes.ADD_BOQ_ROOF,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editBoqRoof = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/boq_roof/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: boqRoofTypes.EDIT_BOQ_ROOF,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeBoqRoof = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/boq_roof/delete/${id}`).then(
      (data) => {
        dispatch({
          type: boqRoofTypes.DELETE_BOQ_ROOF,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
