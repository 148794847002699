import React, { useEffect } from 'react'
import StepForm from './StepForm'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'

import { fetchData, unloadData } from '../../redux/action/allAction';
import { Loader } from 'semantic-ui-react';

interface Props {
    match: {
        params:
        {
            slug: string;
            id: number;
        };
    };
}
export default function StepEdit(props: Props) {
    const step = useSelector((state: any) => state.all_ind);
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchStep(props.match.params.id,props.match.params.slug))
        dispatch(fetchData(`/step/edit/${props.match.params.slug}`))
        return () => {
            dispatch(unloadData())
        }
        // eslint-disable-next-line
    }, [])

    if (_.isEmpty(step)) {
        return (
            <Loader />
        )
    }
    return (
        <StepForm type="Edit" data={step} id={props.match.params.id} slug={props.match.params.slug} />
    )
}
