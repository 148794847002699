import React from 'react'

import FormbuilderMetaForm from './FormbuilderMetaForm';


export default function FormbuilderMetaCreate() {
    
    return (
        <FormbuilderMetaForm type="Create" />
    )
}
