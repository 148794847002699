import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const sponsorTypes = {
  SPONSOR_LOADED: "SPONSOR_LOADED",
  SPONSOR_UNLOADED: "SPONSOR_UNLOADED",
  ADD_SPONSOR: "ADD_SPONSOR",
  EDIT_SPONSOR: "EDIT_SPONSOR",
  DELETE_SPONSOR: "DELETE_SPONSOR",
};

export const loadSponsorPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: sponsorTypes.SPONSOR_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadSponsorPage = () => {
  return {
    type: sponsorTypes.SPONSOR_UNLOADED,
    payload: {},
  };
};

export const addSponsor = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/sponsor", payload).then(
      (data) => {
        dispatch({
          type: sponsorTypes.ADD_SPONSOR,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editSponsor = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/sponsor/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: sponsorTypes.EDIT_SPONSOR,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeSponsor = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/sponsor/delete/${id}`).then(
      (data) => {
        dispatch({
          type: sponsorTypes.DELETE_SPONSOR,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
