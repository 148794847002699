import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const lessonDataTypes = {
  LESSON_DATA_LOADED: "LESSON_DATA_LOADED",
  LESSON_DATA_UNLOADED: "LESSON_DATA_UNLOADED",
  ADD_LESSON_DATA: "ADD_LESSON_DATA",
  EDIT_LESSON_DATA: "EDIT_LESSON_DATA",
  DELETE_LESSON_DATA: "DELETE_LESSON_DATA",
};

export const loadLessonDataPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: lessonDataTypes.LESSON_DATA_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadLessonDataPage = () => {
  return {
    type: lessonDataTypes.LESSON_DATA_UNLOADED,
    payload: {},
  };
};

export const addLessonData = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/lesson_data", payload).then(
      (data) => {
        dispatch({
          type: lessonDataTypes.ADD_LESSON_DATA,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editLessonData = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/lesson_data/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: lessonDataTypes.EDIT_LESSON_DATA,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeLessonData = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/lesson_data/delete/${id}`).then(
      (data) => {
        dispatch({
          type: lessonDataTypes.DELETE_LESSON_DATA,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
