import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const libraryTypes = {
  LIBRARY_LOADED: "LIBRARY_LOADED",
  LIBRARY_UNLOADED: "LIBRARY_UNLOADED",
  ADD_LIBRARY: "ADD_LIBRARY",
  EDIT_LIBRARY: "EDIT_LIBRARY",
  DELETE_LIBRARY: "DELETE_LIBRARY",
};

export const loadLibraryPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: libraryTypes.LIBRARY_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
      
    );
  };
};

export const unloadLibraryPage = () => {
  return {
    type: libraryTypes.LIBRARY_UNLOADED,
    payload: {},
  };
};

export const addLibrary = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/library", payload).then(
      (data) => {
        dispatch({
          type: libraryTypes.ADD_LIBRARY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editLibrary = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/library/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: libraryTypes.EDIT_LIBRARY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeLibrary = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/library/delete/${id}`).then(
      (data) => {
        dispatch({
          type: libraryTypes.DELETE_LIBRARY,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
