import { filesTypes } from "../action/fileAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case filesTypes.FILES_LOADED:
      return action.payload;

    case filesTypes.FILES_UNLOADED:
      return initialState;

    case filesTypes.ADD_FILE:
      return { ...state };

    case filesTypes.DELETE_FILE:
      return state;

    default:
      return state;
  }
};
