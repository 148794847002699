import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
 
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Input,
  Select,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadOfficePage,
  unloadOfficePage,
  addOffice,
  editOffice,
  removeOffice,
} from "../../redux/action/officeAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  offices: {
    offices: {
      data: {
        id: number;
        district: string;
        district_lang: string;
        vdc_municipality: string;
        vdc_municipality_lang: string;
        type: string;
        type_lang: string;
        phone: string;
        app_id: number;
        related_app_id: [];
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function Office() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      district: "",
      district_lang: "",
      vdc_municipality: "",
      vdc_municipality_lang: "",
      type: "",
      type_lang: "",
      phone: "",
      app_id: [0],
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/office");
  const [search, setSearch] = useState<string>("");
  const offices = useSelector((state: RootState) => state.offices);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [filter, setFilter] = useState<number[]>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadOfficePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadOfficePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (offices.offices !== undefined) {
      setTotal(offices.offices.last_page);
      var final_stage = offices.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(final_stage);
    }
  }, [offices]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/office?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        "&filter=" +
        filter
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/office?q=" + search + "&filter=" + filter);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        district: "",
        district_lang: "",
        vdc_municipality: "",
        vdc_municipality_lang: "",
        type: "",
        type_lang: "",
        phone: "",
        app_id: [],
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let office = offices.offices.data.find((v) => v.id === id);

    setData({
      newData: {
        id: office!.id,
        district: office!.district,
        district_lang: office!.district_lang,
        vdc_municipality: office!.vdc_municipality,
        vdc_municipality_lang: office!.vdc_municipality_lang,
        type: office!.type,
        type_lang: office!.type_lang,
        app_id:
          office!.related_app_id !== null
            ? office!.related_app_id
            : [office!.app_id],

        phone: office!.phone,
      },
    });

    setCreating(true);
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleFilterChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    setFilter(value);
    setApiUrl("/office?filter=" + value + "&q=" + search);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addOffice(fdata.newData, () => {
      dispatch(loadOfficePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editOffice(fdata.newData, () => {
      dispatch(loadOfficePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    
    setLoading(true);

    dispatch(removeOffice(deleteId, () => {
      dispatch(loadOfficePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  function RenderList(datas: any) {
    if (datas.data.offices === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.offices.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.offices.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.district}</Table.Cell>
          <Table.Cell>{data.district_lang}</Table.Cell>
          <Table.Cell>{data.vdc_municipality}</Table.Cell>
          <Table.Cell>{data.vdc_municipality_lang}</Table.Cell>
          <Table.Cell>{data.type}</Table.Cell>
          <Table.Cell>{data.type_lang}</Table.Cell>
          <Table.Cell>{data.phone}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Offices</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div
      className="app-card-org"
      key="2"
      // className={"table-responsive"}
      style={{ overflowX: "scroll" }}
    >
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Office
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Offices
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Select
        placeholder="Filter by App"
        multiple
        clearable
        options={appOptions}
        onChange={handleFilterChange}
      />

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>District</Table.HeaderCell>
            <Table.HeaderCell>District Lang.</Table.HeaderCell>
            <Table.HeaderCell>VDC / Municipality</Table.HeaderCell>
            <Table.HeaderCell>VDC / Municipality Lang.</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Type Lang.</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{offices && <RenderList data={offices} />}</Table.Body>
      </Table>

      {offices.offices === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="small"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Office
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select App"
              required
              multiple
              options={appOptions}
              label="App"
              value={fdata.newData.app_id}
              name="app_id"
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="district"
                label="District"
                value={fdata.newData.district}
                onChange={handleChange}
                placeholder="District Name"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="district_lang"
                label="District (In Preferred Language)"
                value={fdata.newData.district_lang}
                onChange={handleChange}
                placeholder="District (In Preferred Language)"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="vdc_municipality"
                label="VDC / Municipality"
                value={fdata.newData.vdc_municipality}
                onChange={handleChange}
                placeholder="VDC / Municipality"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="vdc_municipality_lang"
                label="VDC / Municipality (in Preferred Language)"
                value={fdata.newData.vdc_municipality_lang}
                onChange={handleChange}
                placeholder="VDC / Municipality (in Preferred Language)"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="type"
                label="Type"
                value={fdata.newData.type}
                onChange={handleChange}
                placeholder="Type"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="type_lang"
                label="Type (In Preferred Language)"
                value={fdata.newData.type_lang}
                onChange={handleChange}
                placeholder="Type (In Preferred Language)"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="phone"
                label="Phone"
                value={fdata.newData.phone}
                onChange={handleChange}
                placeholder="Phone"
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
