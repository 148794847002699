import React, { ReactElement } from "react";

// eslint-disable-next-line
export default function Done({}): ReactElement {
  return (
    <div style={{textAlign: "center"}}>
      <p><i className={"fa fa-exclamation-circle fa-5x text-danger"}></i></p>
      <h3>404 Page Not Found</h3>
    </div>
  );
}
