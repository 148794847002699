import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Popup,
  Input,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadStagePage,
  unloadStagePage,
  addStage,
  editStage,
  removeStage,
} from "../../redux/action/stageAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
import StatCalculations from "../../components/StatCalculations";

export default function Stage() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      project: "",
      order: 0,
      detail: "",
      instruction: "",
      stats: [],
      status_choices: [],
    },
  });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/stage");
  const stages = useSelector((state: any) => state.stages);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [projectOptions, setProjectOptions] = useState([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const [projectFilter, setProjectFilter] = useState<number | undefined>(
    undefined
  );

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      loadStagePage(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadStagePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (stages.stage !== undefined) {
      setTotal(stages.stage.last_page);
      var final_project = stages.projects.map(
        (data: { id: number; name: string }) => {
          return {
            key: data.id,
            value: data.id,
            text: data.name,
          };
        }
      );

      setProjectOptions(final_project);
    }
  }, [stages]);

  useEffect(() => {
    if (projectFilter !== undefined) {
      onSearch({ key: "Enter" });
    }
  }, [projectFilter]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/stage?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        (projectFilter ? "&project_id=" + projectFilter : "")
    );
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        project: "",
        order: 0,
        detail: "",
        instruction: "",
        stats: [],
        status_choices: [],
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let stage = stages.stage.data.find((v: { id: number }) => v.id === id);

    setData({
      newData: {
        id: stage.id,
        name: stage.name,
        project: stage.project_id,
        order: stage.order,
        detail: stage.detail ?? "",
        instruction: stage.instruction ?? "",
        stats: stage.stats ?? [],
        status_choices: stage.status_choices ?? [],
      },
    });
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl(
        "/stage?q=" +
          search +
          (projectFilter ? "&project_id=" + projectFilter : "")
      );
    }
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        addStage(
          fdata.newData,
          () => {
            dispatch(
              loadStagePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data added successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        editStage(
          fdata.newData,
          () => {
            dispatch(
              loadStagePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data Edited successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };
  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      removeStage(
        deleteId,
        () => {
          dispatch(
            loadStagePage(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  function RenderList(datas: any) {
    if (datas.data.stage === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (
      !datas.data.stage ||
      !datas.data.stage.data ||
      datas.data.stage.data.length === 0
    ) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.stage.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.project ? data.project.name : ""}</Table.Cell>
          <Table.Cell>{data.order}</Table.Cell>

          <Table.Cell>
            <Popup
              trigger={
                <Button
                  as={Link}
                  to={`/step/${data.id}`}
                  icon="arrow alternate circle right outline"
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  circular
                ></Button>
              }
              content="Step"
              position="top center"
            />

            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              circular
              className="deleteButton"
              icon
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Stage</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create Stage
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>
          Stage List
          <div className="pull-right">
            <div style={{ display: "flex" }}>
              <Form.Select
                style={{ marginRight: "5px" }}
                placeholder="Choose a Project"
                options={projectOptions}
                label={"Filter Project →"}
                value={projectFilter ?? undefined}
                onChange={(e, data) => {
                  setProjectFilter((data.value as number) ?? undefined);
                }}
                className="small"
                clearable
                search
              />

              <Input
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={onSearch}
                className="small"
                action={{ icon: "search" }}
                placeholder="Search..."
              />
            </div>
          </div>
        </Header>

        <Table basic="very" unstackable striped style={{ marginTop: "25px" }}>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Stage Name</Table.HeaderCell>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Order</Table.HeaderCell>

              <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{stages && <RenderList data={stages} />}</Table.Body>
        </Table>

        {stages.stage === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column>
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>

      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        // size="large"
        className="modal-customized"
        style={{ width: "80vw" }}
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Stage
        </Modal.Header>
        <Modal.Content className="modalContent">
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Stage Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Stage name..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Project"
                required
                options={projectOptions}
                label="Project type"
                value={fdata.newData.project}
                name="project"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>Order</label>
              <Form.Input
                fluid
                type="number"
                name="order"
                value={fdata.newData.order}
                onChange={handleChange}
                placeholder="Enter Order..."
              />
            </Form.Field>

            <Form.Field>
              <label>Description</label>
              <Form.Input
                fluid
                type="text"
                name="detail"
                value={fdata.newData.detail}
                onChange={handleChange}
                placeholder="Provide some description.."
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label>Instruction</label>
            <Form.TextArea
              fluid
              name="instruction"
              value={fdata.newData.instruction}
              onChange={handleChange}
              placeholder="Provide some detailed instructions.."
            />
          </Form.Field>

          <StatCalculations
            initialStats={fdata.newData.stats}
            autosuggest={fdata.newData.status_choices}
            onChange={(stats) => {
              setData({
                newData: Object.assign({}, fdata.newData, {
                  stats: stats,
                }),
              });
            }}
          />
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
