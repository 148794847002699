import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
 
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Popup,
  DropdownProps,
  Dropdown,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadRolePage,
  unloadRolePage,
  addRole,
  editRole,
  removeRole,
} from "../../redux/action/roleAction";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ModalComponent from "../../components/Modal";
import { alertActions } from "../../redux/action/alertAction";

type OptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

interface RootState {
  roles: {
    roles: {
      data: {
        name: string;
        id: number;
        slug: string;
        organization: number;
        module: number;
      }[];
      last_page: number;
      per_page: number;
    };
    organizations: {
      id: number;
      name: string;
    }[];
    modules: {
      id: number;
      name: string;
      slug: string;
    }[];
  };
}

export interface RootState1 {
  authentication: {
    user: {
      data: {
        first_name: string;
        last_name: string;
        email: string;
        id: number;
      };
      role: string;
      organization: string;
    };
  };
}

const Role = () => {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      slug: "",
      organization: 0,
      module: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [apiUrl, setApiUrl] = useState("/role");
  const roles = useSelector((state: RootState) => state.roles);
  const { user } = useSelector((state: RootState1) => state.authentication);
  const [total, setTotal] = useState(1);
  const [orgOptions, setOrgOptions] = useState<OptionType>([]);
  const [moduleOptions, setModuleOptions] = useState<OptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadRolePage(apiUrl, () => { 
      setLoading(false);
    }));
    return () => {
      dispatch(unloadRolePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (roles.roles !== undefined) {
      setTotal(roles.roles.last_page);
      setPerPage(roles.roles.per_page || 5);

      let final_organizations = roles.organizations.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });
      let final_modules = roles.modules.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });
      setOrgOptions(final_organizations);
      setModuleOptions(final_modules);
    }
    // eslint-disable-next-line
  }, [roles]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/role?page=" + pageInfo.activePage.toString() + "&per_page=" + perPage);
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage(data.value as number || 5);

    setApiUrl(
      "/role?page=" + activePage + "&per_page=" + (data.value || 5)
    );
  }

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        slug: "",
        organization: 0,
        module: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let role = roles.roles.data.find((v: { id: number }) => v.id === id);
    setData({
      newData: {
        id: role!.id,
        name: role!.name,
        slug: role!.slug,
        organization: role!.organization,
        module: role!.module,
      },
    });
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result?: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addRole(fdata.newData, () => {
      dispatch(loadRolePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editRole(fdata.newData, () => {
      dispatch(loadRolePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeRole(deleteId, () => {
      dispatch(loadRolePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  function RenderList({ data }: any) {
    if (data.roles === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (data.roles.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    // console.log(datas.data)
    return data.roles.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.slug}</Table.Cell>
          <Table.Cell>
            {data.organizations ? data.organizations.name : ""}
          </Table.Cell>
          <Table.Cell>{data.modules ? data.modules.name : ""}</Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>

            <Popup
              trigger={
                <Button
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                  }}
                  circular
                  as={Link}
                  to={"/user?role_id=" + data.id}
                  icon={"user"}
                ></Button>
              }
              content={
                "Filter & Show All Users by this Role: " + data.name
              }
              position="top right"
              size="tiny"
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Role</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
        <Button
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
          className="createButton"
        >
          Create Role
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>Role List</Header>

        <Table basic="very" unstackable striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Role Name</Table.HeaderCell>
              <Table.HeaderCell>Slug</Table.HeaderCell>
              <Table.HeaderCell>Organization</Table.HeaderCell>
              <Table.HeaderCell>Module</Table.HeaderCell>
              <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{roles.roles && <RenderList data={roles} />}</Table.Body>
        </Table>
        {roles.roles === undefined || loading ? <Loader active inverted size="big" /> : ""}
        
        <Grid>
          <Grid.Column>
            <div style={{ marginRight: '5px', display: 'inline-block' }}>
              <p style={{ display: 'inline-block' }}>Per Page: </p>
              <Dropdown
                options={[
                  { key: 5, text: '5', value: 5 },
                  { key: 10, text: '10', value: 10 },
                  { key: 15, text: '15', value: 15 },
                  { key: 25, text: '25', value: 25 },
                  { key: 50, text: '50', value: 50 },
                  { key: 100, text: '100', value: 100 },
                ]}
                placeholder='Per Page'
                compact
                selection
                upward
                onChange={onPerPageChange}
                style={{ marginLeft: '5px' }}
                value={perPage}
              />
            </div>

            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>

      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="tiny"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Role
        </Modal.Header>
        <Modal.Content className="modalContent">
          <Form>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Role Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Role name..."
                className="formLabel"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="slug"
                label="Role Slug"
                value={fdata.newData.slug}
                onChange={handleChange}
                placeholder="Enter Role slug..."
                className="formLabel"
              />
            </Form.Field>

            <Form.Field hidden={user.role !== "Admin"}>
              <Form.Select
                fluid
                placeholder="Select Organization"
                clearable
                options={orgOptions}
                label="Organization (Select None if not any organization based)"
                value={fdata.newData.organization}
                name="organization"
                onChange={handleChange}
                className="formLabel"
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Modules"
                search
                clearable
                selection
                options={moduleOptions}
                label="Modules (Select None if not any modules based)(Loaded according to org permission )"
                value={fdata.newData.module}
                name="module"
                onChange={handleChange}
                className="formLabel"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default Role;
