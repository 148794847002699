import React, { useCallback, useState } from "react";

import {
  Segment,
  Breadcrumb,
  List,
  Menu,
  Message,
  Label,
  Table,
  Modal,
  Loader,
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { apiService } from "../../services";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import _ from "lodash";

interface CountryResponse {
  id: number;
  country_name: string;
  created_at: string;
  updated_at: string;
  organization: {
    id: number;
    name: string;
    slug: string;
    country_id: number;
    project: {
      id: number;
      name: string;
      order: number;
      slug: string;
      organization_id: number;
      is_rhiab: boolean;
    }[];
    users: {
      id: number;
      first_name: string;
      last_name: string | null;
      email: string;
      organization_id: number;
      role: number | null;
      roles: {
        id: number;
        name: string;
      } | null;
    }[];
  }[];
}

interface FormResponse {
  _id: {
    $oid: string;
  };
  name: string;
  slug: string;
  project: string;
  organization_id: string;
  total_active_records: number;
  total_rejected_records: number;
}

export default function CountryReport(props: any) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [activeActivity, setActiveActivity] = useState("Organizations");

  const [country, setCountry] = useState<CountryResponse | undefined>(
    undefined
  );
  const [forms, setForms] = useState<FormResponse[] | undefined>(undefined);

  const [apiUrl, setApiUrl] = useState<string>(
    `/country/${props.match.params.id}`
  );

  useEffect(() => {
    apiService.getAll(apiUrl).then((res: any) => {
      setCountry(res.country);
      setForms(res.forms);
    });
  }, [apiUrl]);

  const activityHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: any
  ) => {
    setActiveActivity(data.name);
  };

  const RenderOrganizationsTable = useCallback((): any => {
    if (
      country?.organization === undefined ||
      country?.organization.length === 0
    ) {
      return <Message>No Associated Organizations Found</Message>;
    }

    return country?.organization.map(function (organization, index) {
      return (
        <Table.Row key={organization.id}>
          <Table.Cell>
            <a
              href={"/organization/organizationReport/" + organization.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {organization.name || organization.slug}
            </a>
          </Table.Cell>
          <Table.Cell>{organization.slug}</Table.Cell>
          <Table.Cell>{organization.project.length}</Table.Cell>
          <Table.Cell>{organization.users.length}</Table.Cell>
        </Table.Row>
      );
    });
  }, [country?.organization]);

  const RenderProjectsTable = useCallback((): any => {
    if (
      country?.organization === undefined ||
      country?.organization.length === 0
    ) {
      return <Message>No Associated Projects Found</Message>;
    }

    let projects = _.flatten(
      country.organization.map((organization) => organization.project)
    );

    return projects.map(function (project) {
      return (
        <Table.Row key={project.id}>
          <Table.Cell
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a
              href={"/project/projectReport/" + project.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.name || project.slug}
            </a>

            {project.is_rhiab === true ? (
              <>
                <Label tag color="blue" size="mini">
                  RHIAB
                </Label>
              </>
            ) : (
              ""
            )}
          </Table.Cell>
          <Table.Cell>{project.slug}</Table.Cell>
          <Table.Cell>
            {
              (forms || []).filter(
                (form: any) => String(form.project) === String(project.id)
              ).length
            }
          </Table.Cell>
          <Table.Cell>
            {country.organization.find(
              (org) => org.id === project.organization_id
            )?.name ?? "-"}
          </Table.Cell>
        </Table.Row>
      );
    });
  }, [country?.organization, forms]);

  const RenderFormsTable = useCallback((): any => {
    if (forms === undefined || forms.length === 0) {
      return <Message>No Forms Found For This Country.</Message>;
    }

    return forms.map(function (form, index) {
      return (
        <Table.Row key={form.slug}>
          <Table.Cell>
            <a
              href={"/formbuilder/FormBuilderApp/" + form.slug}
              target="_blank"
              rel="noopener noreferrer"
            >
              {form.name}
            </a>
          </Table.Cell>
          <Table.Cell>{form.total_active_records || 0}</Table.Cell>
          <Table.Cell>{form.total_rejected_records || 0}</Table.Cell>
          <Table.Cell>
            {(country?.organization ?? []).find(
              (org: any) => String(org.id) === String(form.organization_id)
            )?.name ?? "-"}
          </Table.Cell>
          <Table.Cell>
            {(
              (country?.organization ?? []).find(
                (org) => String(org.id) === String(form.organization_id)
              )?.project ?? []
            ).find((project) => String(project.id) === String(form.project))
              ?.name ?? "-"}
          </Table.Cell>
        </Table.Row>
      );
    });
  }, [country, forms]);

  const RenderUsersTable = useCallback((): any => {
    if (
      country?.organization === undefined ||
      country?.organization.length === 0
    ) {
      return <Message>No Associated Users Found</Message>;
    }

    let users = _.flatten(
      country.organization.map((organization) => organization.users)
    );

    return users.map(function (user) {
      return (
        <Table.Row key={user.id}>
          <Table.Cell
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.first_name} {user.last_name}
          </Table.Cell>
          <Table.Cell>{user.email}</Table.Cell>
          <Table.Cell>{user?.roles?.name ?? "-"}</Table.Cell>
        </Table.Row>
      );
    });
  }, [country?.organization]);

  return [
    <div key={1}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section link as={Link} to="/country">
          Country
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Country Report</Breadcrumb.Section>
      </Breadcrumb>
    </div>,

    <Segment key={2}>
      {country && forms ? (
        <div>
          <List verticalAlign="middle" style={{ margin: 0 }}>
            <List.Item>
              <List.Content>
                <List.Header
                  as="a"
                  className="text-uppercase"
                  style={{ fontSize: "1.5em" }}
                >
                  {country.country_name}
                </List.Header>

                <List.Description
                  style={{ opacity: 0.5, fontSize: "0.8em", marginTop: "5px" }}
                >
                  #{country.id} ({moment(country.created_at).fromNow()})
                </List.Description>
              </List.Content>
            </List.Item>
          </List>

          <div>
            <Menu pointing secondary>
              <Menu.Item
                active={activeActivity === "Organizations"}
                name="Organizations"
                onClick={activityHandler}
              />
              <Menu.Item
                active={activeActivity === "Projects"}
                name="Projects"
                onClick={activityHandler}
              />
              <Menu.Item
                active={activeActivity === "Forms"}
                name="Forms"
                onClick={activityHandler}
              />
              <Menu.Item
                active={activeActivity === "Users"}
                name="Users"
                onClick={activityHandler}
              />
            </Menu>
          </div>

          <div>
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                display: activeActivity === "Organizations" ? "block" : "none",
              }}
            >
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Slug</Table.HeaderCell>
                    <Table.HeaderCell>Total Projects</Table.HeaderCell>
                    <Table.HeaderCell>Total Users</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <RenderOrganizationsTable />
                </Table.Body>
              </Table>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                display: activeActivity === "Projects" ? "block" : "none",
              }}
            >
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Project Name</Table.HeaderCell>
                    <Table.HeaderCell>Slug</Table.HeaderCell>
                    <Table.HeaderCell>Linked Forms Count</Table.HeaderCell>
                    <Table.HeaderCell>Organization</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <RenderProjectsTable />
                </Table.Body>
              </Table>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                display: activeActivity === "Forms" ? "block" : "none",
              }}
            >
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Form Name</Table.HeaderCell>
                    <Table.HeaderCell>
                      Total Active Submissions
                    </Table.HeaderCell>
                    <Table.HeaderCell>Rejected</Table.HeaderCell>
                    <Table.HeaderCell>Linked To Organization</Table.HeaderCell>
                    <Table.HeaderCell>Linked To Project</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <RenderFormsTable />
                </Table.Body>
              </Table>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                display: activeActivity === "Users" ? "block" : "none",
              }}
            >
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <RenderUsersTable />
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: "50px" }}>
          <Loader active={true} inverted size="large" />
        </div>
      )}
    </Segment>,
  ];
}
