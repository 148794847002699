import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const roofTypes = {
  ROOF_LOADED: "ROOF_LOADED",
  ROOF_UNLOADED: "ROOF_UNLOADED",
  ADD_ROOF: "ADD_ROOF",
  EDIT_ROOF: "EDIT_ROOF",
  DELETE_ROOF: "DELETE_ROOF",
};

export const loadRoofPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: roofTypes.ROOF_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadRoofPage = () => {
  return {
    type: roofTypes.ROOF_UNLOADED,
    payload: {},
  };
};

export const addRoof = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/roof", payload).then(
      (data) => {
        dispatch({
          type: roofTypes.ADD_ROOF,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editRoof = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/roof/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: roofTypes.EDIT_ROOF,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeRoof = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/roof/delete/${id}`).then(
      (data) => {
        dispatch({
          type: roofTypes.DELETE_ROOF,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
