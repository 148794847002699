import { allTypes } from "../action/allAction";

export default (state = {}, action: any) => {
    switch (action.type) {
        case allTypes.FETCH_DATA:

            return action.payload;

        case allTypes.POST_DATA:

            return { ...state };

        case allTypes.DATA_UNLOADED:
            return {};

        default:
            return state;
    }
};
