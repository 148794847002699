import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Icon,
  List,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import ModalComponent from "../../../components/Modal";
import { alertActions } from "../../../redux/action/alertAction";
import { fetchData, unloadData } from "../../../redux/action/allAction";
import { apiService } from "../../../services";

interface Props {
  history: any;
  match: any;
}
export default function FormbuilderReport(props: Props): ReactElement {
  const report = useSelector((state: any) => state.all_ind);
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  useEffect(() => {
    dispatch(fetchData(`/formBuilder/reportpdf/${props.match.params.slug}`));

    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    props.history.goBack();
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    // alert(delteId)

    apiService
      ._delete(
        `/formBuilder/reportpdfData/${props.match.params.slug}/${deleteId}
      `
      )
      .then(
        (data) => {
          setOpen(false);
          dispatch(
            fetchData(`/formBuilder/reportpdf/${props.match.params.slug}`)
          );
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function RenderList(datas: any) {
    if (datas.data.report === undefined) {
      return (
        <List divided verticalAlign="middle">
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              {" "}
              <Message>Loading ....</Message>
            </List.Content>
          </List.Item>
        </List>
      );
    }

    if (datas.data.report.length === 0) {
      return (
        <List divided verticalAlign="middle">
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              {" "}
              <Message>No data Found</Message>
            </List.Content>
          </List.Item>
        </List>
      );
    }

    return datas.data.report.map((data: any) => {
      return (
        <List divided verticalAlign="middle" key={data._id}>
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              <Button
                as={Link}
                to={`/formbuilder/report/${props.match.params.slug}/${
                  data._id.$oid ?? "new"
                }`}
                icon
                circular
                className="editButton"
              >
                <Icon name="pencil" />
              </Button>

              <Button
                onClick={() => deleteData(data._id.$oid ?? "")}
                icon
                circular
                className="deleteButton"
              >
                <Icon name="trash" />
              </Button>
            </List.Content>

            <List.Content>
              <List.Header className="text-uppercase">{data.name}</List.Header>
              <List.Description>
                {data.country ? data.country.country_name : ""}
              </List.Description>
              <List.Description>{data.order}</List.Description>
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            Formbuilder Report List
          </Breadcrumb.Section>
        </Breadcrumb>
        <ModalComponent
          open={open}
          setOpen={setOpen}
          confirmDelete={confirmDelete}
          strict={true}
        />
        <div></div>
      </div>

      <div className="app-card-org">
        <Button
          className="createButton"
          icon
          labelPosition="left"
          as={Link}
          to={`/formbuilder/report/${props.match.params.slug}/new`}
        >
          Create Pdf Report
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>Formbuilder Report List</Header>
        {report && <RenderList data={report} />}

        {report.report === undefined ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}
