import React, { useState, useEffect } from "react";
import {
  Pagination,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  List,
  Divider,
  Image,
  Label,
  Modal,
  DropdownProps,
  Dropdown,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";

import {
  simpleFetch,
  simplePost,
  simpleUnload,
  simpleDelete,
} from "../../../redux/action/simpleAction";

import { Link, useHistory } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../../redux/action/alertAction";

interface RootState {
  simpleReducer: {
    finance: {
      id: number;
      name: string;
      description: string;
      project_id: number;
      form_id: string;
      budget: string;
      created_at: string;
      updated_at: string;
      project: {
        id: number;
        name: string;
      }
    };
    tranches: {
      data: {
        id: number;
        name: string;
        finance_id: string|number;
        amount: string;
        order: number;
        transactions_count: number;
      }[];
      last_page: number;
      per_page: number;
    };
  };
}

export default function Tranche(props: any) {
  const finance_id = props.match.params.id;

  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: '',
      amount: '',
      order: 0,
    },
  });

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [apiUrl, setApiUrl] = useState(`/finance/${finance_id}/tranche`);
  const [url, setUrl] = useState("");
  const tranches = useSelector((state: RootState) => state.simpleReducer);
  const [total, setTotal] = useState(1);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(simpleFetch(apiUrl, () => setLoading(false), () => setLoading(false)));

    return () => {
      dispatch(simpleUnload());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (tranches && tranches.tranches !== undefined) {
      setTotal(tranches.tranches.last_page);
      setPerPage(tranches.tranches.per_page || 5);
    }
  }, [tranches]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/finance/" + finance_id + "/tranche?page=" + pageInfo.activePage.toString() + "&per_page=" + perPage);
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage(data.value as number || 5);

    setApiUrl(
      "/finance/" + finance_id + "/tranche?page=" + activePage + "&per_page=" + (data.value || 5)
    );
  }

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: '',
        amount: '',
        order: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let tranche = tranches.tranches.data.find((v) => v.id === id);

    if(!tranche) {
      return dispatch(alertActions.error('Record not available to edit.'));
    }

    setData({
      newData: {
        id: tranche.id,
        name: tranche.name,
        amount: tranche.amount,
        order: tranche.order,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    console.log(result)
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////

    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(simplePost(`/finance/${finance_id}/tranche`, fdata.newData, () => {
      dispatch(simpleFetch(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Tranche Added successfully"));
      }))
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(simplePost(`/finance/${finance_id}/tranche/${fdata.newData.id}`, fdata.newData, () => {
      dispatch(simpleFetch(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Tranche Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(simpleDelete(`/finance/${finance_id}/tranche/${deleteId}`, () => {
      dispatch(simpleFetch(apiUrl, () => {
        dispatch(alertActions.success("Tranche Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  const RenderList = React.useCallback((): any => {
    if (tranches.tranches === undefined) {
      return <Message>Loading ....</Message>;
    }

    if (tranches.tranches.data.length === 0) {
      return <Message>No data Found</Message>;
    }

    return tranches.tranches.data.map((data) => {
      return (
        <List divided verticalAlign="middle" key={data.id} className='hover-bg-gray-100'>
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              <Button
                onClick={() => {
                  history.push(`/finance/${tranches.finance.id}/tranche/${data.id}/transaction`)
                }}
                icon
                circular
                className="createButton"
              >
                <b style={{marginRight: '5px'}}>Transactions</b>
                <Icon name="send" />
              </Button>
              
              <Button
                onClick={() => {
                  selectDataForEditing(data.id);
                  setOpenInfo(true);
                  setCreating(false);
                }}
                icon
                circular
                className="editButton"
              >
                <Icon name="pencil" />
              </Button>

              <Button
                onClick={() => deleteData(data.id)}
                icon
                circular
                className="deleteButton"
              >
                <Icon name="delete" />
              </Button>
            </List.Content>

            <List.Content>
              <List.Header
                className="text-uppercase"
              >
                <span style={{ color: "var(--color-blue)" }}>
                  {data.name}
                </span>
              </List.Header>

              <small>
                <List.Description>
                  <strong>Amount:</strong> {data.amount}
                </List.Description>

                <List.Description>
                  <strong>Order:</strong> {data.order}
                </List.Description>

                <List.Description>
                  <strong>Transactions Count:</strong> {data.transactions_count}
                </List.Description>
              </small>
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
    // eslint-disable-next-line
  }, [tranches]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        
        <Breadcrumb.Divider icon="right arrow" />

        <Breadcrumb.Section link as={Link} to="/finance">
          <FormattedMessage id="Finance" defaultMessage="Finance" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        
        <Breadcrumb.Section active>Tranches</Breadcrumb.Section>
      </Breadcrumb>
      
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict_text="Deleting Tranche will also remove all it's Transactions History."
        strict={true}
        loading={loading}
      />
      
      <div className="app-card-org" key="2">
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create Tranche
          <Icon name="plus" className="iconSize" />
        </Button>

        <Header>Tranches List</Header>

        {!tranches || tranches.tranches === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          <div>
            <div style={{"marginBottom":"10px","display":"flex","justifyContent":"center","alignItems":"center","gap":"80px"}}>
              <div>
                <p style={{margin: 0}}><strong>Finance Name: </strong> {tranches.finance.name}</p>
                <p style={{margin: 0}}><strong>Total Finance Budget: </strong> {tranches.finance.budget}</p>
              </div>
            </div>

            <Divider/>

            <RenderList />
          </div>
        )}

        <Grid>
          <Grid.Column>
            <div style={{ marginRight: '5px', display: 'inline-block' }}>
              <p style={{ display: 'inline-block' }}>Per Page: </p>
              <Dropdown
                options={[
                  { key: 5, text: '5', value: 5 },
                  { key: 10, text: '10', value: 10 },
                  { key: 15, text: '15', value: 15 },
                  { key: 25, text: '25', value: 25 },
                  { key: 50, text: '50', value: 50 },
                  { key: 100, text: '100', value: 100 },
                ]}
                placeholder='Per Page'
                compact
                selection
                upward
                onChange={onPerPageChange}
                style={{ marginLeft: '5px' }}
                value={perPage}
              />
            </div>
            
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>

      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="small"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Tranche
        </Modal.Header>

        <Modal.Content>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter a name..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="amount"
                label="Amount"
                value={fdata.newData.amount}
                onChange={handleChange}
                placeholder="Total Amount"
                min={0}
                max={tranches?.finance?.budget ?? 9999999}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Form.Input
              fluid
              required
              type="number"
              name="order"
              label="Order"
              value={fdata.newData.order}
              onChange={handleChange}
              placeholder="Tranche Order"
              min={0}
            />
          </Form.Field>
        </Modal.Content>

        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
