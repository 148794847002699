import { formTypes } from "../action/formAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case formTypes.FORM_PAGE_LOADED:

            return action.payload;

        case formTypes.FORM_PAGE_UNLOADED:
            return initialState;
        case formTypes.ADD_FORM:
           
             return { ...state};
        case formTypes.EDIT_FORM:

            return state;
        case formTypes.DELETE_FORM:
           
            return state;

        default:
            return state;
    }
};
