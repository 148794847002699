import { faqTypes } from "../action/faqAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case faqTypes.FAQ_LOADED:

            return action.payload;

        case faqTypes.FAQ_UNLOADED:
            return initialState;
        case faqTypes.ADD_FAQ:
           
             return { ...state};
        case faqTypes.EDIT_FAQ:

            return state;
        case faqTypes.DELETE_FAQ:
           
            return state;

        default:
            return state;
    }
};
