import React from 'react'


import FormbuilderForm from './FormbuilderForm';


export default function FormbuilderCreate() {
    
    return (
        <FormbuilderForm type="Create" />
    )
}
