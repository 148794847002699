import { alertConstants } from "../action/alertAction";

export function alert(state = {}, action: any) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: "success",
        title: "Congratulation",
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: "error",
        title: "Opps",
        message: action.message,
      };
    case alertConstants.INFO:
      return {
        type: "info",
        title: "Info",
        message: action.message,
      };
    case alertConstants.WARNING:
      return {
        type: "warning",
        title: "Warning",
        message: action.message,
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
