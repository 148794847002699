import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadAppUserPage,
  unloadAppUserPage,
  addAppUser,
  editAppUser,
  removeAppUser,
  resetAppUserPoints,
} from "../../../redux/action/appUsersAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../../redux/action/alertAction";
interface RootState {
  app_users: {
    app_users: {
      data: {
        name: string;
        address: string;
        email: string;
        phone_no: string;
        imei_no: string;
        id: number;
        app_id: number;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function AppUser() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      address: "",
      email: "",
      phone_no: "",
      imei_no: "",
      app_id: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [resetPopUpOpen, setResetPopUpOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [resetId, setResetId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/app_user");
  const [search, setSearch] = useState<string>("");
  const app_users = useSelector((state: RootState) => state.app_users);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadAppUserPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAppUserPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (app_users.app_users !== undefined) {
      setTotal(app_users.app_users.last_page);
      var final_stage = app_users.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(final_stage);
    }
  }, [app_users]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/app_user?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/app_user?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        address: "",
        email: "",
        phone_no: "",
        imei_no: "",
        app_id: 0,
      },
    });
  };


  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addAppUser(fdata.newData));
    else dispatch(editAppUser(fdata.newData));

    clearForm();
    dispatch(loadAppUserPage(apiUrl, () => { 
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    
    setLoading(true);

    dispatch(removeAppUser(deleteId, () => {
      dispatch(loadAppUserPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));       
  };

  const resetPoints = (id: number) => {
    setResetPopUpOpen(true);
    setResetId(id);
  };

  const confirmReset = () => {

    setLoading(true);

    dispatch(resetAppUserPoints(resetId, () => {
      setLoading(false);
      dispatch(alertActions.success("Points Reset successfully"));
      setResetPopUpOpen(false);
    },
    () => setLoading(false)));      
  };

  function RenderList(datas: any) {
    if (datas.data.app_users === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.app_users.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.app_users.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.address}</Table.Cell>
          <Table.Cell>{data.email}</Table.Cell>
          <Table.Cell>{data.phone_no}</Table.Cell>
          <Table.Cell>{data.imei_no}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>

          <Table.Cell>
            <Button
              className="formbuilder-buttons"
              circular
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
              as={Link}
              to={`/app_user/${data.id}`}
              icon
            >
              <Icon name="info circle" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>

            <Button
              onClick={() => resetPoints(data.id)}
              icon
              circular
              className="editButton"
            >
              Reset
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Referral</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>App User</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ModalComponent
        open={resetPopUpOpen}
        setOpen={setResetPopUpOpen}
        confirmDelete={confirmReset}
        task={"Reset"}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      
      <Header>
        App User
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-2"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>IMEI No</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{app_users && <RenderList data={app_users} />}</Table.Body>
      </Table>

      {app_users.app_users === undefined || loading  ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
    >
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} App Users
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Name..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="address"
                label="Address"
                value={fdata.newData.address}
                onChange={handleChange}
                placeholder="Enter Address..."
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="email"
                label="Email"
                value={fdata.newData.email}
                onChange={handleChange}
                placeholder="Enter Email..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="phone_no"
                label="Phone Number"
                value={fdata.newData.phone_no}
                onChange={handleChange}
                placeholder="Enter Phone Number..."
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="imei_no"
                label="IMEI Number"
                value={fdata.newData.imei_no}
                onChange={handleChange}
                placeholder="Enter IMEI Number..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                options={appOptions}
                label="App"
                value={fdata.newData.app_id}
                name="app_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
