import { countryTypes } from "../action/countryAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case countryTypes.COUNTRY_PAGE_LOADED:

            return action.payload;

        case countryTypes.COUNTRY_PAGE_UNLOADED:
            return initialState;
        case countryTypes.ADD_COUNTRY:
           
             return { ...state};
        case countryTypes.EDIT_COUNTRY:

            return state;
        case countryTypes.DELETE_COUNTRY:
           
            return state;

        default:
            return state;
    }
};
