import { roleTypes } from "../action/roleAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case roleTypes.ROLE_PAGE_LOADED:

            return action.payload;

        case roleTypes.ROLE_PAGE_UNLOADED:
            return initialState;
        case roleTypes.ADD_ROLE:
           
             return { ...state};
        case roleTypes.EDIT_ROLE:

            return state;
        case roleTypes.DELETE_ROLE:
           
            return state;

        default:
            return state;
    }
};
