export * from "./history";
export * from "./auth-header";

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "i"
  );
  let isValid = !!urlPattern.test(urlString);

  if (!isValid) {
    return false;
  }

  if (!/^https?:\/\//i.test(urlString)) {
    urlString = "https://" + urlString;
  }

  try {
    return new URL(urlString).href;
  } catch (e) {
    return false;
  }
};

export function normalizeStepTypeName(type: string) {
  return type === "details"
    ? "SHOW RECORDS"
    : type === "details_field"
    ? "DETAILS FIELD"
    : type === "form"
    ? "FILL FORM"
    : type === "sync_parent_form_identifier_to_fulcrum_choices"
    ? "SYNC FULCRUM CHOICES"
    : type === "training_module"
    ? "TRAINING MODULE"
    : (type ?? "").toUpperCase().split("_").join(" ");
}

export function appendZeroToSingleDigitNumber(number: number) {
  return number + 1 <= 9 ? `0${number + 1}` : number + 1 + "";
}

export function appendZeroAndALetterToSingleDigitNumber(
  number: number,
  letterIndex: number
) {
  return (
    appendZeroToSingleDigitNumber(number) +
    "-" +
    String.fromCharCode(65 + letterIndex)
  );
}

export function openTrainingStartWindow(training_module_id = null) {
  const w = 1276;
  const h = 800;
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    `/training_start${
      training_module_id ? "?training_module_id=" + training_module_id : ""
    }`,
    "Popup",
    "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, width=" +
      w / systemZoom +
      ", height=" +
      h / systemZoom +
      ", top=" +
      (top + 10) +
      ", left=" +
      left
  );
  // @ts-ignore
  if (window.focus) newWindow.focus();
}
