import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  
  Table,
  Button,
  Pagination,
  Grid,
  Header,
  Icon,
  Message,
  Loader,
  Input,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  loadFormPage,
  unloadFormPage,
  removeForm,
} from "../../redux/action/formAction";
import ModalComponent from "../../components/Modal";
import { alertActions } from "../../redux/action/alertAction";

export default function Form() {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const [apiUrl, setApiUrl] = useState<string>("/forms");
  const forms = useSelector((state: any) => state.form);
  const [search, setSearch] = useState<string>("");
  const [total, setTotal] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadFormPage(apiUrl, () => { 
      setLoading(false);
    }));


    return () => {
      dispatch(unloadFormPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (forms.last_page !== undefined) {
      setTotal(forms.last_page);
    }
    // eslint-disable-next-line
  }, [forms]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/forms?page=" + pageInfo.activePage.toString() + "&q=" + search);
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/forms?q=" + search);
    }
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeForm(deleteId, () => {
      dispatch(loadFormPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  const RenderList = React.useCallback(() => {
    if (forms.data.length === 0) {
      return <Message>No data Found</Message>;
    }

    return forms.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.app ? data.app.app_name : ""}</Table.Cell>
          <Table.Cell>
            {data.organization ? data.organization.name : ""}
          </Table.Cell>
          <Table.Cell>{data.link}</Table.Cell>

          <Table.Cell>
            <Button
              className="editButton"
              circular
              as={Link}
              to={`/form/edit/${data.id}`}
              icon
            >
              <Icon name="pencil" />
            </Button>

            <Button
              circular
              className="deleteButton"
              onClick={() => deleteData(data.id)}
              icon
            >
              <Icon name="delete" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }, [forms]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          <FormattedMessage id="Form" defaultMessage="Form" /> Api
        </Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        labelPosition="left"
        as={Link}
        to="/form/create"
      >
        Create Form Api
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        <FormattedMessage id="Form" defaultMessage="Form" /> &nbsp; Api{" "}
        <FormattedMessage id="List" defaultMessage="List" />
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-3"
          action={{ icon: "search" }}
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>Organization</Table.HeaderCell>
            <Table.HeaderCell>Link</Table.HeaderCell>

            <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {forms.data === undefined || loading ? (
            <tr>
              <td>
                <Loader active inverted size="big" />
              </td>
            </tr>
          ) : (
            <RenderList />
          )}
        </Table.Body>
      </Table>

      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
  ];
}
