import React, { ReactElement } from "react";
import {
  Card,
  Icon,
  Grid,
  Header,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";

interface Props {
  cardHeader?: string;
  iconName?: SemanticICONS;
  color?: SemanticCOLORS;
  headerColor?: string;
  number: number;
}

export default function NumberCard(props: Props): ReactElement {

  return (
    <Card color={props.color} className="mx-auto p-2" style={{width: "245px"}}>
      <Grid columns={2}>
        <Grid.Row stretched verticalAlign="middle" relaxed="true" className="text-center">
            <Grid.Column>
              <Icon name={props.iconName} size="huge" color={props.color} className="ml-auto" />
            </Grid.Column>
          <Grid.Column>
            <Header as="h3" color={props.color}>
              {" "}
              {props.number}{" "}
            </Header>
            <Card.Content className={props.headerColor}  style={{borderRadius:"3px"}}>
              <Card.Header className="text-center text-white">
                {props.cardHeader}
              </Card.Header></Card.Content>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  );
}

