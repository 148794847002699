import React, { useState, useEffect } from "react";
import {
  Table,
  Header,
  Breadcrumb,
  Message,
  Button,
  Icon,
  Grid,
  Pagination,
  Modal,
  Form,
  Loader,
  Dropdown,
} from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";
import {
  loadLiveTrainingPage,
  unloadLiveTrainingPage,
  addLiveTraining,
  editLiveTraining,
  removeLiveTraining,
} from "../../redux/action/liveTrainingAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ModalComponent, { ImageModalComponent } from "../../components/Modal";
import moment from "moment";
import { alertActions } from "../../redux/action/alertAction";

interface RootState {
  live_trainings: {
    live_trainings: {
      data: {
        id: number;
        training_module_id: number;
        training_name: string;
        training_name_slug: string;
        training_creator_name: string;
        password: string;
        active_status: boolean;
        training_date_time: string;
        training_date_end_time: string;
        participants: any[];
        external_participants: any[];
      }[];

      last_page: number;
    };

    training_modules: {
      id: number;
      name: string;
    }[];
    users: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  };
}

type userOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type externalEmailOptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

type trainingModuleOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function LiveTraining() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      training_module_id: 0,
      training_name: "",
      training_creator_name: "",
      password: "",
      training_date_time: "",
      training_date_end_time: "",
      participants: [],
      external_participants: [],
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [url, setUrl] = useState("");
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/live_training");
  const [search, setSearch] = useState<string>("");
  const live_trainings = useSelector(
    (state: RootState) => state.live_trainings
  );
  const [total, setTotal] = useState(1);
  const [userOptions, setUserOptions] = useState<userOptionType>([]);
  const [trainingModuleOptions, setTrainingModuleOptions] =
    useState<trainingModuleOptionType>([]);
  const [filter, setFilter] = useState<number[]>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [externalEmailOptions, setExternalEmailList] =
    useState<externalEmailOptionType>([]);
  const [loading, setLoading] = useState(false);


  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadLiveTrainingPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadLiveTrainingPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (live_trainings.live_trainings !== undefined) {
      setTotal(live_trainings.live_trainings.last_page);
      var final_stage = live_trainings.training_modules.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setTrainingModuleOptions(final_stage);
    }

    if (live_trainings.users !== undefined) {
      let final_users = live_trainings.users.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.first_name + " " + data.last_name,
        };
      });

      setUserOptions(final_users);
    }
  }, [live_trainings]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl(
      "/live_training?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        "&filter=" +
        filter
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/live_training?q=" + search + "&filter=" + filter);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        training_module_id: 0,
        training_name: "",
        training_creator_name: "",
        password: "",
        training_date_time: "",
        training_date_end_time: "",
        participants: [],
        external_participants: [],
      },
    });
  };

  const handleAddItem = (event: any, data: any) => {
    // console.log({data})
    setExternalEmailList([
      ...externalEmailOptions,
      { key: data.value, text: data.value, value: data.value },
    ]);
    // emailOptions.push({ key: data.value, text: data.value, value: data.value });
  };

  const selectDataForEditing = (id: number) => {
    let live_training = live_trainings.live_trainings.data.find(
      (v) => v.id === id
    );

    setData({
      newData: {
        id: live_training!.id,
        training_module_id: live_training!.training_module_id,
        training_name: live_training!.training_name,
        training_creator_name: live_training!.training_creator_name,
        password: live_training!.password,
        training_date_time: live_training!.training_date_time,
        training_date_end_time: live_training!.training_date_end_time,
        //@ts-ignore
        participants: live_training!.participants.map((user) => {
          return user.id;
        }),
        //@ts-ignore
        external_participants: live_training!.external_participants.map(
          (external_participant) => {
            return external_participant.email;
          }
        ),
      },
    });

    let email_temp = live_training!.external_participants.map(
      (item: object) => {
        //@ts-ignore
        return { key: item.email, text: item.email, value: item.email };
      }
    );
    setExternalEmailList(email_temp);
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addLiveTraining(fdata.newData, () => {
      dispatch(loadLiveTrainingPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(editLiveTraining(fdata.newData, () => {
      dispatch(loadLiveTrainingPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeLiveTraining(deleteId, () => {
      dispatch(loadLiveTrainingPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));    
  };

  function RenderList(datas: any) {
    if (datas.data.live_trainings === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.live_trainings.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.live_trainings.data.map((data: any) => {
      let training_module = datas.data.training_modules.find(
        (a: any) => a.id === data.training_module_id
      );
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.training_name}</Table.Cell>
          <Table.Cell>{data.training_date_time}</Table.Cell>
          <Table.Cell>{data.training_date_end_time}</Table.Cell>
          <Table.Cell>{data.training_creator_name}</Table.Cell>
          <Table.Cell>{training_module ? training_module.name : ""}</Table.Cell>
          <Table.Cell>
            <Button
              as={Link}
              to={`/live_training/${data.training_name_slug}`}
              icon
              circular
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
            >
              <Icon name="arrow right" />
              Join
            </Button>

            {!(JSON.parse(localStorage.getItem("user") || '{}').role === "User") ? 
            <>
              <Button
                onClick={() => {
                  selectDataForEditing(data.id);
                  setOpenInfo(true);
                  setCreating(false);
                }}
                icon
                circular
                className="editButton"
              >
                <Icon name="pencil" />
              </Button>

              <Button
                onClick={() => deleteData(data.id)}
                icon
                circular
                className="deleteButton"
              >
                <Icon name="trash" />
              </Button>
              </>
              : ""}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Live Training</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      {!(JSON.parse(localStorage.getItem("user") || '{}').role === "User") ? <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Live Training
        <Icon name="plus" className="iconSize" />
      </Button> : ""}
      
      <Header>
        Live Training
        {/* <Input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={onSearch}
            className="pull-right small mb-3"
            icon="search"
            placeholder="Search..."
          /> */}
      </Header>

      {/* <Select
          placeholder="Filter by App"
          multiple
          clearable
          options={appOptions}
          onChange={handleFilterChange}
        /> */}

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Training Name</Table.HeaderCell>
            <Table.HeaderCell>Start Date Time</Table.HeaderCell>
            <Table.HeaderCell>End Date Time</Table.HeaderCell>
            <Table.HeaderCell>Creator Name</Table.HeaderCell>
            <Table.HeaderCell>Training Module</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {live_trainings && <RenderList data={live_trainings} />}
        </Table.Body>
      </Table>

      {live_trainings.live_trainings === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="large"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Live Training
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form.Field>
          <Form.Select
            fluid
            placeholder="Select Training Module"
            required
            options={trainingModuleOptions}
            label="Training Module"
            value={fdata.newData.training_module_id}
            name="training_module_id"
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Group widths={2}>
          <Form.Field>
            <Form.Input
              fluid
              required
              type="text"
              name="training_name"
              label="Enter name for live training"
              value={fdata.newData.training_name}
              onChange={handleChange}
              placeholder="Name of live training"
            />
          </Form.Field>

          <Form.Field>
            <Form.Input
              fluid
              required
              type="text"
              name="training_creator_name"
              label="Enter your name (to display in live training)"
              value={fdata.newData.training_creator_name}
              onChange={handleChange}
              placeholder="Your name (to display in live training)"
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths={2}>
          <Form.Field>
            <Form.Input
              fluid
              required
              type="text"
              name="password"
              label="Enter password for joining live training"
              value={fdata.newData.password}
              onChange={handleChange}
              placeholder="Password for joining live training"
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths={2}>
          <Form.Field>
            <label>Select Starting Date and Time for live training</label>
            <DateTimeInput
              name="training_date_time"
              placeholder="Select starting date and time for live training"
              value={fdata.newData.training_date_time}
              iconPosition="left"
              onChange={handleChange}
              clearable={true}
              dateTimeFormat={"YYYY-MM-DD H:mm:ss"}
              minDate={moment()}
              preserveViewMode={false}
              closable={true}
              animation={"zoom"}
            />
            <small>Please enter the date and time in GMT format.</small>
          </Form.Field>

          <Form.Field
            disabled={fdata.newData.training_date_time ? false : true}
          >
            <label>Select Ending Date and Time for live training</label>
            <DateTimeInput
              name="training_date_end_time"
              placeholder="Select ending date and time for live training"
              value={fdata.newData.training_date_end_time}
              iconPosition="left"
              onChange={handleChange}
              clearable={true}
              dateTimeFormat={"YYYY-MM-DD H:mm:ss"}
              minDate={fdata.newData.training_date_time}
              preserveViewMode={false}
              closable={true}
              animation={"zoom"}
            />
            <small>Please enter the date and time in GMT format.</small>
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <Form.Select
            placeholder="Select Participants for live training"
            fluid
            multiple
            search
            selection
            scrolling
            name="participants"
            label="Participants for live training"
            options={userOptions}
            value={fdata.newData.participants}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field>
          <label>
            Enter email(s) of the external participant for invitation
          </label>
          <Dropdown
            search
            selection
            multiple
            value={fdata.newData.external_participants}
            name="external_participants"
            onChange={handleChange}
            allowAdditions
            onAddItem={handleAddItem}
            options={externalEmailOptions}
          />
        </Form.Field>
        {/* <AddNewEmailField changeEmail={handleEmailChange} /> */}
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
