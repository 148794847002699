import { alertActions } from './alertAction';
import { apiService } from '../../services';
export const stageTypes = {
   
    STAGE_PAGE_LOADED: "STAGE_PAGE_LOADED",
    STAGE_PAGE_UNLOADED: "STAGE_PAGE_UNLOADED",
    ADD_STAGE:"ADD_STAGE",
    EDIT_STAGE:"EDIT_STAGE",
    DELETE_STAGE:"DELETE_STAGE",


}

export const loadStagePage =  (url:string, onFinally: Function) => {
    
    return async (dispatch:any) =>{

        apiService.getAll(url)
        .then(
            data => { 
               
                dispatch({
                    type: stageTypes.STAGE_PAGE_LOADED,
                    payload: data
                })
                onFinally();
            },
            error => {
                onFinally();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
}



export const unloadStagePage = () => {
    return {
        type: stageTypes.STAGE_PAGE_UNLOADED,
        payload: {}

    };
}

export const addStage = (payload:object, onSuccess: Function, onError: Function) => {
    

    return async (dispatch:any) =>{

        apiService.save('/stage',payload)
        .then(
            data => { 
               
                dispatch({
                    type: stageTypes.ADD_STAGE,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data added successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };

   
}
interface payloadProps {
    id: number,
   // [all:any]:any
}
export const editStage = (payload:payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch:any) =>{

        apiService.update(`/stage/update/${payload.id}`,payload)
        .then(
            data => { 
               
                dispatch({
                    type: stageTypes.EDIT_STAGE,
                    payload: data
                });
                onSuccess();
                // dispatch(alertActions.success('Data Edited successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
}



  export const removeStage= (id:number, onSuccess: Function, onError: Function) =>  {

   
    return async (dispatch:any) =>{

        apiService._delete(`/stage/delete/${id}`)
        .then(
            data => { 
               
                dispatch({
                    type: stageTypes.DELETE_STAGE,
                   
                });
                onSuccess();
                // dispatch(alertActions.success('Data Deleted successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
  }

  

