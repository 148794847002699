import React, { useEffect, useState } from "react";

import {
  Header,
  Icon,
  Tab,
  Form,
  Button,
  Divider,
  Modal,
  Card,
  Input,
  Popup,
  Breadcrumb,
  Progress,
} from "semantic-ui-react";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { useDispatch } from "react-redux";

export default function AdminSettings() {
  const dispatch = useDispatch();

  const [fulcrumToken, setFulcrumToken] = useState("");
  const [fulcrumSyncAllError, setFulcrumSyncAllError] = useState<
    string | boolean
  >(false);
  const [fulcrumSyncAllMessage, setFulcrumSyncAllMessage] = useState<
    string | boolean
  >(false);
  const [fulcrumSyncAllLoading, setFulcrumSyncAllLoading] = useState(false);
  const [batchId, setBatchId] = useState<string | null>(null);
  const [batchResponse, setBatchResponse] = useState<any>(undefined);

  const performSyncAll = async () => {
    setFulcrumSyncAllLoading(true);

    apiService
      .save1("/fulcrum-sync-everything", {
        fulcrum_token: fulcrumToken,
      })
      .then(
        (data) => {
          setFulcrumSyncAllLoading(false);
          setFulcrumSyncAllError(false);
          setFulcrumSyncAllMessage(
            data.message ?? "Syncing will start soon in background. Thank you."
          );
          if (data.batch_id) {
            setBatchId(data.batch_id);
          }
        },
        (error) => {
          setFulcrumSyncAllLoading(false);
          setFulcrumSyncAllError(
            typeof error === "string"
              ? error
              : "Something went wrong. Unable to sync."
          );
          setFulcrumSyncAllMessage(false);
        }
      );

    alertActions.success("Work in Progress. Use responsively.");
  };

  const refreshBatchStatus = async () => {
    setFulcrumSyncAllLoading(true);

    apiService.getAll("/fulcrum-sync-everything/status").then(
      (data) => {
        setBatchResponse(data);
        setBatchId(null);

        if (data.batch.progress < 100) {
          return setTimeout(refreshBatchStatus, 5000);
        } else {
          setFulcrumSyncAllLoading(false);
        }
      },
      (error) => {
        setFulcrumSyncAllLoading(false);
        dispatch(
          alertActions.info(
            typeof error === "string"
              ? error
              : "Something went wrong. Unable to get background job status."
          )
        );
        setBatchResponse(undefined);
        setBatchId(null);
      }
    );
  };

  useEffect(() => {
    if (batchId) {
      refreshBatchStatus();
    }
  }, [batchId]);

  useEffect(() => {
    refreshBatchStatus();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          <FormattedMessage
            id="Admin Settings"
            defaultMessage="Admin Settings"
          />
        </Breadcrumb.Section>
      </Breadcrumb>

      <div className="app-card-org">
        <h3 className="text-2xl font-bold text-orange-500 mb-4">
          Sync All Fulcrum Forms, Records & Files to BCTAP:
        </h3>

        <Form onSubmit={performSyncAll} autoComplete="off">
          <Form.Input
            fluid
            required
            type="password"
            name="fulcrum_token"
            label="Fulcrum Token"
            placeholder="Enter Fulcrum Token..."
            value={fulcrumToken}
            onChange={(e, data) => {
              setFulcrumToken(data.value);
            }}
          />

          {fulcrumSyncAllError && (
            <div className="text-red-500 text-md font-bold mb-2">
              {fulcrumSyncAllError}
            </div>
          )}

          {fulcrumSyncAllMessage && (
            <div className="text-green-500 text-md font-bold mb-2">
              {fulcrumSyncAllMessage}
            </div>
          )}

          <Button
            type="submit"
            color="orange"
            loading={fulcrumSyncAllLoading}
            disabled={
              fulcrumSyncAllLoading ||
              (batchResponse?.batch &&
                moment().diff(moment(batchResponse.batch.createdAt), "hours") <=
                  5)
            }
          >
            Sync All
          </Button>
        </Form>

        {batchResponse && batchResponse.batch && (
          <div style={{ marginTop: "25px" }}>
            <Progress
              percent={batchResponse.batch.progress}
              progress
              active
              success={batchResponse.batch.progress === 100}
              error={batchResponse.batch.failedJobs > 0}
              size="medium"
              style={{ overflow: "initial", marginBottom: "10px" }}
            />

            <p>
              <b>Description:</b> {batchResponse.batch.name}
            </p>
            <p>
              <b>Batch:</b> {batchResponse.batch.id}
            </p>
            <Divider />

            <p>
              <b>Total Forms:</b> {batchResponse.batch.totalJobs}
            </p>
            <p>
              <b>Pending Forms:</b> {batchResponse.batch.pendingJobs}
            </p>
            <p style={{ color: "green" }}>
              <b>Processed Forms:</b> {batchResponse.batch.processedJobs}
            </p>
            <p style={{ color: "red" }}>
              <b>Failed Forms:</b> {batchResponse.batch.failedJobs}
            </p>

            <Divider />

            <p>
              <b>Created At:</b>{" "}
              {moment(batchResponse.batch.createdAt).fromNow()}
            </p>
            <p>
              <b>Is Canceled:</b>{" "}
              {batchResponse.batch.canceledAt ? "Yes" : "No"}
            </p>
            <p>
              <b>Finished At:</b>{" "}
              {batchResponse.batch.finishedAt
                ? moment(batchResponse.batch.finishedAt).fromNow()
                : "-"}
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
