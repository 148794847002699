import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const officeTypes = {
  OFFICE_LOADED: "OFFICE_LOADED",
  OFFICE_UNLOADED: "OFFICE_UNLOADED",
  ADD_OFFICE: "ADD_OFFICE",
  EDIT_OFFICE: "EDIT_OFFICE",
  DELETE_OFFICE: "DELETE_OFFICE",
};

export const loadOfficePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: officeTypes.OFFICE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadOfficePage = () => {
  return {
    type: officeTypes.OFFICE_UNLOADED,
    payload: {},
  };
};

export const addOffice = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/office", payload).then(
      (data) => {
        dispatch({
          type: officeTypes.ADD_OFFICE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editOffice = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/office/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: officeTypes.EDIT_OFFICE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeOffice = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/office/delete/${id}`).then(
      (data) => {
        dispatch({
          type: officeTypes.DELETE_OFFICE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
