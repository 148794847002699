import { formbuilderTypes } from '../action/formbuilderAction'

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
    switch (action.type) {
        case formbuilderTypes.FORMBUILDER_PAGE_LOADED:

            return action.payload;

        case formbuilderTypes.FORMBUILDER_PAGE_UNLOADED:
            return initialState;

        case formbuilderTypes.ADD_FORMBUILDER:
            return { ...state };
        case formbuilderTypes.EDIT_FORMBUILDER:
            return state;
        case formbuilderTypes.FETCH_FORMBUILDER:
            return action.payload;
    
        case formbuilderTypes.DELETE_FORMBUILDER:
            return state;
        default:
            return state;
    }
};
