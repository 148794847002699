import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Popup,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadSocialAnalyticsPage,
  unloadSocialAnalyticsPage,
  addSocialAnalytics,
  editSocialAnalytics,
  removeSocialAnalytics,
} from "../../redux/action/socialAnalyticsAction";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ModalComponent from "../../components/Modal";
import { alertActions } from "../../redux/action/alertAction";

interface fdataType {
  newData: {
    id: number;
    name: string;
    page_id: string;
    access_token: string;
    type: string;
    organization_id: number;
  };
}

interface RootState {
  social_analytics: {
    social_analytics: {
      data: {
        id: number;
        name: string;
        page_id: string;
        access_token: string;
        type: string;
        organization_id: number;
      }[];
      last_page: number;
    };
    organizations: {
      id: number;
      name: string;
    }[];
  };
}

type OptionType = {
  key: string | number;
  value: string | number;
  text: string;
}[];

export default function SocialAnalytics() {
  const [fdata, setData] = useState<fdataType>({
    newData: {
      id: 0,
      name: "",
      page_id: "",
      access_token: "",
      type: "",
      organization_id: 0,
    },
  });
  const [showingToken, setShowingToken] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);
  const [apiUrl, setApiUrl] = useState<string>("/social_analytics");
  const social_analytics = useSelector(
    (state: RootState) => state.social_analytics
  );
  const [total, setTotal] = useState<number>(1);
  const [organizationOptions, setOrganizationOptions] = useState<OptionType>(
    []
  );
  const [openInfo, setOpenInfo]: any = useState(false);
  const [add, setAdd]: any = useState(false);
  const [typeOptions] = useState<OptionType>([
    {
      key: "facebook",
      value: "facebook",
      text: "Facebook",
    },
    {
      key: "instagram",
      value: "instagram",
      text: "Instagram",
    },
    {
      key: "twitter",
      value: "twitter",
      text: "Twitter",
    },
    {
      key: "youtube",
      value: "youtube",
      text: "YouTube",
    },
    {
      key: "facebook_ads",
      value: "facebook_ads",
      text: "Facebook Ads",
    },
  ]);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSocialAnalyticsPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadSocialAnalyticsPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (social_analytics.social_analytics !== undefined) {
      setTotal(social_analytics.social_analytics.last_page);
      let orgs = social_analytics.organizations.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });
      setOrganizationOptions(orgs);
    }
  }, [social_analytics]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl("/social_analytics?page=" + pageInfo.activePage.toString());
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        page_id: "",
        access_token: "",
        type: "",
        organization_id: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let data = social_analytics.social_analytics.data.find((v) => v.id === id);

    setData({
      newData: {
        id: data!.id,
        name: data!.name,
        page_id: data!.page_id,
        access_token: data!.access_token,
        type: data!.type,
        organization_id: data!.organization_id,
      },
    });
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result?: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addSocialAnalytics(fdata.newData, () => {
      dispatch(loadSocialAnalyticsPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(editSocialAnalytics(fdata.newData, () => {
      dispatch(loadSocialAnalyticsPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeSocialAnalytics(deleteId, () => {
      dispatch(loadSocialAnalyticsPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  function RenderList(datas: any) {
    if (datas.data.social_analytics === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (datas.data.social_analytics.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.social_analytics.data.map((data: any) => {
      let org = datas.data.organizations.find(
        (a: any) => a.id === data.organization_id
      );
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.page_id}</Table.Cell>
          <Table.Cell style={{ textTransform: "uppercase" }}>
            {data.type}
          </Table.Cell>
          <Table.Cell>{org ? org.name : ""}</Table.Cell>

          <Table.Cell>
            <Popup
              trigger={
                <Button
                  primary
                  size="mini"
                  as={Link}
                  to={`/social_analytics/${data.id}`}
                  icon
                >
                  <Icon name="info circle" />
                </Button>
              }
              content="Quick View Info"
              position="top center"
            />
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setAdd(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Social Analytics</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setAdd(true);
        }}
        labelPosition="left"
      >
        Create Social Analytics
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>Social Analytics & Tokens</Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Page Id</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Organization</Table.HeaderCell>

            <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {social_analytics && <RenderList data={social_analytics} />}
        </Table.Body>
      </Table>
      {social_analytics.social_analytics === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {add ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {add ? "Create" : "Edit"} Social Analytics
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Social Account Name"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="page_id"
                label="Page ID/Ad Object ID"
                value={fdata.newData.page_id}
                onChange={handleChange}
                placeholder="Page ID/Ad Object ID"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Social Media Type"
                required
                options={typeOptions}
                label="Social Media Type"
                value={fdata.newData.type}
                name="type"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Organization"
                required
                options={organizationOptions}
                label="Select Organization"
                value={fdata.newData.organization_id}
                name="organization_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Form.Input
              fluid
              required
              action={{
                color: showingToken ? "teal" : "grey",
                icon: "eye",
                tabIndex: "-1",
                onClick: (e: any) => {
                  e.preventDefault();
                  setShowingToken(!showingToken);
                },
              }}
              id="accessTokenInput"
              type={showingToken ? "text" : "password"}
              name="access_token"
              label="Access Token"
              value={fdata.newData.access_token}
              onChange={handleChange}
              placeholder="Access Token"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
