import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const trainingUserDetailsType = {
  TRAINING_USER_DETAILS_LOADED: "TRAINING_USER_DETAILS_LOADED",
  TRAINING_USER_DETAILS_DATA_LOADED: "TRAINING_USER_DETAILS_DATA_LOADED",
};

export const loadTrainingUserDetailsPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: trainingUserDetailsType.TRAINING_USER_DETAILS_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const loadTrainingUserDetailsDataPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: trainingUserDetailsType.TRAINING_USER_DETAILS_DATA_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

