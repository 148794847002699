import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadLibraryPage,
  unloadLibraryPage,
  addLibrary,
  editLibrary,
  removeLibrary,
} from "../../redux/action/libraryAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { ImageModalComponent } from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import "react-quill/dist/quill.snow.css";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  libraries: {
    libraries: {
      data: {
        id: number;
        library_code: string;
        technology: string;
        type: string;
        number_of_storeys: number;
        number_of_bedrooms: number;
        number_of_common_rooms: number;
        kitchen_access: string;
        toilet_access: string;
        bedroom_size_length: number;
        bedroom_size_width: number;
        doors_front: number;
        doors_back: number;
        doors_left: number;
        doors_right: number;
        windows_front: number;
        windows_back: number;
        windows_left: number;
        windows_right: number;
        terrace: string;
        depth_of_terrace: string;
        terrace_type: string;
        roof_shape: string;
        roof_material: string;
        cad_link: string;
        pdf_link: string;
        image_link: string;
        boq_link: string;
        skp_link: string;
        app_id: number;
        roof_id: number;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
    roofs: {
      id: number;
      name: string;
      name_lang: string;
    }[];
  };
}

type optionType = {
  key: string | number;
  value: number | string;
  text: string;
}[];

export default function Library() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      library_code: "",
      technology: "",
      type: "",
      number_of_storeys: 0,
      number_of_bedrooms: 0,
      number_of_common_rooms: 0,
      kitchen_access: "",
      toilet_access: "",
      bedroom_size_length: 0,
      bedroom_size_width: 0,
      doors_front: 0,
      doors_back: 0,
      doors_left: 0,
      doors_right: 0,
      windows_front: 0,
      windows_back: 0,
      windows_left: 0,
      windows_right: 0,
      terrace: "",
      depth_of_terrace: "",
      terrace_type: "",
      roof_shape: "",
      roof_material: "",
      cad_link: "",
      pdf_link: "",
      image_link: "",
      boq_link: "",
      skp_link: "",
      app_id: 0,
      roof_id: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [url, setUrl] = useState("");
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/library");
  const [search, setSearch] = useState<string>("");
  const libraries = useSelector((state: RootState) => state.libraries);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<optionType>([]);
  const [roofOptions, setRoofOptions] = useState<optionType>([]);
  const [technologyOptions, setTechnologyOptions] = useState<optionType>([]);
  const [houseTypeOptions, setHouseTypeOptions] = useState<optionType>([]);
  const [kitchenAccessTypeOptions, setKitchenAccessTypeOptions] =
    useState<optionType>([]);
  const [toiletAccessTypeOptions, setToiletAccessTypeOptions] =
    useState<optionType>([]);
  const [terraceOptions, setTerraceOptions] = useState<optionType>([]);
  const [terraceTypeOptions, setTerraceTypeOptions] = useState<optionType>([]);
  const [roofShapeOptions, setRoofShapeOptions] = useState<optionType>([]);
  const [roofMaterialOptions, setRoofMaterialOptions] = useState<optionType>(
    []
  );
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadLibraryPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadLibraryPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (libraries.libraries !== undefined) {
      setTotal(libraries.libraries.last_page);
      var apps = libraries.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(apps);

      var roofs = libraries.roofs.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name + " / " + data.name_lang,
        };
      });

      setRoofOptions(roofs);

      setTechnologyOptions([
        {
          key: "Confined Masonry",
          value: "Confined Masonry",
          text: "Confined Masonry / Masonry Tertutup",
        },
        {
          key: "Timber",
          value: "Timber",
          text: "Timber / Kayu",
        },
        {
          key: "Timber Ferrocement Plaster",
          value: "Timber Ferrocement Plaster",
          text: "Timber Ferrocement Plaster / Dinding Kawat Plaster",
        },
      ]);

      setHouseTypeOptions([
        {
          key: "36",
          value: "36",
          text: "Type 36",
        },
        {
          key: "45",
          value: "45",
          text: "Type 45",
        },
        {
          key: "54",
          value: "54",
          text: "Type 54",
        },
      ]);

      setKitchenAccessTypeOptions([
        {
          key: "In",
          value: "In",
          text: "In / Di",
        },
        {
          key: "Both",
          value: "Both",
          text: "Both / Kedua",
        },
      ]);

      setToiletAccessTypeOptions([
        {
          key: "In",
          value: "In",
          text: "In / Dalam",
        },
        {
          key: "Out",
          value: "Out",
          text: "Out / Luar",
        },
        {
          key: "Both",
          value: "Both",
          text: "Both / Dalam dan luar",
        },
      ]);

      setTerraceOptions([
        {
          key: "Front",
          value: "Front",
          text: "Front / Depan",
        },
        {
          key: "Back",
          value: "Back",
          text: "Back / Kembali",
        },
        {
          key: "Both",
          value: "Both",
          text: "Both / Kedua",
        },
        {
          key: "No",
          value: "No",
          text: "No / Tidak",
        },
      ]);

      setTerraceTypeOptions([
        {
          key: "Timber",
          value: "Timber",
          text: "Timber / Kayu",
        },
        {
          key: "Concrete",
          value: "Concrete",
          text: "Concrete / Beton",
        },
      ]);

      setRoofShapeOptions([
        {
          key: "Hipped",
          value: "Hipped",
          text: "Hipped",
        },
        {
          key: "Gable",
          value: "Gable",
          text: "Gable",
        },
        {
          key: "Single Sloped",
          value: "Single Sloped",
          text: "Single Sloped",
        },
        {
          key: "Flat",
          value: "Flat",
          text: "Flat",
        },
      ]);

      setRoofMaterialOptions([
        {
          key: "CGI",
          value: "CGI",
          text: "CGI",
        },
        {
          key: "Gable",
          value: "Clay Tile",
          text: "Clay Tile / Genteng tanah liat",
        },
        {
          key: "Concrete",
          value: "Concrete",
          text: "Concrete / Beton",
        },
      ]);
    }
  }, [libraries]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl(
      "/library?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/library?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        library_code: "",
        technology: "",
        type: "",
        number_of_storeys: 0,
        number_of_bedrooms: 0,
        number_of_common_rooms: 0,
        kitchen_access: "",
        toilet_access: "",
        bedroom_size_length: 0,
        bedroom_size_width: 0,
        doors_front: 0,
        doors_back: 0,
        doors_left: 0,
        doors_right: 0,
        windows_front: 0,
        windows_back: 0,
        windows_left: 0,
        windows_right: 0,
        terrace: "",
        depth_of_terrace: "",
        terrace_type: "",
        roof_shape: "",
        roof_material: "",
        cad_link: "",
        pdf_link: "",
        image_link: "",
        boq_link: "",
        skp_link: "",
        app_id: 0,
        roof_id: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let item = libraries.libraries.data.find((v) => v.id === id);

    setData({
      newData: {
        id: item!.id,
        library_code: item!.library_code,
        technology: item!.technology,
        type: item!.type,
        number_of_storeys: item!.number_of_storeys,
        number_of_bedrooms: item!.number_of_bedrooms,
        number_of_common_rooms: item!.number_of_common_rooms,
        kitchen_access: item!.kitchen_access,
        toilet_access: item!.toilet_access,
        bedroom_size_length: item!.bedroom_size_length,
        bedroom_size_width: item!.bedroom_size_width,
        doors_front: item!.doors_front,
        doors_back: item!.doors_back,
        doors_left: item!.doors_left,
        doors_right: item!.doors_right,
        windows_front: item!.windows_front,
        windows_back: item!.windows_back,
        windows_left: item!.windows_left,
        windows_right: item!.windows_right,
        terrace: item!.terrace,
        depth_of_terrace: item!.depth_of_terrace,
        terrace_type: item!.terrace_type,
        roof_shape: item!.roof_shape,
        roof_material: item!.roof_material,
        cad_link: item!.cad_link,
        pdf_link: item!.pdf_link,
        image_link: item!.image_link,
        boq_link: item!.boq_link,
        skp_link: item!.skp_link,
        app_id: item!.app_id,
        roof_id: item!.roof_id,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addLibrary(fdata.newData, () => {
      dispatch(loadLibraryPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(editLibrary(fdata.newData, () => {
      dispatch(loadLibraryPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeLibrary(deleteId, () => {
      dispatch(loadLibraryPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  function RenderList(datas: any) {
    if (datas.data.libraries === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.libraries.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.libraries.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      let roof = datas.data.roofs.find((a: any) => a.id === data.roof_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>
          <Table.Cell>{data.library_code}</Table.Cell>
          <Table.Cell>{data.technology}</Table.Cell>
          <Table.Cell>
            {data.technology === "Confined Masonry"
              ? "Masonry Tertutup"
              : data.technology === "Timber"
              ? "Kayu"
              : data.technology === "Timber Ferrocement Plaster"
              ? "Dinding Kawat Plaster"
              : "-"}
          </Table.Cell>
          <Table.Cell>
            {data.roof_shape ? (roof ? roof.name : "") : "-"}
          </Table.Cell>
          <Table.Cell>
            {data.roof_shape ? (roof ? roof.name_lang : "") : "-"}
          </Table.Cell>
          <Table.Cell>{data.type}</Table.Cell>
          <Table.Cell>{data.number_of_storeys}</Table.Cell>
          <Table.Cell>{data.number_of_bedrooms}</Table.Cell>
          <Table.Cell>{data.number_of_common_rooms}</Table.Cell>
          <Table.Cell>
            {data.cad_link ? (
              <Button
                href={data.cad_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Cad
              </Button>
            ) : (
              ""
            )}

            {data.pdf_link ? (
              <Button
                href={data.pdf_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pdf
              </Button>
            ) : (
              ""
            )}

            {data.image_link ? (
              <Button onClick={() => setUrl(data.image_link)}>Image</Button>
            ) : (
              ""
            )}

            {data.boq_link ? (
              <Button
                href={data.boq_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Boq Pdf
              </Button>
            ) : (
              ""
            )}

            {data.skp_link ? (
              <Button
                href={data.skp_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Sketch
              </Button>
            ) : (
              ""
            )}
          </Table.Cell>

          <Table.Cell>
            <Button
              className="formbuilder-buttons"
              circular
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
              as={Link}
              to={`/library/${data.id}`}
              icon
            >
              <Icon name="info circle" />
            </Button>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Library</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Library
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Library
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>Library Code</Table.HeaderCell>
            <Table.HeaderCell>Technology</Table.HeaderCell>
            <Table.HeaderCell>Technology Lang.</Table.HeaderCell>
            <Table.HeaderCell>Roof</Table.HeaderCell>
            <Table.HeaderCell>Roof Lang.</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Storeys #</Table.HeaderCell>
            <Table.HeaderCell>Bedroom #</Table.HeaderCell>
            <Table.HeaderCell>Common Rooms #</Table.HeaderCell>
            <Table.HeaderCell>Files</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{libraries && <RenderList data={libraries} />}</Table.Body>
      </Table>

      {libraries.libraries === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="large"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Library
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="library_code"
                label="Library Code"
                value={fdata.newData.library_code}
                onChange={handleChange}
                placeholder="Enter Library Code"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                options={appOptions}
                label="Select App"
                value={fdata.newData.app_id}
                name="app_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Technology"
                required
                options={technologyOptions}
                label="Technology"
                value={fdata.newData.technology}
                name="technology"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select House Type"
                required
                options={houseTypeOptions}
                label="Select House Type"
                value={fdata.newData.type}
                name="type"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Roof Type"
                required
                options={roofOptions}
                label="Roof Type"
                value={fdata.newData.roof_id}
                name="roof_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="number_of_storeys"
                label="Enter Number of Storeys"
                value={fdata.newData.number_of_storeys}
                onChange={handleChange}
                placeholder="Enter Number of Storeys"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="number_of_bedrooms"
                label="Enter Number of Bedrooms"
                value={fdata.newData.number_of_bedrooms}
                onChange={handleChange}
                placeholder="Enter Number of Bedrooms"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="number_of_common_rooms"
                label="Enter Number of Common Room"
                value={fdata.newData.number_of_common_rooms}
                onChange={handleChange}
                placeholder="Enter Number of Common Room"
                min={0}
              />
            </Form.Field>

            <Form.Field></Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Kitchen Access"
                required
                options={kitchenAccessTypeOptions}
                label="Select Kitchen Access"
                value={fdata.newData.kitchen_access}
                name="kitchen_access"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Toilet Access"
                required
                options={toiletAccessTypeOptions}
                label="Select Toilet Access"
                value={fdata.newData.toilet_access}
                name="toilet_access"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="bedroom_size_length"
                label="Bedroom Length (Meters)"
                value={fdata.newData.bedroom_size_length}
                onChange={handleChange}
                placeholder="Bedroom Length"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="bedroom_size_width"
                label="Bedroom Width (Meters)"
                value={fdata.newData.bedroom_size_width}
                onChange={handleChange}
                placeholder="Bedroom Width"
                min={0}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="doors_front"
                label="Doors Front"
                value={fdata.newData.doors_front}
                onChange={handleChange}
                placeholder="Doors Front"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="doors_back"
                label="Doors Back"
                value={fdata.newData.doors_back}
                onChange={handleChange}
                placeholder="Doors Back"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="doors_left"
                label="Doors Left"
                value={fdata.newData.doors_left}
                onChange={handleChange}
                placeholder="Doors Left"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="doors_right"
                label="Doors Right"
                value={fdata.newData.doors_right}
                onChange={handleChange}
                placeholder="Doors Right"
                min={0}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="windows_front"
                label="Windows Front"
                value={fdata.newData.windows_front}
                onChange={handleChange}
                placeholder="Windows Front"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="windows_back"
                label="Windows Back"
                value={fdata.newData.windows_back}
                onChange={handleChange}
                placeholder="Windows Back"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="windows_left"
                label="Windows Left"
                value={fdata.newData.windows_left}
                onChange={handleChange}
                placeholder="Windows Left"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="windows_right"
                label="Windows Right"
                value={fdata.newData.windows_right}
                onChange={handleChange}
                placeholder="Windows Right"
                min={0}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Terrace Option"
                required
                options={terraceOptions}
                label="Select Terrace Option"
                value={fdata.newData.terrace}
                name="terrace"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required={fdata.newData.terrace === "No" ? false : true}
                type="number"
                name="depth_of_terrace"
                label="Depth Of Terrace"
                value={fdata.newData.depth_of_terrace}
                onChange={handleChange}
                placeholder="Depth Of Terrace"
                min={0}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Terrace Type"
                required={fdata.newData.terrace === "No" ? false : true}
                options={terraceTypeOptions}
                label="Select Terrace Type"
                value={fdata.newData.terrace_type}
                name="terrace_type"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field></Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Roof Shape"
                required
                options={roofShapeOptions}
                label="Select Roof Shape"
                value={fdata.newData.roof_shape}
                name="roof_shape"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Roof Material"
                required
                options={roofMaterialOptions}
                label="Select Roof Material"
                value={fdata.newData.roof_material}
                name="roof_material"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="cad_link"
                label="Upload CAD"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="pdf_link"
                label="Upload PDF"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="image_link"
                label="Upload Image"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="boq_link"
                label="Upload BOQ PDF"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Form.Input
              fluid
              type="file"
              name="skp_link"
              label="Upload Sketch File( .skp file )"
              onChange={handleChange}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
