import { combineReducers } from "redux";

//import * as UserManagement from "./UserManagement";
import * as SearchStore from "./SearchStore";
import { SideMenuReducer } from "./SideMenu";
import userReducer from "./userReducer";
import userIndividual from "./allIndividual";
import countryReducer from "./countryReducer";
import moduleReducer from "./moduleReducer";
import roleReducer from "./roleReducer";
import { authentication } from "./authReducer";
import { alert } from "./alertReducer";
import formbuilderReducer from "./formbuilderReducer";
import formdataReducer from "./formdataReducer";
import organizationReducer from "./organizationReducer";
import projectReducer from "./projectReducer";
import phaseReducer from "./phaseReducer";
import stageReducer from "./stageReducer";
import stepReducer from "./stepReducer";
import dashboardReducer from "./dashboardReducer";
import { userConstants } from "../action/authAction";
import languageReducer from "./languageReducer";
import trainingModuleReducer from "./trainingModuleReducer";
import moduleLessonReducer from "./moduleLessonReducer";
import liveTrainingReducer from "./liveTrainingReducer";
import appReducer from "./appReducer";
import lessonDataReducer from "./lessonDataReducer";
import awarenessModuleReducer from "./awarenessModuleReducer";
import tileReducer from "./tileReducer";
import aboutUsReducer from "./aboutUsReducer";
import appPointsReducer from "./appPointsReducer";
import appUsersReducer from "./appUsersReducer";
import transactionsReducer from "./transactionsReducer";
import awarenessNewsReducer from "./awarenessNewsReducer";
import awarenessDocumentReducer from "./awarenessDocumentReducer";
import awarenessVideoReducer from "./awarenessVideoReducer";
import awarenessTscReducer from "./awarenessTscReducer";
import contactReducer from "./contactReducer";
import officeReducer from "./officeReducer";
import messageReducer from "./messageReducer";
import siteSafetyReducer from "./siteSafetyReducer";
import categoryReducer from "./categoryReducer";
import housePartReducer from "./housePartReducer";
import houseImageReducer from "./houseImageReducer";
import floorReducer from "./floorReducer";
import roofReducer from "./roofReducer";
import materialCategoryReducer from "./materialCategoryReducer";
import materialListReducer from "./materialListReducer";
import faqReducer from "./faqReducer";
import boqDrawingReducer from "./boqDrawingReducer";
import boqRoofReducer from "./boqRoofReducer";
import unitPriceReducer from "./unitPriceReducer";
import boqReportReducer from "./boqReportReducer";
import galleryReducer from "./galleryReducer";
import sliderImageReducer from "./sliderImageReducer";
import sponsorReducer from "./sponsorReducer";
import libraryReducer from "./libraryReducer";
import covidTraceReducer from "./covidTraceReducer";
import formReducer from "./formReducer";
import plannerReducer from "./plannerReducer";
import houseCategoryReducer from "./houseCategoryReducer";
import strengthenHouseCategoryReducer from "./strengthenHouseCategoryReducer";
import socialAnalyticsReducer from "./socialAnalyticsReducer";
import strengthenHousePartReducer from "./strengthenHousePartReducer";
import strengthenAwarenessHouseImageReducer from "./strengthenAwarenessHouseImageReducer";
import trainingUserDetailsReducer from "./trainingUserDetailsReducer";
import sharingReducer from "./sharingReducer";
import documentationReducer from "./documentationReducer";
import staticPageReducer from "./staticPageReducer";
import { liveTrainingTypes } from "../action/liveTrainingAction";
import formbuilderMetaReducer from "./formbuilderMetaReducer";
import simpleReducer from "./simpleReducer";
import fileReducer from "./fileReducer";

export type RootState = ReturnType<typeof allReducer>;

const allReducer = combineReducers({
  authentication: authentication,
  sideMenu: SideMenuReducer,
  formbuilder: formbuilderReducer,
  formbuilder_meta: formbuilderMetaReducer,
  formdata: formdataReducer,
  country: countryReducer,
  module: moduleReducer,
  language: languageReducer,
  roles: roleReducer,
  organizations: organizationReducer,
  projects: projectReducer,
  stages: stageReducer,
  phases: phaseReducer,
  steps: stepReducer,
  users: userReducer,
  all_ind: userIndividual,
  dashboard: dashboardReducer,
  training_modules: trainingModuleReducer,
  module_lessons: moduleLessonReducer,
  lesson_datas: lessonDataReducer,
  live_trainings: liveTrainingReducer,
  organization_users: trainingUserDetailsReducer,
  awareness_modules: awarenessModuleReducer,
  tiles: tileReducer,
  awareness_news: awarenessNewsReducer,
  awareness_documents: awarenessDocumentReducer,
  awareness_videos: awarenessVideoReducer,
  awareness_tscs: awarenessTscReducer,
  faqs: faqReducer,
  about_us: aboutUsReducer,
  app_points: appPointsReducer,
  app_users: appUsersReducer,
  transactions: transactionsReducer,
  apps: appReducer,
  offices: officeReducer,
  messages: messageReducer,
  site_safety: siteSafetyReducer,
  contacts: contactReducer,
  house_parts: housePartReducer,
  strengthen_house_parts: strengthenHousePartReducer,
  strengthen_house_images: strengthenAwarenessHouseImageReducer,
  house_categories: houseCategoryReducer,
  strengthen_house_categories: strengthenHouseCategoryReducer,
  house_images: houseImageReducer,
  material_categories: materialCategoryReducer,
  material_lists: materialListReducer,
  floors: floorReducer,
  roofs: roofReducer,
  boq_drawings: boqDrawingReducer,
  boq_roofs: boqRoofReducer,
  unit_prices: unitPriceReducer,
  boq_reports: boqReportReducer,
  categories: categoryReducer,
  galleries: galleryReducer,
  files: fileReducer,
  slider_images: sliderImageReducer,
  sponsors: sponsorReducer,
  libraries: libraryReducer,
  covid_traces: covidTraceReducer,
  form: formReducer,
  planners: plannerReducer,
  social_analytics: socialAnalyticsReducer,
  sharings: sharingReducer,
  documentations: documentationReducer,
  static_pages: staticPageReducer,
  //userManagement: UserManagement.reducer,
  searchStore: SearchStore.reducer,
  alert: alert,
  simpleReducer: simpleReducer,
});

const rootReducer = (state: any, action: { type: string }) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
  }

  return allReducer(state, action);
};

export default rootReducer;
