import React, { useEffect } from 'react'
import FormbuilderForm from './FormbuilderForm'
import { useDispatch, useSelector } from 'react-redux';
import { unloadData, fetchData } from '../../redux/action/allAction';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash'

export default function FormbuilderEdit(props:any) {
    const formbuilder = useSelector((state:any) => state.all_ind);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchData(`/formBuilder/edit/${props.match.params.slug}`))
        return () => {
            dispatch(unloadData())
        }
         // eslint-disable-next-line
    }, [])

    if(_.isEmpty(formbuilder)){
        return(
            <Loader/>
        )
    }
    return (
        <FormbuilderForm type="Edit" data={formbuilder} slug={props.match.params.slug} />
    )
}
