import React, { useState, useEffect } from "react";
import {
  Pagination,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  List,
  Divider,
  Image,
  Label,
  Modal,
  DropdownProps,
  Dropdown,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadAppModulePage,
  unloadAppModulePage,
  addAppModule,
  editAppModule,
  removeAppModule,
} from "../../redux/action/appAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { ImageModalComponent } from "../../components/Modal";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  apps: {
    apps: {
      data: {
        id: number;
        app_name: string;
        organization_id: number;
        project_id: number;
        country_id: number;
        app_unique_id: string;
        image: string;
        client_id: string;
        client_secret: string;
        refresh_token: string;
        package_name: string;
        app_admins: any[];
        data_collection_enabled_status: boolean;
      }[];
      last_page: number;
      per_page: number;
    };
    countries: {
      id: number;
      country_name: string;
    }[];
    organizations: {
      id: number;
      name: string;
      project: {
        id: number;
        name: string;
        organization_id: number;
      }[]
    }[];
    app_admins: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type appAdminOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type projectOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function AppModule() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      app_name: "",
      organization_id: 0,
      project_id: 0,
      country_id: 0,
      image: "",
      client_id: "",
      client_secret: "",
      refresh_token: "",
      package_name: "",
      app_admins: [],
      data_collection_enabled_status: false,
    },
  });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [apiUrl, setApiUrl] = useState("/app");
  const [url, setUrl] = useState("");
  const apps = useSelector((state: RootState) => state.apps);
  const [total, setTotal] = useState(1);
  const [countryOptions, setCountryOptions] = useState<appOptionType>([]);
  const [organizationOptions, setOrganizationOptions] = useState<appOptionType>(
    []
  );
  const [projectOptions, setProjectOptions] = useState<projectOptionType>(
    []
  );
  const [appAdminOptions, setAppAdminOptions] = useState<appAdminOptionType>(
    []
  );
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadAppModulePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAppModulePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (apps.apps !== undefined) {
      setTotal(apps.apps.last_page);
      setPerPage(apps.apps.per_page || 5);
      var final_stage = apps.countries.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.country_name,
        };
      });

      setCountryOptions(final_stage);

      var org_stage = apps.organizations.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setOrganizationOptions(org_stage);

      setProjectOptions([]);

      if (apps.app_admins !== undefined) {
        let final_app_admins = apps.app_admins.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.first_name + " " + data.last_name,
          };
        });

        setAppAdminOptions(final_app_admins);
      }
    }
  }, [apps]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/app?page=" + pageInfo.activePage.toString() + "&per_page=" + perPage);
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage(data.value as number || 5);

    setApiUrl(
      "/app?page=" + activePage + "&per_page=" + (data.value || 5)
    );
  }

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        app_name: "",
        organization_id: 0,
        project_id: 0,
        country_id: 0,
        image: "",
        client_id: "",
        client_secret: "",
        refresh_token: "",
        package_name: "",
        app_admins: [],
        data_collection_enabled_status: false,
      },
    });
    setProjectOptions([]);
  };

  const selectDataForEditing = (id: number) => {
    let app = apps.apps.data.find((v) => v.id === id);

    if(app!.organization_id) {
      setProjectOptions(
        (apps.organizations.find(o => o.id === app!.organization_id)?.project ?? [])
        .map((proj) => ({
          key: proj.id,
          value: proj.id,
          text: proj.name
        }))
      );
    }

    setData({
      newData: {
        id: app!.id,
        app_name: app!.app_name,
        organization_id: app!.organization_id,
        country_id: app!.country_id,
        project_id: app!.project_id,
        image: "",
        client_id: app!.client_id,
        client_secret: app!.client_secret,
        refresh_token: app!.refresh_token,
        package_name: app!.package_name,
        data_collection_enabled_status: app!.data_collection_enabled_status,
        //@ts-ignore
        app_admins: app.app_admin.map((app_admin) => {
          return app_admin.user_id;
        }),
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////

    let name = result.name;

    if(name === 'organization_id') {
      setProjectOptions(
        (apps.organizations.find(o => o.id === value)?.project ?? [])
        .map((proj) => ({
          key: proj.id,
          value: proj.id,
          text: proj.name
        }))
      );
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addAppModule(fdata.newData, () => {
      dispatch(loadAppModulePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editAppModule(fdata.newData, () => {
      dispatch(loadAppModulePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

   
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeAppModule(deleteId, () => {
      dispatch(loadAppModulePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  const RenderList = React.useCallback((): any => {
    if (apps.apps === undefined) {
      return <Message>Loading ....</Message>;
    }
    if (apps.apps.data.length === 0) {
      return <Message>No data Found</Message>;
    }

    return apps.apps.data.map((data: any) => {
      // console.log(apps.app_admins);
      let country = apps.countries.find((a: any) => a.id === data.country_id);
      let organization = apps.organizations.find(
        (a: any) => a.id === data.organization_id
      );
      let project = (organization?.project ?? []).find((p) => p.id === data.project_id);

      return (
        <List divided verticalAlign="middle" key={data.id}>
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              {data.image ? (
                <Button
                  circular
                  icon
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  onClick={() => setUrl(data.image)}
                >
                  <Icon name="eye" />
                </Button>
              ) : (
                <i>-</i>
              )}
              {/* <Button
              primary
              circular
              as={Link}
              to={`/app/${data.id}`}
              icon
            >
              <Icon name="info circle" />
            </Button> */}
              <Button
                onClick={() => {
                  selectDataForEditing(data.id);
                  setOpenInfo(true);
                  setCreating(false);
                }}
                icon
                circular
                className="editButton"
              >
                <Icon name="pencil" />
              </Button>
              <Button
                onClick={() => deleteData(data.id)}
                icon
                circular
                className="deleteButton"
              >
                <Icon name="delete" />
              </Button>
            </List.Content>
            <Image
              size="tiny"
              rounded
              bordered
              src={data.image ?? (window.location.origin + '/logo192.png')}
              style={{ minHeight: "80px" }}
              onError={(e: any) => (e.target.src = "./logo192.png")}
            />
            <List.Content>
              <List.Header
                as={Link}
                to={`/app/${data.id}`}
                className="text-uppercase"
              >
                <span style={{ color: "var(--color-blue)" }}>
                  {data.app_name} ({country ? country.country_name : ""})
                </span>
              </List.Header>
              <List.Description>
                <strong>Unique Id:</strong> {data.unique_app_id}
              </List.Description>
              <List.Description>
                <strong>Organization:</strong>{" "}
                {organization ? organization.name : ""}
              </List.Description>
              <List.Description>
                <strong>Project:</strong>{" "}
                {project ? project.name : ""}
              </List.Description>
              <List.Description className="mt-2">
                {data.app_admin.map((app_admin_user: any) => {
                  return (
                    <React.Fragment>
                      <Label
                        as="a"
                        style={{
                          backgroundColor: "var(--color-blue)",
                          color: "white",
                          borderColor: "var(--color-blue)",
                        }}
                      >
                        <Icon name="user" />
                        {apps.app_admins.map((app_admin: any) => {
                          if (app_admin.id == app_admin_user.user_id) {
                            return (
                              app_admin.first_name + " " + app_admin.last_name
                            );
                          }
                          return "";
                        })}
                      </Label>
                    </React.Fragment>
                  );

                  //  console.log(app_admin_user.user_id);

                  // console.log(searchIndex);
                })}
              </List.Description>
              <List.Description className="mt-2">
                {data.data_collection_enabled_status ? <Label
                  as="a"
                  color="teal"
                  tag
                  size={"tiny"}
                  className={data.data_collection_enabled_status === false ? "d-none" : ""}
                >
                  {data.data_collection_enabled_status ? "Data Collection Enabled" : ""}
                </Label> : ""}
                
              </List.Description>
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
    // eslint-disable-next-line
  }, [apps]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Apps</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />

      <div className="app-card-org" key="2">
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create App
          <Icon name="plus" className="iconSize" />
        </Button>

        <Header>Apps</Header>
        {apps.apps === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          <RenderList />
        )}
        <Grid>
          <Grid.Column>
            <div style={{ marginRight: '5px', display: 'inline-block' }}>
              <p style={{ display: 'inline-block' }}>Per Page: </p>
              <Dropdown
                options={[
                  { key: 5, text: '5', value: 5 },
                  { key: 10, text: '10', value: 10 },
                  { key: 15, text: '15', value: 15 },
                  { key: 25, text: '25', value: 25 },
                  { key: 50, text: '50', value: 50 },
                  { key: 100, text: '100', value: 100 },
                ]}
                placeholder='Per Page'
                compact
                selection
                upward
                onChange={onPerPageChange}
                style={{ marginLeft: '5px' }}
                value={perPage}
              />
            </div>
            
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="small"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} App
        </Modal.Header>
        <Modal.Content>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="app_name"
                label="App Name"
                value={fdata.newData.app_name}
                onChange={handleChange}
                placeholder="Enter App name..."
              />
            </Form.Field>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Country"
                required
                options={countryOptions}
                label="Country"
                value={fdata.newData.country_id}
                name="country_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Organization"
                required
                options={organizationOptions}
                label="Organization"
                value={fdata.newData.organization_id}
                name="organization_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Project"
                required
                options={projectOptions}
                label="Project"
                value={fdata.newData.project_id}
                name="project_id"
                onChange={handleChange}
                disabled={projectOptions.length <= 0}
              />
            </Form.Field>
          </Form.Group>
          
          <Form.Field>
            <Form.Input
              fluid
              type="file"
              name="image"
              label="Upload Image For App"
              onChange={handleChange}
              accept="image/*"
            />
          </Form.Field>

          <Form.Field>
            <Form.Select
              placeholder="Select admin(s) for the app"
              fluid
              multiple
              search
              selection
              name="app_admins"
              label="Select admin(s) for the app"
              onChange={handleChange}
              value={fdata.newData.app_admins}
              options={appAdminOptions}
            />
          </Form.Field>
          <Form.Group grouped>
          <label>Enable / Disable</label>
          <Form.Checkbox
            toggle
            label={{ children: "Data Collection" }}
            name="data_collection_enabled_status"
            checked={fdata.newData.data_collection_enabled_status}
            onChange={handleChange}
          />
          
        </Form.Group>

        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
