const SEARCH_BY_TEXT = 'SEARCH_BY_TEXT';
const initialState:any = { text: '' };

export const actionCreators = {
    search: (text:string) => async (dispatch:any) => {
      dispatch({ type: SEARCH_BY_TEXT, text: text });
  },
};

export const reducer = (state=initialState, action:any) => {
  
  if (action.type === SEARCH_BY_TEXT) {
    return { ...state, text: action.text };
  }

  return state;
};
