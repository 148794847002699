import React, { ReactElement } from "react";
import ContainerComponent from "./ContainerComponent";
import { CheckIndividual, RenderTableFormDetail } from "./FormDataDetail";

interface Props {
  data: any[];
  label: string;
  field: any;
  components_to_hide?: string[];
}

function DataGridComponent({
  data,
  label,
  field,
  components_to_hide,
}: Props): ReactElement {
  const ListItem = () => (
    <React.Fragment>
      {data.map((item, index) => {
        if (Object.keys(item).length === 0 && item.constructor === Object)
          return null;

        return (
          <div
            key={index}
            style={{ marginLeft: "10px", boxShadow: "1px 1px 8px 2px #ddd" }}
          >
            <div className="title-secondary">
              <h5 style={{ fontSize: "0.9rem", fontWeight: "700" }}>
                {label}{" "}
                <span style={{ fontWeight: "900" }}>
                  ({index + 1} of {data.length})
                </span>
              </h5>
            </div>

            {Object.keys(item).map((key, index) => {
              let comp = field.find(
                (word: { key: string }) => word.key === key
              );
              if (comp === undefined) return null;

              return (
                <React.Fragment key={index}>
                  {/* If Container inside of current DataGrid render it as container */}
                  {comp.type && comp.type === "container" ? (
                    <ContainerComponent
                      data={item[key]}
                      label={comp.label}
                      field={comp.component ?? []}
                      components_to_hide={components_to_hide}
                    />
                  ) : comp.type &&
                    (comp.type === "datagrid" || comp.type === "editgrid") ? (
                    // DataGrid inside of current DataGrid
                    Object.keys(item[key]).map((key1, index) => (
                      <div key={index}>
                        <div className="title-tertiary">
                          <h5>
                            {comp["label"] || ""} ({index + 1} of{" "}
                            {item[key].length} )
                          </h5>
                        </div>
                        <RenderTableFormDetail
                          data={item[key][key1]}
                          field={comp["component"]}
                          components_to_hide={components_to_hide}
                        />
                      </div>
                    ))
                  ) : (
                    <CheckIndividual
                      index={index}
                      data={item[key]}
                      resource={comp["resource"] || ""}
                      type={comp["type"] || ""}
                      label={comp["label"] || ""}
                      data_name={key}
                      components_to_hide={components_to_hide}
                      options={comp["values"] || []}
                    />
                  )}{" "}
                </React.Fragment>
              );
            })}
          </div>
        );
      })}{" "}
    </React.Fragment>
  );

  return (
    <div key={label}>
      <ListItem />
      <br />
    </div>
  );
}

export default DataGridComponent;
