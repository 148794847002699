import React, { ReactElement, useState } from "react";
import {
  Card,
  Icon,
  Grid,
  Header,
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react";

import { ImageModalComponent } from "../../components/Modal";
import logo from "../../assets/images/BuildChange_Logo.png";

interface Props {
  handleAnswerChange: Function;
  question: any;
  type: string;
  cardHeader?: string;
  iconName?: SemanticICONS;
  color?: SemanticCOLORS;
  headerColor?: string;
  //   number: number;
}

export default function Question(props: Props): ReactElement {
  const [url, setUrl] = useState("");
  const { question, type } = props;

  return (
    <div className="section-content">
      <ImageModalComponent setUrl={setUrl} url={url} />

      <div className="question-container">
        <h4 style={{ float: "left" }}>{question.title}</h4>
        <span
          className={
            question.image
              ? "ml-3 badge badge-info image-tag"
              : "ml-3 badge badge-danger image-tag"
          }
          title={question.image ? question.image : "NO IMAGE"}
        >
          {question.image ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setUrl(question.image)}
            >
              VIEW IMAGE
            </span>
          ) : (
            "NO IMAGE"
          )}
        </span>
      </div>

      <div className="mcq-options">
        {question.answers.map((a: any, a_index: number) => {
          a = question.answers[a_index];
          return (
            <React.Fragment>
              <label htmlFor={"option" + question.id + a.id}>
                {question.question_type === "single" ? (
                  <input
                    type="radio"
                    name={"option" + question.id}
                    id={"option" + question.id + a.id}
                    style={{
                      zoom: 1.3,
                      transform: "translate(0,2px)",
                    }}
                    onChange={(e) =>
                      props.handleAnswerChange(
                        type,
                        question.question_type,
                        a.id,
                        question.id
                      )
                    }
                  />
                ) : (
                  <input
                    type="checkbox"
                    name={"option" + question.id}
                    id={"option" + question.id + a.id}
                    style={{
                      zoom: 1.3,
                      transform: "translate(0,2px)",
                    }}
                    onChange={(e) =>
                      props.handleAnswerChange(
                        type,
                        question.question_type,
                        a.id,
                        question.id
                      )
                    }
                  />
                )}
                {a.title}
                {/* <span className="custom-checkbox"></span> */}
                <span>
                  <span
                    className={
                      a.image
                        ? "ml-3 badge badge-info answer-image-tag"
                        : "ml-3 badge badge-danger answer-image-tag"
                    }
                    title={a.image ? a.image : "NO IMAGE"}
                  >
                    {a.image ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setUrl(a.image)}
                      >
                        VIEW IMAGE
                      </span>
                    ) : (
                      "NO IMAGE"
                    )}
                  </span>
                </span>
              </label>

              <br />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
