import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const socialAnalytics = {
  SOCIAL_ANALYTICS_LOADED: "SOCIAL_ANALYTICS_LOADED",
  SOCIAL_ANALYTICS_UNLOADED: "SOCIAL_ANALYTICS_UNLOADED",
  ADD_SOCIAL_ANALYTICS: "ADD_SOCIAL_ANALYTICS",
  EDIT_SOCIAL_ANALYTICS: "EDIT_SOCIAL_ANALYTICS",
  DELETE_SOCIAL_ANALYTICS: "DELETE_SOCIAL_ANALYTICS",
};

export const loadSocialAnalyticsPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: socialAnalytics.SOCIAL_ANALYTICS_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadSocialAnalyticsPage = () => {
  return {
    type: socialAnalytics.SOCIAL_ANALYTICS_UNLOADED,
    payload: {},
  };
};

export const addSocialAnalytics = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/social_analytics", payload).then(
      (data) => {
        dispatch({
          type: socialAnalytics.ADD_SOCIAL_ANALYTICS,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editSocialAnalytics = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/social_analytics/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: socialAnalytics.EDIT_SOCIAL_ANALYTICS,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeSocialAnalytics = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/social_analytics/delete/${id}`).then(
      (data) => {
        dispatch({
          type: socialAnalytics.DELETE_SOCIAL_ANALYTICS,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
