import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const housePartTypes = {
  HOUSE_PART_LOADED: "HOUSE_PART_LOADED",
  HOUSE_PART_UNLOADED: "HOUSE_PART_UNLOADED",
  ADD_HOUSE_PART: "ADD_HOUSE_PART",
  EDIT_HOUSE_PART: "EDIT_HOUSE_PART",
  DELETE_HOUSE_PART: "DELETE_HOUSE_PART",
};

export const loadHousePartPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: housePartTypes.HOUSE_PART_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadHousePartPage = () => {
  return {
    type: housePartTypes.HOUSE_PART_UNLOADED,
    payload: {},
  };
};

export const addHousePart = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/house_part",  {...payload,extra}).then(
      (data) => {
        dispatch({
          type: housePartTypes.ADD_HOUSE_PART,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editHousePart = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/house_part/update/${payload.id}`, {...payload,extra}).then(
      (data) => {
        dispatch({
          type: housePartTypes.EDIT_HOUSE_PART,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeHousePart = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/house_part/delete/${id}`).then(
      (data) => {
        dispatch({
          type: housePartTypes.DELETE_HOUSE_PART,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
