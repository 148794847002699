import React, { useState, useEffect, Component } from "react";
import { GrapesjsReact } from 'grapesjs-react';
import 'grapesjs-preset-webpage';
import 'grapesjs/dist/css/grapes.min.css';

import { Segment, Message } from "semantic-ui-react";
import {
  loadSharingTypePage,
  unloadSharingTypePage,
  collabSharingType,
} from "../../redux/action/sharingAction";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  sharings: {
    id: number;
    title: string;
    data: object[];
    log: string[];
    created_by?: {
      first_name: string;
      last_name: string;
    };
    created_at: string;
    updated_at: string;
  };
}

export default function SharingInfo(props: Props) {
  const [apiUrl] = useState(`/sharing/${props.match.params.id}`);
  const [collabApiUrl] = useState(`/sharing/collab/${props.match.params.id}`);
  const sharings = useSelector((state: RootState) => state.sharings);
  const [firstLoad, setFirstLoad] = useState(true);
  const [html, setHtml] = useState("");
  const [css, setCss] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSharingTypePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadSharingTypePage());
    };
  }, [apiUrl, dispatch]);

  const handleSubmit = (html: string, css: string) => {
    setHtml(html);
    setCss(css);
    dispatch(
      collabSharingType(collabApiUrl, {
        title: sharings.title,
        html: html,
        css: css,
      })
    );
  };

  return [
    <Segment key="1" className="no-pad-segment">
      {sharings === undefined ||
      !sharings ||
      Object.keys(sharings).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <Info
          firstLoad={firstLoad}
          setFirstLoad={setFirstLoad}
          sharings={sharings}
          submit={handleSubmit}
          id={props.match.params.id}
          dispatch={dispatch}
          apiUrl={apiUrl}
          html={html}
          css={css}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </Segment>,
  ];
}

const Info = (props: any) => {
  let data = props.sharings;
  let submit = props.submit;

  if (!data || !submit || props.loading) {
    return <Message>Loading ....</Message>;
  }

  // WEIRD REACT PROBLEM (USELESS REACT)
  if (data.hasOwnProperty("sharings") && data.sharings.data) {
    props.dispatch(loadSharingTypePage(props.apiUrl, () => { 
      props.setLoading(false);
    }));
    return <p>Loading taking longer ....</p>;
  }
  
  return (
    <div>
      <span id="grapes-loader" className="loader-overlay">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="191px"
          height="191px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#aaa"
            strokeWidth="3"
            r="9"
            strokeDasharray="42.411500823462205 16.13716694115407"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="0.5988023952095808s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </span>

      <span
        className="peak-msg"
        title={data.log.reverse().slice(0, 10).join("\n")}
      >
        {data.title}
      </span>

      <GrapesjsReact
        id="geditor"
        // presetType="webpage"

        // @ts-ignore
        height="88vh"

        // @ts-ignore
        storageManager={{
          autoLoad: false,
        }}

        // @ts-ignore
        plugins={[
          'gjs-preset-webpage',
          'gjs-blocks-basic'
        ]}
        
        onInit={(editor) => {
          editor.StorageManager.setAutosave(false);
          let grapesLoader = document.getElementById("grapes-loader");

          // SET THE JSON DATA (IF FIRSTLOAD LOAD FROM API - ELSE SHOW OLD DATA)
          if (props.firstLoad || !props.html) {
            // console.log(data);
            editor.setComponents(data.html);
            editor.setStyle(data.css);
            props.setFirstLoad(false);
            grapesLoader!.style.display = "none";
          } else {
            editor.setComponents(props.html);
            editor.setStyle(props.css);
          }

          // VIEW JSON BUTTON
          editor.Panels.addButton("devices-c", {
            id: "view-json",
            className: "fa fa-object-group",
            command(editor: any) {
              grapesLoader!.style.display = "flex";
              setTimeout(() => {
                let m = editor.Modal.setTitle(
                  "Components JSON Data - Useful For Backup and Raw Data"
                ).setContent(
                  `<textarea style="width:100%; height: 250px; outline: 0; font-family: monospace;" readonly>${JSON.stringify(
                    editor.getComponents()
                  )}</textarea>`
                );
                m.onceOpen(() => {
                  grapesLoader!.style.display = "none";
                });
                m.open();
              }, 200);
            },
            attributes: { title: "View JSON", id: "view-json" },
            active: false,
          });

          // SHARE BUTTON
          editor.Panels.addButton("devices-c", {
            id: "share-portal",
            className: "fa fa-share",
            command(editor: any) {
              let url =  window.location.origin +
              "/portal/" +
              data.public_id +
              "?by_u_id=" +
              // @ts-ignore
              (JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).data.id : 'anon');

              let input = document.createElement('input');
              input.setAttribute('value', url);
              document.body.appendChild(input);
              input.select();
              document.execCommand('copy');

              editor.Modal.setTitle("Public Portal Share URL")
                .setContent(
                  `<textarea style="width:100%; height: 28px; outline: 0; font-family: monospace; background: rgb(50, 50, 50); color: white; resize: none;" readonly>` +
                    url +
                    `</textarea>
                    <small style="animation: fade 1s ease-out forwards; display: block; margin-top: -5px; margin-bottom: 5px; color: orange;"><i>URL Copied to clipboard !</i></small>
                    <a href="`+url+`" target="_blank" class="btn btn-sm btn-primary">Open <i class="ml-1 fa fa-external-link"></i></a>
                    <br/><br/>
                    <b>Share With:</b>
                    <a href="https://twitter.com/intent/tweet/?text=Sharing Portal&url=`+url+`" target="_blank" rel="noopener" title="Share on Twitter" class="btn btn-sm text-white" style="background: #007bff;"><i class="fa fa-twitter"></i> Twitter</a>
                    <a href="https://facebook.com/sharer/sharer.php?u=`+url+`" target="_blank" rel="noopener" title="Share on Facebook" class="btn btn-sm text-white" style="background: #3b5998;"><i class="fa fa-facebook"></i> Facebook</a>
                    <a href="https://www.linkedin.com/shareArticle?mini=true&url=`+url+`&title=Sharing Portal&summary=Sharing&source=BCTAP" target="_blank" rel="noopener" title="Share on LinkedIn" class="btn btn-sm text-white" style="background: #0e76a8;"><i class="fa fa-linkedin"></i> LinkedIn</a>
                    <a href="mailto:?subject=Sharing Portal&amp;body=`+url+`" target="_blank" rel="noopener" title="Send By Mail" class="btn btn-sm text-white" style="background: #695d5d;"><i class="fa fa-envelope"></i> Mail</a>
                    `
                )
                .open();
            },
            attributes: { title: "Share", id: "share-portal" },
            active: false,
          });

          // SAVE DATA BUTTON
          editor.Panels.addButton("devices-c", {
            id: "save-json",
            className: "save-btn",
            async command(editor: any) {
              grapesLoader!.style.display = "flex";
              await submit(editor.getHtml(), editor.getCss());
              setTimeout(() => {
                grapesLoader!.style.display = "none";
              }, 4000);
            },
            label: '<i class="fa fa-save mr-2"></i> <span>Save</span>',
            attributes: { title: "Save the Document", id: "save-json" },
            active: false,
          });
        }}
      />
    </div>
  );
}
