import React, { useEffect } from "react";
import MainRoutes from "./routes";
import { history } from "./helper";
import { alertActions } from "./redux/action/alertAction";
import { useSelector, useDispatch } from "react-redux";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";

export default function App() {
  const alert = useSelector((state: any) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      if (process.env.NODE_ENV == "production") {
        dispatch(alertActions.clear());
      }
    });
  }, []);

  useEffect(() => {
    if (alert.message) {
      toast(
        {
          type: alert.type,
          icon: alert.type == "error" ? "warning sign" : "info",
          title: alert.title,
          description: alert.message,
          animation: "swing left",
          time: alert.type == "error" ? 8000 : 5000,
          size: "small",
        },
        () => {
          if (process.env.NODE_ENV !== "production") {
            dispatch(alertActions.clear());
          }
        }
      );
    }
  }, [alert]);

  return (
    <div>
      <SemanticToastContainer />

      <MainRoutes />
    </div>
  );
}
