import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { postData } from "../../redux/action/allAction";

import {
  Header,
  Icon,
  Tab,
  Form,
  Button,
  Divider,
  Modal,
  Card,
  Input,
  Popup,
} from "semantic-ui-react";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { AppConfig } from "../../config";

interface fdataType {
  userData: {
    first_name: string;
    last_name: string;
    email: string;
    oldpassword: string;
    password: string;
    confirm_password: string;
  };
}

export interface RootState {
  authentication: {
    user: {
      data: {
        first_name: string;
        last_name: string;
        email: string;
        id: number;
        organization_id: number;
      };
      role: string;
      organization: string;
      other_org: number[];
      other_orgs_details: {
        id: number;
        name: string;
        slug: string;
      }[];
    };
  };
}

export default function UserProfile() {
  const [fdata, setData] = useState<fdataType>({
    userData: {
      first_name: "",
      last_name: "",
      email: "",
      oldpassword: "",
      password: "",
      confirm_password: "",
    },
  });
  const [slug, setSlug] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [project, setProject] = useState([]);
  const [datashare, setDatashare] = useState(false);
  const [datastudio, setDataStudio] = useState("");

  const [openDataStudio, setOpenDataStudio] = useState<boolean>(false);
  const [openDataShareEnabled, setOpenDataShareEnabled] =
    useState<boolean>(false);
  const [openDataShareDisabled, setOpenDataShareDisabled] =
    useState<boolean>(false);

  const { user } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch();

  useEffect(() => {
    setData({
      userData: Object.assign({}, fdata.userData, {
        first_name: user.data.first_name,
        last_name: user.data.last_name,
        email: user.data.email,
      }),
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
  }, [user]);

  const getData = () => {
    if (user) {
      if (user.data !== undefined) {
        if (user.data.organization_id !== null) {
          apiService
            .getAll(`/GetData/org_ind/${user.data.organization_id}`)
            .then(
              (data) => {
                if (data.slug !== null) {
                  setSlug(data.slug);
                }
                if (data.data_share !== null) {
                  setDatashare(data.data_share);
                }

                if (data.data_studio !== null) {
                  setDataStudio(data.data_studio);
                }
                if (data.project !== null) {
                  setProject(data.project);
                }
              },
              (error) => {
                dispatch(alertActions.error(error.toString()));
              }
            );
        }
      }
    }
  };

  const enableDataShare = () => {
    apiService.getAll(`/org/data_share/${slug}`).then(
      (data) => {
        // dispatch(fetchData(apiUrl));
        getData();
        dispatch(alertActions.success(data.message));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      }
    );
  };

  const setDataShare = () => {
    if (datashare) {
      setOpenDataShareDisabled(false);
      setOpenDataShareEnabled(true);
    } else {
      setOpenDataShareDisabled(true);
      setOpenDataShareEnabled(false);
    }
  };

  const copyToClipboard = (url: string) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    setData({
      userData: Object.assign({}, fdata.userData, {
        [name]: value,
      }),
    });
  };

  const handleDataStudioChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    //let name = result.name;

    setDataStudio(value);
  };

  const DataStudioSave = () => {
    apiService
      .save(`/dataStudio/org/${slug}`, { data_studio: datastudio })
      .then(
        (data) => {
          // dispatch(fetchData(apiUrl));
          getData();
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const generateToken = () => {
    apiService.getToken(`/oauth/token`).then(
      (data) => {
        // dispatch(fetchData(apiUrl));
        if (data.access_token !== undefined) {
          setAuthToken(data.access_token);
        }

        dispatch(alertActions.success(data.message));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const handleProfileSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      postData(
        `/changeProfile`,
        {
          first_name: fdata.userData.first_name,
          last_name: fdata.userData.last_name,
          email: fdata.userData.email,
        },
        true
      )
    );
  };

  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      postData(`/passwordchange`, {
        new_password: fdata.userData.password,
        new_password_confirmation: fdata.userData.confirm_password,
        old_password: fdata.userData.oldpassword,
      })
    );

    setData({
      userData: Object.assign({}, fdata.userData, {
        password: "",
        confirm_password: "",
        oldpassword: "",
      }),
    });
  };

  const panes = [
    {
      menuItem: "Change Profile",
      render: () => (
        <Tab.Pane>
          <Form onSubmit={(e) => handleProfileSubmit(e)}>
            <h4 className="text-xl">Change Profile</h4>
            <hr />
            <br />
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="first_name"
                label="First Name"
                value={fdata.userData.first_name}
                onChange={handleChange}
                placeholder="Enter First name..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="last_name"
                label="Last Name"
                value={fdata.userData.last_name}
                onChange={handleChange}
                placeholder="Enter Last name..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="email"
                name="email"
                label="Email"
                value={fdata.userData.email}
                onChange={handleChange}
                placeholder="Enter email..."
              />
            </Form.Field>

            <Button primary type="submit">
              Submit
            </Button>
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Change Password",
      render: () => (
        <Tab.Pane>
          <Form onSubmit={(e) => handlePasswordSubmit(e)}>
            <h4 className="text-xl">Change Password</h4>
            <hr />
            <br />
            <Form.Field>
              <Form.Input
                fluid
                required
                type="password"
                name="oldpassword"
                label="Old Password"
                value={fdata.userData.oldpassword}
                onChange={handleChange}
                placeholder="Enter old password..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="password"
                name="password"
                label="Password"
                value={fdata.userData.password}
                onChange={handleChange}
                placeholder="Enter password..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="password"
                name="confirm_password"
                label="Password Confirmation"
                value={fdata.userData.confirm_password}
                onChange={handleChange}
                placeholder="Enter password Again..."
              />
            </Form.Field>

            <Button primary type="submit">
              Change Password
            </Button>
          </Form>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Modal
        size="small"
        open={openDataShareDisabled}
        dimmer="blurring"
        closeIcon
        onClose={() => setOpenDataShareDisabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Enable Data Shares?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenDataShareDisabled(false)}>
            Cancel
          </Button>
          <Button
            content="Enable"
            labelPosition="right"
            icon="checkmark"
            onClick={() => enableDataShare()}
            positive
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataShareEnabled}
        closeIcon
        onClose={() => setOpenDataShareEnabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Select Data Shares Link</p>

            <Card.Group>
              <Card fluid color="orange">
                <Card.Content>
                  <Card.Header>Api</Card.Header>
                </Card.Content>

                <Card.Description>
                  <Input fluid>
                    <input
                      defaultValue={AppConfig.apiUrl + "/orgshare/" + slug}
                    ></input>
                    <Popup
                      content="Link Copied to Clipboard"
                      onOpen={() =>
                        copyToClipboard(AppConfig.apiUrl + "/orgshare/" + slug)
                      }
                      on="click"
                      trigger={<Button>Copy</Button>}
                    />
                  </Input>
                </Card.Description>
              </Card>

              {project &&
                project.map((data: any, index: number) => {
                  return (
                    <Card fluid color="orange" key={index}>
                      <Card.Content>
                        <Card.Header>
                          {data["name"] ?? ""} Project Api
                        </Card.Header>
                      </Card.Content>

                      <Card.Description>
                        <Input fluid>
                          <input
                            defaultValue={
                              AppConfig.apiUrl +
                                "/projectshare/" +
                                data["slug"] ?? ""
                            }
                          ></input>
                          <Popup
                            content="Link Copied to Clipboard"
                            onOpen={() =>
                              copyToClipboard(
                                AppConfig.apiUrl +
                                  "/projectshare/" +
                                  data["slug"] ?? ""
                              )
                            }
                            on="click"
                            trigger={<Button>Copy</Button>}
                          />
                        </Input>
                      </Card.Description>
                    </Card>
                  );
                })}
            </Card.Group>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenDataShareEnabled(false)}>
            Cancel
          </Button>
          <Button
            content="Disable"
            labelPosition="right"
            icon="checkmark"
            onClick={() => enableDataShare()}
            negative
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataStudio}
        closeIcon
        onClose={() => setOpenDataStudio(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Studio Link</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Input
              fluid
              placeholder="Enter Data studio link..."
              name="datastudio"
              onChange={handleDataStudioChange}
              value={datastudio}
            />
            {datastudio !== "" ? (
              <a href={datastudio} target="_blank" rel="noopener noreferrer">
                <Button primary>Open Link</Button>
              </a>
            ) : (
              ""
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenDataStudio(false)}>
            Cancel
          </Button>
          <Button
            content="Save"
            labelPosition="right"
            icon="checkmark"
            onClick={() => DataStudioSave()}
            positive
          />
        </Modal.Actions>
      </Modal>

      <Divider horizontal>
        <Header as="h4">
          <Icon name="info" />
          User Details
        </Header>
      </Divider>
      <Header as="h2" icon textAlign="center">
        <Icon name="user" circular />
        <Header.Content>
          {user && user.data.first_name} {user && user.data.last_name}
          <br />
          {user && user.data.email}
          <br />
          Role : {user && user.role}
          <br />
          Organization : {user && user.organization} <br />
          Other Organizations :{" "}
          {user &&
            user.other_orgs_details &&
            user.other_orgs_details.map((o) => o.name).join(", ")}{" "}
          <br />
          {user && user.role === "Organization Admin" ? (
            <div>
              {" "}
              <Button
                color={datashare ? "twitter" : "google plus"}
                circular
                onClick={setDataShare}
              >
                Data Shares
              </Button>
              <Button circular onClick={() => setOpenDataStudio(true)}>
                Data Studio
              </Button>
            </div>
          ) : (
            ""
          )}
          <br />
          <Button
            content="Generate Token"
            onClick={generateToken}
            hidden={user && user.role === "User"}
          ></Button>
          <Input fluid hidden={authToken === ""}>
            <input readOnly defaultValue={authToken}></input>
            <Popup
              content="Token Copied to Clipboard"
              onOpen={() => copyToClipboard(authToken)}
              on="click"
              trigger={<Button>copy</Button>}
            />
          </Input>
        </Header.Content>
      </Header>
      <p></p>

      <Divider horizontal>
        <Header as="h4">
          <Icon name="settings" />
          Change Details
        </Header>
      </Divider>
      <Tab
        menu={{ fluid: true, vertical: true, tabular: true }}
        panes={panes}
      />
    </React.Fragment>
  );
}
