import { liveTrainingTypes } from "../action/liveTrainingAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case liveTrainingTypes.LIVE_TRAINING_LOADED:
      return action.payload;

    case liveTrainingTypes.LIVE_TRAINING_UNLOADED:
      return initialState;
    case liveTrainingTypes.ADD_LIVE_TRAINING:
      return { ...state };
    case liveTrainingTypes.EDIT_LIVE_TRAINING:
      return state;
    case liveTrainingTypes.DELETE_LIVE_TRAINING:
      return state;

    default:
      return state;
  }
};
