import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addFormbuilderMeta,
  editFormbuilderMeta,
} from "../../redux/action/formbuilderMetaAction";
import {
  Breadcrumb,
  Form,
  Select,
  Button,
  Label,
  Dropdown,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
//
import { Formio } from "@formio/react";
import { AppConfig } from "../../config";

interface Props {
  type: string;
  data?: any;
  slug?: string;
  schema?: any;
  location?: any;
}
interface DataType {
  newData: {
    id: number;
    name: string;
    organization?: number | null;
    meta_record_type: string;
  };
}
type OptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

interface Type {
  display: any;
}
export default function FormbuilderMetaForm(props: Props) {
  const [fdata, setData] = useState<DataType>({
    newData: {
      id: 0,
      name: "",
      organization: null,
      meta_record_type: "",
    },
  });

  const [component, setcomponent] = useState<any>();

  const [organizationOptions, setOrganizationOptions] = useState<OptionType>(
    []
  );

  const defaultMetaRecordTypeOptions = [
    { key: "BUILDING", value: "BUILDING", text: "BUILDING" },
    { key: "EVENT", value: "EVENT", text: "EVENT" },
    { key: "LOG", value: "LOG", text: "LOG" },
  ];

  const [metaRecordTypeOptions, setMetaRecordTypeOptions] = useState<OptionType>([]);

  const dispatch = useDispatch();
  const history = useHistory<any>();

  useEffect(() => {
    getData();
    FormioForm({ display: "form" });
    
    // EDIT
    if (props.type === "Edit" && props.data.form !== undefined) {
      var ndata = props.data.form;
      setData({
        newData: {
          id: ndata.id,
          name: ndata.name,
          organization: ndata.organization_id,
          meta_record_type: ndata.meta_record_type,
        },
      });

      if (history.location.state === undefined) {
        let component = JSON.parse(ndata.component);

        FormioForm(component);
        setcomponent(component);
      }

      // IF meta_record_type when editing form is new. Add is as well.
      if(
        ndata.meta_record_type 
        && !defaultMetaRecordTypeOptions.find(o => o.value === ndata.meta_record_type)
      ) {
        const val = ndata.meta_record_type.toUpperCase();
        setMetaRecordTypeOptions([
          { key: val, value: val, text: val },
          ...defaultMetaRecordTypeOptions]
        );
      }
      else {
        setMetaRecordTypeOptions(defaultMetaRecordTypeOptions);
      }
    }
    else {
      setMetaRecordTypeOptions(defaultMetaRecordTypeOptions);
    }

    if (history.location.state !== undefined) {
      let { schema, type } = history.location.state;
      dispatch(
        alertActions.success(
          "Upload success. Please edit at least one component before submit."
        )
      );
      FormioForm(schema);
      setcomponent(schema);
    }
  }, []);

  const getData = () => {
    apiService.getAll(`/formBuilder/meta/create`).then(
      (data) => {
        let final_org = data.organizations.map((data: any) => {
          return {
            key: data.id,
            value: `${data.id}`,
            text: data.name,
          };
        });

        setOrganizationOptions(final_org);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const FormioForm = (component: object) => {
    try {
      Formio.builder(document.getElementById("builder"), component, {
        builder: {
          premium: false,

          custom3: {
            title: "Metadata Fields",
            weight: 10,
            components: {
              _parent: {
                key: "_parent",
                title: "Parent",
                icon: "terminal",
                schema: {
                  label: "Parent",
                  key: "_parent",
                  type: "select",
                  input: true,
                  dataSrc: "resource",
                },
              },
              _mainLabel: {
                key: "_mainLabel",
                title: "Main Label",
                icon: "terminal",
                schema: {
                  label: "Main Label",
                  disabled: true,
                  tableView: true,
                  key: "_mainLabel",
                  type: "textfield",
                  input: true,
                },
              },
            },
          },
          layout: {
            components: {
              table: false,
              columns: false,
              panel: false,
              fieldset: true,
              well: false,
              tabs: false,
              collapsible: false,
            },
          },
          advanced: {
            components: {
              survey: false,
              //form: true,
            },
          },
          data: {
            components: {
              datamap: false,
              tree: false,
              container: false,
              editgrid: false,
            },
          },
        },
      }).then(function (builder: any) {
        setcomponent(builder.schema); // SET COMPONENT ON FIRST RENDERED STAGE

        builder.on("saveComponent", function () {
          //console.log(builder.schema);
          setcomponent(builder.schema); // On Save Fields
        });

        builder.on("deleteComponent", function () {
          setcomponent(builder.schema); // On Delete Fields
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(!component) {
      dispatch(
        alertActions.error(
          "Please add at least one component before submitting."
        )
      );
      return;
    }

    if (props.type !== "Edit") {
      dispatch(
        addFormbuilderMeta({
          ...fdata.newData,
          type: "form",
          component: JSON.stringify(component),
        })
      );
      clearForm();
    } else
      dispatch(
        editFormbuilderMeta(
          {
            ...fdata.newData,
            type: "form",
            component: JSON.stringify(component),
          },
          props.slug
        )
      );
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        organization: 0,
        meta_record_type: "",
      },
    });
    setcomponent("");
    FormioForm({ display: "form" });
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result?: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;
    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleMetaRecordTypeChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;

    setData({
      newData: Object.assign({}, fdata.newData, {
        meta_record_type: (value ? value.toUpperCase() : null),
      }),
    });
  };

  const handleCustomRecordTypeAddition = (event: any, data: any) => {
    const val = data.value.toUpperCase();
    setMetaRecordTypeOptions([
      ...metaRecordTypeOptions,
      { key: val, text: val, value: val },
    ]);
  };

  return (
    <div>
      <div key={1}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section link as={Link} to="/formbuilder" active>
            <FormattedMessage id="Formbuilder" defaultMessage="Formbuilder" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section link as={Link} to="/formbuilder/meta" active>
            <FormattedMessage
              id="MetaDataFields"
              defaultMessage="Meta Data Fields"
            />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>Meta {props.type}</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <div key={2}>
        <Form onSubmit={handleSubmit}>
          <div className="app-card-org">
            <Form.Group widths={3}>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Meta Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Meta Form Name..."
              />

              <Form.Field required>
                <label>Record Type</label>
                <Dropdown
                  required
                  search
                  selection
                  fluid
                  placeholder="Select Record Type"
                  options={metaRecordTypeOptions}
                  label="Record Type"
                  value={fdata.newData.meta_record_type}
                  name="metadata"
                  onChange={handleMetaRecordTypeChange}
                  allowAdditions
                  onAddItem={handleCustomRecordTypeAddition}
                  clearable
                  style={{ fontSize: '1rem'}}
                />
              </Form.Field>

              <Form.Field>
                <Form.Select
                  required
                  fluid
                  label="Organization"
                  placeholder="Select your Organization"
                  options={organizationOptions}
                  value={fdata.newData.organization ?? undefined}
                  name="organization"
                  onChange={handleChange}
                  clearable
                />
              </Form.Field>
            </Form.Group>

            <Button type="submit" className="createButton">
              Save Meta Form
            </Button>
          </div>

          <div className="app-card-org">
            <div id="builder"></div>
          </div>
        </Form>
      </div>
    </div>
  );
}
