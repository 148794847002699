import { awarenessDocumentTypes } from "../action/awarenessDocumentAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case awarenessDocumentTypes.AWARENESS_DOCUMENT_LOADED:

            return action.payload;

        case awarenessDocumentTypes.AWARENESS_DOCUMENT_UNLOADED:
            return initialState;
        case awarenessDocumentTypes.ADD_AWARENESS_DOCUMENT:
           
             return { ...state};
        case awarenessDocumentTypes.EDIT_AWARENESS_DOCUMENT:

            return state;
        case awarenessDocumentTypes.DELETE_AWARENESS_DOCUMENT:
           
            return state;

        default:
            return state;
    }
};
