import { alertActions } from './alertAction';
import { apiService } from '../../services';
import { Dispatch } from 'redux';
export const languageTypes = {

    LANGUAGE_PAGE_LOADED: "LANGUAGE_PAGE_LOADED",
    LANGUAGE_PAGE_UNLOADED: "LANGUAGE_PAGE_UNLOADED",
    ADD_LANGUAGE: "ADD_LANGUAGE",
    EDIT_LANGUAGE: "EDIT_LANGUAGE",
    DELETE_LANGUAGE: "DELETE_LANGUAGE",


}

export const loadLanguagePage = (url: string, onFinally: Function) => {

    return async (dispatch: any) => {

        apiService.getAll(url)
            .then(
                data => {

                    dispatch({
                        type: languageTypes.LANGUAGE_PAGE_LOADED,
                        payload: data
                    })
                    onFinally();

                },
                error => {
                    onFinally();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };
}



export const unloadLanguagePage = () => {
    return {
        type: languageTypes.LANGUAGE_PAGE_UNLOADED,
        payload: {}

    };
}

export const addLanguage = (payload: object, onSuccess: Function, onError: Function) => {


    return async (dispatch: any) => {

        apiService.save('/language', payload)
            .then(
                data => {

                    dispatch({
                        type: languageTypes.ADD_LANGUAGE,
                        payload: data
                    })
                    onSuccess();
                    // dispatch(alertActions.success('Data added successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };


}
interface payloadProps {
    id: number,
    language:string,
    language_logo:string,
    language_code:string,

}

export const editLanguage = (payload: payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch: any) => {

        apiService.update(`/language/update/${payload.id}`, payload)
            .then(
                data => {

                    dispatch({
                        type: languageTypes.EDIT_LANGUAGE,
                        payload: data
                    });
                    onSuccess();
                    // dispatch(alertActions.success('Data Edited successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}



export const removeLanguage = (id:number, onSuccess: Function, onError: Function) => {


    return async (dispatch:Dispatch) => {

        apiService._delete(`/language/delete/${id}`)
            .then(
                data => {

                    dispatch({
                        type: languageTypes.DELETE_LANGUAGE,
                       
                    });
                    onSuccess();
                    // dispatch(alertActions.success('Data Deleted successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}



