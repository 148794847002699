import React, { useState, useEffect, Component } from "react";
import {  Breadcrumb, Message } from "semantic-ui-react";
import {
  loadAwarenessModulePage,
  unloadAwarenessModulePage,
} from "../../redux/action/awarenessModuleAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface AwarenessModule {
  name: string;
  id: number;
  app_id: number;
  description: string;
  type: string;
  slug: string;
}

interface RootState {
  awareness_modules: {
    awareness_module: AwarenessModule;
    app: {
      app_name: string;
      id: number;
    };
    tiles: {
      id: number;
      name: string;
      value: string;
      type: string;
      order: string;
    }[];
  };
}

export default function AwarenessModuleInfo(props: Props) {
  const [apiUrl, setApiUrl] = useState(
    `/awareness_module/${props.match.params.id}`
  );
  const awareness_modules = useSelector(
    (state: RootState) => state.awareness_modules
  );

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setApiUrl(`/awareness_module/${props.match.params.id}`);
    dispatch(loadAwarenessModulePage(apiUrl, () => { 
      setLoading(false);
      // dispatch(alertActions.success("Data added successfully"));
    }));

    return () => {
      dispatch(unloadAwarenessModulePage());
    };
  }, [apiUrl, dispatch, props.match.params.id]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Awareness Module</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {awareness_modules === undefined ||
        Object.keys(awareness_modules).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {awareness_modules.awareness_module
              ? awareness_modules.awareness_module.name
              : props.match.params.id}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <div className="app-card-org" key="2">
      {/* <Header>Awareness Module Info</Header> */}
      {/* <Divider /> */}
      {awareness_modules === undefined ||
      Object.keys(awareness_modules).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <Info awareness_modules={awareness_modules} />
      )}
    </div>,
  ];
}

class Info extends Component<RootState> {
  render() {
    const data = this.props.awareness_modules;
    if (!data || !data.awareness_module) {
      return <Message>Loading ....</Message>;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <h3>{data.awareness_module.name}</h3>
          <p>Description: {data.awareness_module.description}</p>
          <p>
            Type: {data.awareness_module.type} &nbsp;&nbsp;&nbsp;{" "}
            <span>
              Slug: <i>{data.awareness_module.slug}</i>
            </span>
          </p>
          <b>
            Linked App:{" "}
            {data.app && data.app.app_name ? (
              <Link to={"/app/" + data.app.id}>{data.app.app_name}</Link>
            ) : (
              "APP NOT FOUND"
            )}
          </b>
          <hr />
          <br />
          {/* <b>Name: {data.awareness_module.name}</b>
          <p style={{margin: 0}}>Description: <small>{data.awareness_module.description}</small></p>
          <p style={{margin: 0}}>Type: {data.awareness_module.type}</p>
          <p style={{margin: 0}}>Slug: <i>{data.awareness_module.slug}</i></p>
          <br />
          <b>
            Linked App:{" "}
            {data.app && data.app.app_name
              ? <Link to={"/app/"+data.app.id}>{data.app.app_name}</Link>
              : "APP NOT FOUND"}
          </b>

          <hr /> */}
          <h4>Tiles</h4>
          <table className="table border table-striped col-sm-12">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>Type</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data.tiles) && data.tiles.length > 0 ? (
                data.tiles.map((i) => {
                  return (
                    <tr key={`tile_children_${i.id}`}>
                      <td>
                        <Link to={"/tile/" + i.id}>{i.name}</Link>
                      </td>
                      <td>{i.value}</td>
                      <td>{i.type}</td>
                      <td>{i.order}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={4}>
                    NO DATA
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
