import React from 'react'
import StepForm from './StepForm'



export default function StepCreate(props: { match: { params: { id: any } } }) {
    
    return (
        <StepForm type="Create" id={props.match.params.id}/>
    )
}
