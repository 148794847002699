import { alertActions } from './alertAction';
import { apiService } from '../../services';
export const organizationTypes = {
   
    ORGANIZATION_PAGE_LOADED: "ORGANIZATION_PAGE_LOADED",
    ORGANIZATION_PAGE_UNLOADED: "ORGANIZATION_PAGE_UNLOADED",
    ADD_ORGANIZATION:"ADD_ORGANIZATION",
    EDIT_ORGANIZATION:"EDIT_ORGANIZATION",
    DELETE_ORGANIZATION:"DELETE_ORGANIZATION",


}

export const loadOrganizationPage =  (url:string, onFinally: Function) => {
    
    return async (dispatch:any) =>{

        apiService.getAll(url)
        .then(
            data => { 
               
                dispatch({
                    type: organizationTypes.ORGANIZATION_PAGE_LOADED,
                    payload: data
                })
                onFinally();
               
            },
            error => {
                dispatch(alertActions.error(error.toString()));
                onFinally();
            }
        );
        

    };
}



export const unloadOrganizationPage = () => {
    return {
        type: organizationTypes.ORGANIZATION_PAGE_UNLOADED,
        payload: {}

    };
}

export const addOrganization = (payload:object, onSuccess: Function, onError: Function) => {
    

    return async (dispatch:any) =>{

        apiService.save('/organization',payload)
        .then(
            data => { 
               
                dispatch({
                    type: organizationTypes.ADD_ORGANIZATION,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data added successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };

   
}
interface payloadProps {
    id: number,
   // [all:any]:any
}
export const editOrganization = (payload:payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch:any) =>{

        apiService.update(`/organization/update/${payload.id}`,payload)
        .then(
            data => { 
               
                dispatch({
                    type: organizationTypes.EDIT_ORGANIZATION,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data Edited successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
}



  export const removeOrganization= (id:number, onSuccess: Function, onError: Function) =>  {
   
   
    return async (dispatch:any) =>{

        apiService._delete(`/organization/delete/${id}`)
        .then(
            data => { 
               
                dispatch({
                    type: organizationTypes.DELETE_ORGANIZATION
                   
                })
                onSuccess();
                // dispatch(alertActions.success('Data Deleted successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
  }

  

