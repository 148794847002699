import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Breadcrumb, Button, Tab } from "semantic-ui-react";
import { postData } from "../../redux/action/allAction";
import { Controlled as CodeMirror } from "react-codemirror2";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed';

interface Props {
  
  data?: any;
  location?: any;
  match:any
}

const codeMirrorOptions = {
  theme: "material",
  lineNumbers: true,
  scrollbarStyle: null,
  lineWrapping: true,
  autocorrect:true
};

export default function CreateHeaderFooter(props: Props) {

  const [values, setValues] = useState({
    header: `<style>
    .top-header{
        display: flex;
        justify-content: space-between;
    }
    
    .blue{
     color: #6383a8;
    }
    
    .green{
     color: #9ec54d;
    }
    </style>
    <div class="top-header">
         <h1>Logo</h1>
         <h4> <span class="blue">Build</span> <span class="green">Change</span></h4>
    </div>`,
    footer: `<style>
    .footer-report{
        display: flex;
        justify-content: space-between;
    }

.footer-report p{
  line-height: .5rem
}
    
    </style>
    <div class="footer-report">
         <div>
             <p>Build Change</p>
             <p>1001 Bannock St., Suite 467</p>
              <p>Denver, CO 80204, USA</p>
             <p>+1 303-953-2563<p>
         </div>
    </div>`,
    headerFooterName: "",
    id:0
  });
  const history = useHistory<any>();
 

  const dispatch = useDispatch();

  const headerFooterRef = useRef<any>();

  useEffect(() => {
    if (history.location.state !== undefined) {
      let { id, name,header,footer } = history.location.state;
      setValues({
        header:header,
        footer: footer,
        headerFooterName: name,
        id:id
      })
    }
  }, [])

  const handleTextArea = (e: any) => {
    e.persist();
    const { name, value } = e.target;
    setValues((val) => ({
      ...val,
      [name]: value,
    }));
  };

  const panes = [
   
    {
      menuItem: "Header",
      pane: (
        <Tab.Pane key="header">
               <CodeMirror
            value={values.header}
            className="code-editor"
            
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                header: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
              
        
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Footer",
      pane: (
        <Tab.Pane key="footer">
           <CodeMirror
            value={values.footer}
            className="code-editor"
            
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                footer: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
        
        </Tab.Pane>
      ),
    },
  ];



  const createHeader = () => {
    return { __html: values.header };
  };
  const createFooter = () => {
    return { __html: values.footer };
  };


  const handleSaveHeaderFooter = () => {
    dispatch(
      postData("/project/reportHeaderFooter/" + props.match.params.id, {
        name: values.headerFooterName,
        header: values.header,
        footer: values.footer,
        id:values.id
      })
    );
  };

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to="/project">
            Project
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section
            link
            as={Link}
            to={`/project/projectReport/${props.match.params.id}`}
          >
            Project Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>Header/Footer</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div>
          <Tab
            className="print-tab-menu"
            panes={panes}
            renderActiveOnly={false}
          />
        </div>
        <div className="render-results">
          
        <input className="custom-input" type="text" name="headerFooterName" placeholder="Enter Header/Footer Name" onChange={handleTextArea} value={values.headerFooterName} required />
        &nbsp;&nbsp;&nbsp;

          <Button
            className="export-button"
            onClick={() => {
                handleSaveHeaderFooter()
            }}
          >
            Save Header/Footer
          </Button>
         
            <div ref={headerFooterRef} className="result header-footer">
              <div
                className="header"
                dangerouslySetInnerHTML={createHeader()}
              ></div>
              <div
                className="footer"
                dangerouslySetInnerHTML={createFooter()}
              ></div>
            </div>
       
        </div>
      </div>
    </div>
  );
}
