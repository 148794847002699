import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const projectTypes = {
  PROJECT_PAGE_LOADED: "PROJECT_PAGE_LOADED",
  PROJECT_PAGE_UNLOADED: "PROJECT_PAGE_UNLOADED",
  ADD_PROJECT: "ADD_PROJECT",
  EDIT_PROJECT: "EDIT_PROJECT",
  DELETE_PROJECT: "DELETE_PROJECT",
};

export const loadProjectPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: projectTypes.PROJECT_PAGE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadProjectPage = () => {
  return {
    type: projectTypes.PROJECT_PAGE_UNLOADED,
    payload: {},
  };
};

export const addProject = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => {
  return async (dispatch: any) => {
    apiService.save("/project", payload).then(
      (data) => {
        dispatch({
          type: projectTypes.ADD_PROJECT,
          payload: data,
        });
        onSuccess(data);
        // dispatch(alertActions.success('Data added successfully'))
      },
      (error) => {
        onError(error);
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editProject = (
  payload: payloadProps,
  onSuccess: Function,
  onError: Function
) => {
  return async (dispatch: any) => {
    apiService.update(`/project/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: projectTypes.EDIT_PROJECT,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success('Data Edited successfully'))
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeProject = (
  id: number,
  onSuccess: Function,
  onError: Function,
  asRhiab: boolean = false
) => {
  return async (dispatch: any) => {
    apiService
      ._delete(
        asRhiab ? `/project/delete-all-rhiab/${id}` : `/project/delete/${id}`
      )
      .then(
        (data) => {
          dispatch({
            type: projectTypes.DELETE_PROJECT,
          });
          onSuccess();
          // dispatch(alertActions.success('Data Deleted successfully'))
        },
        (error) => {
          onError();
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
};
