import React, { useState } from "react";

import {
  Segment,
  Button,
  Icon,
  Message,
  Table,
  Modal,
  Form,
  Label,
} from "semantic-ui-react";
import { apiService } from "../../../../services";
import { alertActions } from "../../../../redux/action/alertAction";
import { useDispatch } from "react-redux";

import { AppConfig } from "../../../../config";
import { YearInput } from "semantic-ui-calendar-react";

export default function Boq(props: {
  project: any;
  refresh: Function;
  boqs: any[];
  raw: any;
}) {
  const raw = props.raw;
  const project = props.project;
  const refresh = props.refresh;
  const boqs = props.boqs ?? [];

  const dispatch = useDispatch();

  const [boqFormLoading, setBoqFormLoading] = useState(false);
  const [boqFormEditing, setBoqFormEditing] = useState<any>(null);
  const [boqForm, setBoqForm] = useState<any>({
    contractor: "",
    year: new Date().getFullYear().toString(),
    sheet: null,
  });

  const [openUploadBOQSheet, setOpenUploadBOQSheet] = useState<boolean>(false);

  const changeDefaultBoq = (id: any) => {
    if (project?.id) {
      setBoqFormLoading(true);

      apiService
        .save1(`/project/${project.id}/boq/${id}/default`, {})
        .then((res) => {
          refresh();
          dispatch(alertActions.success(res?.message ?? "Default BOQ Changed"));
        })
        .catch((err) => {
          dispatch(alertActions.error(err));
        })
        .finally(() => {
          setBoqFormLoading(false);
        });
    }
  };

  const deleteBoq = (id: any) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Delete this BOQ and its Chapters/Activities Permanently?\nAre you sure?"
      )
    ) {
      if (project?.id) {
        setBoqFormLoading(true);

        apiService
          ._delete(`/project/${project.id}/boq/${id}`)
          .then((res) => {
            refresh();
            dispatch(alertActions.success(res?.message ?? "BOQ Deleted"));
          })
          .catch((err) => {
            dispatch(alertActions.error(err));
          })
          .finally(() => {
            setBoqFormLoading(false);
          });
      }
    }
  };

  const initEditBoq = (id: any) => {
    if (project?.id) {
      setBoqFormLoading(true);

      apiService
        .getAll(`/project/${project.id}/boq/${id}`)
        .then((res) => {
          setBoqFormEditing(res);
          setBoqForm({
            contractor: res.contractor,
            year: res.year,
            sheet: null,
          });
          setOpenUploadBOQSheet(true);
        })
        .catch((err) => {
          dispatch(alertActions.error(err));
        })
        .finally(() => {
          setBoqFormLoading(false);
        });
    }
  };

  const submitBOQForm = () => {
    if (project.id) {
      setBoqFormLoading(true);

      let url = boqFormEditing
        ? `/project/${project.id}/boq/${boqFormEditing.id}`
        : `/project/${project.id}/boq`;

      apiService
        .save(url, boqForm, true)
        .then((data) => {
          // console.log(data);
          dispatch(alertActions.success("BOQ Chapters imported successfully."));
          resetBOQForm();
          refresh();
        })
        .catch((err) => {
          dispatch(alertActions.error(err));
        })
        .finally(() => {
          setBoqFormLoading(false);
        });
    }
  };

  const resetBOQForm = () => {
    setOpenUploadBOQSheet(false);
    setBoqFormEditing(null);
    setBoqForm({
      contractor: "",
      year: new Date().getFullYear().toString(),
      sheet: "",
    });
  };

  const RenderBOQs = (): any => {
    return (
      <div>
        <Button
          primary
          onClick={() => {
            setOpenUploadBOQSheet(true);
          }}
        >
          <Icon name="file excel"></Icon>
          Upload BOQ Excel Sheet
        </Button>

        <Button
          positive
          as="a"
          target="_blank"
          href={`${AppConfig.webUrl}/asset/rhiab/boq-template.xlsx`}
        >
          <Icon name="cloud download"></Icon>
          Download Excel Template
        </Button>

        {boqs === undefined || boqs.length === 0 ? (
          <Message>No BOQs Found</Message>
        ) : (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Contractor</Table.HeaderCell>
                <Table.HeaderCell>Year</Table.HeaderCell>
                <Table.HeaderCell>Updated By</Table.HeaderCell>
                <Table.HeaderCell>Chapters</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {boqs.map((boq: any) => (
                <Table.Row>
                  <Table.Cell>{boq.contractor}</Table.Cell>
                  <Table.Cell>{boq.year}</Table.Cell>
                  <Table.Cell>
                    <span title={boq.editor.email}>
                      {boq.editor.first_name} {boq.editor.last_name}
                    </span>
                  </Table.Cell>
                  <Table.Cell>{boq.chapters_count}</Table.Cell>
                  <Table.Cell>
                    {boq.default ? (
                      <Label
                        size="small"
                        icon="checkmark"
                        content="DEFAULT BOQ"
                        color="green"
                      />
                    ) : (
                      <Label
                        style={{ paddingRight: "1.14em" }}
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          changeDefaultBoq(boq.id);
                        }}
                        as="a"
                        icon={boqFormLoading ? "spinner" : "circle outline"}
                        basic
                        content="SET DEFAULT"
                        color="green"
                      />
                    )}

                    <Button
                      circular
                      icon="trash"
                      size="small"
                      color="red"
                      style={{ marginLeft: "8px" }}
                      onClick={(e) => deleteBoq(boq.id)}
                      loading={boqFormLoading}
                      disabled={boqFormLoading}
                    />

                    <Button
                      circular
                      icon="pencil"
                      size="small"
                      color="blue"
                      style={{ marginLeft: "8px" }}
                      onClick={(e) => initEditBoq(boq.id)}
                      loading={boqFormLoading}
                      disabled={boqFormLoading}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  };

  return (
    <div>
      <Modal
        size={boqFormEditing ? "large" : "small"}
        open={openUploadBOQSheet}
        closeIcon
        onClose={() => resetBOQForm()}
        style={{ position: "relative" }}
        as={Form}
        onSubmit={(e: any) => {
          submitBOQForm();
        }}
      >
        <Modal.Header>
          <Icon name={boqFormEditing ? "pencil" : "plus"} />
          {boqFormEditing ? "Edit the" : "Upload a"} BOQ Excel Sheet
        </Modal.Header>

        <Modal.Content>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="contractor"
                label="Contractor"
                value={boqForm.contractor}
                onChange={(e, data) =>
                  setBoqForm({ ...boqForm, contractor: data.value })
                }
                placeholder="Enter the Contractor Name"
              />
            </Form.Field>

            <Form.Field>
              <div className="required field">
                <label>Year</label>
              </div>
              <YearInput
                value={boqForm.year}
                name="year"
                placeholder="Year"
                onChange={(e, data) =>
                  setBoqForm({ ...boqForm, year: data.value })
                }
                closable={true}
                animation={"zoom"}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Form.Input
              fluid
              type="file"
              name="sheet"
              label={
                boqFormEditing
                  ? "Upload Updated BOQ Spreadsheet"
                  : "Upload the BOQ Spreadsheet"
              }
              onChange={(e, data) =>
                setBoqForm({ ...boqForm, sheet: e.target.files?.[0] })
              }
              accept=".xlsx, .xls"
              required={boqFormEditing ? false : true}
            />
          </Form.Field>

          {boqFormEditing && boqFormEditing.chapters && (
            <>
              <hr />
              <h4>Chapters</h4>
              <Table celled size="small" style={{ fontSize: "0.7em" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Chapter Code</Table.HeaderCell>
                    <Table.HeaderCell>Chapter Description</Table.HeaderCell>
                    <Table.HeaderCell>Activity Code</Table.HeaderCell>
                    <Table.HeaderCell>Activity Description</Table.HeaderCell>
                    <Table.HeaderCell>Activity Cost</Table.HeaderCell>
                    <Table.HeaderCell>Activity Unit</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {boqFormEditing.chapters.map((chapter: any) => {
                    return chapter.activities.map((activity: any) => {
                      return (
                        <Table.Row>
                          <Table.Cell>{chapter.chapter_code}</Table.Cell>
                          <Table.Cell>{chapter.chapter_description}</Table.Cell>
                          <Table.Cell>{activity.activity_code}</Table.Cell>
                          <Table.Cell>
                            {activity.activity_description}
                          </Table.Cell>
                          <Table.Cell>{activity.activity_cost}</Table.Cell>
                          <Table.Cell>{activity.activity_unit}</Table.Cell>
                        </Table.Row>
                      );
                    });
                  })}
                </Table.Body>
              </Table>
            </>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button color="black" onClick={() => resetBOQForm()}>
            Cancel
          </Button>

          <Button
            content={boqFormEditing ? "Upload & Update" : "Upload & Create"}
            labelPosition="right"
            icon="checkmark"
            type="submit"
            positive
            loading={boqFormLoading}
            disabled={boqFormLoading}
          />
        </Modal.Actions>
      </Modal>

      <RenderBOQs />
    </div>
  );
}
