import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Popup,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadModulePage,
  unloadModulePage,
  addModule,
  editModule,
  removeModule,
} from "../../redux/action/moduleAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";

interface DataType {
  newData: {
    id: number;
    name: string;
    slug: string;
  };
}
interface RootState {
  module: {
    data: { name: string; slug: string; id: number }[];
    last_page: number;
    per_page: number;
  };
}

const Module = () => {
  const [fdata, setData] = useState<DataType>({
    newData: {
      id: 0,
      name: "",
      slug: "",
    },
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState<number>(1);
  const [apiUrl, setApiUrl] = useState<string>("/module");
  const modules = useSelector((state: RootState) => state.module);
  const [total, setTotal] = useState<number>(1);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState<number>(5);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      loadModulePage(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadModulePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (modules.last_page !== undefined) {
      setTotal(modules.last_page);
    }
    setPerPage(modules.per_page || 5);
    // eslint-disable-next-line
  }, [modules]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/module?page=" + pageInfo.activePage.toString() + "&per_page=" + perPage
    );
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage((data.value as number) || 5);

    setApiUrl("/module?page=" + activePage + "&per_page=" + (data.value || 5));
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        slug: "",
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let module = modules.data.find((v) => v.id === id);

    setData({
      newData: {
        id: module!.id,
        name: module!.name,
        slug: module!.slug,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        addModule(
          fdata.newData,
          () => {
            dispatch(
              loadModulePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data added successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        editModule(
          fdata.newData,
          () => {
            dispatch(
              loadModulePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data Edited successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      removeModule(
        deleteId,
        () => {
          dispatch(
            loadModulePage(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  function RenderList(datas: any) {
    if (datas.data === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (datas.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    // console.log(datas.data)
    return datas.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.slug}</Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>

            <Popup
              trigger={
                <Button
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                  }}
                  circular
                  as={Link}
                  to={"/formbuilder?module_id=" + data.id}
                  icon={"book"}
                ></Button>
              }
              content={
                "Filter & Show Form Builder by this Module: " + data.name
              }
              position="top right"
              size="tiny"
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          <FormattedMessage id="Module" defaultMessage="Module" />
        </Breadcrumb.Section>
      </Breadcrumb>

      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Module
        <Icon name="plus" className="iconSize" />
      </Button>

      <Header>
        {" "}
        <FormattedMessage id="Module" defaultMessage="Module" /> &nbsp;
        <FormattedMessage id="List" defaultMessage="List" />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="ID" defaultMessage="ID" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {" "}
              <FormattedMessage id="Module" defaultMessage="Module" /> Name
            </Table.HeaderCell>
            <Table.HeaderCell>
              {" "}
              <FormattedMessage id="Slug" defaultMessage="Slug" />
            </Table.HeaderCell>

            <Table.HeaderCell width={2}>
              <FormattedMessage id="Actions" defaultMessage="Actions" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{modules && <RenderList data={modules.data} />}</Table.Body>
      </Table>
      {modules.data === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <div style={{ marginRight: "5px", display: "inline-block" }}>
            <p style={{ display: "inline-block" }}>Per Page: </p>
            <Dropdown
              options={[
                { key: 5, text: "5", value: 5 },
                { key: 10, text: "10", value: 10 },
                { key: 15, text: "15", value: 15 },
                { key: 25, text: "25", value: 25 },
                { key: 50, text: "50", value: 50 },
                { key: 100, text: "100", value: 100 },
              ]}
              placeholder="Per Page"
              compact
              selection
              upward
              onChange={onPerPageChange}
              style={{ marginLeft: "5px" }}
              value={perPage}
            />
          </div>

          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
      key="3"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Module
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Name</label>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                value={fdata.newData.name}
                onChange={(e) => handleChange(e)}
                placeholder="Enter Module name..."
              />
            </Form.Field>

            <Form.Field>
              <label> Slug</label>
              <Form.Input
                fluid
                required
                type="text"
                name="slug"
                value={fdata.newData.slug}
                onChange={(e) => handleChange(e)}
                placeholder="Enter Module slug..."
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
};

export default Module;
