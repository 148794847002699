import { trainingUserDetailsType } from "../action/trainingUserDetailsAction"



const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case trainingUserDetailsType.TRAINING_USER_DETAILS_LOADED:

            return action.payload;
        case trainingUserDetailsType.TRAINING_USER_DETAILS_DATA_LOADED:

            return action.payload;

        default:
            return state;
    }
};
