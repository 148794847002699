import { stepTypes } from "../action/stepAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case stepTypes.STEP_PAGE_LOADED:

            return action.payload;

        case stepTypes.STEP_PAGE_UNLOADED:
            return initialState;
        case stepTypes.ADD_STEP:
           
             return { ...state};
        case stepTypes.EDIT_STEP:

            return state;
        case stepTypes.DELETE_STEP:
           
            return state;
        case stepTypes.FETCH_STEP:
            return  [ ...state, action.payload];         

        default:
            return state;
    }
};
