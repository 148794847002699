import { materialListTypes } from "../action/materialListAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case materialListTypes.MATERIAL_LIST_LOADED:
      return action.payload;

    case materialListTypes.MATERIAL_LIST_UNLOADED:
      return initialState;
    case materialListTypes.ADD_MATERIAL_LIST:
      return { ...state };
    case materialListTypes.EDIT_MATERIAL_LIST:
      return state;
    case materialListTypes.DELETE_MATERIAL_LIST:
      return state;

    default:
      return state;
  }
};
