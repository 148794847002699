import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  Segment,
  Message,
  Loader,
  Button,
  Icon,
  Label,
} from "semantic-ui-react";
import { Formio } from "@formio/react";
import { useDispatch } from "react-redux";

import ModalComponent from "../../components/Modal";
import { unloadFormdataPage } from "../../redux/action/formDataAction";
import { AppConfig } from "../../config";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";

export default function RheatFill(props: any) {
  const dispatch = useDispatch();
  const [shared, setShared] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [lat, setLatitude] = useState<number>(0);
  const [long, setLongitude] = useState<number>(0);

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [evaluatorName, setEvaluatorName] = useState<string>("");
  const [evaluatorOrg, setEvaluatorOrg] = useState<string>("");
  const [dataId, setDataId] = useState<string>("");

  const [rheatId, setRheatId] = useState<
    {
      title: string;
      id: string;
      hide: boolean;
      evalName: string;
      evalOrg: string;
    }[]
  >([]);

  const [pdfHidden, setPdfHidden] = useState<boolean>(true);
  const [cmpBtnHidden, setCmpBtnHidden] = useState<boolean>(false);
  const [hideForm, setHideForm] = useState<boolean>(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    checkRelatedForm(dataId);

    let rheatObject = localStorage.getItem("rheat");
    let rheatObject_tmp = JSON.parse(rheatObject || "[]");

    if (rheatObject_tmp[0] !== undefined) {
      setEvaluatorName(rheatObject_tmp[0].evalName ?? evaluatorName);
      setEvaluatorOrg(rheatObject_tmp[0].evalOrg ?? "");
      setRheatId(rheatObject_tmp);
    }

    return () => {
      dispatch(unloadFormdataPage());
    };
  }, []);

  useEffect(() => {
    if (rheatId) {
      localStorage.setItem("rheat", JSON.stringify(rheatId));
    } else {
      localStorage.removeItem("rheat");
    }
  }, [rheatId]);

  useEffect(() => {
    checkRelatedForm(dataId);
  }, [evaluatorName]);

  const checkRelatedForm = (id: string, lat1?: number, long1?: number) => {
    if (lat1 === undefined && lat === 0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            showPosition(props.match.params.slug, "", null, position);
          },
          function (error) {
            if (error.code == error.PERMISSION_DENIED) {
              console.log("Geo Location Permission Denied.. Using 0,0");
            }
            formio(props.match.params.slug, "", null, lat, long);
          }
        );
      }
    } else {
      formio(props.match.params.slug, "", null, lat1, long1);
    }
  };

  const PreviewPdf = (data_id: string, compare?: boolean, title?: string) => {
    let body = {};
    if (compare) {
      const filtered = rheatId.filter((obj) => {
        return obj.hide === false;
      });
      if (filtered.length > 0) {
        if (filtered.length > 6) {
          dispatch(alertActions.error("Only maximum 6 data can be compared"));
          return;
        }

        var newArr = filtered.map(function (val, index) {
          return val.id;
        });

        body["ids"] = newArr;
      } else {
        dispatch(alertActions.error("No data to compare"));
        return;
      }
    }
    apiService
      .getfilePost(
        `/formBuilder/exportpdfWithoutAuth/${props.match.params.slug}/${data_id}`,
        body
      )
      .then(
        (data) => {
          setTimeout(() => {
            setPdfUrl(data);
            setPdfHidden(false);
            setHideForm(true);
          }, 100);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const handleReportVisibility = (data_id: string, hide: boolean) => {
    let newState = [...rheatId];
    const haveRheatId = rheatId.findIndex((obj) => {
      return obj.id === data_id;
    });
    newState[haveRheatId].hide = hide;
    setRheatId(newState);
  };

  const showPosition = (
    data_slug: string,
    id: string,
    data: any,
    position: any
  ) => {
    let lat = position.coords.latitude ?? 0;
    let long = position.coords.longitude ?? 0;
    setLatitude(lat);
    setLongitude(long);

    formio(
      data_slug,
      id,
      data,
      position.coords.latitude,
      position.coords.longitude
    );
  };

  const formio = (
    slug_temp: string,
    data_id: string,
    main_data: any,
    lat1?: number,
    long1?: number
  ) => {
    apiService
      .getAll(`/formBuilder/checkifshared/${props.match.params.slug}`)
      .then(
        (data) => {
          setShared(data.shared);
          setName(data.name);
          if (data.shared) {
            Formio.createForm(
              document.getElementById("formio"),
              `${AppConfig.apiUrl}/formBuilders/${slug_temp}`,
              {
                // saveDraft: true,
                evaluatorName: "disabled",
              }
            ).then(function (form: any) {
              // Prevent the submission from going to the form.io server.
              if (main_data === undefined) main_data = null;
              if (main_data !== null) {
                form.submission = {
                  data: main_data,
                };

                if (main_data.evaluatorName) {
                  try {
                    form.components[1].element.children[1].children[0].setAttribute(
                      "disabled",
                      "disabled"
                    );
                  } catch {}
                }
              } else {
                if (evaluatorName !== "") {
                  form.submission = {
                    data: {
                      evaluatorName: evaluatorName,
                      evaluatorOrganization: evaluatorOrg,
                    },
                  };

                  try {
                    form.components[1].element.children[1].children[0].setAttribute(
                      "disabled",
                      "disabled"
                    );
                  } catch {}
                } else {
                  let rheatObject = localStorage.getItem("rheat");
                  let rheatObject_tmp = JSON.parse(rheatObject || "[]");
                  if (rheatObject_tmp[0] !== undefined) {
                    setEvaluatorName(
                      rheatObject_tmp[0].evalName ?? evaluatorName
                    );
                    setEvaluatorOrg(rheatObject_tmp[0].evalOrg ?? "");
                    setRheatId(rheatObject_tmp);

                    form.submission = {
                      data: {
                        evaluatorName:
                          rheatObject_tmp[0].evalName ?? evaluatorName,
                        evaluatorOrganization:
                          rheatObject_tmp[0].evalOrg ?? evaluatorOrg,
                      },
                    };

                    try {
                      form.components[1].element.children[1].children[0].setAttribute(
                        "disabled",
                        "disabled"
                      );
                    } catch {}
                  }
                }
              }
              form.nosubmit = true;

              // Triggered when they click the submit button.
              form.on("submit", function (submission: any) {
                if (lat1) {
                  submission["data"]["_latitude"] = lat1;
                  submission["data"]["_longitude"] = long1;
                } else {
                  submission["data"]["_latitude"] = lat;
                  submission["data"]["_longitude"] = long;
                }

                if (main_data === null && data_id !== "") {
                  submission["data"]["_related_id"] = data_id;
                }

                apiService
                  .save(
                    `/website_formBuilders/${slug_temp}/submission`,
                    submission
                  )
                  .then(
                    (formdata) => {
                      let title = submission["data"]["title"] ?? "";
                      let evaluatorName =
                        submission["data"]["evaluatorName"] ?? "";
                      let evaluatorOrganization =
                        submission["data"]["evaluatorOrganization"] ?? "";

                      dispatch(alertActions.success("Data Saved successfully"));

                      setEvaluatorName(evaluatorName);
                      setEvaluatorOrg(evaluatorOrganization);
                      setCmpBtnHidden(false);

                      if (formdata.data_id !== undefined) {
                        let newState = [...rheatId];
                        const haveRheatId = newState.findIndex((obj) => {
                          return obj.id === formdata.data_id;
                        });

                        if (haveRheatId !== undefined && haveRheatId >= 0) {
                          newState[haveRheatId].title = title;
                          setRheatId(newState);
                        } else {
                          setRheatId((prevState) => [
                            ...prevState,
                            {
                              title: title,
                              hide: false,
                              id: formdata.data_id,
                              evalOrg: evaluatorOrganization,
                              evalName: evaluatorName,
                            },
                          ]);
                        }

                        PreviewPdf(formdata.data_id, false, title);
                        setDataId(formdata.data_id);
                        checkRelatedForm(formdata.data_id, lat1, long1);
                      } else {
                        setPdfHidden(true);
                        formio(slug_temp, data_id, null, lat1, long1);
                      }
                    },
                    (error) => {
                      setPdfHidden(true);
                      dispatch(alertActions.error(error.toString()));
                    }
                  );
              });
            });
          }
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const handleResetForm = () => {
    setDataId("");
    setPdfHidden(true);
    setHideForm(false);
    checkRelatedForm("", lat, long);
  };

  const handleResetRheatIds = () => {
    setRheatId([]);
    setCmpBtnHidden(true);
    localStorage.removeItem("rheat");
    setEvaluatorName("");
    setEvaluatorOrg("");
    setOpen(false);
  };

  const handleShowReport = (compare: boolean, Id?: string) => {
    if (Id) setDataId(Id);
    setCmpBtnHidden(compare);
    if (Id) {
      PreviewPdf(Id, compare);
    } else {
      PreviewPdf(dataId, compare);
    }
  };

  const handleEditReport = (id: string) => {
    setDataId(id);
    apiService
      .getAll(`/formBuilder/finddata/${props.match.params.slug}/${id}`)
      .then((data) => {
        if (data != null) {
          setPdfHidden(true);
          setHideForm(false);
          formio(props.match.params.slug, "", data, lat, long);
        }
      });
  };

  return (
    <Segment>
      <div>
        <ModalComponent
          open={open}
          setOpen={setOpen}
          confirmDelete={handleResetRheatIds}
          task="Reset"
          text="Are you sure you want to reset all history?"
        />

        <Button color="linkedin" onClick={() => setOpen(true)} floated="right">
          <Icon name="refresh" />
          Reset All
        </Button>
        <Button color="twitter" onClick={handleResetForm} floated="right">
          <Icon name="refresh" />
          Reset Form
        </Button>
        {cmpBtnHidden ? (
          <Button
            hidden={pdfHidden}
            floated="right"
            onClick={() => handleShowReport(false)}
          >
            Show Individual Report
          </Button>
        ) : (
          <Button
            hidden={pdfHidden}
            floated="right"
            onClick={() => handleShowReport(true)}
          >
            Show comparative Report
          </Button>
        )}

        {hideForm ? (
          <Button hidden={pdfHidden} onClick={handleResetForm}>
            Start Assessment
          </Button>
        ) : (
          <Button hidden={pdfHidden} onClick={() => setHideForm(true)}>
            Hide Assessment
          </Button>
        )}
      </div>
      <br />

      {rheatId &&
        rheatId.map((rid, i) => (
          <Button
            as="div"
            key={i}
            labelPosition="right"
            style={{ margin: "5px" }}
          >
            <Button
              className={
                dataId == (rid["id"] ?? "")
                  ? "blue-rheat-button"
                  : "none-rheat-button"
              }
              onClick={() => handleShowReport(false, rid["id"] ?? "")}
            >
              {rid["title"] ?? ""}
            </Button>
            <Label
              as="a"
              basic
              pointing="left"
              onClick={() => handleEditReport(rid["id"] ?? "")}
            >
              <Icon name="edit" />
            </Label>
            {rid["hide"] ?? false ? (
              <Label
                as="a"
                basic
                pointing="left"
                onClick={() => handleReportVisibility(rid["id"] ?? "", false)}
              >
                <Icon name="add" />
              </Label>
            ) : (
              <Label
                as="a"
                basic
                pointing="left"
                onClick={() => handleReportVisibility(rid["id"] ?? "", true)}
              >
                <Icon name="delete" />
              </Label>
            )}
          </Button>
        ))}

      <br />

      {name && !shared && (
        <Message error>
          This form is not shared. Please contact the Administrator.
        </Message>
      )}

      <br />

      <div id="formio" hidden={hideForm} className="rheat-container"></div>

      <div hidden={pdfHidden || !hideForm}>
        {pdfUrl && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        )}
      </div>

      {name === "" ? <Loader active inverted size="big" /> : ""}
    </Segment>
  );
}
