import { plannerTypes } from "../action/plannerAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case plannerTypes.PLANNER_LOADED:
      return action.payload;

    case plannerTypes.PLANNER_UNLOADED:
      return initialState;
    case plannerTypes.ADD_PLANNER:
      return { ...state };
    case plannerTypes.EDIT_PLANNER:
      return state;
    case plannerTypes.DELETE_PLANNER:
      return state;

    default:
      return state;
  }
};
