import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const transactionTypes = {
  TRANSACTION_LOADED: "TRANSACTION_LOADED",
  TRANSACTION_UNLOADED: "TRANSACTION_UNLOADED",
  ADD_TRANSACTION: "ADD_TRANSACTION",
  EDIT_TRANSACTION: "EDIT_TRANSACTION",
  DELETE_TRANSACTION: "DELETE_TRANSACTION",
};

export const loadTransactionPage = (url: string) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: transactionTypes.TRANSACTION_LOADED,
          payload: data,
        });
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadTransactionPage = () => {
  return {
    type: transactionTypes.TRANSACTION_UNLOADED,
    payload: {},
  };
};

export const addTransaction = (payload: object) => {
  return async (dispatch: any) => {
    apiService.save("/transaction", payload).then(
      (data) => {
        dispatch({
          type: transactionTypes.ADD_TRANSACTION,
          payload: data,
        });
        dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editTransaction = (payload: payloadProps) => {
  return async (dispatch: any) => {
    apiService.update(`/transaction/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: transactionTypes.EDIT_TRANSACTION,
          payload: data,
        });
        dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeTransaction = (id: number) => {
  return async (dispatch: any) => {
    apiService._delete(`/transaction/delete/${id}`).then(
      (data) => {
        dispatch({
          type: transactionTypes.DELETE_TRANSACTION,
        });
        dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
