// Without trailing slash at the end.
var API_URL = process.env.REACT_APP_API_URL ?? "http://127.0.0.1:8000/api";
// API_URL = "https://web.bctap.net/api";
var client_cred = {
  grant_type: "client_credentials",
  client_id: 6,
  client_secret: "ATcF8ZoNYoZ6FD2jtlBwE6W4N7o6uNg1jEH4Ig91",
  scope: "*",
};

const BUILD_ENV = process.env.REACT_APP_BUILD_ENV ?? "local";
if (BUILD_ENV === "production") {
  API_URL = "https://web.bctap.net/api";

  client_cred = {
    grant_type: "client_credentials",
    client_id: 49,
    client_secret: "dk4Vqca6EsydMLVmoizQZmaK8gZfzQpG6KENt1El",
    scope: "*",
  };
} else if (BUILD_ENV === "development") {
  API_URL = "https://testweb.bctap.net/api";
}

// API_URL = "https://edatos.drcmp.org/api";
// API_URL = "https://web.bctap.net/api"

// var query = {};
// window.location.search.substr(1).split('&').forEach(function(item) {
//   query[item.split('=')[0]] = item.split('=')[1] && decodeURIComponent(item.split('=')[1]);
// });

// PROJECT_URL = query.projectUrl || PROJECT_URL;
// API_URL = query.apiUrl || API_URL;

localStorage.setItem("api_url", API_URL);

const WEB_URL = API_URL.replace("/api", "");

export const AppConfig = {
  // projectUrl: PROJECT_URL,
  apiUrl: API_URL,
  webUrl: WEB_URL,
  clientCred: client_cred,
};
