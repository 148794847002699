import { phaseTypes } from "../action/phaseAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case phaseTypes.PHASE_PAGE_LOADED:

            return action.payload;

        case phaseTypes.PHASE_PAGE_UNLOADED:
            return initialState;
        case phaseTypes.ADD_PHASE:
           
             return { ...state};
        case phaseTypes.EDIT_PHASE:

            return state;
        case phaseTypes.DELETE_PHASE:
           
            return state;

        default:
            return state;
    }
};
