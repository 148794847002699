import { trainingModuleTypes } from "../action/trainingModuleAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case trainingModuleTypes.TRAINING_MODULE_LOADED:

            return action.payload;

        case trainingModuleTypes.TRAINING_MODULE_UNLOADED:
            return initialState;
        case trainingModuleTypes.ADD_TRAINING_MODULE:
           
             return { ...state};
        case trainingModuleTypes.EDIT_TRAINING_MODULE:

            return state;
        case trainingModuleTypes.DELETE_TRAINING_MODULE:
           
            return state;

        default:
            return state;
    }
};
