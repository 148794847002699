import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const covidTraceTypes = {
  COVID_TRACE_LOADED: "COVID_TRACE_LOADED",
  COVID_TRACE_UNLOADED: "COVID_TRACE_UNLOADED",
};

export const loadCovidTracePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: covidTraceTypes.COVID_TRACE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadCovidTracePage = () => {
  return {
    type: covidTraceTypes.COVID_TRACE_UNLOADED,
    payload: {},
  };
};