import { userTypes } from "../action/userAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case userTypes.USER_PAGE_LOADED:

            return action.payload;

        case userTypes.USER_PAGE_UNLOADED:
            return initialState;
        case userTypes.ADD_USER:
           
             return { ...state};
        case userTypes.EDIT_USER:

            return state;
        case userTypes.DELETE_USER:
           
            return state;

        default:
            return state;
    }
};
