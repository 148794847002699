import React, { ReactElement } from "react";
import { isValidUrl } from "../../../helper";
import revitIcon from "../../../assets/cisco/svg/revit.svg";
import pdfIcon from "../../../assets/cisco/svg/pdf.svg";

interface DynamicalLinkComponentProps {
  link: string;
  data_name: string;
}

export function DynamicalLinkComponent({
  link,
  data_name,
}: DynamicalLinkComponentProps) {
  let revitIconFor = ["revit_model_link", "revit_model"];
  let pdfIconFor = [
    "construction_drawings_link",
    "revit_imported_construction_drawings",
    "existing_drawings_link",
    "revit_imported_existing_drawings",
    "_drawings_link",
    "calculation_sheet_link",
    "calculation_sheet",
  ];

  return (
    <div>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block"
      >
        {revitIconFor.some((name: string) => data_name.endsWith(name)) ? (
          <img
            alt="revit file download"
            src={revitIcon}
            className="w-10 h-10"
          />
        ) : pdfIconFor.some((name: string) => data_name.endsWith(name)) ? (
          <img alt="pdf file download" src={pdfIcon} className="w-10 h-10" />
        ) : (
          link ?? ""
        )}
      </a>
    </div>
  );
}

interface Props {
  data: any;
  label: string;
  data_name?: string;
}

function NormalComponent({ data, label, data_name }: Props): ReactElement {
  const ListItem = () => {
    let type = typeof data;
    if (type === "object" || Array.isArray(data)) {
      return <div>{JSON.stringify(data)}</div>;
    } else if (type === "string") {
      // let text = document.createTextNode(data);
      // console.log(data_name);

      let validUrl = isValidUrl(data);

      if (validUrl && data_name) {
        return <DynamicalLinkComponent link={validUrl} data_name={data_name} />;
      } else {
        return (
          <div className="whitespace-pre-wrap">
            {data.replace(/<(.|\n)*?>/g, "")}
          </div>
        );
      }
    } else {
      return <div>{data}</div>;
    }
  };

  return (
    <div className="rows line-spacing" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        <ListItem />
      </div>
    </div>
  );
}

export default NormalComponent;
