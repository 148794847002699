import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Segment,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Input,
  Select,
  Divider,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadAwarenessVideoPage,
  unloadAwarenessVideoPage,
  addAwarenessVideo,
  editAwarenessVideo,
  removeAwarenessVideo,
} from "../../redux/action/awarenessVideoAction";
import { alertActions } from "../../redux/action/alertAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { apiService } from "../../services";
interface RootState {
  awareness_videos: {
    awareness_videos: {
      data: {
        title: string;
        title_lang: string;
        id: number;
        tile_id: number;
        app_id: number;
        project_id: number;
        awareness_module_id: number;
        description: string;
        description_lang: string;
        video: string;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
    tiles: {
      id: number;
      name: string;
      awareness_module_id: number;
    }[];
    awareness_modules: {
      id: number;
      name: string;
      app_id: number;
    }[];
    projects: {
      id: number;
      name: string;
      code: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function AwarenessVideo() {
  let quillRef: any;
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      title: "",
      title_lang: "",

      description: "",
      description_lang: "",
      video: "",
      project_id: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/awareness_video");
  const [search, setSearch] = useState<string>("");
  const awareness_videos = useSelector(
    (state: RootState) => state.awareness_videos
  );
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [awarenessModuleOptions, setAwarenessModuleOptions] = useState<
    appOptionType[]
  >([]);
  const [tileOptions, setTileOptions] = useState<appOptionType[]>([]);
  const [projectOptions, setProjectOptions] = useState<appOptionType[]>([]);

  const [filter, setFilter] = useState<number[]>([]);

  const [inputList, setInputList] = useState([
    { id: 0, app_id: 0, awareness_module_id: 0, tile_id: 0 },
  ]);

  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      loadAwarenessVideoPage(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadAwarenessVideoPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (awareness_videos.awareness_videos !== undefined) {
      setTotal(awareness_videos.awareness_videos.last_page);
    }
  }, [awareness_videos]);

  useEffect(() => {
    getData();

    return () => {
      //
    };
    // eslint-disable-next-line
  }, []);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/awareness_video?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        "&filter=" +
        filter
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/awareness_video?q=" + search + "&filter=" + filter);
    }
  };

  const getData = () => {
    apiService.getAll(`/faq/create/video`).then(
      (data) => {
        let final_awareness_module: any = [];
        let final_tiles: any = [];
        let final_stage = data.apps.map((app: any) => {
          final_awareness_module[app.id] = [];
          // eslint-disable-next-line
          app.awareness_module.map((mod: any, index: number) => {
            final_tiles[mod.id] = [];
            // eslint-disable-next-line
            mod.tiles.map((tile: any, index1: number) => {
              final_tiles[mod.id][index1] = {
                key: tile.id,
                value: tile.id,
                text: tile.name,
              };
            });

            final_awareness_module[app.id][index] = {
              key: mod.id,
              value: mod.id,
              text: mod.name,
            };
          });

          return {
            key: app.id,
            value: app.id,
            text: app.app_name,
          };
        });

        setAppOptions(final_stage);

        setAwarenessModuleOptions(final_awareness_module);
        setTileOptions(final_tiles);

        setProjectOptions(
          (data.projects ?? []).map((project: any) => ({
            key: project.id,
            value: project.id,
            text: project.name,
          }))
        );
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const GetEditData = (id: number) => {
    apiService.getAll(`/awareness_video/edit/${id}`).then(
      (data) => {
        let final = data.map((item: any) => {
          return {
            app_id: item.app_id,
            awareness_module_id: item.awareness_module_id,
            tile_id: item.tile_id,
            id: item.id,
          };
        });

        setInputList(final);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        title: "",
        title_lang: "",
        description: "",
        description_lang: "",
        video: "",
        project_id: 0,
      },
    });
    setInputList([{ id: 0, app_id: 0, awareness_module_id: 0, tile_id: 0 }]);
  };

  const selectDataForEditing = (id: number) => {
    let awareness_video = awareness_videos.awareness_videos.data.find(
      (v) => v.id === id
    );
    GetEditData(id);
    setData({
      newData: {
        id: awareness_video!.id,
        title: awareness_video!.title,
        title_lang: awareness_video!.title_lang,
        description: awareness_video!.description,
        description_lang: awareness_video!.description_lang,
        video: awareness_video!.video,
        project_id: awareness_video!.project_id ?? null,
      },
    });
    setCreating(true);
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    if (name === "project_id" && value) {
      setInputList([]);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleDescriptionChange = async (name: string, value: string) => {
    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        addAwarenessVideo(
          fdata.newData,
          inputList,
          () => {
            dispatch(
              loadAwarenessVideoPage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data added successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        editAwarenessVideo(
          fdata.newData,
          inputList,
          () => {
            dispatch(
              loadAwarenessVideoPage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data Edited successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      removeAwarenessVideo(
        deleteId,
        () => {
          dispatch(
            loadAwarenessVideoPage(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  const handleFilterChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    setFilter(value);
    setApiUrl("/awareness_video?filter=" + value + "&q=" + search);
  };

  // handle input change
  const handleInputChange =
    (index: number) =>
    (event: React.SyntheticEvent<HTMLElement, Event>, result: any) => {
      let value = result.value;
      let name = result.name;
      const list = [...inputList];
      list[index][name] = value;

      setInputList(list);
    };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { id: 0, app_id: 0, awareness_module_id: 0, tile_id: 0 },
    ]);
  };

  const AddNew = (): any => {
    return inputList.map(
      (
        data: { app_id: number; awareness_module_id: number; tile_id: number },
        index: number
      ) => {
        return (
          <Form.Group widths={3} key={index}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                options={appOptions}
                label="App"
                value={data.app_id}
                name="app_id"
                onChange={handleInputChange(index)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Awareness Module"
                required
                disabled={
                  awarenessModuleOptions[data.app_id] !== undefined &&
                  awarenessModuleOptions[data.app_id].length <= 0
                    ? true
                    : false
                }
                options={
                  awarenessModuleOptions[data.app_id] !== undefined
                    ? awarenessModuleOptions[data.app_id]
                    : []
                }
                label="Awareness Module"
                value={data.awareness_module_id}
                name="awareness_module_id"
                onChange={handleInputChange(index)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Tile"
                required
                disabled={
                  tileOptions[data.awareness_module_id] !== undefined &&
                  tileOptions[data.awareness_module_id].length <= 0
                    ? true
                    : false
                }
                options={
                  tileOptions[data.awareness_module_id] !== undefined
                    ? tileOptions[data.awareness_module_id]
                    : []
                }
                label="Tile"
                value={data.tile_id}
                name="tile_id"
                onChange={handleInputChange(index)}
              />
            </Form.Field>

            {inputList.length !== 1 && (
              <Button
                icon="minus"
                type="button"
                onClick={() => handleRemoveClick(index)}
              />
            )}
            {inputList.length - 1 === index && (
              <Button
                icon="plus"
                color="green"
                floated="right"
                type="button"
                onClick={handleAddClick}
              />
            )}
          </Form.Group>
        );
      }
    );
  };

  function RenderList(datas: any) {
    if (datas.data.awareness_videos === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.awareness_videos.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.awareness_videos.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.title}</Table.Cell>
          <Table.Cell>{data.title_lang ?? "-"}</Table.Cell>
          <Table.Cell>{data.app ? data.app.app_name : "-"}</Table.Cell>
          <Table.Cell>
            {data.awareness_module ? data.awareness_module.name : "-"}
          </Table.Cell>
          <Table.Cell>{data.tile ? data.tile.name : "-"}</Table.Cell>
          <Table.Cell>{data.project ? data.project.name : "-"}</Table.Cell>
          <Table.Cell>
            {data.video ? (
              <a href={data.video} target="_blank" rel="noopener noreferrer">
                View
              </a>
            ) : (
              <i>-</i>
            )}
          </Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Awareness Videos</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create Awareness Video
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>
          Awareness Videos
          <Input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={onSearch}
            className="pull-right small mb-2"
            icon="search"
            placeholder="Search..."
          />
        </Header>
        <Select
          placeholder="Filter by App"
          multiple
          clearable
          options={appOptions}
          onChange={handleFilterChange}
        />

        <Table basic="very" unstackable striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Title Lang</Table.HeaderCell>
              <Table.HeaderCell>App</Table.HeaderCell>
              <Table.HeaderCell>Awareness Module</Table.HeaderCell>
              <Table.HeaderCell>Tile</Table.HeaderCell>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Video</Table.HeaderCell>

              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {awareness_videos && <RenderList data={awareness_videos} />}
          </Table.Body>
        </Table>

        {awareness_videos.awareness_videos === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column>
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>
      ,
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="large"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Awareness Video
        </Modal.Header>
        <Modal.Content className="modalContent" scrolling>
          <Form>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Choose a Project"
                required
                options={projectOptions}
                label="Project"
                value={fdata.newData.project_id}
                name="project_id"
                onChange={handleChange}
                clearable
              />
            </Form.Field>

            {!fdata.newData.project_id ? <AddNew /> : ""}
            <br />
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="title"
                  label="Video Title"
                  value={fdata.newData.title}
                  onChange={handleChange}
                  placeholder="Enter Video title..."
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  type="text"
                  name="title_lang"
                  label="Video Title (In Preferred Language)"
                  value={fdata.newData.title_lang}
                  onChange={handleChange}
                  placeholder="Enter Video title (In Preferred Language)..."
                />
              </Form.Field>
            </Form.Group>

            <br />

            <Segment placeholder style={{ minHeight: "0rem" }}>
              <Grid columns={2} stackable textAlign="center">
                <Divider vertical>Or</Divider>

                <Grid.Row verticalAlign="middle">
                  <Grid.Column>
                    <Form.Input
                      fluid
                      required
                      type="text"
                      name="video"
                      label="Video URL"
                      value={
                        //@ts-ignore
                        fdata.newData.video instanceof File
                          ? ""
                          : fdata.newData.video
                      }
                      onChange={handleChange}
                      placeholder="Enter Valid Video URL..."
                      style={{ minWidth: "100%" }}
                      disabled={
                        //@ts-ignore
                        fdata.newData.video instanceof File
                      }
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field style={{ maxWidth: "100%" }}>
                      <Form.Input
                        fluid
                        type="file"
                        name="video"
                        label="Upload Video By Self"
                        id="private_video_field"
                        onChange={handleChange}
                        disabled={
                          typeof fdata.newData.video === "string" &&
                          fdata.newData.video.trim().length > 0
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <br />
            <Form.Group widths={2}>
              <Form.Field>
                <ReactQuill
                  theme="snow"
                  ref={(el) => {
                    quillRef = el;
                  }}
                  placeholder="Description"
                  value={fdata.newData.description}
                  onChange={(content, delta, source, editor) => {
                    if (source === "user") {
                      handleDescriptionChange("description", content);
                    } else {
                      quillRef.getEditor().history.clear();
                    }
                  }}
                />
              </Form.Field>

              <Form.Field>
                <ReactQuill
                  theme="snow"
                  ref={(el) => {
                    quillRef = el;
                  }}
                  placeholder="Description (In Preferred Language)"
                  value={fdata.newData.description_lang}
                  onChange={(content, delta, source, editor) => {
                    if (source === "user") {
                      handleDescriptionChange("description_lang", content);
                    } else {
                      quillRef.getEditor().history.clear();
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
