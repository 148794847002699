import { alertActions } from './alertAction';
import { apiService } from '../../services';

export const stepTypes = {
   
    STEP_PAGE_LOADED: "STEP_PAGE_LOADED",
    STEP_PAGE_UNLOADED: "STEP_PAGE_UNLOADED",
    ADD_STEP:"ADD_STEP",
    EDIT_STEP:"EDIT_STEP",
    DELETE_STEP:"DELETE_STEP",
    FETCH_STEP:"FETCH_STEP"


}

export const loadStepPage =  (url:string) => {
    
    return async (dispatch:any) =>{

        apiService.getAll(url)
        .then(
            data => { 
               
                dispatch({
                    type: stepTypes.STEP_PAGE_LOADED,
                    payload: data
                })
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
}

export const fetchStep =  (id:number,slug:string) => { 
    
        return async (dispatch:any) =>{
    
            apiService.getAll(`/step/${id}/edit/${slug}`)
            .then(
                data => { 
                   
                    dispatch({
                        type: stepTypes.FETCH_STEP,
                        payload: data
                    })
                   
                },
                error => {
                   
                    dispatch(alertActions.error(error.toString()));
                }
            );
            
    
        };
    
    
}


export const unloadStepPage = () => {
    return {
        type: stepTypes.STEP_PAGE_UNLOADED,
        payload: {}

    };
}

export const addStep = (payload:object) => {
    

    return async (dispatch:any) =>{

        apiService.save('/step',payload)
        .then(
            data => { 
               
                dispatch({
                    type: stepTypes.ADD_STEP,
                    payload: data
                })
                dispatch(alertActions.success('Data added successfully'))
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };

   
}
interface payloadProps {
    id: number,
    [key:string]:any
   // [all:any]:any
}
export const editStep = (payload:payloadProps) => {

    return async (dispatch:any) =>{

        apiService.update(`/step/update/${payload.id}`,payload)
        .then(
            data => { 
               
                dispatch({
                    type: stepTypes.EDIT_STEP,
                    payload: data
                })
                dispatch(alertActions.success('Data Edited successfully'))
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
}



  export const removeStep= (id:number) =>  {

   
    return async (dispatch:any) =>{

        apiService._delete(`/step/delete/${id}`)
        .then(
            data => { 
               
                dispatch({
                    type: stepTypes.DELETE_STEP,
                
                })
                dispatch(alertActions.success('Data Deleted successfully'))
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
  }

  

