import React from "react";
import { Segment, Breadcrumb, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { FormattedMessage } from "react-intl";

import DataList from "../../StepSkeleton/DataList";
import SimpleTable from "../../StepSkeleton/SimpleTable";
import DataListWithSimpleTable from "../../StepSkeleton/DataListWithSimpleTable";

export default function DatatableList(props: {
  match: { params: { slug: string } };
  history: any;
}) {
  const [formName, setFormName] = React.useState(null);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section link as={Link} to="/formbuilder">
          Formbuilder
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          {formName || "..."} (Data Table)
        </Breadcrumb.Section>
      </Breadcrumb>
    </div>,
    <div key="2">
      <Header>
        <p
          style={{ textAlign: "center", color: "orange" }}
          id="duplicate-or-not"
        ></p>{" "}
      </Header>

      <DataListWithSimpleTable
        slug={props.match.params.slug}
        id={""}
        setFormName={setFormName}
      />

      {/* <SimpleTable slug={props.match.params.slug} /> */}

      {/* <DataList
        slug={props.match.params.slug}
        id={""}
        setFormName={setFormName}
      /> */}
    </div>,
  ];
}
