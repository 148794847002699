import { strengthenHouseImageTypes } from "../action/strengthenAwarenessHouseImageAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case strengthenHouseImageTypes.STRENGTHEN_HOUSE_IMAGE_LOADED:
      return action.payload;

    case strengthenHouseImageTypes.STRENGTHEN_HOUSE_IMAGE_UNLOADED:
      return initialState;
    case strengthenHouseImageTypes.ADD_STRENGTHEN_HOUSE_IMAGE:
      return { ...state };
    case strengthenHouseImageTypes.EDIT_STRENGTHEN_HOUSE_IMAGE:
      return state;
    case strengthenHouseImageTypes.DELETE_STRENGTHEN_HOUSE_IMAGE:
      return state;

    default:
      return state;
  }
};
