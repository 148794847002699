import React from "react";
import { Link } from "react-router-dom";
import { Label } from "semantic-ui-react";
import logo from "../assets/images/BuildChange_Logo.png";

export default function Support() {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <iframe
        title="Chat"
        allowFullScreen={true}
        frameBorder="0"
        height="100%"
        scrolling="no"
        src="https://chat-forms.com/forms/1624282560314-pu3/index.html"
        key="chat-modal-iframe"
        width="100%"
        style={{ height: "100%" }}
      ></iframe>

      <div
        style={{
          position: "fixed",
          bottom: "0",
          right: "0",
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          color: "white",
          padding: "10px",
          textAlign: "center",
          background: "#111",
          borderRadius: "25px 0 0 0",
        }}
      >
        <img
          src={logo}
          alt="Build Change Logo"
          style={{ width: "150px", marginBottom: "5px" }}
        />
        <Label
          color="black"
          as={Link}
          to={`/`}
          style={{ textDecoration: "none" }}
        >
          BCTAP
        </Label>
      </div>
    </div>
  );
}
