import React, { useState, useEffect, useCallback } from "react";

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  PieChart,
  Pie,
  AreaChart,
  ResponsiveContainer,
} from "recharts";
import {
  Breadcrumb,
  List,
  Image,
  Button,
  Popup,
  Icon,
  Menu,
  Dropdown,
  Message,
  DropdownProps,
  Modal,
  Form,
  Card,
  Input,
  Dimmer,
  Loader,
  Label,
} from "semantic-ui-react";
import Nestable from "react-nestable";

import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import DataCount from "./DataCount";
import { fetchData, unloadData } from "../../redux/action/allAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { AppConfig } from "../../config";
import defaultImg from "../../assets/images/image.png";

const recordActivityOptions = [
  {
    key: "1",
    text: "Data per day",
    value: "day",
  },
  {
    key: "7",
    text: "Data per week",
    value: "week",
  },
  {
    key: "28",
    text: "Data per Month",
    value: "month",
  },
];

const data = [
  {
    name: "Page A",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Page B",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Page C",
    uv: 1397,
    pv: 1098,
    amt: 989,
  },
  {
    name: "Page D",
    uv: 1480,
    pv: 1200,
    amt: 1228,
  },
  {
    name: "Page E",
    uv: 1520,
    pv: 1108,
    amt: 1100,
  },
  {
    name: "Page F",
    uv: 1400,
    pv: 680,
    amt: 1700,
  },
];

export default function FormBuilderApp(props: any) {
  const formbuilder = useSelector((state: any) => state.all_ind);

  const [formbuilder_detail, setformbuilder_detail] = useState({
    data_share: false,
  });
  const dispatch = useDispatch();
  const [datewise_count, setDatewise_count] = useState([]);
  const [userwise_count, setUserwise_count] = useState([]);
  const [userwise_total_count, setUserwise_total_count] = useState([]);
  const [child_count, setChild_count] = useState([]);

  const [datastudio, setDataStudio] = useState("");
  const [
    isLoadingForEnableWritableDataShare,
    setIsLoadingForEnableWritableDataShare,
  ] = useState(false);

  const [openDataStudio, setOpenDataStudio] = useState<boolean>(false);
  const [openDataShareEnabled, setOpenDataShareEnabled] =
    useState<boolean>(false);
  const [openDataShareDisabled, setOpenDataShareDisabled] =
    useState<boolean>(false);

  const [activeActivity, setActiveActivity] = useState("Activity 1");
  const [apiUrl, setApiUrl] = useState<string>(
    `/formBuilder/detail/${props.match.params.slug}`
  );
  const [name, setName] = useState("");

  const handleOnChangeSort = (items: any) => {
    //console.log({items})

    apiService
      .save(`/formBuilder/related/${props.match.params.slug}`, {
        items: items,
      })
      .then(
        (data) => {
          // dispatch(fetchData(apiUrl));
          setChild_count(items);
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
  const renderItem = ({ item }: any) => (
    <List divided selection>
      {" "}
      <RenderChild child={item} />
    </List>
  );

  const [file, setFile] = useState<any>();
  const history = useHistory();
  const [openImport, setOpenImport] = useState<boolean>(false);

  const [openImportGSheet, setOpenImportGSheet] = useState<boolean>(false);
  const [gSheetUrl, setGSheetUrl] = useState<string>("");
  const [importGSheetLoading, setImportGSheetLoading] =
    useState<boolean>(false);

  const [openFulcrumFormSchemaRestore, setOpenFulcrumFormSchemaRestore] =
    useState<boolean>(false);
  const [fulcrumFormSchemaRestoreLoading, setFulcrumFormSchemaRestoreLoading] =
    useState<boolean>(false);
  const [fulcrumFormSchemaRestoreId, setFulcrumFormSchemaRestoreId] = useState<
    string | undefined
  >();

  useEffect(() => {
    dispatch(fetchData(apiUrl));
    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (formbuilder.detail !== undefined) {
      //console.log(formbuilders.last_page)
      setformbuilder_detail(formbuilder.detail);
      setName(formbuilder.detail.name);
      setDataStudio(formbuilder.detail.data_studio || "");
    }
    if (formbuilder.datewise_count !== undefined) {
      let final_datewise_count = formbuilder.datewise_count.map((data: any) => {
        return {
          key: data.label,
          count: data.count,
          name: data.label,
        };
      });

      let final_userwise_count = formbuilder.datewise_count.map((data: any) => {
        return {
          ...data.user_count,
          ...{
            key: data.label,
            name: data.label,
          },
        };
      });

      setUserwise_count(final_userwise_count);
      setDatewise_count(final_datewise_count);

      let users_total_count = {};
      final_userwise_count.forEach((count: any) => {
        if (!users_total_count[Object.keys(count)[0]]) {
          users_total_count[Object.keys(count)[0]] = 0;
        }

        users_total_count[Object.keys(count)[0]] += Object.values(count)[0];
      });

      let users_total_count_array: any = [];
      Object.keys(users_total_count).forEach((user) => {
        users_total_count_array.push({
          name: user,
          value: users_total_count[user],
        });
      });

      setUserwise_total_count(users_total_count_array);
    }

    if (formbuilder.child !== undefined) {
      let final_child_count = formbuilder.child.map(
        (data: any, index: number) => {
          return {
            id: index,
            name: data.name,
            slug: data.slug,
          };
        }
      );

      setChild_count(final_child_count);
    }
  }, [formbuilder]);

  const activityHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: any
  ) => {
    setActiveActivity(data.name);
  };

  const handleRecordType = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setApiUrl(
      `/formBuilder/detail/${props.match.params.slug}?type=${data.value}`
    );
  };

  const importData = () => {
    setOpenImport(true);
  };

  const processExcel = () => {
    apiService.save(`/formBuilderImport`, { file: file }).then(
      (data) => {
        setOpenImport(false);
        dispatch(alertActions.success(data.message));

        // apiService.save(`/formBuilder/importSave/${props.match.params.slug}`, { 'data': data})
        // .then(
        //     (dat) => {
        //         dispatch(alertActions.success(dat.message));

        //     },
        //     error => {

        //         dispatch(alertActions.error(error.toString()));
        //     }
        //     );

        history.push(`/formbuilder/${props.match.params.slug}/edit`, data);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const restoreFulcrumFormFromBackup = () => {
    if (!fulcrumFormSchemaRestoreId) {
      dispatch(alertActions.error("Please select a backup to restore"));
      return;
    }

    setFulcrumFormSchemaRestoreLoading(true);

    apiService
      .save(`/formBuilder/fulcrum/restore/${fulcrumFormSchemaRestoreId}`)
      .then(
        (data) => {
          setOpenFulcrumFormSchemaRestore(false);
          setFulcrumFormSchemaRestoreLoading(false);
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          setFulcrumFormSchemaRestoreLoading(false);
        }
      );
  };

  const importGSheet = () => {
    setImportGSheetLoading(true);

    apiService
      .save(`/formBuilderImportViaGoogleSheet`, { url: gSheetUrl })
      .then(
        (data) => {
          setOpenImportGSheet(false);
          setImportGSheetLoading(false);
          dispatch(alertActions.success(data.message));
          history.push(`/formbuilder/${props.match.params.slug}/edit`, data);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          setImportGSheetLoading(false);
        }
      );
  };

  const exportForm = (slug: string) => {
    apiService.getfile(`/formBuilder/exportForm/${slug}`).then(
      (data) => {
        setTimeout(() => {
          window.open(data);
        }, 100);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const setDataShare = () => {
    if (formbuilder_detail.data_share) {
      setOpenDataShareDisabled(false);
      setOpenDataShareEnabled(true);
    } else {
      setOpenDataShareDisabled(true);
      setOpenDataShareEnabled(false);
    }
  };

  const enableDataShare = (slug: string) => {
    apiService.getAll(`/formBuilder/data_share/${slug}`).then(
      (data) => {
        dispatch(fetchData(apiUrl));
        dispatch(alertActions.success(data.message));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      }
    );
  };

  const enableWritableDataShare = (slug: string) => {
    setIsLoadingForEnableWritableDataShare(true);
    apiService
      .getAll(`/formBuilder/writable_data_share/${slug}`)
      .then(
        (data) => {
          dispatch(fetchData(apiUrl));
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      )
      .finally(() => {
        setTimeout(() => {
          setIsLoadingForEnableWritableDataShare(false);
        }, 1000);
      });
  };

  const handleDataStudioChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    //let name = result.name;

    setDataStudio(value);
  };

  const DataStudioSave = () => {
    apiService
      .save(`/dataStudio/formbuilder/${props.match.params.slug}`, {
        data_studio: datastudio,
      })
      .then(
        (data) => {
          // dispatch(fetchData(apiUrl));
          dispatch(fetchData(apiUrl));
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const copyToClipboard = (url: string) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const handleFileChange = async (event: any, result: any) => {
    let value = event.target.files[0];
    setFile(value);
  };

  const RenderChild = useCallback(
    (data): any => {
      if (data.child === undefined) {
        return <Message>No Child Found</Message>;
      }

      return (
        <List.Item style={{ padding: "15px" }} key={data.child.id}>
          <List.Icon name="database" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Description as="a" style={{ marginBottom: "5px" }}>
              {data.child.name}
            </List.Description>
          </List.Content>
          <List.Content floated="right" style={{ marginTop: "5px" }}>
            <Popup
              trigger={
                <Button
                  color="grey"
                  circular
                  as={Link}
                  to={`/formbuilder/fill/${data.child.slug}`}
                >
                  <Button.Content>Fill</Button.Content>
                </Button>
              }
              content="Fill This Form"
              position="top center"
            />
            <Popup
              trigger={
                <Button
                  animated
                  primary
                  circular
                  as={Link}
                  to={`/formbuilder/data/${data.child.slug}`}
                >
                  <Button.Content hidden>View</Button.Content>
                  <Button.Content visible>
                    <Icon name="eye" />
                  </Button.Content>
                </Button>
              }
              content="View Filled Data"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  animated
                  as={Link}
                  to={`/formbuilder/datatable/${data.child.slug}`}
                >
                  <Button.Content hidden>Datatable View</Button.Content>
                  <Button.Content visible>
                    <Icon name="eye dropper" />
                  </Button.Content>
                </Button>
              }
              content="View Filled Data in datatable"
              position="top center"
            />
          </List.Content>
        </List.Item>
      );
    },
    [formbuilder, name, props.match.params.slug]
  );

  const RenderLog = useCallback((): any => {
    if (!formbuilder.logs || formbuilder.logs.length <= 0) {
      return <Message>No Log Found</Message>;
    }

    return formbuilder.logs.map(
      (
        item: {
          _id: string;
          datetime: Date;
          extra: { user_name: string };
          context: { type: string; data_id: string };
          created_at: Date;
          updated_at: Date;
        },
        index: number
      ) => {
        let type = item.context.type || "";
        return (
          <List.Item style={{ padding: "15px" }} key={item._id || index}>
            <List.Icon name="user circle" size="large" verticalAlign="middle" />
            <List.Content>
              <List.Description
                as="a"
                style={{ color: "gray", marginBottom: "5px" }}
              >
                {(item.created_at || item.datetime) &&
                  moment(item.created_at || item.datetime).fromNow()}
              </List.Description>
              <List.Description
                as={Link}
                to={`/formbuilder/data/${props.match.params.slug}/detail/${item.context.data_id}`}
              >
                <strong>{item.extra && item.extra.user_name}</strong> {type}{" "}
                <span style={{ color: "#4183c4" }}>1 record</span> in{" "}
                <span style={{ color: "#4183c4" }}>{name}</span> ({" "}
                <strong>{type === "created" ? 1 : 0}</strong> created{" "}
                <strong>{type === "updated" ? 1 : 0}</strong> updated{" "}
                <strong>{type === "deleted" ? 1 : 0}</strong> deleted )
              </List.Description>
            </List.Content>
          </List.Item>
        );
      }
    );
  }, [formbuilder, name, props.match.params.slug]);

  return [
    <div key={1}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section link as={Link} to="/formbuilder">
          Formbuilder
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>{name}</Breadcrumb.Section>
      </Breadcrumb>

      <Modal
        size="small"
        open={openImport}
        onClose={() => setOpenImport(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Import form using excel</h4>
            <Button
              onClick={() => {
                window.open(
                  `${AppConfig.apiUrl.replace("api", "")}static/bUXn1gEZ.xlsx`
                );
              }}
              className="createButton"
            >
              Download Sample
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              type="file"
              name="excel"
              label="Upload Excel file"
              onChange={handleFileChange}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button className="clearButton" onClick={() => setOpenImport(false)}>
            Cancel
          </Button>
          <Button className="submitButton" onClick={() => processExcel()}>
            Import
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openImportGSheet}
        onClose={() => setOpenImportGSheet(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Import form using Google Spreadsheet Public URL</h4>
            <Button
              onClick={() => {
                window.open(
                  `${AppConfig.apiUrl.replace("api", "")}static/bUXn1gEZ.xlsx`
                );
              }}
              className="createButton"
            >
              Download Sample
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              name="url"
              label="Google Sheet Public URL"
              onChange={(e) => setGSheetUrl(e.target.value)}
              placeholder="https://docs.google.com/spreadsheets/d/1234ABCD/edit"
              required
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            onClick={() => setOpenImportGSheet(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitButton"
            onClick={() => importGSheet()}
            loading={importGSheetLoading}
          >
            Import
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataShareDisabled}
        dimmer="blurring"
        closeIcon
        onClose={() => setOpenDataShareDisabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Data shares create unique URLs to share read-only access to
              Formbuilder data. Select a format to view the link. Shares will
              generate and display a URL for a data feed using the file format
              of your choosing. The URLs generated are still private to you, and
              will only be available to those with the link.
            </p>
            <p>Enable Data Shares?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            onClick={() => setOpenDataShareDisabled(false)}
          >
            Cancel
          </Button>
          <Button
            content="Enable"
            labelPosition="right"
            icon="checkmark"
            onClick={() => enableDataShare(props.match.params.slug)}
            className="submitButton"
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataShareEnabled}
        closeIcon
        onClose={() => setOpenDataShareEnabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Data shares create unique URLs to share read-only access to
              Formbuilder data. Select a format to view the link. Shares will
              generate and display a URL for a data feed using the file format
              of your choosing. The URLs generated are still private to you, and
              will only be available to those with the link.
            </p>

            {formbuilder && formbuilder.detail ? (
              <Card fluid color="red">
                <Card.Content>
                  <Card.Header>
                    <Button
                      content={
                        (formbuilder.detail.writable_data_share
                          ? "Disable"
                          : "Enable") + " Writable Data Share URL"
                      }
                      labelPosition="right"
                      icon={
                        formbuilder.detail.writable_data_share
                          ? "times"
                          : "checkmark"
                      }
                      onClick={() =>
                        enableWritableDataShare(props.match.params.slug)
                      }
                      className={
                        formbuilder.detail.writable_data_share
                          ? "deleteButton"
                          : "submitButton"
                      }
                      loading={isLoadingForEnableWritableDataShare}
                    />
                  </Card.Header>
                </Card.Content>

                <Card.Description>
                  <p style={{ padding: "0px 20px 10px 20px" }}>
                    When you enable the Writable Data Share URL feature, it
                    allows you to add/update records using the Api URL below. No
                    Authentication is required, so make sure to keep these links
                    safe.
                    {formbuilder.detail.writable_data_share ? (
                      <div>
                        <br />
                        To perform a writable action, simply make a <b>
                          POST
                        </b>{" "}
                        request to the <b>Api URL</b> below, and wrap the record
                        object in data key. <b>{'{ "data": {...} }'}</b> before
                        sending the request.
                      </div>
                    ) : (
                      ""
                    )}
                  </p>
                </Card.Description>
              </Card>
            ) : (
              ""
            )}

            <p>Select Data Shares Link</p>

            <Card.Group>
              <Card fluid color="red">
                <Card.Content>
                  <Card.Header>Excel</Card.Header>
                </Card.Content>

                <Card.Description>
                  <Input fluid>
                    <input
                      readOnly
                      defaultValue={
                        AppConfig.apiUrl +
                        "/share/" +
                        props.match.params.slug +
                        ".xls"
                      }
                    ></input>
                    <Popup
                      content="Link Copied to Clipboard"
                      onOpen={() =>
                        copyToClipboard(
                          AppConfig.apiUrl +
                            "/share/" +
                            props.match.params.slug +
                            ".xls"
                        )
                      }
                      on="click"
                      trigger={<Button>Copy</Button>}
                    />
                  </Input>
                </Card.Description>
              </Card>
              <Card fluid color="orange">
                <Card.Content>
                  <Card.Header>Json</Card.Header>
                </Card.Content>

                <Card.Description>
                  <Input fluid>
                    <input
                      readOnly
                      defaultValue={
                        AppConfig.apiUrl +
                        "/share/" +
                        props.match.params.slug +
                        ".json"
                      }
                    ></input>
                    <Popup
                      content="Link Copied to Clipboard"
                      onOpen={() =>
                        copyToClipboard(
                          AppConfig.apiUrl +
                            "/share/" +
                            props.match.params.slug +
                            ".json"
                        )
                      }
                      on="click"
                      trigger={<Button>Copy</Button>}
                    />
                  </Input>
                </Card.Description>
              </Card>

              <Card fluid color="orange">
                <Card.Content>
                  <Card.Header>Api</Card.Header>
                </Card.Content>

                <Card.Description>
                  <Input fluid>
                    <input
                      readOnly
                      defaultValue={
                        AppConfig.apiUrl + "/share/" + props.match.params.slug
                      }
                    ></input>
                    <Popup
                      content="Link Copied to Clipboard"
                      onOpen={() =>
                        copyToClipboard(
                          AppConfig.apiUrl + "/share/" + props.match.params.slug
                        )
                      }
                      on="click"
                      trigger={<Button>Copy</Button>}
                    />
                  </Input>
                </Card.Description>
              </Card>

              <Card fluid color="teal">
                <Card.Content>
                  <Card.Header>CSV</Card.Header>
                </Card.Content>

                <Card.Description>
                  <Input fluid>
                    <input
                      readOnly
                      defaultValue={
                        AppConfig.apiUrl +
                        "/share/" +
                        props.match.params.slug +
                        ".csv"
                      }
                    ></input>
                    <Popup
                      content="Link Copied to Clipboard"
                      onOpen={() =>
                        copyToClipboard(
                          AppConfig.apiUrl +
                            "/share/" +
                            props.match.params.slug +
                            ".csv"
                        )
                      }
                      on="click"
                      trigger={<Button>Copy</Button>}
                    />
                  </Input>

                  {formbuilder.datagrid_keys &&
                    formbuilder.datagrid_keys.map(
                      (data: string, index: number) => {
                        return (
                          <div key={index}>
                            <Input fluid>
                              <input
                                readOnly
                                defaultValue={
                                  AppConfig.apiUrl +
                                  "/share/" +
                                  props.match.params.slug +
                                  ".csv?child=" +
                                  data
                                }
                              ></input>
                              <Popup
                                content="Link Copied to Clipboard"
                                onOpen={() =>
                                  copyToClipboard(
                                    AppConfig.apiUrl +
                                      "/share/" +
                                      props.match.params.slug +
                                      ".csv?child=" +
                                      data
                                  )
                                }
                                on="click"
                                trigger={<Button>Copy</Button>}
                              />
                            </Input>
                          </div>
                        );
                      }
                    )}
                </Card.Description>
              </Card>
              {formbuilder.detail && formbuilder.detail.fulcrum_id ? (
                <Card fluid color="teal">
                  <Card.Content>
                    <Card.Header>Fulcrum Webhook</Card.Header>
                  </Card.Content>

                  <Card.Description>
                    <Input fluid>
                      <input
                        readOnly
                        defaultValue={
                          AppConfig.apiUrl +
                          "/fulcrum/webhook?api_token=" +
                          (formbuilder.detail.fulcrum_api_token ||
                            "<fulcrum_api_token>")
                        }
                      ></input>
                      <Popup
                        content="Webhook URL to Clipboard"
                        onOpen={() =>
                          copyToClipboard(
                            AppConfig.apiUrl +
                              "/fulcrum/webhook?api_token=" +
                              (formbuilder.detail.fulcrum_api_token ||
                                "<fulcrum_api_token>")
                          )
                        }
                        on="click"
                        trigger={<Button>Copy</Button>}
                      />
                    </Input>
                    <small
                      style={{
                        padding: "0 15px",
                        fontWeight: 700,
                        color: "teal",
                      }}
                    >
                      {!formbuilder.detail.fulcrum_api_token
                        ? "Replace <fulcrum_api_token> with your valid Fulcrum API Token"
                        : "If API Token were deleted. You might need to update the api_token value."}
                    </small>
                  </Card.Description>
                </Card>
              ) : (
                ""
              )}
              {formbuilder.detail && formbuilder.detail.kobo_id ? (
                <Card fluid color="teal">
                  <Card.Content>
                    <Card.Header>KoboToolbox Webhook</Card.Header>
                  </Card.Content>

                  <Card.Description>
                    <Input fluid>
                      <input
                        readOnly
                        defaultValue={
                          AppConfig.apiUrl +
                          "/kobo/webhook?api_token=" +
                          (formbuilder.detail.kobo_api_token ||
                            "<kobo_api_token>") +
                          (formbuilder.detail.kobo_base_url
                            ? "&base_url=" + formbuilder.detail.kobo_base_url
                            : "")
                        }
                      ></input>
                      <Popup
                        content="Copy Webhook URL to Clipboard"
                        onOpen={() =>
                          copyToClipboard(
                            AppConfig.apiUrl +
                              "/kobo/webhook?api_token=" +
                              (formbuilder.detail.kobo_api_token ||
                                "<kobo_api_token>") +
                              (formbuilder.detail.kobo_base_url
                                ? "&base_url=" +
                                  formbuilder.detail.kobo_base_url
                                : "")
                          )
                        }
                        on="click"
                        trigger={<Button>Copy</Button>}
                      />
                    </Input>
                    <small
                      style={{
                        padding: "0 15px",
                        fontWeight: 700,
                        color: "teal",
                      }}
                    >
                      {!formbuilder.detail.kobo_api_token
                        ? "Replace <kobo_api_token> with your valid Kobo API Token"
                        : "If API Token was changed. You might need to update the api_token value."}
                    </small>
                  </Card.Description>
                </Card>
              ) : (
                ""
              )}
            </Card.Group>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            onClick={() => setOpenDataShareEnabled(false)}
          >
            Cancel
          </Button>
          <Button
            content="Disable"
            labelPosition="right"
            icon="checkmark"
            onClick={() => enableDataShare(props.match.params.slug)}
            className="submitButton"
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataStudio}
        closeIcon
        onClose={() => setOpenDataStudio(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Studio Link</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Input
              fluid
              placeholder="Enter Data studio link..."
              name="datastudio"
              onChange={handleDataStudioChange}
              value={datastudio}
            />
            {datastudio !== "" ? (
              <a href={datastudio} target="_blank" rel="noopener noreferrer">
                <Button basic primary>
                  Open Link
                </Button>
              </a>
            ) : (
              ""
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="clearButton"
            onClick={() => setOpenDataStudio(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitButton"
            content="Save"
            labelPosition="right"
            icon="checkmark"
            onClick={() => DataStudioSave()}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openFulcrumFormSchemaRestore}
        onClose={() => setOpenFulcrumFormSchemaRestore(false)}
        style={{ position: "relative" }}
      >
        {formbuilder && formbuilder.fulcrum_form_schemas && (
          <>
            <Modal.Header>
              <div className="d-flex justify-content-between">
                <h4>Restore Fulcrum Form from a Backup:</h4>

                <small className="text-gray-500">
                  "{formbuilder.fulcrum_form_schemas.length}" backups available.
                </small>
              </div>
            </Modal.Header>
            <Modal.Content>
              <Form.Field>
                <Form.Select
                  fluid
                  label="Select a Backup:"
                  name="fulcrum_form_schema_id"
                  value={fulcrumFormSchemaRestoreId as string}
                  onChange={(e, { value }) => {
                    setFulcrumFormSchemaRestoreId(value as string);
                  }}
                  clearable
                  style={{ minHeight: "90px" }}
                  options={formbuilder.fulcrum_form_schemas.map((item: any) => {
                    return {
                      key: item._id,
                      text: (
                        <div>
                          <p>
                            Version: <b>{item.version}</b>
                          </p>
                          <p>
                            Date:{" "}
                            <b>{moment(item.updated_at).toLocaleString()}</b>
                          </p>
                          <p>Name: {item.name}</p>
                        </div>
                      ),
                      value: item._id,
                    };
                  })}
                  placeholder="Choose One ....."
                  required
                />
              </Form.Field>
            </Modal.Content>
            <Modal.Actions>
              <Button
                className="clearButton"
                onClick={() => setOpenFulcrumFormSchemaRestore(false)}
              >
                Cancel
              </Button>
              <Button
                className="submitButton"
                loading={fulcrumFormSchemaRestoreLoading}
                onClick={() => {
                  restoreFulcrumFormFromBackup();
                }}
              >
                {!fulcrumFormSchemaRestoreLoading && (
                  <Icon name="dot circle" className="text-red-700" />
                )}
                Restore to Fulcrum
              </Button>
            </Modal.Actions>
          </>
        )}
      </Modal>
    </div>,

    <div className="app-card-org" key={2}>
      <List divided verticalAlign="middle">
        <List.Item>
          <Image size="tiny" rounded bordered src={defaultImg} />
          <List.Content key="1">
            <List.Header as="a" className="text-uppercase">
              {name}
            </List.Header>
            <List.Description>
              <p>{formbuilder.org || "-"}</p>

              {formbuilder.detail && (
                <List.Description className="mt-2">
                  {formbuilder.detail.share && (
                    <Label
                      style={{
                        backgroundColor: "var(--color-blue)",
                        color: "white",
                      }}
                      tag
                      size={"tiny"}
                    >
                      Form Share Enabled
                    </Label>
                  )}

                  {formbuilder.detail.data_share && (
                    <Label
                      style={{
                        backgroundColor: "var(--color-blue)",
                        color: "white",
                      }}
                      tag
                      size={"tiny"}
                    >
                      Data Share Enabled
                    </Label>
                  )}

                  {formbuilder.detail.fulcrum_id && (
                    <Label
                      style={{
                        backgroundColor: "var(--color-red)",
                        color: "white",
                      }}
                      tag
                      size={"tiny"}
                    >
                      Imported From Fulcrum
                    </Label>
                  )}

                  {formbuilder.detail.kobo_id && (
                    <Label
                      style={{
                        backgroundColor: "var(--cyan)",
                        color: "white",
                      }}
                      tag
                      size={"tiny"}
                    >
                      Imported From KoboToolbox
                    </Label>
                  )}

                  {formbuilder.detail.tags &&
                    formbuilder.detail.tags.map((tag: string) => {
                      return (
                        <React.Fragment key={tag}>
                          <Label
                            size="tiny"
                            style={{
                              backgroundColor: "var(--color-blue)",
                              color: "white",
                              borderColor: "var(--color-blue)",
                            }}
                          >
                            {tag}
                          </Label>
                        </React.Fragment>
                      );
                    })}
                </List.Description>
              )}
            </List.Description>
          </List.Content>
          <List.Content
            key="2"
            floated="right"
            style={{ marginTop: "5px", position: "relative" }}
          >
            <Dimmer
              active={!name ? true : false}
              inverted
              style={{ height: "130%", width: "102%" }}
              className="sm-dimmer"
            >
              <Loader active={!name ? true : false} inverted size="large" />
            </Dimmer>

            <p style={{ color: "grey", textAlign: "right" }}>
              <DataCount slug={props.match.params.slug} />{" "}
            </p>

            <Popup
              trigger={
                <Button
                  icon
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "red",
                  }}
                  as={Link}
                  to={`/formbuilder/reportList/${props.match.params.slug}`}
                >
                  <Icon name="file pdf" />
                </Button>
              }
              content="PDF Report"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  icon
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "green",
                  }}
                  as={Link}
                  to={`/formbuilder/gsheetList/${props.match.params.slug}`}
                >
                  <Icon name="file excel" />
                </Button>
              }
              content="Excel Report"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  icon
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  onClick={() => exportForm(props.match.params.slug)}
                >
                  <Icon name="download" />
                </Button>
              }
              content="Download Form"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  icon
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-green)",
                  }}
                  onClick={() => importData()}
                >
                  <Icon name="upload" />
                </Button>
              }
              content="Import Form"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  icon
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-green)",
                  }}
                  onClick={() => setOpenImportGSheet(true)}
                >
                  <Icon name="table" />
                </Button>
              }
              content="Import Google Sheet Form"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  icon
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "darkorange",
                  }}
                  circular
                  onClick={() => setOpenDataStudio(true)}
                >
                  <Icon name="database" />
                </Button>
              }
              content="Data Studio"
              position="top center"
            />

            {formbuilder &&
              formbuilder.detail &&
              formbuilder.detail.fulcrum_id && (
                <Popup
                  trigger={
                    <Button
                      icon
                      className="formbuilder-buttons"
                      style={{
                        backgroundColor: "white",
                        color: "red",
                      }}
                      circular
                      onClick={() => setOpenFulcrumFormSchemaRestore(true)}
                    >
                      <Icon name="circle" />
                    </Button>
                  }
                  content="Restore Fulcrum Form Schema from available backups."
                  style={{ textAlign: "center" }}
                  position="top center"
                />
              )}

            <Popup
              trigger={
                <Button
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: `${
                      formbuilder_detail.data_share
                        ? "var(--color-red)"
                        : "var(--color-blue)"
                    }`,
                  }}
                  icon
                  circular
                  onClick={setDataShare}
                >
                  <Icon name="send" />
                </Button>
              }
              content="Data Share"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  icon="pencil"
                  as={Link}
                  to={`/formbuilder/${props.match.params.slug}/edit`}
                  className="formbuilder-buttons"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                ></Button>
              }
              content="Edit this Form"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  icon="code"
                  as={Link}
                  to={`/formbuilder/${props.match.params.slug}/function`}
                  className="formbuilder-buttons"
                  circular
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                ></Button>
              }
              content="Update Automated Functions & Calculations"
              position="top right"
            />

            <Popup
              trigger={
                <Button
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-green)",
                  }}
                  circular
                  as={Link}
                  to={`/formbuilder/fill/${props.match.params.slug}`}
                  icon="write square"
                ></Button>
              }
              content="Fill This Form"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "blue",
                  }}
                  circular
                  as={Link}
                  to={`/formbuilder/deactivated/${props.match.params.slug}`}
                  icon="unlinkify"
                ></Button>
              }
              content="Deactivated List"
              position="top center"
            />

            <Popup
              trigger={
                <Button
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  circular
                  as={Link}
                  to={`/formbuilder/data/${props.match.params.slug}`}
                  icon="eye"
                  // style={{ marginTop: "10px" }}
                ></Button>
              }
              content="View Filled Data"
              position="top center"
            />
          </List.Content>
        </List.Item>
      </List>

      <div>
        <Menu pointing secondary>
          <Menu.Item
            active={activeActivity === "Activity 1"}
            name="Activity 1"
            onClick={activityHandler}
          />
          <Menu.Item
            active={activeActivity === "Activity 2"}
            name="Activity 2"
            onClick={activityHandler}
          />
          <Menu.Item
            active={activeActivity === "Log Records"}
            name="Log Records"
            onClick={activityHandler}
          />

          <Menu.Item
            active={activeActivity === "Related Forms"}
            name="Related Forms"
            onClick={activityHandler}
          />
        </Menu>
      </div>
      <div
        style={{
          width: "100%",
          height: "350px",
          marginTop: "10px",
          marginBottom: "10px",
          display: activeActivity === "Activity 1" ? "block" : "none",
        }}
      >
        Record Activity &nbsp; &nbsp;
        <Dropdown
          simple
          placeholder="State"
          onChange={handleRecordType}
          selection
          options={recordActivityOptions}
        />
        <ResponsiveContainer>
          <ComposedChart
            data={datewise_count}
            margin={{
              top: 20,
              right: 30,
              left: 30,
              bottom: 0,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" barSize={40} fill="#413ea0" />
            <Line type="monotone" dataKey="count" stroke="#ff7300" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div
        style={{
          width: "100%",
          height: "350px",
          marginTop: "10px",
          marginBottom: "10px",
          display: activeActivity === "Activity 1" ? "block" : "none",
        }}
      >
        Record Activity &nbsp; &nbsp;
        {/* <Dropdown simple placeholder='State' selection options={recordActivityOptions} /> */}
        <ResponsiveContainer>
          <LineChart
            data={userwise_count}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
            <YAxis />
            <Tooltip />
            <Legend />

            {formbuilder.users &&
              Object.keys(formbuilder.users).map((key: any, index: number) => {
                return (
                  <Line
                    type="monotone"
                    dataKey={formbuilder.users[key]}
                    stroke={`#${Math.floor(Math.random() * 16777215).toString(
                      16
                    )}`}
                    key={index}
                  />
                );
              })}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
          display: activeActivity === "Activity 2" ? "block" : "none",
        }}
      >
        Record Activity Per Users: &nbsp; &nbsp;
        {/* <Dropdown simple placeholder='State' selection options={recordActivityOptions} /> */}
        {!userwise_total_count || userwise_total_count.length <= 0 ? (
          <Message style={{ marginTop: "5px" }}>
            No User Specific Records Available
          </Message>
        ) : (
          ""
        )}
        <div style={{ display: "flex" }}>
          <PieChart width={400} height={350} style={{ margin: "auto" }}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={userwise_total_count}
              cx={200}
              cy={200}
              outerRadius={80}
              fill="#8884d8"
              label
            />
            <Tooltip />
          </PieChart>

          {/* <AreaChart
            width={700}
            height={350}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 0,
            }}
            style={{ margin: "auto" }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
            />
            <Area
              type="monotone"
              dataKey="pv"
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
            <Area
              type="monotone"
              dataKey="amt"
              stackId="1"
              stroke="#ffc658"
              fill="#ffc658"
            />
          </AreaChart> */}
        </div>
      </div>

      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: activeActivity === "Log Records" ? "block" : "none",
        }}
      >
        <List divided selection>
          <RenderLog />
        </List>
      </div>

      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: activeActivity === "Related Forms" ? "block" : "none",
        }}
      >
        {child_count && child_count.length > 0 ? (
          <Nestable
            maxDepth={1}
            collapsed={true}
            items={child_count}
            renderItem={renderItem}
            onChange={handleOnChangeSort}
          />
        ) : (
          <Message>No Related Forms Available</Message>
        )}
      </div>
    </div>,
  ];
}
