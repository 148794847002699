import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
 
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Select,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadHouseImagePage,
  unloadHouseImagePage,
  addHouseImage,
  editHouseImage,
  removeHouseImage,
} from "../../../redux/action/houseImageAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import { ImageModalComponent } from "../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../../redux/action/alertAction";

interface RootState {
  house_images: {
    house_images: {
      data: {
        title: string;
        title_lang: string;
        description: string;
        description_lang: string;
        id: number;
        tile_id: number;
        house_category_id: number;
        good_photo: string;
        bad_photo: string;
        related_house_part_id: [];
      }[];
      last_page: number;
    };
    tiles: {
      id: number;
      name: string;
    }[];
    house_categories: {
      id: number;
      title: string;
      title_lang: string;
      app_id: number;
      app: {
        id: number;
        app_name: string;
      };
    }[];
    apps: {
      id: number;
      app_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type houseCategoryOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function HouseImageQuality() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      title: "",
      title_lang: "",
      description: "",
      description_lang: "",
      good_photo: "",
      bad_photo: "",
      house_category_id: [0],
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [url, setUrl] = useState("");
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/house_image_quality");
  const house_images = useSelector((state: RootState) => state.house_images);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  //const [tileOptions, setTileOptions] = useState<appOptionType[]>([]);
  const [housePartOptions, setHousePartOptions] =
    useState<houseCategoryOptionType>([]);
  const [filter, setFilter] = useState<number[]>([]);
  const [search, setSearch] = useState<string>("");
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadHouseImagePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadHouseImagePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (house_images.house_images !== undefined) {
      setTotal(house_images.house_images.last_page);

      if (house_images.house_categories !== undefined) {
        var house_categories = house_images.house_categories.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text:
              data.title +
              "/" +
              data.title_lang +
              house_images.apps
                .map((app) => {
                  if (app.id === data.app_id) {
                    return " -> " + app.app_name;
                  }
                  
                  return "";
                })
                .join(""),
          };
        });

        setHousePartOptions(house_categories);
      }

      if (house_images.apps !== undefined) {
        var app = house_images.apps.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.app_name,
          };
        });

        setAppOptions(app);
      }
    }
  }, [house_images]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/house_image_quality?page=" +
        pageInfo.activePage.toString() +
        "&filter=" +
        filter
    );
  };
  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/house_image_quality?q=" + search + "&filter=" + filter);
    }
  };

  const handleFilterChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    setFilter(value);
    setApiUrl("/house_image_quality?filter=" + value + "&q=" + search);
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        title: "",
        title_lang: "",
        description: "",
        description_lang: "",
        good_photo: "",
        bad_photo: "",
        house_category_id: [],
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let house_image = house_images.house_images.data.find((v) => v.id === id);

    setData({
      newData: {
        id: house_image!.id,
        title: house_image!.title,
        title_lang: house_image!.title_lang,
        description: house_image!.description,
        description_lang: house_image!.description_lang,
        good_photo: "",
        bad_photo: "",
        house_category_id:
          house_image!.related_house_part_id !== null
            ? house_image!.related_house_part_id
            : [house_image!.house_category_id],
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addHouseImage(fdata.newData, () => {
      dispatch(loadHouseImagePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(editHouseImage(fdata.newData, () => {
      dispatch(loadHouseImagePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeHouseImage(deleteId, () => {
      dispatch(loadHouseImagePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  function RenderList(datas: any) {
    if (datas.data.house_images === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.house_images.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.house_images.data.map((data: any) => {
      // let tile = datas.data.tiles.find((a: any) => a.id === data.tile_id);
      // let house_part = datas.data.house_categories.find(
      //   (a: any) => a.id === data.house_category_id
      // );
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.title}</Table.Cell>
          <Table.Cell>{data.title_lang}</Table.Cell>
          <Table.Cell>{data.description}</Table.Cell>
          <Table.Cell>{data.description_lang}</Table.Cell>
          <Table.Cell>{data.tile ? data.tile.name : ""}</Table.Cell>
          <Table.Cell>
            {data.house_category
              ? data.house_category.title + "/" + data.house_category.title_lang
              : ""}
          </Table.Cell>
          <Table.Cell>
            {data.good_photo ? (
              <Button onClick={() => setUrl(data.good_photo)}>View</Button>
            ) : (
              <i>-</i>
            )}
          </Table.Cell>

          <Table.Cell>
            {data.bad_photo ? (
              <Button onClick={() => setUrl(data.bad_photo)}>View</Button>
            ) : (
              <i>-</i>
            )}
          </Table.Cell>

          <Table.Cell>
            {/* <Button
              primary
              size="mini"
              as={Link}
              to={`/training/${data.id}`}
              icon
            >
              <Icon name="info circle" />
            </Button> */}
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Material Quality</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>House Image</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create House Image
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        House Images
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-2"
          icon="search"
          placeholder="Search..."
        />
      </Header>
      <Select
        placeholder="Filter by App"
        multiple
        clearable
        options={appOptions}
        onChange={handleFilterChange}
      />

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Title Lang</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Description Lang</Table.HeaderCell>
            <Table.HeaderCell>Tile</Table.HeaderCell>
            <Table.HeaderCell>House Materials Quality</Table.HeaderCell>
            <Table.HeaderCell>Good Photo</Table.HeaderCell>
            <Table.HeaderCell>Bad Photo</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {house_images && <RenderList data={house_images} />}
        </Table.Body>
      </Table>

      {house_images.house_images === undefined || loading  ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="large"
      className="modal-customized"
    >
       <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} House Image
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="title"
                label="Title"
                value={fdata.newData.title}
                onChange={handleChange}
                placeholder="Enter Title..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="title_lang"
                label="Title (In Preferred Language)"
                value={fdata.newData.title_lang}
                onChange={handleChange}
                placeholder="Enter Title (In Preferred Language)..."
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="description"
                label="Description"
                value={fdata.newData.description}
                onChange={handleChange}
                placeholder="Enter Description..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="description_lang"
                label="Description (In Preferred Language)"
                value={fdata.newData.description_lang}
                onChange={handleChange}
                placeholder="Enter Description (In Preferred Language)..."
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select House Materials Quality"
                required
                multiple
                options={housePartOptions}
                label="House Materials Quality"
                value={fdata.newData.house_category_id}
                name="house_category_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required={fdata.newData.id === 0}
                type="file"
                name="good_photo"
                label="Upload Good Photo"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required={fdata.newData.id === 0}
                type="file"
                name="bad_photo"
                label="Upload Bad Photo"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
