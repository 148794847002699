import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

//import { fetchFormbuilder } from '../../../redux/action/formbuilderAction';

import {
  fetchFormdata,
  unloadFormdataPage,
  removeFormdata,
} from "../../../redux/action/formDataAction";
import {
  Table,
  Segment,
  Breadcrumb,
  Message,
  Button,
  Header,
  Icon,
  Loader,
  Grid,
  Pagination,
  List,
  Popup as SPopup,
  Checkbox,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";

import ModalComponent from "../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../../services";
import { alertActions } from "../../../redux/action/alertAction";
import MarkerPopup from "../../../components/Modal/MarkerPopup";
import { LatLngExpression } from "leaflet";
import moment from "moment";

interface Props {
  match: { params: { slug: any } };
}

export default function FormbuilderData(props: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [apiUrl, setApiUrl] = useState<string>(
    `/formBuilder/data/${props.match.params.slug}`
  );
  const [total, setTotal] = useState<number>(1);
  const [hideMap, setHideMap] = useState(false);
  const [mapCenter, setMapCenter] = useState<LatLngExpression>([
    27.7047954, 85.3042305,
  ]);
  const [fullScreenMap, setFullScreenMap] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dataId, setDataId] = useState("");
  const [selected, setSelected] = useState<string[]>([]);

  const [isOpenBulkDeleteConfirmModal, setIsOpenBulkDeleteConfirmModal] =
    useState(false);

  const [isBulkDeleting, setIsBulkDeleting] = useState(false);

  const formdata = useSelector((state: any) => state.formdata);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFormdata(apiUrl));

    return () => {
      dispatch(unloadFormdataPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    GetReport(props.match.params.slug);

    if (formdata.data !== undefined) {
      //console.log(formbuilders.last_page)
      setTotal(formdata.data.last_page);

      if (formdata.data.data.length > 0) {
        for (let i = 0; i < formdata.data.data.length; i++) {
          if (
            formdata.data.data[i]._latitude &&
            formdata.data.data[i]._longitude
          ) {
            setMapCenter([
              formdata.data.data[i]._latitude,
              formdata.data.data[i]._longitude,
            ]);
            break;
          }
        }
      }
    }
  }, [formdata]);

  // show hide map handler
  const showHidemapHandler = () => {
    if (hideMap) {
      setHideMap(false);
    } else {
      setHideMap(true);
    }
  };

  // full screen map handler
  const fullScreenMapHandler = () => {
    if (fullScreenMap) {
      setFullScreenMap(false);
    } else {
      setFullScreenMap(true);
    }
  };

  const GetReport = (data: string) => {
    if (data !== "") {
      apiService.save2(`/GetLocation/${data}`, {}).then(
        (data) => {
          if (data.data !== "undefined") {
            setFilteredData(data.data);
          }
        },

        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
    }
  };

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      `/formBuilder/data/${props.match.params.slug}?page=` +
        pageInfo.activePage.toString()
    );
  };

  const deleteData = (slug: string) => {
    setOpen(true);
    setDeleteId(slug);
  };

  const relatedForm = (slug: string, id: string) => {
    history.push(`/formbuilder/fill/${slug}`, { id: id });
  };

  const confirmDelete = () => {
    // alert(delteId)

    dispatch(
      removeFormdata(`/formData/${props.match.params.slug}/delete/${deleteId}`)
    );
    dispatch(fetchFormdata(apiUrl));
    setOpen(false);
  };

  const SelectData = (event: React.FormEvent<HTMLInputElement>, data: any) => {
    const value = data.checked;

    if (value) {
      setSelected((oldArray) => [...oldArray, data.name]);
    } else {
      setSelected((oldArray) => oldArray.filter((item) => item !== data.name));
    }
  };

  const PreDeleteHandler = () => {
    setIsOpenBulkDeleteConfirmModal(true);
  };

  const DeleteHandler = () => {
    setIsBulkDeleting(true);

    apiService
      .save1(`/formData/${props.match.params.slug}/records/delete`, {
        ids: selected,
      })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          dispatch(fetchFormdata(apiUrl));
          setIsOpenBulkDeleteConfirmModal(false);
          setIsBulkDeleting(false);
          setSelected([]);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          setIsOpenBulkDeleteConfirmModal(false);
          setIsBulkDeleting(false);
        }
      );
  };

  const RenderHeader = useCallback(
    (datas: any) => {
      if (datas.data.field === undefined) {
        return <Table.HeaderCell> Loading.....</Table.HeaderCell>;
      }

      return datas.data.field.map((data: string, index: number) => {
        return (
          <Table.HeaderCell key={index + 1} className="overview">
            {" "}
            <span title={data}></span>
          </Table.HeaderCell>
        );
      });
      // eslint-disable-next-line
    },
    [formdata]
  );

  const RenderMarkerCluster = useCallback(
    ({ data }: any) => {
      if (data.data !== undefined && data.data.data.length !== 0) {
        return data.data.data.map((item: any, index: any) => {
          if (
            item.hasOwnProperty("_latitude") &&
            item.hasOwnProperty("_longitude")
          ) {
            // const latLong = item['geoLocation'].split("-")
            let latLong = {
              lat: item["_latitude"] !== "-" ? item["_latitude"] : 0,
              lng: item["_longitude"] !== "-" ? item["_longitude"] : 0,
              zoom: 14,
            };

            return (
              <Marker key={index} position={latLong}>
                <Popup>
                  <List divided relaxed>
                    <List.Item>
                      <List.Content>
                        <List.Header as="p">State: </List.Header>
                        <List.Description as="b">
                          {item.state}
                          <br />
                          <br />
                          {item._latitude},{item._longitude}
                        </List.Description>
                      </List.Content>
                    </List.Item>

                    {data.key
                      ? data.key
                          .filter((itm: string) => itm !== "_id")
                          .map(
                            (
                              members: string,
                              index1: string | number | undefined
                            ) => {
                              return (
                                <React.Fragment key={index1}>
                                  {members in item ? (
                                    Array.isArray(item[members]) ? (
                                      <List.Item>
                                        {" "}
                                        <List.Content>
                                          <List.Header as="p">
                                            {members}:{" "}
                                          </List.Header>
                                          <List.Description as="a">
                                            {item[members].join(", ")}
                                          </List.Description>
                                        </List.Content>
                                      </List.Item>
                                    ) : (
                                      <List.Item>
                                        {" "}
                                        <List.Content>
                                          <List.Header as="p">
                                            {members}:{" "}
                                          </List.Header>
                                          <List.Description as="a">
                                            {item[members]}
                                          </List.Description>
                                        </List.Content>
                                      </List.Item>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            }
                          )
                      : ""}
                  </List>
                </Popup>
              </Marker>
            );
          }
          return "";
        });
      } else {
        return <Marker position={[0, 0]} />;
      }
      // eslint-disable-next-line
    },
    [formdata]
  );

  const RenderList = useCallback(
    ({ data }: any) => {
      if (data.data === undefined || data.data.data === undefined) {
        return (
          <Table.Row key={1}>
            <Table.Cell>
              {" "}
              <Message>Loading ....</Message>
            </Table.Cell>
          </Table.Row>
        );
      }
      if (data.data.data.length === 0) {
        return (
          <Table.Row key="1">
            <Table.Cell>
              {" "}
              <Message>No data Found</Message>
            </Table.Cell>
          </Table.Row>
        );
      }
      return data.data.data.map((item: any, index: any) => {
        return (
          <Table.Row key={item._id.$oid}>
            <Table.Cell>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Checkbox
                  name={item._id.$oid}
                  id={"select-record-" + item._id.$oid}
                  onChange={SelectData}
                ></Checkbox>
                <label
                  htmlFor={"select-record-" + item._id.$oid}
                  style={{ margin: 0, cursor: "pointer" }}
                >
                  <span>{item._id.$oid}</span>
                  <small
                    title={
                      item.created_at
                        ? moment.utc(item.created_at).fromNow()
                        : ""
                    }
                  >
                    <br />
                    {item.created_at ?? ""}
                  </small>
                </label>
              </div>
            </Table.Cell>
            <Table.Cell
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className="formbuilder-buttons"
                circular
                style={{
                  backgroundColor: "white",
                  color: "var(--color-blue)",
                }}
                as={Link}
                to={`/formbuilder/data/${props.match.params.slug}/detail/${item._id.$oid}`}
                icon
              >
                <Icon name="eye" />
              </Button>
              <Button
                icon
                circular
                className="editButton"
                as={Link}
                to={`/formbuilder/data/${props.match.params.slug}/edit/${item._id.$oid}`}
              >
                <Icon name="pencil" />
              </Button>

              <Button
                icon
                circular
                className="deleteButton"
                onClick={() => deleteData(item._id.$oid)}
              >
                <Icon name="trash" />
              </Button>

              <Button
                hidden={data.related === false}
                color="green"
                size="mini"
                onClick={() =>
                  relatedForm(
                    props.match.params.slug,
                    item._related_id ? item._related_id : item._id.$oid
                  )
                }
                icon
              >
                Fill Related form
              </Button>
            </Table.Cell>
            <Table.Cell>{item.status}</Table.Cell>
            <Table.Cell>{item._latitude || 0.0}</Table.Cell>
            <Table.Cell>{item._longitude || 0.0}</Table.Cell>

            {formdata &&
              formdata.form &&
              formdata.form.is_feedback_form &&
              formdata.form.slug === "rhiab-feedback-master-form" && (
                <>
                  <Table.Cell>
                    {item._feedback_user_name ?? "-"} <br />
                    <small>{item._feedback_user_email ?? "-"}</small>
                  </Table.Cell>

                  <Table.Cell>
                    {item._feedback_project_slug ?? "-"} <br />
                    <small>
                      Organization: {item._feedback_organization_slug ?? "-"}
                    </small>
                  </Table.Cell>
                </>
              )}

            {data.key ? (
              data.key
                .filter((itm: string) => itm !== "_id")
                .map((members: string, index1: string | number | undefined) => {
                  return (
                    <Table.Cell key={index1}>
                      <div className="data_container">
                        {members in item
                          ? typeof item[members] === "object"
                            ? "<object>"
                            : item[members]["label"] ?? item[members]
                          : ""}
                      </div>
                    </Table.Cell>
                  );
                })
            ) : (
              <Table.Cell>No data Found</Table.Cell>
            )}
          </Table.Row>
        );
      });
      // eslint-disable-next-line
    },
    [formdata]
  );

  return (
    <>
      <div key={1}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to="/formbuilder">
            Formbuilder
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            {formdata?.form?.name || "..."} (Data List)
          </Breadcrumb.Section>
        </Breadcrumb>
        <ModalComponent
          open={open}
          setOpen={setOpen}
          confirmDelete={confirmDelete}
        />
        <MarkerPopup
          openModal={openModal}
          formId={props.match.params.slug}
          dataId={dataId}
          setOpenModal={setOpenModal}
        />
        <ModalComponent
          open={isOpenBulkDeleteConfirmModal}
          setOpen={setIsOpenBulkDeleteConfirmModal}
          confirmDelete={DeleteHandler}
          strict={true}
          loading={isBulkDeleting}
          text="Permanently delete all selected records?"
          strict_text="This will permanently delete all selected records and cannot be recovered?"
        />
      </div>
      <div className="app-card-org" key={2}>
        <div>
          {hideMap ? (
            <SPopup
              content="Show Map"
              trigger={<Button icon="unhide" onClick={showHidemapHandler} />}
            />
          ) : (
            <SPopup
              content="Hide Map"
              trigger={<Button icon="hide" onClick={showHidemapHandler} />}
            />
          )}

          <SPopup
            content={
              fullScreenMap ? "Click For Half Screen" : "Click for Full Screen"
            }
            trigger={<Button icon="map" onClick={fullScreenMapHandler} />}
          />
        </div>
      </div>
      <div
        className="app-card-org"
        key={3}
        style={{
          display: hideMap ? "none" : "block",
        }}
      >
        <Map
          style={{
            height: fullScreenMap ? "600px" : "250px",
            zIndex: 99,
            display: hideMap ? "none" : "block",
          }}
          center={[27.7047954, 85.3042305]}
          zoom={5}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {filteredData.map(
              (
                item: {
                  _latitude: any;
                  _longitude: any;
                  _id: { $oid: string };
                },
                index: number
              ) => (
                <Marker
                  key={index}
                  position={[
                    item._latitude === "-" ? 0 : item._latitude || 0,
                    item._longitude === "-" ? 0 : item._longitude || 0,
                  ]}
                  onClick={() => {
                    setDataId(item._id.$oid || "");
                    setOpenModal(true);
                  }}
                ></Marker>
              )
            )}
          </MarkerClusterGroup>
        </Map>
      </div>
      <div className="app-card-org" key={4} style={{ overflowX: "scroll" }}>
        <div>
          {selected.length > 0 ? (
            <Button
              className="formbuilder-buttons deleteButton"
              onClick={PreDeleteHandler}
            >
              Delete Records ({selected.length})
            </Button>
          ) : (
            ""
          )}
          <Table basic="very" unstackable striped role="grid">
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Latitude</Table.HeaderCell>
                <Table.HeaderCell>Longitude</Table.HeaderCell>

                {formdata &&
                  formdata.form &&
                  formdata.form.is_feedback_form &&
                  formdata.form.slug === "rhiab-feedback-master-form" && (
                    <>
                      <Table.HeaderCell>User Name</Table.HeaderCell>
                      <Table.HeaderCell>Project Slug</Table.HeaderCell>
                    </>
                  )}

                {formdata && <RenderHeader data={formdata} />}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {formdata.data === undefined ? (
                <Loader active inverted size="big" />
              ) : (
                <RenderList data={formdata} />
              )}
            </Table.Body>
          </Table>
        </div>

        <Grid>
          <Grid.Column>
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
}
