import { boqRoofTypes } from "../action/boqRoofAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case boqRoofTypes.BOQ_ROOF_LOADED:
      return action.payload;

    case boqRoofTypes.BOQ_ROOF_UNLOADED:
      return initialState;
    case boqRoofTypes.ADD_BOQ_ROOF:
      return { ...state };
    case boqRoofTypes.EDIT_BOQ_ROOF:
      return state;
    case boqRoofTypes.DELETE_BOQ_ROOF:
      return state;

    default:
      return state;
  }
};
