import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const messageTypes = {
  MESSAGE_LOADED: "MESSAGE_LOADED",
  MESSAGE_UNLOADED: "MESSAGE_UNLOADED",
  ADD_MESSAGE: "ADD_MESSAGE",
  EDIT_MESSAGE: "EDIT_MESSAGE",
  DELETE_MESSAGE: "DELETE_MESSAGE",
};

export const loadMessagePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: messageTypes.MESSAGE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadMessagePage = () => {
  return {
    type: messageTypes.MESSAGE_UNLOADED,
    payload: {},
  };
};

export const addMessage = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/message", payload).then(
      (data) => {
        dispatch({
          type: messageTypes.ADD_MESSAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editMessage = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/message/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: messageTypes.EDIT_MESSAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeMessage = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/message/delete/${id}`).then(
      (data) => {
        dispatch({
          type: messageTypes.DELETE_MESSAGE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
