import React, { useState, useEffect, Component } from "react";
import {
  Segment,
  Header,
  Breadcrumb,
  Message,
  Divider,
  Icon,
  Feed,
  SemanticICONS,
} from "semantic-ui-react";
import {
  loadSocialAnalyticsPage,
  unloadSocialAnalyticsPage,
} from "../../redux/action/socialAnalyticsAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  social_analytics: {
    success: boolean;
    data: {
      about: {
        id: number;
        name: string;
        fan_count: number;
        likes?: any;
        about: string;
        picture: string;
        cover: string;
      };
      posts: {
        id: number;
        message: string;
        picture: string;
        share: number;
        view: number;
        like: number;
        dislike: number;
        created_time: string;
      }[];
    };
    type: string;
  };
}

export default function SocialAnalyticsInfo(props: Props) {
  const [apiUrl, setApiUrl] = useState(
    `/social_analytics/${props.match.params.id}`
  );
  const social_analytics = useSelector(
    (state: RootState) => state.social_analytics
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    // setApiUrl(`/social_analytics/${props.match.params.id}`);
    dispatch(loadSocialAnalyticsPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadSocialAnalyticsPage());
    };
  }, [apiUrl, dispatch, props.match.params.id]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Social Analytics</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {social_analytics === undefined ||
        Object.keys(social_analytics).length <= 0 ||
        !social_analytics.success ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {social_analytics.data.about.name}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <div>
      {social_analytics === undefined ||
      Object.keys(social_analytics).length <= 0 ||
      !social_analytics.success ? (
        ""
      ) : (
        <GenSocialType social_analytics={social_analytics} />
      )}
    </div>,
    <Segment key="2">
      {social_analytics === undefined ||
      Object.keys(social_analytics).length <= 0 ||
      !social_analytics.success ? (
        <Message>Loading...</Message>
      ) : (
        <div>
          <Header>
            {social_analytics.data.about.cover && (
              <div
                className="cover-social-media"
                style={{
                  background: "url(" + social_analytics.data.about.cover + ")",
                }}
              ></div>
            )}
            <div className="row">
              <div className="col-md-6">
                <h2 style={{ fontWeight: 900 }}>
                  {social_analytics.data.about.name}
                </h2>
              </div>
              <div className="col-md-6 text-right">
                <small style={{ fontSize: "0.6em" }}>
                  <i>{social_analytics.data.about.id}</i>
                </small>
                <br />
                <small
                  style={{
                    fontSize: "0.5em",
                    marginTop: "-10px",
                    display:
                      social_analytics.type &&
                      social_analytics.type.indexOf("ads") >= 0
                        ? "none"
                        : "block",
                  }}
                >
                  <b>Page ID</b>
                </small>
              </div>
            </div>
          </Header>
          <Divider />
          <Info social_analytics={social_analytics} />
        </div>
      )}
    </Segment>,
    <Segment
      key="3"
      style={{
        display:
          social_analytics.type && social_analytics.type.indexOf("ads") >= 0
            ? "none"
            : "block",
      }}
    >
      {social_analytics === undefined ||
      Object.keys(social_analytics).length <= 0 ||
      !social_analytics.success ? (
        <Message>No Posts</Message>
      ) : (
        <div>
          <PostInfo social_analytics={social_analytics} />
        </div>
      )}
    </Segment>,
  ];
}

class Info extends Component<RootState> {
  render() {
    const data = this.props.social_analytics;
    if (!data || !data.data) {
      return <Message>Loading ....</Message>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <p style={{ margin: "2px 0" }}>
              <Icon circular name="users" /> {data.data.about.fan_count}{" "}
              {data.type.indexOf("ad") >= 0
                ? "Total Impressions"
                : "people follow this"}
            </p>
            {data.type.indexOf("ad") >= 0 && (
              <p style={{ margin: "2px 0" }}>
                <Icon circular name="mouse pointer" /> {data.data.about.likes}{" "}
                Total Unique Clicks
              </p>
            )}
            <p style={{ margin: "2px 0" }}>
              <Icon circular name="info" /> {data.data.about.about}
            </p>
          </div>
          <div className="col-md-6 text-right">
            {data.data.about.picture && (
              <img className={"small"} src={data.data.about.picture} alt='img'></img>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class PostInfo extends Component<RootState> {
  render() {
    const data = this.props.social_analytics;
    if (!data || !data.data) {
      return "";
    }

    return (
      <div>
        <Header>
          <h5>Recent Posts</h5>
        </Header>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <Feed>
              {data.data.posts.map((post) => {
                return (
                  <Feed.Event
                    key={post.id}
                    image={data.data.about.picture}
                    date={new Date(post.created_time).toLocaleString()}
                    summary={{ user: data.data.about.name }}
                    extraText={post.message ? post.message : " "}
                    extraImages={post.picture ? [post.picture] : []}
                    meta={{
                      children: (
                        <div>
                          <Icon name="thumbs up" />
                          {post.like} Likes &nbsp;|&nbsp;
                          {post.dislike ? (
                            <span>
                              <Icon name="thumbs down" />
                              {post.dislike} Dislikes &nbsp;|&nbsp;
                            </span>
                          ) : (
                            ""
                          )}
                          {post.view ? (
                            <span>
                              <Icon name="eye" />
                              {post.view} Views
                            </span>
                          ) : (
                            <span>
                              <Icon name="share alternate" />
                              {post.share} Share
                            </span>
                          )}
                          <br />
                          <br />
                        </div>
                      ),
                    }}
                  />
                );
              })}
            </Feed>
          </div>
        </div>
      </div>
    );
  }
}

class GenSocialType extends Component<RootState> {
  render() {
    const social_analytics = this.props.social_analytics;
    let icon: SemanticICONS = "pencil";
    let text = "Social Media";

    if (social_analytics.type === "facebook") {
      icon = "facebook";
      text = "Facebook";
    } else if (social_analytics.type === "instagram") {
      icon = "instagram";
      text = "Instagram";
    } else if (social_analytics.type === "twitter") {
      icon = "twitter";
      text = "Twitter";
    } else if (social_analytics.type === "youtube") {
      icon = "youtube";
      text = "YouTube";
    } else if (social_analytics.type === "facebook_ads") {
      icon = "facebook";
      text = "Facebook Ads";
    }

    return (
      <Message compact floating size="mini">
        {icon !== "pencil" ? (
          <div>
            <Icon name={icon}></Icon> {text}
          </div>
        ) : (
          text
        )}
      </Message>
    );
  }
}
