import React, { useState, useEffect, Component } from "react";
import {

  Header,
  Breadcrumb,
  Message,
  Divider,
} from "semantic-ui-react";
import {
  loadTrainingModulePage,
  unloadTrainingModulePage,
} from "../../redux/action/trainingModuleAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  training_modules: {
    name: string;
    id: number;
    app_id: number;
    description: string;
    image: string;
    app?: {
      app_name: string;
      created_at: string;
      project_id: number;
      status: string;
    };
    project?: {
      id: number;
      name: string;
      slug: string;
    };
    module_lessons?: {
      id: number;
      name: string;
      description: string;
    }[];
  };
}

export default function TrainingModuleInfo(props: Props) {
  const [apiUrl] = useState(`/training_module/${props.match.params.id}`);
  const training_modules = useSelector(
    (state: RootState) => state.training_modules
  );

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadTrainingModulePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadTrainingModulePage());
    };
  }, [apiUrl, dispatch]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Training Module</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {training_modules === undefined ||
        Object.keys(training_modules).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {training_modules.name}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <div className="app-card-org" key="2">
      <Header>Training Modules Info</Header>
      <Divider />
      {training_modules === undefined ||
      Object.keys(training_modules).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <TrainingInfo training_modules={training_modules} />
      )}
    </div>,
  ];
}

class TrainingInfo extends Component<RootState> {
  render() {
    const data = this.props.training_modules;
    return (
      <div>
        <b>{data.name}</b>
        <p>{data.description}</p>
        {data.image ? (
          <img
            src={data.image}
            alt="Failed to Load"
            className="ui medium image"
          />
        ) : (
          ""
        )}

        <b>App: {data.app ? data.app.app_name : "N/A (No App Linked)"}</b>
        <p>Created At: {data.app ? data.app.created_at : "N/A"}</p>

        <b>Project: {data.project ? data.project.name : "N/A (No Project Linked)"}</b>
        <p>Project Slug: {data.project ? data.project.slug : "-"}</p>

        <hr />
        <h4>Module Lessons</h4>
        <table className="table border table-striped col-sm-12 col-lg-6">
          <thead style={{ color: "var(--color-blue)" }}>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {data.module_lessons
              ? data.module_lessons.map((i) => {
                  return (
                    <tr key={`module_lessons_${i.id}`}>
                      <td>{i.name}</td>
                      <td>{i.description}</td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
    );
  }
}
