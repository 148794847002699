export function authHeader() {
  interface UserType {
    access_token: string;
  }

  // return authorization header with jwt token
  let user_info: any = localStorage.getItem("user");
  let user: UserType = JSON.parse(user_info);

  if (user && user.access_token) {
    return {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",

      Authorization: "Bearer " + user.access_token,
    };
  } else {
    return {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }
}

export function sesmicHeader() {
  interface UserType {
    sesmic_token: string;
  }

  // return authorization header with jwt token
  let user_info: any = localStorage.getItem("user");
  let user: UserType = JSON.parse(user_info);

  if (user && user.sesmic_token) {
    return {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",

      Authorization: "Bearer " + user.sesmic_token,
    };
  } else {
    return {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }
}
