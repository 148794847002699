import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const unitPriceTypes = {
  UNIT_PRICE_LOADED: "UNIT_PRICE_LOADED",
  UNIT_PRICE_UNLOADED: "UNIT_PRICE_UNLOADED",
  ADD_UNIT_PRICE: "ADD_UNIT_PRICE",
  EDIT_UNIT_PRICE: "EDIT_UNIT_PRICE",
  DELETE_UNIT_PRICE: "DELETE_UNIT_PRICE",
};

export const loadUnitPricePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: unitPriceTypes.UNIT_PRICE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadUnitPricePage = () => {
  return {
    type: unitPriceTypes.UNIT_PRICE_UNLOADED,
    payload: {},
  };
};

export const addUnitPrice = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/unit_price", payload).then(
      (data) => {
        dispatch({
          type: unitPriceTypes.ADD_UNIT_PRICE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editUnitPrice = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/unit_price/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: unitPriceTypes.EDIT_UNIT_PRICE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeUnitPrice = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/unit_price/delete/${id}`).then(
      (data) => {
        dispatch({
          type: unitPriceTypes.DELETE_UNIT_PRICE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
