import { alertActions } from './alertAction';
import { apiService } from '../../services';
export const dashboardTypes = {
   
    FETCH_MENU: "FETCH_MENU",
    DASHBOARD_UNLOADED: "DASHBOARD_UNLOADED",

}

export const fetchMenu =  () => { 
    
    return async (dispatch:any) =>{

        apiService.getAll('/dashboard')
        .then(
            data => { 
               
                dispatch({
                    type: dashboardTypes.FETCH_MENU,
                    payload: data
                })
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };


}

export const unloadMenu = () => {
    return {
        type: dashboardTypes.DASHBOARD_UNLOADED,
        payload: {}

    };
}



