import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,

  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadSharingTypePage,
  unloadSharingTypePage,
  addSharingType,
  editSharingType,
  removeSharingType,
} from "../../redux/action/sharingAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  sharings: {
    sharings: {
      data: {
        title: string;
        id: number;
        created_by: {
          first_name: string;
          last_name: string;
        };
      }[];
      last_page: number;
    };
  };
}

export default function Sharing() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      title: "",
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/sharing");
  const [search, setSearch] = useState<string>("");
  const sharings = useSelector((state: RootState) => state.sharings);
  const [total, setTotal] = useState(1);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadSharingTypePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadSharingTypePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (sharings.sharings !== undefined) {
      setTotal(sharings.sharings.last_page);
    }
  }, [sharings]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl(
      "/sharing?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/sharing?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        title: "",
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let sharing = sharings.sharings.data.find((v) => v.id === id);

    setData({
      newData: {
        id: sharing!.id,
        title: sharing!.title,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addSharingType(fdata.newData, () => {
      dispatch(loadSharingTypePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editSharingType(fdata.newData, () => {
      dispatch(loadSharingTypePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeSharingType(deleteId, () => {
      dispatch(loadSharingTypePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  function RenderList(datas: any) {
    if (datas.data.sharings === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.sharings.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.sharings.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.title}</Table.Cell>
          <Table.Cell>
            {data.created_by.first_name + " " + data.created_by.last_name}
          </Table.Cell>

          <Table.Cell>
            <Button
              circular
              as={Link}
              to={`/sharing/${data.id}`}
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
              icon
            >
              <Icon name="edit" />
            </Button>
            <Button
              circular
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-green)",
              }}
              icon
              as={Link}
              to={`/portal/${data.public_id}`}
            >
              <Icon name="eye" />
            </Button>
            <Button
              circular
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              className="editButton"
              icon
            >
              <Icon name="pencil" />
            </Button>

            <Button
              circular
              onClick={() => deleteData(data.id)}
              icon
              className="deleteButton"
            >
              <Icon name="delete" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Sharings Portals</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <div className="app-card-org" style={{ overflowX: "scroll" }}>
        <Button
          className="createButton"
          icon
          labelPosition="left"
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
        >
          Create Sharing Portals
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>
          Sharing Portals
          <Input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={onSearch}
            className="pull-right small mb-3"
            icon="search"
            placeholder="Search..."
          />
        </Header>

        <Table basic="very" unstackable selectable striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>

              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{sharings && <RenderList data={sharings} />}</Table.Body>
        </Table>

        {sharings.sharings === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column>
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>
      ,
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="tiny"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Sharing Portal
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="title"
                label="Title"
                value={fdata.newData.title}
                onChange={handleChange}
                placeholder="Enter Title"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
