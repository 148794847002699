import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadMaterialListPage,
  unloadMaterialListPage,
  addMaterialList,
  editMaterialList,
  removeMaterialList,
} from "../../../redux/action/materialListAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../../redux/action/alertAction";
interface RootState {
  material_lists: {
    material_lists: {
      data: {
        id: number;
        name: string;
        name_lang: string;
        unit: string;
        order: number;
        category_id: number;
      }[];
      last_page: number;
    };
    material_categories: {
      id: number;
      name: string;
      name_lang: string;
    }[];
  };
}

type materialCategoryOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function MaterialList() {
  const [fdata, setData] = useState({
    newData: [
      {
        id: 0,
        name: "",
        name_lang: "",
        unit: "",
        order: 0,
        category_id: 0,
      },
    ],
  });
  const [form_counter, setFormCounter] = useState({ counter: 1 });
  const [open, setOpen] = useState(false);
  const [editing, setEdit] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [openInfo, setOpenInfo]: any = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/material_list");
  const material_lists = useSelector(
    (state: RootState) => state.material_lists
  );
  const [total, setTotal] = useState(1);
  const [materialCategoryOptions, setMaterialCategoryOptions] =
    useState<materialCategoryOptionType>([]);
  const [loading, setLoading] = useState(false);

  const increaseFormCounter = () => {
    const newFormCouter = form_counter.counter + 1;
    setFormCounter({ counter: newFormCouter });
    let data = fdata.newData.concat({
      id: 0,
      name: "",
      name_lang: "",
      unit: "",
      order: 0,
      category_id: 0,
    });

    setData({
      newData: data,
    });
  };

  const decreaseFormCounter = (index: number) => {
    const newFormCouter = form_counter.counter - 1;
    setFormCounter({ counter: newFormCouter });
    let data = fdata.newData.filter((item, i) => {
      return index === i;
    });

    setData({
      newData: data,
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadMaterialListPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadMaterialListPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (material_lists.material_lists !== undefined) {
      setTotal(material_lists.material_lists.last_page);
      var final_stage = material_lists.material_categories.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name + " / " + data.name_lang,
        };
      });

      setMaterialCategoryOptions(final_stage);
    }
  }, [material_lists]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/material_list?page=" + pageInfo.activePage.toString());
  };

  const clearForm = () => {
    setData({
      newData: [
        {
          id: 0,
          name: "",
          name_lang: "",
          unit: "",
          order: 0,
          category_id: 0,
        },
      ],
    });
  };

  const selectDataForEditing = (id: number) => {
    setEdit(true);
    let material_list = material_lists.material_lists.data.find(
      (v) => v.id === id
    );

    setData({
      newData: [
        {
          id: material_list!.id,
          name: material_list!.name,
          name_lang: material_list!.name_lang,
          unit: material_list!.unit,
          order: material_list!.order,
          category_id: material_list!.category_id,
        },
      ],
    });
  };

  const handleChange = async (event: any, result: any, index: number) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;
    let f_ind_data = fdata.newData[index];
    f_ind_data[name] = value;
    let newdata = fdata.newData.map((data, i) => {
      if (index === i) {
        return f_ind_data;
      } else {
        return data;
      }
    });

    setData({
      newData: newdata,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (!editing) 
    {
      dispatch(addMaterialList({ data: fdata.newData }, () => {
        dispatch(loadMaterialListPage(apiUrl, () => { 
          setLoading(false);
          dispatch(alertActions.success("Data added successfully"));
        }));
        clearForm();
        setOpenInfo(false);
        
      }, () => {
        setLoading(false);
      }));
    } 
    else 
    {
      dispatch(editMaterialList({ data: fdata.newData }, () => {
        dispatch(loadMaterialListPage(apiUrl, () => { 
          setLoading(false);
          dispatch(alertActions.success("Data Edited successfully"));
        }));
        clearForm();
        setOpenInfo(false);
        
      }, () => {
        setLoading(false);
      }));
    }

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeMaterialList(deleteId, () => {
      dispatch(loadMaterialListPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));       
  };

  function RenderList(datas: any) {
    if (datas.data.material_lists === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.material_lists.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.material_lists.data.map((data: any) => {
      let category = datas.data.material_categories.find(
        (a: any) => a.id === data.category_id
      );
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.name_lang}</Table.Cell>
          <Table.Cell>{data.unit}</Table.Cell>
          <Table.Cell>{data.order}</Table.Cell>
          <Table.Cell>
            {category ? category.name + " / " + category.name_lang : ""}
          </Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>BOQ</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Material List</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Material List
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>Material List</Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Material Name</Table.HeaderCell>
            <Table.HeaderCell>Material Name Lang.</Table.HeaderCell>
            <Table.HeaderCell>Unit</Table.HeaderCell>
            <Table.HeaderCell>Order</Table.HeaderCell>
            <Table.HeaderCell>Material Category</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {material_lists && <RenderList data={material_lists} />}
        </Table.Body>
      </Table>

      {material_lists.material_lists === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Material List
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          {[...Array(fdata.newData.length)].map((e, index) => {
            return (
              <>
                <Form.Group widths={2}>
                  <Form.Field>
                    <Form.Select
                      fluid
                      placeholder="Select Material Category"
                      required
                      options={materialCategoryOptions}
                      label="Material Category"
                      value={fdata.newData[index].category_id}
                      name="category_id"
                      onChange={(event, result) =>
                        handleChange(event, result, index)
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      fluid
                      required
                      type="text"
                      name="name"
                      label="Material Name"
                      value={fdata.newData[index].name}
                      onChange={(event, result) =>
                        handleChange(event, result, index)
                      }
                      placeholder="Material Name"
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field>
                  <Form.Input
                    fluid
                    required
                    type="text"
                    name="name_lang"
                    label="Material Name (In Preferred Language)"
                    value={fdata.newData[index].name_lang}
                    onChange={(event, result) =>
                      handleChange(event, result, index)
                    }
                    placeholder="Material Name (In Preferred Language)"
                  />
                </Form.Field>

                <Form.Field>
                  <Form.Input
                    fluid
                    required
                    type="text"
                    name="unit"
                    label="Material Unit"
                    value={fdata.newData[index].unit}
                    onChange={(event, result) =>
                      handleChange(event, result, index)
                    }
                    placeholder="Material Unit"
                  />
                </Form.Field>

                <Form.Field>
                  <Form.Input
                    fluid
                    required
                    type="text"
                    name="order"
                    label="Order"
                    value={fdata.newData[index].order}
                    onChange={(event, result) =>
                      handleChange(event, result, index)
                    }
                    placeholder="Order"
                  />
                </Form.Field>

                <Form.Field>
                  <br />
                  {!editing ? (
                    index === 0 ? (
                      <Button primary onClick={() => increaseFormCounter()}>
                        Add More
                      </Button>
                    ) : (
                      <Button
                        primary
                        onClick={() => decreaseFormCounter(index)}
                      >
                        Remove
                      </Button>
                    )
                  ) : (
                    ""
                  )}
                </Form.Field>
              </>
            );
          })}
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
