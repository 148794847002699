import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const liveTrainingTypes = {
  LIVE_TRAINING_LOADED: "LIVE_TRAINING_LOADED",
  LIVE_TRAINING_UNLOADED: "LIVE_TRAINING_UNLOADED",
  ADD_LIVE_TRAINING: "ADD_LIVE_TRAINING",
  EDIT_LIVE_TRAINING: "EDIT_LIVE_TRAINING",
  DELETE_LIVE_TRAINING: "DELETE_LIVE_TRAINING",
};

export const loadLiveTrainingPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
        type: liveTrainingTypes.LIVE_TRAINING_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadLiveTrainingPage = () => {
  return {
    type: liveTrainingTypes.LIVE_TRAINING_LOADED,
    payload: {},
  };
};

export const addLiveTraining = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/live_training", payload).then(
      (data) => {
        dispatch({
          type: liveTrainingTypes.ADD_LIVE_TRAINING,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editLiveTraining = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/live_training/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: liveTrainingTypes.EDIT_LIVE_TRAINING,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeLiveTraining = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/live_training/delete/${id}`).then(
      (data) => {
        dispatch({
          type: liveTrainingTypes.DELETE_LIVE_TRAINING,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
