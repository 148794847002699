import { awarenessModuleTypes } from "../action/awarenessModuleAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case awarenessModuleTypes.AWARENESS_MODULE_LOADED:

            return action.payload;

        case awarenessModuleTypes.AWARENESS_MODULE_UNLOADED:
            return initialState;
        case awarenessModuleTypes.ADD_AWARENESS_MODULE:
           
             return { ...state};
        case awarenessModuleTypes.EDIT_AWARENESS_MODULE:

            return state;
        case awarenessModuleTypes.DELETE_AWARENESS_MODULE:
           
            return state;

        default:
            return state;
    }
};
