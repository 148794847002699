import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const staticPageTypes = {
  STATIC_PAGE_LOADED: "STATIC_PAGE_LOADED",
  STATIC_PAGE_UNLOADED: "STATIC_PAGE_UNLOADED",
  ADD_STATIC_PAGE: "ADD_STATIC_PAGE",
  EDIT_STATIC_PAGE: "EDIT_STATIC_PAGE",
  DELETE_STATIC_PAGE: "DELETE_STATIC_PAGE",
};

export const loadStaticPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: staticPageTypes.STATIC_PAGE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadStaticPage = () => {
  return {
    type: staticPageTypes.STATIC_PAGE_UNLOADED,
    payload: {},
  };
};

export const addStatic = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/static_page", payload).then(
      (data) => {
        dispatch({
          type: staticPageTypes.ADD_STATIC_PAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editStatic = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/static_page/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: staticPageTypes.EDIT_STATIC_PAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeStatic = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/static_page/delete/${id}`).then(
      (data) => {
        dispatch({
          type: staticPageTypes.DELETE_STATIC_PAGE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
