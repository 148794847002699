import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const appModuleTypes = {
  APP_MODULE_LOADED: "APP_MODULE_LOADED",
  APP_MODULE_UNLOADED: "APP_MODULE_UNLOADED",
  ADD_APP_MODULE: "ADD_APP_MODULE",
  EDIT_APP_MODULE: "EDIT_APP_MODULE",
  DELETE_APP_MODULE: "DELETE_APP_MODULE",
};

export const loadAppModulePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: appModuleTypes.APP_MODULE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAppModulePage = () => {
  return {
    type: appModuleTypes.APP_MODULE_UNLOADED,
    payload: {},
  };
};

export const addAppModule = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/app", payload).then(
      (data) => {
        dispatch({
          type: appModuleTypes.ADD_APP_MODULE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAppModule = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/app/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: appModuleTypes.EDIT_APP_MODULE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAppModule = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/app/delete/${id}`).then(
      (data) => {
        dispatch({
          type: appModuleTypes.DELETE_APP_MODULE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
