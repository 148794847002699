import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const strengthenHouseImageTypes = {
    STRENGTHEN_HOUSE_IMAGE_LOADED: "STRENGTHEN_HOUSE_IMAGE_LOADED",
    STRENGTHEN_HOUSE_IMAGE_UNLOADED: "STRENGTHEN_HOUSE_IMAGE_UNLOADED",
    ADD_STRENGTHEN_HOUSE_IMAGE: "ADD_STRENGTHEN_HOUSE_IMAGE",
    EDIT_STRENGTHEN_HOUSE_IMAGE: "EDIT_STRENGTHEN_HOUSE_IMAGE",
    DELETE_STRENGTHEN_HOUSE_IMAGE: "DELETE_STRENGTHEN_HOUSE_IMAGE",
};

export const loadStrengthenHouseImagePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: strengthenHouseImageTypes.STRENGTHEN_HOUSE_IMAGE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadStrengthenHouseImagePage = () => {
  return {
    type: strengthenHouseImageTypes.STRENGTHEN_HOUSE_IMAGE_UNLOADED,
    payload: {},
  };
};

export const addStrengthenHouseImage = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/strengthen_house_image", payload).then(
      (data) => {
        dispatch({
          type: strengthenHouseImageTypes.ADD_STRENGTHEN_HOUSE_IMAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editStrengthenHouseImage = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/strengthen_house_image/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: strengthenHouseImageTypes.EDIT_STRENGTHEN_HOUSE_IMAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeStrengthenHouseImage = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/strengthen_house_image/delete/${id}`).then(
      (data) => {
        dispatch({
          type: strengthenHouseImageTypes.DELETE_STRENGTHEN_HOUSE_IMAGE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
