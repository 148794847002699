import React, { useState, useEffect, Component } from "react";
import {
  Segment,
  Header,
  Breadcrumb,
  Message,
  Divider,
} from "semantic-ui-react";
import { loadTilePage, unloadTilePage } from "../../redux/action/tileAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface Tile {
  id: number;
  name: string;
  value: string;
  type: string;
  order: string;
  awareness_module_id: number;
  form_id: number;
  tile_id: number;
}

interface RootState {
  tiles: {
    tile: Tile;
    parent: Tile;
    children: Tile[];
    awareness_news: {
      title: string;
      id: number;
      description: string;
      thumbnail: string;
    }[];
    awareness_documents: {
      name: string;
      id: number;
      description: string;
      type: string;
      document: string;
    }[];
    awareness_videos: {
      id: number;
      title: string;
      description: string;
      video: string;
    }[];
  };
}

export default function TileInfo(props: Props) {
  const [apiUrl, setApiUrl] = useState(`/tile/${props.match.params.id}`);
  const [loading, setLoading] = useState(false);
  const tiles = useSelector((state: RootState) => state.tiles);

  const dispatch = useDispatch();
  useEffect(() => {
    setApiUrl(`/tile/${props.match.params.id}`);
    dispatch(loadTilePage(apiUrl, () => { 
      setLoading(false);
      // dispatch(alertActions.success("Data Edited successfully"));
    }));

    return () => {
      dispatch(unloadTilePage());
    };
  }, [apiUrl, dispatch, props.match.params.id]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Tile</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {tiles === undefined || Object.keys(tiles).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {tiles.tile ? tiles.tile.name : props.match.params.id}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <Segment key="2">
      <Header>Tile Info</Header>
      <Divider />
      {tiles === undefined || Object.keys(tiles).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <Info tiles={tiles} />
      )}
    </Segment>,
  ];
}

class Info extends Component<RootState> {
  render() {
    const data = this.props.tiles;
    if (!data || !data.tile) {
      return <Message>Loading ....</Message>;
    }

    return (
      <div className="row">
        <div className="col-md-6">
          <b>Name: {data.tile.name}</b>
          <p style={{margin: 0}}>Value: {data.tile.value}</p>
          <p style={{margin: 0}}>Type: {data.tile.type}</p>
          <p style={{margin: 0}}>Order: {data.tile.order}</p>
          <br />
          <b>
            Parent Tile:{" "}
            {data.parent && data.parent.name
              ? data.parent.name
              : "No Parent Tile"}
          </b>

          <hr />
          <h4>Children Tile</h4>
          <table className="table border table-striped col-sm-12">
            <thead className="bg-info">
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>Type</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data.children) && data.children.length > 0 ? (
                data.children.map((i) => {
                  return (
                    <tr key={`tile_children_${i.id}`}>
                      <td><Link to={"/tile/"+i.id}>{i.name}</Link></td>
                      <td>{i.value}</td>
                      <td>{i.type}</td>
                      <td>{i.order}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={4}>
                    NO DATA
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <hr />

          <h2 className={"text-center"}>- Awareness Documents</h2>
          {Array.isArray(data.awareness_documents) &&
          data.awareness_documents.length > 0 ? (
            data.awareness_documents.map((i) => {
              return (
                <div className="card mb-2" key={`awareness_documents_${i.id}`}>
                  <div className="card-body">
                    <h5>{i.name.toLocaleUpperCase()}</h5>
                    <small>Type: {i.type}</small>
                    <pre style={{ whiteSpace: "pre-line" }}>
                      {i.description}
                    </pre>
                    <a
                      href={i.document}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      View File
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="card">
              <b className="card-body">NO DOCUMENTS</b>
            </div>
          )}

          <hr />

          <h2 className={"text-center"}>- Awareness Videos</h2>
          {Array.isArray(data.awareness_videos) &&
          data.awareness_videos.length > 0 ? (
            data.awareness_videos.map((i) => {
              return (
                <div className="card mb-2" key={`awareness_videos_${i.id}`}>
                  <div className="card-body">
                    <h5>{i.title.toLocaleUpperCase()}</h5>
                    <pre style={{ whiteSpace: "pre-line" }}>
                      {i.description}
                    </pre>
                    <a
                      href={i.video}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      View File
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="card">
              <b className="card-body">NO VIDEOS</b>
            </div>
          )}
        </div>

        <div className="col-md-6">
          <h2 className={"text-center"}>- Awareness News</h2>
          {Array.isArray(data.awareness_news) &&
          data.awareness_news.length > 0 ? (
            data.awareness_news.map((i) => {
              return (
                <div className="card mb-2" key={`awareness_news_${i.id}`}>
                  <div className="card-body">
                    <h5>{i.title.toLocaleUpperCase()}</h5>
                    <pre style={{ whiteSpace: "pre-line" }}>
                      {i.description}
                    </pre>
                    <img
                      alt="Failed to Load"
                      className="ui medium image"
                      src={i.thumbnail}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="card">
              <b className="card-body">NO NEWS</b>
            </div>
          )}
        </div>
      </div>
    );
  }
}
