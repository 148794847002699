import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Formio, Components } from "@formio/react";
import { Provider } from "react-redux";
import store from "./redux";
import "semantic-ui-css/semantic.min.css";
import { Router } from "react-router-dom";
import { history } from "./helper/history";
import { AppConfig } from "./config";
import formiocomponents from "./components/formio";
import "./styles.scss";
import "bootstrap-4-react";

import { IntlProviderWrapper } from "./context/IntlContext";
import ErrorBoundary from "./error-boundary";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_SENTRY_ENABLED === "true"
) {
  Sentry.init({
    environment: process.env.NODE_ENV ?? "production",
    dsn: "https://57963c528259499db93f78eb8845344c@o4504565749645312.ingest.sentry.io/4504603889238016",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.4 : 0.8,
    normalizeDepth: 10,
  });
}

// @ts-ignore
if (module.hot) module.hot.accept();

Formio.setProjectUrl(AppConfig.apiUrl);
Formio.setBaseUrl(AppConfig.apiUrl);

Components.setComponents(formiocomponents);

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router history={history}>
      <IntlProviderWrapper>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </IntlProviderWrapper>
    </Router>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();

// REQUEST LOG GENERATOR
let logexporter: any = null;
document.addEventListener("keyup", function (e: KeyboardEvent) {
  let el = e.target as HTMLElement;

  if (
    el &&
    (el.tagName.toUpperCase() === "INPUT" ||
      el.tagName.toUpperCase() === "TEXTAREA")
  ) {
    return false; // Do not react if typing inside input or textarea
  } else {
    var code = e.code;
    var key = e.key;
    // If tilde key press OPEN a window and show the base64 fetch log content.
    // ON tilde click inside the window show JSON of encoded content there.
    if (code === "96" || key === "`") {
      if (logexporter) {
        logexporter.close();
      }
      logexporter = window.open(
        "",
        "",
        "left=0,top=0,width=500,height=400,toolbar=0,scrollbars=0,status=0"
      );
      if (logexporter) {
        let data = sessionStorage.getItem("fetchlog");
        let empty = data ? false : true;
        logexporter.document.write(`<title>Error Log Export</title>
        <body style="line-break: anywhere;font-size: 0.6em;">
          <span id="encoded">${data || "LOG EMPTY"}</span>
          <span id="decoded" style="display: none;white-space: pre;"></span>
          <script>
            if(!${empty}){
              document.addEventListener('keyup', function (e) {
                if ( e.code === "96" || e.key === '\`' ) {
                  document.getElementById('encoded').style.display = 'none';
                  document.getElementById('decoded').style.display = 'inline-block';
                  document.getElementById('decoded').innerText = \`${JSON.stringify(
                    JSON.parse(atob(data || "") || "[]"),
                    undefined,
                    2
                  )}\`;
                }
              })
            }
          </script>
        </body>`);
        logexporter.document.close();
        logexporter.focus();
      }
    }
  }
  // JSON.parse(atob('.....')) -- to parse the content
});

// On bctap reload or close - close all child log exporter window
window.onunload = function () {
  if (logexporter && !logexporter.closed) {
    logexporter.close();
  }
};
