import React, { ReactElement, useState } from "react";
import { Image } from "semantic-ui-react";
import Viewer from "react-viewer";

interface Props {
  data: {
    url: string;
    name: string;
  }[];
  label: string;
  data_name?: string;
}

function ImageComponent({ data, label, data_name }: Props): ReactElement {
  const [visibleIndex, setVisibleIndex] = useState(-1);

  const ListItems = (): any => {
    let knownViewerImages: { src: string; alt: string }[] = [];

    if (Array.isArray(data) || typeof data === "object") {
      if (Object.keys(data).length <= 0) {
        return "-";
      }

      let rendered = Object.keys(data).map((key, index) => {
        let item = data[key];
        if (item === null || item === undefined) return null;
        if (item["url"] === undefined) return null;
        let extension = item["url"].split(".").pop();

        let meta_type = item["_type"] ?? null;

        if (
          meta_type === "photo" ||
          meta_type === "signature" ||
          extension === "png" ||
          extension === "jpeg" ||
          extension === "jpg" ||
          extension === "webp" ||
          extension === "svg" ||
          extension === ""
        ) {
          knownViewerImages.push({
            src: item["url"],
            alt: item["name"] ?? data_name,
          });

          let isSignature =
            meta_type === "signature" || data_name?.endsWith("signature");

          return (
            <React.Fragment key={index}>
              <Image
                onClick={() => {
                  setVisibleIndex(index);
                }}
                src={item["url"]}
                size={isSignature ? "medium" : "tiny"}
                style={{
                  height: "80px",
                  width: "auto",
                }}
              />
            </React.Fragment>
          );
        } else if (
          meta_type === "video" ||
          extension === "mp4" ||
          extension === "ogg" ||
          extension === "webm"
        ) {
          return (
            <React.Fragment key={index}>
              <video
                style={{
                  aspectRatio: "10/6",
                  height: "150px",
                  border: "2px dashed #32323222",
                }}
                controls
              >
                <source src={item["url"]} type="video/mp4" />
                <a href={item["url"]} target="_blank" rel="noopener noreferrer">
                  Open Video
                </a>
              </video>
            </React.Fragment>
          );
        } else if (
          meta_type === "audio" ||
          extension === "mp3" ||
          extension === "m4a" ||
          extension === "ogg"
        ) {
          return (
            <React.Fragment key={index}>
              <audio
                style={{
                  border: "2px dashed #32323222",
                  borderRadius: "50px",
                }}
                controls
              >
                <source src={item["url"]} type="audio/mpeg" />
                <a href={item["url"]} target="_blank" rel="noopener noreferrer">
                  Open Audio URL
                </a>
              </audio>
            </React.Fragment>
          );
        } else {
          return (
            <a href={item["url"]} target="_blank" rel="noopener noreferrer">
              View
            </a>
          );
        }
      });

      return (
        <div>
          <div className="flex flex-wrap gap-3">{rendered}</div>

          <Viewer
            visible={visibleIndex >= 0}
            onClose={() => {
              setVisibleIndex(-1);
            }}
            images={knownViewerImages}
            downloadable={true}
            onMaskClick={() => {
              setVisibleIndex(-1);
            }}
            activeIndex={visibleIndex >= 0 ? visibleIndex : 0}
          />
        </div>
      );
    } else {
      return "-";
    }
  };

  return (
    <div className="rows" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        <ListItems />
      </div>
    </div>
  );
}

export default ImageComponent;
