import { socialAnalytics } from "../action/socialAnalyticsAction";

const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
      case socialAnalytics.SOCIAL_ANALYTICS_LOADED:
        return action.payload;

      case socialAnalytics.SOCIAL_ANALYTICS_UNLOADED:
        return initialState;
      case socialAnalytics.ADD_SOCIAL_ANALYTICS:
        return { ...state };
      case socialAnalytics.EDIT_SOCIAL_ANALYTICS:
        return state;
      case socialAnalytics.DELETE_SOCIAL_ANALYTICS:
        return state;

      default:
        return state;
    }
};
