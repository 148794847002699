import { apiService } from '../../services';
import { alertActions } from './alertAction';
export const formdataTypes = {
    ADD_FORMDATA: 'ADD_FORMDATA',
    DELETE_FORMDATA: "DELETE_FORMDATA",
    FETCH_FORMDATA: "FETCH_FORMDATA",
    FORMDATA_PAGE_UNLOADED: "FORMDATA_PAGE_UNLOADED",
    CLEAR_FORMDATA:"CLEAR_FORMDATA"
}



export const fetchFormdata = (link:string) => {

    return async (dispatch:any) => {

        apiService.getAll(link)
            .then(
                data => {

                    dispatch({
                        type: formdataTypes.FETCH_FORMDATA,
                        payload: data
                    })

                },
                error => {

                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}

export const unloadFormdataPage = () => {
    return {
        type: formdataTypes.FORMDATA_PAGE_UNLOADED,
        payload: {}

    };
}

export const addFormdata = (slug:string, payload:object) => {

    return async (dispatch:any) => {

        apiService.save(`/formBuilders/${slug}/submission`, payload)
            .then(
                data => {

                    dispatch({
                        type: formdataTypes.ADD_FORMDATA,
                        payload: data
                    })
                    dispatch(alertActions.success('Data Saved successfully'))

                },
                error => {

                    dispatch(alertActions.error(error.toString()));
                }
            );

    }
}
export const removeFormdata = (url:string) => {

    return async (dispatch:any) => {

        apiService._delete(url)
            .then(
                data => {

                    dispatch({
                        type: formdataTypes.DELETE_FORMDATA,
                    })
                    dispatch(alertActions.success('Data Deleted successfully'))

                },
                error => {

                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}
export const unloadFormData = () => {
    return {
        type: formdataTypes.CLEAR_FORMDATA,
        payload: {}

    };
}