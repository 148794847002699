import { boqReportTypes } from "../action/boqReportAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case boqReportTypes.BOQ_REPORT_LOADED:
      return action.payload;
   
    default:
      return state;
  }
};
