import React, { useState, useEffect } from "react";
import {
  Image,
  Pagination,
  Icon,
  Button,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  List,
  Divider,
  Input,
  Modal,
  Popup,
  DropdownProps,
  Dropdown,
  Label,
  Card,
  TextArea,
  Checkbox,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadProjectPage,
  unloadProjectPage,
  addProject,
  editProject,
  removeProject,
} from "../../redux/action/projectAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
import defaultImg from "../../assets/images/image.png";
type indProjectType = {
  name: string;
  code: string | null;
  detail: string | null;
  id: number;
  organization_id: number;
  order: number;
  image: string;
  fulcrum_id?: string;
  is_rhiab?: boolean;
  users: {
    id: number;
  }[];
  organization: {
    id: number;
    name: string;
  };
  options: {
    create_fulcrum_form: boolean;
    fulcrum_form_language: string;
    fulcrum_form_country: string;
    fulcrum_form_name: string;
    fulcrum_form_description: string;
    project_context: string;
    structural_system?: string;
    intervention_type?: string;
    design_orientation: string;
    risks: string;
    fulcrum_form_is_geometry_assessment_needed: boolean;
    is_rhiab: boolean;
    finance_project_budget: number;
    finance_house_budget: number;
    finance_type_of_fund: string;
    finance_tranches: {
      tranche_name: string;
      tranche_amount: number;
      tranche_description?: string;
      tranche_order?: number;
    }[];
    ignore_enrollment_and_screening?: boolean | 0 | 1;

    create_project_context?: boolean;
    project_context_form_name?: string;
    project_context_form_description?: string;

    status_field?: string;
    hide_empty_components?: boolean;
    disable_step_forms?: boolean;
  } | null;
};

interface RootState {
  projects: {
    project: {
      data: Array<indProjectType>;
      last_page: number;
      per_page: number;
    };
    organizations: {
      id: number;
      name: string;
    }[];
    users: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  };
}

interface DataType {
  newData: {
    id: number;
    name: string;
    code: string | null;
    description: string;
    organization: number;
    order: number;
    image: string;
    projectmanager: number[];
    create_fulcrum_form: boolean | 0 | 1;
    is_rhiab: boolean | 0 | 1;
    fulcrum_token?: string;
    fulcrum_form_name?: string;
    fulcrum_form_description?: string;
    fulcrum_form_language?: string;
    fulcrum_form_country?: string;
    project_context?: string;
    structural_system?: string;
    intervention_type?: string;
    design_orientation?: string;
    fulcrum_form_is_geometry_assessment_needed?: boolean | 0 | 1;
    risks: string[];

    finance_project_budget?: number;
    finance_house_budget?: number;
    finance_type_of_fund?: string;
    finance_tranches?: {
      tranche_name: string;
      tranche_amount: number;
      tranche_description?: string;
      tranche_order?: number; // basically index.. stored in DB tranche table..
    }[];

    ignore_enrollment_and_screening?: boolean | 0 | 1;

    create_project_context?: boolean;
    project_context_form_name?: string;
    project_context_form_description?: string;

    status_field?: string;
    hide_empty_components?: boolean;
    disable_step_forms?: boolean;
  };
}

type OptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

interface RenderPropsType {
  data: {
    project: {
      data: indProjectType[];
    };
  };
}

export default function Project() {
  const ELIGIBLE_COUNTRIES_CHOICE_LIST_ID =
    "6624fc51-b62a-4030-8141-9ee695b58423";

  const STRUCTURAL_SYSTEM_CHOICE_LIST_ID =
    "9e6fc87f-af07-49f2-a1ce-7d303cb31a01";

  const [fdata, setData] = useState<DataType>({
    newData: {
      id: 0,
      name: "",
      code: "",
      description: "",
      image: "",
      organization: 0,
      order: 0,
      projectmanager: [],
      is_rhiab: 1,
      create_fulcrum_form: 0,
      fulcrum_form_language: "english",
      risks: [],
    },
  });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteAsRhiab, setDeleteAsRhiab] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [apiUrl, setApiUrl] = useState("/project");
  const projects = useSelector((state: RootState) => state.projects);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [organizationOptions, setOrganizationOptions] = useState<OptionType>(
    []
  );
  const [userOptions, setUserOptions] = useState<OptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const [lastFulcrumTokenUsed, setLastFulcrumTokenUsed] = useState("");

  const [eligibleCountries, setEligibleCountries] = useState<
    { label: string; value: string }[]
  >([]);

  const [structuralSystems, setStructuralSystems] = useState<
    { label: string; value: string }[]
  >([]);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      loadProjectPage(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadProjectPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (projects.project !== undefined) {
      setTotal(projects.project.last_page);
      setPerPage(projects.project.per_page || 5);

      var final_org = projects.organizations.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });
      var final_user = projects.users.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: `${data.first_name} ${data.last_name}`,
        };
      });
      setOrganizationOptions(final_org);
      setUserOptions(final_user);
    }
  }, [projects]);

  React.useEffect(() => {
    const fetchFulcrumChoiceLists = setTimeout(() => {
      if (
        fdata.newData.fulcrum_token &&
        fdata.newData.fulcrum_token.length > 10 &&
        fdata.newData.fulcrum_token !== lastFulcrumTokenUsed
      ) {
        setLastFulcrumTokenUsed(fdata.newData.fulcrum_token);

        // >>>1. fetch eligible countries...
        fetch(
          `https://api.fulcrumapp.com/api/v2/choice_lists/${ELIGIBLE_COUNTRIES_CHOICE_LIST_ID}.json`,
          {
            headers: {
              "X-ApiToken": fdata.newData.fulcrum_token,
            },
          }
        )
          .then((res) => res.json())
          .then((res) => setEligibleCountries(res.choice_list.choices)) // {label: "", value: ""}[]
          .catch((err) => console.log(err));

        // >>>2. fetch structural system choice options...
        fetch(
          `https://api.fulcrumapp.com/api/v2/choice_lists/${STRUCTURAL_SYSTEM_CHOICE_LIST_ID}.json`,
          {
            headers: {
              "X-ApiToken": fdata.newData.fulcrum_token,
            },
          }
        )
          .then((res) => res.json())
          .then((res) => setStructuralSystems(res.choice_list.choices)) // {label: "", value: ""}[]
          .catch((err) => console.log(err));
      }
    }, 1000);

    return () => clearTimeout(fetchFulcrumChoiceLists);
  }, [fdata]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/project?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        "&per_page=" +
        perPage
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/project?q=" + search + "&per_page=" + perPage);
    }
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage((data.value as number) || 5);

    setApiUrl(
      "/project?page=" +
        activePage +
        "&per_page=" +
        (data.value || 5) +
        "&q=" +
        search
    );
  };

  const clearForm = () => {
    setCreating(true);
    setData({
      newData: {
        id: 0,
        name: "",
        code: "",
        description: "",
        image: "",
        organization: 0,
        projectmanager: [],
        order: 0,
        create_fulcrum_form: 0,
        is_rhiab: 1,
        fulcrum_token: "",
        fulcrum_form_name: "",
        fulcrum_form_description: "",
        fulcrum_form_language: "english",
        project_context: "",
        design_orientation: "",
        fulcrum_form_is_geometry_assessment_needed: 0,
        risks: [],
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let project = projects.project.data.find((v) => v.id === id);

    let form_data = {
      id: project!.id,
      name: project!.name,
      code: project!.code ?? "",
      description: project!.detail ?? "",
      image: "",
      organization: project!.organization_id,
      order: project!.order,
      projectmanager: project!.users.map((data) => {
        return data.id;
      }),
      create_fulcrum_form: 0,
      is_rhiab: 0,
      risks: [] as string[],
    };

    // Read-only Options...
    if (project && project.options) {
      form_data["create_fulcrum_form"] = project.options.create_fulcrum_form
        ? 1
        : 0;
      form_data["fulcrum_form_language"] =
        project.options.fulcrum_form_language ?? "english";
      form_data["fulcrum_form_name"] = project.options.fulcrum_form_name ?? "";
      form_data["fulcrum_form_description"] =
        project.options.fulcrum_form_description ?? "";
      form_data["project_context"] = project.options.project_context ?? "";
      form_data["design_orientation"] =
        project.options.design_orientation ?? "";
      form_data["risks"] = JSON.parse(project.options.risks ?? "[]");
      form_data["fulcrum_form_is_geometry_assessment_needed"] = project.options
        .fulcrum_form_is_geometry_assessment_needed
        ? 1
        : 0;
      form_data["is_rhiab"] = project.options.is_rhiab ? 1 : 0;
      form_data["fulcrum_token"] = "•••••••••";

      form_data["finance_project_budget"] =
        project.options.finance_project_budget ?? 0;
      form_data["finance_house_budget"] =
        project.options.finance_house_budget ?? 0;
      form_data["finance_type_of_fund"] =
        project.options.finance_type_of_fund ?? null;
      form_data["finance_tranches"] = project.options.finance_tranches ?? [];
      form_data["fulcrum_form_country"] =
        project.options.fulcrum_form_country ?? null;
      form_data["structural_system"] =
        project.options.structural_system ?? null;
      form_data["intervention_type"] =
        project.options.intervention_type ?? null;

      form_data["ignore_enrollment_and_screening"] =
        project.options.ignore_enrollment_and_screening ?? false;

      form_data["create_project_context"] =
        project.options.create_project_context ?? false;
      form_data["project_context_form_name"] =
        project.options.project_context_form_name ?? "";
      form_data["project_context_form_description"] =
        project.options.project_context_form_description ?? "";

      form_data["status_field"] = project.options.status_field ?? "";
      form_data["hide_empty_components"] = project.options.hide_empty_components
        ? 1
        : 0;
      form_data["disable_step_forms"] = project.options.disable_step_forms
        ? 1
        : 0;

      // Used to show selected value in Dropdown Options (which is or else empty)
      setStructuralSystems([
        {
          label: project.options.structural_system ?? "",
          value: project.options.structural_system ?? "",
        },
      ]);
      setEligibleCountries([
        {
          label: project.options.fulcrum_form_country ?? "",
          value: project.options.fulcrum_form_country ?? "",
        },
      ]);
    }

    setData({
      // @ts-ignore
      newData: form_data,
    });
  };

  const handleChange = (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;
    if (result.type === "file") {
      value = event.target.files[0];
    }

    if (name === "create_fulcrum_form") {
      value = value ? 1 : 0;
    } else if (name === "is_rhiab") {
      value = value ? 1 : 0;
    } else if (name === "fulcrum_form_is_geometry_assessment_needed") {
      value = value ? 1 : 0;
    } else if (name === "create_project_context") {
      value = value ? 1 : 0;
    } else if (name === "hide_empty_components") {
      value = value ? 1 : 0;
    } else if (name === "disable_step_forms") {
      value = value ? 1 : 0;
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const updateTranches = (index: number, key: string, value: any) => {
    let tranchesClone = [...(fdata?.newData?.finance_tranches ?? [])].map(
      (val, i) => {
        if (index === i) {
          val[key] = value;
        }

        return val;
      }
    );

    setData({
      newData: Object.assign({}, fdata.newData, {
        finance_tranches: tranchesClone,
      }),
    });
  };

  const addNewTranche = () => {
    setData({
      newData: Object.assign({}, fdata.newData, {
        finance_tranches: [
          ...(fdata?.newData?.finance_tranches ?? []),
          {
            tranche_name: `Tranche ${
              (fdata?.newData?.finance_tranches ?? []).filter(
                (t) => t.tranche_order !== 0
              ).length + 1
            }`,
            tranche_amount: 0,
          },
        ],
      }),
    });
  };

  const addZerothTranche = () => {
    setData({
      newData: Object.assign({}, fdata.newData, {
        finance_tranches: [
          {
            tranche_name: `Pre-Construction`,
            tranche_amount: 0,
            tranche_order: 0,
          },
          ...(fdata?.newData?.finance_tranches ?? []),
        ],
      }),
    });
  };

  const deleteTrancheByIndex = (index: number) => {
    let tranchesClone = [...(fdata?.newData?.finance_tranches ?? [])];
    tranchesClone.splice(index, 1);
    setData({
      newData: Object.assign({}, fdata.newData, {
        finance_tranches: tranchesClone,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const payload = fdata.newData;

    if (payload.id === 0)
      dispatch(
        addProject(
          payload,
          (data: any) => {
            dispatch(
              loadProjectPage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data added successfully"));

                if (data && data.created_fulcrum_id) {
                  dispatch(
                    alertActions.success(
                      "Fulcrum Form Created with ID: " + data.created_fulcrum_id
                    )
                  );
                }
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        editProject(
          payload,
          (data: any) => {
            dispatch(
              loadProjectPage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data Edited successfully"));

                if (data && data.created_fulcrum_id) {
                  dispatch(
                    alertActions.success(
                      "New Fulcrum Form Created with ID: " +
                        data.created_fulcrum_id
                    )
                  );
                }
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number, asRhiab: boolean = false) => {
    setOpen(true);
    setDeleteId(id);
    setDeleteAsRhiab(asRhiab);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      removeProject(
        deleteId,
        () => {
          dispatch(
            loadProjectPage(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
          setDeleteAsRhiab(false);
        },
        () => setLoading(false),
        deleteAsRhiab
      )
    );
    setOpen(false);
    setDeleteAsRhiab(false);
  };

  // const OpenModal = () => {
  //   return (

  //   );
  // };

  function RenderList(datas: RenderPropsType): any {
    if (datas.data.project === undefined) {
      return <Message>Loading ....</Message>;
    }

    if (datas.data.project.data.length === 0) {
      return <Message>No data Found</Message>;
    }

    return datas.data.project.data.map((data) => {
      return (
        <List divided verticalAlign="middle" key={data.id}>
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              <Button
                circular
                className="editButton"
                as={Link}
                to={`/project/projectReport/` + data.id}
                icon
              >
                <Icon name="eye" />
              </Button>

              <Button
                circular
                className="editButton"
                onClick={() => {
                  selectDataForEditing(data.id);
                  setOpenInfo(true);
                  setCreating(false);
                }}
                icon
              >
                <Icon name="pencil" />
              </Button>

              <Button
                circular
                className="deleteButton"
                onClick={() => deleteData(data.id)}
                icon
              >
                <Icon name="delete" />
              </Button>

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                    }}
                    circular
                    as={Link}
                    to={"/formbuilder?project_id=" + data.id}
                    icon={"book"}
                  ></Button>
                }
                content={
                  "Filter & Show Form Builder by this Project: " + data.name
                }
                position="top right"
                size="tiny"
              />

              {data.is_rhiab === true ? (
                <>
                  <br />
                  <br />
                  <Button
                    circular
                    className="deleteButton"
                    onClick={() => deleteData(data.id, true)}
                    style={{ fontSize: "85%" }}
                  >
                    <Icon name="delete" /> Delete RHIAB Project
                  </Button>
                </>
              ) : (
                ""
              )}
            </List.Content>

            <Image
              size="tiny"
              rounded
              bordered
              verticalAlign="top"
              src={data.image || defaultImg}
              style={{ minHeight: "80px" }}
              onError={(e: any) => (e.target.src = defaultImg)}
            />

            <List.Content
              as={Link}
              style={{ textDecoration: "none" }}
              to={`/project/projectReport/` + data.id}
            >
              <List.Header
                style={{ fontSize: "125%", marginBottom: "5px" }}
                className="text-uppercase"
              >
                {data.name}
              </List.Header>

              <List.Description style={{ fontSize: "80%" }}>
                {data.code ? data.code : ""}
              </List.Description>

              <List.Description
                style={{ fontSize: "80%", marginBottom: "5px" }}
              >
                Order: {data.order}
              </List.Description>

              <List.Description style={{ marginBottom: "5px" }}>
                <b>{data.organization ? data.organization.name : ""}</b>
              </List.Description>

              {data.is_rhiab === true ? (
                <Label tag color="blue" size="mini">
                  RHIAB
                </Label>
              ) : (
                ""
              )}
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
  }

  return (
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Project</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
        task={
          deleteAsRhiab === true
            ? "Permanently Delete Project and Related"
            : "Permanently Delete"
        }
        strict_text={
          deleteAsRhiab === true
            ? "This will permanently delete the project along with all RHIAB stages, phases, steps, finance/tranche, transactions, forms, records, homeowners etc. Similarly, related Fulcrum form and project will be also be deleted."
            : ""
        }
      />
      <div className="app-card-org" key="2">
        <Button
          className="createButton"
          icon
          labelPosition="left"
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
        >
          Create Project
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>
          Project List{" "}
          <small
            style={{
              display: "inline-block",
              fontSize: "x-small",
              fontStyle: "italic",
            }}
          >
            (Sorted as per the order assigned to the projects)
          </small>{" "}
          <Input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={onSearch}
            className="pull-right small"
            action={{ icon: "search" }}
            placeholder="Search..."
          />
        </Header>

        {projects && <RenderList data={projects} />}

        {projects.project === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column>
            <div style={{ marginRight: "5px", display: "inline-block" }}>
              <p style={{ display: "inline-block" }}>Per Page: </p>
              <Dropdown
                options={[
                  { key: 5, text: "5", value: 5 },
                  { key: 10, text: "10", value: 10 },
                  { key: 15, text: "15", value: 15 },
                  { key: 25, text: "25", value: 25 },
                  { key: 50, text: "50", value: 50 },
                  { key: 100, text: "100", value: 100 },
                ]}
                placeholder="Per Page"
                compact
                selection
                upward
                onChange={onPerPageChange}
                style={{ marginLeft: "5px" }}
                value={perPage}
              />
            </div>

            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>
      ,
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        style={{ minWidth: "80vw" }}
        className="modal-customized"
        closeOnDimmerClick={false}
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Project
        </Modal.Header>
        <Modal.Content>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Project Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Project Name..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                type="text"
                name="code"
                label="Project Code"
                value={fdata.newData.code}
                onChange={handleChange}
                placeholder="Enter Project Code..."
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Organization"
                required
                options={organizationOptions}
                label="Project Organization"
                value={fdata.newData.organization}
                name="organization"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select ProjectManagers"
                required
                search
                clearable
                multiple
                selection
                options={userOptions}
                label="Project Managers"
                value={fdata.newData.projectmanager}
                name="projectmanager"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <label>Project Order</label>
              <Form.Input
                fluid
                type="number"
                name="order"
                value={fdata.newData.order}
                onChange={handleChange}
                placeholder="Enter Order..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="image"
                label="Upload Project Logo (square shaped)"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Form.TextArea
              fluid
              name="description"
              value={fdata.newData.description}
              label="Project Description"
              onChange={handleChange}
            />
          </Form.Field>

          <div
            style={{
              cursor: !creating ? "not-allowed" : "initial",
            }}
          >
            <Form.Group widths={2}>
              <Form.Field disabled={!creating}>
                <Form.Checkbox
                  style={{ marginTop: "10px" }}
                  fluid
                  name="create_fulcrum_form"
                  label="Fulcrum Form and Project Auto-Creation"
                  checked={fdata.newData.create_fulcrum_form ? true : false}
                  onChange={handleChange}
                />
              </Form.Field>
            </Form.Group>

            {fdata.newData.create_fulcrum_form === 1 ? (
              <>
                <Divider />

                <Form.Group widths={2}>
                  <Form.Field disabled={!creating}>
                    <Form.Checkbox
                      style={{ marginTop: "10px" }}
                      fluid
                      name="is_rhiab"
                      label="RHIAB Phases and Steps Auto-Generation"
                      checked={fdata.newData.is_rhiab ? true : false}
                      onChange={handleChange}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Input
                  disabled={!creating}
                  fluid
                  required
                  type="text"
                  name="fulcrum_token"
                  label="Fulcrum Token"
                  value={fdata.newData.fulcrum_token}
                  onChange={handleChange}
                  placeholder="Fulcrum API Token"
                />

                <Form.Group widths={2}>
                  <Form.Field>
                    <Form.Select
                      disabled={!creating}
                      fluid
                      required
                      type="text"
                      name="fulcrum_form_language"
                      label="Fulcrum Form Language"
                      value={fdata.newData.fulcrum_form_language}
                      onChange={handleChange}
                      placeholder="Select Language"
                      options={[
                        { text: "English", value: "english" },
                        { text: "Spanish (Español)", value: "spanish" },
                        { text: "French", value: "french" },
                      ]}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Select
                      disabled={!creating}
                      fluid
                      required
                      type="text"
                      name="fulcrum_form_country"
                      label="Project Country"
                      value={fdata.newData.fulcrum_form_country}
                      onChange={handleChange}
                      placeholder="Select Country"
                      options={eligibleCountries.map((c) => ({
                        text: c.label,
                        value: c.value,
                      }))}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group widths={2}>
                  <Form.Field>
                    <Form.Input
                      disabled={!creating}
                      fluid
                      required
                      type="text"
                      name="fulcrum_form_name"
                      label="Fulcrum Form Name"
                      value={fdata.newData.fulcrum_form_name}
                      onChange={handleChange}
                      placeholder="Form Name..."
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      disabled={!creating}
                      fluid
                      required
                      type="text"
                      name="fulcrum_form_description"
                      label="Fulcrum Form Description"
                      value={fdata.newData.fulcrum_form_description}
                      onChange={handleChange}
                      placeholder="Form Description..."
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field>
                  <p
                    style={{
                      margin: "0 15px 0 0",
                      display: "inline-block",
                      width: "290px",
                      opacity: creating ? 1 : 0.3,
                    }}
                  >
                    What is the project context?{" "}
                    <span className="manual-required-asterisk">*</span>
                  </p>

                  <Button
                    type="button"
                    disabled={!creating}
                    primary={fdata.newData.project_context === "postdisaster"}
                    basic={fdata.newData.project_context !== "postdisaster"}
                    onClick={() =>
                      handleChange(null, {
                        name: "project_context",
                        value: "postdisaster",
                      })
                    }
                  >
                    {fdata.newData.project_context === "postdisaster" ? (
                      <Icon name="check circle outline" />
                    ) : (
                      <Icon name="circle outline" />
                    )}
                    POST-DISASTER
                  </Button>

                  <Button
                    type="button"
                    disabled={!creating}
                    primary={fdata.newData.project_context === "prevention"}
                    basic={fdata.newData.project_context !== "prevention"}
                    onClick={() =>
                      handleChange(null, {
                        name: "project_context",
                        value: "prevention",
                      })
                    }
                  >
                    {fdata.newData.project_context === "prevention" ? (
                      <Icon name="check circle outline" />
                    ) : (
                      <Icon name="circle outline" />
                    )}
                    PREVENTION
                  </Button>
                </Form.Field>

                <Form.Group widths={2}>
                  <Form.Field>
                    <Form.Select
                      disabled={!creating}
                      fluid
                      required
                      type="text"
                      name="structural_system"
                      label="Structural System"
                      value={fdata.newData.structural_system}
                      onChange={handleChange}
                      placeholder="Select Structural System"
                      options={structuralSystems.map((c) => ({
                        text: c.label,
                        value: c.value,
                      }))}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Select
                      disabled={!creating}
                      fluid
                      required
                      type="text"
                      name="intervention_type"
                      label="Intervention Type"
                      value={fdata.newData.intervention_type}
                      onChange={handleChange}
                      placeholder="Select Intervention Type"
                      options={[
                        { text: "Complete Retrofit", value: "retrofit" },
                        { text: "Retrofit Lite", value: "retrofit_lite" },
                        { text: "New Construction", value: "new" },
                      ]}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        margin: "0 15px 0 0",
                        display: "inline-block",
                        width: "290px",
                        opacity: creating ? 1 : 0.3,
                      }}
                    >
                      What kind of hazards will be considered?
                    </p>

                    <Dropdown
                      disabled={!creating}
                      style={{ minWidth: "364px" }}
                      placeholder="Choose multiple options..."
                      multiple
                      search
                      selection
                      name="risks"
                      options={[
                        { key: "seismic", value: "seismic", text: "Seismic" },
                        { key: "wind", value: "wind", text: "Wind" },
                        {
                          key: "flooding",
                          value: "flooding",
                          text: "Flooding",
                        },
                        { key: "fire", value: "fire", text: "Fire" },
                        { key: "soil", value: "soil", text: "Soil" },
                      ]}
                      value={fdata.newData.risks}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Field>

                <Form.Field>
                  <p
                    style={{
                      margin: "0 15px 0 0",
                      display: "inline-block",
                      width: "290px",
                      opacity: creating ? 1 : 0.3,
                    }}
                  >
                    What kind of design will be performed?{" "}
                    <span className="manual-required-asterisk">*</span>
                  </p>

                  <Button
                    type="button"
                    disabled={!creating}
                    primary={fdata.newData.design_orientation === "on_field"}
                    basic={fdata.newData.design_orientation !== "on_field"}
                    onClick={() =>
                      handleChange(null, {
                        name: "design_orientation",
                        value: "on_field",
                      })
                    }
                  >
                    {fdata.newData.design_orientation === "on_field" ? (
                      <Icon name="check circle outline" />
                    ) : (
                      <Icon name="circle outline" />
                    )}
                    ON-THE-FIELD DESIGN
                  </Button>

                  <Button
                    type="button"
                    disabled={!creating}
                    primary={fdata.newData.design_orientation === "on_office"}
                    basic={fdata.newData.design_orientation !== "on_office"}
                    onClick={() =>
                      handleChange(null, {
                        name: "design_orientation",
                        value: "on_office",
                      })
                    }
                  >
                    {fdata.newData.design_orientation === "on_office" ? (
                      <Icon name="check circle outline" />
                    ) : (
                      <Icon name="circle outline" />
                    )}
                    IN-THE-OFFICE DESIGN
                  </Button>
                </Form.Field>

                <Form.Field>
                  <p
                    style={{
                      margin: "0 15px 0 0",
                      display: "inline-block",
                      width: "290px",
                      opacity: creating ? 1 : 0.3,
                    }}
                  >
                    Does the house digital geometry assessment need to be
                    performed?{" "}
                    <span className="manual-required-asterisk">*</span>
                  </p>

                  <Button
                    type="button"
                    disabled={!creating}
                    primary={
                      !!fdata.newData.fulcrum_form_is_geometry_assessment_needed
                    }
                    basic={
                      !fdata.newData.fulcrum_form_is_geometry_assessment_needed
                    }
                    onClick={() =>
                      handleChange(null, {
                        name: "fulcrum_form_is_geometry_assessment_needed",
                        value: true,
                      })
                    }
                  >
                    {fdata.newData
                      .fulcrum_form_is_geometry_assessment_needed ? (
                      <Icon name="check circle outline" />
                    ) : (
                      <Icon name="circle outline" />
                    )}
                    YES
                  </Button>

                  <Button
                    type="button"
                    disabled={!creating}
                    primary={
                      !fdata.newData.fulcrum_form_is_geometry_assessment_needed
                    }
                    basic={
                      !!fdata.newData.fulcrum_form_is_geometry_assessment_needed
                    }
                    onClick={() =>
                      handleChange(null, {
                        name: "fulcrum_form_is_geometry_assessment_needed",
                        value: false,
                      })
                    }
                  >
                    {fdata.newData
                      .fulcrum_form_is_geometry_assessment_needed ? (
                      <Icon name="circle outline" />
                    ) : (
                      <Icon name="check circle outline" />
                    )}
                    NO
                  </Button>
                </Form.Field>

                <Divider
                  horizontal
                  section
                  style={{ opacity: creating ? 1 : 0.5 }}
                >
                  <Header as="h4">
                    <Icon name="money bill alternate outline" />
                    Finance
                  </Header>
                </Divider>

                <Form.Group widths={3}>
                  <Form.Field>
                    <Form.Input
                      fluid
                      required
                      type="number"
                      name="finance_project_budget"
                      label="Total Project Budget"
                      value={fdata.newData.finance_project_budget}
                      onChange={handleChange}
                      placeholder="Enter Project Budget (local currency)"
                      disabled={!creating}
                      min={0}
                      max={Number.MAX_SAFE_INTEGER}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      fluid
                      required
                      type="number"
                      name="finance_house_budget"
                      label="House-Specific Budget"
                      value={fdata.newData.finance_house_budget}
                      onChange={handleChange}
                      placeholder="Enter House-Specific Budget (local currency)"
                      disabled={!creating}
                      min={0}
                      max={
                        fdata.newData.finance_project_budget ??
                        Number.MAX_SAFE_INTEGER
                      }
                    />
                  </Form.Field>

                  <Form.Field required>
                    <Form.Select
                      disabled={!creating}
                      fluid
                      required
                      name="finance_type_of_fund"
                      label="Fund Type"
                      value={fdata.newData.finance_type_of_fund}
                      onChange={handleChange}
                      placeholder="Choose a Fund Type"
                      options={[
                        { text: "Loan", value: "loan" },
                        { text: "Subsidy", value: "subsidy" },
                        { text: "Grant", value: "grant" },
                      ]}
                    />
                  </Form.Field>
                </Form.Group>

                <Divider horizontal style={{ opacity: creating ? 1 : 0.5 }}>
                  <Header as="h5">
                    <Icon name="send" />
                    Tranches
                  </Header>
                </Divider>

                {fdata.newData.finance_tranches &&
                fdata.newData.finance_tranches.length > 0 ? (
                  <Card fluid>
                    {fdata.newData.finance_tranches.map(
                      (tranche: any, index) => (
                        <Card.Content
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "stretch",
                            width: "100%",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              alignItems: "center",
                              flex: 1,
                            }}
                          >
                            <Input
                              type="text"
                              label="Tranche Name"
                              value={tranche.tranche_name}
                              style={{ flex: "1", marginRight: "10rem" }}
                              onChange={(e, data) => {
                                updateTranches(
                                  index,
                                  "tranche_name",
                                  data.value
                                );
                              }}
                              disabled={!creating}
                            ></Input>

                            <Input
                              type="number"
                              label="Tranche Amount"
                              value={tranche.tranche_amount}
                              style={{ flex: "1", marginRight: "10rem" }}
                              onChange={(e, data) => {
                                updateTranches(
                                  index,
                                  "tranche_amount",
                                  data.value
                                );
                              }}
                              disabled={!creating}
                            ></Input>
                          </div>

                          <Button
                            type="button"
                            color="red"
                            onClick={() => deleteTrancheByIndex(index)}
                            icon="trash alternate outline"
                            style={{
                              marginTop: "3px",
                            }}
                            size="tiny"
                            disabled={tranche.tranche_order === 0 || !creating}
                          />

                          <div style={{ width: "100%", marginTop: "10px" }}>
                            <TextArea
                              label="Tranche Description"
                              placeholder={`Description for ${
                                tranche.tranche_name || "tranche"
                              }`}
                              value={tranche.tranche_description}
                              style={{
                                flex: "1",
                                width: "100%",
                                opacity: creating ? 1 : 0.5,
                              }}
                              onChange={(e, data) => {
                                updateTranches(
                                  index,
                                  "tranche_description",
                                  data.value
                                );
                              }}
                              maxLength="255"
                              disabled={!creating}
                            ></TextArea>
                          </div>
                        </Card.Content>
                      )
                    )}
                  </Card>
                ) : (
                  ""
                )}

                {creating ? (
                  <>
                    <Card.Content>
                      <Button type="button" onClick={addNewTranche}>
                        <Icon name="plus" />
                        Add Tranche
                      </Button>
                    </Card.Content>

                    <Card.Content style={{ marginTop: "10px" }}>
                      <Checkbox
                        label="Include Pre-Construction Tranche (Tranche 0)"
                        onChange={(e, data) => {
                          if (data.checked) {
                            addZerothTranche();
                          } else {
                            deleteTrancheByIndex(0);
                          }
                        }}
                      />
                    </Card.Content>
                  </>
                ) : (
                  ""
                )}

                <Card.Content style={{ marginTop: "10px" }}>
                  <Form.Field disabled={!creating}>
                    <Form.Checkbox
                      fluid
                      name="ignore_enrollment_and_screening"
                      label='Do not include "Enrollment And Screening" (Stage 01)'
                      checked={
                        fdata.newData.ignore_enrollment_and_screening
                          ? true
                          : false
                      }
                      onChange={handleChange}
                    />
                  </Form.Field>
                </Card.Content>

                <Divider horizontal style={{ opacity: creating ? 1 : 0.5 }}>
                  <Header as="h5">
                    <Icon name="tree" />
                    Project Context
                  </Header>
                </Divider>

                <Card.Content style={{ marginTop: "10px" }}>
                  <Form.Field disabled={!creating}>
                    <Form.Checkbox
                      fluid
                      name="create_project_context"
                      label="Create Project Context"
                      checked={
                        fdata.newData.create_project_context ? true : false
                      }
                      onChange={handleChange}
                    />
                  </Form.Field>
                </Card.Content>

                {fdata.newData.create_project_context ? (
                  <>
                    <Form.Group style={{ marginTop: "6px" }} widths="equal">
                      <Form.Field required>
                        <Form.Input
                          fluid
                          required
                          name="project_context_form_name"
                          label="Project Context Form Name"
                          value={fdata.newData.project_context_form_name}
                          onChange={handleChange}
                          placeholder="Form Name..."
                        />
                      </Form.Field>

                      <Form.Field required>
                        <Form.Input
                          fluid
                          required
                          name="project_context_form_description"
                          label="Project Context Form Description"
                          value={fdata.newData.project_context_form_description}
                          onChange={handleChange}
                          placeholder="Form Description..."
                        />
                      </Form.Field>
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {/* Project specific Customizations */}
                <Divider horizontal>
                  <Header as="h5">
                    <Icon name="paint brush" />
                    Customization
                  </Header>
                </Divider>

                <Form.Field required>
                  <Form.Input
                    fluid
                    name="status_field"
                    label="Status Field Data Name"
                    value={fdata.newData.status_field}
                    onChange={handleChange}
                    placeholder="Leave blank to use the default status."
                  />
                </Form.Field>

                <Form.Group
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    justifyContent: "between",
                    alignItems: "end",
                  }}
                  widths="equal"
                >
                  <Form.Field>
                    <Form.Checkbox
                      fluid
                      name="hide_empty_components"
                      label="Hide Empty Components from Record Preview?"
                      checked={
                        fdata.newData.hide_empty_components ? true : false
                      }
                      onChange={handleChange}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Checkbox
                      fluid
                      name="disable_step_forms"
                      label="Disable Approval Buttons and Comment Fields? (02-C, 03-B, 04-B, 05-B)"
                      checked={fdata.newData.disable_step_forms ? true : false}
                      onChange={handleChange}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            ) : (
              ""
            )}
          </div>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            type="button"
            onClick={() => setOpenInfo(false)}
            color="red"
            icon="times"
          />

          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
