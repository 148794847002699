import React, { ReactElement } from "react";
import { CheckIndividual, RenderTableFormDetail } from "./FormDataDetail";
import { Accordion } from "semantic-ui-react";

interface Props {
  data: any[];
  label: string;
  field: any;
  components_to_hide?: string[];
}

function ContainerComponent({
  data,
  label,
  field,
  components_to_hide,
}: Props): ReactElement {
  const component_types_to_send_data_as_is = ["datagrid", "editgrid"]; // component will not receive child data and field-component. BUT Parent data as before.

  const Title = (
    <div className="title-secondary">
      <h5>{label}</h5>
    </div>
  );

  const ListData = (
    <div>
      {Object.keys(data).map((key, index) => {
        // console.log(components_to_hide, key);
        let comp = field.find((word: { key: string }) => word.key === key);
        if (comp === undefined) return null;
        if (data[key] == undefined) return null;
        if (Array.isArray(data[key])) {
          if (data[key].length === 0) return null;
        }

        const Title2 = (
          <div className="title-tertiary">
            <h5>{comp["label"] || ""}</h5>
          </div>
        );

        const ListData2 = (
          <RenderTableFormDetail
            data={
              component_types_to_send_data_as_is.includes(comp["type"])
                ? { [key]: data[key] }
                : data[key]
            }
            field={
              component_types_to_send_data_as_is.includes(comp["type"])
                ? [comp]
                : comp["component"] ?? []
            }
            components_to_hide={components_to_hide}
          />
        );

        const panels2 = [
          {
            key: "panel-2",
            title: { content: Title2 },
            content: { content: ListData2 },
          },
        ];

        return (
          <React.Fragment key={index}>
            {comp["component"] !== undefined ? (
              <div style={{ marginLeft: "15px" }}>
                <Accordion defaultActiveIndex={0} panels={panels2} />
              </div>
            ) : (
              <CheckIndividual
                index={index}
                data={data[key]}
                resource={comp["resource"] || ""}
                type={comp["type"] || ""}
                label={comp["label"] || ""}
                data_name={key}
                components_to_hide={components_to_hide}
                options={comp["values"] || []}
              />
            )}{" "}
          </React.Fragment>
        );
      })}
    </div>
  );

  const panels = [
    {
      key: "panel-1",
      title: { content: Title },
      content: { content: ListData },
    },
  ];

  const ListItem = () => (
    <React.Fragment>
      <div
        style={{ marginLeft: "15px" }}
        className="transition-all border-l-2 border-dotted border-gray-50 hover:border-gray-300"
      >
        <Accordion defaultActiveIndex={0} panels={panels} />
      </div>
    </React.Fragment>
  );
  if (Array.isArray(data)) {
    if (data.length === 0) return <div></div>;
  }

  return (
    <div key={label}>
      <ListItem />
      <br />
    </div>
  );
}

export default ContainerComponent;
