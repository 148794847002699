import { lessonDataTypes } from "../action/lessonDataAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case lessonDataTypes.LESSON_DATA_LOADED:

            return action.payload;

        case lessonDataTypes.LESSON_DATA_UNLOADED:
            return initialState;
        case lessonDataTypes.ADD_LESSON_DATA:
           
             return { ...state};
        case lessonDataTypes.EDIT_LESSON_DATA:

            return state;
        case lessonDataTypes.DELETE_LESSON_DATA:
           
            return state;

        default:
            return state;
    }
};
