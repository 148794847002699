import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const categoryTypes = {
  CATEGORY_LOADED: "CATEGORY_LOADED",
  CATEGORY_UNLOADED: "CATEGORY_UNLOADED",
  ADD_CATEGORY: "ADD_CATEGORY",
  EDIT_CATEGORY: "EDIT_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
};

export const loadCategoryPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: categoryTypes.CATEGORY_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadCategoryPage = () => {
  return {
    type: categoryTypes.CATEGORY_UNLOADED,
    payload: {},
  };
};

export const addCategory = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/category", payload).then(
      (data) => {
        dispatch({
          type: categoryTypes.ADD_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editCategory = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/category/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: categoryTypes.EDIT_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeCategory = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/category/delete/${id}`).then(
      (data) => {
        dispatch({
          type: categoryTypes.DELETE_CATEGORY,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
