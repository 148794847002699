import React, { useEffect, useMemo, useRef, useState } from "react";
import { Segment, Button, Icon, Menu, Label } from "semantic-ui-react";

import { Formio } from "@formio/react";
import { alertActions } from "../../../redux/action/alertAction";
import { apiService } from "../../../services";
import { useDispatch } from "react-redux";
import ModalComponent from "../../../components/Modal";

import reviewIcon from "../../../assets/cisco/svg/review.svg";

interface Props {
  data: any;
  fetchApi: (is_step_form_recently_submitted?: boolean) => void;
}

export default function StepFormBuilder({ data, fetchApi }: Props) {
  const tranche_amount = new URLSearchParams(window.location?.search ?? "").get(
    "tranche_amount"
  );

  const finance_id = new URLSearchParams(window.location?.search ?? "").get(
    "finance_id"
  );

  const tranche_id = new URLSearchParams(window.location?.search ?? "").get(
    "tranche_id"
  );

  let formIoStepForm = useRef(null);

  const [open, setOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  const [isSyncingStatus, setIsSyncingStatus] = useState(false);
  const [isSyncingChoices, setIsSyncingChoices] = useState(false);
  const [isReturning, setIsReturning] = useState(false);

  const currentStep = useMemo(() => {
    return data?.steps?.find(
      (step: { access: boolean; active: boolean }) => step.access && step.active // only accessible and active step.
    );
  }, [data]);

  useEffect(() => {
    if (currentStep && formIoStepForm.current) {
      let component = JSON.parse(currentStep.field_component);

      if (typeof component === "string") {
        component = JSON.parse(component);
      }

      // Default Value tranche_amount
      if (component && component.components) {
        let current_tranche = getCurrentTranche();

        let tranche_instruction =
          current_tranche?.description ?? "Please fill the tranche details..";
        let initial_amount = current_tranche?.amount ?? 0;

        let current_tranche_amount =
          data.data?.current_tranche_amount ??
          tranche_amount ??
          initial_amount ??
          0;

        let cumulative_tranche_amount =
          data.data?.cumulative_tranche_amount ?? 0;

        component.components = component.components.map((c: any) => {
          if (c.key === "_tranche_amount") {
            return { ...c, defaultValue: current_tranche_amount };
          } else if (c.key === "_tranche_instruction") {
            return { ...c, defaultValue: tranche_instruction };
          } else if (c.key === "_tranche_initial_amount") {
            return { ...c, defaultValue: initial_amount };
          } else if (c.key === "_tranche_cumulative_amount") {
            return { ...c, defaultValue: cumulative_tranche_amount };
          }

          return c;
        });
      }

      // console.log(component);
      if (component != null) {
        RenderFormIo(component);
      } else {
        RenderFormIo({});
      }

      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [currentStep, formIoStepForm]);

  /**
   * Render Form.io Step Form using vanilla js package (not react specific form.io package)
   */
  const RenderFormIo = (component: object) => {
    if (!formIoStepForm.current) {
      console.warn("Form.io Ref Not Found.");
      return;
    }

    Formio.createForm(formIoStepForm.current, component, {
      noAlerts: true,
    }).then(function (form: {
      nosubmit: boolean;
      on: (arg0: string, arg1: (submission: any) => void) => void;
      emit: Function;
    }) {
      // Prevent the submission from going to the form.io server.
      form.nosubmit = true;

      // Triggered when they click the submit button.
      form.on("submit", function (submission) {
        // console.log(submission);

        apiService
          .save(
            `/step/${data.step.id}/submission/${data.data._id.$oid}${
              finance_id && tranche_id
                ? "?finance_id=" + finance_id + "&tranche_id=" + tranche_id
                : ""
            }`,
            submission
          )
          .then(
            (res_data) => {
              dispatch(alertActions.success("Data Submitted"));
              if (data.data.fulcrum_id) {
                syncStatusBackToFulcrum();
              } else if (
                data.step &&
                data.step.can_sync_fulcrum_choices === true
              ) {
                syncFulcrumChoicesAutomatically();
              } else {
                fetchApi(true);
              }
            },
            (error) => {
              dispatch(alertActions.error(error.toString()));
              form.emit("submitError", error.toString());
            }
          );
      });
    });
  };

  const returnToPrevious = () => {
    setOpen(true);
  };

  const confirmReturn = () => {
    setIsReturning(true);

    apiService
      .save(`/step/return`, {
        status: data.data.status,
        id: data.data._id.$oid,
        step_id: data.step.id,
      })
      .then(
        (data) => {
          dispatch(alertActions.success("Successfully Returned Back."));
          fetchApi(true);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      )
      .finally(() => {
        setIsReturning(false);
      });

    setOpen(false);
  };

  const getCurrentTranche = () => {
    if (data && data.finances) {
      let finance = data.finances.find((f: any) => String(f.id) === finance_id);

      if (finance) {
        return finance.tranches.find((t: any) => String(t.id) === tranche_id);
      }
    }

    return null;
  };

  const syncStatusBackToFulcrum = () => {
    setIsSyncingStatus(true);

    apiService
      .save(
        `/form/${data.form.slug}/record/${data.data._id.$oid}/sync-status-to-fulcrum`
      )
      .then((res) => {
        dispatch(alertActions.success(res.message));
      })
      .catch((err) => {
        dispatch(alertActions.error(err));
      })
      .finally(() => {
        setIsSyncingStatus(false);
        syncFulcrumChoicesAutomatically();
      });
  };

  const syncFulcrumChoicesAutomatically = () => {
    if (data.step && data.step.can_sync_fulcrum_choices) {
      setIsSyncingChoices(true);

      apiService
        .save1(`/step/${data.step.id}/fulcrum_choices/sync`, {})
        .then((res) => {
          dispatch(alertActions.success(res.message));
        })
        .catch((err) => {
          dispatch(alertActions.error(err));
        })
        .finally(() => {
          setIsSyncingChoices(false);
          fetchApi(true);
        });
    } else {
      fetchApi(true);
    }
  };

  return (
    <React.Fragment>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmReturn}
        task="Return"
      />

      {currentStep ? (
        <Segment>
          <div>
            <div className="ui borderless pointing secondary menu">
              <Menu.Item
                active
                key={currentStep.id}
                className="pt-0 pb-2 pl-0 pr-3"
              >
                <img src={reviewIcon} alt="" className="w-5 h-5" />
                {currentStep.name}
              </Menu.Item>
            </div>

            <div className="ui segment active tab">
              <div>
                {getCurrentTranche() ? (
                  <Label
                    circular
                    color="green"
                    style={{
                      marginBottom: "10px",
                      boxShadow: "2px 2px 6px #323232",
                    }}
                  >
                    <Icon name="info circle" />
                    {getCurrentTranche().name}
                  </Label>
                ) : (
                  ""
                )}

                <div>
                  <div
                    aria-label="Rendered Step Form"
                    ref={formIoStepForm}
                  ></div>
                </div>

                {currentStep.return ? (
                  <Button
                    size="small"
                    onClick={() => returnToPrevious()}
                    color="red"
                    className="m-0 step-return-btn"
                    loading={isReturning}
                    disabled={isReturning}
                  >
                    <Icon name="undo" />
                    Return
                  </Button>
                ) : (
                  ""
                )}

                {isSyncingStatus ? (
                  <h5 style={{ marginTop: "8px", color: "orange" }}>
                    {" "}
                    <Icon name="sync" /> Syncing Status Back to Fulcrum....
                  </h5>
                ) : (
                  ""
                )}

                {isSyncingChoices ? (
                  <h5 style={{ marginTop: "8px", color: "orange" }}>
                    {" "}
                    <Icon name="sync" /> Syncing Homeowner Fulcrum Choices....
                  </h5>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Segment>
      ) : (
        ""
      )}

      {/* {process.env.NODE_ENV !== "production" && data.data.fulcrum_id ? (
        <Button
          color="orange"
          size="small"
          loading={isSyncingStatus}
          disabled={isSyncingStatus}
          onClick={() => syncStatusBackToFulcrum()}
        >
          <Icon name="sync" />
          Sync Status Back to Fulcrum [DEBUG]
        </Button>
      ) : (
        ""
      )} */}
    </React.Fragment>
  );
}
