import { alertActions } from './alertAction';
import { apiService } from '../../services';
export const formTypes = {
   
    FORM_PAGE_LOADED: "FORM_PAGE_LOADED",
    FORM_PAGE_UNLOADED: "FORM_PAGE_UNLOADED",
    ADD_FORM:"ADD_FORM",
    EDIT_FORM:"EDIT_FORM",
    DELETE_FORM:"DELETE_FORM",


}

export const loadFormPage =  (url:string, onFinally: Function) => {
    
    return async (dispatch:any) =>{

        apiService.getAll(url)
        .then(
            data => { 
               
                dispatch({
                    type: formTypes.FORM_PAGE_LOADED,
                    payload: data
                })
                onFinally();
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
                onFinally();
            }
        );
        

    };
}



export const unloadFormPage = () => {
    return {
        type: formTypes.FORM_PAGE_UNLOADED,
        payload: {}

    };
}

export const addForm = (payload:object, onSuccess: Function, onError: Function) => {
    

    return async (dispatch:any) =>{

        apiService.save('/forms',payload)
        .then(
            data => { 
               
                dispatch({
                    type: formTypes.ADD_FORM,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data added successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };

   
}
interface payloadProps {
    id: number,
   // [all:any]:any
}
export const editForm = (payload:payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch:any) =>{

        apiService.update(`/forms/update/${payload.id}`,payload)
        .then(
            data => { 
               
                dispatch({
                    type: formTypes.EDIT_FORM,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data Edited successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
}



  export const removeForm= (id:number, onSuccess: Function, onError: Function) =>  {
   
    return async (dispatch:any) =>{

        apiService._delete(`/forms/delete/${id}`)
        .then(
            data => { 
               
                dispatch({
                    type: formTypes.DELETE_FORM,
                
                });
                onSuccess();
                // dispatch(alertActions.success('Data Deleted successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
  }

  

