import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
 
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Select,
  Loader,
  Input,
  Modal,
  Card,
  Popup,
  List,
  Divider,
  Image,
  DropdownProps,
  Dropdown,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadOrganizationPage,
  unloadOrganizationPage,
  addOrganization,
  editOrganization,
  removeOrganization,
} from "../../redux/action/organizationAction";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ModalComponent from "../../components/Modal";
import { apiService } from "../../services";
//import { fetchData } from "../../redux/action/allAction";
import { alertActions } from "../../redux/action/alertAction";
import { AppConfig } from "../../config";
//import { AnyMxRecord } from "dns";
import defaultImg from '../../assets/images/image.png';
interface fdataType {
  newData: {
    id: number;
    name: string;
    image: string;
    country: number;
    module: number[];
  };
}

interface RootState {
  organizations: {
    organization: {
      data: {
        name: string;
        country_id: number;
        image: string;
        id: number;
        modules: {
          id: number;
        }[];
      }[];
      last_page: number;
      per_page: number;
    };
    countries: {
      id: number;
      country_name: string;
    }[];
    modules: {
      id: number;
      name: string;
      slug: string;
    }[];
  };
}

type OptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function Organization() {
  const [fdata, setData] = useState<fdataType>({
    newData: {
      id: 0,
      name: "",
      image: "",
      country: 0,
      module: [],
    },
  });
  const [datastudio, setDataStudio] = useState("");
  const [project, setProject] = useState([]);
  const [openDataStudio, setOpenDataStudio] = useState<boolean>(false);
  const [openDataShareEnabled, setOpenDataShareEnabled] =
    useState<boolean>(false);
  const [openDataShareDisabled, setOpenDataShareDisabled] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [slug, setSlug] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [apiUrl, setApiUrl] = useState<string>("/organization");
  let organizations = useSelector((state: RootState) => state.organizations);
  const [total, setTotal] = useState<number>(1);
  const [countryOptions, setCountryOptions] = useState<OptionType>([]);
  const [moduleOptions, setModuleOptions] = useState<OptionType>([]);
  const [column, setColumn] = useState<string | null>(null);
  const [direction, setDirection] =
    useState<"ascending" | "descending" | undefined>(undefined);
  const [search, setSearch] = useState<string>("");
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadOrganizationPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadOrganizationPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (organizations.organization !== undefined) {
      setTotal(organizations.organization.last_page);
      setPerPage(organizations.organization.per_page || 5);
      let final_countries = organizations.countries.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.country_name,
        };
      });
      let final_modules = organizations.modules.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });
      setCountryOptions(final_countries);
      setModuleOptions(final_modules);
    }
  }, [organizations]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/organization?page=" + pageInfo.activePage.toString() + "&per_page=" + perPage);
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage(data.value as number || 5);

    setApiUrl(
      "/organization?page=" + activePage + "&per_page=" + (data.value || 5)
    );
  }

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        image: "",
        country: 0,
        module: [],
      },
    });
  };

  const enableDataShare = (slug: string) => {
    apiService.getAll(`/org/data_share/${slug}`).then(
      (data) => {
        // dispatch(fetchData(apiUrl));
        dispatch(loadOrganizationPage(apiUrl, () => { 
          setLoading(false);
        }));

        dispatch(alertActions.success(data.message));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      }
    );
  };

  const setDataShare = (data_share: boolean, slug: string, id: number) => {
    setSlug(slug);
    setProject([]);
    apiService.getAll(`/GetData/org_ind/${id}`).then(
      (data) => {
        if (data.project !== null) {
          setProject(data.project);
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
    if (data_share) {
      setOpenDataShareDisabled(false);
      setOpenDataShareEnabled(true);
    } else {
      setOpenDataShareDisabled(true);
      setOpenDataShareEnabled(false);
    }
  };

  const copyToClipboard = (url: string) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const handleSort = (clickedColumn: any) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection("ascending");
      organizations.organization.data.sort((a, b) => {
        return a[clickedColumn] - b[clickedColumn];
      });

      return;
    }

    organizations.organization.data.reverse();
    setDirection(direction === "ascending" ? "descending" : "ascending");
    // Todo: To Implement any sort of Network based sort then we need to change apiurl to add ?sort=name&page=pageInfo.activePage etc.
  };

  const selectDataForEditing = (id: number) => {
    let organization = organizations.organization.data.find((v) => v.id === id);

    setData({
      newData: {
        id: organization!.id,
        name: organization!.name,
        country: organization!.country_id,
        image: "",
        module: organization!.modules.map((data) => {
          return data.id;
        }),
      },
    });
  };
  const handleDataStudioChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    //let name = result.name;

    setDataStudio(value);
  };

  const DataStudioSave = () => {
    apiService
      .save(`/dataStudio/org/${slug}`, { data_studio: datastudio })
      .then(
        (data) => {
          // dispatch(fetchData(apiUrl));
          dispatch(loadOrganizationPage(apiUrl, () => { 
            setLoading(false);
          }));
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const handleChange = (event: any, result?: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    if (result.type === "file") {
      value = event.target.files[0];
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleDataStudio = (studio: string, slug: string) => {
    setOpenDataStudio(true);
    setSlug(slug);
    setDataStudio(studio);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addOrganization(fdata.newData, () => {
      dispatch(loadOrganizationPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editOrganization(fdata.newData, () => {
      dispatch(loadOrganizationPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeOrganization(deleteId, () => {
      dispatch(loadOrganizationPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  function RenderList(datas: any) {
    if (datas.data.organization === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (datas.data.organization.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.organization.data
      .filter((data: any) => {
        if (!search || search.length <= 0) {
          return true;
        }
        let pass = false;
        Object.keys(data).forEach((k) => {
          if (
            data[k] &&
            data[k].toString().toLowerCase().indexOf(search.toLowerCase()) >= 0
          ) {
            pass = true;
          }
        });
        return pass;
      })
      .map((data: any,index:number) => {
        return (
          <List divided verticalAlign="middle" key={index}>
            <List.Item>
              <List.Content floated="right" style={{ marginTop: "5px" }}>
                <Button
                  size="mini"
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                  onClick={() => handleDataStudio(data.data_studio, data.slug)}
                >
                  Data Studio
                </Button>
                <Button
                  size="mini"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                  // color={data.data_share ? "teal" : "google plus"}
                  className={
                    data.data_share
                      ? "data-share formbuilder-buttons"
                      : "data-not-share formbuilder-buttons"
                  }
                  onClick={() =>
                    setDataShare(data.data_share, data.slug, data.id)
                  }
                >
                  Data Shares
                </Button>
                <Button
                  onClick={() => {
                    selectDataForEditing(data.id);
                    setOpenInfo(true);
                    setCreating(false);
                  }}
                  icon
                  circular
                  className="editButton"
                >
                  <Icon name="pencil" />
                </Button>

                <Button
                  onClick={() => deleteData(data.id)}
                  icon
                  circular
                  className="deleteButton"
                >
                  <Icon name="trash" />
                </Button>

                <Popup
                  trigger={
                    <Button
                      className="formbuilder-buttons"
                      style={{
                        backgroundColor: "white",
                      }}
                      circular
                      as={Link}
                      to={"/formbuilder?organization_id=" + data.id}
                      icon={"book"}
                    ></Button>
                  }
                  content={
                    "Filter & Show Form Builder by this Organization: " + data.name
                  }
                  position="top right"
                  size="tiny"
                />

                <Popup
                  trigger={
                    <Button
                      className="formbuilder-buttons"
                      style={{
                        backgroundColor: "white",
                      }}
                      circular
                      as={Link}
                      to={"/user?organization_id=" + data.id}
                      icon={"user"}
                    ></Button>
                  }
                  content={
                    "Filter & Show All Users by this Organization: " + data.name
                  }
                  position="top right"
                  size="tiny"
                />
              </List.Content>

              <Image
                size="tiny"
                rounded
                bordered
                src={
                  data.image ||
                  defaultImg
                }
                style={{ minHeight: "80px" }}
                onError={(e: any) =>
                  (e.target.src =
                    defaultImg)
                }
              />
              <List.Content as={Link} to={`/organization/organizationReport/` + data.id}>
                <List.Header className="text-uppercase">
                  {data.name}
                </List.Header>
                <List.Description>
                  {data.country ? data.country.country_name : ""}
                </List.Description>
                <List.Description>{data.order}</List.Description>
              </List.Content>
            </List.Item>
            <Divider />
          </List>
        );
      });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <ModalComponent
          open={open}
          setOpen={setOpen}
          confirmDelete={confirmDelete}
          strict={true}
          loading={loading}
        />
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Organization</Breadcrumb.Section>
      </Breadcrumb>
      <Modal
        size="small"
        open={openDataShareDisabled}
        dimmer="blurring"
        closeIcon
        onClose={() => setOpenDataShareDisabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Enable Data Shares?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancel"
            labelPosition="right"
            icon="delete"
            className="clearButton"
            onClick={() => setOpenDataShareDisabled(false)}
          ></Button>
          <Button
            content="Enable"
            labelPosition="right"
            icon="checkmark"
            className="submitButton"
            onClick={() => enableDataShare(slug)}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataShareEnabled}
        closeIcon
        onClose={() => setOpenDataShareEnabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Select Data Shares Link</p>

            <Card.Group>
              <Card fluid color="orange">
                <Card.Content>
                  <Card.Header>Api</Card.Header>
                </Card.Content>

                <Card.Description>
                  <Input fluid>
                    <input
                      readOnly
                      defaultValue={AppConfig.apiUrl + "/orgshare/" + slug}
                    ></input>
                    <Popup
                      content="Link Copied to Clipboard"
                      onOpen={() =>
                        copyToClipboard(AppConfig.apiUrl + "/orgshare/" + slug)
                      }
                      on="click"
                      trigger={<Button>Copy</Button>}
                    />
                  </Input>
                </Card.Description>
              </Card>

              {project &&
                project.map((data: any, index: number) => {
                  return (
                    <Card fluid color="orange" key={index}>
                      <Card.Content>
                        <Card.Header>
                          {data["name"] ?? ""} Project Api
                        </Card.Header>
                      </Card.Content>

                      <Card.Description>
                        <Input fluid>
                          <input
                            defaultValue={
                              AppConfig.apiUrl +
                                "/projectshare/" +
                                data["slug"] ?? ""
                            }
                          ></input>
                          <Popup
                            content="Link Copied to Clipboard"
                            onOpen={() =>
                              copyToClipboard(
                                AppConfig.apiUrl +
                                  "/projectshare/" +
                                  data["slug"] ?? ""
                              )
                            }
                            on="click"
                            trigger={<Button>Copy</Button>}
                          />
                        </Input>
                      </Card.Description>
                    </Card>
                  );
                })}
            </Card.Group>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenDataShareEnabled(false)}>
            Cancel
          </Button>
          <Button
            content="Disable"
            labelPosition="right"
            icon="checkmark"
            onClick={() => enableDataShare(slug)}
            negative
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataStudio}
        closeIcon
        onClose={() => setOpenDataStudio(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Studio Link</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Input
              fluid
              placeholder="Enter Data studio link..."
              name="datastudio"
              onChange={handleDataStudioChange}
              value={datastudio}
            />
            {datastudio !== "" || datastudio !== null ? (
              <a href={datastudio} target="_blank" rel="noopener noreferrer">
                <Button
                  className="formbuilder-buttons mt-2"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                >
                  Open Link
                </Button>
              </a>
            ) : (
              ""
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancel"
            labelPosition="right"
            icon="delete"
            className="clearButton"
            onClick={() => setOpenDataStudio(false)}
          ></Button>
          <Button
            content="Save"
            labelPosition="right"
            icon="checkmark"
            onClick={() => DataStudioSave()}
            className="submitButton"
          />
        </Modal.Actions>
      </Modal>
    </div>,
    <div className="app-card-org" key="2">
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Organization
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Organization List
        <Input
          onChange={(e) => setSearch(e.target.value)}
          className="pull-right small"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      {organizations && <RenderList data={organizations} />}

      {organizations.organization === undefined  || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}

      <br/>
      <Grid>
        <Grid.Column>
          <div style={{ marginRight: '5px', display: 'inline-block' }}>
            <p style={{ display: 'inline-block' }}>Per Page: </p>
            <Dropdown
              options={[
                { key: 5, text: '5', value: 5 },
                { key: 10, text: '10', value: 10 },
                { key: 15, text: '15', value: 15 },
                { key: 25, text: '25', value: 25 },
                { key: 50, text: '50', value: 50 },
                { key: 100, text: '100', value: 100 },
              ]}
              placeholder='Per Page'
              compact
              selection
              upward
              onChange={onPerPageChange}
              style={{ marginLeft: '5px' }}
              value={perPage}
            />
          </div>

          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Organization
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Organization Name</label>
            <Form.Input
              fluid
              required
              type="text"
              name="name"
              value={fdata.newData.name}
              onChange={handleChange}
              placeholder="Enter Organization name..."
            />
          </Form.Field>

          <Form.Field>
            <label>Country</label>
            <Select
              fluid
              placeholder="Select Country"
              options={countryOptions}
              label="Country type"
              value={fdata.newData.country}
              name="country"
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select Modules"
              required
              search
              clearable
              multiple
              selection
              options={moduleOptions}
              label="Modules"
              value={fdata.newData.module}
              name="module"
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="image"
                label="Upload Organization Logo"
                onChange={handleChange}
                accept="image/*"
                required={fdata.newData.id === 0}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
