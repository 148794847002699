import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const houseCategoryTypes = {
  HOUSE_CATEGORY_LOADED: "HOUSE_CATEGORY_LOADED",
  HOUSE_CATEGORY_UNLOADED: "HOUSE_CATEGORY_UNLOADED",
  ADD_HOUSE_CATEGORY: "ADD_HOUSE_CATEGORY",
  EDIT_HOUSE_CATEGORY: "EDIT_HOUSE_CATEGORY",
  DELETE_HOUSE_CATEGORY: "DELETE_HOUSE_CATEGORY",
};

export const loadHouseCategoryPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: houseCategoryTypes.HOUSE_CATEGORY_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadHouseCategoryPage = () => {
  return {
    type: houseCategoryTypes.HOUSE_CATEGORY_UNLOADED,
    payload: {},
  };
};

export const addHouseCategory = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/house_category",  {...payload,extra}).then(
      (data) => {
        dispatch({
          type: houseCategoryTypes.ADD_HOUSE_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editHouseCategory = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/house_category/update/${payload.id}`,  {...payload,extra}).then(
      (data) => {
        dispatch({
          type: houseCategoryTypes.EDIT_HOUSE_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeHouseCategory = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/house_category/delete/${id}`).then(
      (data) => {
        dispatch({
          type: houseCategoryTypes.DELETE_HOUSE_CATEGORY,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
