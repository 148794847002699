import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadSharingTypePage,
  unloadSharingTypePage,
} from "../../redux/action/sharingAction";

interface Props {
  match: {
    params: {
      public_id: any;
    };
  };
}

interface RootState {
  sharings: {
    css: string;
    html: string;
    title: string;
    updated_at: string;
    error?: string;
  };
}

export default function PublicPortal(props: Props) {
  const [apiUrl] = useState(`/sharing/portal/${props.match.params.public_id}`);
  const sharings = useSelector((state: RootState) => state.sharings);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSharingTypePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadSharingTypePage());
    };
  }, [apiUrl, dispatch]);

  useEffect(() => {
    if (sharings !== undefined && sharings && sharings.title) {
      document.title = sharings.title + " - Shared Portal";
    }
  }, [sharings]);

  ////////////////////////////////////////////////////////
  // // OLD SCHOOL METHOD // (NOT USEFUL WITH REACT)
  // function getContent(url: string) {
  //   const requestOptions: object = {
  //     method: "GET",
  //     headers: authHeader(),
  //   };

  //   return fetch(`${url}`, requestOptions).then((res) => {
  //     return res.json();
  //   }).then((data) => {
  //     setHtml(data.html);
  //     setCss(data.css);
  //     setTitle(data.title);
  //   })
  // }
  // getContent("/sharing/portal/"+props.match.params.public_id)
  ////////////////////////////////////////////////////////////

  return sharings === undefined || !sharings || !sharings.html ? (
    <div className="loader-overlay" style={{ zIndex: 1 }}>
      {sharings.error || sharings.html === null ? (
        <p>
          NO CONTENT
          <br />
          <small>Portal has no content to show.</small>
        </p>
      ) : (
        "LOADING..."
      )}
    </div>
  ) : (
    <div
      style={{
        margin: "10px",
        animation: "fade 1s ease forwards",
      }}
      dangerouslySetInnerHTML={{
        __html: sharings.html + "<style>" + sharings.css + "</style>",
      }}
    />
  );
}
