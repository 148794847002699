import { sliderImageTypes } from "../action/sliderImageAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case sliderImageTypes.SLIDER_IMAGE_LOADED:
      return action.payload;

    case sliderImageTypes.SLIDER_IMAGE_UNLOADED:
      return initialState;
    case sliderImageTypes.ADD_SLIDER_IMAGE:
      return { ...state };
    case sliderImageTypes.EDIT_SLIDER_IMAGE:
      return state;
    case sliderImageTypes.DELETE_SLIDER_IMAGE:
      return state;

    default:
      return state;
  }
};
