import { awarenessNewsTypes } from "../action/awarenessNewsAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case awarenessNewsTypes.AWARENESS_NEWS_LOADED:

            return action.payload;

        case awarenessNewsTypes.AWARENESS_NEWS_UNLOADED:
            return initialState;
        case awarenessNewsTypes.ADD_AWARENESS_NEWS:
           
             return { ...state};
        case awarenessNewsTypes.EDIT_AWARENESS_NEWS:

            return state;
        case awarenessNewsTypes.DELETE_AWARENESS_NEWS:
           
            return state;

        default:
            return state;
    }
};
