import React, { useEffect } from 'react'
import UserForm from './UserForm'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'

import { fetchData, unloadData } from '../../redux/action/allAction';
import { Loader } from 'semantic-ui-react';

interface Props {
    match: {
        params:
        {
            slug: string;
            id: number;
        };
    };
}
export default function UserEdit(props: Props) {
    const user = useSelector((state: any) => state.all_ind);
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchUser(props.match.params.id,props.match.params.slug))
        dispatch(fetchData(`/users/edit/${props.match.params.id}`))
        return () => {
            dispatch(unloadData())
        }
        // eslint-disable-next-line
    }, [])

    if (_.isEmpty(user)) {
        return (
            <Loader />
        )
    }
    return (
        <UserForm type="Edit" data={user}   />
    )
}
