import { covidTraceTypes } from "../action/covidTraceAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case covidTraceTypes.COVID_TRACE_LOADED:
      return action.payload;
    case covidTraceTypes.COVID_TRACE_UNLOADED:
      return initialState;
    default:
      return state;
  }
};
