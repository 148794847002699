import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const materialListTypes = {
  MATERIAL_LIST_LOADED: "MATERIAL_LIST_LOADED",
  MATERIAL_LIST_UNLOADED: "MATERIAL_LIST_UNLOADED",
  ADD_MATERIAL_LIST: "ADD_MATERIAL_LIST",
  EDIT_MATERIAL_LIST: "EDIT_MATERIAL_LIST",
  DELETE_MATERIAL_LIST: "DELETE_MATERIAL_LIST",
};

export const loadMaterialListPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: materialListTypes.MATERIAL_LIST_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadMaterialListPage = () => {
  return {
    type: materialListTypes.MATERIAL_LIST_UNLOADED,
    payload: {},
  };
};

export const addMaterialList = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/material_list", payload).then(
      (data) => {
        dispatch({
          type: materialListTypes.ADD_MATERIAL_LIST,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
type payloadProps = {
  data:{
  id: number
  }[]
};

export const editMaterialList = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/material_list/update/${payload.data[0].id}`, payload).then(
      (data) => {
        dispatch({
          type: materialListTypes.EDIT_MATERIAL_LIST,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeMaterialList = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/material_list/delete/${id}`).then(
      (data) => {
        dispatch({
          type: materialListTypes.DELETE_MATERIAL_LIST,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
