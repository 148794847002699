import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Breadcrumb, Button, Tab } from "semantic-ui-react";
import { postData } from "../../redux/action/allAction";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed';


interface Props {
  data?: any;
  location?: any;
  match: any;
}

const codeMirrorOptions = {
  theme: "material",
  lineNumbers: true,
  scrollbarStyle: null,
  lineWrapping: true,
  autocorrect:true
};

export default function CreateCover(props: Props) {
  const [values, setValues] = useState({
    cover: "",
    coverName: "",
    id: 0,
  });
  const history = useHistory<any>();
  const dispatch = useDispatch();
  const coverRef = useRef<any>();

  useEffect(() => {
    if (history.location.state !== undefined) {
      let { id, name, cover } = history.location.state;
      setValues({
        cover: cover !== null ? cover : "",
        coverName: name,
        id: id,
      });
    }
  }, []);

  const handleTextArea = (e: any) => {
    e.persist();
    const { name, value } = e.target;
    setValues((val) => ({
      ...val,
      [name]: value,
    }));
  };

  const panes = [
    {
      menuItem: "Cover",
      pane: (
        <Tab.Pane key="cover">
          <CodeMirror
            value={values.cover}
            className="code-editor"
            
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                cover: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
        </Tab.Pane>
      ),
    },
  ];

  const createCover = () => {
    return { __html: values.cover };
  };

  const handleSaveCover = () => {
    dispatch(
      postData("/project/reportpdf/" + props.match.params.id, {
        name: values.coverName,
        pdf: values.cover,
        id: values.id,
      })
    );
  };

  return (
    <div>
      <div>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to="/project">
            Project
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section
            link
            as={Link}
            to={`/project/projectReport/${props.match.params.id}`}
          >
            Project Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>Cover</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <div className="text-editor-div">
          <Tab
            className="print-tab-menu"
            panes={panes}
            renderActiveOnly={false}
          />
        </div>
        <div className="render-results">
          <input
            className="custom-input"
            type="text"
            name="coverName"
            placeholder="Enter Cover Name"
            onChange={handleTextArea}
            value={values.coverName}
            required
          />
          &nbsp;&nbsp;&nbsp;
          <Button
            className="export-button"
            onClick={() => {
              handleSaveCover();
            }}
          >
            Save Cover
          </Button>
          <div
            ref={coverRef}
            className="result result-cover"
            dangerouslySetInnerHTML={createCover()}
          />
        </div>
      </div>
    </div>
  );
}
