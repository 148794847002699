import { alertActions } from './alertAction';
import { apiService } from '../../services';
import { Dispatch } from 'redux';
export const moduleTypes = {

    MODULE_PAGE_LOADED: "MODULE_PAGE_LOADED",
    MODULE_PAGE_UNLOADED: "MODULE_PAGE_UNLOADED",
    ADD_MODULE: "ADD_MODULE",
    EDIT_MODULE: "EDIT_MODULE",
    DELETE_MODULE: "DELETE_MODULE",


}

export const loadModulePage = (url: string, onFinally: Function) => {

    return async (dispatch: any) => {

        apiService.getAll(url)
            .then(
                data => {

                    dispatch({
                        type: moduleTypes.MODULE_PAGE_LOADED,
                        payload: data
                    })
                    onFinally();

                },
                error => {

                    dispatch(alertActions.error(error.toString()));
                    onFinally();
                }
            );


    };
}



export const unloadModulePage = () => {
    return {
        type: moduleTypes.MODULE_PAGE_UNLOADED,
        payload: {}

    };
}

export const addModule = (payload: object, onSuccess: Function, onError: Function) => {


    return async (dispatch: any) => {

        apiService.save('/module', payload)
            .then(
                data => {

                    dispatch({
                        type: moduleTypes.ADD_MODULE,
                        payload: data
                    })
                    onSuccess();
                    // dispatch(alertActions.success('Data added successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };


}
interface payloadProps {
    id: number,
    name:string,
   
//    [key: string]: any
}

export const editModule = (payload: payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch: any) => {

        apiService.update(`/module/update/${payload.id}`, payload)
            .then(
                data => {

                    dispatch({
                        type: moduleTypes.EDIT_MODULE,
                        payload: data
                    })
                    onSuccess();
                    // dispatch(alertActions.success('Data Edited successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}



export const removeModule = (id:number, onSuccess: Function, onError: Function) => {


    return async (dispatch:Dispatch) => {

        apiService._delete(`/module/delete/${id}`)
            .then(
                data => {

                    dispatch({
                        type: moduleTypes.DELETE_MODULE,
                       
                    })
                    onSuccess();
                    // dispatch(alertActions.success('Data Deleted successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}



