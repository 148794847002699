import { appUserTypes } from "../action/appUsersAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case appUserTypes.APP_USER_LOADED:
      return action.payload;
    case appUserTypes.APP_USER_UNLOADED:
      return initialState;
    case appUserTypes.ADD_APP_USER:
      return state;
    case appUserTypes.EDIT_APP_USER:
      return state;
    case appUserTypes.DELETE_APP_USER:
      return state;
    default:
      return state;
  }
};
