import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Accordion,
  Label,
  Image,
  Divider,
  Tab,
  Card,
  Icon,
  Dimmer,
  Loader,
  Breadcrumb,
  Modal,
  Form,
  Checkbox,
  Popup,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import HeadComponent from "./component/HeadComponent";
import StepFormBuilder from "./component/StepFormBuilder";
import LogDetail from "./component/LogDetail";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, unloadData } from "../../redux/action/allAction";
import FormDataDetail, {
  RenderAllStepData,
  RenderTableFormDetail,
} from "./component/FormDataDetail";
import coinIcon from "../../assets/cisco/svg/coin.svg";

// import ReactToPrint from "react-to-print";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";

import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon as LeafletIcon } from "leaflet";
import _, { isEmpty } from "lodash";
import { formBuilderReportPageRouteGenerate } from "../../helper/routing";
import { FormattedMessage } from "react-intl";
import ReactToPrint from "react-to-print";

function searchForName(record: any, defaultValue: string = "") {
  let name =
    record.name ??
    record.homeowner_name ??
    record.homeownerName ??
    record.member_name ??
    record.memberName ??
    record.full_name ??
    record.fullName ??
    record._parent?.name ??
    record._parent?.fullName ??
    record._parent?.full_name ??
    null;

  if (name) {
    return `Name: ${name}`;
  }

  return defaultValue;
}

export default function FormViewV2(props: {
  history: { goBack: Function; go: Function; push: Function };
  match: { params: { slug: any; id: any } };
  location: { search: string | null };
}) {
  const containers_to_show = new URLSearchParams(props?.location?.search ?? "")
    .get("containers_to_show")
    ?.split(",");

  const step_id = new URLSearchParams(props?.location?.search ?? "").get(
    "step_id"
  );

  const hide_step_forms = new URLSearchParams(
    props?.location?.search ?? ""
  ).get("hide_step_forms");

  const tranche_amount = new URLSearchParams(window.location?.search ?? "").get(
    "tranche_amount"
  );

  const finance_id = new URLSearchParams(window.location?.search ?? "").get(
    "finance_id"
  );

  const tranche_id = new URLSearchParams(window.location?.search ?? "").get(
    "tranche_id"
  );

  const data_details = useSelector((state: any) => state.all_ind);
  const [reportList, setReportList] = useState<
    { key: number | string; text: string; value: string }[]
  >([
    {
      key: 0,
      value: "",
      text: "Default",
    },
  ]);

  const [reportSelected, setreportSelected] = useState({
    newData: {
      report: "",
      parent: false,
      child: false,
      step: false,
      related: false,
    },
  });
  const [openData, setOpenData] = useState<boolean>(false);
  const [hideEmptyPanels, setHideEmptyPanels] = useState<boolean>(false);
  const [stepFormRecentlySubmitted, setStepFormRecentlySubmitted] =
    useState<boolean>(false);

  // const [submission, setsubmission] = useState();
  // const [component, setcomponent] = useState();
  const dispatch = useDispatch();
  const ref = useRef<any>();

  const [hideMap, setHideMap] = useState(true);

  useEffect(() => {
    // dispatch(fetchStep(props.match.params.id,props.match.params.slug))
    fetchApi();
    return () => {
      dispatch(unloadData());
    };
  }, [props.match.params]);

  useEffect(() => {
    // console.log(data_details)
    if (data_details.report_list !== undefined) {
      var final = data_details.report_list.map(
        (data: { _id: { $oid: string }; name: string }) => {
          return {
            key: data._id.$oid,
            value: data._id.$oid,
            text: data.name,
          };
        }
      );
      final.unshift({
        key: 0,
        value: "",
        text: "Default",
      });
      setReportList(final);
    }
  }, [data_details]);

  const fetchApi = (is_step_form_recently_submitted = false) => {
    if (is_step_form_recently_submitted) {
      setStepFormRecentlySubmitted(true);
    }

    dispatch(
      fetchData(
        `/formData/${props.match.params.slug}/detail/${props.match.params.id}${
          step_id ? `?step_id=${step_id}` : ""
        }`
      )
    );
  };

  const handleChange = (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;
    setreportSelected({
      newData: Object.assign({}, reportSelected.newData, {
        [name]: value,
      }),
    });
  };

  const exportPdf = () => {
    apiService
      .getfile(
        `/formBuilder/exportpdf/${props.match.params.slug}/${props.match.params.id}?report=${reportSelected.newData.report}&related=${reportSelected.newData.related}&
        step=${reportSelected.newData.step}&parent=${reportSelected.newData.parent}&child=${reportSelected.newData.child}
        `
      )
      .then(
        (data) => {
          setOpenData(false);
          setTimeout(() => {
            window.open(data);
          }, 100);
        },
        (error) => {
          setOpenData(false);
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const mainContent = (
    <div className="my-custom-scrollbar">
      {data_details && <FormDataDetail data={data_details} />}
    </div>
  );

  const mainSectionContent = (
    <div className="my-custom-scrollbar">
      {data_details && (
        <FormDataDetail
          data={data_details}
          containers_to_show={containers_to_show ?? []}
        />
      )}
    </div>
  );

  const parentContent = data_details.parent ? (
    <div className="my-custom-scrollbar">
      <div className="table-data">
        <RenderTableFormDetail
          data={data_details.parent.data}
          field={data_details.parent.field}
        />
      </div>
    </div>
  ) : (
    <small className="block p-3">Not Available</small>
  );

  const EachContent = ({ data, field, link }: any) => (
    <div className="my-custom-scrollbar">
      <div className="table-data">
        {data && <RenderTableFormDetail data={data} field={field} />}
      </div>

      {link && (
        <Button as="a" href={link} target="_blank" basic compact size="mini">
          <Icon name="external" /> Open In New Tab
        </Button>
      )}
    </div>
  );

  const ChildContent = (data: any) => {
    if (data.data === undefined || data.field === undefined) {
      return null;
    }

    return data.data.map((item: any, index: number) => {
      let childIdentifierAndValue = null;

      if (data?.parent_form_identifier && item[data?.parent_form_identifier]) {
        const field = data.field?.find(
          (field: any) => field.key === data?.parent_form_identifier
        );
        if (field) {
          childIdentifierAndValue = (
            <p>
              <b>{field.label || field.key}: </b>
              {item[data?.parent_form_identifier]}
            </p>
          );
        } else {
          childIdentifierAndValue = (
            <p>
              <b>{data?.parent_form_identifier}: </b>
              {item[data?.parent_form_identifier]}
            </p>
          );
        }
      }

      return {
        key: index,
        title: {
          content: (
            <Label
              color="brown"
              content={
                <div>
                  {childIdentifierAndValue}
                  <p>
                    <b>{searchForName(item)}</b>
                  </p>
                  <p>Record ID: {item._id.$oid || "-"}</p>
                </div>
              }
            />
          ),
        },
        content: {
          content: (
            <EachContent
              data={item}
              field={data.field}
              link={
                data.slug && item._id.$oid
                  ? `/formbuilder/data/${data.slug}/view/${item._id.$oid}/`
                  : null
              }
            />
          ),
        },
      };
    });
  };

  const childPanel =
    data_details.child &&
    data_details.child.map((item: any, index: number) => {
      return {
        key: index,
        title: {
          content: <Label color="green" content={item.name || "-"} />,
        },
        content: {
          content:
            item.data === undefined ||
            item.field === undefined ||
            item.data.length === 0 ? (
              <small className="block">Related Record was not found.</small>
            ) : (
              <Accordion
                exclusive={false}
                panels={ChildContent(item)}
                fluid
                styled
              />
            ),
        },
      };
    });

  const relatedContent =
    data_details.related &&
    data_details.related.map((item: any, index: number) => {
      return {
        key: index,
        title: {
          content: <Label color="green" content={item.name || "-"} />,
        },
        content: {
          content: (
            <Accordion
              exclusive={false}
              panels={ChildContent(item)}
              fluid
              styled
            />
          ),
        },
      };
    });

  const stepContent =
    (data_details.steps && data_details.steps.length > 0) ||
    (data_details.data && data_details.data["step_data"]) ? (
      <div>{data_details && <RenderAllStepData data={data_details} />}</div>
    ) : (
      <small className="block p-3">Not Available</small>
    );

  const PlannerContent = (
    <div>
      {data_details.planner ? (
        <Image src={data_details.planner.photo ?? ""} size="large" />
      ) : (
        <small className="block p-3">Not Available</small>
      )}
    </div>
  );

  const FinanceContent = (
    <div className="p-3">
      {data_details.finances && data_details.finances.length > 0 ? (
        data_details.finances.map((finance: any, index: number) => (
          <div key={finance.id}>
            <h3>
              <strong>{finance.name}:</strong>
            </h3>

            <ul>
              {finance.tranches.map((tranche: any) => (
                <Card fluid className="w-full" key={tranche.id}>
                  <Card.Content>
                    <li className="mb-2 text-xl font-bold underline underline-offset-2">
                      {tranche.name}:
                    </li>

                    <ul>
                      {tranche.transactions &&
                      tranche.transactions.length > 0 ? (
                        tranche.transactions.map(
                          (transaction: any, index: number) => (
                            <div
                              style={{
                                marginLeft: index * 15 + "px",
                                paddingLeft: "10px",
                                borderLeft: "3px dotted #656565",
                              }}
                              className="hover:bg-[#eeeeee55] transition-all"
                            >
                              <li>
                                <strong>Owner: </strong>
                                {transaction.owner_name}
                              </li>
                              <li>
                                <strong>Amount: </strong>
                                <b
                                  className={`${
                                    transaction.amount > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {transaction.amount}
                                </b>
                              </li>
                              <li>
                                <strong>Status: </strong>
                                <Label
                                  style={{
                                    marginLeft: "5px",
                                    padding: "3px 5px",
                                    fontSize: "0.8rem",
                                  }}
                                  color={
                                    transaction.status === "paid"
                                      ? "green"
                                      : transaction.status === "reviewed"
                                      ? "blue"
                                      : transaction.status === "not_paid" ||
                                        transaction.status === "not_reviewed"
                                      ? "red"
                                      : "yellow"
                                  }
                                >
                                  {transaction.status === "paid" ||
                                  transaction.status === "not_paid" ? (
                                    <img
                                      src={coinIcon}
                                      alt="Money"
                                      className="coin-icon"
                                    />
                                  ) : (
                                    <Icon
                                      name={
                                        transaction.status === "paid"
                                          ? "money bill alternate outline"
                                          : transaction.status === "not_paid"
                                          ? "money bill alternate outline"
                                          : transaction.status === "reviewed"
                                          ? "pencil"
                                          : transaction.status ===
                                            "not_reviewed"
                                          ? "pencil"
                                          : "spinner"
                                      }
                                    />
                                  )}

                                  {transaction.status === "not_paid"
                                    ? "REJECTED"
                                    : transaction.status === "not_reviewed"
                                    ? "REJECTED"
                                    : transaction.status.toUpperCase()}
                                </Label>
                              </li>
                              <li>
                                <strong>Remarks: </strong>
                                <span className="whitespace-pre">
                                  {transaction.description ?? "-"}
                                </span>
                              </li>
                              <li>
                                <strong>Time: </strong>
                                {transaction.updated_at
                                  ? new Date(
                                      transaction.updated_at
                                    ).toLocaleString() + " Local Time"
                                  : "-"}
                              </li>
                              <hr className="my-2" />
                            </div>
                          )
                        )
                      ) : (
                        <li>
                          <small>Zero Transactions for this Owner.</small>
                        </li>
                      )}
                    </ul>
                  </Card.Content>
                </Card>
              ))}
            </ul>

            {index < data_details.finances.length - 1 ? <Divider /> : ""}
          </div>
        ))
      ) : (
        <small>No Finance Transactions Available</small>
      )}
    </div>
  );

  const rootPanelsTabs = [
    // {
    //   menuItem: "Main Data",
    //   render: () => <Tab.Pane content={mainSectionContent} />,
    // },
    {
      menuItem: "All Data",
      render: () => <Tab.Pane content={mainContent} />,
    },
    {
      menuItem: "Related Data",
      render: () => (
        <Tab.Pane>
          {data_details.related ? (
            <Accordion exclusive={false} panels={relatedContent} fluid styled />
          ) : (
            <small className="block p-3">No Related Records Available</small>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Step Data",
      // menuItem: "Comments",
      render: () => <Tab.Pane>{stepContent}</Tab.Pane>,
    },
    {
      menuItem: "Planner Data",
      render: () => <Tab.Pane>{PlannerContent}</Tab.Pane>,
    },
    {
      menuItem: "Child Data",
      // menuItem: "Enrollment",
      render: () => (
        <Tab.Pane>
          {data_details.child ? (
            <Accordion exclusive={false} panels={childPanel} fluid styled />
          ) : (
            <small className="block p-3">Not Available</small>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Finance",
      render: () => <Tab.Pane>{FinanceContent}</Tab.Pane>,
    },
  ];

  return [
    <div
      key="1"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section
          link
          onClick={() => {
            if (window.history.length > 1) {
              props.history.goBack();
            } else {
              props.history.push("/");
            }
          }}
        >
          <FormattedMessage id="Back" defaultMessage="Back" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          {data_details.form && data_details.form.name}
        </Breadcrumb.Section>
      </Breadcrumb>

      <Modal
        size="small"
        open={openData}
        onClose={() => setOpenData(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Select pdf design for report</Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select Report Type"
              required
              search
              defaultValue=""
              clearable
              selection
              options={reportList}
              label="Report Type"
              value={reportSelected.newData.report}
              name="report"
              onChange={handleChange}
            />
          </Form.Field>

          <br />
          {/* <Form.Field>
      <Checkbox label='Main Data' />
    </Form.Field> */}
          <Form.Field>
            <Checkbox
              label="Related Data"
              onChange={handleChange}
              name="related"
              checked={reportSelected.newData.related}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Step Data"
              onChange={handleChange}
              name="step"
              checked={reportSelected.newData.step}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Parent Data"
              onChange={handleChange}
              name="parent"
              checked={reportSelected.newData.parent}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Child Data"
              onChange={handleChange}
              name="child"
              checked={reportSelected.newData.child}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox label="Planner Data" name="planner" />
          </Form.Field>
          <Form.Field>
            <Checkbox label="Finance Data" name="finance" />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenData(false)}>
            Cancel
          </Button>
          <Button className="bg-green" onClick={exportPdf}>
            Export
          </Button>
        </Modal.Actions>
      </Modal>

      <div className="flex items-center justify-start">
        {/* <Checkbox
          label="Hide Empty Panels"
          style={{ marginRight: "8px" }}
          onChange={(e, data) =>
            setHideEmptyPanels(data.checked ? true : false)
          }
          checked={hideEmptyPanels}
        /> */}

        <Popup
          content={hideMap ? "Show Map" : "Hide Map"}
          trigger={
            <Button
              icon="map"
              basic
              circular
              onClick={() => setHideMap(!hideMap)}
              className={`${hideMap ? "opacity-50" : ""}`}
            />
          }
        ></Popup>

        <Button
          className="bg-green export-button"
          onClick={() => setOpenData(true)}
          icon="upload"
          circular
        />

        <ReactToPrint
          documentTitle={props.match.params.slug + "_" + props.match.params.id}
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <Button className="bg-blue export-button">Print this out!</Button>
            );
          }}
          content={() => ref.current}
        />
      </div>
    </div>,

    <div key="2" className="app-card-org">
      <Dimmer className="sm-dimmer" inverted active={isEmpty(data_details)}>
        <Loader size="large" />
      </Dimmer>

      <div ref={ref} className="custom-accordion-container">
        <div className="project-report-title">
          <h3 className="main-title" style={{ borderBottom: 0 }}>
            {data_details.data &&
              searchForName(data_details.data, data_details.data._id.$oid)}
          </h3>
          <p className="sub-title">
            {data_details.data &&
            data_details?.form?.parent_form_identifier &&
            data_details.data[data_details.form.parent_form_identifier] ? (
              <>
                <b>{_.startCase(data_details.form.parent_form_identifier)}: </b>
                {data_details.data[data_details.form.parent_form_identifier]}
              </>
            ) : (
              ""
            )}
          </p>
          <p className="sub-title">
            <b>Record ID:</b>{" "}
            {data_details.data && data_details.data?._id?.$oid}
          </p>
          <p className="sub-title">
            <b>Form:</b> {data_details.form && data_details.form.name}
            {data_details.project ? " - " + data_details.project : ""}
          </p>

          {/* {data_details && data_details.next_record_id ? (
            <Button
              className="absolute"
              style={{
                right: "30px",
                top: "30px",
              }}
              as={Link}
              to={formBuilderReportPageRouteGenerate(
                props.match.params.slug,
                data_details.next_record_id,
                step_id as string,
                hide_step_forms ? true : false,
                containers_to_show ?? [],
                {
                  finance_id: finance_id,
                  tranche_id: tranche_id,
                  tranche_amount: tranche_amount,
                }
              )}
              title="Open Next Record"
              size="small"
              onClick={() => setOpenData(true)}
              icon="arrow right"
              circular
            />
          ) : (
            ""
          )}

          {data_details && data_details.previous_record_id ? (
            <Button
              className="absolute"
              style={{
                left: "30px",
                top: "30px",
              }}
              as={Link}
              to={formBuilderReportPageRouteGenerate(
                props.match.params.slug,
                data_details.previous_record_id,
                step_id as string,
                hide_step_forms ? true : false,
                containers_to_show ?? [],
                {
                  finance_id: finance_id,
                  tranche_id: tranche_id,
                  tranche_amount: tranche_amount,
                }
              )}
              title="Open Previous Record"
              size="small"
              onClick={() => setOpenData(true)}
              icon="arrow left"
              circular
            />
          ) : (
            ""
          )} */}
        </div>

        <br />
        <hr />
        <br />

        <div>{data_details && <HeadComponent data={data_details} />}</div>

        {data_details && data_details.data && !hideMap && (
          <Map
            style={{ height: "300px", marginTop: "15px", zIndex: 1 }}
            center={[
              data_details.data._latitude ?? 0,
              data_details.data._longitude ?? 0,
            ]}
            zoom={5}
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="OpenStreetMap">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
            </LayersControl>

            <Marker
              position={[
                data_details.data._latitude ?? 0,
                data_details.data._longitude ?? 0,
              ]}
              icon={
                new LeafletIcon({
                  iconUrl: markerIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
            ></Marker>
          </Map>
        )}

        <br />

        <Tab panes={rootPanelsTabs} />

        <br />

        {/* <Accordion exclusive={false} panels={rootPanels()} fluid styled /> */}

        <div className="step-form">
          {data_details.steps &&
            !hide_step_forms &&
            !stepFormRecentlySubmitted && (
              <StepFormBuilder data={data_details} fetchApi={fetchApi} />
            )}
        </div>

        <br />

        {data_details && <LogDetail data={data_details} />}
      </div>
    </div>,
  ];
}
