/**
 * API Route Generator with query params
 */
export const apiRouteGenerate = (
  path: string,
  query_params: object,
  trailing_slash: boolean = true
): string => {
  let query_string = "";

  if (path.includes("?")) {
    path = path.split("?")[0];
  }
  if (path.includes("#")) {
    path = path.split("#")[0];
  }
  if (path.includes("&")) {
    path = path.split("&")[0];
  }
  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  for (const key in query_params) {
    if (
      query_params[key] &&
      (typeof query_params[key] === "string" ||
        typeof query_params[key] === "number" ||
        typeof query_params[key] === "boolean")
    ) {
      const element = query_params[key];
      query_string += `${key}=${element}&`;
    }
  }
  return `/${path}${trailing_slash ? "/" : ""}?${query_string}`;
};

/**
 * Generates and returns the URL for Form Builder Record Report Page (with query params)
 */
export const formBuilderReportPageRouteGenerate = (
  form_slug: string,
  record_id: string,
  step_id: string | number,
  hide_step_forms: boolean,
  containers_to_show: string[],
  finance_meta?: {
    finance_id: number | string | null;
    tranche_id: number | string | null;
    tranche_amount: number | string | null;
  },
  next_record_id?: string,
  previous_record_id?: string
): string => {
  return `/formbuilder/data/${form_slug}/view/${record_id}/${
    step_id ? `?step_id=${step_id}` : `?`
  }${hide_step_forms ? "&hide_step_forms=true" : ""}${
    containers_to_show.length > 0
      ? "&containers_to_show=" + containers_to_show.join(",")
      : ""
  }${
    finance_meta && finance_meta.finance_id && finance_meta.tranche_id
      ? "&finance_id=" +
        finance_meta.finance_id +
        "&tranche_id=" +
        finance_meta.tranche_id +
        "&tranche_amount=" +
        finance_meta.tranche_amount
      : ""
  }${next_record_id ? "&next_record_id=" + next_record_id : ""}${
    previous_record_id ? "&previous_record_id=" + previous_record_id : ""
  }`;
};
