import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const appUserTypes = {
  APP_USER_LOADED: "APP_USER_LOADED",
  APP_USER_UNLOADED: "APP_USER_UNLOADED",
  ADD_APP_USER: "ADD_APP_USER",
  EDIT_APP_USER: "EDIT_APP_USER",
  DELETE_APP_USER: "DELETE_APP_USER",
  RESET_APP_USER_POINTS: "RESET_APP_USER_POINTS",
};

export const loadAppUserPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: appUserTypes.APP_USER_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAppUserPage = () => {
  return {
    type: appUserTypes.APP_USER_UNLOADED,
    payload: {},
  };
};

export const addAppUser = (payload: object) => {
  return async (dispatch: any) => {
    apiService.save("/app_user", payload).then(
      (data) => {
        dispatch({
          type: appUserTypes.ADD_APP_USER,
          payload: data,
        });
        dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAppUser = (payload: payloadProps) => {
  return async (dispatch: any) => {
    apiService.update(`/app_user/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: appUserTypes.EDIT_APP_USER,
          payload: data,
        });
        dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAppUser = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/app_user/delete/${id}`).then(
      (data) => {
        dispatch({
          type: appUserTypes.DELETE_APP_USER,
          payload: id,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const resetAppUserPoints = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save(`/app_user/reset_points/${id}`, {}).then(
      (data) => {
        dispatch({
          type: appUserTypes.RESET_APP_USER_POINTS,
        });
        onSuccess();
        // dispatch(alertActions.success("Points Reset successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
