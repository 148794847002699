import { alertActions } from './alertAction';
import { apiService } from '../../services';
export const phaseTypes = {
   
    PHASE_PAGE_LOADED: "PHASE_PAGE_LOADED",
    PHASE_PAGE_UNLOADED: "PHASE_PAGE_UNLOADED",
    ADD_PHASE:"ADD_PHASE",
    EDIT_PHASE:"EDIT_PHASE",
    DELETE_PHASE:"DELETE_PHASE",


}

export const loadPhasePage =  (url:string) => {
    
    return async (dispatch:any) =>{

        apiService.getAll(url)
        .then(
            data => { 
               
                dispatch({
                    type: phaseTypes.PHASE_PAGE_LOADED,
                    payload: data
                })
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
}



export const unloadPhasePage = () => {
    return {
        type: phaseTypes.PHASE_PAGE_UNLOADED,
        payload: {}

    };
}

export const addPhase = (payload:object) => {
    

    return async (dispatch:any) =>{

        apiService.save('/phase',payload)
        .then(
            data => { 
               
                dispatch({
                    type: phaseTypes.ADD_PHASE,
                    payload: data
                })
                dispatch(alertActions.success('Data added successfully'))
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };

   
}
interface payloadProps {
    id: number,
   // [all:any]:any
}
export const editPhase = (payload:payloadProps) => {

    return async (dispatch:any) =>{

        apiService.update(`/phase/update/${payload.id}`,payload)
        .then(
            data => { 
               
                dispatch({
                    type: phaseTypes.EDIT_PHASE,
                    payload: data
                })
                dispatch(alertActions.success('Data Edited successfully'))
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
}



  export const removePhase= (id:number) =>  {

   
    return async (dispatch:any) =>{

        apiService._delete(`/phase/delete/${id}`)
        .then(
            data => { 
               
                dispatch({
                    type: phaseTypes.DELETE_PHASE
                  
                })
                dispatch(alertActions.success('Data Deleted successfully'))
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
  }

  

