import React from "react";

import { Divider, Grid, Icon } from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { normalizeStepTypeName } from "../../../helper";

export default function StepInfoComponent(props: {
  step_details: any & {
    form_count?: number;
    record_count?: number;
    stage: any;
    form: {
      _id: string;
      name: string;
      slug: string;
      parent: string;
      parent_form_identifier: string;
    };
  };
}) {
  const step = props.step_details;
  const stage = props.step_details.stage;

  return (
    <React.Fragment>
      <Grid stackable={true} style={{ padding: "0 0 0 15px" }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <div className="" style={{ height: "100%" }}>
              <Divider />
              <p className="m-0">
                <strong>STEP NAME:</strong> {step?.name}
              </p>
              <p className="m-0">
                <strong>STEP TYPE:</strong>{" "}
                {step?.type ? normalizeStepTypeName(step.type) : "-"}
              </p>
              <p className="m-0">
                <strong>STEP STATUS:</strong>{" "}
                <small>
                  {step?.pre_status ? (
                    <>
                      <span>{step?.pre_status} </span>
                      <Icon name="arrow right" />
                    </>
                  ) : (
                    ""
                  )}

                  <span>{step?.status ?? "-"}</span>
                </small>
              </p>

              {step?.type ===
              "sync_parent_form_identifier_to_fulcrum_choices" ? (
                <p className="m-0">
                  <strong>Fulcrum Choice List:</strong>{" "}
                  {step?.fulcrum_choice_list_id ?? "N/A"}
                </p>
              ) : (
                ""
              )}

              <hr />

              <p className="m-0">
                <strong>TOTAL FORMS:</strong> {step?.form_count}
              </p>
              <p className="m-0">
                <strong>FORM NAME:</strong> {step?.form?.name ?? "N/A"}
              </p>
              <p className="m-0">
                <strong>TOTAL HOMEOWNERS:</strong> {step?.record_count}
              </p>

              <hr />

              <p className="m-0">
                <strong>STAGE NAME:</strong> {stage?.name}
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}
