import React, { useEffect, useState } from "react";
import {
  Segment,
  Breadcrumb,
  Header,
  Button,
  Icon,
  Step,
  Modal,
  Form,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, unloadData } from "../../redux/action/allAction";
import { Formio } from "@formio/react";
import { FormattedMessage } from "react-intl";
import { addFormdata, unloadFormData } from "../../redux/action/formDataAction";
import { AppConfig } from "../../config";
import DataList from "./DataList";
import { alertActions } from "../../redux/action/alertAction";
import { apiService } from "../../services";
import { normalizeStepTypeName } from "../../helper";

export default function SyncFulcrumChoices(props: {
  history: any;
  step_details: any;
  step_id: number;
}) {
  const step_details = props.step_details;
  const [showFulcrumToken, setShowFulcrumToken] = useState<boolean>(false);
  const [syncing, setSyncing] = useState<boolean>(false);

  const [totalSynced, setTotalSynced] = useState<number | null>(null);

  const dispatch = useDispatch();

  const triggerSync = () => {
    setSyncing(true);

    apiService
      .save1(`/step/${props.step_id}/fulcrum_choices/sync`, {})
      .then((res) => {
        setTotalSynced(res?.total_synced ?? 0);
        dispatch(alertActions.success(res.message));
      })
      .catch((err) => {
        dispatch(alertActions.error(err));
      })
      .finally(() => {
        setSyncing(false);
      });
  };

  return (
    <>
      <div className="text-center">
        <Button
          primary
          loading={syncing}
          disabled={syncing}
          onClick={triggerSync}
          size="huge"
        >
          {!syncing && <Icon name="sync alternate" />}
          Click to Sync Fulcrum Classification Set
        </Button>

        <p style={{ fontWeight: "700", marginTop: "8px" }}>
          {totalSynced !== null ? "Total Synced: " + totalSynced : ""}
        </p>
      </div>

      {step_details && step_details.form ? (
        <div>
          <hr />
          <p>
            This will sync all the{" "}
            <b>"{step_details.form.parent_form_identifier}"</b> record value,
            from{" "}
            <b title={step_details.form.slug}>"{step_details.form.name}"</b>{" "}
            form.
          </p>
          <p>
            And, updates the Classification Set of ID{" "}
            <b>"{step_details.fulcrum_choice_list_id}"</b>, using the provided
            fulcrum token.
          </p>
          <Button
            style={{ fontFamily: '"Courier New", Courier, monospace' }}
            compact
            icon={showFulcrumToken ? "eye" : "eye slash"}
            label={
              showFulcrumToken
                ? step_details.fulcrum_token
                : String("*").repeat(step_details.fulcrum_token.length)
            }
            onClick={() => setShowFulcrumToken(!showFulcrumToken)}
          />

          <hr />

          <small>
            Any chosen Status Filter will be applied as well.{" "}
            <a
              href={`https://web.fulcrumapp.com/classification_sets/${step_details.fulcrum_choice_list_id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View in Fulcrum
              <Icon name="external" className="ml-1" />
            </a>
          </small>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
