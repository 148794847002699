import React, { ReactElement } from "react";

interface Props {
  data: {
    url: string;
  }[];
  label: string;
  resource: string;
  options: {
    label: string;
    value: string;
  }[];
}

function SelectComponent({
  data,
  label,
  resource,
  options,
}: Props): ReactElement {
  const ListItem = () => (
    <React.Fragment>
      {Object.keys(data).map((key, index) => {
        let type = typeof data[key];
        if (key === "id") return null;
        if (type === "string" || type === "number") {
          return (
            <React.Fragment key={index}>
              <br />
              <strong style={{ textTransform: "capitalize" }}>
                {key.split(/(?:[a-z])(?=[A-Z])/)}
              </strong>
              : {data[key]}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              <br />
              <strong style={{ textTransform: "capitalize" }}>
                {key.split(/(?:[a-z])(?=[A-Z])/)}
              </strong>
              : {JSON.stringify(data[key])}
            </React.Fragment>
          );
        }
      })}{" "}
    </React.Fragment>
  );

  const findInOptions = (data: string | number) => {
    if (!options || options.length <= 0) {
      return data;
    }

    let option = options.find((o) => o.value === data);

    return option?.label ?? data;
  };

  return (
    <div className="rows" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        {data["id"] && resource ? (
          <>
            <strong hidden={data["id"] === undefined}> ID : </strong>{" "}
            <a
              href={`/formbuilder/data/${resource}/view/${data["id"]}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {data["id"] || ""}
            </a>
          </>
        ) : (
          ""
        )}

        {typeof data === "string" || typeof data === "number" ? (
          findInOptions(data)
        ) : typeof data === "object" ? (
          "label" in data ? (
            data["label"]
          ) : (
            <ListItem />
          )
        ) : (
          "⚠ NOT-FOUND"
        )}
      </div>
    </div>
  );
}

export default SelectComponent;
