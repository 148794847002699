import React from 'react'
import FormForm from './FormForm'



export default function FormCreate() {
    
    return (
        <FormForm type="Create" />
    )
}
