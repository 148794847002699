//import CheckMatrix from './CheckMatrix';
//import ReactExample from "./ReactExample/ReactExample";
import GeoLocation from './GeoLocation/GeoLocation';
import Collapsible from './Collapsible/Panel.js'

//import toggleCustomComp from "./Custom";
//var Collapsible = require("./Collapsible/Panel.js");

export default {
  //checkmatrix: CheckMatrix,
  geolocation:GeoLocation,
  // reactexample: ReactExample,
  // toggleCustomComp:toggleCustomComp,
  collapsible:Collapsible
}
