import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const contactTypes = {
  CONTACT_LOADED: "CONTACT_LOADED",
  CONTACT_UNLOADED: "CONTACT_UNLOADED",
  ADD_CONTACT: "ADD_CONTACT",
  EDIT_CONTACT: "EDIT_CONTACT",
  DELETE_CONTACT: "DELETE_CONTACT",
};

export const loadContactPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: contactTypes.CONTACT_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadContactPage = () => {
  return {
    type: contactTypes.CONTACT_UNLOADED,
    payload: {},
  };
};

export const addContact = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/contact", payload).then(
      (data) => {
        dispatch({
          type: contactTypes.ADD_CONTACT,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editContact = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/contact/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: contactTypes.EDIT_CONTACT,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeContact = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/contact/delete/${id}`).then(
      (data) => {
        dispatch({
          type: contactTypes.DELETE_CONTACT,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
