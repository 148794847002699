import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addFormbuilder,
  editFormbuilder,
} from "../../redux/action/formbuilderAction";
import {
  Breadcrumb,
  Form,
  Select,
  Button,
  Label,
  Dropdown,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
//
import { Formio } from "@formio/react";
import { AppConfig } from "../../config";
import { uniq } from "lodash";
import FormBuilderTranslate from "./FormBuilderTranslate";

interface Props {
  type: string;
  data?: any;
  slug?: string;
  schema?: any;
  location?: any;
}
interface DataType {
  newData: {
    id: number;
    name: string;
    title: string;
    organization: number;
    library?: string;
    module: number;
    project: number;
    parent?: string;
    parent_form_identifier?: string;
    related?: string;
    record_type: string;
    max_isacsimo: number;
    sub_record_type: string;
    isac_simo: boolean;
    fulcrum_data_share_link?: string;
    fulcrum_api_token?: string;
    kobo_api_token?: string;
    tags?: string[];
    hide_in_dashboard?: boolean;
    is_feedback_form?: boolean;
    components_to_hide?: string[];
    collect_app_accessible?: boolean;
    collect_app_guest_accessible?: boolean;
    collect_app_guest_password?: string | null;
    was_collect_app_guest_password_filled?: boolean;
  };
}

type OptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

interface Type {
  display: any;
}

const generalEnglishLanguageData = {
  error: "Please fix the following errors before submitting.",
  invalid_date: "{{field}} is not a valid date.",
  invalid_email: "{{field}} must be a valid email.",
  invalid_regex: "{{field}} does not match the pattern {{regex}}.",
  mask: "{{field}} does not match the mask.",
  max: "{{field}} cannot be greater than {{max}}.",
  maxLength: "{{field}} must be shorter than {{length}} characters.",
  min: "{{field}} cannot be less than {{min}}.",
  minLength: "{{field}} must be longer than {{length}} characters.",
  next: "Next",
  pattern: "{{field}} does not match the pattern {{pattern}}",
  previous: "Previous",
  required: "{{field}} is required",
  Submit: "Submit",
};

export default function FormbuilderForm(props: Props) {
  const [fdata, setData] = useState<DataType>({
    newData: {
      id: 0,
      name: "",
      title: "",

      organization: 0,
      library: "",
      module: 0,
      project: 0,
      parent: "",
      parent_form_identifier: "",
      record_type: "",
      sub_record_type: "",
      max_isacsimo: 0,
      related: "",
      isac_simo: false,
      tags: [],
      hide_in_dashboard: false,
      is_feedback_form: false,

      components_to_hide: [],
      collect_app_accessible: true,
      collect_app_guest_accessible: false,
      collect_app_guest_password: "",
      was_collect_app_guest_password_filled: false,
    },
  });
  const [type, setType] = useState<Type>({ display: "wizard" });
  const [component, setcomponent] = useState<any>();

  const [organizationOptions, setOrganizationOptions] = useState<OptionType>(
    []
  );
  const [tagOptions, SetTagOptions] = useState<OptionType>([]);

  const [libraryOptions, setLibraryOptions] = useState<Array<any>>([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [recordTypeOptions, setRecordTypeOptions] = useState<Array<any>>([]);

  const [allSubRecordTypes, setAllSubRecordTypes] = useState<Array<any>>([]);
  const [filteredSubRecordTypeOptions, setFilteredSubRecordTypeOptions] =
    useState<Array<any>>([]);
  const [isMetaFormSchemaLoading, setIsMetaFormSchemaLoading] = useState(false);

  const [formOption, setFormOptions] = useState([]);
  const [allFormOption, setAllFormOptions] = useState([]);

  const [languageDataModalOpen, setLanguageDataModalOpen] = useState(false);
  const [languageData, setLanguageData] = useState({
    en: generalEnglishLanguageData,
  });

  const dispatch = useDispatch();
  const history = useHistory<any>();

  useEffect(() => {
    getData();
    FormioForm(type);

    if (props.type === "Edit" && props.data.form !== undefined) {
      var ndata = props.data.form;
      setData({
        newData: {
          id: ndata.id,
          name: ndata.name,
          title: ndata.title,
          organization: ndata.organization_id,
          module: ndata.module,
          project: ndata.project,
          parent: ndata.parent,
          parent_form_identifier: ndata.parent_form_identifier,
          related: ndata.related,
          record_type: ndata.record_type,
          sub_record_type: ndata.sub_record_type,
          isac_simo: ndata.isac_simo,
          max_isacsimo: ndata.max_isacsimo,
          fulcrum_api_token: ndata.fulcrum_api_token || null,
          fulcrum_data_share_link: ndata.fulcrum_data_share_link || null,
          kobo_api_token: ndata.kobo_api_token || "",
          tags: ndata.tags || [],
          hide_in_dashboard: ndata.hide_in_dashboard ?? false,
          is_feedback_form: ndata.is_feedback_form ?? false,
          components_to_hide: ndata.components_to_hide ?? [],
          collect_app_accessible: ndata.collect_app_accessible ?? true,
          collect_app_guest_accessible:
            ndata.collect_app_guest_accessible ?? false,
          collect_app_guest_password: ndata.collect_app_guest_password ?? "",
          was_collect_app_guest_password_filled:
            ndata.collect_app_guest_password ? true : false,
        },
      });

      setLanguageData(ndata.translations || { en: generalEnglishLanguageData });

      if (history.location.state === undefined) {
        let component = JSON.parse(ndata.component);

        setType({ display: ndata.type || ndata.display || "wizard" });
        FormioForm(component);
        setcomponent(component);
      }
      //console.log(JSON.parse(ndata.component))
    }

    if (history.location.state !== undefined) {
      let { schema, type } = history.location.state;
      dispatch(
        alertActions.success(
          "Upload success. Please edit atleast one component before submit"
        )
      );
      FormioForm(schema);
      setcomponent(schema);
      if (type === "form") {
        setType({ display: "form" });
      }
    }

    // eslint-disable-next-line
  }, []);

  const getData = () => {
    apiService.getAll(`/formBuilderCreate`).then(
      (data) => {
        let final_library = data.libraries.map((data: any) => {
          return {
            key: data.slug,
            value: data.slug,
            text: data.name,
          };
        });
        let final_org = data.organizations.map((data: any) => {
          return {
            key: data.id,
            value: `${data.id}`,
            text: data.name,
          };
        });

        let final_modules = data.modules.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: `${data.id}`,
              text: data.name,
            };
          }
        );

        let final_projects = data.projects.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: `${data.id}`,
              text: data.name,
            };
          }
        );

        let final_forms = data.forms.map(
          (data: { name: string; slug: string }) => {
            return {
              key: data.slug,
              value: `${data.slug}`,
              text: data.name,
            };
          }
        );

        let final_tags = data.tags.map((data: string) => {
          return {
            key: data,
            value: `${data}`,
            text: data,
          };
        });

        setRecordTypeOptions(
          uniq(
            data.meta.map(
              (meta: { meta_record_type: string }) => meta.meta_record_type
            )
          ).map((record_type: any) => ({
            key: record_type,
            value: `${record_type}`,
            text: record_type,
          }))
        );

        setAllSubRecordTypes(data.meta); // cache all sub-record types (meta data i.e. is_meta=true forms)

        setFormOptions(final_forms);
        setAllFormOptions(data.forms);
        setModuleOptions(final_modules);
        setLibraryOptions(final_library);
        setOrganizationOptions(final_org);
        setProjectOptions(final_projects);
        SetTagOptions(final_tags);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const FormioForm = (component: object) => {
    try {
      Formio.builder(document.getElementById("builder"), component, {
        builder: {
          premium: false,

          custom: {
            title: "Custom Fields",
            weight: 10,
            components: {
              collapsible: {
                key: "collapsible",
                title: "Collapsible",
                icon: "list-alt",
                schema: {
                  label: "Collapsible",
                  key: "collapsible",
                  type: "collapsible",
                  input: false,
                  collapsible: true,
                  collapsed: true,
                },
              },
              draft: {
                key: "saveDraft",
                title: "Save as Draft",
                icon: "link",
                schema: {
                  label: "Save as Draft",
                  key: "saveDraft",
                  type: "button",
                  action: "saveState",
                  state: "draft",
                  input: true,
                },
              },
              image: {
                key: "image",
                title: "Image",
                icon: "image",
                schema: {
                  label: "Image",
                  key: "image",
                  type: "file",
                  storage: "url",
                  input: true,
                  image: true,
                  url: `${AppConfig.apiUrl}/fileUpload`,
                },
              },
              file: {
                key: "file",
                title: "File",
                icon: "file",
                schema: {
                  label: "File",
                  key: "file",
                  type: "file",
                  storage: "url",
                  input: true,

                  url: `${AppConfig.apiUrl}/fileUpload`,
                },
              },
            },
          },
          custom1: {
            title: "ISAC SIMO Fields",
            weight: 10,
            components: {
              isacsimoImg: {
                key: "isacsimoImg",
                title: "ISAC SIMO Image",
                icon: "image",
                schema: {
                  label: "ISAC SIMO Image",
                  key: "isacsimoImg",
                  type: "file",
                  storage: "url",
                  input: true,
                  image: true,
                  url: `${AppConfig.apiUrl}/fileUpload`,
                },
              },

              isacsimoCheck: {
                key: "isacsimoCheck",
                title: "ISAC SIMO Check",
                icon: "terminal",
                schema: {
                  label: "ISAC SIMO Check",
                  disabled: true,
                  tableView: true,
                  key: "isacsimoCheck",
                  customDefaultValue: "value='1'",
                  placeholder: "object type ID",
                  type: "textfield",
                  input: true,
                },
              },
              isacsimoResult: {
                key: "isacsimoResult",
                title: "ISAC SIMO Result",
                icon: "terminal",
                schema: {
                  label: "ISAC SIMO Result",
                  disabled: true,
                  tableView: true,
                  key: "isacsimoResult",
                  type: "textfield",
                  input: true,
                },
              },
            },
          },
          custom3: {
            title: "Metadata Fields",
            weight: 10,
            components: {
              _parent: {
                key: "_parent",
                title: "Parent",
                icon: "terminal",
                schema: {
                  label: "Parent",
                  key: "_parent",
                  type: "select",
                  input: true,
                  dataSrc: "resource",
                },
              },
              _mainLabel: {
                key: "_mainLabel",
                title: "Main Label",
                icon: "terminal",
                schema: {
                  label: "Main Label",
                  disabled: true,
                  tableView: true,
                  key: "_mainLabel",
                  type: "textfield",
                  input: true,
                },
              },
            },
          },
          layout: {
            components: {
              table: false,
              columns: false,
              panel: false,
              fieldset: true,
              well: false,
              tabs: false,
              collapsible: false,
              htmlelement: false,
              html: {
                key: "html",
                title: "HTML Element 📷",
                icon: "code",
                schema: {
                  label: "HTML",
                  tag: "div",
                  className:
                    "d-flex justify-content-center align-items-center flex-column",
                  attrs: [
                    {
                      attr: "alt",
                      value: "some content",
                    },
                  ],
                  content:
                    '<img src="https://i.imgur.com/ulM8PKF.png" width="40%" height="auto"/>\n<small>Adding Images using links. For loading the form much faster.</small>',
                  refreshOnChange: false,
                  type: "htmlelement",
                  input: false,
                  tableView: false,
                },
              },
            },
          },
          advanced: {
            components: {
              survey: false,
              //form: true,
            },
          },
          data: {
            components: {
              datamap: false,
              tree: false,
              container: false,
              editgrid: false,
            },
          },
        },
      }).then(function (builder: any) {
        setcomponent(builder.schema); // SET COMPONENT ON FIRST RENDERED STAGE

        builder.on("saveComponent", function () {
          setcomponent(builder.schema); // After Save Fields
        });

        // deleteComponent is not being triggered at all 😢
        builder.on("deleteComponent", function () {
          // console.log(builder.schema);
          // CHECK IF metadata page or fieldset exists or not
          let hasMetaData = builder?.schema?.components.find(
            (cmp: any) => cmp.key === "metadata"
          );

          // IF metadata page or fieldset does not exists (probably deleted). Clear the input or record_type and sub.
          if (!hasMetaData) {
            setData({
              newData: Object.assign({}, fdata.newData, {
                record_type: null,
                sub_record_type: null,
              }),
            });
          }

          setcomponent(builder.schema); // After Delete Fields
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddItem = (event: any, data: any) => {
    // console.log({data})
    SetTagOptions([
      ...tagOptions,
      { key: data.value, text: data.value, value: data.value },
    ]);
    // emailOptions.push({ key: data.value, text: data.value, value: data.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.type !== "Edit") {
      dispatch(
        addFormbuilder({
          ...fdata.newData,
          type: type.display,
          component: JSON.stringify(component),
          translations: JSON.stringify(languageData),
        })
      );
      clearForm();
    } else
      dispatch(
        editFormbuilder(
          {
            ...fdata.newData,
            type: type.display,
            component: JSON.stringify(component),
            translations: JSON.stringify(languageData),
          },
          props.slug
        )
      );
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        title: "",
        organization: 0,
        library: "",
        module: 0,
        project: 0,
        parent: "",
        parent_form_identifier: "",
        record_type: "",
        sub_record_type: "",
        max_isacsimo: 0,
        isac_simo: false,
        components_to_hide: [],
        collect_app_accessible: true,
        collect_app_guest_accessible: false,
      },
    });
    setType({ display: "wizard" });
    setcomponent("");
    FormioForm({ display: "wizard" });
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result?: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    if (name === "project" || name === "organization") {
      let organization_id =
        name === "organization" ? value : fdata.newData.organization;
      let project_id = name === "project" ? value : fdata.newData.project;

      let filteredFormOptions: any = allFormOption;

      if (project_id) {
        filteredFormOptions = filteredFormOptions.filter(
          (f: any) => parseInt(f.project) === parseInt(project_id)
        );
      }

      if (organization_id) {
        filteredFormOptions = filteredFormOptions.filter(
          (f: any) => parseInt(f.organization_id) === parseInt(organization_id)
        );
      }

      // Convert to Options json
      filteredFormOptions = filteredFormOptions.map(
        (data: { name: string; slug: string }) => {
          return {
            key: data.slug,
            value: `${data.slug}`,
            text: data.name,
          };
        }
      );

      // Re-filter the forms dropdown
      setFormOptions(filteredFormOptions);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handletypeChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    // console.log(component)
    FormioForm({ display: result.value });
    setType({ display: result.value });

    setData({
      newData: Object.assign({}, fdata.newData, {
        record_type: null,
        sub_record_type: null,
      }),
    });
  };

  const handleMetadataChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    setData({
      newData: Object.assign({}, fdata.newData, {
        record_type: value,
      }),
    });

    setFilteredSubRecordTypeOptions(
      allSubRecordTypes
        .filter(
          (sub_record_type: any) => sub_record_type.meta_record_type === value
        ) // Filter by record_type
        .map((sub_record_type: any) => ({
          key: sub_record_type.slug,
          value: `${sub_record_type.slug}`,
          text: sub_record_type.name,
        }))
    );
  };

  const handleSubMetadataChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    // return alert(JSON.stringify(result))
    let metaComponents: object[] = [];

    let slug = result.value;

    setData({
      newData: Object.assign({}, fdata.newData, {
        sub_record_type: slug, // slug of is_meta=true form
        sub_record_type_name:
          result.options.find((o: any) => o.value === slug)?.text || "N/A", // name
      }),
    });

    let currentComponent = component;

    // If Current Component State is empty (empty form-builder), set components blank manually.
    if (!currentComponent || !currentComponent["components"]) {
      currentComponent = { components: [], display: type.display };
    }

    setIsMetaFormSchemaLoading(true);

    apiService.getAll(`/form/${slug}?is_meta=true`).then(
      (data) => {
        // Load Meta Data Form Component of is_meta=true form via API
        if (data.components !== undefined) {
          metaComponents = data.components.filter(
            (field: any) => field.key !== "submit" && field.type !== "button"
          ); // Get Meta Form Components (Remove Submit or Buttons if exists)
        }

        // Try to search for metadata key in components (to check if already exists before)
        let result = currentComponent["components"].filter(
          (cmp: any) => cmp.key === "metadata"
        );

        // If already exists Remove old Metadata key (as we will set this api fetched new one)
        if (result.length !== 0) {
          currentComponent["components"] = currentComponent[
            "components"
          ].filter((cmp: any) => cmp.key !== "metadata");
        }

        // For Wizard Type
        if (type.display === "wizard") {
          currentComponent["components"].unshift({
            title: "MetaData",
            label: "MetaData",
            type: "panel",
            key: "metadata",
            components: metaComponents,
          });
        }
        // For Simple Form
        else {
          currentComponent["components"].unshift({
            legend: "MetaData",
            tableView: false,
            key: "metadata",
            type: "fieldset",
            label: "",
            input: false,
            components: metaComponents,
          });
        }

        if (typeof currentComponent !== "string") {
          FormioForm(currentComponent);
        }

        setIsMetaFormSchemaLoading(false);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        setIsMetaFormSchemaLoading(false);
      }
    );
  };

  const handleLibraryChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    let name = result.name;
    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
    if (value !== "") {
      apiService.getAll(`/formBuilder/edit/${value}`).then(
        (data) => {
          var ndata = data.form;
          let component = JSON.parse(ndata.component);
          setType(component);
          FormioForm(component);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
    }
  };

  const formOptions = [
    { key: "form", value: "form", text: "Form" },
    { key: "wizard", value: "wizard", text: "Wizard" },
  ];

  return (
    <div>
      <div key={1}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to="/formbuilder">
            Formbuilder
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            Formbuilder {props.type}
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div key={2}>
        <Form onSubmit={handleSubmit}>
          <div className="app-card-org">
            <Form.Group widths={3}>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Form Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter  name..."
              />

              <Form.Input
                fluid
                required
                type="text"
                name="title"
                label="Description"
                value={fdata.newData.title}
                onChange={handleChange}
                placeholder="Enter  Description..."
              />
              <Form.Field>
                <Form.Select
                  required
                  fluid
                  label="Organization"
                  placeholder="Select your Organization"
                  options={organizationOptions}
                  value={fdata.newData.organization}
                  name="organization"
                  onChange={handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={3}>
              <Form.Field>
                <Form.Select
                  fluid
                  placeholder="Select Module"
                  required
                  search
                  clearable
                  selection
                  options={moduleOptions}
                  label="Module"
                  value={fdata.newData.module}
                  name="module"
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <Form.Select
                  fluid
                  placeholder="Select Project"
                  required
                  search
                  clearable
                  selection
                  options={projectOptions}
                  label="Project"
                  value={fdata.newData.project}
                  name="project"
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Form type</label>
                <Select
                  required
                  fluid
                  placeholder="Select Form Type"
                  options={formOptions}
                  label="Form type"
                  value={type.display}
                  name="type"
                  onChange={handletypeChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={3}>
              <Form.Field>
                <label>Related Form(Step Form)</label>
                <Select
                  fluid
                  clearable
                  placeholder="Select Related Form"
                  options={formOption}
                  label="Related Form"
                  value={fdata.newData.related}
                  name="related"
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <label>Parent Form</label>
                <Select
                  fluid
                  clearable
                  placeholder="Select Parent Form"
                  options={formOption}
                  label="Parent Form"
                  value={fdata.newData.parent}
                  name="parent"
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  clearable
                  placeholder="e.g. homeowner_id"
                  value={fdata.newData.parent_form_identifier}
                  label="Parent Form Identifier Key"
                  name="parent_form_identifier"
                  onChange={handleChange}
                  disabled={!fdata.newData.parent}
                  required={fdata.newData.parent}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={3}>
              <Form.Field>
                <Form.Select
                  fluid
                  search
                  clearable
                  label="Library"
                  placeholder="Select Library"
                  options={libraryOptions}
                  value={fdata.newData.library}
                  name="library"
                  onChange={handleLibraryChange}
                />
              </Form.Field>

              <Form.Field>
                <label>Tags</label>
                <Dropdown
                  search
                  selection
                  multiple
                  value={fdata.newData.tags}
                  name="tags"
                  onChange={handleChange}
                  allowAdditions
                  onAddItem={handleAddItem}
                  options={tagOptions}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={3}>
              <Form.Field>
                <label>Record Type</label>
                <Select
                  fluid
                  placeholder="Select Record Type"
                  options={recordTypeOptions}
                  label="Record Type"
                  value={fdata.newData.record_type}
                  name="metadata"
                  onChange={handleMetadataChange}
                />

                <p>
                  <small>
                    <b>
                      <Link to="/formbuilder/meta">
                        Click Here to Add or Manage Meta Data Fields (Record
                        Types)
                      </Link>
                    </b>
                  </small>
                </p>
              </Form.Field>

              <Form.Field>
                <label>Sub-Record Type</label>
                <Select
                  fluid
                  placeholder="Select Sub-Record Type"
                  options={filteredSubRecordTypeOptions}
                  label="Sub-Record Type"
                  value={fdata.newData.sub_record_type}
                  name="submetadata"
                  onChange={handleSubMetadataChange}
                  disabled={filteredSubRecordTypeOptions.length === 0}
                  loading={isMetaFormSchemaLoading}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={3}>
              <Form.Field>
                <label>ISAC SIMO</label>
                <Form.Checkbox
                  toggle
                  name="isac_simo"
                  checked={fdata.newData.isac_simo}
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Input
                fluid
                disabled={fdata.newData.isac_simo === false}
                type="number"
                name="max_isacsimo"
                label="Max api name no"
                value={fdata.newData.max_isacsimo}
                onChange={handleChange}
                placeholder="Enter  Max api name no..."
              />
            </Form.Group>
            {props.data && props.data.form && props.data.form.fulcrum_id ? (
              <>
                <Label
                  style={{
                    backgroundColor: "var(--color-red)",
                    color: "white",
                  }}
                  tag
                  size={"tiny"}
                >
                  {"Imported From Fulcrum"}
                </Label>
                <br />
                <Form.Group widths={3}>
                  <Form.Field>
                    <Form.Input
                      fluid
                      clearable
                      placeholder="Fulcrum Data Share URL (.json)"
                      label="Fulcrum Data Share URL"
                      value={fdata.newData.fulcrum_data_share_link}
                      name="fulcrum_data_share_link"
                      onChange={handleChange}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      fluid
                      clearable
                      placeholder="Enter your updated Fulcrum API Token"
                      label="Fulcrum API Token"
                      value={fdata.newData.fulcrum_api_token}
                      name="fulcrum_api_token"
                      onChange={handleChange}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {props.data && props.data.form && props.data.form.kobo_id ? (
              <>
                <br />
                <Label
                  style={{ backgroundColor: "var(--cyan)", color: "white" }}
                  tag
                  size={"tiny"}
                >
                  {"Imported From KoboToolbox"}
                </Label>
                <br />
                <Form.Group widths={3}>
                  <Form.Field>
                    <Form.Input
                      fluid
                      clearable
                      placeholder="・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・・"
                      label="KoboToolbox API Token"
                      value={fdata.newData.kobo_api_token}
                      name="kobo_api_token"
                      onChange={handleChange}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <div>
              <Label color="blue" ribbon style={{ marginBottom: "8px" }}>
                Additional Options
              </Label>

              <Form.Group widths={5}>
                <Form.Field>
                  <label title="e.g. RHIAB Dashboard list">
                    Hide in Dashboard ?
                  </label>
                  <Form.Checkbox
                    toggle
                    name="hide_in_dashboard"
                    checked={fdata.newData.hide_in_dashboard}
                    onChange={handleChange}
                  />
                </Form.Field>

                <Form.Field>
                  <label title="e.g. RHIAB Feedback Form">
                    Is a Feedback Form ?
                  </label>
                  <Form.Checkbox
                    toggle
                    name="is_feedback_form"
                    checked={fdata.newData.is_feedback_form}
                    onChange={handleChange}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Collect App Accessible</label>
                  <Form.Checkbox
                    toggle
                    name="collect_app_accessible"
                    checked={fdata.newData.collect_app_accessible}
                    onChange={handleChange}
                  />
                  <small
                    style={{
                      marginTop: "-7px",
                      display: "block",
                      opacity: "0.7",
                    }}
                  >
                    Visible in Data Collector App (<b>Auth</b> Version)?
                  </small>
                </Form.Field>

                <Form.Field>
                  <label title="e.g. in Data Collect Mobile App">
                    Collect App Guest Accessible
                  </label>
                  <Form.Checkbox
                    toggle
                    name="collect_app_guest_accessible"
                    checked={fdata.newData.collect_app_guest_accessible}
                    onChange={handleChange}
                  />
                  <small
                    style={{
                      marginTop: "-7px",
                      display: "block",
                      opacity: "0.7",
                    }}
                  >
                    Visible in Data Collector App (<b>Guest</b> Version)?
                  </small>
                </Form.Field>

                <Form.Field width={5}>
                  <label title="Either Fulcrum fields data_name or BCTAP fields key name">
                    Components to Hide
                  </label>

                  <Form.Dropdown
                    options={[...(fdata.newData?.components_to_hide ?? [])].map(
                      (s) => ({
                        value: s.trim(),
                        key: s.trim(),
                        text: s.trim(),
                      })
                    )}
                    name="components_to_hide"
                    placeholder="Provide Component Keys to hide..."
                    search
                    selection
                    fluid
                    multiple
                    allowAdditions
                    value={fdata.newData?.components_to_hide}
                    onChange={handleChange}
                  />
                </Form.Field>
              </Form.Group>

              {fdata.newData.collect_app_guest_accessible === true ? (
                <Form.Group widths={2}>
                  <Form.Field>
                    <label title="Password is asked before showing the form in data collect guest application.">
                      Lock Screen Password for Collect App Guest (Leave empty to
                      not lock this form)
                    </label>
                    <Form.Input
                      fluid
                      clearable
                      placeholder="* * * * * * * *"
                      value={fdata.newData.collect_app_guest_password}
                      name="collect_app_guest_password"
                      onChange={handleChange}
                    />

                    {fdata.newData.was_collect_app_guest_password_filled ===
                    true ? (
                      <small
                        style={{
                          display: "block",
                          color: "green",
                          fontWeight: "bold",
                        }}
                      >
                        [ LOCK SCREEN PASSWORD EXISTS ✔ ]
                      </small>
                    ) : (
                      ""
                    )}
                  </Form.Field>
                </Form.Group>
              ) : (
                ""
              )}
            </div>

            <Button type="submit" className="createButton">
              Save Form
            </Button>
          </div>
          <br />

          <div className="app-card-org">
            <div id="builder"></div>
          </div>
        </Form>

        <div className="app-card-org">
          <Button onClick={() => setLanguageDataModalOpen(true)}>
            Manage Translations
          </Button>

          <ul style={{ marginTop: "8px" }}>
            <li>
              - <b>Languages: </b> {Object.keys(languageData).length} (
              {Object.keys(languageData).join(", ")})
            </li>
            <li>
              - <b>Total Translations: </b>{" "}
              {Object.values(languageData).reduce((a: number, b: object) => {
                return a + Object.keys(b).length;
              }, 0)}
            </li>
          </ul>
        </div>
      </div>

      {languageDataModalOpen && (
        <FormBuilderTranslate
          languageData={languageData}
          setLanguageData={setLanguageData}
          component={component}
          setLanguageDataModalOpen={setLanguageDataModalOpen}
        />
      )}
    </div>
  );
}
