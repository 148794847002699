import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const materialCategoryTypes = {
  MATERIAL_CATEGORY_LOADED: "MATERIAL_CATEGORY_LOADED",
  MATERIAL_CATEGORY_UNLOADED: "MATERIAL_CATEGORY_UNLOADED",
  ADD_MATERIAL_CATEGORY: "ADD_MATERIAL_CATEGORY",
  EDIT_MATERIAL_CATEGORY: "EDIT_MATERIAL_CATEGORY",
  DELETE_MATERIAL_CATEGORY: "DELETE_MATERIAL_CATEGORY",
};

export const loadMaterialCategoryPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: materialCategoryTypes.MATERIAL_CATEGORY_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadMaterialCategoryPage = () => {
  return {
    type: materialCategoryTypes.MATERIAL_CATEGORY_UNLOADED,
    payload: {},
  };
};

export const addMaterialCategory = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/material_category", payload).then(
      (data) => {
        dispatch({
          type: materialCategoryTypes.ADD_MATERIAL_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editMaterialCategory = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/material_category/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: materialCategoryTypes.EDIT_MATERIAL_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeMaterialCategory = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/material_category/delete/${id}`).then(
      (data) => {
        dispatch({
          type: materialCategoryTypes.DELETE_MATERIAL_CATEGORY,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
