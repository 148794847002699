import { alertActions } from "./alertAction";
import { apiService } from "../../services";

export const formbuilderMetaTypes = {
  ADD_FORMBUILDER_META: "ADD_FORMBUILDER_META",
  EDIT_FORMBUILDER_META: "EDIT_FORMBUILDER_META",
  DELETE_FORMBUILDER_META: "DELETE_FORMBUILDER_META",
  FETCH_FORMBUILDER_META: "FETCH_FORMBUILDER_META",
  FETCH_FORMBUILDER_DETAIL_META: "FETCH_FORMBUILDER_DETAIL_META",
  FORMBUILDER_PAGE_LOADED_META: "FORMBUILDER_PAGE_LOADED_META",
  FORMBUILDER_PAGE_UNLOADED_META: "FORMBUILDER_PAGE_UNLOADED_META",
};

export const loadFormbuilderPageMeta = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: formbuilderMetaTypes.FORMBUILDER_PAGE_LOADED_META,
          payload: data,
        });

        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));

        onFinally();
      }
    );
  };
};

export const fetchFormbuilderMeta = (slug: string) => {
  return async (dispatch: any) => {
    apiService.getAll(`/formBuilder/meta/edit/${slug}`).then(
      (data) => {
        dispatch({
          type: formbuilderMetaTypes.FETCH_FORMBUILDER_META,
          payload: data,
        });
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadFormbuilderPageMeta = () => {
  return {
    type: formbuilderMetaTypes.FORMBUILDER_PAGE_UNLOADED_META,
    payload: {},
  };
};

export const editFormbuilderMeta = (payload: object, slug?: string) => {
  return async (dispatch: any) => {
    apiService.save1(`/formBuilder/meta/update/${slug}`, payload).then(
      (data) => {
        dispatch({
          type: formbuilderMetaTypes.EDIT_FORMBUILDER_META,
          payload: data,
        });
        dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const addFormbuilderMeta = (payload: object) => {
  return async (dispatch: any) => {
    apiService.save1("/formBuilder/meta/store", payload).then(
      (data) => {
        dispatch({
          type: formbuilderMetaTypes.ADD_FORMBUILDER_META,
          payload: data,
        });
        dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
export const removeFormbuilderMeta = (
  slug: string,
  onSuccess: Function,
  onError: Function
) => {
  return async (dispatch: any) => {
    apiService._delete(`/formBuilder/meta/delete/${slug}`).then(
      (data) => {
        dispatch({
          type: formbuilderMetaTypes.DELETE_FORMBUILDER_META,
        });
        onSuccess();
        //dispatch(alertActions.success('Data Deleted successfully'))
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
