import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Segment,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadStrengthenHousePartPage,
  unloadStrengthenHousePartPage,
  addStrengthenHousePart,
  editStrengthenHousePart,
  removeStrengthenHousePart,
} from "../../../redux/action/strengthenHousePartAction";

import { Link } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../../redux/action/alertAction";
interface RootState {
  strengthen_house_parts: {
    strengthen_house_parts: {
      data: {
        id: number;
        title: string;
        title_lang: string;
        order: number;
        tile_id: number;
        app_id: number;
        awareness_house_category_id: number;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
    tiles: {
      id: number;
      name: string;
      awareness_module_id: number;
    }[];
    awareness_modules: {
      id: number;
      name: string;
      app_id: number;
    }[];
    awareness_house_categories: {
      id: number;
      title: string;
      title_lang: string;
      app: { app_name: string };
    }[];
  };
}

type houseCategoryOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function StrengthenHousePart() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      title: "",
      title_lang: "",
      order: 0,
      awareness_house_category_id: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/strengthen_house_part");
  const [search, setSearch] = useState<string>("");
  const strengthen_house_parts = useSelector(
    (state: RootState) => state.strengthen_house_parts
  );
  const [total, setTotal] = useState(1);
  const [houseCategoryOptions, setHouseCategoryOption] =
    useState<houseCategoryOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  //const [inputList, setInputList] = useState([{ id: 0, category_id: 0 }])

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadStrengthenHousePartPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadStrengthenHousePartPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (strengthen_house_parts.strengthen_house_parts !== undefined) {
      setTotal(strengthen_house_parts.strengthen_house_parts.last_page);
      var final_stage = strengthen_house_parts.awareness_house_categories.map(
        (data) => {
          return {
            key: data.id,
            value: data.id,
            text:
              data.app.app_name + "/" + data.title + " / " + data.title_lang,
          };
        }
      );

      setHouseCategoryOption(final_stage);
    }
  }, [strengthen_house_parts]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl(
      "/strengthen_house_part?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/strengthen_house_part?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        title: "",
        title_lang: "",
        order: 0,
        awareness_house_category_id: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let strengthen_house_part =
      strengthen_house_parts.strengthen_house_parts.data.find(
        (v) => v.id === id
      );
    setData({
      newData: {
        id: strengthen_house_part!.id,
        title: strengthen_house_part!.title,
        title_lang: strengthen_house_part!.title_lang,
        order: strengthen_house_part!.order,
        awareness_house_category_id:
          strengthen_house_part!.awareness_house_category_id,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addStrengthenHousePart(fdata.newData, () => {
      dispatch(loadStrengthenHousePartPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(editStrengthenHousePart(fdata.newData, () => {
      dispatch(loadStrengthenHousePartPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeStrengthenHousePart(deleteId, () => {
      dispatch(loadStrengthenHousePartPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));      
  };

  function RenderList(datas: any) {
    if (datas.data.strengthen_house_parts === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.strengthen_house_parts.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.strengthen_house_parts.data.map((data: any) => {
      let house_category = datas.data.awareness_house_categories.find(
        (a: any) => a.id === data.awareness_house_category_id
      );

      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.title}</Table.Cell>
          <Table.Cell>{data.title_lang}</Table.Cell>
          <Table.Cell>{data.order}</Table.Cell>
          <Table.Cell>
            {house_category
              ? house_category.title + " / " + house_category.title_lang
              : ""}
          </Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Strengthen My House</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>House Part</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create House Part
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        House Parts
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-2"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Title Lang.</Table.HeaderCell>
            <Table.HeaderCell>Order</Table.HeaderCell>
            <Table.HeaderCell>House Made Off</Table.HeaderCell>
            {/* <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>Awareness Module</Table.HeaderCell>
            <Table.HeaderCell>Tile</Table.HeaderCell> */}

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {strengthen_house_parts && (
            <RenderList data={strengthen_house_parts} />
          )}
        </Table.Body>
      </Table>

      {strengthen_house_parts.strengthen_house_parts === undefined || loading  ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="small"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} House Part
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="title"
                label="Title"
                value={fdata.newData.title}
                onChange={handleChange}
                placeholder="Title"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="title_lang"
                label="Title (In Preferred Language)"
                value={fdata.newData.title_lang}
                onChange={handleChange}
                placeholder="Title (In Preferred Language)"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select House Made Off Item"
                required
                options={houseCategoryOptions}
                label="House Made Off"
                value={fdata.newData.awareness_house_category_id}
                name="awareness_house_category_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="order"
                label="Order"
                value={fdata.newData.order}
                onChange={handleChange}
                placeholder="Order"
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
