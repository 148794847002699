import { alertActions } from './alertAction';
import { apiService } from '../../services';
export const userTypes = {
   
    USER_PAGE_LOADED: "USER_PAGE_LOADED",
    USER_PAGE_UNLOADED: "USER_PAGE_UNLOADED",
    ADD_USER:"ADD_USER",
    EDIT_USER:"EDIT_USER",
    DELETE_USER:"DELETE_USER",


}

export const loadUserPage =  (url:string, onFinally: Function) => {
    
    return async (dispatch:any) =>{

        apiService.getAll(url)
        .then(
            data => { 
               
                dispatch({
                    type: userTypes.USER_PAGE_LOADED,
                    payload: data
                })
                onFinally();
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
                onFinally();
            }
        );
        

    };
}



export const unloadUserPage = () => {
    return {
        type: userTypes.USER_PAGE_UNLOADED,
        payload: {}

    };
}

export const addUser = (payload:object, onSuccess: Function, onError: Function) => {
    

    return async (dispatch:any) =>{

        apiService.save('/users',payload)
        .then(
            data => { 
               
                dispatch({
                    type: userTypes.ADD_USER,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data added successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };

   
}
interface payloadProps {
    id: number,
   // [all:any]:any
}
export const editUser = (payload:payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch:any) =>{

        apiService.update(`/users/update/${payload.id}`,payload)
        .then(
            data => { 
               
                dispatch({
                    type: userTypes.EDIT_USER,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data Edited successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
}



  export const removeUser= (id:number, onSuccess: Function, onError: Function) =>  {
   
    return async (dispatch:any) =>{

        apiService._delete(`/users/delete/${id}`)
        .then(
            data => { 
               
                dispatch({
                    type: userTypes.DELETE_USER,
                
                })
                onSuccess();
                // dispatch(alertActions.success('Data Deleted successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
  }

  

