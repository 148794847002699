import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchMenu } from "../../redux/action/dashboardaction";
import {
  Grid,
  Card,
  Label,
  Header,
  Icon,
  GridColumn,
  Image,
  Button,
  Statistic,
  Modal,
  Dropdown,
  Radio,
  Form,
  Input,
  Progress,
} from "semantic-ui-react";

//import NumberCard from "../../components/layout/DashboardCard";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Link } from "react-router-dom";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
//import colombiaMap from "../../assets/images/colombia-map.png";
//import unops from "../../assets/images/unops.png";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MarkerPopup from "../../components/Modal/MarkerPopup";
import defaultImg from "../../assets/images/image.png";
type OptionType = {
  key: string | number;
  value: number;
  text: string;
}[];
interface fdataType {
  newData: {
    program: string | number;
    project: string | number;
    org: string | number;
  };
}

interface cdataType {
  newData: {
    flag: string;
    population: string | number;
    area: string | number;
    gini: string | number;
  };
}

export default function Dashboard() {
  const dashboard = useSelector((state: any) => state.dashboard);
  const [fdata, setData] = useState<fdataType>({
    newData: {
      program: "",
      project: "",
      org: "",
    },
  });

  const [cdata, setCData] = useState<cdataType>({
    newData: {
      flag: "",
      population: "",
      area: "",
      gini: "",
    },
  });
  const { dashboard_info } = dashboard;
  const dispatch = useDispatch();
  const [openInfo, setOpenInfo]: any = useState(false);
  const [filterToggle, setFilterToggle]: any = useState(false);
  const [demographicToggle, setDemographicToggle]: any = useState(false);
  const [metaDataField, setMetaDataField] = useState([]);
  const [programOptions, setProgramOptions] = useState<OptionType>([]);
  const [orgOptions, setOrgOptions] = useState<OptionType>([]);
  const [projectOptions, setProjectOptions] = useState<OptionType>([]);
  const [formOptions, setFormOptions] = useState<OptionType>([]);
  const [form, setForm] = useState("");
  const [project, setProject] = useState("");
  const [org, setOrg] = useState("");
  const [country, setCountry] = useState("");

  const [latitude, setLatitude] = useState(27.7047954);
  const [longitude, setLongitude] = useState(85.3042305);
  const [dataId, setDataId] = useState("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [metadatafilterToggle, setMetadataFilterToggle] = useState(false);

  const [status, setStatus] = useState("all");
  const [statusOptions, setStatusOptions] = useState([]);
  const [record, setRecord] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState<any>(new Date("2023-01-01"));
  const [endDate, setEndDate] = useState<any>(new Date("2023-01-02"));

  const [orgImg, setOrgImg] = useState(defaultImg);

  useEffect(() => {
    dispatch(fetchMenu());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (country !== "") {
      let f_country = country.toLowerCase();

      fetch("https://restcountries.com/v3.1/name/" + f_country)
        .then((response) => response.json())

        .then(
          (data) => {
            if (data.length > 0 && data[0] !== undefined) {
              setLatitude(data[0].latlng[0] || 0);
              setLongitude(data[0].latlng[1] || 0);

              setCData({
                newData: Object.assign({}, cdata.newData, {
                  flag: data[0].flags.svg || "",
                  population: data[0].population || "",
                  area: data[0].area || "",
                  gini: Object.values(data[0].gini)?.[0] || "",
                }),
              });
            }
          },

          (error) => {}
        );
    }

    // eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    if (dashboard_info !== undefined) {
      if (dashboard_info.country !== undefined) {
        var final_country = dashboard_info.country.map(
          (data: { id: number; country_name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.country_name,
            };
          }
        );
        setProgramOptions(final_country);
      }

      if (dashboard_info.organizations !== undefined) {
        var final_organizations = dashboard_info.organizations.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );
        setOrgOptions(final_organizations);
      }

      if (dashboard_info.projects !== undefined) {
        var final_projects = dashboard_info.projects.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );
        setProjectOptions(final_projects);
      }

      if (dashboard_info.form !== undefined) {
        var final_form = dashboard_info.form.map(
          (data: { slug: string; name: string }) => {
            return {
              key: data.slug,
              value: data.slug,
              text: data.name,
            };
          }
        );
        setFormOptions(final_form);
      }
    }
    // eslint-disable-next-line
  }, [dashboard_info]);

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const handleDemographicToggle = () => {
    setDemographicToggle(!demographicToggle);
  };

  const handleChangeProgram = (e: any, target: any) => {
    let value = target.value;
    let name = target.name;
    setDemographicToggle(true);
    if (name === "form") {
      setForm(value);
      GetReport(value, status, record, startDate, endDate);
    } else {
      setData({
        newData: Object.assign({}, fdata.newData, {
          [name]: value,
        }),
      });
      apiService.getAll(`/GetData/${name}/${value}`).then(
        (data) => {
          var final = data.map(
            (data: { id?: number; name: string; slug?: string }) => {
              return {
                key: data.id || data.slug,
                value: data.id || data.slug,
                text: data.name,
              };
            }
          );
          if (name === "program") {
            let c1 = programOptions.find(
              (element: any) => element.value === value
            );
            setCountry(c1 !== undefined ? c1.text : "");
            setOrgOptions(final);
          } else if (name === "org") {
            let c2 = orgOptions.find((element: any) => element.value === value);
            setOrg(c2 !== undefined ? c2.text : "");
            setProjectOptions(final);
          } else if (name === "project") {
            let c3 = projectOptions.find(
              (element: any) => element.value === value
            );
            setProject(c3 !== undefined ? c3.text : "");
            setFormOptions(final);
          }
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
    }
  };

  const GetReport = (
    data: string,
    status: string,
    record: string,
    sDate: Date,
    eDate: Date,
    metadata?: any
  ) => {
    if (data !== "") {
      if (metadata === undefined) {
        metadata = {};
      }
      apiService
        .save2(
          `/GetReport/${data}?status=${status}&filter=${record}&sdate=${sDate.toISOString()}&edate=${eDate.toISOString()}`,
          metadata
        )
        .then(
          (data) => {
            if (data.status !== "undefined" && data.status) {
              setStatusOptions(data.status);
            }
            if (data.data !== "undefined" && data.data) {
              setFilteredData(data.data);

              // TRY TO CENTER THE MAP TO FIRST MARKER WITH CORRECT LOCATION
              if (data.data.length > 0) {
                for (let i = 0; i < data.data.length; i++) {
                  if (
                    data.data[i]._latitude &&
                    data.data[i]._longitude &&
                    data.data[i]._latitude !== "-" &&
                    data.data[i]._longitude !== "-"
                  ) {
                    setLatitude(data.data[i]._latitude || 0);
                    setLongitude(data.data[i]._longitude || 0);
                    break;
                  } else if (i === data.data.length - 1) {
                    setLatitude(0);
                    setLongitude(0);
                  }
                }
              }
            }

            if (data.metadata !== "undefined" && data.metadata) {
              setMetaDataField(data.metadata);
            }
            let pid = fdata.newData.project;
            if (data.project !== "undefined" && data.project) {
              setProject(data?.project?.name || "");
              pid = data?.project?.id || "";
            }
            if (
              data.organization !== "undefined" &&
              data.organization !== undefined &&
              data.organization
            ) {
              setOrg(data?.organization?.name || "");
              setOrgImg(data?.organization?.image || defaultImg);
              setData({
                newData: Object.assign({}, fdata.newData, {
                  org: data?.organization?.id || "",
                  program: data?.organization?.country_id || "",
                  project: pid,
                }),
              });
            }
            if (data.country !== "undefined" && data.country) {
              setCountry(data?.country?.country_name || "");
            }
          },

          (error) => {
            dispatch(alertActions.error(error.toString()));
          }
        );
    }
  };

  const handleFilterChange = (e: any, target: any): any => {
    let name = target.name;
    if (name === "record") {
      setRecord(target.value);
      GetReport(form, status, target.value, startDate, endDate);
    } else {
      setStatus(target.value);
      GetReport(form, target.value, record, startDate, endDate);
    }
  };
  const submitMetadataFilter = (e: any) => {
    e.preventDefault();

    const data = new FormData(e.target);

    GetReport(form, status, record, startDate, endDate, data);
    setMetadataFilterToggle(false);
  };

  const setDate = (date: any, type: string) => {
    if (type === "start") {
      setStartDate(date);
      GetReport(form, status, record, date, endDate);
    } else {
      setEndDate(date);
      GetReport(form, status, record, startDate, date);
    }
  };

  const handleDownloadData = () => {
    if (form !== "") {
      apiService
        .getfile(
          `/formBuilder/exportAll/${form}/xls?status=${status}&filter=${record}&sdate=${startDate.toISOString()}&edate=${endDate.toISOString()}`
        )
        .then(
          (data) => {
            setTimeout(() => {
              window.open(data);
            }, 100);
          },
          (error) => {
            dispatch(alertActions.error(error.toString()));
          }
        );
    }
  };

  const GetOrganizationCard = ({ data }: any) => {
    return data.map(
      (
        item: { id: number; name: string; country: { country_name: string } },
        index: number
      ) => (
        <div key={item.id} className="mx-auto p-3 app-card-org d-org-card">
          <div>
            <p>{item.name}</p>
          </div>
          <div style={{ fontSize: "12px", color: "gray" }}>
            <Icon name="map marker alternate" />{" "}
            {item.country && item.country.country_name}
          </div>
        </div>
      )
    );
  };

  const OpenModal = ({ item }: any) => {
    return (
      <Modal
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        onOpen={() => setOpenInfo(item)}
        size="tiny"
        style={{ position: "relative" }}
      >
        <Header icon="info" content={item.app_name} />
        <Modal.Content>
          <p>
            <small>
              <b>Unique ID:</b> {item.unique_app_id}
            </small>
          </p>
          <p>
            <b>Total Modules:</b> {item.awareness_module_count}
          </p>
          <p>
            <b>Total Tiles:</b> {item.TilesCount}
          </p>
          <p>
            <b>Total Downloads:</b> {item.TotalDownloads}
          </p>
        </Modal.Content>
      </Modal>
    );
  };

  const GetAppCard = ({ data }: any) => {
    return data.map((item: any, index: number) => (
      <div className="mx-auto m-2 app-card-org d-app-wrapper" key={index}>
        <div className="d-app-header">
          <p> {item.app_name}</p>
          <Image avatar src={item.image ? item.image : defaultImg} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            color: "gray",
            textAlign: "center",
          }}
        >
          <div>
            <p>{item.awareness_module_count}</p>
            <p>Modules</p>
          </div>
          <div>
            <p>{item.TilesCount}</p>
            <p>Tiles</p>
          </div>
          <div>
            <p>{item.TotalDownloads}</p>
            <p>Downloads</p>
          </div>
        </div>
        <div className="d-app-buttons">
          <Button>Stats</Button>
          <Button onClick={() => setOpenInfo(item)}>Info</Button>
        </div>
      </div>
    ));
  };

  return (
    <React.Fragment>
      <h5>
        <span className="color-blue">Admin</span>{" "}
        <span className="color-green">Dashboard</span>
      </h5>
      <MarkerPopup
        openModal={openModal}
        formId={form}
        dataId={dataId}
        setOpenModal={setOpenModal}
      />

      <div className="filters" style={{ zIndex: 9 }}>
        <div className="filter-effect" onClick={() => handleFilterToggle()}>
          <Icon name="filter" className="center-tiny-icons" />
        </div>
        <div>
          <Dropdown
            clearable
            fluid
            search
            selection
            value={fdata.newData.program}
            name="program"
            options={programOptions}
            onChange={handleChangeProgram}
            placeholder="Program"
          />
        </div>
        <div style={{ display: "block" }}>
          <Dropdown
            clearable
            fluid
            name="org"
            search
            value={fdata.newData.org}
            selection
            onChange={handleChangeProgram}
            options={orgOptions}
            placeholder="Organization"
          />
        </div>
        <div>
          <Dropdown
            clearable
            fluid
            name="project"
            search
            selection
            value={fdata.newData.project}
            onChange={handleChangeProgram}
            options={projectOptions}
            placeholder="Project"
          />
        </div>
        <div>
          <Dropdown
            clearable
            fluid
            name="form"
            search
            value={form}
            selection
            onChange={handleChangeProgram}
            options={formOptions}
            placeholder="Form"
          />
        </div>
        <div>
          <Button className="download-data" onClick={handleDownloadData} fluid>
            Download Data
          </Button>
        </div>
        <div>
          <Button className="new-report" fluid hidden>
            New Report
          </Button>
        </div>
        <div
          className="filter-effect"
          onClick={() => handleDemographicToggle()}
        >
          {demographicToggle ? (
            <Icon
              name="eye"
              style={{ color: "white" }}
              className="center-tiny-icons"
            />
          ) : (
            <Icon
              name="eye slash"
              style={{ color: "white" }}
              className="center-tiny-icons"
            />
          )}
        </div>
      </div>

      <div className="org-dashboard-content">
        <div
          className="filters-options"
          style={filterToggle ? { display: "block" } : { display: "none" }}
        >
          <p>Qucik Filters</p>
          <p className="filters-title">Record Updated</p>
          <div>
            <Radio
              label="All"
              name="record"
              value="all"
              checked={record === "all"}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <Radio
              label="Today"
              name="record"
              value="today"
              checked={record === "today"}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <Radio
              label="Yesterday"
              name="record"
              value="yesterday"
              checked={record === "yesterday"}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <Radio
              label="Last 7 days"
              name="record"
              value="7days"
              checked={record === "7days"}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <Radio
              label="Last 30 days"
              name="record"
              value="30days"
              checked={record === "30days"}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <Radio
              label=" Specific Range"
              name="record"
              value="specific"
              checked={record === "specific"}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            From:{" "}
            <DatePicker
              selected={startDate}
              disabled={record !== "specific"}
              onChange={(date) => setDate(date, "start")}
              selectsStart
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              startDate={startDate}
              dateFormat="yyy/MM/dd"
              endDate={endDate}
            />
          </div>
          <div>
            To: &nbsp;&nbsp;&nbsp;{" "}
            <DatePicker
              selected={endDate}
              onChange={(date) => setDate(date, "end")}
              selectsEnd
              disabled={record !== "specific"}
              startDate={startDate}
              endDate={endDate}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="yyy/MM/dd"
              minDate={startDate}
            />
          </div>
          <p className="filters-title">Assignment</p>
          <div>
            <Radio
              label="All"
              name="status"
              value="all"
              checked={status === "all"}
              onChange={handleFilterChange}
            />
          </div>

          {statusOptions &&
            statusOptions.map((item: string, index: number) => (
              <div key={index}>
                <Radio
                  label={item}
                  name="status"
                  value={item}
                  checked={status === item}
                  onChange={handleFilterChange}
                />
              </div>
            ))}

          <Modal
            size="small"
            open={metadatafilterToggle}
            closeIcon
            onOpen={() =>
              form !== "" && metaDataField !== undefined
                ? setMetadataFilterToggle(true)
                : ""
            }
            onClose={() => setMetadataFilterToggle(false)}
            style={{ position: "relative" }}
            trigger={<p className="filters-title">Advanced Filters</p>}
          >
            <Modal.Header>MetaData Filter</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Form onSubmit={submitMetadataFilter}>
                  {metaDataField &&
                    metaDataField.map((data: any, index: number) => {
                      return (
                        <Form.Field
                          key={index}
                          id={data["key"] ?? index}
                          name={data["key"] ?? index}
                          control={Input}
                          label={data["label"] ?? index}
                          placeholder={data["label"] ?? index}
                        />
                      );
                    })}
                  <Form.Button
                    content="Filter"
                    labelPosition="right"
                    icon="checkmark"
                    positive
                  />
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="black"
                onClick={() => setMetadataFilterToggle(false)}
              >
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
        <div style={{ flex: 4 }}>
          <Map
            className="dashboard-map"
            center={[latitude, longitude]}
            zoom={5}
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Mapnik">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="BlackAndWhite">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
            </LayersControl>
            {/* <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            /> */}
            <MarkerClusterGroup>
              {filteredData.map(
                (
                  item: {
                    _latitude: any;
                    _longitude: any;
                    _id: { $oid: string };
                  },
                  index: number
                ) => (
                  <Marker
                    key={index}
                    position={[
                      item._latitude === "-" ? 0 : item._latitude || 0,
                      item._longitude === "-" ? 0 : item._longitude || 0,
                    ]}
                    onClick={() => {
                      setDataId(item._id.$oid || "");
                      setOpenModal(true);
                    }}
                  ></Marker>
                )
              )}
            </MarkerClusterGroup>
            ;
          </Map>

          <div className="dashboard-stats">
            <div>
              <div className="dashboard-stats-label">
                <p>Total Forms</p>
                <p>{dashboard_info && dashboard_info.total_forms}</p>
              </div>
              <Progress percent={80} indicating />
            </div>
            <div>
              <div className="dashboard-stats-label">
                <p>Users</p>
                <p> {dashboard_info && dashboard_info.total_users}</p>
              </div>
              <Progress percent={20} indicating />
            </div>
            <div>
              <div className="dashboard-stats-label">
                <p>Projects</p>
                <p> {dashboard_info && dashboard_info.total_projects}</p>
              </div>
              <Progress color="blue" percent={30} active />
            </div>
            <div>
              <div className="dashboard-stats-label">
                <p>Apps</p>
                <p> {dashboard_info && dashboard_info.apps.length}</p>
              </div>
              <Progress percent={90} indicating />
            </div>

            <div>
              <div className="dashboard-stats-label">
                <p>Organizations</p>
                <p> {dashboard_info && dashboard_info.total_organizations}</p>
              </div>
              <Progress warning percent={90} indicating />
            </div>
          </div>
        </div>

        <div
          className="demographic-details"
          style={demographicToggle ? { display: "block" } : { display: "none" }}
        >
          <div>
            <p className="side-title">Program - {country}</p>
            <div className="dashboard-side">
              <div>
                <img src={cdata.newData.flag} width="80px" alt="" />
              </div>
              <div className="side-content">
                <p>
                  <strong>Population:</strong> {cdata.newData.population}
                </p>
                <p>
                  <strong>Area:</strong> {cdata.newData.area}
                </p>
                <p>
                  <strong>Gini:</strong> {cdata.newData.gini}
                </p>
                <hr />
              </div>
            </div>
          </div>
          <br />
          <div>
            <p className="side-title">Organization - {org}</p>

            <div className="dashboard-side">
              <div>
                <img src={orgImg} width="50px" alt={org} />
              </div>
              <div className="side-content">
                <p>
                  <strong>Name:</strong> {org}
                </p>
              </div>
            </div>
          </div>
          <br />
          <div>
            <p className="side-title">Project - {project}</p>

            <div className="dashboard-side">
              <div className="side-content">
                <p>
                  <strong>Total Records:</strong> {filteredData.length}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-card-org">
        <Grid>
          <Grid.Column floated="left" width={5} key={"gc_" + 1}>
            <Header as="h3" className="color-blue">
              Organizations
            </Header>
          </Grid.Column>
          <Grid.Column
            floated="right"
            width="5"
            textAlign="right"
            key={"gc_" + 2}
          >
            <Label
              as="a"
              tag
              style={{ backgroundColor: "#9ec54d", color: "#fff" }}
            >
              {dashboard_info && dashboard_info.total_organizations} Total
              Organizations
            </Label>
          </Grid.Column>
        </Grid>
        <Card.Group>
          {dashboard_info && (
            <GetOrganizationCard data={dashboard_info.organizations} />
          )}
        </Card.Group>
        <br />
        <div className="view-org-btn">
          <Button
            as={Link}
            to="/organization"
            className="bg-blue formbuilder-buttons"
          >
            View All Organizations
          </Button>
        </div>
      </div>
      <div className="app-card-org">
        <Header as="h3" className="color-blue">
          Apps
        </Header>
        {dashboard_info ? (
          <Card.Group>
            <GetAppCard data={dashboard_info.apps} />
          </Card.Group>
        ) : (
          <p style={{ textAlign: "center", color: "white" }}>Loading..</p>
        )}
        <OpenModal item={openInfo} />
      </div>
    </React.Fragment>
  );
}
