import React, { useState, useEffect } from "react";
import {
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Input,
  Grid,
  Pagination,
  Select,
  Label,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadTilePage,
  unloadTilePage,
  addTile,
  editTile,
  removeTile,
} from "../../redux/action/tileAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { ImageModalComponent } from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  tiles: {
    tiles: {
      id: number;
      name: string;
      name_lang: string;
      value: string;
      type: string;
      order: string;
      awareness_module_id: number;
      form: string;
      tile_id: number;
      visibility_before_login: string;
      visibility_after_login: string;
      image: string;
      roles: any[];
    }[];
    awareness_modules: {
      id: number;
      name: string;
    }[];
    forms: {
      id: number;
      name: string;
      slug: string;
    }[];
    apps: {
      id: number;
      app_name: string;
    }[];
    roles: {
      id: number;
      name: string;
    }[];
  };
}

type optionType = {
  key: string | number;
  value: number | string;
  text: string;
}[];

const visibilityBeforeLoginOptions = [
  {
    key: "true",
    value: "true",
    text: "True",
  },
  {
    key: "false",
    value: "false",
    text: "False",
  },
];
const visibilityAfterLoginOptions = [
  {
    key: "true",
    value: "true",
    text: "True",
  },
  {
    key: "false",
    value: "false",
    text: "False",
  },
];
const tileLayoutType = [
  {
    key: "construction_library",
    value: "construction_library",
    text: "Construction Library",
  },
  {
    key: "house_construction",
    value: "house_construction",
    text: "Basics of House Construction",
  },
  {
    key: "material_quality",
    value: "material_quality",
    text: "Material Quality & Storage",
  },
  {
    key: "strengthen_my_house",
    value: "strengthen_my_house",
    text: "Strengthen My House",
  },
  {
    key: "downloads",
    value: "downloads",
    text: "Downloads",
  },
  {
    key: "documents",
    value: "documents",
    text: "Document",
  },
  {
    key: "site_safety",
    value: "site_safety",
    text: "Site Safety",
  },
  {
    key: "news",
    value: "news",
    text: "News",
  },
  {
    key: "faq",
    value: "faq",
    text: "FAQ",
  },
  {
    key: "video",
    value: "video",
    text: "Video",
  },
  {
    key: "gallery",
    value: "gallery",
    text: "Gallery",
  },
  {
    key: "ask_for_help",
    value: "ask_for_help",
    text: "Ask For Help",
  },
  {
    key: "contact_tracing",
    value: "contact_tracing",
    text: "Contact Tracing",
  },
  {
    key: "training",
    value: "training",
    text: "Training",
  },
  {
    key: "webform",
    value: "webform",
    text: "Web Form",
  },
  {
    key: "static_pages",
    value: "static_pages",
    text: "Static Pages",
  },
  {
    key: "earthquake_module",
    value: "earthquake_module",
    text: "Earthquake Module",
  },
  {
    key: "floor_plan",
    value: "floor_plan",
    text: "Floor Plan",
  },
  {
    key: "isac_simo",
    value: "isac_simo",
    text: "ISAC SIMO Module",
  },
  {
    key: "crowd_source_image",
    value: "crowd_source_image",
    text: "Crowd Source Image",
  },
];

const itemsPerPage = 5;

export default function Tile() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      name_lang: "",
      value: "",
      type: "",
      order: "",
      awareness_module_id: 0,
      form: "",
      tile_id: 0,
      visibility_before_login: "",
      visibility_after_login: "",
      image: "",
      roles: [],
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [search, setSearch] = useState<string>("");
  const [url, setUrl] = useState("");
  const [apiUrl, setApiUrl] = useState("/tile");
  const [total, setTotal] = useState(1);
  const [item, SetItem] = useState<{}[]>([]);
  const [activePage, setActivePage] = useState(1);
  const tiles = useSelector((state: RootState) => state.tiles);
  const [formOptions, setFormOptions] = useState<optionType>([]);
  const [appOptions, setAppOptions] = useState<optionType>([]);
  const [roleOptions, setRoleOptions] = useState<optionType>([]);
  const [awarenessModuleOptions, setAwarenessModuleOptions] =
    useState<optionType>([]);
  const [tileOptions, setTileOptions] = useState<optionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadTilePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadTilePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (tiles.tiles !== undefined) {
      let items = tiles.tiles
        .filter((data: any) => {
          if (!search || search.length <= 0) {
            return true;
          }
          let pass = false;
          Object.keys(data).forEach((k) => {
            if (
              data[k] &&
              data[k].toString().toLowerCase().indexOf(search.toLowerCase()) >=
                0
            ) {
              pass = true;
            }
          });
          return pass;
        })
        .slice(
          (activePage - 1) * itemsPerPage,
          (activePage - 1) * itemsPerPage + itemsPerPage
        );
      SetItem(items);
    }

    return () => {};
    // eslint-disable-next-line
  }, [activePage]);

  useEffect(() => {
    if (tiles.tiles !== undefined) {
      let items = tiles.tiles.slice(0, itemsPerPage);
      SetItem(items);
      let final_module = tiles.awareness_modules.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setAwarenessModuleOptions(final_module);

      let final_form = tiles.forms.map((data) => {
        return {
          key: data.slug,
          value: data.slug,
          text: data.name,
        };
      });

      setFormOptions(final_form);
      setTotal(tiles.tiles.length / itemsPerPage);

      let final_stage = tiles.tiles.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setTileOptions(final_stage);

      if (tiles.apps !== undefined) {
        let final_app = tiles.apps.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.app_name,
          };
        });

        setAppOptions(final_app);
      }

      if (tiles.roles !== undefined) {
        let final_roles = tiles.roles.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.name,
          };
        });

        setRoleOptions(final_roles);
      }
    }
  }, [tiles]);

  useEffect(() => {
    // @ts-ignore
    let id = fdata.newData.editing || false;
    if (id) {
      let final_tiles = [];
      final_tiles = tiles.tiles
        .filter((tile) => {
          return (
            fdata.newData.tile_id === tile.id ||
            (tile.awareness_module_id === fdata.newData.awareness_module_id &&
              fdata.newData.type === tile.type &&
              id !== tile.id)
          );
        })
        .map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.name,
          };
        });

      setTileOptions(final_tiles);
    }
  }, [fdata]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        name_lang: "",
        value: "",
        type: "",
        order: "",
        awareness_module_id: 0,
        form: "",
        tile_id: 0,
        visibility_before_login: "",
        visibility_after_login: "",
        image: "",
        roles: [],
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let tile = tiles.tiles.find((v) => v.id === id);
    console.log("Edit id" + id);

    setData({
      newData: {
        id: tile!.id,
        name: tile!.name,
        name_lang: tile!.name_lang ? tile!.name_lang : "",
        value: tile!.value,
        type: tile!.type,
        order: tile!.order,
        awareness_module_id: tile!.awareness_module_id,
        form: tile!.form,
        tile_id: tile!.tile_id,
        visibility_before_login: tile!.visibility_before_login,
        visibility_after_login: tile!.visibility_after_login,
        image: tile!.image,
        //@ts-ignore
        roles: tile.roles.map((role) => {
          return role.role_id;
        }),
        editing: id,
      },
    });
    setCreating(true);

  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;
    let final_tiles = [];
    if (name === "awareness_module_id") {
      if (fdata.newData.type !== "") {
        final_tiles = tiles.tiles
          .filter(
            (tile) =>
              tile.awareness_module_id === value &&
              fdata.newData.type === tile.type &&
              fdata.newData.id !== tile.id
          )
          .map((data) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          });
      } else {
        final_tiles = tiles.tiles
          .filter(
            (tile) =>
              tile.awareness_module_id === value && fdata.newData.id !== tile.id
          )
          .map((data) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          });
      }

      setTileOptions(final_tiles);
    } else if (name === "type") {
      if (fdata.newData.awareness_module_id !== 0) {
        final_tiles = tiles.tiles
          .filter(
            (tile) =>
              fdata.newData.awareness_module_id === tile.awareness_module_id &&
              tile.type === value &&
              fdata.newData.id !== tile.id
          )
          .map((data) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          });
      } else {
        final_tiles = tiles.tiles
          .filter((tile) => tile.type === value && fdata.newData.id !== tile.id)
          .map((data) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          });
      }
      setTileOptions(final_tiles);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) await dispatch(addTile(fdata.newData, () => {
      dispatch(loadTilePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else await dispatch(editTile(fdata.newData, () => {
      dispatch(loadTilePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);
    dispatch(removeTile(deleteId, () => {
      dispatch(loadTilePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));    
  };

  const onSearch = (srch: string) => {
    //setApiUrl('/tile?q=' + search);
    setSearch(srch);
    if (srch !== "") {
      let items = tiles.tiles.filter((data: any) => {
        if (!srch || srch.length <= 0) {
          return true;
        }
        let pass = false;
        Object.keys(data).forEach((k) => {
          if (
            data[k] &&
            data[k].toString().toLowerCase().indexOf(srch.toLowerCase()) >= 0
          ) {
            pass = true;
          }
        });
        return pass;
      });
      setTotal(items.length / itemsPerPage);

      SetItem(items.slice(0, itemsPerPage));
    } else {
      let items = tiles.tiles.slice(
        (activePage - 1) * itemsPerPage,
        (activePage - 1) * itemsPerPage + itemsPerPage
      );
      SetItem(items);
      setTotal(tiles.tiles.length / itemsPerPage);
    }
  };

  const handleFilterChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    //setFilter(value)
    setApiUrl("/tile?filter=" + value);
  };

  const RenderList = (datas: any): any => {
    if (datas.data.tiles === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.tiles.length === 0 && item.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return item.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          {/* <Table.Cell>{data.value}</Table.Cell> */}
          <Table.Cell>{data.type}</Table.Cell>
          <Table.Cell>{data.order}</Table.Cell>
          <Table.Cell>
            {data.awareness_module ? data.awareness_module.name : ""}
          </Table.Cell>
          <Table.Cell>{data.form ? data.form.name : ""}</Table.Cell>
          <Table.Cell>{data.tile ? data.tile.name : ""}</Table.Cell>
          <Table.Cell>
            {data.visibility_before_login === "true" ? "Visible" : "Invisible"}
          </Table.Cell>
          <Table.Cell>
            {data.visibility_after_login === "true" ? "Visible" : "Invisible"}
          </Table.Cell>

          <Table.Cell>
            {data.image ? (
              <Button onClick={() => setUrl(data.image)}>View</Button>
            ) : (
              <i>-</i>
            )}
          </Table.Cell>
          <Table.Cell>
            {data.roles.length > 0
              ? data.roles.map((role: any) => {
                  return tiles.roles.map((r) => {
                    if (r.id === role.role_id) {
                      return (
                        <Label
                          style={{
                            backgroundColor: "var(--color-blue)",
                            borderColor: "var(--color-blue)",
                            color: "white",
                          }}
                          className="mb-2"
                          horizontal
                        >
                          {r.name}
                        </Label>
                      );
                    }

                    return "";
                  });
                })
              : "N/A"}
          </Table.Cell>

          <Table.Cell>
            <Button
              as={Link}
              to={`/tile/${data.id}`}
              icon
              circular
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
            >
              <Icon name="info circle" />
            </Button>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Tiles</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />
      <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create Tile
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>
          Tiles<small style={{fontSize: 'x-small', fontStyle: 'italic', marginLeft: '5px'}}>(Sorted as per the order assigned to the tiles)</small>{" "}
          <Input
            onChange={(e) => onSearch(e.target.value)}
            className="pull-right small"
            icon="search"
            placeholder="Search..."
          />
        </Header>
        <Select
          placeholder="Filter by App"
          multiple
          clearable
          options={appOptions}
          onChange={handleFilterChange}
        />
        <Table basic="very" unstackable striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              {/* <Table.HeaderCell>Description</Table.HeaderCell> */}
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Order</Table.HeaderCell>
              <Table.HeaderCell>Awareness Module</Table.HeaderCell>
              <Table.HeaderCell>Form</Table.HeaderCell>
              <Table.HeaderCell>Tile</Table.HeaderCell>
              <Table.HeaderCell>Before Login</Table.HeaderCell>
              <Table.HeaderCell>After Login</Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>User Roles</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{tiles && <RenderList data={tiles} />}</Table.Body>
        </Table>

        {tiles === undefined || loading ? <Loader active inverted size="big" /> : ""}

        <Grid>
          <Grid.Column>
            <Pagination
              disabled={total < 2}
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
              siblingRange={1}
            />
          </Grid.Column>
        </Grid>
      </div>
      ,
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="large"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Tile
        </Modal.Header>
        <Modal.Content className="modalContent">
          <Form>
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="name"
                  label="Tile Name"
                  value={fdata.newData.name}
                  onChange={handleChange}
                  placeholder="Enter Tile name..."
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="name_lang"
                  label="Tile Name (In Preferred Language)"
                  value={fdata.newData.name_lang}
                  onChange={handleChange}
                  placeholder="Enter Tile name (In Preferred Language)..."
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <Form.TextArea
                  fluid
                  required
                  type="text"
                  name="value"
                  label="Description"
                  value={fdata.newData.value}
                  onChange={handleChange}
                  placeholder="Description of Tile"
                />
              </Form.Field>

              <Form.Select
                fluid
                placeholder="Select Awareness Module"
                required
                options={awarenessModuleOptions}
                label="Awareness Module"
                value={fdata.newData.awareness_module_id}
                name="awareness_module_id"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <Form.Select
                  fluid
                  required
                  placeholder="Select Layout For Tile"
                  options={tileLayoutType}
                  label="Select layout for tile"
                  value={fdata.newData.type}
                  name="type"
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Select
                fluid
                required
                disabled={fdata.newData.type !== "webform"}
                placeholder="Select Form"
                options={formOptions}
                label="Form(Only Shared enabled is listed here)"
                value={fdata.newData.form}
                name="form"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="order"
                  label="Order"
                  value={fdata.newData.order}
                  onChange={handleChange}
                  placeholder="Order"
                />
              </Form.Field>

              <Form.Select
                fluid
                placeholder="Select Parent Tile"
                options={tileOptions}
                label="Parent Tile"
                value={fdata.newData.tile_id}
                name="tile_id"
                clearable
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Select
                fluid
                required
                placeholder="Select Visibilty Status Before Login"
                options={visibilityBeforeLoginOptions}
                label="Visibility Before Login"
                value={fdata.newData.visibility_before_login}
                name="visibility_before_login"
                onChange={handleChange}
              />
              <Form.Select
                fluid
                required
                placeholder="Select Visibilty Status After Login"
                options={visibilityAfterLoginOptions}
                label="Visibility After Login"
                value={fdata.newData.visibility_after_login}
                name="visibility_after_login"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group widths={2}>
              <Form.Field>
                <Form.Select
                  placeholder="Select roles"
                  fluid
                  multiple
                  search
                  selection
                  name="roles"
                  label="Select user roles to allow usability"
                  onChange={handleChange}
                  value={fdata.newData.roles}
                  options={roleOptions}
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  required={fdata.newData.id === 0}
                  type="file"
                  name="image"
                  label="Upload Image For Tile"
                  onChange={handleChange}
                  accept="image/*"
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
