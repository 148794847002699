import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const moduleLessonTypes = {
  MODULE_LESSON_LOADED: "MODULE_LESSON_LOADED",
  MODULE_LESSON_UNLOADED: "MODULE_LESSON_UNLOADED",
  ADD_MODULE_LESSON: "ADD_MODULE_LESSON",
  EDIT_MODULE_LESSON: "EDIT_MODULE_LESSON",
  DELETE_MODULE_LESSON: "DELETE_MODULE_LESSON",
};

export const loadModuleLessonPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: moduleLessonTypes.MODULE_LESSON_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadModuleLessonPage = () => {
  return {
    type: moduleLessonTypes.MODULE_LESSON_UNLOADED,
    payload: {},
  };
};

export const addModuleLesson = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/module_lesson", payload).then(
      (data) => {
        dispatch({
          type: moduleLessonTypes.ADD_MODULE_LESSON,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editModuleLesson = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/module_lesson/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: moduleLessonTypes.EDIT_MODULE_LESSON,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeModuleLesson = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/module_lesson/delete/${id}`).then(
      (data) => {
        dispatch({
          type: moduleLessonTypes.DELETE_MODULE_LESSON,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
