import React, { useEffect } from "react";
import { Segment, Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { AppConfig } from "../../../config";
import { Formio } from "@formio/react";
import { addFormdata } from "../../../redux/action/formDataAction";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, unloadData } from "../../../redux/action/allAction";
import { FormattedMessage } from "react-intl";

export default function EditFormData(props: {
  match: { params: { slug: string; id: any } };
  history: any;
}) {
  const data_details = useSelector((state: any) => state.all_ind);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchData(
        `/formData/${props.match.params.slug}/edit/${props.match.params.id}`
      )
    );

    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data_details.data !== undefined) {
      let data = JSON.parse(data_details.data);
      formio(data);
    }

    // eslint-disable-next-line
  }, [data_details]);
  const goBack = () => {
    props.history.goBack();
  };

  const formio = (data: any) => {
    Formio.createForm(
      document.getElementById("formio"),
      `${AppConfig.apiUrl}/formBuilders/${props.match.params.slug}`,
      {
        draft: true,
      }
    ).then(function (form: {
      submission: { data: any };
      nosubmit: boolean;
      on: (arg0: string, arg1: (submission: any) => void) => void;
    }) {
      form.submission = {
        data: data,
      };
      // Prevent the submission from going to the form.io server.
      form.nosubmit = true;

      // Triggered when they click the submit button.
      form.on("submit", function (submission) {
        //console.log(submission.data);
        if (submission.data._id === undefined) {
          submission.data._id = data._id ?? "";
          submission.data.state = data.state ?? "";
        }
        dispatch(addFormdata(props.match.params.slug, submission));

        //alert('Submission sent to custom endpoint. See developer console.');
      });
    });
  };

  return [
    <div key={1}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>
        {/* <Breadcrumb.Divider icon='right angle' />
                <Breadcrumb.Section link as={Link} to="/formbuilder">Formbuilder</Breadcrumb.Section>

                <Breadcrumb.Divider icon='right angle' />
                <Breadcrumb.Section link as={Link} to={`/formbuilder/data/${props.match.params.slug}`}>Formbuilder</Breadcrumb.Section> */}
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          {data_details?.form?.name ?? "..."} (Edit Data)
        </Breadcrumb.Section>
      </Breadcrumb>
    </div>,

    <div className="app-card-org" key={2}>
      <div id="formio"></div>
    </div>,
  ];
}
