import React, { useEffect } from "react";
import FormbuilderMetaForm from "./FormbuilderMetaForm";
import { useDispatch, useSelector } from "react-redux";
import { unloadData, fetchData } from "../../redux/action/allAction";
import { Loader } from "semantic-ui-react";
import _ from "lodash";

export default function FormbuilderMetaEdit(props: any) {
  const formbuilder = useSelector((state: any) => state.all_ind);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData(`/formBuilder/meta/edit/${props.match.params.slug}`));
    return () => {
      dispatch(unloadData());
    };
  }, []);

  if (_.isEmpty(formbuilder) || !formbuilder.form) {
    return (
        <div>
           <Loader active inverted size="big" />

           <p>Loading...</p>
        </div>
    ) ;
  }

  return (
    <FormbuilderMetaForm
      type="Edit"
      data={formbuilder}
      slug={props.match.params.slug}
    />
  );
}
