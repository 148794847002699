//import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { AlertModalComponent } from "../../components/Modal";
//import Question from "../../components/training/Question";
import Test from "../../components/training/Test";
import { AppConfig } from "../../config";
import { authHeader } from "../../helper";
import {
  loadAppModulePage,
  unloadAppModulePage,
} from "../../redux/action/appAction";
//import { resetAppUserPoints } from "../../redux/action/appUsersAction";
//import appUsersReducer from "../../redux/reducer/appUsersReducer";

interface RootState {
  apps: {
    apps: {
      id: number;
      name: string;
      description: string;
      image: string;
      project: {
        id: number;
        name: string;
      },
      module_lessons: {
        id: number;
        name: string;
        description: string;
        image: string;
        lesson_datas: {
          id: number;
          name: string;
          description: string;
          pretest: string;
          duringtest: string;
          posttest: string;
          video: string;
          document: string;
        }[];
      }[];
    }[];
  };
}

export default function Start() {
  const [step, setStep]: any = useState(1);
  const [apiUrl, setApiUrl] = useState("/start_training");
  const [apiUrls, setApiUrls] = useState("/start_training");
  const [isModule, setIsModule]: any = useState(true);
  const [isLesson, setIsLesson]: any = useState(false);
  const [isLessonType, setIsLessonType]: any = useState(false);

  const [training_module_id, setTrainingModuleId]: any = useState(false);
  const [module_lesson_id, setModuleLessonId]: any = useState(false);
  const [lesson_type_id, setLessonTypeId]: any = useState(false);
  const [lesson_data_id, setLessonDataId]: any = useState(false);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [answerSheet, setAnswerSheet]: any = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const apps = useSelector((state: RootState) => state.apps);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAppModulePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAppModulePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  const nextStep = () => {
    let value = step;
    setStep(() => value + 1);
  };

  const prevStep = () => {
    let value = step;
    setStep(() => value - 1);
  };

  if (!apps || !apps.apps) {
    return <b>Loading..</b>;
  } else if (apps && apps.apps && apps.apps.length <= 0) {
    return <b>No Tests Availalbe</b>;
  }

  const reset = () => {
    setIsModule(true);
    setIsLesson(false);
    setIsLessonType(false);
    setTrainingModuleId(false);
    setModuleLessonId(false);
    setLessonDataId(false);
    // setLessonTypeId(false);
  };

  const startThisLesson = async (lesson_id: any, sheet: any) => {
    console.log(sheet);
    setAnswerSheet(null);
    setCurrentLesson(null);
    sheet["pretest_done"] = false;
    sheet["video_watched"] = sheet.video ? false : true;
    sheet["document_viewed"] = sheet.document ? false : true;

    sheet["during_test_done"] = false;
    sheet["post_test_done"] = false;
    if (!sheet.pretest || sheet.pretest.length <= 0) {
      sheet["pretest_done"] = true;
    }
    if (!sheet.posttest || sheet.posttest.length <= 0) {
      sheet["post_test_done"] = true;
    }
    if (!sheet.duringtest || sheet.duringtest.length <= 0) {
      sheet["during_test_done"] = true;
    }
    if (!sheet.video) {
      sheet["video_watched"] = true;
    }
    if (!sheet.document) {
      sheet["document_viewed"] = true;
    }

    const res = await fetchUserTestStatus(lesson_id);
    console.log(res);
    if (res.pretest_data && res.pretest_data.length > 0) {
      sheet["pretest_done"] = true;
    }
    if (res.posttest_data && res.posttest_data.length > 0) {
      sheet["post_test_done"] = true;
    }
    if (res.duringtest_data && res.duringtest_data.length > 0) {
      sheet["during_test_done"] = true;
    }
    if (res.document_viewed && res.document_viewed != "false") {
      sheet["document_viewed"] = true;
    }
    if (res.video_watched && res.video_watched != "false") {
      sheet["video_watched"] = true;
    }
    setAnswerSheet(sheet);
    setCurrentLesson(lesson_id);
  };

  const fetchUserTestStatus = (lesson_id: any) => {
    let headers = authHeader();
    let formdata = new FormData();
    formdata.append("lesson_data_id", lesson_id);

    const requestOptions: object = {
      method: "POST",
      headers: headers,
      body: formdata,
    };
    const config = {
      apiUrl: AppConfig.apiUrl,
    };

    //remaining code left to be done
    return fetch(
      `${config.apiUrl}/lesson_data/fetch_user_test_data`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage("Failed To Fetch Data.");
      });
  };

  const getYouTubeVideoId = (url: any) => {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      return "https://www.youtube.com/embed/" + match[2];
    } else {
      return url;
    }
  };

  const Congratulations = () => {
    return (
      <div className="finished-congratulations">
        <h1>Congratulations !!</h1>
        <p>You have completed this training successfully..</p>
      </div>
    );
  };

  const getCurrentTestMaterials = () => {
    let data: any = apps.apps
      .filter((app) => {
        return app.id === training_module_id;
      })[0]
      .module_lessons.filter((ml) => {
        return ml.id === module_lesson_id;
      })[0]
      .lesson_datas.filter((ldInner) => {
        return ldInner.id === lesson_type_id;
      });

    if (data.length > 0) {
      data = data[0];
      if (step === 1) {
        if (data.pretest.length > 0) {
          data["test_type"] = "Pre Test";
          data["type"] = "pretest_done";
          data["relevant_data"] = data.pretest;
        } else {
          nextStep();
        }
      } else if (step === 2) {
        if (data.video) {
          data["test_type"] = "Watch Video";
          data["type"] = "video_watched";
          data["relevant_data"] = getYouTubeVideoId(data.video);
        } else {
          nextStep();
        }
      } else if (step === 3) {
        if (data.document) {
          data["test_type"] = "Learn PDF";
          data["type"] = "document_viewed";
          data["relevant_data"] = data.document;
        } else {
          nextStep();
        }
      } else if (step === 4) {
        if (data.duringtest.length > 0) {
          data["test_type"] = "During Test";
          data["type"] = "during_test_done";
          data["relevant_data"] = data.duringtest;
        } else {
          nextStep();
        }
      } else if (step === 5) {
        if (data.posttest.length > 0) {
          data["test_type"] = "Post Test";
          data["type"] = "post_test_done";
          data["relevant_data"] = data.posttest;
        } else {
          nextStep();
        }
      } else if (step === 6) {
        return <Congratulations />;
      }
    }

    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AlertModalComponent
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            alertMessageHeader="Test Failed!!!"
          />
          <div>
            <div className="training-breadcrumb-module bg-green">
              <p>
                {
                  apps.apps.filter((app) => {
                    return app.id === training_module_id;
                  })[0].name
                }
              </p>
            </div>
            <div className="training-breadcrumb-lesson bg-green">
              <p>
                {
                  apps.apps
                    .filter((app) => {
                      return app.id === training_module_id;
                    })[0]
                    .module_lessons.filter((ml) => {
                      return ml.id === module_lesson_id;
                    })[0].name
                }
              </p>
            </div>
            <div className="training-breadcrumb-lesson bg-green">
              <p>
                {
                  apps.apps
                    .filter((app) => {
                      return app.id === training_module_id;
                    })[0]
                    .module_lessons.filter((ml) => {
                      return ml.id === module_lesson_id;
                    })[0]
                    .lesson_datas.filter((ld) => {
                      return ld.id === lesson_type_id;
                    })[0].name
                }
                {/* {apps.apps.filter((app) => {
                return app.id === training_module_id;
              })[0].module_lessons.filter((ml) => {
                return ml.id === module_lesson_id;
              })[0].lesson_types.filter((lt) => {
                return lt.id === lesson_type_id;
              })[0].name} */}
              </p>
            </div>
            <div className="training-breadcrumb-lesson bg-green">
              <p>{data.test_type}</p>
            </div>
          </div>
        </div>

        {/* IS VIDEO */}
        {data["type"] === "video_watched" ? (
          <div
            className="section-content"
            style={{ height: "auto", margin: "30px 0" }}
          >
            <div className="training-video">
              <iframe
                title="Training Video"
                width="100%"
                style={{ height: "70vh" }}
                src={data["relevant_data"]}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                onError={(e) => setAlertMessage("Failed to Load Video")}
              ></iframe>
            </div>
          </div>
        ) : // IS DOCUMENT
        data["type"] === "document_viewed" ? (
          <div
            className="section-content"
            style={{ height: "auto", margin: "30px 0" }}
          >
            <div className="training-video">
              <iframe
                title="Training Video"
                width="100%"
                style={{ height: "70vh" }}
                src={data["relevant_data"]}
                frameBorder="0"
                allowFullScreen
                onError={(e) => setAlertMessage("Failed to Load Document")}
              ></iframe>
            </div>
          </div>
        ) : (
          // IS TEST
          <Test
            data={data}
            sheet={answerSheet}
            handleAnswerChange={handleAnswerChange}
          />
        )}
      </React.Fragment>
    );
  };

  const handleAnswerChange = (
    test_type: any,
    question_type: any,
    answer_id: any,
    question_id: any
  ) => {
    if (!answerSheet) {
      setAlertMessage("Opps, Something failed.");
      return false;
    }

    let tempAnswerSheet = answerSheet;
    // @ts-ignore
    tempAnswerSheet[test_type].map((q) => {
      if (q.id === question_id) {
        if (question_type === "single") {
          q["selected_answer"] = [answer_id];
        } else {
          let index = q["selected_answer"].indexOf(answer_id);
          if (index >= 0) {
            q["selected_answer"].splice(index, 1);
          } else {
            q["selected_answer"].push(answer_id);
          }
        }
        return q;
      } else {
        return q;
      }
    });
    // @ts-ignore
    setAnswerSheet({ ...tempAnswerSheet });
  };

  const markTestDone = async (type: string) => {
    let tempAnswerSheet = answerSheet;
    // console.log(type)
    if (type === "pretest") {
      if (answerSheet.pretest_done) {
        return nextStep();
      }
      // @ts-ignore
      const pretestdata = tempAnswerSheet["pretest"];

      const percentage = calculatePercentage(pretestdata);

      console.log("percentage" + percentage);

      if (percentage >= 70) {
        // @ts-ignore
        // tempAnswerSheet['pretest']['percentage'] = percentage;
        const res = await postTestData(
          tempAnswerSheet["id"],
          "pre",
          pretestdata
        );
        console.log(res);
        // @ts-ignore
        tempAnswerSheet["pretest_done"] = true;
        nextStep();
      } else {
        setAlertMessage(
          "You obtained " +
            percentage +
            "%. \nMinimum 70% is required to pass. Please, Try Again!"
        );
      }
    } else if (type === "posttest") {
      if (answerSheet.post_test_done) {
        return nextStep();
      }
      // @ts-ignore
      const posttestdata = tempAnswerSheet["posttest"];

      const percentage = calculatePercentage(posttestdata);

      if (percentage >= 70) {
        // @ts-ignore
        const res = await postTestData(
          tempAnswerSheet["id"],
          "post",
          posttestdata
        );
        console.log(res);
        // @ts-ignore
        tempAnswerSheet["post_test_done"] = true;
        nextStep();
      } else {
        setAlertMessage(
          "Failed. You got " +
            percentage +
            "%\n70%+ required to pass. Try Again."
        );
      }
    } else if (type === "duringtest") {
      if (answerSheet.during_test_done) {
        return nextStep();
      }
      // @ts-ignore
      const duringtestdata = tempAnswerSheet["duringtest"];

      const percentage = calculatePercentage(duringtestdata);

      if (percentage >= 70) {
        // @ts-ignore
        const res = await postTestData(
          tempAnswerSheet["id"],
          "during",
          duringtestdata
        );
        console.log(res);
        // @ts-ignore
        tempAnswerSheet["during_test_done"] = true;
        nextStep();
      } else {
        setAlertMessage(
          "Failed. You got " +
            percentage +
            "%\n70%+ required to pass. Try Again."
        );
      }
    } else if (type === "video") {
      if (answerSheet.video_watched) {
        return nextStep();
      }
      // @ts-ignore
      const res = await postTestData(tempAnswerSheet["id"], "learn", {
        video: true,
      });
      // console.log(res);
      // @ts-ignore
      tempAnswerSheet["video_watched"] = true;
      nextStep();
    } else if (type === "document") {
      if (answerSheet.document_viewed) {
        return nextStep();
      }
      // @ts-ignore
      const res = await postTestData(tempAnswerSheet["id"], "learn", {
        document: true,
      });
      // console.log(res);
      // @ts-ignore
      tempAnswerSheet["document_viewed"] = true;
      nextStep();
    }
    // @ts-ignore
    setAnswerSheet({ ...tempAnswerSheet });
  };

  //calculate percentage of each testdata submitted
  const calculatePercentage = (test_data: string) => {
    const totalQuestions = test_data.length;
    console.log("Total Q" + totalQuestions);
    let correctAnswers = 0; //ok
    let percentage = 0;

    for (let i = 0; i < test_data.length; i++) {
      let correct_ids = test_data[i]["answers"]
        .filter((a: any) => {
          return a.correct;
        })
        .map((a: any) => {
          return a.id;
        });
      if (test_data[i]["selected_answer"].length > 0) {
        let correct = test_data[i]["selected_answer"].every((a_id: any) => {
          return correct_ids.indexOf(a_id) >= 0;
        });
        if (correct) {
          correctAnswers++;
        }
      }
    }

    console.log("Total Corr" + correctAnswers);

    percentage = (correctAnswers / totalQuestions) * 100;

    return percentage;
  };

  //send POST request to store the test data of logged in user
  const postTestData = async (
    lesson_data_id: string,
    type: string,
    data: object
  ) => {
    // this.props.setloading(true);
    let headers = authHeader();
    let formdata = new FormData();
    formdata.append("lesson_data_id", lesson_data_id);
    formdata.append("test_type", type);
    formdata.append("test_data", JSON.stringify(data));
    const requestOptions: object = {
      method: "POST",
      headers: headers,
      body: formdata,
    };
    const config = {
      apiUrl: AppConfig.apiUrl,
    };

    //remaining code left to be done
    return fetch(
      `${config.apiUrl}/lesson_data/store_data_frontend`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage("Failed To Save Data.");
      });
  };

  return (
    <div style={{ margin: "1rem" }}>
      <div className="app-card-org">
        {/* training modules list */}
        {isModule ? (
          <div className="training-module">
            {apps.apps.length > 0
              ? apps.apps.map((app) => {
                  return (
                    <div
                      key={app.id}
                      className="module-title"
                      onClick={() => {
                        console.log(
                          "Training Module ID  " + app.id + "  " + app.name
                        );
                        setIsModule(false);
                        setIsLesson(true);
                        setTrainingModuleId(app.id);
                      }}
                    >
                      <h4>{app.name}</h4>
                      <small style={{
                        display: 'block',
                        textAlign: 'center',
                        lineHeight: 0,
                        transform: 'translate(0, -8px)',
                        fontSize: '75%',
                        color: '#c0d77d',
                        letterSpacing: '1px',
                      }}>{app?.project?.name ?? ''}</small>
                    </div>
                  );
                })
              : ""}
          </div>
        ) : isLesson ? (
          <div className="training-lesson">
            <div className="training-breadcrumb-module bg-green">
              <p>
                {
                  apps.apps.filter((app) => {
                    return app.id === training_module_id;
                  })[0].name
                }
              </p>
            </div>
            <div className="training-module">
              {apps.apps.length > 0
                ? apps.apps
                    .filter((app) => {
                      return app.id === training_module_id;
                    })[0]
                    .module_lessons.map((ml) => {
                      return (
                        <div
                          key={ml.id}
                          className="lesson-title"
                          onClick={() => {
                            console.log(
                              "Module lesson ID  " + ml.id + " " + ml.name
                            );
                            setIsLesson(false);
                            setIsLessonType(true);
                            setModuleLessonId(ml.id);
                          }}
                        >
                          <h4>{ml.name}</h4>
                        </div>
                      );
                    })
                : ""}
              <div className="section-last">
                <Button
                  className="backto-module"
                  onClick={() => {
                    reset();
                  }}
                >
                  Back to Module
                </Button>
              </div>
            </div>
          </div>
        ) : isLessonType ? (
          <div className="training-lesson">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div className="training-breadcrumb-module bg-green">
                  <p>
                    {
                      apps.apps.filter((app) => {
                        return app.id === training_module_id;
                      })[0].name
                    }
                  </p>
                </div>
                <div className="training-breadcrumb-lesson bg-green">
                  <p>
                    {
                      apps.apps
                        .filter((app) => {
                          return app.id === training_module_id;
                        })[0]
                        .module_lessons.filter((ml) => {
                          return ml.id === module_lesson_id;
                        })[0].name
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="training-module">
              {apps.apps.length > 0
                ? apps.apps
                    .filter((app) => {
                      return app.id === training_module_id;
                    })[0]
                    .module_lessons.filter((ml) => {
                      return ml.id === module_lesson_id;
                    })[0]
                    .lesson_datas.map((ld) => {
                      return (
                        <div
                          key={ld.id}
                          className="lesson-title"
                          onClick={() => {
                            console.log(
                              "Lesson Type ID  " + ld.id + " " + ld.name
                            );
                            let data: any = apps.apps
                              .filter((app) => {
                                return app.id === training_module_id;
                              })[0]
                              .module_lessons.filter((ml) => {
                                return ml.id === module_lesson_id;
                              })[0]
                              .lesson_datas.filter((ldInner) => {
                                return ld.id === ldInner.id;
                              });

                            console.log(data);
                            console.log(typeof data);
                            // console.log(data[0].name);

                            if (data.length > 0) {
                              console.log("LESSON TYPE ID CLICKED" + ld.id);
                              data = data[0];
                              setIsLesson(false);
                              setIsLessonType(false);
                              setLessonTypeId(ld.id);
                              startThisLesson(data.id, data);
                            } else {
                              setAlertMessage("No Tests Available for it yet.");
                            }
                          }}
                        >
                          <h4>{ld.name}</h4>
                        </div>
                      );
                    })
                : ""}
              <div className="section-last">
                <Button
                  className="backto-module"
                  onClick={() => {
                    reset();
                  }}
                >
                  Back to Module
                </Button>
                <Button
                  className="backto-lesson"
                  onClick={() => {
                    setIsModule(false);
                    setIsLesson(true);
                    setIsLessonType(false);
                    setLessonTypeId(false);
                  }}
                >
                  Back to Lesson
                </Button>
              </div>
            </div>
          </div>
        ) : step === 1 ? (
          <div className="training-section">
            {getCurrentTestMaterials()}
            <div className="section-last">
              <Button
                className="backto-module"
                onClick={() => {
                  reset();
                }}
              >
                Back to Module
              </Button>
              <Button
                style={{ backgroundColor: "gray", color: "#fff" }}
                onClick={() => {
                  setIsModule(false);
                  setIsLesson(true);
                  setIsLessonType(false);
                  setLessonTypeId(false);
                  setStep(1);
                }}
              >
                Back to Lesson
              </Button>
              <Button
                className="bg-green"
                onClick={() => {
                  markTestDone("pretest");
                }}
              >
                Next
              </Button>
            </div>
          </div>
        ) : step === 2 ? (
          <div className="training-section">
            {getCurrentTestMaterials()}
            <div className="section-last">
              <Button
                className="backto-module"
                onClick={() => {
                  reset();
                }}
              >
                Back to Module
              </Button>
              <Button
                style={{ backgroundColor: "gray", color: "#fff" }}
                onClick={() => {
                  setIsModule(false);
                  setIsLesson(true);
                  setIsLessonType(false);
                  setLessonTypeId(false);
                  setStep(1);
                }}
              >
                Back to Lesson
              </Button>
              <Button
                className="bg-blue"
                onClick={() => {
                  prevStep();
                }}
              >
                Back
              </Button>
              <Button
                className="bg-green"
                onClick={() => {
                  markTestDone("video");
                }}
              >
                Next
              </Button>
            </div>
          </div>
        ) : step === 3 ? (
          <div className="training-section">
            {getCurrentTestMaterials()}
            <div className="section-last">
              <Button
                className="backto-module"
                onClick={() => {
                  reset();
                }}
              >
                Back to Module
              </Button>
              <Button
                style={{ backgroundColor: "gray", color: "#fff" }}
                onClick={() => {
                  setIsModule(false);
                  setIsLesson(true);
                  setIsLessonType(false);
                  setLessonTypeId(false);
                  setStep(1);
                }}
              >
                Back to Lesson
              </Button>
              <Button
                className="bg-blue"
                onClick={() => {
                  prevStep();
                }}
              >
                Back
              </Button>
              <Button
                className="bg-green"
                onClick={() => {
                  markTestDone("document");
                }}
              >
                Next
              </Button>
            </div>
          </div>
        ) : step === 4 ? (
          <div className="training-section">
            {getCurrentTestMaterials()}
            <div className="section-last">
              <Button
                className="backto-module"
                onClick={() => {
                  reset();
                }}
              >
                Back to Module
              </Button>
              <Button
                style={{ backgroundColor: "gray", color: "#fff" }}
                onClick={() => {
                  setIsModule(false);
                  setIsLesson(true);
                  setIsLessonType(false);
                  setLessonTypeId(false);
                  setStep(1);
                }}
              >
                Back to Lesson
              </Button>
              <Button
                className="bg-green"
                onClick={() => {
                  markTestDone("duringtest");
                }}
              >
                Next
              </Button>
            </div>
          </div>
        ) : step === 5 ? (
          <div className="training-section">
            {getCurrentTestMaterials()}
            <div className="section-last">
              <Button
                className="backto-module"
                onClick={() => {
                  reset();
                }}
              >
                Back to Module
              </Button>
              <Button
                style={{ backgroundColor: "gray", color: "#fff" }}
                onClick={() => {
                  setIsModule(false);
                  setIsLesson(true);
                  setIsLessonType(false);
                  setLessonTypeId(false);
                  setStep(1);
                }}
              >
                Back to Lesson
              </Button>
              <Button
                className="bg-green"
                onClick={() => {
                  markTestDone("posttest");
                }}
              >
                Next
              </Button>
            </div>
          </div>
        ) : step === 6 ? (
          <>{getCurrentTestMaterials()}</>
        ) : (
          "You are lost. Page does not exist."
        )}
      </div>
    </div>
  );
}
