import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadAwarenessModulePage,
  unloadAwarenessModulePage,
  addAwarenessModule,
  editAwarenessModule,
  removeAwarenessModule,
} from "../../redux/action/awarenessModuleAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  awareness_modules: {
    awareness_modules: {
      data: {
        name: string;
        id: number;
        app_id: number;
        description: string;
        type: string;
        slug: string;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type moduleTypeOptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

export default function AwarenessModule() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      app_id: 0,
      description: "",
      type: "",
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/awareness_module");
  const [search, setSearch] = useState<string>("");
  //const [filter, setFilter] = useState<number[]>([]);
  const awareness_modules = useSelector(
    (state: RootState) => state.awareness_modules
  );
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [moduleTypeOptions, setModuleTypeOptions] =
    useState<moduleTypeOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadAwarenessModulePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAwarenessModulePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (awareness_modules.awareness_modules !== undefined) {
      setTotal(awareness_modules.awareness_modules.last_page);
      var final_stage = awareness_modules.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(final_stage);

      setModuleTypeOptions([
        {
          key: "Awareness",
          value: "Awareness",
          text: "Awareness",
        },
        {
          key: "contact_trace",
          value: "contact_trace",
          text: "Contact Trace",
        },
      ]);
    }
  }, [awareness_modules]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/awareness_module?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/awareness_module?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        app_id: 0,
        description: "",
        type: "",
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let training_module = awareness_modules.awareness_modules.data.find(
      (v) => v.id === id
    );

    setData({
      newData: {
        id: training_module!.id,
        name: training_module!.name,
        app_id: training_module!.app_id,
        description: training_module!.description,
        type: training_module!.type,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addAwarenessModule(fdata.newData, () => {
      dispatch(loadAwarenessModulePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(editAwarenessModule(fdata.newData, () => {
      dispatch(loadAwarenessModulePage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeAwarenessModule(deleteId, () => {
      dispatch(loadAwarenessModulePage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));                                                      
    
  };

  function RenderList(datas: any) {
    if (datas.data.awareness_modules === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.awareness_modules.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.awareness_modules.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.description}</Table.Cell>
          <Table.Cell>{data.type}</Table.Cell>
          <Table.Cell>{data.slug}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>

          <Table.Cell>
            <Button
              as={Link}
              to={`/awareness_module/${data.id}`}
              icon
              circular
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
            >
              <Icon name="info circle" />
            </Button>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Awareness Module</Breadcrumb.Section>
      </Breadcrumb>

      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Awareness Module
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Awareness Modules
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-3"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Slug</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {awareness_modules && <RenderList data={awareness_modules} />}
        </Table.Body>
      </Table>

      {awareness_modules.awareness_modules === undefined  || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Awareness Module
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Field>
            <Form.Input
              fluid
              required
              type="text"
              name="name"
              label="Awareness Module Name"
              value={fdata.newData.name}
              onChange={handleChange}
              placeholder="Enter Awareness Module name..."
              className="formLabel"
            />
          </Form.Field>

          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select App"
              required
              options={appOptions}
              label="App"
              value={fdata.newData.app_id}
              name="app_id"
              onChange={handleChange}
              className="formLabel"
            />
          </Form.Field>

          <Form.Field>
            <Form.TextArea
              required
              type="text"
              name="description"
              label="Description"
              value={fdata.newData.description}
              onChange={handleChange}
              placeholder="Description"
              className="formLabel"
            />
          </Form.Field>

          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select Type"
              required
              options={moduleTypeOptions}
              label="Type"
              value={fdata.newData.type}
              name="type"
              onChange={handleChange}
              className="formLabel"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          loading={loading}
          disabled={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
