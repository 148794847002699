import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Input,
  Select,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadContactPage,
  unloadContactPage,
  addContact,
  editContact,
  removeContact,
} from "../../redux/action/contactAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  contacts: {
    contacts: {
      data: {
        id: number;
        name: string;
        category: string;
        post: string;
        post_lang: string;
        mobile: string;
        email: string;
        app_id: number;
        related_app_id: [];
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number | string;
  text: string;
}[];

const categoryOptions = [
  {
    key: "Government Contact Details",
    value: "Government Contact Details",
    text: "Government Contact Details",
  },
  {
    key: "Technical Support Contact Details",
    value: "Technical Support Contact Details",
    text: "Technical Support Contact Details",
  },
  {
    key: "Builder Contact Details",
    value: "Other Contacts",
    text: "Other Contacts",
  },
  {
    key: "Other Contacts",
    value: "Builder Contact Details",
    text: "Builder Contact Details",
  },
];

export default function Contact() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      category: "",
      post: "",
      post_lang: "",
      mobile: "",
      email: "",
      app_id: [0],
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/contact");
  const [search, setSearch] = useState<string>("");
  const contacts = useSelector((state: RootState) => state.contacts);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);

  const [filter, setFilter] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadContactPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadContactPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (contacts.contacts !== undefined) {
      setTotal(contacts.contacts.last_page);

      if (contacts.apps !== undefined) {
        var final_stage = contacts.apps.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.app_name,
          };
        });

        setAppOptions(final_stage);
      }
    }
  }, [contacts]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl(
      "/contact?page=" +
        pageInfo.activePage.toString() +
        "&q=" +
        search +
        "&filter=" +
        filter
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/contact?q=" + search + "&filter=" + filter);
    }
  };

  const handleFilterChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    setFilter(value);
    setApiUrl("/contact?filter=" + value + "&q=" + search);
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        category: "",
        post: "",
        post_lang: "",
        mobile: "",
        email: "",
        app_id: [],
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let contact = contacts.contacts.data.find((v) => v.id === id);

    setData({
      newData: {
        id: contact!.id,
        name: contact!.name,
        category: contact!.category,
        post: contact!.post,
        post_lang: contact!.post_lang,
        mobile: contact!.mobile,
        email: contact!.email,
        app_id:
          contact!.related_app_id !== null
            ? contact!.related_app_id
            : [contact!.app_id],
      },
    });

    setCreating(true);
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addContact(fdata.newData, () => {
      dispatch(loadContactPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editContact(fdata.newData, () => {
      dispatch(loadContactPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeContact(deleteId, () => {
      dispatch(loadContactPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  function RenderList(datas: any) {
    if (datas.data.contacts === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.contacts.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.contacts.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{data.category}</Table.Cell>
          <Table.Cell>{data.post}</Table.Cell>
          <Table.Cell>{data.post_lang}</Table.Cell>
          <Table.Cell>{data.mobile}</Table.Cell>
          <Table.Cell>{data.email}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Contacts</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Contact
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Contacts
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-2"
          icon="search"
          placeholder="Search..."
        />
      </Header>
      <Select
        placeholder="Filter by App"
        multiple
        clearable
        options={appOptions}
        onChange={handleFilterChange}
      />

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Post</Table.HeaderCell>
            <Table.HeaderCell>Post Language</Table.HeaderCell>
            <Table.HeaderCell>Mobile</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{contacts && <RenderList data={contacts} />}</Table.Body>
      </Table>

      {contacts.contacts === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="small"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Contact
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Field>
            <Form.Input
              fluid
              required
              type="text"
              name="name"
              label="Contact Name"
              value={fdata.newData.name}
              onChange={handleChange}
              placeholder="Enter Contact name..."
            />
          </Form.Field>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                multiple
                options={appOptions}
                label="App"
                value={fdata.newData.app_id}
                name="app_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Category"
                required
                options={categoryOptions}
                label="Category"
                value={fdata.newData.category}
                name="category"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="post"
                label="Post"
                value={fdata.newData.post}
                onChange={handleChange}
                placeholder="Post"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="post_lang"
                label="Post (In Preferred Language)"
                value={fdata.newData.post_lang}
                onChange={handleChange}
                placeholder="Post in your Language"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="mobile"
                label="Mobile"
                value={fdata.newData.mobile}
                onChange={handleChange}
                placeholder="Mobile"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="email"
                name="email"
                label="Email"
                value={fdata.newData.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          loading={loading}
          disabled={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
