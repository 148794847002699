import React, { useEffect, useState } from 'react';

//import _ from 'lodash';
import { AppConfig } from '../../config';


const DataCount = ({ slug }: { slug: string }) => {

    const [count, setCount] = useState<number | undefined>()


    useEffect(() => {
        (async function () {
            try {
                let response = await fetch(
                    `${AppConfig.apiUrl}/getFormbuilderDataCount/${slug}`
                );
                let data = await response.json();
                setCount(data.count)
            } catch (e) {
                console.error(e);
            }
        })();

        return () => {
           
        }
// eslint-disable-next-line 
    }, [slug]);

    if (count === undefined) {
        return <React.Fragment> * Records</React.Fragment>;
    }
    return (
        <React.Fragment>
            {count} Records
        </React.Fragment>
    )
}
export default DataCount