import { Viewer, Worker } from "@react-pdf-viewer/core";
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  Icon,
  Segment,
  Tab,
} from "semantic-ui-react";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/css/css";
import "codemirror/mode/javascript/javascript";
import { apiService } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../../redux/action/alertAction";
import { fetchData, unloadData } from "../../../redux/action/allAction";

interface Props {
  history: any;
  match: any;
}

export default function FormReportView(props: any): ReactElement {
  const dispatch = useDispatch();
  const report = useSelector((state: any) => state.all_ind);
  const [pdfUrl, setpdfUrl] = useState<string>(
    "http://www.africau.edu/images/default/sample.pdf"
  );

  const [apiList,setApiList]=useState<string[]>([])
  const handle = useFullScreenHandle();

  const [viewFormFields, setViewFormFields] = useState<boolean>(false);
  const [viewSetting, setViewSetting] = useState<boolean>(false);

  const handleRightSideMenu = (view: string) => {
    if (view === "formfields") {
      if (!viewFormFields) {
        setViewSetting(false);
      }
      setViewFormFields(!viewFormFields);
    } else if (view === "setting") {
      if (!viewSetting) {
        setViewFormFields(false);
      }
      setViewSetting(!viewSetting);
    }
  };

  const [values, setValues] = useState({
    name:'',
    id:'',
    body: `<!--  main body file  -->
    <h3 class="main-title">{{$form['title']??''}}</h3>
    <p ><b>Status</b> : {{$main_data['status']??''}}</p>
    <p ><b>Location</b> :  {{$main_data['_latitude']??0}},  {{$main_data['_longitude']??0}}</p>
   <p ><b>Date Created</b> :  {{$main_data['created_at']??''}}</p>

 <div class="part">
   <?php
     $main_wiz= '';
      ?>

   @foreach($field as $each)
        <?php
         $wiz= $each['wizard']??'';
         ?>
         @if($wiz != '' && $main_wiz != $wiz)

             <div class="title-primary">
               <h4>{{$wiz}}</h4>
             </div>
           <?php
           $main_wiz= $wiz;
           ?>
         @endif

         @if($each['type'] == 'datagrid' || $each['type'] == 'editgrid')

           @include('exports.dataGrid_report',['dField'=>$each,'data'=>$main_data[$each['key']]??[],'report'=>$report])
             <div class="datagrid-space"></div>
        @elseif($each['type'] == 'container')

           @include('exports.dataGrid',['dField'=>$each,'data'=>$main_data[$each['key']]??[],'container'=>true])
              <div class="datagrid-space"></div>     
         @else
           @include('exports.eachData_report',['eField'=>$each,'data'=>$main_data[$each['key']]??[],'report'=>$report])

          @endif


       @endforeach

 </div>
    `,
    datagrid: `
    <!--  dataGrid_report file  -->
    
    <?php $i= 1; 
    if(isset($container)) {
      $pdata[]= $data;

    }else{
    $pdata = $data;

    }
    ?>

    @foreach($pdata as $item)
    
      <div class="title-secondary">
        <h4>{{$dField['label'] }} ({{$i}})</h4>
      </div>
    
    @foreach($dField['component'] as $comp)
    
      @if($comp['type'] == 'datagrid' || $comp['type'] == 'editgrid')
    
        @include('exports.dataGrid_report',['dField'=>$comp,'data'=>$item[$comp['key']]??'','report'=>$report])
      <div class="datagrid-space"></div>
      @elseif($comp['type'] == 'container')

      @include('exports.dataGrid',['dField'=>$comp,'data'=>$item[$comp['key']]??'','container'=>true])
      <div class="datagrid-space"></div>

      @else
    
        @include('exports.eachData_report',['eField'=>$comp,'data'=>$item[$comp['key']]??'','report'=>$report])
        @endif
      @endforeach
    
      <?php $i++; ?>
    @endforeach
    
    `,
    eachdata: `
    <!--  eachdata_report file  -->
    <table class="table_data">
    <tr>
      <th>{{$eField['label']??''}}</th>
      <td>
      @if($eField['type'] == 'file' )
        @if(is_array($data))
          @foreach($data as $itm)
            @if(is_array($itm))
              @if(array_key_exists('url',$itm))
                <?php $ext= pathinfo($itm['url'])['extension']??''   ?>
                @if($ext == 'png' || $ext == 'jpeg' || $ext == 'jpg' || $ext == '')
  
  
                  <img class="" src="{{$itm['url']}}" style="height:220px;"/>
                  <br>
                @else
                  <a href="{{$itm['url']}}" target="_blank">View</a>
                  <br>
                @endif
  
              @endif
            @endif
          @endforeach
        @else
          {{($data)}}
        @endif
  
      @elseif($eField['type'] == 'selectboxes')
  
        @if(is_array($data))
          @foreach($data as $key=>$itm)
            @if($itm)
              {{$key}}
              <br/>
            @endif
          @endforeach
        @endif
  
      @elseif($eField['type'] == 'signature')
  
        <img class="" src="{{$data}}" height="100" width="400"/>
  
      @elseif($eField['type'] == 'checkbox')
        @if($data == 1)
          Yes
        @else
          No
        @endif
      @elseif($eField['type'] == 'select')
        @if(is_array($data))
  
          @if(array_key_exists('label',$data))
          {{$data['label']??''}}
          @else
          @foreach($data as $key=>$itm)
            @if(gettype($itm) == 'string' || gettype($itm) == 'number')
              {{$key}} ==> {{$itm}}
            @else
  
              {{$key}} ==> {{json_encode($itm)}}
            @endif
            <br/>
  
          @endforeach
            @endif
  
        @endif
  
  
      @else
  
        @if(is_array($data))
          {{json_encode($data)}}
        @else
        <p style="white-space: pre-wrap;">{!! $data !!}</p>
        @endif
      @endif
      </td>
    </tr>
  </table>
    
    
    
    `,
    header: `
    <div class="top-header">
    <div style="float:left"><h4> <span class="blue">Build</span> <span class="green">Change</span></h4></div>
<div style="float:right">{{(string) $main_data['_id']??''}}</div>
</div>`,
    footer: `
    <div class="footer-report">
    <div style="float:left">
        <p>Build Change</p>
    </div>
 <div style="float:right">{{date('Y-m-d H:i:s')}}</div>
</div>`,
    style: `
    :root {
      --color-blue: #6383a8;
      --color-green: #9ec54d;
      --color-gray: #bdbdb0;
    }

    body {
      margin: 0;
      padding: 0;
    }



    .main-title {
      font-size: 1.4rem;
      margin: 10px 0;
      color: rgb(24, 24, 24);
      text-align: center;
      border-bottom: 1px solid #BDBDB0;
    }

    .title-primary {
      background-color: #6383A8;
      border-left: 8px solid #9EC54D;
      border-radius: 4px;
      margin-top: 20px;
    }

    .title-primary h4 {
      font-size: 1.1rem;
      font-weight: 900;
      padding: 5px 15px;
      margin-top: 0;
      color: #ffffff;
    }

    .title-secondary {
      background-color: #a4a4a2;
      border-left: 5px solid #747474;
      border-radius: 4px;
    }

    .title-secondary h4 {
      font-size: 1.1rem;
      font-weight: 900;
      padding: 5px 15px;
      color: #ffffff;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      text-align: left;

      border: transparent;

    }

    th {
      width: 30%;
      padding: 7px 15px;
    }
    td {
      position: relative;
      width: 70%;
      padding: 7px 15px;

    }
    .datagrid-space{
      height: 10px;
    }

    td::before {
      position: absolute;
      content: "";
      height: 60%;

      top: 20%;
      bottom: 20%;
      left: 0;
      background-color: rgba(0, 0, 0, 0.152);
    }

    tr:nth-child(odd) {
      background-color: #6383a817;
    }

    .information {

      gap: 5px;
      justify-content: space-around;
    }

    .information-title {
      position: relative;
      font-weight: 900;
      font-size: 0.9rem;
      text-transform: uppercase;
    }

    .information-title::before {
      position: absolute;
      content: "";
      height: 2px;
      width: 40px;
      bottom: -5px;
      left: 0;
      border: 1px solid transparent;
      border-radius: 20px;
      background-color: #9EC54D;
    }

    .information-value {
      color: #a7a7a7;
      padding: 4px 4px;
      border-radius: 3px;
      font-size: 0.8rem;
    }

    table {
      border: 1px solid black;


    }
    td, th{
      border: 1px solid black;
    }

    .page-break {
    page-break-after: always;
}
.top-header{
      display: flex;
      justify-content: space-between;
  }
  
  .blue{
   color: #6383a8;
  }
  
  .green{
   color: #9ec54d;
  }
  footer .pagenum::before {
    content: "Page " counter(page);
  }
.footer-report{
        display: flex;
        justify-content: space-between;
    }

.footer-report p{
  line-height: .5rem
}`
  });
  const codeMirrorOptions = {
    theme: "material",
    lineNumbers: true,
    scrollbarStyle: "native",
    viewportMargin: 99,
    autocorrect: true,
  };
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    dispatch(fetchData(`/formBuilder/reportpdfData/${props.match.params.slug}/${props.match.params.id}`));
    //if(props.match.params.id === 'first'){
      PreviewPdf()
    //}
    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, []);
  const handleTextArea = (e: any) => {
    e.persist();
    const { name, value } = e.target;
    setValues((val) => ({
      ...val,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (report !== undefined) {
      if (report.fieldApi !== undefined) {
        setApiList(report.fieldApi)
      }
      if (report.data !== null && report.data !== undefined) {
      if (report.data.header !== undefined) {
        setValues({
          id:report.data._id.$oid??'',
          name: report.data.name,
          header: report.data.header,
          footer: report.data.footer,
          body: report.data.body,
          style: report.data.style,
          eachdata: report.data.eachdata,
          datagrid: report.data.datagrid,
        });
        
      }}
      
    }
  }, [report]);
  const goBack = () => {
    props.history.goBack();
  };

  const PreviewPdf = () => {
   
    apiService
      .getfilePost(
        `/formBuilder/exportpdf/${props.match.params.slug}/first?report=${values.id}
        `,
        values
      )
      .then(
        (data) => {
          setTimeout(() => {
            setpdfUrl(data);
          }, 100);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const SaveReport = () => {
    apiService
      .save(
        `/formBuilder/reportpdf/${props.match.params.slug}
        `,
        values
      )
      .then(
        (data) => {
          setValues((prevState) => ({
            ...prevState,
            id: data.id,
          }));
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const panes = [
    {
      menuItem: "BODY",
      render: () => (
        <Tab.Pane>
          {" "}
          <CodeMirror
            value={values.body}
            className="code-editor1"
            key="main"
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                body: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
          <CodeMirror
            value={values.datagrid}
            className="code-editor1"
            key="datagrid"
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                datagrid: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
          <CodeMirror
            value={values.eachdata}
            className="code-editor1"
            key="eachdata"
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                eachdata: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "HEADER",
      render: () => (
        <Tab.Pane>
          <CodeMirror
            value={values.header}
            className="code-editor"
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                header: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "FOOTER",
      render: () => (
        <Tab.Pane>
          <CodeMirror
            value={values.footer}
            className="code-editor"
            options={{
              mode: "htmlmixed",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                footer: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "STYLES",
      render: () => (
        <Tab.Pane>
          <CodeMirror
            value={values.style}
            className="code-editor"
            options={{
              mode: "css",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                style: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
        </Tab.Pane>
      ),
    }
    
  ];

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            Formbuilder Report Builder
          </Breadcrumb.Section>
        </Breadcrumb>

        <div>
        <input
            className="custom-input"
            type="text"
            name="name"
            placeholder="Enter  Name"
            onChange={handleTextArea}
            value={values.name}
            required
          />
          &nbsp;
          <Button className="export-button" onClick={handle.enter}>
            <svg
              className="rpv-core-icon"
              height="16px"
              viewBox="0 0 24 24"
              width="16px"
            >
              <path
                d="M15.5,8.499l8-8
                M0.5,23.499l8-8
                M5.5,23.499h-5v-5
                M23.5,5.499v-5h-5
                M15.5,15.499l8,8
                M0.5,0.499l8,8
                M0.5,5.499v-5h5
                M18.5,23.499h5v-5"
              ></path>
            </svg>
          </Button>
          <Button className="export-button" onClick={PreviewPdf} icon="play">
            Preview
          </Button>
          <Button
            className="export-button"
            onClick={SaveReport}
            color="twitter"
          >
            Save
          </Button>
        </div>
      </div>

      <FullScreen handle={handle}>
        <div className="afterFullScreen">
          <Grid st columns="equal" stackable>
            {/* <Divider vertical /> */}
            <div className="formbuilder-header">
              <div>
                <h2>Report Builder</h2>
              </div>
              <div>
                <Button
                  className="export-button"
                  onClick={PreviewPdf}
                  icon="play"
                >
                  Preview
                </Button>
                <Button
                  className="export-button"
                  onClick={SaveReport}
                  color="twitter"
                >
                  Save
                </Button>
              </div>
            </div>

            <Grid.Row>
              <Grid.Column width={1} className="gridColumnNoPaddingRight">
                <div className="report-side-menu">
                  <div onClick={() => handleRightSideMenu("formfields")}>
                    <Icon name="bars" size="large" />
                  </div>
                  {/* <div>
                    <Icon name="code" size="large" />
                  </div> */}
                  <div onClick={() => handleRightSideMenu("setting")}>
                    <Icon name="setting" size="large" />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column
                width={2}
                className={`formfields ${viewFormFields ? "active" : ""}`}
              >
                <h3>Form Fields</h3>
                <input type="text" name="" id="" />
                <ul className="tags">
                  {apiList.map((api) => <li>{api}</li>)}
                 
                </ul>
              </Grid.Column>
              <Grid.Column
                width={2}
                className={`formfields ${viewSetting ? "active" : ""}`}
              >
                <h3>Setting</h3>
              </Grid.Column>
              <Grid.Column>
                <Tab panes={panes} />
              </Grid.Column>

              <Grid.Column>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdfUrl}
                    plugins={[
                      // Register plugins
                      defaultLayoutPluginInstance,
                    ]}
                  />
                </Worker>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </FullScreen>
    </React.Fragment>
  );
}
