import React from "react";

interface Props {
  data: {
    data: {
      _id: { $oid: string };
      created_at: Date;
      updated_at: Date;
      status: string;
      _latitude: number;
      _longitude: number;
      _status?: string;
    };
  };
}
export default function HeadComponent({ data }: Props) {
  return (
    <div className="information">
      <div>
        <p className="information-title">ID</p>
        <p className="information-value mt-3" style={{ padding: 0 }}>
          {" "}
          {data.data && data.data._id.$oid}
        </p>
      </div>
      <div>
        <p className="information-title">Status</p>
        <p className="information-value mt-3" style={{ padding: 0 }}>
          {data.data ? data.data?._status ?? data.data.status : "-"}
        </p>
      </div>
      <div>
        <p className="information-title">Date Received</p>
        <p className="information-value mt-3" style={{ padding: 0 }}>
          {data.data && data.data.created_at}
        </p>
      </div>
      <div>
        <p className="information-title">Location</p>
        <p className="information-value mt-3" style={{ padding: 0 }}>
          {data.data && (data.data._latitude ?? 0)} ,{" "}
          {data.data && (data.data._longitude ?? 0)}
        </p>
      </div>
      <div>
        <p className="information-title">Last Activity</p>
        <p className="information-value mt-3" style={{ padding: 0 }}>
          {data.data && (data.data.updated_at ?? "N/A")}
        </p>
      </div>
    </div>

    // <Grid columns={3} textAlign='center'>

    //   <Grid.Row verticalAlign='middle'>
    //     <Grid.Column>
    //       <Header size='small' textAlign="left">
    //         {data.data && data.data._id.$oid}<br />
    //       </Header>
    //     </Grid.Column>

    //     <Grid.Column>
    //       <Header size="small" textAlign="left">
    //         Application Received: {data.data && data.data.created_at}
    //       </Header>
    //     </Grid.Column>

    //     <Grid.Column>
    //       <Header size='small' textAlign="right">
    //         Status: {data.data && data.data.status}
    //       </Header>
    //     </Grid.Column>
    //   </Grid.Row>
    // </Grid>
  );
}
