import { officeTypes } from "../action/officeAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case officeTypes.OFFICE_LOADED:
      return action.payload;

    case officeTypes.OFFICE_UNLOADED:
      return initialState;
    case officeTypes.ADD_OFFICE:
      return { ...state };
    case officeTypes.EDIT_OFFICE:
      return state;
    case officeTypes.DELETE_OFFICE:
      return state;

    default:
      return state;
  }
};
