import React, { ReactElement} from "react";
import { Embed, Modal } from "semantic-ui-react";


interface Props {
  setOpenModal: Function;
  openModal: boolean;
}

export default function FeedbackModal(props: Props): ReactElement {
  //const dispatch = useDispatch();

  return (
    <Modal
      key="chat-modal"
      open={props.openModal === false ? false : true}
      onClose={() => props.setOpenModal(false)}
      className="modal-customized"
      size="large"
      basic
      dimmer="blurring"
    >
      <Modal.Header></Modal.Header>
      <Modal.Content>
        <div className="ui active embed">
          <div className="embed">
            <iframe allowFullScreen={true} frameBorder="0" height="100%" scrolling="no" 
              src="https://chat-forms.com/forms/1624282560314-pu3/index.html" key="chat-modal-iframe"
              title="Chat for Support" width="100%" style={{padding: "10px"}}></iframe>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
