import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const sharingTypes = {
  SHARING_TYPE_LOADED: "SHARING_TYPE_LOADED",
  SHARING_TYPE_UNLOADED: "SHARING_TYPE_UNLOADED",
  ADD_SHARING_TYPE: "ADD_SHARING_TYPE",
  EDIT_SHARING_TYPE: "EDIT_SHARING_TYPE",
  DELETE_SHARING_TYPE: "DELETE_SHARING_TYPE",
  COLLAB_SHARING_TYPE: "COLLAB_SHARING_TYPE",
};

export const loadSharingTypePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: sharingTypes.SHARING_TYPE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch({
          type: sharingTypes.SHARING_TYPE_LOADED,
          payload: {"error": error},
        });
        onFinally();
      }
    );
  };
};

export const unloadSharingTypePage = () => {
  return {
    type: sharingTypes.SHARING_TYPE_UNLOADED,
    payload: {},
  };
};

export const addSharingType = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/sharing", payload).then(
      (data) => {
        dispatch({
          type: sharingTypes.ADD_SHARING_TYPE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editSharingType = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/sharing/${payload.id}?_method=PUT`, payload).then(
      (data) => {
        dispatch({
          type: sharingTypes.EDIT_SHARING_TYPE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeSharingType = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/sharing/${id}`).then(
      (data) => {
        dispatch({
          type: sharingTypes.DELETE_SHARING_TYPE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const collabSharingType = (url: string, payload: any) => {
  return async (dispatch: any) => {
    apiService.update(url, payload).then(
      (data) => {
        dispatch({
          type: sharingTypes.COLLAB_SHARING_TYPE,
          payload: data,
        });
        dispatch(alertActions.success("Saved"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};