import React, { useState, useEffect, Component } from "react";
import {
 
  Header,
  Breadcrumb,
  Message,
  Divider,
} from "semantic-ui-react";
import {
  loadModuleLessonPage,
  unloadModuleLessonPage,
} from "../../redux/action/moduleLessonAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  module_lessons: {
    name: string;
    id: number;
    training_module_id: number;
    description: string;
    image: string;
    training_module?: {
      name: string;
      description: string;
    };
  };
}

export default function ModuleLessonInfo(props: Props) {
  const [apiUrl] = useState(`/module_lesson/${props.match.params.id}`);
  const module_lessons = useSelector(
    (state: RootState) => state.module_lessons
  );

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadModuleLessonPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadModuleLessonPage());
    };
  }, [apiUrl, dispatch]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Module Lesson</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {module_lessons === undefined ||
        Object.keys(module_lessons).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>{module_lessons.name}</Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <div className="app-card-org" key="2">
      <Header>Module Lesson Info</Header>
      <Divider />
      {module_lessons === undefined ||
      Object.keys(module_lessons).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <Info module_lessons={module_lessons} />
      )}
    </div>,
  ];
}

class Info extends Component<RootState> {
  render() {
    const data = this.props.module_lessons;
    return (
      <div>
        <b>{data.name}</b>
        <p>{data.description}</p>
        {data.image ? (
          <img
            src={data.image}
            alt="Failed to Load"
            className="ui medium image"
          />
        ) : (
          ""
        )}
        <br />
        <b>
          Training Module:{" "}
          {data.training_module
            ? data.training_module.name
            : "No Training Module Linked"}
        </b>
        <p>
          Description:{" "}
          {data.training_module
            ? data.training_module.description
            : "No Description Available"}
        </p>
      </div>
    );
  }
}
