import { createStore, applyMiddleware, compose } from "redux";
import * as Sentry from "@sentry/react";

import thunk from "redux-thunk";
import rootReducer from "./reducer";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return compose(applyMiddleware(thunk), sentryReduxEnhancer);
  } else {
    // Enable additional logging in non-production environments.
    return compose(
      applyMiddleware(thunk),
      sentryReduxEnhancer,
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        : (f: any) => f
    );
  }
};

const store = createStore(rootReducer, getMiddleware());

export default store;
