import { languageTypes } from "../action/languageAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case languageTypes.LANGUAGE_PAGE_LOADED:

            return action.payload;

        case languageTypes.LANGUAGE_PAGE_UNLOADED:
            return initialState;
        case languageTypes.ADD_LANGUAGE:
           
             return { ...state};
        case languageTypes.EDIT_LANGUAGE:

            return state;
        case languageTypes.DELETE_LANGUAGE:
           
            return state;

        default:
            return state;
    }
};
