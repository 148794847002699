import React, { ReactElement } from "react";

interface Props {
  data: any;
  label: string;
}

function AddressComponent({ data, label }: Props): ReactElement {
  const GetAddress = () => {
    let type = typeof data;

    if (type === "string") {
      return <div>{data.replace(/<(.|\n)*?>/g, "")}</div>;
    } else if (Array.isArray(data)) {
      return <div>{data.length === 0 ? "-" : JSON.stringify(data)}</div>;
    } else if (type === "object") {
      return <div>{data?.display_name ?? JSON.stringify(data)}</div>;
    } else {
      return <div>{data}</div>;
    }
  };

  return (
    <div className="rows line-spacing" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        <GetAddress />
      </div>
    </div>
  );
}

export default AddressComponent;
