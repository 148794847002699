import React, { useState, useEffect, Component } from "react";
import {
  Segment,
  Header,
  Breadcrumb,
  Message,
  Divider,
} from "semantic-ui-react";
import {
  loadAppUserPage,
  unloadAppUserPage,
} from "../../../redux/action/appUsersAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  app_users: {
    name: string;
    id: number;
    address: string;
    email: string;
    phone_no: string;
    imei_no: string;
    referral_code: number;
    app_id: number;
    app?: {
      app_name: string;
      created_at: string;
    };
    transactions?: {
      id: number;
      imei_no: string;
      points: number;
    }[];
  };
}

export default function AppUserInfo(props: Props) {
    // console.log(props.match.params.id);
  const [apiUrl] = useState(`/app_user/${props.match.params.id}`);
  const [loading, setLoading] = useState(false);

  const app_users = useSelector(
    (state: RootState) =>  state.app_users  
  );
  

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAppUserPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAppUserPage());
    };
  }, [apiUrl, dispatch]);

  return [
      
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section link as={Link} to="/app_user" >App User</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {app_users === undefined ||
        Object.keys(app_users).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {app_users.name}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <Segment key="2">
        {app_users === undefined ||
      Object.keys(app_users).length <= 0 || app_users.transactions === undefined ? (
        <Message>Loading ....</Message>
      ) : (
        <TransactionInfo app_users={app_users} />
      )}
     
    </Segment>,
  ];
}

class TransactionInfo extends Component<RootState> {
  render() {
    
    const data = this.props.app_users;
    
    const totalPointsCollected = (transactions:any) => {
        let total_points = 0;
        transactions.forEach((transaction:any) => {
            total_points = total_points + transaction.points
        })

        return total_points;
    }
    
    const renderTransactionDetails = (transactions:any) => {
        
        if(transactions.length > 0){
            return transactions.map( (transaction:any, index:number) => {
                return (
                    <div id={transaction.id}>
                        {index === 0 ? <div><h5>Summary Of Transactions Carried Out</h5><br /></div> : ""}
                        
                        <table className="table border table-striped col-sm-12 col-lg-6">
                            <thead
                                style={{ backgroundColor: "var(--color-blue)", color: "white" }}
                            >
                                <tr>
                                    <td className="same">Transaction Number</td>
                                    <td>{index+1}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="same">IMEI No of Referred User</td>
                                    <td>{transaction.imei_no}</td>
                                </tr>
                                <tr>
                                    <td className="same">Points collected</td>
                                    <td>{transaction.points}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                )
            })
        }
        else{
            return <p>No records of Transaction associated with the user found!!!</p>;
        }
    }
    
    return (
      <div>
          <Header>Transaction Details of {data.name}</Header>
          <br/>
          <h6>Referral Code: {data.referral_code}</h6>
          <h6>Total Number of Transactions Till Date: {data.transactions?.length}</h6>
          <h6>Total Number of Points Collected: {totalPointsCollected(data.transactions)}</h6>

          <Divider />
          <Segment>
              
            {renderTransactionDetails(data.transactions)}
          </Segment>
           
        
      </div>
    );
  }
}
