import { alertActions } from "./alertAction";
import { apiService } from "../../services";
import { userConstants } from "./authAction";

export const allTypes = {
  FETCH_DATA: "FETCH_DATA",
  DATA_UNLOADED: "DATA_UNLOADED",
  POST_DATA: "POST_DATA",
};

export const fetchData = (url: string, onSuccess: Function = () => {}, onError: Function = () => {}) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: allTypes.FETCH_DATA,
          payload: data,
        });
        onSuccess();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onError();
      }
    );
  };
};

export const postData = (url: string, payload: object, profile: boolean = false, onSuccess: Function = () => {}, onError: Function = () => {}) => {
  return async (dispatch: any) => {
    apiService.save(url, payload).then(
      (data) => {
        dispatch({
          type: allTypes.POST_DATA,
          payload: data,
        });
        if (profile) {
          dispatch(success(data));
          localStorage.setItem("user", JSON.stringify(data));
        }
        dispatch(alertActions.success("Data Updated successfully"));
        onSuccess();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onError();
      }
    );
  };
};

export const performDeleteData = (
  url: string,
  onSuccess: Function = () => {},
  onError: Function = () => {}
) => {
  return async (dispatch: any) => {
    apiService._delete(url).then(
      (data) => {
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadData = () => {
  return {
    type: allTypes.DATA_UNLOADED,
    payload: {},
  };
};

function success(user: any) {
  return { type: userConstants.LOGIN_SUCCESS, user };
}
