import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const sliderImageTypes = {
  SLIDER_IMAGE_LOADED: "SLIDER_IMAGE_LOADED",
  SLIDER_IMAGE_UNLOADED: "SLIDER_IMAGE_UNLOADED",
  ADD_SLIDER_IMAGE: "ADD_SLIDER_IMAGE",
  EDIT_SLIDER_IMAGE: "EDIT_SLIDER_IMAGE",
  DELETE_SLIDER_IMAGE: "DELETE_SLIDER_IMAGE",
};

export const loadSliderImagePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: sliderImageTypes.SLIDER_IMAGE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadSliderImagePage = () => {
  return {
    type: sliderImageTypes.SLIDER_IMAGE_UNLOADED,
    payload: {},
  };
};

export const addSliderImage = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/slider_image", payload).then(
      (data) => {
        dispatch({
          type: sliderImageTypes.ADD_SLIDER_IMAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editSliderImage = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/slider_image/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: sliderImageTypes.EDIT_SLIDER_IMAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeSliderImage = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/slider_image/delete/${id}`).then(
      (data) => {
        dispatch({
          type: sliderImageTypes.DELETE_SLIDER_IMAGE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
