import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const floorTypes = {
  FLOOR_LOADED: "FLOOR_LOADED",
  FLOOR_UNLOADED: "FLOOR_UNLOADED",
  ADD_FLOOR: "ADD_FLOOR",
  EDIT_FLOOR: "EDIT_FLOOR",
  DELETE_FLOOR: "DELETE_FLOOR",
};

export const loadFloorPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: floorTypes.FLOOR_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadFloorPage = () => {
  return {
    type: floorTypes.FLOOR_UNLOADED,
    payload: {},
  };
};

export const addFloor = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/floor", payload).then(
      (data) => {
        dispatch({
          type: floorTypes.ADD_FLOOR,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editFloor = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/floor/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: floorTypes.EDIT_FLOOR,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeFloor = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/floor/delete/${id}`).then(
      (data) => {
        dispatch({
          type: floorTypes.DELETE_FLOOR,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
