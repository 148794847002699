import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const DocumentationTypes = {
  DOCUMENTATION_TYPE_LOADED: "DOCUMENTATION_TYPE_LOADED",
  DOCUMENTATION_TYPE_UNLOADED: "DOCUMENTATION_TYPE_UNLOADED",
  ADD_DOCUMENTATION_TYPE: "ADD_DOCUMENTATION_TYPE",
  EDIT_DOCUMENTATION_TYPE: "EDIT_DOCUMENTATION_TYPE",
  DELETE_DOCUMENTATION_TYPE: "DELETE_DOCUMENTATION_TYPE",
  COLLAB_DOCUMENTATION_TYPE: "COLLAB_DOCUMENTATION_TYPE",
};

export const loadDocumentationTypePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: DocumentationTypes.DOCUMENTATION_TYPE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(alertActions.error(error.toString()));
        dispatch({
          type: DocumentationTypes.DOCUMENTATION_TYPE_LOADED,
          payload: {"error": error},
        });
        
      }
    );
  };
};

export const unloadDocumentationTypePage = () => {
  return {
    type: DocumentationTypes.DOCUMENTATION_TYPE_UNLOADED,
    payload: {},
  };
};

export const addDocumentationType = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/documentation", payload).then(
      (data) => {
        dispatch({
          type: DocumentationTypes.ADD_DOCUMENTATION_TYPE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editDocumentationType = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/documentation/${payload.id}?_method=PUT`, payload).then(
      (data) => {
        dispatch({
          type: DocumentationTypes.EDIT_DOCUMENTATION_TYPE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeDocumentationType = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/documentation/${id}`).then(
      (data) => {
        dispatch({
          type: DocumentationTypes.DELETE_DOCUMENTATION_TYPE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};