import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const awarenessNewsTypes = {
  AWARENESS_NEWS_LOADED: "AWARENESS_NEWS_LOADED",
  AWARENESS_NEWS_UNLOADED: "AWARENESS_NEWS_UNLOADED",
  ADD_AWARENESS_NEWS: "ADD_AWARENESS_NEWS",
  EDIT_AWARENESS_NEWS: "EDIT_AWARENESS_NEWS",
  DELETE_AWARENESS_NEWS: "DELETE_AWARENESS_NEWS",
};

export const loadAwarenessNewsPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: awarenessNewsTypes.AWARENESS_NEWS_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAwarenessNewsPage = () => {
  return {
    type: awarenessNewsTypes.AWARENESS_NEWS_UNLOADED,
    payload: {},
  };
};

export const addAwarenessNews = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/awareness_news", {...payload,extra}).then(
      (data) => {
        dispatch({
          type: awarenessNewsTypes.ADD_AWARENESS_NEWS,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAwarenessNews = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/awareness_news/update/${payload.id}`, {...payload,extra}).then(
      (data) => {
        dispatch({
          type: awarenessNewsTypes.EDIT_AWARENESS_NEWS,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAwarenessNews = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/awareness_news/delete/${id}`).then(
      (data) => {
        dispatch({
          type: awarenessNewsTypes.DELETE_AWARENESS_NEWS,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
