import React, { useReducer, useState } from "react";
//import { Link} from "react-router-dom";
import { Button, Form, Input, Message, Loader, Image } from "semantic-ui-react";
import Layout from "./Layout";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../redux/action/authAction";
import FeedbackModal from "../Modal/FeedbackModal";
import feedackImage from "../../assets/images/bot.gif";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { alertActions } from "../../redux/action/alertAction";
const initialState = {
  email: "",
  password: "",
};
interface Props {
  history: {
    go: Function;
  };
  location: {
    state: {
      from: {
        pathname: string;
      };
    };
  };
}

export default function Login(props: Props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { email, password } = state;
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const auth = useSelector((state: any) => state.authentication);
  const { loggingIn } = auth;
  const dispatches = useDispatch();

  //  useEffect(() => {
  //     dispatches(authActions.logout());
  //      // eslint-disable-next-line
  //  }, [])

  useEffect(() => {
    if (
      auth &&
      auth.user &&
      auth.user.access_token &&
      auth.user.data &&
      localStorage.getItem("user") &&
      sessionStorage.getItem("verified")
    ) {
      dispatch(alertActions.success("Welcome Back."));
      history.push("/");
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value }: any = e.target;
    dispatch({ type: name, value });
  };

  const clearForm = () => {
    dispatch({ type: "reset" });
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmitted(true);
    let back =
      props.location &&
      props.location.state &&
      props.location.state.from &&
      props.location.state.from.pathname
        ? props.location.state.from.pathname
        : undefined;
    if (email && password) {
      dispatches(
        authActions.login(email, password, back, () => {
          clearForm();
        })
      );
    }
  }

  return (
    <Layout header="Dashboard Log in">
      <FeedbackModal openModal={openModal} setOpenModal={setOpenModal} />
      <div
        className="fab"
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {" "}
        <Image circular src={feedackImage} />{" "}
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Field required>
          <Form.Input
            required
            fluid
            icon="user"
            control={Input}
            iconPosition="left"
            placeholder="E-mail address"
            className="auth-input-field"
            name="email"
            value={email}
            onChange={handleChange}
          />
          {submitted && !email && (
            <Message className="help-block">Email is required</Message>
          )}
        </Form.Field>

        <Form.Field required>
          <Form.Input
            required
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            name="password"
            className="auth-input-field"
            value={password}
            onChange={handleChange}
          />
          {submitted && !password && (
            <Message className="help-block">Password is required</Message>
          )}
        </Form.Field>

        <Button
          type="submit"
          className="button-primary"
          fluid
          loading={loggingIn}
          disabled={loggingIn}
          size="large"
        >
          Login
        </Button>

        {/* {loggingIn && <Loader active inline="centered" size="large" />} */}
      </Form>

      {/* <Form.Group unstackable  widths={2}>
                <Link  className="left floated" to="/signup">Forgot Password?</Link>
                <Link  to="/signup">Not Registered?</Link>
            </Form.Group> */}
    </Layout>
  );
}

function reducer(state: any, action: any) {
  if (action.type === "reset") {
    return initialState;
  }

  const result = { ...state };
  result[action.type] = action.value;
  return result;
}
