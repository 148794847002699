import React, { useState, useEffect } from "react";
import { Segment, Breadcrumb, Form, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editStep, addStep } from "../../redux/action/stepAction";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { Formio } from "@formio/react";
import { FormattedMessage } from "react-intl";
import { AppConfig } from "../../config";
import _ from "lodash";
import StatCalculations, { StatType } from "../../components/StatCalculations";

interface Props {
  id: number;
  type: string;
  data?: undefined | Data;
  slug?: string;
}

interface fdataType {
  newData: {
    stage_id: number;
    id: number;
    name: string;
    type: string;
    sub_type: string | null;
    showmenu: boolean;
    return: boolean;
    status: string;
    pre_status: string;
    filtering_status: string;
    data_link: string;
    form_link: string;
    order: number;
    containers_to_show: string[];
    users: number[];
    draftusers: number[];
    filter_by_status: boolean;
    fulcrum_token?: string;
    fulcrum_choice_list_id?: string;
    instruction?: string;
    training_module_id?: number;
    status_field?: string;
    can_sync_fulcrum_choices?: boolean;
    stats: StatType[];
  };
}

interface Data {
  stage_id: number;
  id: number;
  name: string;
  type: string;
  sub_type: string | null;
  show_menu: boolean;
  return: boolean;
  status: string;
  pre_status: string;
  filtering_status: string;
  data_link: string;
  form_link: string;
  order: number;
  containers_to_show: string[];
  field_component: string;
  users: {
    id: number;
  }[];
  draftusers: number[];
  filter_by_status: boolean;
  fulcrum_token?: string;
  fulcrum_choice_list_id?: string;
  instruction?: string;
  training_module_id?: number;
  status_field?: string;
  can_sync_fulcrum_choices?: boolean;
  stats: StatType[];
}

export default function StepForm(props: Props) {
  const [fdata, setData] = useState<fdataType>({
    newData: {
      stage_id: props.id,
      id: 0,
      name: "",
      type: "",
      sub_type: null,
      showmenu: false,
      return: false,
      status: "",
      pre_status: "",
      filtering_status: "",
      data_link: "",
      form_link: "",
      order: 0,
      containers_to_show: [],
      users: [],
      draftusers: [],
      filter_by_status: false,
      fulcrum_token: "",
      fulcrum_choice_list_id: "",
      instruction: "",
      training_module_id: undefined,
      status_field: "status",
      can_sync_fulcrum_choices: false,
      stats: [],
    },
  });
  const [schemaOptions, setSchemaOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  //  const [type, setType] = useState({ display: 'form' });
  const [formbuilderDisplay, setFormbuilderDisplay] = useState({
    display: "none",
  });
  const [component, setcomponent] = useState("");
  const [draftEnable, setDraftEnable] = useState(false);
  const [role, setRole] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [draftuserOptions, setDraftUserOptions] = useState([]);
  const [trainingModuleOptions, setTrainingModuleOptions] = useState([]);
  const [formBuilders, setFormBuilders] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    if (props.type === "Edit" && props.data !== undefined) {
      //  console.log(props.data)

      var ndata = props.data;

      if (ndata.draftusers !== null) {
        if (ndata.draftusers.length > 0) {
          setDraftEnable(true);
        }
      }
      // console.log(ndata)
      if (ndata.type === "details_field") {
        setFormbuilderDisplay({ display: "" });
        let component = JSON.parse(ndata.field_component);
        setcomponent(component);
        FormioForm(component);
      }

      setData({
        newData: {
          stage_id: ndata.stage_id,
          id: ndata.id,
          name: ndata.name,
          type: ndata.type,
          sub_type: ndata.sub_type ?? null,
          showmenu: ndata.show_menu,
          return: ndata.return,
          status: ndata.status,
          pre_status: ndata.pre_status,
          filtering_status: ndata.filtering_status ?? "",
          data_link: ndata.data_link,
          form_link: ndata.form_link,
          order: ndata.order,
          containers_to_show: ndata.containers_to_show ?? [],
          draftusers: ndata.draftusers !== null ? ndata.draftusers : [],
          users: ndata.users.map((data: { id: number }) => {
            return data.id;
          }),
          filter_by_status: ndata.filter_by_status ? true : false,
          fulcrum_token: ndata.fulcrum_token,
          fulcrum_choice_list_id: ndata.fulcrum_choice_list_id,
          instruction: ndata.instruction ?? "",
          training_module_id: ndata.training_module_id,
          status_field: ndata.status_field ?? "status",
          can_sync_fulcrum_choices: ndata.can_sync_fulcrum_choices ?? false,
          stats: ndata.stats ?? [],
        },
      });

      //  console.log(ndata)
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let arr = userOptions.filter(function (el: any) {
      return fdata.newData.users.includes(el.value);
    });
    setDraftUserOptions(arr);
  }, [fdata.newData.users, userOptions]);

  const getData = () => {
    apiService.getAll(`/step/create/${props.id}`).then(
      (data) => {
        setFormBuilders(data.formbuilder);

        var final_schema = data.formbuilder.map(
          (data: { slug: string; name: string }) => {
            return {
              key: data.slug,
              value: data.slug,
              text: data.name,
            };
          }
        );
        var final_user = data.users.map(
          (data: {
            id: number;
            first_name: string;
            last_name: string;
            formbuilder_role: number | null;
          }) => {
            return {
              key: data.id,
              value: data.id,
              role: data.formbuilder_role,
              text: `${data.first_name} ${data.last_name}`,
            };
          }
        );
        var final_roles = data.roles.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );

        setRoleOptions(final_roles);

        setSchemaOptions(final_schema);
        setUserOptions(final_user);

        setTrainingModuleOptions(
          data.training_modules.map((d: any) => ({
            key: d.id,
            value: d.id,
            text: d.name,
          }))
        );
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const clearForm = () => {
    setData({
      newData: {
        stage_id: props.id,
        id: 0,
        name: "",
        type: "",
        sub_type: null,
        showmenu: false,
        return: false,
        status: "",
        pre_status: "",
        filtering_status: "",
        data_link: "",
        form_link: "",
        order: 0,
        containers_to_show: [],
        users: [],
        draftusers: [],
        filter_by_status: false,
        fulcrum_token: "",
        fulcrum_choice_list_id: "",
        instruction: "",
        training_module_id: undefined,
        status_field: "status",
        can_sync_fulcrum_choices: false,
        stats: [],
      },
    });
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    if (name === "status" || name === "filtering_status") {
      if (Array.isArray(value)) {
        value = value
          .map((v: string) => v.trim())
          .join(",")
          .trim();
      } else if (typeof value === "string") {
        value = value.trim();
      }
    }

    const merge: any = {};

    // On Data Link (form) changes
    if (name === "data_link" || name === "can_sync_fulcrum_choices") {
      let form_slug = name === "data_link" ? value : fdata.newData.data_link;

      if (
        fdata.newData.type ===
          "sync_parent_form_identifier_to_fulcrum_choices" ||
        fdata.newData.type === "details_field" ||
        fdata.newData.type === "details"
      ) {
        let form: any = formBuilders.find((f: any) => f.slug === form_slug);

        if (
          fdata.newData.type ===
          "sync_parent_form_identifier_to_fulcrum_choices"
        ) {
          if (value && !form) {
            return alert("⚠ Invalid Form Selected");
          } else if (value && !form.parent_form_identifier) {
            return alert(
              "⚠ This Form has no Parent Form Identifier. And, cannot be linked."
            );
          } else {
            merge.parent = form?.parent ?? null;
            merge.parent_form_identifier = form?.parent_form_identifier ?? null;
          }
        } else {
          merge.parent = form?.parent ?? null;
          merge.parent_form_identifier = form?.parent_form_identifier ?? null;
        }
      } else {
        merge.parent = null;
        merge.parent_form_identifier = null;
        merge.fulcrum_token = "";
        merge.fulcrum_choice_list_id = "";
      }
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
        ...merge,
      }),
    });
  };

  const handleTypeChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    var value = result.type === "checkbox" ? result.checked : result.value;
    var name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
        parent: null,
        parent_form_identifier: null,
        data_link: "",
      }),
    });

    if (value === "details_field") {
      FormioForm({ display: "form" });
      setFormbuilderDisplay({ display: "" });
    } else {
      setFormbuilderDisplay({ display: "none" });
    }
  };

  const handleRoleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    var value = result.value;

    let user_array = userOptions
      .filter(function (el: any) {
        return value.includes(el.role);
      })
      .map(function (d: { value: number }) {
        return d.value;
      });

    setRole(value);

    let new_array = _.uniq(fdata.newData.users.concat(user_array));

    let arr = userOptions.filter(function (el: any) {
      return new_array.includes(el.value);
    });

    setDraftUserOptions(arr);

    setData({
      newData: Object.assign({}, fdata.newData, {
        users: new_array,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (fdata.newData.id === 0) {
      dispatch(addStep({ ...fdata.newData, component }));
      clearForm();
      setFormbuilderDisplay({ display: "none" });
    } else dispatch(editStep({ ...fdata.newData, component }));
  };

  const FormioForm = (component: object) => {
    Formio.builder(document.getElementById("builder"), component, {
      builder: {
        premium: false,

        custom: {
          title: "Custom Fields",
          weight: 10,
          components: {
            image: {
              key: "image",
              title: "Image",
              icon: "image",
              schema: {
                label: "Image",
                key: "image",
                type: "file",
                storage: "url",
                input: true,
                image: true,
                url: `${AppConfig.apiUrl}/fileUpload`,
              },
            },
            file: {
              key: "file",
              title: "File",
              icon: "file",
              schema: {
                label: "File",
                key: "file",
                type: "file",
                storage: "url",
                input: true,

                url: `${AppConfig.apiUrl}/fileUpload`,
              },
            },
          },
        },

        layout: {
          components: {
            table: false,
            columns: false,
            panel: false,
            fieldset: true,
            well: false,
            tabs: false,
          },
        },
        advanced: {
          components: {
            survey: false,
          },
        },
        basic: {
          components: {
            hidden: true,
          },
        },
        data: false,
      },
    }).then(function (builder: any) {
      builder.on("saveComponent", function () {
        //console.log(builder.schema);
        setcomponent(builder.schema);
      });
    });
  };

  const typeOptions = [
    { key: "form", value: "form", text: "Show Form" },
    { key: "details", value: "details", text: "Details View" },
    {
      key: "details_field",
      value: "details_field",
      text: "Details with new field",
    },
    {
      key: "training_module",
      value: "training_module",
      text: "Training Module",
    },
  ];

  const subTypeOptions = [
    { key: "finance", value: "finance", text: "Finance" },
  ];

  const statusChoices = (): string[] =>
    fdata.newData.data_link
      ? (
          formBuilders.find(
            (form: any, idx: number) => form.slug === fdata.newData.data_link
          ) as any
        )?.status_choices ?? []
      : [];

  return (
    <div>
      <div key={1}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to="/stage">
            Stage
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to={`/step/${props.id}`}>
            Step
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>Step {props.type}</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div className="app-card-org" key={2}>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Field>
            <Form.Input
              fluid
              required
              type="text"
              name="name"
              label="Name"
              value={fdata.newData.name}
              onChange={handleChange}
              placeholder="Enter Step name..."
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field>
              <label>Order</label>
              <Form.Input
                fluid
                type="number"
                name="order"
                value={fdata.newData.order}
                onChange={handleChange}
                placeholder="Enter Order..."
              />
            </Form.Field>

            <Form.Field>
              <label>
                Containers/Sections To Show <small>(Data Name)</small>
              </label>
              <Form.Dropdown
                options={fdata.newData.containers_to_show.map((s) => ({
                  value: s.trim(),
                  key: s.trim(),
                  text: s.trim(),
                }))}
                name="containers_to_show"
                placeholder="Leave Empty To Show All Containers/Sections."
                search
                selection
                fluid
                multiple
                allowAdditions
                value={fdata.newData.containers_to_show}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label>Instruction</label>
            <Form.TextArea
              fluid
              name="instruction"
              value={fdata.newData.instruction}
              onChange={handleChange}
              placeholder="Provide some detailed instructions.."
            />
          </Form.Field>

          <Form.Field>
            <Form.Checkbox
              toggle
              required
              label={{ children: "Show in Menu ?" }}
              name="showmenu"
              checked={fdata.newData.showmenu}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select Users"
              required
              search
              clearable
              multiple
              selection
              options={userOptions}
              label="Give permission To"
              value={fdata.newData.users}
              name="users"
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Select
              fluid
              placeholder="Select Role"
              clearable
              multiple
              options={roleOptions}
              label="Role"
              value={role}
              name="role"
              onChange={handleRoleChange}
              width={5}
            />
            <Segment compact>
              <Form.Checkbox
                onChange={() => setDraftEnable(!draftEnable)}
                checked={draftEnable}
                label="Enable other users to edit draft?"
              />
            </Segment>

            <Form.Select
              disabled={draftEnable === false}
              fluid
              placeholder="Select permission"
              required
              search
              clearable
              multiple
              selection
              options={draftuserOptions}
              label="Give edit draft Permission To"
              value={fdata.newData.draftusers}
              name="draftusers"
              width={4}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <Form.Select
                required
                fluid
                search
                placeholder="Select Type"
                clearable
                options={typeOptions}
                label="Type"
                value={fdata.newData.type}
                name="type"
                onChange={handleTypeChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                search
                placeholder="Select Sub-Type"
                clearable
                options={subTypeOptions}
                label="Sub-Type"
                value={fdata.newData.sub_type ?? undefined}
                name="sub_type"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          {fdata.newData.type === "form" ? (
            <Form.Field>
              <Form.Select
                fluid
                search
                placeholder="Select Form Link"
                options={schemaOptions}
                label="Form Link"
                value={fdata.newData.form_link}
                name="form_link"
                onChange={handleChange}
              />
            </Form.Field>
          ) : null}

          {fdata.newData.type === "details" ||
          fdata.newData.type === "details_field" ||
          fdata.newData.type ===
            "sync_parent_form_identifier_to_fulcrum_choices" ? (
            <Form.Field>
              <Form.Select
                fluid
                search
                placeholder="Select Data Link"
                required
                options={schemaOptions}
                label="Select the Form (Data Link)"
                value={fdata.newData.data_link}
                name="data_link"
                onChange={handleChange}
              />
            </Form.Field>
          ) : null}

          {/* AUTO SUGGEST STATUS IF status_choice exists in the chosen data-link form.
          For e.g. in case of Fulcrum import if Status Field is active, its values be loaded. */}
          <datalist id="status_choices">
            {statusChoices().map((status: string) => (
              <option value={status} />
            ))}
          </datalist>

          {fdata.newData.type === "details_field" ? (
            <>
              <Form.Dropdown
                options={[
                  ...(fdata.newData.filtering_status &&
                  fdata.newData.filtering_status.length > 0
                    ? fdata.newData.filtering_status.split(",")
                    : []),
                  ...statusChoices(),
                ].map((s) => ({
                  value: s.trim(),
                  key: s.trim(),
                  text: s.trim(),
                }))}
                name="filtering_status"
                placeholder="Choose Status..."
                search
                selection
                fluid
                multiple
                allowAdditions
                label="Filter By Status"
                value={
                  fdata.newData.filtering_status &&
                  fdata.newData.filtering_status.length > 0
                    ? fdata.newData.filtering_status
                        .split(",")
                        .map((s) => s.trim())
                    : []
                }
                onChange={handleChange}
              />

              <small
                style={{
                  color: "green",
                  fontWeight: 700,
                  marginTop: "-12px",
                  marginBottom: "8px",
                  display: "block",
                  textTransform: "uppercase",
                  fontSize: "70%",
                }}
              >
                Supports Filtering by Multiple Comma Separated Status
              </small>

              <Form.Field>
                <Form.Checkbox
                  toggle
                  label={{
                    children:
                      "Filter Records List using provided status above ?",
                  }}
                  name="filter_by_status"
                  checked={fdata.newData.filter_by_status}
                  onChange={handleChange}
                />
              </Form.Field>
            </>
          ) : (
            ""
          )}

          {fdata.newData.type === "details_field" ? (
            <Form.Field>
              <Form.Input
                required
                fluid
                type="text"
                name="pre_status"
                label="Pre Status"
                value={fdata.newData.pre_status}
                onChange={handleChange}
                placeholder="Enter Pre Status..."
                list="status_choices"
              />
            </Form.Field>
          ) : null}

          {fdata.newData.type === "details" ||
          fdata.newData.type === "details_field" ||
          fdata.newData.type ===
            "sync_parent_form_identifier_to_fulcrum_choices" ? (
            <>
              <Form.Field>
                {fdata.newData.type === "details_field" ? (
                  <Form.Input
                    fluid
                    type="text"
                    name="status"
                    label="Status"
                    value={fdata.newData.status}
                    onChange={handleChange}
                    placeholder="Enter Status..."
                    list="status_choices"
                  />
                ) : (
                  ""
                )}

                {fdata.newData.type === "details" ||
                fdata.newData.type ===
                  "sync_parent_form_identifier_to_fulcrum_choices" ? (
                  <>
                    <Form.Dropdown
                      options={[
                        ...(fdata.newData.status &&
                        fdata.newData.status.length > 0
                          ? fdata.newData.status.split(",")
                          : []),
                        ...statusChoices(),
                      ].map((s) => ({
                        value: s.trim(),
                        key: s.trim(),
                        text: s.trim(),
                      }))}
                      name="status"
                      placeholder="Enter Status..."
                      search
                      selection
                      fluid
                      multiple
                      allowAdditions
                      label="Status"
                      value={
                        fdata.newData.status && fdata.newData.status.length > 0
                          ? fdata.newData.status.split(",").map((s) => s.trim())
                          : []
                      }
                      onChange={handleChange}
                    />

                    <small
                      style={{
                        color: "green",
                        fontWeight: 700,
                        marginTop: "-12px",
                        display: "block",
                        textTransform: "uppercase",
                        fontSize: "70%",
                      }}
                    >
                      Supports Filtering by Multiple Comma Separated Status
                    </small>
                  </>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  type="text"
                  icon="code"
                  iconPosition="left"
                  name="status_field"
                  required
                  label="Status Form Field Name (e.g. _status)"
                  value={fdata.newData.status_field}
                  onChange={handleChange}
                  placeholder="Enter Status Form Field Name..."
                  disabled
                  title="Disabled at the moment."
                />
              </Form.Field>
            </>
          ) : null}

          {fdata.newData.type === "details" ||
          fdata.newData.type ===
            "sync_parent_form_identifier_to_fulcrum_choices" ? (
            <Form.Field>
              <Form.Checkbox
                toggle
                label={{
                  children:
                    fdata.newData.type === "details"
                      ? "Filter List by this status ?"
                      : "Only Sync identifiers with this status ?",
                }}
                name="filter_by_status"
                checked={fdata.newData.filter_by_status}
                onChange={handleChange}
              />
            </Form.Field>
          ) : null}

          {fdata.newData.type === "details_field" ? (
            <Form.Field>
              <Form.Checkbox
                toggle
                required
                label={{ children: "Return Possible ?" }}
                name="return"
                checked={fdata.newData.return}
                onChange={handleChange}
              />
            </Form.Field>
          ) : null}

          {fdata.newData.type === "training_module" ? (
            <Form.Field>
              <Form.Select
                fluid
                search
                placeholder="Select Training Module"
                required
                options={trainingModuleOptions}
                label="Training Module"
                value={fdata.newData.training_module_id}
                name="training_module_id"
                onChange={handleChange}
              />
            </Form.Field>
          ) : null}

          {fdata.newData.type === "details_field" ||
          fdata.newData.type === "details" ? (
            <Form.Field>
              <Form.Checkbox
                toggle
                required
                label={{
                  children:
                    "Also Add Button to Sync Identifiers to Fulcrum Choices List ?",
                }}
                name="can_sync_fulcrum_choices"
                checked={fdata.newData.can_sync_fulcrum_choices}
                onChange={handleChange}
              />
            </Form.Field>
          ) : null}

          <p className="m-0">
            {(fdata.newData as any).parent &&
              "PARENT FORM: " + (fdata.newData as any).parent}
          </p>
          <p className="m-0">
            {(fdata.newData as any).parent_form_identifier &&
              "IDENTIFIER: " + (fdata.newData as any).parent_form_identifier}
          </p>

          {fdata.newData.type ===
            "sync_parent_form_identifier_to_fulcrum_choices" ||
          ((fdata.newData.type === "details_field" ||
            fdata.newData.type === "details") &&
            fdata.newData.can_sync_fulcrum_choices) ? (
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  placeholder="・・・・・・・・・・・・・・・・・・・・・・・・・・・・"
                  required
                  label="Fulcrum API Token"
                  value={fdata.newData.fulcrum_token}
                  name="fulcrum_token"
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  placeholder="Leave empty to auto-generate a new list..."
                  label="Fulcrum Choices List ID"
                  value={fdata.newData.fulcrum_choice_list_id}
                  name="fulcrum_choice_list_id"
                  onChange={handleChange}
                />
              </Form.Field>
            </Form.Group>
          ) : null}

          <StatCalculations
            initialStats={fdata.newData.stats}
            autosuggest={_.uniq([
              ...(fdata.newData.filtering_status &&
              fdata.newData.filtering_status.length > 0
                ? fdata.newData.filtering_status.split(",")
                : []),
              ...(fdata.newData.status && fdata.newData.status.length > 0
                ? fdata.newData.status.split(",")
                : []),
              ...(fdata.newData.pre_status ? [fdata.newData.pre_status] : []),
              ...statusChoices(),
            ])}
            onChange={(stats) => {
              setData({
                newData: Object.assign({}, fdata.newData, {
                  stats: stats,
                }),
              });
            }}
          />
          <br></br>

          <div id="builder" style={formbuilderDisplay}></div>

          <Button
            labelPosition="left"
            icon
            className="submitButton"
            type="submit"
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>

          <Button
            icon
            className="clearButton"
            type="button"
            labelPosition="left"
            onClick={() => clearForm()}
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>
        </Form>
      </div>
    </div>
  );
}
