import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const trainingModuleTypes = {
  TRAINING_MODULE_LOADED: "TRAINING_MODULE_LOADED",
  TRAINING_MODULE_UNLOADED: "TRAINING_MODULE_UNLOADED",
  ADD_TRAINING_MODULE: "ADD_TRAINING_MODULE",
  EDIT_TRAINING_MODULE: "EDIT_TRAINING_MODULE",
  DELETE_TRAINING_MODULE: "DELETE_TRAINING_MODULE",
};

export const loadTrainingModulePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: trainingModuleTypes.TRAINING_MODULE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const unloadTrainingModulePage = () => {
  return {
    type: trainingModuleTypes.TRAINING_MODULE_UNLOADED,
    payload: {},
  };
};

export const addTrainingModule = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/training_module", payload).then(
      (data) => {
        dispatch({
          type: trainingModuleTypes.ADD_TRAINING_MODULE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editTrainingModule = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/training_module/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: trainingModuleTypes.EDIT_TRAINING_MODULE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeTrainingModule = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/training_module/delete/${id}`).then(
      (data) => {
        dispatch({
          type: trainingModuleTypes.DELETE_TRAINING_MODULE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
