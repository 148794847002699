import React, { useEffect, useState } from "react";
import {
  Segment,
  Breadcrumb,
  Header,
  Button,
  Icon,
  Step,
  Modal,
  Form,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, unloadData } from "../../redux/action/allAction";
import { Formio } from "@formio/react";
import { FormattedMessage } from "react-intl";
import { addFormdata, unloadFormData } from "../../redux/action/formDataAction";
import { AppConfig } from "../../config";
import DataList from "./DataList";
import { alertActions } from "../../redux/action/alertAction";
import { apiService } from "../../services";
import { normalizeStepTypeName, openTrainingStartWindow } from "../../helper";
import SyncFulcrumChoices from "./SyncFulcrumChoices";
import StepInfoComponent from "./component/StepInfoComponent";

export default function StepDetail(props: {
  match: { params: { id: number } };
  history: any;
}) {
  const [type, setType] = useState("");
  const history = useHistory<any>();
  const formdata = useSelector((state: any) => state.formdata);
  const step_details = useSelector((state: any) => state.all_ind);
  const [latitude, setlatitude] = useState<number>(0.0);
  const [longitude, setlongitude] = useState<number>(0.0);
  const [openPdfExport, setOpenPdfExport] = useState<boolean>(false);
  const [multiple, setMultiple] = useState<number>(1);
  const [relatedForm, setRelatedForm] = useState([]);
  const [slug, setSlug] = useState<string>("");
  const [dataId, setDataId] = useState<string>("");

  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false);
  const [completionMarkerLoading, setCompletionMarkerLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadStepDetails();

    return () => {
      dispatch(unloadData());
      dispatch(unloadFormData());
      //setSlug("");
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formdata.data_id !== undefined) {
      if (dataId === "") {
        setDataId(formdata.data_id);
        // alert(id)
        // console.log('11')
        checkRelatedForm(formdata.data_id);
      } else {
        checkRelatedForm(dataId);
      }
    } else {
    }
  }, [formdata]);

  useEffect(() => {
    // dispatch(fetchStep(props.match.params.id,props.match.params.slug))
    if (step_details != null) {
      setType(step_details.type);

      if (step_details.type === "form") {
        setSlug(step_details.form_link);
        if (history.location.state !== undefined) {
          let { id } = history.location.state;

          if (id !== undefined) {
            setDataId(id);
            // alert(id)
            // console.log('11')
            checkRelatedForm(id);
          }
        } else {
          //  console.log('12')
          checkRelatedForm("");
        }
      } else if (step_details.type === "details") {
        //fo
      } else {
        //
      }
    }

    // eslint-disable-next-line
  }, [step_details]);

  const loadStepDetails = () => {
    // dispatch(fetchStep(props.match.params.id,props.match.params.slug))
    dispatch(fetchData(`/step/edit/${props.match.params.id}`));
  };

  const checkRelatedForm = (id: string) => {
    //console.log('i am here' +  id)
    if (!step_details || !step_details.form_link) {
      return;
    }

    apiService
      .getAll(
        `/formBuilder/findRelated/${step_details.form_link}${
          id ? "/" + id : ""
        }`
      )
      .then(
        (data) => {
          // dispatch(fetchData(apiUrl));
          if (data.length === 0) {
            if (latitude === 0 && longitude === 0) {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                  showPosition(step_details.form_link, "", null, position);
                });
              }
            } else {
              //console.log('am here 4 '+final.slug)
              formio(step_details.form_link, "", null);
            }
          } else {
            setRelatedForm(data);
            let final = data.find(
              (element: any) => element.status === "Active"
            );
            if (final !== undefined) {
              setSlug(final.slug);

              if (latitude === 0 && longitude === 0) {
                //console.log("am here 3" + final.slug);
                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition(function (position) {
                    showPosition(final.slug, id, final.data, position);
                    //console.log('am here 5'+final.slug)
                  });
                }
              } else {
                //console.log('am here 4 '+final.slug)
                formio(final.slug, id, final.data);
              }
            } else {
              //setDataId('')
              //formio(step_details.form_link,'',null);
            }
          }

          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
  const handleStepClick = (item: {
    name: string;
    slug: string;
    status: string;
    id?: string;
    data?: any;
  }) => {
    formio(item.slug, dataId, item.data);
  };

  const handleResetForm = () => {
    setDataId("");
    if (step_details != null) {
      setSlug(step_details.form_link);
    }
    checkRelatedForm("");
  };
  const handleExportForm = () => {
    setOpenPdfExport(false);
    apiService
      .getfile(
        `/form/exportFormPdf/${step_details.form_link}?multiple=${multiple}`
      )
      .then(
        (data) => {
          setTimeout(() => {
            window.open(data);
          }, 100);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const showPosition = (
    data_slug: string,
    id: string,
    data: any,
    position: any
  ) => {
    setlatitude(position.coords.latitude);
    setlongitude(position.coords.longitude);

    formio(
      data_slug,
      id,
      data,
      position.coords.latitude,
      position.coords.longitude
    );
  };
  const formio = (
    slug_temp: string,
    data_id: string,
    data: any,
    lat?: number,
    long?: number
  ) => {
    if (!slug_temp) {
      return;
    }

    Formio.createForm(
      document.getElementById("formio"),
      `${AppConfig.apiUrl}/formBuilders/${slug_temp}`,
      {
        draft: true,
      }
    ).then(function (form: {
      nosubmit: boolean;
      submission: any;
      on: (arg0: string, arg1: (submission: any) => void) => void;
    }) {
      if (data === undefined) data = null;
      if (data !== null) {
        form.submission = {
          data: data,
        };
      }
      // Prevent the submission from going to the form.io server.
      form.nosubmit = true;

      // Triggered when they click the submit button.
      form.on("submit", function (submission) {
        if (lat) {
          submission["data"]["_latitude"] = lat;
          submission["data"]["_longitude"] = long;
        } else {
          submission["data"]["_latitude"] = latitude;
          submission["data"]["_longitude"] = longitude;
        }

        if (data === null && data_id !== "") {
          submission["data"]["_related_id"] = data_id;
        }
        //console.log({data_id});

        dispatch(addFormdata(slug_temp, submission));
        if (data === null && data_id === "") {
          // checkRelatedForm(data_id);
          //console.log('am here 1'+data_id)
          // } else {
          formio(slug_temp, data_id, null);
          //console.log('am here 2'+data_id)
        }
        //alert('Submission sent to custom endpoint. See developer console.');
      });
    });
  };
  const RenderRelatedForm = (): any => {
    if (relatedForm.length == 0) return null;

    return relatedForm.map(
      (
        item: {
          name: string;
          slug: string;
          status: string;
          id?: string;
        },
        index: number
      ) => (
        <Step
          active={item.status === "Active"}
          completed={item.status === "Completed" || item.status === "submitted"}
          disabled={item.status === "Disabled"}
          onClick={() => handleStepClick(item)}
          key={index}
        >
          <Step.Content>
            <Step.Title>
              {item.name}
              {dataId !== "" && index == 0 ? "(" + dataId + ")" : ""}
              {item.id ? "(" + item.id + ")" : ""}
            </Step.Title>
            <Step.Description style={{ textTransform: "capitalize" }}>
              {item.status.replace("submitted", "completed")}
            </Step.Description>
          </Step.Content>
        </Step>
      )
    );
  };

  const markAsCompleted = () => {
    setCompletionMarkerLoading(true);

    apiService
      .save(`/step/completed/${step_details.id}`)
      .then((res: any) => {
        dispatch(alertActions.success(res.message ?? "Success"));
        loadStepDetails();
        setTimeout(() => {
          setCompletionMarkerLoading(false);
        }, 1000);
      })
      .catch((err) => {
        dispatch(alertActions.error(err.message));
        setCompletionMarkerLoading(false);
      });
  };

  return [
    <Segment key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          {step_details && step_details.name}
        </Breadcrumb.Section>
      </Breadcrumb>

      {step_details.form_link && (
        <Button color="twitter" onClick={handleResetForm} floated="right">
          <Icon name="refresh" />
          Reset Form
        </Button>
      )}

      {step_details.form_link && (
        <Button
          color="facebook"
          onClick={() => setOpenPdfExport(true)}
          floated="right"
        >
          <Icon name="file pdf" />
          Export to pdf
        </Button>
      )}

      <Modal
        size="small"
        open={openPdfExport}
        onClose={() => setOpenPdfExport(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Export form in pdf format</h4>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              type="number"
              name="multiple"
              value={multiple}
              label="Enter maximum number of datagrid to show"
              onChange={(
                event: React.SyntheticEvent<HTMLElement, Event>,
                result?: any
              ) => setMultiple(result.value)}
              required
            />
          </Form.Field>
        </Modal.Content>

        <Modal.Actions>
          <Button
            className="clearButton"
            onClick={() => setOpenPdfExport(false)}
          >
            Cancel
          </Button>
          <Button className="submitButton" onClick={() => handleExportForm()}>
            Export
          </Button>
        </Modal.Actions>
      </Modal>

      {step_details.form_link && (
        <Button
          color="vk"
          as={Link}
          to={`/formbuilder/draft/${slug}`}
          floated="right"
        >
          <Icon name="vk" />
          View Drafts
        </Button>
      )}

      {relatedForm && relatedForm.length ? (
        <Step.Group ordered widths={3}>
          <RenderRelatedForm />
        </Step.Group>
      ) : (
        ""
      )}
    </Segment>,

    <Segment key="2">
      <div className="app-card-org">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              justifyContent: "flex-start",
              cursor: "pointer",
              // display: "inline-flex",
              display: "flex",
              flex: "1",
            }}
            onClick={() => {
              setIsShowingDetails(!isShowingDetails);
            }}
          >
            <Icon name={isShowingDetails ? "angle down" : "angle right"} />
            <Header style={{ margin: 0 }}>
              {!step_details?.name && "Loading..."}
              {step_details && step_details.name}
              {step_details &&
                step_details.type &&
                " (" + normalizeStepTypeName(step_details.type) + ")"}{" "}
              {step_details.filter_by_status && step_details.status ? (
                <p
                  style={{
                    color: "green",
                    fontWeight: 700,
                    fontSize: "80%",
                    margin: 0,
                  }}
                >
                  Filtering by Status: {step_details.status}
                </p>
              ) : step_details.type === "details_field" ? (
                <p
                  style={{
                    color: "green",
                    fontWeight: 700,
                    fontSize: "80%",
                    margin: 0,
                  }}
                >
                  Filtering by Pre-Status: {step_details.pre_status}
                </p>
              ) : (
                ""
              )}
              <p
                style={{ textAlign: "center", color: "orange", margin: 0 }}
                id="duplicate-or-not"
              ></p>{" "}
            </Header>
          </div>

          <div style={{ height: "100%", textAlign: "right" }}>
            {step_details && step_details?.completed !== undefined ? (
              <Button
                color={step_details?.completed ? "red" : "green"}
                rounded
                circular
                onClick={() => markAsCompleted()}
                loading={completionMarkerLoading}
                disabled={completionMarkerLoading}
              >
                <Icon name="checkmark" /> Mark Step as{" "}
                {step_details?.completed ? "Uncompleted" : "Completed"}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>

        {isShowingDetails ? (
          <div
            style={{
              marginTop: "0",
            }}
          >
            <StepInfoComponent step_details={step_details} />
          </div>
        ) : (
          ""
        )}
      </div>

      {/* Used for Rendering the Step Form or Related Forms ('form') */}
      <div id="formio"></div>

      {/* Used to show 'details' or list of records */}
      {type === "details" ? (
        <DataList
          slug={step_details.data_link}
          id={props.match.params.id}
          filter_by_status={step_details.filter_by_status}
          status={step_details.status}
          hideStepForms={true}
          status_field={step_details.status_field}
        />
      ) : (
        ""
      )}

      {/* Notice about 'details_field' type */}
      {type === "details_field" ? (
        <DataList
          slug={step_details.data_link}
          id={props.match.params.id}
          filter_by_status={true}
          status={
            step_details.filtering_status ?? step_details.pre_status ?? ""
          }
          hideStepForms={false}
          status_field={step_details.status_field}
        />
      ) : (
        ""
      )}

      {/* Used to 'sync_parent_form_identifier_to_fulcrum_choices' */}
      {type === "sync_parent_form_identifier_to_fulcrum_choices" ? (
        <SyncFulcrumChoices
          step_id={props.match.params.id}
          step_details={step_details}
          history={props.history}
        />
      ) : (
        ""
      )}

      {/* Used to 'training_module' */}
      {type === "training_module" ? (
        <>
          {step_details.training_module_id &&
          step_details.training_module_name ? (
            <div className="text-center">
              <hr />
              <p className="text-xl">
                <b>Training Module:</b> {step_details.training_module_name}
              </p>
              <div className="flex items-center justify-center space-x-4">
                <Button
                  primary
                  onClick={() => {
                    openTrainingStartWindow(step_details.training_module_id);
                  }}
                  size="large"
                >
                  Start Training
                  <Icon name="chevron right" />
                </Button>

                <Button
                  secondary
                  as={Link}
                  to={`/training/${step_details.training_module_id}`}
                  size="large"
                >
                  <Icon name="info circle" />
                  View Detail
                </Button>
              </div>
            </div>
          ) : (
            <small>No Linked Training Module Found.</small>
          )}
        </>
      ) : (
        ""
      )}
    </Segment>,
  ];
}
