import { alertActions } from './alertAction';
import { apiService } from '../../services';
import { Dispatch } from 'redux';
export const countryTypes = {

    COUNTRY_PAGE_LOADED: "COUNTRY_PAGE_LOADED",
    COUNTRY_PAGE_UNLOADED: "COUNTRY_PAGE_UNLOADED",
    ADD_COUNTRY: "ADD_COUNTRY",
    EDIT_COUNTRY: "EDIT_COUNTRY",
    DELETE_COUNTRY: "DELETE_COUNTRY",


}

export const loadCountryPage = (url: string, onFinally: Function) => {

    return async (dispatch: any) => {

        apiService.getAll(url)
            .then(
                data => {

                    dispatch({
                        type: countryTypes.COUNTRY_PAGE_LOADED,
                        payload: data
                    });
                    onFinally();

                },
                error => {

                    dispatch(alertActions.error(error.toString()));
                    onFinally();
                }
            );


    };
}



export const unloadCountryPage = () => {
    return {
        type: countryTypes.COUNTRY_PAGE_UNLOADED,
        payload: {}

    };
}

export const addCountry = (payload: object, onSuccess: Function, onError: Function) => {


    return async (dispatch: any) => {

        apiService.save('/country', payload)
            .then(
                data => {

                    dispatch({
                        type: countryTypes.ADD_COUNTRY,
                        payload: data
                    })
                    onSuccess();
                    // dispatch(alertActions.success('Data added successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };


}
interface payloadProps {
    id: number,
    name:string,
   
//    [key: string]: any
}

export const editCountry = (payload: payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch: any) => {

        apiService.update(`/country/update/${payload.id}`, payload)
            .then(
                data => {

                    dispatch({
                        type: countryTypes.EDIT_COUNTRY,
                        payload: data
                    })
                    onSuccess();
                    // dispatch(alertActions.success('Data Edited successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}



export const removeCountry = (id:number, onSuccess: Function, onError: Function) => {


    return async (dispatch:Dispatch) => {

        apiService._delete(`/country/delete/${id}`)
            .then(
                data => {

                    dispatch({
                        type: countryTypes.DELETE_COUNTRY,
                       
                    })
                    onSuccess();
                    // dispatch(alertActions.success('Data Deleted successfully'))

                },
                error => {
                    onError();
                    dispatch(alertActions.error(error.toString()));
                }
            );


    };

}



