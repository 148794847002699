import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Segment,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Tab,
  Divider,
  Input,
  Modal,
  Popup,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadLessonDataPage,
  unloadLessonDataPage,
  addLessonData,
  editLessonData,
  removeLessonData,
} from "../../redux/action/lessonDataAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { authHeader } from "../../helper";
import { AppConfig } from "../../config";
//import { DivIcon } from "leaflet";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  lesson_datas: {
    lesson_types: {
      name: string;
      id: number;
      module_lesson_id: number;
      description: string;
      image: string;
    }[];

    module_lessons: {
      id: number;
      name: string;
      description: string;
    }[];

    lesson_datas: {
      data: {
        id: number;
        name: string;
        description: string;
        video: string;
        document: string;
        pretest: QuestionAnswerType;
        duringtest: QuestionAnswerType;
        posttest: QuestionAnswerType;
        module_lesson_id: number;
        // lesson_type_id: number;
        visibility_status: string;
      }[];
      last_page: number;
    };
  };
}

type QuestionAnswerType = {
  id: number;
  title: string;
  image: string;
  answers: {
    id: number;
    title: string;
    correct: boolean;
    image: string;
  }[];
}[];

type moduleLessonsOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type visibilityStatusOptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

export default function LessonData() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      description: "",
      pretest: [],
      duringtest: [],
      posttest: [],
      document: "",
      video: "",
      module_lesson_id: 0,
      // lesson_type_id: 0,
      to_delete: [],
      visibility_status: "",
    },
  });

  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/lesson_data");
  const [search, setSearch] = useState<string>("");
  const lesson_datas = useSelector((state: RootState) => state.lesson_datas);
  const [total, setTotal] = useState(1);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const [moduleLessonOptions, setModuleLessonOptions] =
    useState<moduleLessonsOptionType>([]);

  const [visibilityStatusOptions, setVisibilityStatusOptions] =
    useState<visibilityStatusOptionType>([]);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadLessonDataPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadLessonDataPage());
    };
  }, [apiUrl, dispatch]);

  useEffect(() => {
    if (lesson_datas.module_lessons !== undefined) {
      var final_stage = lesson_datas.module_lessons.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setModuleLessonOptions(final_stage);

      setVisibilityStatusOptions([
        {
          key: "true",
          value: "true",
          text: "True",
        },
        {
          key: "false",
          value: "false",
          text: "False",
        },
      ]);
    }
  }, [lesson_datas]);

  useEffect(() => {
    if (lesson_datas.lesson_datas !== undefined) {
      setTotal(lesson_datas.lesson_datas.last_page);
    }
  }, [lesson_datas]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/lesson_data?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/lesson_data?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        module_lesson_id: 0,
        description: "",
        pretest: [],
        duringtest: [],
        posttest: [],
        document: "",
        video: "",
        // lesson_type_id: 0,
        to_delete: [],
        visibility_status: "",
      },
    });
    if (document.getElementById("document_field")) {
      //@ts-ignore
      document.getElementById("document_field")!.value = null;
    }
    if (document.getElementById("private_video_field")) {
      //@ts-ignore
      document.getElementById("private_video_field")!.value = null;
    }
  };

  const panes = [
    {
      menuItem: "Pre Test",
      render: () => (
        <Tab.Pane>
          <QuestionAnswer
            type="pretest"
            data={fdata.newData.pretest}
            newdata={fdata.newData}
            setdata={setData}
            setloading={setLoading}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "During",
      render: () => (
        <Tab.Pane>
          <QuestionAnswer
            type="duringtest"
            data={fdata.newData.duringtest}
            newdata={fdata.newData}
            setdata={setData}
            setloading={setLoading}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Post Test",
      render: () => (
        <Tab.Pane>
          <QuestionAnswer
            type="posttest"
            data={fdata.newData.posttest}
            newdata={fdata.newData}
            setdata={setData}
            setloading={setLoading}
          />
        </Tab.Pane>
      ),
    },
  ];

  const selectDataForEditing = (id: number) => {
    let lesson_data = lesson_datas.lesson_datas.data.find((v) => v.id === id);

    setData({
      newData: {
        id: lesson_data!.id,
        name: lesson_data!.name,
        module_lesson_id: lesson_data!.module_lesson_id,
        description: lesson_data!.description,
        // @ts-ignore
        pretest: lesson_data!.pretest,
        // @ts-ignore
        duringtest: lesson_data!.duringtest,
        // @ts-ignore
        posttest: lesson_data!.posttest,
        document: "",
        video: lesson_data!.video ? lesson_data!.video : "",
        // lesson_type_id: lesson_data!.lesson_type_id,
        to_delete: [],
        visibility_status: lesson_data!.visibility_status,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) await dispatch(addLessonData(fdata.newData, () => {
      dispatch(loadLessonDataPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else await dispatch(editLessonData(fdata.newData, () => {
      dispatch(loadLessonDataPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeLessonData(deleteId, () => {
      dispatch(loadLessonDataPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  function RenderList(datas: any) {
    if (datas.data.lesson_datas === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.lesson_datas.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.lesson_datas.data.map((data: any) => {
      
      let module_lesson = datas.data.module_lessons.find(
        (a: any) => a.id === data.module_lesson_id
      );
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>
            {data.pretest
              ? Object.keys(data.pretest).length > 0
                ? "Yes"
                : "No"
              : "No"}
          </Table.Cell>
          <Table.Cell>
            {data.duringtest
              ? Object.keys(data.duringtest).length > 0
                ? "Yes"
                : "No"
              : "No"}
          </Table.Cell>
          <Table.Cell>
            {data.posttest
              ? Object.keys(data.posttest).length > 0
                ? "Yes"
                : "No"
              : "No"}
          </Table.Cell>
          <Table.Cell>{module_lesson ? module_lesson.name : ""}</Table.Cell>
          <Table.Cell>
            {data.document && (
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href={data.document}
                icon
                labelPosition="right"
                className="mb-2"
              >
                Document
                <Icon name="arrow right" />
              </Button>
            )}
            {data.video && (
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href={data.video}
                icon
                labelPosition="right"
              >
                Video
                <Icon name="arrow right" />
              </Button>
            )}
          </Table.Cell>

          <Table.Cell>
            {data.visibility_status === "true" ? "Yes" : "No"}
          </Table.Cell>

          <Table.Cell>
            {/* <Popup
              trigger={
                <Button
                  as={Link}
                  to={`/lesson_data/${data.id}`}
                  icon
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                >
                  <Icon name="info circle" />
                </Button>
              }
              content="Info"
              position="top center"
            /> */}

            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Lesson Data</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Lesson Data
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Lesson Data
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-3"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Pretest</Table.HeaderCell>
            <Table.HeaderCell>Duringtest</Table.HeaderCell>
            <Table.HeaderCell>Posttest</Table.HeaderCell>
            <Table.HeaderCell>Module</Table.HeaderCell>
            {/* <Table.HeaderCell>Lesson</Table.HeaderCell> */}
            <Table.HeaderCell>Resources</Table.HeaderCell>
            <Table.HeaderCell>Visible</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {lesson_datas && <RenderList data={lesson_datas} />}
        </Table.Body>
      </Table>

      {lesson_datas.lesson_datas === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="large"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Lesson Data
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Lesson Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Lesson name..."
              />
            </Form.Field>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Module Lesson"
                required
                options={moduleLessonOptions}
                label="Module Lesson"
                value={fdata.newData.module_lesson_id}
                name="module_lesson_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.TextArea
                required
                type="text"
                name="description"
                label="Description"
                value={fdata.newData.description}
                onChange={handleChange}
                placeholder="Lesson Description"
                className="formLabel"
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="document"
                label="Add Documents/Resource/PDF"
                id="document_field"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Segment placeholder style={{ minHeight: "0rem" }}>
            <Grid columns={2} stackable textAlign="center">
              <Divider vertical>Or</Divider>

              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Form.Input
                    type="text"
                    name="video"
                    label="YouTube Video URL"
                    value={
                      //@ts-ignore
                      fdata.newData.video instanceof File
                        ? ""
                        : fdata.newData.video
                    }
                    onChange={handleChange}
                    placeholder="Video (Optional)"
                    style={{ minWidth: "100%" }}
                    disabled={
                      //@ts-ignore
                      fdata.newData.video instanceof File
                    }
                  />
                </Grid.Column>

                <Grid.Column>
                  <Form.Field style={{ maxWidth: "100%" }}>
                    <Form.Input
                      fluid
                      type="file"
                      name="video"
                      label="Upload Video By Self"
                      id="private_video_field"
                      onChange={handleChange}
                      disabled={
                        typeof fdata.newData.video === "string" &&
                        fdata.newData.video.trim().length > 0
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Form.Select
            fluid
            required
            placeholder="Select Visibilty Status For This Training Module"
            options={visibilityStatusOptions}
            label="Visibility For The Training Module"
            value={fdata.newData.visibility_status}
            name="visibility_status"
            onChange={handleChange}
          />

          <br />
          <Tab panes={panes} defaultActiveIndex={0} />
          <br />
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}

export interface QuestionAnswerProps {
  data: QuestionAnswerType;
  newdata: object;
  setdata: any;
  type: string;
  setloading: any;
  // questions: QuestionAnswerType;
  // setQuestions: any;
}

export interface QuestionAnswerState {}

class QuestionAnswer extends React.Component<
  QuestionAnswerProps,
  QuestionAnswerState
> {
  // state = { :  }
  render() {
    // console.log(this.props.data)
    return (
      <div style={{ width: "100%" }}>
        {/* {JSON.stringify(this.props.data)} */}
        {this.props.data &&
          this.props.data.map((q: any, index) => {
            q = this.props.data[index];
            return (
              <div key={q.id} className="mb-3">
                <Segment style={{ backgroundColor: "#f9fafb" }}>
                  <br />

                  <div style={{ padding: "5px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h4 style={{ display: "flex", width: "100%" }}>
                        <span style={{ marginTop: "7px" }}>
                          {index + 1 + ") "}
                        </span>
                        <input
                          type="text"
                          value={q.title}
                          className="form-control"
                          style={{
                            width: "100%",
                            marginLeft: "5px",
                            fontSize: "18px",
                            height: "44px",
                          }}
                          onChange={(e) => this.editQuestion(e, index)}
                        />
                      </h4>
                      <small
                        className="ml-2 btn-sm btn-danger"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.removeQuestion(e, index)}
                      >
                        Remove
                      </small>
                      <input
                        className="ml-2"
                        style={{ width: "auto" }}
                        type="file"
                        accept="image/*"
                        onChange={(e) => this.tempUrlForImage(e, index)}
                      />
                      <span
                        className={
                          q.image
                            ? "ml-1 badge badge-info"
                            : "ml-1 badge badge-danger"
                        }
                        title={q.image ? q.image : "NO IMAGE"}
                      >
                        {q.image ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => this.fetchSignedImage(e, q.image)}
                          >
                            VIEW IMAGE
                          </span>
                        ) : (
                          "NO IMAGE"
                        )}
                      </span>
                    </div>
                  </div>

                  <ul className="mt-1" style={{ listStyleType: "upper-alpha" }}>
                    {q.answers.map((a: any, a_index: number) => {
                      a = q.answers[a_index];
                      return (
                        <div
                          style={{
                            paddingTop: "10px",
                            paddingLeft: "35px",
                            paddingBottom: "0px",
                            marginBottom: "10px",
                            backgroundColor: "lavenderblush",
                          }}
                        >
                          <li key={q.id + "-" + a_index}>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <input
                                onChange={(e) =>
                                  this.toggleCorrect(e, index, a_index)
                                }
                                checked={a.correct}
                                className="ml-2 mr-2"
                                type="checkbox"
                              />
                              <p style={{ display: "flex" }}>
                                <input
                                  type="text"
                                  value={a.title}
                                  className="form-control"
                                  style={{ width: "100%" }}
                                  onChange={(e) =>
                                    this.editAnswer(e, index, a_index)
                                  }
                                />
                              </p>
                              <small
                                className="ml-2 btn-sm btn-danger"
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  this.removeAnswer(e, index, a_index)
                                }
                              >
                                Remove
                              </small>
                              <input
                                className="ml-2"
                                style={{ width: "100%" }}
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  this.tempUrlForImage(e, index, a_index)
                                }
                              />
                              <span
                                className={
                                  a.image
                                    ? "ml-1 badge badge-info"
                                    : "ml-1 badge badge-danger"
                                }
                                title={a.image ? a.image : "NO IMAGE"}
                              >
                                {a.image ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      this.fetchSignedImage(e, a.image)
                                    }
                                  >
                                    VIEW IMAGE
                                  </span>
                                ) : (
                                  "NO IMAGE"
                                )}
                              </span>
                            </p>
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                  {/* Answer add */}

                  <Divider
                    horizontal
                    inverted
                    style={{ color: "black", marginTop: "30px" }}
                  >
                    Add Answer Here
                  </Divider>

                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        placeholder="Type Answer Here"
                        type="text"
                        className="ml-4 form-control"
                        style={{ width: "100%" }}
                        id={"add-answer-val-" + index + "-" + this.props.type}
                      />
                      <small
                        className="ml-2 btn btn-success"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => this.addAnswer(e, index)}
                      >
                        Add Answer
                      </small>
                    </div>
                  </div>
                </Segment>
              </div>
            );
          })}
        <br />
        <Divider
          horizontal
          inverted
          style={{ color: "black", marginTop: "15px" }}
        >
          Add New Question Here
        </Divider>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              placeholder="Type Question Here"
              type="text"
              className="form-control"
              style={{ width: "100%" }}
              id={"add-question-val-" + this.props.type}
            />
            <small
              className="ml-2 btn btn-success"
              style={{ cursor: "pointer" }}
              onClick={(e) => this.addQuestion(e)}
            >
              Add Question
            </small>
          </div>
        </div>
      </div>
    );
  }

  tempUrlForImage(e: any, index: number, a_index: number = -1) {
    this.props.setloading(true);
    let headers = authHeader();
    let formdata = new FormData();
    formdata.append("image", e.target.files[0]);
    const requestOptions: object = {
      method: "POST",
      headers: headers,
      body: formdata,
    };
    const config = {
      apiUrl: AppConfig.apiUrl,
    };

    return fetch(`${config.apiUrl}/lesson_data/image_temp`, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let newData = this.props.newdata;
        console.log(newData);
        let newQuestions = this.props.data;
        if (a_index < 0) {
          if (
            newQuestions[index].image &&
            newQuestions[index].image.length > 0
          ) {
            newData["to_delete"].push(newQuestions[index].image);
          }
          newQuestions[index].image = data.image;
        } else {
          if (
            newQuestions[index].answers[a_index].image &&
            newQuestions[index].answers[a_index].image.length > 0
          ) {
            newData["to_delete"].push(
              newQuestions[index].answers[a_index].image
            );
          }
          newQuestions[index].answers[a_index].image = data.image;
        }

        newData[this.props.type] = [...newQuestions];
        this.props.setdata({ newData: newData });
        this.props.setloading(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Image failed to upload.");
        this.props.setloading(false);
      });
  }

  fetchSignedImage(e: any, url: string) {
    this.props.setloading(true);
    let headers = authHeader();
    let formdata = new FormData();
    formdata.append("url", url);
    // @ts-ignore
    formdata.append("id", this.props.newdata.id.toString());
    const requestOptions: object = {
      method: "POST",
      headers: headers,
      body: formdata,
    };
    const config = {
      apiUrl: AppConfig.apiUrl,
    };

    return fetch(`${config.apiUrl}/lesson_data/image_signed`, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data.url)
        window.open(data.url);
        this.props.setloading(false);
      })
      .catch((err) => {
        alert("Image failed to load :(");
        this.props.setloading(false);
      });
  }

  editQuestion(e: any, index: number) {
    let newQuestions = this.props.data;

    newQuestions = newQuestions.map((val, idx) => {
      if (idx === index) {
        let t_val = val;
        t_val.title = e.target.value;
      }
      return val;
    });
    let newData = this.props.newdata;
    newData[this.props.type] = [...newQuestions];
    this.props.setdata({ newData: newData });
  }

  editAnswer(e: any, que_index: number, ans_index: number) {
    let newQuestions = this.props.data;

    newQuestions[que_index].answers = newQuestions[que_index].answers.map(
      (val, idx) => {
        if (idx === ans_index) {
          let t_val = val;
          t_val.title = e.target.value;
        }
        return val;
      }
    );
    let newData = this.props.newdata;
    newData[this.props.type] = [...newQuestions];
    this.props.setdata({ newData: newData });
  }

  removeQuestion(e: any, index: number) {
    let newData = this.props.newdata;
    let newQuestions = this.props.data.filter((val, idx) => {
      if (idx === index) {
        if (val.image.length > 0) {
          newData["to_delete"].push(val.image);
        }
        for (let i = 0; i < val.answers.length; i++) {
          if (val.answers[i].image.length > 0) {
            newData["to_delete"].push(val.answers[i].image);
          }
        }
      }
      return idx !== index;
    });

    newData[this.props.type] = [...newQuestions];
    this.props.setdata({ newData: newData });
  }

  removeAnswer(e: any, que_index: number, ans_index: number) {
    let newQuestions = this.props.data;
    let newData = this.props.newdata;

    newQuestions[que_index].answers = newQuestions[que_index].answers.filter(
      (val, idx) => {
        if (idx === ans_index) {
          if (val.image.length > 0) {
            newData["to_delete"].push(val.image);
          }
        }
        return idx !== ans_index;
      }
    );

    newData[this.props.type] = [...newQuestions];
    this.props.setdata({ newData: newData });
  }

  toggleCorrect(e: any, que_index: number, ans_index: number) {
    let newQuestions = this.props.data;

    newQuestions[que_index].answers = newQuestions[que_index].answers.map(
      (val, idx) => {
        if (idx === ans_index) {
          let t_val = val;
          t_val.correct = !t_val.correct;
        }
        return val;
      }
    );
    let newData = this.props.newdata;
    newData[this.props.type] = [...newQuestions];
    this.props.setdata({ newData: newData });
  }

  addAnswer(e: any, que_index: number) {
    let newQuestions = this.props.data;

    newQuestions[que_index].answers.push({
      id: new Date().getTime(),

      title: (
        document.getElementById(
          "add-answer-val-" + que_index + "-" + this.props.type
        ) as HTMLInputElement
      ).value,
      correct: false,
      image: "",
    });
    let newData = this.props.newdata;
    newData[this.props.type] = [...newQuestions];
    this.props.setdata({ newData: newData });

    (
      document.getElementById(
        "add-answer-val-" + que_index + "-" + this.props.type
      ) as HTMLInputElement
    ).value = "";
  }

  addQuestion(e: any) {
    let newQuestions = this.props.data;

    newQuestions.push({
      id: new Date().getTime(),

      title: (
        document.getElementById(
          "add-question-val-" + this.props.type
        ) as HTMLInputElement
      ).value,
      image: "",
      answers: [],
    });
    let newData = this.props.newdata;
    newData[this.props.type] = [...newQuestions];
    this.props.setdata({ newData: newData });

    (
      document.getElementById(
        "add-question-val-" + this.props.type
      ) as HTMLInputElement
    ).value = "";
  }
}
