import React, { useState, useEffect } from "react";
import { Segment, Breadcrumb, Form, Button, Icon, Loader } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editForm, addForm } from "../../redux/action/formAction";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

interface fdataType {
  newData: {
    id: number;
    name: string;
    organization_id: number;
    app_id: number;
    link: string;
    permission: number[];
    draftusers: number[];
    order: number;
  };
}
interface Props {
  type: string;
  data?: undefined | Data;
}

interface Data {
  id: number;
  name: string;
  organization_id: number;
  app_id: number;
  link: string;
  order: number;
  users: {
    id: number;
  }[];
  draftusers: number[];
}

export default function FormForm(props: Props) {
  const [fdata, setData] = useState<fdataType>({
    newData: {
      id: 0,
      name: "",
      organization_id: 0,
      app_id: 0,
      link: "",
      order: 0,
      permission: [],
      draftusers: [],
    },
  });

  const [orgOptions, setOrgOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [draftuserOptions, setDraftUserOptions] = useState([]);
  const [appOptions, setAppOptions] = useState([]);
  const [schemaOptions, setSchemaOptions] = useState([]);
  const [draftEnable, setDraftEnable] = useState(false);
  const [role, setRole] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getData();
    if (props.type === "Edit" && props.data !== undefined) {
      //  console.log(props.data)

      var ndata = props.data;
      // console.log(ndata)
      if (ndata.draftusers !== null) {
        if (ndata.draftusers.length > 0) {
          setDraftEnable(true);
        }
      }
      let ids = ndata.users.map((data) => {
        return data.id;
      });

      setData({
        newData: {
          id: ndata.id,
          name: ndata.name,
          organization_id: ndata.organization_id,
          app_id: ndata.app_id,
          link: ndata.link,
          permission: ids,
          draftusers: ndata.draftusers !== null ? ndata.draftusers : [],
          order: ndata.order,
        },
      });

      //  console.log(ndata)
    }
    return () => {
      //
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let arr = userOptions.filter(function (el: any) {
      return fdata.newData.permission.includes(el.value);
    });
    setDraftUserOptions(arr);
  }, [fdata.newData.permission, userOptions]);

  const getData = () => {
    apiService.getAll(`/forms/create`).then(
      (data) => {
        var final_org = data.organizations.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );
        var final_app = data.apps.map(
          (data: { id: number; app_name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.app_name,
            };
          }
        );
        var final_schema = data.formbuilder.map(
          (data: { slug: string; name: string }) => {
            return {
              key: data.slug,
              value: data.slug,
              text: data.name,
            };
          }
        );
        var final_user = data.users.map(
          (data: {
            id: number;
            first_name: string;
            last_name: string;
            formbuilder_role: number | null;
          }) => {
            return {
              key: data.id,
              value: data.id,
              role: data.formbuilder_role,
              text: `${data.first_name} ${data.last_name}`,
            };
          }
        );

        var final_roles = data.roles.map(
          (data: { id: number; name: string }) => {
            return {
              key: data.id,
              value: data.id,
              text: data.name,
            };
          }
        );

        setRoleOptions(final_roles);

        setUserOptions(final_user);
        setOrgOptions(final_org);
        setSchemaOptions(final_schema);
        setAppOptions(final_app);

        // let arr = final_user.filter(function (el: any) {
        //     return fdata.newData.permission.includes(el.value)
        // })

        // console.log(arr,final_user,fdata.newData)

        // setDraftUserOptions(final_user)
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        organization_id: 0,
        app_id: 0,
        link: "",
        permission: [],
        order: 0,
        draftusers: [],
      },
    });
  };

  const handleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleTypeChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    var value = result.type === "checkbox" ? result.checked : result.value;
    var name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleRoleChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    var value = result.value;

    let user_array = userOptions
      .filter(function (el: any) {
        return value.includes(el.role);
      })
      .map(function (d: { value: number }) {
        return d.value;
      });

    setRole(value);

    let new_array = _.uniq(fdata.newData.permission.concat(user_array));

    let arr = userOptions.filter(function (el: any) {
      return new_array.includes(el.value);
    });

    setDraftUserOptions(arr);

    setData({
      newData: Object.assign({}, fdata.newData, {
        permission: new_array,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) {
      dispatch(addForm({ ...fdata.newData }, () => {
        clearForm();
        dispatch(alertActions.success("Data added successfully"));
        history.push(`/form`);
        
      }, () => {
        setLoading(false);
      }));
    } 
    else {
      dispatch(editForm({ ...fdata.newData }, () => {
      clearForm();
      dispatch(alertActions.success("Data Edited successfully"));
      history.push(`/form`);
      }, () => {
        setLoading(false);
      }));
    }
  }

  return (
    <div>
      <div key={1}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section link as={Link} to={`/form`}>
            {" "}
            <FormattedMessage id="Form" defaultMessage="Form" /> Api
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            {" "}
            <FormattedMessage id="Form" defaultMessage="Form" /> Api{" "}
            {props.type}
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>
      
      <Segment key={2} padded>
        <Loader active disabled={!loading} inverted size="big" />
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              required
              type="text"
              name="name"
              label="Name"
              value={fdata.newData.name}
              onChange={handleChange}
              placeholder="Enter  name..."
            />

            <Form.Select
              fluid
              placeholder="Select Schema"
              required
              clearable
              options={schemaOptions}
              label="Schema Link"
              value={fdata.newData.link}
              name="link"
              onChange={handleTypeChange}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              fluid
              placeholder="Select App"
              required
              clearable
              options={appOptions}
              label="App"
              value={fdata.newData.app_id}
              name="app_id"
              onChange={handleTypeChange}
            />

            <Form.Select
              fluid
              placeholder="Select Organization"
              required
              clearable
              options={orgOptions}
              label="Organization"
              value={fdata.newData.organization_id}
              name="organization_id"
              onChange={handleTypeChange}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              fluid
              placeholder="Select permission"
              required
              search
              clearable
              multiple
              selection
              options={userOptions}
              label="Give Permission To"
              value={fdata.newData.permission}
              name="permission"
              onChange={handleChange}
            />

            <Form.Input
              fluid
              type="number"
              name="order"
              label="Order"
              value={fdata.newData.order}
              onChange={handleChange}
              placeholder="Enter  order..."
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Select
              fluid
              placeholder="Select Role"
              clearable
              multiple
              options={roleOptions}
              label="Role"
              value={role}
              name="role"
              onChange={handleRoleChange}
              width={5}
            />
            <Segment compact>
              <Form.Checkbox
                onChange={() => setDraftEnable(!draftEnable)}
                checked={draftEnable}
                label="Enable other users to edit draft?"
              />
            </Segment>

            <Form.Select
              disabled={draftEnable === false}
              fluid
              placeholder="Select permission"
              required
              search
              clearable
              multiple
              selection
              options={draftuserOptions}
              label="Give edit draft Permission To"
              value={fdata.newData.draftusers}
              name="draftusers"
              width={4}
              onChange={handleChange}
            />
          </Form.Group>

          <br></br>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>

          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>
        </Form>
      </Segment>
    </div>
  );
}
