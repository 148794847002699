import { alertActions } from "./alertAction";
import { apiService } from "../../services";

export const filesTypes = {
  FILES_LOADED: "FILES_LOADED",
  FILES_UNLOADED: "FILES_UNLOADED",
  ADD_FILE: "ADD_FILE",
  DELETE_FILE: "DELETE_FILE",
};

export const loadFilesList = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: filesTypes.FILES_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadFilesList = () => {
  return {
    type: filesTypes.FILES_UNLOADED,
    payload: {},
  };
};

export const addFile = (
  payload: object,
  onSuccess: Function,
  onError: Function
) => {
  return async (dispatch: any) => {
    apiService.save("/files", payload).then(
      (data) => {
        dispatch({
          type: filesTypes.ADD_FILE,
          payload: data,
        });
        onSuccess();
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeFile = (
  id: number,
  onSuccess: Function,
  onError: Function
) => {
  return async (dispatch: any) => {
    apiService._delete(`/files/${id}`).then(
      (data) => {
        dispatch({
          type: filesTypes.DELETE_FILE,
        });
        onSuccess();
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
