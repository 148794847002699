let formbuilder = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Stage",
    linkTo: "stage",
    IconName: "sitemap",
  },
]);

const training = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Training Module",
    linkTo: "training",
    IconName: "user",
  },
  {
    name: "Module Lesson",
    linkTo: "module_lesson",
    IconName: "archive",
  },
  {
    name: "Lesson Data",
    linkTo: "lesson_data",
    IconName: "lightbulb",
  },
  {
    name: "User Details",
    linkTo: "user_details",
    IconName: "file alternate",
  },
]);

const only_training = Object.freeze([
  {
    name: "Start Training",
    linkTo: "start_training",
    IconName: "pencil",
    onClick: () => {
      const w = 1200;
      const h = 850;
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft;
      const top = (height - h) / 2 / systemZoom + dualScreenTop;
      const newWindow = window.open(
        "/training_start",
        "Popup",
        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, width=" +
          w / systemZoom +
          ", height=" +
          h / systemZoom +
          ", top=" +
          top +
          ", left=" +
          left
      );
      // @ts-ignore
      if (window.focus) newWindow.focus();
    },
  },
  {
    name: "Live Training",
    linkTo: "live_training",
    IconName: "video",
  },
]);

const contact_trace = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Contact Tracing",
    linkTo: "covid_trace",
    IconName: "h square",
  },
]);

const planner = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Planner",
    linkTo: "planner",
    IconName: "square",
  },
]);

const library = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
]);

const mobile_awareness_app = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Module",
    linkTo: "awareness_module",
    IconName: "adn",
  },
  {
    name: "Tile",
    linkTo: "tile",
    IconName: "cubes",
  },
  {
    name: "Contact",
    linkTo: "contact",
    IconName: "phone",
  },
  {
    name: "TSCs",
    linkTo: "awareness_tsc",
    IconName: "user",
  },
  {
    name: "Office",
    linkTo: "office",
    IconName: "building",
  },
  {
    name: "About Us",
    linkTo: "aboutus",
    IconName: "globe",
  },
  {
    name: "Message",
    linkTo: "message",
    IconName: "envelope open",
  },
  {
    name: "News",
    linkTo: "awareness_news",
    IconName: "newspaper",
  },
  {
    name: "Document",
    linkTo: "awareness_document",
    IconName: "file text",
  },
  {
    name: "Video",
    linkTo: "awareness_video",
    IconName: "video",
  },
  {
    name: "Worksite Safety",
    linkTo: "site_safety",
    IconName: "road",
  },
  {
    name: "FAQs",
    linkTo: "faq",
    IconName: "chat",
  },
  {
    name: "Category",
    linkTo: "category",
    IconName: "tree",
  },
  {
    name: "Gallery",
    linkTo: "gallery",
    IconName: "photo",
  },
  {
    name: "Slider Image",
    linkTo: "slider_image",
    IconName: "photo",
  },
  {
    name: "Sponsor",
    linkTo: "sponsor",
    IconName: "briefcase",
  },
  {
    name: "Library",
    linkTo: "library",
    IconName: "book",
  },
  {
    name: "Static Pages",
    linkTo: "static_page",
    IconName: "copy",
  },
  {
    type: "group",
    name: "Safe My House",
    IconName: "circle",
    list: [
      {
        name: "House Part",
        linkTo: "house_part",
        IconName: "circle",
      },
      {
        name: "House Image",
        linkTo: "house_image",
        IconName: "circle",
      },
    ],
  },
  {
    type: "group",
    name: "Strengthen My House",
    IconName: "circle",
    list: [
      {
        name: "House Made Off",
        linkTo: "strengthen_house_category",
        IconName: "circle",
      },
      {
        name: "House Part",
        linkTo: "strengthen_house_part",
        IconName: "circle",
      },
      {
        name: "House Image",
        linkTo: "strengthen_house_image",
        IconName: "circle",
      },
    ],
  },
  {
    type: "group",
    name: "Materials Quality",
    IconName: "circle",
    list: [
      {
        name: "House Materials Quality",
        linkTo: "house_category",
        IconName: "circle",
      },
      {
        name: "House Image",
        linkTo: "house_image_quality",
        IconName: "circle",
      },
    ],
  },
  {
    type: "group",
    name: "referral",
    IconName: "circle",
    list: [
      {
        name: "App Users",
        linkTo: "app_user",
        IconName: "users",
      },
      {
        name: "Transactions",
        linkTo: "transaction",
        IconName: "users",
      },
      {
        name: "App Points",
        linkTo: "app_point",
        IconName: "users",
      },
    ],
  },
  {
    type: "group",
    name: "BOQ",
    IconName: "asterisk",
    list: [
      {
        name: "Material Category",
        linkTo: "material_category",
        IconName: "circle",
      },
      {
        name: "Material List",
        linkTo: "material_list",
        IconName: "circle",
      },
      {
        name: "Floor",
        linkTo: "floor",
        IconName: "circle",
      },
      {
        name: "Roof",
        linkTo: "roof",
        IconName: "circle",
      },
      {
        name: "Location Unit Price",
        linkTo: "unit_price",
        IconName: "circle",
      },
      {
        name: "BOQ Drawing",
        linkTo: "boq_drawing",
        IconName: "circle",
      },
      {
        name: "BOQ Roof",
        linkTo: "boq_roof",
        IconName: "circle",
      },
      {
        name: "Report",
        linkTo: "boq_report",
        IconName: "book",
      },
    ],
  },
]);

const admin = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Formbuilder",
    linkTo: "formbuilder",
    IconName: "book",
  },

  {
    name: "Module",
    linkTo: "module",
    IconName: "shop",
  },
  {
    name: "Country",
    linkTo: "country",
    IconName: "flag",
  },
  {
    name: "Organization",
    linkTo: "organization",
    IconName: "building outline",
  },
  {
    name: "Role",
    linkTo: "role",
    IconName: "radio",
  },
  {
    name: "User",
    linkTo: "user",
    IconName: "user",
  },
  {
    name: "Project",
    linkTo: "project",
    IconName: "hdd outline",
  },
  {
    name: "Form Api",
    linkTo: "form",
    IconName: "wpforms",
  },

  {
    name: "Language",
    linkTo: "language",
    IconName: "language",
  },
  {
    name: "App",
    linkTo: "app",
    IconName: "mobile",
  },
  // {
  //   name: "File Manager",
  //   linkTo: "file_manager",
  //   IconName: "folder",
  // },
  {
    name: "Social Analytics",
    linkTo: "social_analytics",
    IconName: "globe",
  },
  // {
  //   name: "Documentation",
  //   linkTo: "documentation",
  //   IconName: "copy",
  // },
  // {
  //   name: "Seismic Coin",
  //   linkTo: "seismic",
  //   IconName: "dollar",
  // },
  {
    name: "Finance Module",
    linkTo: "finance",
    IconName: "dollar",
  },
  {
    name: "Files",
    linkTo: "files",
    IconName: "file alternate outline",
  },
]);

const orgadmin = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Formbuilder",
    linkTo: "formbuilder",
    IconName: "book",
  },
  {
    name: "Role",
    linkTo: "role",
    IconName: "radio",
  },

  {
    name: "User",
    linkTo: "user",
    IconName: "user",
  },
  {
    name: "Project",
    linkTo: "project",
    IconName: "hdd outline",
  },
  {
    name: "Form Api",
    linkTo: "form",
    IconName: "wpforms",
  },
  {
    name: "App",
    linkTo: "app",
    IconName: "mobile",
  },
  {
    name: "Sharing Portal",
    linkTo: "sharing",
    IconName: "share",
  },
  // {
  //   name: "Seismic Coin",
  //   linkTo: "seismic",
  //   IconName: "dollar",
  // },
  {
    name: "Finance Module",
    linkTo: "finance",
    IconName: "dollar",
  },
  {
    name: "Files",
    linkTo: "files",
    IconName: "file alternate outline",
  },
]);

const user = Object.freeze([
  {
    name: "Home",
    linkTo: "",
    IconName: "dashboard",
  },
  {
    name: "Formbuilder",
    linkTo: "formbuilder",
    IconName: "book",
  },
  {
    name: "Form Api",
    linkTo: "form",
    IconName: "wpforms",
  },
  {
    name: "Social Analytics",
    linkTo: "social_analytics",
    IconName: "globe",
  },
  {
    name: "Files",
    linkTo: "files",
    IconName: "file alternate outline",
  },
]);

export const navigation: { [key: string]: any } = {
  admin,
  orgadmin,
  user,
  training,
  only_training,
  mobile_awareness_app,
  formbuilder,
  library,
  contact_trace,
  planner,
};
