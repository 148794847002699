export const Module = {
  Formbuilder: "formbuilder",
  Training: "training",
  Awareness: "mobile_awareness_app",
  Library: "library",
  Contact_trace: "contact_trace",
  Planner: "planner",
};

export const Module_name = {
  formbuilder: "Data Collection",
  training: "Training",
  mobile_awareness_app: "Mobile App",
  library: "Library",
  contact_trace: "Contact Trace",
  planner: "Floor Plan",
};
