import React, { Component } from "react";

import { Icon } from "semantic-ui-react";

interface Props {
  name: any;
  hideText: boolean;
  color?: any;
}
interface State {}

export default class TextIcon extends Component<Props, State> {
  style = {
    alignSelf: "center",
    paddingLeft: "4px",
  };

  render() {
    return (
      <div
        style={{
          pointerEvents: "none",
          whiteSpace: "nowrap",
          display: "inline-flex",
        }}
      >
        <Icon size="large" name={this.props.name} />
        <div style={this.style} hidden={this.props.hideText}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
