import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const strengthenHouseCategoryTypes = {
  STRENGTHEN_HOUSE_CATEGORY_LOADED: "STRENGTHEN_HOUSE_CATEGORY_LOADED",
  STRENGTHEN_HOUSE_CATEGORY_UNLOADED: "STRENGTHEN_HOUSE_CATEGORY_UNLOADED",
  ADD_STRENGTHEN_HOUSE_CATEGORY: "ADD_STRENGTHEN_HOUSE_CATEGORY",
  EDIT_STRENGTHEN_HOUSE_CATEGORY: "EDIT_STRENGTHEN_HOUSE_CATEGORY",
  DELETE_STRENGTHEN_HOUSE_CATEGORY: "DELETE_STRENGTHEN_HOUSE_CATEGORY",
};

export const loadStrengthenHouseCategoryPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: strengthenHouseCategoryTypes.STRENGTHEN_HOUSE_CATEGORY_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadStrengthenHouseCategoryPage = () => {
  return {
    type: strengthenHouseCategoryTypes.STRENGTHEN_HOUSE_CATEGORY_UNLOADED,
    payload: {},
  };
};

export const addStrengthenHouseCategory = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/strengthen_house_category", {...payload,extra}).then(
      (data) => {
        dispatch({
          type: strengthenHouseCategoryTypes.ADD_STRENGTHEN_HOUSE_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editStrengthenHouseCategory = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/strengthen_house_category/update/${payload.id}`,  {...payload,extra}).then(
      (data) => {
        dispatch({
          type: strengthenHouseCategoryTypes.EDIT_STRENGTHEN_HOUSE_CATEGORY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeStrengthenHouseCategory = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/strengthen_house_category/delete/${id}`).then(
      (data) => {
        dispatch({
          type: strengthenHouseCategoryTypes.DELETE_STRENGTHEN_HOUSE_CATEGORY,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
