import React, { useState, useEffect, Component } from "react";
import {
  
  Header,
  Breadcrumb,
  Message,
  Divider,
  Button,
} from "semantic-ui-react";
import {
  loadLibraryPage,
  unloadLibraryPage,
} from "../../redux/action/libraryAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  libraries: {
    id: number;
    library_code: string;
    technology: string;
    type: string;
    number_of_storeys: number;
    number_of_bedrooms: number;
    number_of_common_rooms: number;
    kitchen_access: string;
    toilet_access: string;
    bedroom_size_length: number;
    bedroom_size_width: number;
    doors_front: number;
    doors_back: number;
    doors_left: number;
    doors_right: number;
    windows_front: number;
    windows_back: number;
    windows_left: number;
    windows_right: number;
    terrace: string;
    depth_of_terrace: string;
    terrace_type: string;
    roof_shape: string;
    roof_material: string;
    cad_link: string;
    pdf_link: string;
    image_link: string;
    boq_link: string;
    skp_link: string;
    app_id: number;
    roof_id: number;
    app_name: string;
    roof: string;
  };
}

export default function LibraryInfo(props: Props) {
  const [apiUrl] = useState(`/library/${props.match.params.id}`);
  const libraries = useSelector((state: RootState) => state.libraries);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadLibraryPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadLibraryPage());
    };
  }, [apiUrl, dispatch]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Library</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {libraries === undefined || Object.keys(libraries).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {libraries.library_code}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <div className="app-card-org" key="2">
      <Header>Library Info</Header>
      <Divider />
      {libraries === undefined || Object.keys(libraries).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <Info libraries={libraries} />
      )}
    </div>,
  ];
}

class Info extends Component<RootState> {
  render() {
    const data = this.props.libraries;
    return (
      <div>
        <b>Code: {data.library_code}</b>
        <p>
          <b>App:</b> {data.app_name}
        </p>
        <p>
          <b>Technology:</b> {data.technology} /{" "}
          {data.technology === "Confined Masonry"
            ? "Masonry Tertutup"
            : data.technology === "Timber"
            ? "Kayu"
            : data.technology === "Timber Ferrocement Plaster"
            ? "Dinding Kawat Plaster"
            : "-"}
        </p>
        <p>
          <b>Roof:</b> {data.roof_shape ? (data.roof ? data.roof : "") : "-"}
        </p>
        <p>
          <b>Type:</b> {data.type}
        </p>
        <p>
          <b>Number of Storeys:</b> {data.number_of_storeys}
        </p>
        <p>
          <b>Number of Bedrooms:</b> {data.number_of_bedrooms}
        </p>
        <p>
          <b>Number of Common Rooms:</b> {data.number_of_common_rooms}
        </p>
        <p>
          <b>Kitchen Access:</b> {data.kitchen_access} /{" "}
          {data.kitchen_access === "In"
            ? "Di"
            : data.kitchen_access === "Both"
            ? "Kedua"
            : "-"}
        </p>
        <p>
          <b>Toilet Access:</b> {data.toilet_access} /{" "}
          {data.toilet_access === "In"
            ? "Dalam"
            : data.toilet_access === "Out"
            ? "Luar"
            : data.toilet_access === "Both"
            ? "Dalam dan luar"
            : "-"}
        </p>
        <p>
          <b>Bedroom Size Length:</b> {data.bedroom_size_length}, <b>Width:</b>{" "}
          {data.bedroom_size_width}
          <br />
          <b>Doors Front:</b> {data.doors_front}, <b>Back:</b> {data.doors_back}
          , <b>Left:</b> {data.doors_left}, <b>Right:</b> {data.doors_right}
          <br />
          <b>Windows Front:</b> {data.windows_front}, <b>Back:</b>{" "}
          {data.windows_back}, <b>Left:</b> {data.windows_left}, <b>Right:</b>{" "}
          {data.windows_right}, <br />
        </p>
        <p>
          <b>Terrace: </b> {data.terrace} /{" "}
          {data.terrace === "Front"
            ? "Depan"
            : data.terrace === "Back"
            ? "Kembali"
            : data.terrace === "Both"
            ? "Kedua"
            : data.terrace === "No"
            ? "Tidak"
            : "-"}{" "}
          <br />
          <b>Depth of Terrace:</b> {data.depth_of_terrace} <br />
          <b>Terrace Type:</b> {data.terrace_type} /{" "}
          {data.terrace_type === "Timber"
            ? "Kayu"
            : data.terrace_type === "Concrete"
            ? "Beton"
            : "-"}{" "}
          <br />
          <b>Roof Material:</b> {data.roof_material} /{" "}
          {data.roof_material === "CGI"
            ? "CGI"
            : data.roof_material === "Clay Tile"
            ? "Genteng tanah liat"
            : data.roof_material === "Concrete"
            ? "Beton"
            : "-"}{" "}
          <br />
        </p>
        <hr />
        <p>
          <b>Files:</b>
        </p>
        {data.cad_link ? (
          <Button
            href={data.cad_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cad
          </Button>
        ) : (
          ""
        )}

        {data.pdf_link ? (
          <Button
            href={data.pdf_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Pdf
          </Button>
        ) : (
          ""
        )}

        {data.image_link ? (
          <Button
            href={data.image_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Image
          </Button>
        ) : (
          ""
        )}

        {data.boq_link ? (
          <Button
            href={data.boq_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Boq Pdf
          </Button>
        ) : (
          ""
        )}

        {data.skp_link ? (
          <Button
            href={data.skp_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Sketch
          </Button>
        ) : (
          ""
        )}
      </div>
    );
  }
}
