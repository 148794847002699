import React, { useCallback, useState } from "react";

import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Segment,
  Breadcrumb,
  List,
  Image,
  Button,
  Icon,
  Menu,
  Dropdown,
  Message,
  Divider,
  DropdownProps,
  Label,
  Table,
  Modal,
  Card,
  Input,
  Popup,
  Loader,
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { apiService } from "../../services";
import { useEffect } from "react";
import { alertActions } from "../../redux/action/alertAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, unloadData } from "../../redux/action/allAction";

import moment from "moment";
import { AppConfig } from "../../config";
import defaultImg from "../../assets/images/image.png";
import Boq from "./Components/Report/Boq";
import MagicPlanFulcrum from "./Components/Report/MagicPlanFulcrum";
import Stages from "./Components/Report/Stages";

const recordActivityOptions = [
  {
    key: "1",
    text: "Data per day",
    value: "day",
  },
  {
    key: "7",
    text: "Data per week",
    value: "week",
  },
  {
    key: "28",
    text: "Data per Month",
    value: "month",
  },
];

export default function ProjectReport(props: any) {
  const url = new URL(window.location.href);
  const dispatch = useDispatch();

  const project = useSelector((state: any) => state.all_ind);
  const [fdata, setData] = useState({
    newData: {
      name: "",
      file: "",
    },
  });
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [datewise_count, setDatewise_count] = useState([]);

  const [stages, setStages] = useState([]);

  const [datastudio, setDataStudio] = useState("");
  const [openDataStudio, setOpenDataStudio] = useState<boolean>(false);
  const [openDataShareEnabled, setOpenDataShareEnabled] =
    useState<boolean>(false);
  const [openDataShareDisabled, setOpenDataShareDisabled] =
    useState<boolean>(false);

  const [apiUrl, setApiUrl] = useState<string>(
    `/project/detail/${props.match.params.id}`
  );

  const [activeActivity, setActiveActivity] = useState(
    url.searchParams.get("tab") ?? "Users & Activity"
  );

  useEffect(() => {
    dispatch(fetchData(apiUrl));
    loadStages();

    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (project.detail !== undefined) {
      //console.log(formbuilders.last_page)
      // setformbuilder_detail(formbuilder.detail)
      setName(project.detail.name);
      setSlug(project.detail.slug);

      setDataStudio(project.detail.data_studio);
    }

    if (project.datewise_count !== undefined) {
      let final_datewise_count = project.datewise_count.map((data: any) => {
        return {
          key: data.label,
          count: data.count,
          name: data.label,
        };
      });

      // setUserwise_count(final_userwise_count)
      setDatewise_count(final_datewise_count);
    }
  }, [project]);

  const handleChange = (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;
    if (result.type === "file") {
      value = event.target.files[0];
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleRecordType = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setApiUrl(`/project/detail/${props.match.params.id}?type=${data.value}`);
  };

  const activityHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: any
  ) => {
    setActiveActivity(data.name);

    url.searchParams.set("tab", data.name);
    window.history.pushState(null, "", url.toString());
  };

  const loadStages = () => {
    let project_id = props?.match?.params?.id || project?.detail?.id;

    if (project_id) {
      apiService.getAll(`/stage?project_id=${project_id}&per_page=10`).then(
        (res) => {
          setStages(res?.stage?.data ?? []);
        },
        (error) => {
          alertActions.error(error ?? "Failed to load stages.");
        }
      );
    }
  };

  const RenderLog = useCallback((): any => {
    if (project.logs === undefined || project.logs.length === 0) {
      return <Message>No Log Found</Message>;
    }

    return project.logs.map(
      (
        item: {
          _id: string;
          datetime: Date;
          extra: { user_name: string };
          context: { type: string; data_id: string; form_id: string };
          created_at: Date;
          updated_at: Date;
        },
        index: number
      ) => {
        let type = item.context.type || "";
        return (
          <List.Item style={{ padding: "15px" }} key={item._id || index}>
            <List.Icon name="user circle" size="large" verticalAlign="middle" />
            <List.Content>
              <List.Description
                as="a"
                style={{ color: "gray", marginBottom: "5px" }}
              >
                {(item.created_at || item.datetime) &&
                  moment(item.created_at || item.datetime).fromNow()}
              </List.Description>
              <List.Description as="a">
                <strong>{item.extra && item.extra.user_name}</strong> {type}{" "}
                <span style={{ color: "#4183c4" }}>1 record</span> in{" "}
                <span style={{ color: "#4183c4" }}>
                  {name} (
                  {project.form_count[item.context.form_id || ""]
                    ? project.form_count[item.context.form_id || ""]["name"] ||
                      ""
                    : "Form"}
                  )
                </span>{" "}
                ( <strong>{type === "created" ? 1 : 0}</strong> created{" "}
                <strong>{type === "updated" ? 1 : 0}</strong> updated{" "}
                <strong>{type === "deleted" ? 1 : 0}</strong> deleted )
              </List.Description>
            </List.Content>
          </List.Item>
        );
      }
    );
  }, [project, name]);

  const RenderTable = useCallback((): any => {
    if (
      project.form_count === undefined ||
      Object.keys(project.form_count).length === 0
    ) {
      return <Message>No Forms Found</Message>;
    }

    return Object.keys(project.form_count).map(function (key, index) {
      if (project.form_count[key] === undefined) return null;
      return (
        <Table.Row key={key}>
          <Table.Cell>
            <a
              href={"/formbuilder/FormBuilderApp/" + key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.form_count[key].name || ""}
            </a>
          </Table.Cell>
          <Table.Cell>{project.form_count[key].data || 0}</Table.Cell>
          <Table.Cell>{project.form_count[key].rejected || 0}</Table.Cell>
        </Table.Row>
      );
    });
  }, [project]);

  const Users = ({
    data,
  }: {
    data: { first_name: string; last_name: string; email: string }[];
  }): any => {
    return data.map((item, index) => {
      return (
        <Popup
          on={["click"]}
          trigger={
            <Label key={index} as="a" style={{ textTransform: "capitalize" }}>
              <Icon name="user" />
              {item.first_name} {item.last_name}
            </Label>
          }
          content={item.email}
          position="top center"
        />
      );
    });
  };

  const enableDataShare = () => {
    apiService.getAll(`/project/data_share/${slug}`).then(
      (data) => {
        dispatch(fetchData(apiUrl));
        dispatch(alertActions.success(data.message));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        setOpenDataShareDisabled(false);
        setOpenDataShareEnabled(false);
      }
    );
  };

  const setDataShare = () => {
    if (project.detail.data_share) {
      setOpenDataShareDisabled(false);
      setOpenDataShareEnabled(true);
    } else {
      setOpenDataShareDisabled(true);
      setOpenDataShareEnabled(false);
    }
  };

  const handleDataStudioChange = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    result: any
  ) => {
    let value = result.value;
    //let name = result.name;

    setDataStudio(value);
  };

  const DataStudioSave = () => {
    apiService
      .save(`/dataStudio/project/${slug}`, { data_studio: datastudio })
      .then(
        (data) => {
          // dispatch(fetchData(apiUrl));
          dispatch(fetchData(apiUrl));
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
  const copyToClipboard = (url: string) => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  return [
    <div key={1}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section link as={Link} to="/project">
          Project
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Project Report</Breadcrumb.Section>
      </Breadcrumb>

      <Modal
        size="small"
        open={openDataShareDisabled}
        dimmer="blurring"
        closeIcon
        onClose={() => setOpenDataShareDisabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Enable Data Shares?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenDataShareDisabled(false)}>
            Cancel
          </Button>
          <Button
            content="Enable"
            labelPosition="right"
            icon="checkmark"
            onClick={() => enableDataShare()}
            positive
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataShareEnabled}
        closeIcon
        onClose={() => setOpenDataShareEnabled(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Shares</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Select Data Shares Link</p>

            <Card.Group>
              <Card fluid color="orange">
                <Card.Content>
                  <Card.Header>Project Api</Card.Header>
                </Card.Content>

                <Card.Description>
                  <Input fluid>
                    <input
                      defaultValue={AppConfig.apiUrl + "/projectshare/" + slug}
                    ></input>
                    <Popup
                      content="Link Copied to Clipboard"
                      onOpen={() =>
                        copyToClipboard(
                          AppConfig.apiUrl + "/projectshare/" + slug
                        )
                      }
                      on="click"
                      trigger={<Button>Copy</Button>}
                    />
                  </Input>
                </Card.Description>
              </Card>

              {project.form_count &&
                Object.keys(project.form_count).map((key, index) => {
                  return (
                    <Card fluid color="orange" key={index}>
                      <Card.Content>
                        <Card.Header>
                          {project.form_count[key]["name"] ?? ""} Data Api
                        </Card.Header>
                      </Card.Content>

                      <Card.Description>
                        <Input fluid>
                          <input
                            defaultValue={AppConfig.apiUrl + "/share/" + key}
                          ></input>
                          <Popup
                            content="Link Copied to Clipboard"
                            onOpen={() =>
                              copyToClipboard(
                                AppConfig.apiUrl + "/share/" + key
                              )
                            }
                            on="click"
                            trigger={<Button>Copy</Button>}
                          />
                        </Input>
                      </Card.Description>
                    </Card>
                  );
                })}
            </Card.Group>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenDataShareEnabled(false)}>
            Cancel
          </Button>
          <Button
            content="Disable"
            labelPosition="right"
            icon="checkmark"
            onClick={() => enableDataShare()}
            negative
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={openDataStudio}
        closeIcon
        onClose={() => setOpenDataStudio(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>Data Studio Link</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Input
              fluid
              placeholder="Enter Data studio link..."
              name="datastudio"
              onChange={handleDataStudioChange}
              value={datastudio}
            />

            <br />
            {datastudio ? (
              <a href={datastudio} target="_blank" rel="noopener noreferrer">
                <Button basic color="blue">
                  Open Link
                </Button>
              </a>
            ) : (
              ""
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpenDataStudio(false)}>
            Cancel
          </Button>
          <Button
            content="Save"
            labelPosition="right"
            icon="checkmark"
            onClick={() => DataStudioSave()}
            positive
          />
        </Modal.Actions>
      </Modal>
    </div>,

    <Segment key={2}>
      <List divided verticalAlign="middle">
        <List.Item>
          <List.Content floated="right" style={{ marginTop: "5px" }}>
            <Button
              color={
                project.detail
                  ? project.detail.data_share
                    ? "twitter"
                    : "google plus"
                  : "google plus"
              }
              circular
              onClick={setDataShare}
            >
              Data Shares
            </Button>
            <Button circular onClick={() => setOpenDataStudio(true)}>
              Data Studio
            </Button>
          </List.Content>
          <Image
            size="tiny"
            rounded
            bordered
            src={
              project.detail ? project.detail.image || defaultImg : defaultImg
            }
            onError={(e: any) => (e.target.src = defaultImg)}
          />
          <List.Content>
            <List.Header as="a" className="text-uppercase">
              {name}
            </List.Header>

            <Loader active={!name ? true : false} inverted size="large" />

            <List.Description>
              {project?.detail?.code ? project.detail.code : ""}
            </List.Description>

            <List.Description>{project.org ?? ""}</List.Description>
          </List.Content>
        </List.Item>
      </List>

      <div>
        <Menu pointing secondary>
          <Menu.Item
            active={activeActivity === "Users & Activity"}
            name="Users & Activity"
            onClick={activityHandler}
          />
          <Menu.Item
            active={activeActivity === "Forms"}
            name="Forms"
            onClick={activityHandler}
          />
          <Menu.Item
            active={activeActivity === "Logs"}
            name="Logs"
            onClick={activityHandler}
          />
          <Menu.Item
            active={activeActivity === "BOQs"}
            name="BOQs"
            content="BOQs"
            onClick={activityHandler}
          />
          {/* <Menu.Item
            active={activeActivity === "MagicPlan"}
            name="MagicPlan"
            content="MagicPlan/Fulcrum"
            onClick={activityHandler}
          /> */}
          <Menu.Item
            active={activeActivity === "Stages"}
            name="Stages"
            content="Stages"
            onClick={activityHandler}
          />
        </Menu>
      </div>
      <div>
        <div
          style={{
            width: "100%",
            // height: "350px",
            marginTop: "10px",
            marginBottom: "10px",
            display: activeActivity === "Users & Activity" ? "block" : "none",
          }}
        >
          <div>
            {project.project_managers && (
              <div>
                {" "}
                <h5>Project Managers</h5>
                {project.project_managers &&
                project.project_managers.length > 0 ? (
                  <Users data={project.project_managers} />
                ) : (
                  <small style={{ color: "orange" }}>
                    No Users Associated to this Project.
                  </small>
                )}
                <Divider />
              </div>
            )}
          </div>
          Record Activity &nbsp; &nbsp;
          <Dropdown
            simple
            placeholder="State"
            onChange={handleRecordType}
            selection
            options={recordActivityOptions}
          />
          <ResponsiveContainer height={350}>
            <ComposedChart
              data={datewise_count}
              margin={{
                top: 20,
                right: 30,
                left: 30,
                bottom: 0,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" barSize={40} fill="#413ea0" />
              <Line type="monotone" dataKey="count" stroke="#ff7300" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
            display: activeActivity === "Forms" ? "block" : "none",
          }}
        >
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Form Name</Table.HeaderCell>
                <Table.HeaderCell>Total Active Submissions</Table.HeaderCell>
                <Table.HeaderCell>Rejected</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <RenderTable />
            </Table.Body>
          </Table>
        </div>

        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: activeActivity === "Logs" ? "block" : "none",
          }}
        >
          <List divided selection>
            <RenderLog />
          </List>
        </div>

        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: activeActivity === "BOQs" ? "block" : "none",
          }}
        >
          <List divided selection>
            {project && (
              <Boq
                project={project.detail}
                boqs={project.boqs}
                refresh={() => {
                  dispatch(fetchData(apiUrl));
                }}
                raw={project}
              />
            )}
          </List>
        </div>

        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: activeActivity === "MagicPlan" ? "block" : "none",
          }}
        >
          <List divided selection>
            {project && <b>TODO: MAGIC PLAN FORM STUFF</b>}

            {/* {project && (
              <MagicPlanFulcrum
                project={project.detail}
                magic_plans={project.magic_plans ?? []}
                refresh={() => {
                  dispatch(fetchData(apiUrl));
                }}
                raw={project}
              />
            )} */}
          </List>
        </div>

        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: activeActivity === "Stages" ? "block" : "none",
          }}
        >
          <List divided selection>
            {stages && project && (
              <Stages
                project={project.detail}
                stages={stages}
                refresh={() => {
                  dispatch(fetchData(apiUrl));
                }}
              />
            )}
          </List>
        </div>
      </div>
    </Segment>,
  ];
}
