import { toggleMenu, openMenu,toggleModule, closeMenu } from "../action/sideMenuAction";

interface Props{
  smallMenu: boolean,
  module:string
}
const initialState:Props = { smallMenu: true ,module:'main'};



export const SideMenuReducer = (state=initialState, action:any) => {


  if (action.type === toggleMenu) {
    return { ...state, smallMenu: !state.smallMenu};
  }else if(action.type === toggleModule) {
    return { ...state, module: action.payload};
  }
  else if(action.type === openMenu) {
    return { ...state, smallMenu: false};
  }else if(action.type === closeMenu){
    if(!state.smallMenu){
      return { ...state, smallMenu: true}
    }
  }

  return state;
};
