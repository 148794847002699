import React, { useEffect, useState } from "react";
//import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  Segment,
  Message,
  Header,
  Loader,
  Step,
  Button,
  Icon,
} from "semantic-ui-react";

import { Formio } from "@formio/react";
import { useDispatch } from "react-redux";
import { unloadFormdataPage } from "../../redux/action/formDataAction";
import { AppConfig } from "../../config";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
//import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

export default function WebsiteFormbuilderFill(props: any) {
  const dispatch = useDispatch();
  const [shared, setshared] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [lat, setlatitude] = useState<number>(0);
  const [long, setlongitude] = useState<number>(0);
  const [relatedForm, setRelatedForm] = useState([]);
  // const [pdfUrl, setpdfUrl] = useState<string>(
  //   "http://www.africau.edu/images/default/sample.pdf"
  // );

  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //const [slug, setSlug] = useState<string>(props.match.params.slug);
  const [dataId, setDataId] = useState<string>("");
  //const [pdfHidden, setpdfHidden] = useState<boolean>(true);
  //const [hideForm, setHideForm] = useState<boolean>(false);

  useEffect(() => {
    checkRelatedForm(dataId);

    return () => {
      dispatch(unloadFormdataPage());
    };
    // eslint-disable-next-line
  }, []);
  const checkRelatedForm = (id: string, lat1?: number, long1?: number) => {
    //console.log('i am here' +  id)
    apiService
      .getAll(
        `/formBuilder/findRelated/${props.match.params.slug}${
          id ? "/" + id : ""
        }`
      )
      .then(
        (data) => {
          // dispatch(fetchData(apiUrl));
          if (data.length === 0) {
            if (lat1 === undefined) {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  function (position) {
                    showPosition(props.match.params.slug, "", null, position);
                  },
                  function (error) {
                    formio(props.match.params.slug, "", null, lat, long);
                  }
                );
              }
            } else {
              formio(props.match.params.slug, "", null, lat1, long1);
            }
          } else {
            setRelatedForm(data);
            let final = data.find(
              (element: any) => element.status === "Active"
            );
            if (final !== undefined) {
              //setSlug(final.slug);

              if (lat1 === undefined) {
                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition(
                    function (position) {
                      showPosition(final.slug, id, final.data, position);
                    },
                    function (error) {
                      formio(final.slug, id, final.data, lat, long);
                    }
                  );
                }
              } else {
                formio(final.slug, id, final.data, lat1, long1);
              }
            }
          }

          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
  /*
  const PreviewPdf = (data_id:string) => {

    apiService
      .getfilePost(
        `/formBuilder/exportpdf/${props.match.params.slug}/${data_id}
        `,
        {}
      )
      .then(
        (data) => {
          setTimeout(() => {
            setpdfUrl(data);
            setpdfHidden(false)
            setHideForm(true)
          }, 100);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          setpdfHidden(true)
          setHideForm(false)
        }
      );
  };
*/
  const showPosition = (
    data_slug: string,
    id: string,
    data: any,
    position: any
  ) => {
    setlatitude(position.coords.latitude);
    setlongitude(position.coords.longitude);

    formio(
      data_slug,
      id,
      data,
      position.coords.latitude,
      position.coords.longitude
    );
  };
  const formio = (
    slug_temp: string,
    data_id: string,
    main_data: any,
    lat1?: number,
    long1?: number
  ) => {
    apiService
      .getAll(`/formBuilder/checkifshared/${props.match.params.slug}`)
      .then(
        (data) => {
          setshared(data.shared);
          setName(data.name);
          if (data.shared) {
            Formio.createForm(
              document.getElementById("formio"),
              `${AppConfig.apiUrl}/formBuilders/${slug_temp}`,
              {
                // saveDraft: true,
              }
            ).then(function (form: any) {
              // Prevent the submission from going to the form.io server.
              if (main_data === undefined) main_data = null;
              if (main_data !== null) {
                form.submission = {
                  data: main_data,
                };
              }
              form.nosubmit = true;

              // Triggered when they click the submit button.
              form.on("submit", function (submission: any) {
                //console.log(submission);

                // dispatch(addFormdata(props.match.params.slug, submission));

                if (lat1) {
                  submission["data"]["_latitude"] = lat1;
                  submission["data"]["_longitude"] = long1;
                } else {
                  submission["data"]["_latitude"] = lat;
                  submission["data"]["_longitude"] = long;
                }

                // console.log('test1 '+data_id+data)
                if (main_data === null && data_id !== "") {
                  submission["data"]["_related_id"] = data_id;
                }

                //console.log({ submission });
                apiService
                  .save(
                    `/website_formBuilders/${slug_temp}/submission`,
                    submission
                  )
                  .then(
                    (formdata) => {
                      dispatch(alertActions.success("Data Saved successfully"));

                      if (formdata.data_id !== undefined) {
                        // PreviewPdf(formdata.data_id)
                        if (data_id === "") {
                          setDataId(formdata.data_id);

                          // alert(id)
                          //console.log('RESPONSE'+formdata.data_id)
                          checkRelatedForm(formdata.data_id, lat1, long1);
                        } else {
                          checkRelatedForm(data_id, lat1, long1);
                        }
                      } else {
                        //setpdfHidden(true)
                        formio(slug_temp, data_id, null, lat1, long1);
                      }
                    },
                    (error) => {
                      //setpdfHidden(true)
                      dispatch(alertActions.error(error.toString()));
                    }
                  );

                //alert('Submission sent to custom endpoint. See developer console.');
              });
            });
          }
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
  const handleResetForm = () => {
    setDataId("");
    //setpdfHidden(true)
    //setSlug(props.match.params.slug);

    checkRelatedForm("");
  };

  const handleStepClick = (item: {
    name: string;
    slug: string;
    status: string;
    id?: string;
    data?: any;
  }) => {
    formio(item.slug, dataId, item.data);
  };
  const RenderRelatedForm = (): any => {
    if (relatedForm.length == 0) return null;

    return relatedForm.map(
      (
        item: {
          name: string;
          slug: string;
          status: string;
          id?: string;
        },
        index: number
      ) => (
        <Step
          active={item.status === "Active"}
          completed={item.status === "Completed" || item.status === "submitted"}
          disabled={item.status === "Disabled"}
          onClick={() => handleStepClick(item)}
          key={index}
        >
          <Step.Content>
            <Step.Title>
              {item.name}
              {dataId !== "" && index == 0 ? "(" + dataId + ")" : ""}
              {item.id ? "(" + item.id + ")" : ""}
            </Step.Title>
            <Step.Description style={{ textTransform: "capitalize" }}>
              {item.status.replace("submitted", "completed")}
            </Step.Description>
          </Step.Content>
        </Step>
      )
    );
  };

  return (
    <Segment>
      <Header>{name}</Header>
      <Button color="twitter" onClick={handleResetForm} floated="right">
        <Icon name="refresh" />
        Reset Form
      </Button>
      {/* {hideForm ? (
           <Button icon="unhide" onClick={()=>setHideForm(false)} />

          ) : <Button icon="hide" onClick={()=>setHideForm(true)} />} */}
      <Step.Group ordered widths={3}>
        <RenderRelatedForm />
      </Step.Group>

      {name && !shared && (
        <Message error>
          This form is not shared. Please contact Administrator
        </Message>
      )}
      <div id="formio"></div>

      {name === "" ? <Loader active inverted size="big" /> : ""}
    </Segment>
  );
}
