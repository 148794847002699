import { aboutUsTypes } from "../action/aboutUsAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case aboutUsTypes.ABOUT_US_LOADED:
      return action.payload;

    case aboutUsTypes.ABOUT_US_UNLOADED:
      return initialState;
    case aboutUsTypes.ADD_ABOUT_US:
      return { ...state };
    case aboutUsTypes.EDIT_ABOUT_US:
      return state;
    case aboutUsTypes.DELETE_ABOUT_US:
      return state;

    default:
      return state;
  }
};
