import { stageTypes } from "../action/stageAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case stageTypes.STAGE_PAGE_LOADED:

            return action.payload;

        case stageTypes.STAGE_PAGE_UNLOADED:
            return initialState;
        case stageTypes.ADD_STAGE:
           
             return { ...state};
        case stageTypes.EDIT_STAGE:

            return state;
        case stageTypes.DELETE_STAGE:
           
            return state;

        default:
            return state;
    }
};
