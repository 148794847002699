import { formbuilderMetaTypes } from "../action/formbuilderMetaAction";

const initialState: Array<any> = [];

export default (state = initialState, action: any) => {
  switch (action.type) {
    case formbuilderMetaTypes.FORMBUILDER_PAGE_LOADED_META:
      return action.payload;

    case formbuilderMetaTypes.FORMBUILDER_PAGE_UNLOADED_META:
      return initialState;

    case formbuilderMetaTypes.ADD_FORMBUILDER_META:
      return { ...state };

    case formbuilderMetaTypes.EDIT_FORMBUILDER_META:
      return state;

    case formbuilderMetaTypes.FETCH_FORMBUILDER_META:
      return action.payload;

    case formbuilderMetaTypes.DELETE_FORMBUILDER_META:
      return state;

    default:
      return state;
  }
};
