import React, { ReactElement, useEffect, useState } from "react";
import { Button, Dimmer, Icon, Loader, Modal } from "semantic-ui-react";
import { LayersControl, Map, Marker, TileLayer } from "react-leaflet";
import { useDispatch } from "react-redux";

import FormDataDetail from "../../views/StepSkeleton/component/FormDataDetail";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { useHistory } from "react-router-dom";

interface Props {
  setOpenModal: Function;
  openModal: boolean;
  formId: string;
  dataId: string;
}

export default function MarkerPopup(props: Props): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data_details, setData_details] = useState<any>();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.openModal) {
      fetchApi();
    }
    // eslint-disable-next-line
  }, [props.dataId, props.openModal]);

  useEffect(() => {
    if (data_details !== undefined) {
      if (data_details.data !== undefined) {
        if (data_details.data._latitude !== undefined) {
          setLatitude(
            data_details.data._latitude === "-"
              ? 0
              : data_details.data._latitude
          );
          setLongitude(
            data_details.data._longitude === "-"
              ? 0
              : data_details.data._longitude
          );
        }
      }
    }
  }, [data_details]);

  const fetchApi = () => {
    setLoading(true);
    apiService.getAll(`/formData/${props.formId}/detail/${props.dataId}`).then(
      (data) => {
        setData_details(data);
        setLoading(false);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        setLoading(false);
      }
    );
  };

  const DetailPageHandler = () => {
    history.push(`/formbuilder/data/${props.formId}/view/${props.dataId}`);
  };

  return (
    <Modal
      open={props.openModal === false ? false : true}
      onClose={() => props.setOpenModal(false)}
      className="modal-customized"
      size="small"
    >
      <Modal.Header>
        {props.formId}
        <Button primary floated="right" onClick={DetailPageHandler}>
          {" "}
          More Details{" "}
        </Button>
      </Modal.Header>
      <Modal.Content scrolling>
        <div style={{ position: "relative" }}>
          {loading && (
            <Dimmer
              active={loading}
              inverted
              style={{ height: "100%", width: "100%" }}
            >
              <Loader active={loading} inverted size="large" />
            </Dimmer>
          )}

          <div className="information">
            <div>
              <p className="information-title">ID</p>
              <p className="information-value">{props.dataId}</p>
            </div>
            <div>
              <p className="information-title">Status</p>
              <p className="information-value">
                {data_details &&
                  data_details.data &&
                  (data_details.data.status || "")}
              </p>
            </div>
            <div>
              <p className="information-title">Date Created</p>
              <p className="information-value">
                {data_details &&
                  data_details.data &&
                  (data_details.data.created_at || "")}
              </p>
            </div>
          </div>
          <br />
          <Map
            style={{ height: "25vh", zIndex: 1 }}
            center={[latitude, longitude]}
            zoom={5}
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Mapnik">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="BlackAndWhite">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
            </LayersControl>
            <Marker position={[latitude, longitude]}></Marker>
          </Map>
          <br />
          {data_details && <FormDataDetail data={data_details} />}
        </div>
      </Modal.Content>
    </Modal>
  );
}
