import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Button,
  Segment,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Icon,
} from "semantic-ui-react";
import { apiService } from "../../../services/api.service";

import { useSelector, useDispatch } from "react-redux";
import {
  loadBoqReportPage,
  unloadBoqReportPage,
} from "../../../redux/action/boqReportAction";
import { Link } from "react-router-dom";

import { FormattedMessage } from "react-intl";
interface RootState {
  boq_reports: {
    libraries: {
      id: number;
      library_code: string;
    }[];
    locations: {
      id: number;
      name: string;
    }[];
  };
}

type libraryOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type locationOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type dateOptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

type roofOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type reportOptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

export default function Report() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      library_id: 0,
      location_id: 0,
      date: "",
      roof_id: 0,
      type: "",
    },
  });

  const [creating, setCreating] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/boq_report");
  const boq_reports = useSelector((state: RootState) => state.boq_reports);

  // eslint-disable-next-line
  const [total, setTotal] = useState(1);
  const [libraryOptions, setLibraryOptions] = useState<libraryOptionType>([]);
  const [locationOptions, setLocationOptions] = useState<locationOptionType>(
    []
  );
  const [dateOptions, setDateOptions] = useState<dateOptionType>([]);
  const [roofOptions, setRoofOptions] = useState<roofOptionType>([]);
  const [reportOptions, setReportTypeOptions] = useState<reportOptionType>([]);
  const [reportData, setReportData] = useState<any>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loadFooter, setFooterLoadOption]: any = useState(false);
  let drawing_arr: any = [];
  let roof_arr: any = [];

  let [_drawing_arr, setDrawingArr] = useState([]);
  let [_roof_arr, setRoofArr] = useState([]);
  const [loading, setLoading] = useState(false);

  //const [grandTotal, setGrandTotal] = useState(0);

  const setDrawingArrValue = (value: any) => {
    setDrawingArr(value);
  };

  const formatNumberToIndonesianRupee = (amount: any) => {
    let currencyStr = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "IDR",
    }).format(amount);
    let finalCurrency = currencyStr.replace("IDR", "");

    return "Rp. " + finalCurrency;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadBoqReportPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadBoqReportPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (
      boq_reports !== undefined &&
      boq_reports.libraries &&
      boq_reports.locations
    ) {
      var final_stage_library = boq_reports.libraries.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.library_code,
        };
      });

      setLibraryOptions(final_stage_library);

      var final_stage_locations = boq_reports.locations.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name,
        };
      });

      setLocationOptions(final_stage_locations);

      setReportTypeOptions([
        {
          key: "Summary",
          value: "Summary",
          text: "Summary",
        },
        {
          key: "Detailed View",
          value: "Detailed View",
          text: "Detailed View",
        },
      ]);
    }
  }, [boq_reports]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    setApiUrl("/boq_report?page=" + pageInfo.activePage.toString());
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        library_id: 0,
        location_id: 0,
        date: "",
        roof_id: 0,
        type: "",
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    if (name === "library_id") {
      let lib_opt = await apiService.getAll(
        "/boq_report_roof_ajax?library_id=" + value
      );

      var lib_opt_format = lib_opt.map((data: any) => {
        return {
          key: data.id,
          value: data.id,
          text: data.value,
        };
      });

      setRoofOptions(lib_opt_format);
    }

    if (name === "location_id") {
      let date_opt = await apiService.getAll(
        "/boq_report_date_ajax?tsc_id=" + value
      );
      var date_opt_format = date_opt.map((data: any) => {
        return {
          key: data.id,
          value: data.date,
          text: data.date,
        };
      });

      setDateOptions(date_opt_format);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    let report_data = await apiService.getAll(
      `/boq_report_generate?library_id=${fdata.newData.library_id}&roof_id=${fdata.newData.roof_id}&location_id=${fdata.newData.location_id}&date=${fdata.newData.date}&type=${fdata.newData.type}`
    );
    setOpenInfo(false);
    setLoading(false);
    setReportData(report_data);
  };

  function RenderList(datas: any) {
    if (datas.data === undefined || !datas.data) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (!datas.data.material_lists || datas.data.material_lists.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (datas.data.type === "Summary") {
      let grand_total_price = 0;
      return datas.data.material_lists.map((item: any, index: number) => {
        let mat_name = item.name.split(" ").join("_");
        let totalQuantity = 0;
        let indtotalPrice = 0;

        // eslint-disable-next-line
        datas.data.boq_drawings.map((drawing: any, index: number) => {
          if (drawing[mat_name] != "NULL") {
            totalQuantity = totalQuantity + Number(drawing[mat_name]);
          }
        });

        // eslint-disable-next-line
        datas.data.boq_roofs.map((roof: any, index: number) => {
          if (roof[mat_name] != "NULL") {
            totalQuantity = totalQuantity + Number(roof[mat_name]);
          }
        });

        let ind_unit_price =
          datas.data.location_unit_prices[mat_name] != "NULL"
            ? Math.round(datas.data.location_unit_prices[mat_name] * 100) / 100
            : 0;
        indtotalPrice = Math.round(ind_unit_price * totalQuantity * 100) / 100;

        // setGrandTotal(grandTotal+indtotalPrice);
        grand_total_price += indtotalPrice;
        // console.log(grand_total_price);
        setTimeout(() => {
          //total
          document.getElementById("report_total")!.innerHTML =
            "<b>" +
            formatNumberToIndonesianRupee(
              Math.round(grand_total_price * 100) / 100
            ) +
            "</b>";

          //additional contingency
          document.getElementById("report_contingency")!.innerHTML =
            "<b>" +
            formatNumberToIndonesianRupee(
              Math.round(grand_total_price * 0.15 * 100) / 100
            ) +
            "</b>";
          //grand total
          document.getElementById("grand_total")!.innerHTML =
            "<b>" +
            formatNumberToIndonesianRupee(
              Math.round((grand_total_price + grand_total_price * 0.15) * 100) /
                100
            ) +
            "</b>";
        }, 100);

        return (
          <Table.Row key={item.id}>
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{item.name + " / " + item.name_lang}</Table.Cell>
            <Table.Cell>{item.unit}</Table.Cell>
            <Table.Cell>
              {totalQuantity === 0 ? 0 : Math.round(totalQuantity * 100) / 100}
            </Table.Cell>
            <Table.Cell>
              {formatNumberToIndonesianRupee(ind_unit_price)}
            </Table.Cell>
            <Table.Cell>
              {formatNumberToIndonesianRupee(indtotalPrice)}
            </Table.Cell>
          </Table.Row>
        );
      });
    } else if (datas.data.type === "Detailed View") {
      let grand_total_price_detailed = 0;
      // eslint-disable-next-line
      return datas.data.material_lists.map((item: any, index: number) => {
        let mat_name = item.name.split(" ").join("_");
        let totalQuantity = 0;
        let indtotalPrice = 0;
        // eslint-disable-next-line
        datas.data.boq_drawings.map((drawing: any, index: number) => {
          if (drawing[mat_name] != "NULL") {
            totalQuantity = totalQuantity + Number(drawing[mat_name]);
          }
          // totalQuantity = totalQuantity + Number(drawing[mat_name]);
        });
        // eslint-disable-next-line
        datas.data.boq_roofs.map((roof: any, index: number) => {
          if (roof[mat_name] != "NULL") {
            totalQuantity = totalQuantity + Number(roof[mat_name]);
          }
        });

        let ind_unit_price =
          datas.data.location_unit_prices[mat_name] != "NULL"
            ? Math.round(datas.data.location_unit_prices[mat_name] * 100) / 100
            : 0;
        indtotalPrice = Math.round(ind_unit_price * totalQuantity * 100) / 100;

        // setGrandTotal(grandTotal+indtotalPrice);
        grand_total_price_detailed += indtotalPrice;
        setTimeout(() => {
          //total
          document.getElementById("report_total_detailed")!.innerHTML =
            "<b>" +
            formatNumberToIndonesianRupee(
              Math.round(grand_total_price_detailed * 100) / 100
            ) +
            "</b>";
          //additional contingency
          document.getElementById("report_contingency_detailed")!.innerHTML =
            "<b>" +
            formatNumberToIndonesianRupee(
              Math.round(grand_total_price_detailed * 0.15 * 100) / 100
            ) +
            "</b>";
          //grand total
          document.getElementById("grand_total_detailed")!.innerHTML =
            "<b>" +
            formatNumberToIndonesianRupee(
              Math.round(
                (grand_total_price_detailed +
                  grand_total_price_detailed * 0.15) *
                  100
              ) / 100
            ) +
            "</b>";
        }, 100);

        return (
          <Table.Row key={item.id}>
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{item.name + " / " + item.name_lang}</Table.Cell>
            <Table.Cell>{item.unit}</Table.Cell>
            <Table.Cell>
              {formatNumberToIndonesianRupee(ind_unit_price)}
            </Table.Cell>
            {datas.data.boq_drawings.map((drawing: any, index: number) => {
              // console.log(ind_unit_price);
              // drawing_arr[index] = drawing_arr[index] + parseFloat((ind_unit_price * (drawing[mat_name] === "NULL" ? 0 : (Number(drawing[mat_name])) === 0 ? 0 : (Number(drawing[mat_name])))).toFixed(2));

              // if(index >= datas.data.boq_drawings.length-1){
              //   setFooterLoadOption(true);
              // }

              // console.log(index + '->' + drawing_arr[index]);
              // @ts-ignore
              // document.getElementById("price-"+index).innerHTML = "dsad";
              // datas.setDrawingArrValue(drawing_arr);
              return (
                <React.Fragment key={"frag_thingy_" + index}>
                  <Table.Cell>
                    {drawing[mat_name] === "NULL" ? 0 : drawing[mat_name]}
                  </Table.Cell>
                  <Table.Cell>
                    {formatNumberToIndonesianRupee(
                      (
                        ind_unit_price *
                        (drawing[mat_name] === "NULL"
                          ? 0
                          : Number(drawing[mat_name]) === 0
                          ? 0
                          : Number(drawing[mat_name]))
                      ).toFixed(2)
                    )}
                  </Table.Cell>
                </React.Fragment>
              );
            })}

            {datas.data.boq_roofs.map((roof: any, index: number) => {
              // roof_arr[index] = roof_arr[index] + parseFloat((ind_unit_price * (roof[mat_name] === "NULL" ? 0 : (Number(roof[mat_name])) === 0 ? 0 : (Number(roof[mat_name])))).toFixed(2));

              return (
                <React.Fragment>
                  <Table.Cell>
                    {roof[mat_name] === "NULL" ? 0 : roof[mat_name]}
                  </Table.Cell>
                  <Table.Cell>
                    {/* {formatNumberToIndonesianRupee((ind_unit_price * (roof[mat_name] === "NULL" ? 0 : Number(roof[mat_name]))) === 0 ? 0 : (ind_unit_price * (roof[mat_name] === "NULL" ? 0 : Number(roof[mat_name]))).toFixed(2))} */}
                    {formatNumberToIndonesianRupee(
                      (
                        ind_unit_price *
                        (roof[mat_name] === "NULL"
                          ? 0
                          : Number(roof[mat_name]) === 0
                          ? 0
                          : Number(roof[mat_name]))
                      ).toFixed(2)
                    )}
                  </Table.Cell>
                </React.Fragment>
              );
            })}
            <Table.Cell>
              {formatNumberToIndonesianRupee(
                Math.round(totalQuantity * ind_unit_price * 100) / 100
              )}
            </Table.Cell>
          </Table.Row>
        );
      });
    }
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>BOQ</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Report</Breadcrumb.Section>
      </Breadcrumb>
    </div>,
    <div className="app-card-org" key="2" style={{ marginTop: "1rem" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Show Report Form
        <Icon name="plus" className="iconSize" />
      </Button>
    </div>,
    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">Report Form</Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Library"
                required
                options={libraryOptions}
                label="Select Library"
                value={fdata.newData.library_id}
                name="library_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Location"
                required
                options={locationOptions}
                label="Select Location"
                value={fdata.newData.location_id}
                name="location_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Date"
                required
                options={dateOptions}
                label="Select Date"
                value={fdata.newData.date}
                name="date"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Roof"
                required
                options={roofOptions}
                label="Select Roof"
                value={fdata.newData.roof_id}
                name="roof_id"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select Report Type"
              required
              options={reportOptions}
              label="Select Report Type"
              value={fdata.newData.type}
              name="type"
              onChange={handleChange}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,

    <Segment
      style={
        reportData &&
        reportData.type &&
        reportData.library_code &&
        reportData.material_lists &&
        reportData.location_unit_prices &&
        reportData.boq_roofs &&
        reportData.boq_drawings
          ? { overflowX: "scroll" }
          : { display: "none" }
      }
      key="3"
    >
      <Header>
        Material List{" "}
        {reportData && reportData.type && reportData.type === "Summary"
          ? "(Summary View)"
          : "(Detailed View)"}
      </Header>
      <Header>
        {"Design :  "}
        {reportData && reportData.library_code
          ? reportData.library_code
          : "N/A"}
      </Header>

      <Header>
        {"TSC Name :  "}
        {reportData && reportData.tsc_name ? reportData.tsc_name : "N/A"}
      </Header>

      <Table basic="very" unstackable striped>
        {reportData && reportData.type === "Summary" ? (
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Materials</Table.HeaderCell>
              <Table.HeaderCell>Unit</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Rate(Rp.)/Unit</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        ) : (
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Materials</Table.HeaderCell>
              <Table.HeaderCell>Unit</Table.HeaderCell>
              <Table.HeaderCell>Rate(Rp.)/Unit</Table.HeaderCell>
              {reportData &&
                reportData.boq_drawings &&
                reportData.boq_drawings.map((drawing: any, index: number) => {
                  drawing_arr[index] = 0;
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell>
                        {drawing.floors.name +
                          " / " +
                          drawing.floors.name_lang +
                          "(Quantity"}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {drawing.floors.name +
                          " / " +
                          drawing.floors.name_lang +
                          "(Price"}
                      </Table.HeaderCell>
                    </React.Fragment>
                  );
                })}
              ;
              {reportData &&
                reportData.boq_roofs &&
                reportData.boq_roofs.map((roof: any, index: number) => {
                  roof_arr[index] = 0;
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell>
                        {roof.roof.name +
                          " / " +
                          roof.roof.name_lang +
                          "(Quantity"}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {roof.roof.name +
                          " / " +
                          roof.roof.name_lang +
                          "(Price"}
                      </Table.HeaderCell>
                    </React.Fragment>
                  );
                })}
              ;<Table.HeaderCell>Total(Rp.)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )}

        <Table.Body>
          {reportData && (
            <RenderList
              data={reportData}
              setDrawingArrValue={setDrawingArrValue}
            />
          )}
        </Table.Body>

        {reportData && reportData.type === "Summary" ? (
          <Table.Footer>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <b>Total</b>
              </Table.Cell>
              <Table.Cell id="report_total">
                <b>{"Rp. 0"}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <b>(+)</b>
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <b>Additional Contingency</b>
              </Table.Cell>
              <Table.Cell>
                <b>15%</b>
              </Table.Cell>
              <Table.Cell id="report_contingency">
                <b>{"Rp. 0"}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <b>Grand Total</b>
              </Table.Cell>
              <Table.Cell id="grand_total">
                <b>{"Rp. 0"}</b>{" "}
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        ) : (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>
                <b>Total</b>
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>

              {reportData.boq_drawings &&
                reportData.boq_drawings.map((drawing: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell id={"price-" + index}>
                        {formatNumberToIndonesianRupee(
                          reportData.boq_drawing_detailed[index]
                        )}
                      </Table.HeaderCell>
                    </React.Fragment>
                  );
                })}

              {reportData.boq_roofs &&
                reportData.boq_roofs.map((roof: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>
                        {formatNumberToIndonesianRupee(
                          reportData.boq_roof_detailed[index]
                        )}
                      </Table.HeaderCell>
                    </React.Fragment>
                  );
                })}
              <Table.HeaderCell id="report_total_detailed">
                {"Rp. 0"}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <b>(+)</b>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <b>Additional Contingency</b>
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              {reportData.boq_drawings &&
                reportData.boq_drawings.map((drawing: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </React.Fragment>
                  );
                })}

              {reportData.boq_roofs &&
                reportData.boq_roofs.map((roof: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>
                        <b>15%</b>
                      </Table.HeaderCell>
                    </React.Fragment>
                  );
                })}

              <Table.Cell id="report_contingency_detailed">
                {"Rp. 0"}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>
                <b>Grand Total</b>
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              {reportData.boq_drawings &&
                reportData.boq_drawings.map((drawing: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </React.Fragment>
                  );
                })}

              {reportData.boq_roofs &&
                reportData.boq_roofs.map((roof: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </React.Fragment>
                  );
                })}
              <Table.Cell id="grand_total_detailed">{"Rp. 0"} </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>

      {boq_reports === undefined || loading ? <Loader active inverted size="big" /> : ""}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </Segment>,
  ];
}
