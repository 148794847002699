import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  
  Table,
  Button,
  Pagination,
  Grid,
  Header,
  Icon,
  Message,
  Loader,
  Input,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  loadUserPage,
  unloadUserPage,
  removeUser,
} from "../../redux/action/userAction";
import ModalComponent from "../../components/Modal";
import { alertActions } from "../../redux/action/alertAction";

export default function User(props: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(1);

  const [organization_id, setOrganizationId] = useState<string | null>(new URLSearchParams(props.location.search).get("organization_id") || null);
  const [role_id, setRoleId] = useState<string | null>(new URLSearchParams(props.location.search).get("role_id") || null);
  const [country_id, setCountryId] = useState<string | null>(new URLSearchParams(props.location.search).get("country_id") || null);

  const [apiUrl, setApiUrl] = useState<string>(
    organization_id 
      ? "/users/?organization_id="+organization_id
      : role_id 
        ? "/users/?role_id="+role_id
        : country_id 
          ? "/users/?country_id="+country_id
          : "/users"
  );
  const users = useSelector((state: any) => state.users);
  const [total, setTotal] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState<number>(5);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadUserPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadUserPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (users.last_page !== undefined) {
      setTotal(users.last_page);
      setPerPage(users.per_page || 5);
    }
    // eslint-disable-next-line
  }, [users]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/users?page=" + pageInfo.activePage.toString() + "&per_page=" + (perPage || 5) + "&q=" + search
      + (organization_id ? `&organization_id=${organization_id}` : '')
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl(
        "/users?q=" + search + "&per_page=" + (perPage || 5)
        + (organization_id ? `&organization_id=${organization_id}` : '')
      );
    }
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage(data.value as number || 5);

    setApiUrl(
      "/users?page=" + activePage + "&per_page=" + (data.value || 5) + "&q=" + search
      + (organization_id ? `&organization_id=${organization_id}` : '')
    );
  }

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);
    dispatch(removeUser(deleteId, () => {
      dispatch(loadUserPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));   
  };

  const clearFilters = ({organization_id, role_id, country_id, updateApiUrl}: any) => {
    setOrganizationId(organization_id || null);
    setRoleId(role_id || null);
    setCountryId(country_id || null);
    setSearch('');

    if(updateApiUrl === true) {
      setApiUrl(
        "/users?page=" + 1
        + (organization_id ? `&organization_id=${organization_id}` : '')
        + (role_id ? `&role_id=${role_id}` : '')
        + (country_id ? `&country_id=${country_id}` : '')
      )
    }
  }

  function RenderList({ data }: any) {
    if (data.data === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (data.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return data.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.first_name}</Table.Cell>
          <Table.Cell>{data.last_name}</Table.Cell>
          <Table.Cell>{data.email}</Table.Cell>
          <Table.Cell>
            {data.organization && data.organization.name && <li>{data.organization.name}</li>}
            {data.other_orgs_details && data.other_orgs_details.map((o: any) => <li key={o.id}>{o.name}</li>)}
            {!data.organization && data?.other_orgs_details.length === 0 && <b> - </b>}
          </Table.Cell>

          <Table.Cell>
            <Button
              circular
              className="editButton"
              as={Link}
              to={`/user/edit/${data.id}`}
              icon
            >
              <Icon name="pencil" />
            </Button>

            <Button
              circular
              className="deleteButton"
              onClick={() => deleteData(data.id)}
              icon
            >
              <Icon name="delete" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>
          <FormattedMessage id="User" defaultMessage="User" />
        </Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        icon
        className="createButton"
        labelPosition="left"
        as={Link}
        to="/user/create"
      >
        Create User
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        <FormattedMessage id="User" defaultMessage="User" />{" "}
        <FormattedMessage id="List" defaultMessage="List" />
        {
          organization_id ? (
            <FormattedMessage id="organization_id_filter" defaultMessage={
              " / Filtering By Organization: " + organization_id + (
                " (" + (users?.data?.[0]?.organization?.name ?? '*') + ")"
              )
            } />
          ) : ""
        }
        {
          role_id ? (
            <FormattedMessage id="role_id_filter" defaultMessage={
              " / Filtering By Role ID: " + role_id
            } />
          ) : ""
        }
        {
          country_id ? (
            <FormattedMessage id="country_id_filter" defaultMessage={
              " / Filtering By Country ID: " + country_id
            } />
          ) : ""
        }
        {
          (organization_id || role_id || country_id)
          ? (
            <Button size="mini" circular compact basic icon="close" onClick={() => clearFilters({updateApiUrl: true})}
              title="Clear Filter"
              style={{
                padding: "4px",
                fontSize: "1em",
                marginLeft: "10px",
                marginTop: "-3px",
              }}
            />
          ) : ""
        }
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-3"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>

            <Table.HeaderCell>Organization</Table.HeaderCell>

            <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{users && <RenderList data={users} />}</Table.Body>
      </Table>
      {users.data === undefined || loading ? <Loader active inverted size="big" /> : ""}
      <Grid>
        <Grid.Column>
          <div style={{ marginRight: '5px', display: 'inline-block' }}>
            <p style={{ display: 'inline-block' }}>Per Page: </p>
            <Dropdown
              options={[
                { key: 5, text: '5', value: 5 },
                { key: 10, text: '10', value: 10 },
                { key: 15, text: '15', value: 15 },
                { key: 25, text: '25', value: 25 },
                { key: 50, text: '50', value: 50 },
                { key: 100, text: '100', value: 100 },
              ]}
              placeholder='Per Page'
              compact
              selection
              upward
              onChange={onPerPageChange}
              style={{ marginLeft: '5px' }}
              value={perPage}
            />
          </div>

          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
  ];
}
