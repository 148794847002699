import React, { useState, useEffect, Component } from "react";
import {
  Segment,
  Header,
  Breadcrumb,
  Message,
  Divider,
  Form,
  Button,
  Tab,
  Icon,
  Label,
  Loader
} from "semantic-ui-react";
import { loadTrainingUserDetailsDataPage } from "../../redux/action/trainingUserDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
//import { userConstants } from "../../redux/action/authAction";
import { authHeader } from "../../helper";
import { AppConfig } from "../../config";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  organization_users: {
    id: number;
    user_id: number;
    training_module_id: number;
    module_lesson_id: number;
    // lesson_type_id: number;
    lesson_data_id: number;
    video_watched: string;
    document_viewed: string;
    pretest_data: string | null;
    duringtest_data: string | null;
    posttest_data: string | null;
    training_module: {
      id: number;
      name: string;
    };
    module_lesson: {
      id: number;
      name: string;
    };
    lesson_data: {
      id: number;
    };
    user: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
    };
  }[];
}

export default function TrainingUserDetailsDataInfo(props: Props) {
  const [training_module, setTrainingModule] = useState(0);
  const [module_lesson, setModuleLesson] = useState(0);
  const [lesson_data, setLessonData] = useState(0);
  const [apiUrl] = useState(`/user_details/${props.match.params.id}`);
  const organization_users = useSelector(
    (state: RootState) => state.organization_users
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadTrainingUserDetailsDataPage(apiUrl, () => { 
      setLoading(false);
    }));

    // return () => {
    //   dispatch(unloadTrainingModulePage());
    // };
  }, [apiUrl, dispatch]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Training User Details</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Detailed Data</Breadcrumb.Section>
      </Breadcrumb>
    </div>,
    <div className="app-card-org" key="2">
      <Header>Training User Details</Header>
      <Divider />
      {organization_users === undefined || organization_users.length <= 0 ? (
          <Message>Loading .... { organization_users.length <= 0 ? 'No User Detail Found' : '' }</Message>
      ) : (
        <Info
          organization_users={organization_users}
          training_module={training_module}
          setTrainingModule={setTrainingModule}
          module_lesson={module_lesson}
          setModuleLesson={setModuleLesson}
          lesson_data={lesson_data}
          setLessonData={setLessonData}
        />
        // JSON.stringify(organization_users)
      )}
    </div>,
  ];
}

class Info extends Component<any> {
  state: any = {
    match: [],
  };

  render() {
    const data = this.props.organization_users;

    let generateMatchData = () => {
      let match = data.filter((d: any) => {
        return (
          d.training_module_id === this.props.training_module &&
          d.module_lesson_id === this.props.module_lesson &&
          d.lesson_data_id === this.props.lesson_data
        );
      });
      this.setState({ match: match });
    };

    let generatePanes = (data: any) => {
      let panes: any = [];

      if (data.pretest_data) {
        panes.push({
          menuItem: "Pre Test",
          render: () => (
            <Tab.Pane>
              <ResultView
                type={"pretest"}
                data={JSON.parse(data.pretest_data)}
              />
            </Tab.Pane>
          ),
        });
      }

      if (data.duringtest_data) {
        panes.push({
          menuItem: "During Test",
          render: () => (
            <Tab.Pane>
              <ResultView
                type={"duringtest"}
                data={JSON.parse(data.duringtest_data)}
              />
            </Tab.Pane>
          ),
        });
      }

      if (data.posttest_data) {
        panes.push({
          menuItem: "Post Test",
          render: () => (
            <Tab.Pane>
              <ResultView
                type={"posttest"}
                data={JSON.parse(data.posttest_data)}
              />
            </Tab.Pane>
          ),
        });
      }

      if (data.video_watched || data.document_viewed) {
        panes.push({
          menuItem: "Resources",
          render: () => (
            <Tab.Pane>
              <div style={{ padding: '15px' }}>
                <p><b>Video Watched:</b> {data.video_watched ? 'Yes' : 'No'}</p>
                <p><b>Document Viewed:</b> {data.document_viewed ? 'Yes' : 'No'}</p>
              </div>
            </Tab.Pane>
          ),
        });
      }

      return panes;
    };

    let generateCertificate = (id: any) => {
      let headers = authHeader();
      let formdata = new FormData();
      formdata.append("result_data_id", id);

      const requestOptions: object = {
        method: "POST",
        headers: headers,
        body: formdata,
      };
      const config = {
        apiUrl: AppConfig.apiUrl,
      };

      //remaining code left to be done
      return fetch(
        `${config.apiUrl}/lesson_data/generate_certificate`,
        requestOptions
      )
        .then((res) => {
          const contentType = res.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return res.json();
          } else {
            return res.blob();
          }
        })
        .then((blob) => {
          if (blob instanceof Blob) {
            var file = URL.createObjectURL(blob);
            window.open(file);
          } else {
            if (blob.message) {
              alert(blob.message);
            } else {
              alert("Failed To Generate.");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message) {
            alert(err.message);
          } else {
            alert("Failed To Generate.");
          }
        });
    };

    //@ts-ignore
    if (data[0].user === undefined) {
      return <Message>Loading ....</Message>;
    }

    return (
      <div>
        <b style={{ fontFamily: "Tahoma", fontSize: "1.2em" }}>
          User Name <Icon name="arrow right"></Icon>{" "}
          {data[0].user.first_name + " " + data[0].user.last_name}
        </b>
        <br />
        <b style={{ fontFamily: "Tahoma", fontSize: "1.2em" }}>
          User Email{" "}
          <Icon name="arrow right" style={{ marginLeft: "3px" }}></Icon>{" "}
          {data[0].user.email}
        </b>
        <hr />
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Training Module"
                required
                options={_.uniqBy(
                  data.map((d: any) => {
                    return {
                      key: d.training_module.id,
                      value: d.training_module.id,
                      text: d.training_module.name,
                    };
                  }),
                  "key"
                )}
                label="Select Training Module"
                value={this.props.training_module}
                name="type"
                onChange={(e: any, res: any) =>
                  this.props.setTrainingModule(res.value)
                }
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Module Lesson"
                required
                options={_.uniqBy(
                  data
                    .filter((d: any) => {
                      return (
                        d.training_module.id === this.props.training_module
                      );
                    })
                    .map((d: any) => {
                      return {
                        key: d.module_lesson.id,
                        value: d.module_lesson.id,
                        text: d.module_lesson.name,
                      };
                    }),
                  "key"
                )}
                label="Select Module Lesson"
                value={this.props.module_lesson}
                name="type"
                onChange={(e: any, res: any) =>
                  this.props.setModuleLesson(res.value)
                }
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Lesson Data"
                required
                options={_.uniqBy(
                  data
                    .filter((d: any) => {
                      return (
                        d.training_module.id === this.props.training_module &&
                        d.module_lesson.id === this.props.module_lesson
                      );
                    })
                    .map((d: any) => {
                      return {
                        key: d.lesson_data.id,
                        value: d.lesson_data.id,
                        text: d.lesson_data.name,
                      };
                    }),
                  "key"
                )}
                label="Select Lesson Data"
                value={this.props.lesson_data}
                name="type"
                onChange={(e: any, res: any) =>
                  this.props.setLessonData(res.value)
                }
              />
            </Form.Field>
          </Form.Group>

          <Button
            disabled={
              !this.props.training_module ||
              !this.props.module_lesson ||
              !this.props.lesson_data
            }
            primary
            type="button"
            onClick={() => generateMatchData()}
          >
            View Data
          </Button>

          <Button
            disabled={
              !this.props.training_module ||
              !this.props.module_lesson ||
              !this.props.lesson_data ||
              !this.props.organization_users ||
              !this.state.match ||
              this.state.match.length <= 0
            }
            color="green"
            type="button"
            onClick={() => generateCertificate(this.state.match[0].id)}
          >
            Generate Certificate
          </Button>
        </Form>
        <hr />
        {this.state.match &&
          this.state.match.map((d: any) => {
            return (
              <div>
                <Tab panes={generatePanes(d)} defaultActiveIndex={0} />
                <br />
                <br />
              </div>
            );
          })}
      </div>
    );
  }
}

type QuestionAnswerType = {
  id: number;
  title: string;
  image: string;
  answers: {
    id: number;
    title: string;
    correct: boolean;
    image: string;
  }[];
}[];

export interface QuestionAnswerProps {
  data: QuestionAnswerType;
  type: string;
}

export interface QuestionAnswerState {}

class ResultView extends React.Component<
  QuestionAnswerProps,
  QuestionAnswerState
> {
  // state = { :  }
  render() {
    // console.log(this.props.data)
    return (
      <div>
        {/* {JSON.stringify(this.props.data)} */}
        {this.props.data &&
          this.props.data.map((q: any, index) => {
            q = this.props.data[index];
            console.log(q.question_type);
            console.log(q.selected_answer[0]);
            return (
              <div key={q.id} className="mb-3">
                <div>
                  <br />
                  <br />

                  <div>
                    <p style={{ display: "flex", alignItems: "center" }}>
                      <h4 style={{ display: "flex" }}>
                        <span style={{ marginTop: "7px" }}>
                          {index + 1 + ")   "} {q.title}
                        </span>
                      </h4>
                      <span
                        className={
                          q.image
                            ? "ml-3 badge badge-info"
                            : "ml-3 badge badge-danger"
                        }
                        title={q.image ? q.image : "NO IMAGE"}
                      >
                        {q.image ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => window.open(q.image)}
                          >
                            VIEW IMAGE
                          </span>
                        ) : (
                          "NO IMAGE"
                        )}
                      </span>

                      <span style={{ position: "absolute", right: "25px" }}>
                        <Label
                          as="a"
                          style={{
                            backgroundColor: "var(--color-blue)",
                            borderColor: "var(--color-blue)",
                            color: "white",
                          }}
                          tag
                        >
                          {q.question_type === "single"
                            ? "Single Choice Question"
                            : "Multiple Choice Question"}
                        </Label>
                      </span>
                    </p>
                  </div>

                  <ul className="mt-1" style={{ listStyleType: "upper-alpha" }}>
                    {q.answers.map((a: any, a_index: number) => {
                      a = q.answers[a_index];
                      return (
                        <Segment
                          style={
                            a.correct
                              ? {
                                  paddingTop: "10px",
                                  paddingLeft: "35px",
                                  paddingBottom: "0px",
                                  marginBottom: "10px",
                                  backgroundColor: "#9ec54d",
                                  color: "white",
                                }
                              : {
                                  paddingTop: "10px",
                                  paddingLeft: "35px",
                                  paddingBottom: "0px",
                                  marginBottom: "10px",
                                  backgroundColor: "lavender",
                                }
                          }
                        >
                          <li key={q.id + "-" + a_index}>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <p style={{ display: "flex" }}>
                                <b>{a.title}</b>
                              </p>
                              <span
                                className={
                                  a.image
                                    ? "ml-3 badge badge-info"
                                    : "ml-3 badge badge-danger"
                                }
                                title={a.image ? a.image : "NO IMAGE"}
                              >
                                {a.image ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => window.open(a.image)}
                                  >
                                    VIEW IMAGE
                                  </span>
                                ) : (
                                  "NO IMAGE"
                                )}
                              </span>
                              <span
                                style={{
                                  position: "absolute",
                                  right: "35px",
                                  fontSize: "1.8em",
                                }}
                              >
                                {q.selected_answer.includes(a.id) ? (
                                  <Icon size="small" name="user" />
                                ) : (
                                  ""
                                )}
                                {q.selected_answer.includes(a.id) ? (
                                  <span style={{ marginLeft: "20px" }}>
                                    <Icon
                                      size="small"
                                      name="arrow right"
                                      style={
                                        a.correct
                                          ? {
                                              marginRight: "20px",
                                              fontSize: "20px",
                                            }
                                          : {
                                              marginRight: "25px",
                                              fontSize: "20px",
                                            }
                                      }
                                    ></Icon>{" "}
                                    {a.correct ? (
                                      <Icon
                                        size="small"
                                        name="checkmark box"
                                      ></Icon>
                                    ) : (
                                      <Icon name="close"></Icon>
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                              {/* <span>{q.selected_answer.includes(a.id) &&  a.correct ?  : <Icon name="cross"></Icon> }</span> */}
                            </p>
                          </li>
                        </Segment>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}
