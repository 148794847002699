import React, { useState, useEffect } from "react"; // DONE
import {
  Segment,
  Header,
  Breadcrumb,
  Loader,
  Accordion,
  Image,
  Button,
  Icon,
  Tab,
  Label,
  Menu,
  Message,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import { AppConfig } from "../../config";
import {
  loadAppModulePage,
  unloadAppModulePage,
} from "../../redux/action/appAction";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { authHeader } from "../../helper";
import { AlertModalComponent } from "../../components/Modal";
interface RootState {
  apps: {
    apps: {
      id: number;
      name: string;
      description: string;
      image: string;
      module_lessons: {
        id: number;
        name: string;
        description: string;
        image: string;
        lesson_types: {
          id: number;
          name: string;
          description: string;
          image: string;
          lesson_datas: {
            id: number;
            pretest: string;
            duringtest: string;
            posttest: string;
            video: string;
            document: string;
          }[];
        }[];
      }[];
    }[];
  };
}

export default function AppModule() {
  // eslint-disable-next-line
  const [apiUrl, setApiUrl] = useState("/start_training");
  const [currentLesson, setCurrentLesson] = useState(null);
  const [answerSheet, setAnswerSheet]: any = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // const [loading, setLoading] = useState(false);

  const apps = useSelector((state: RootState) => state.apps);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAppModulePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAppModulePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  const handleAnswerChange = (
    test_type: any,
    question_type: any,
    answer_id: any,
    question_id: any
  ) => {
    if (!answerSheet) {
      alert("Opps, Something failed.");
      return false;
    }

    let tempAnswerSheet = answerSheet;
    // @ts-ignore
    tempAnswerSheet[test_type].map((q) => {
      if (q.id === question_id) {
        if (question_type === "single") {
          q["selected_answer"] = [answer_id];
        } else {
          let index = q["selected_answer"].indexOf(answer_id);
          if (index >= 0) {
            q["selected_answer"].splice(index, 1);
          } else {
            q["selected_answer"].push(answer_id);
          }
        }
        return q;
      } else {
        return q;
      }
    });
    // @ts-ignore
    setAnswerSheet({ ...tempAnswerSheet });
  };

  const startThisLesson = async (lesson_id: any, sheet: any) => {
    sheet["pretest_done"] = false;
    sheet["video_watched"] = sheet.video ? false : true;
    sheet["document_viewed"] = sheet.document ? false : true;
    sheet["learn_done"] = false;
    sheet["during_test_done"] = false;
    sheet["post_test_done"] = false;
    if (!sheet.pretest || sheet.pretest.length <= 0) {
      sheet["pretest_done"] = true;
    }
    if (!sheet.posttest || sheet.posttest.length <= 0) {
      sheet["post_test_done"] = true;
    }
    if (!sheet.duringtest || sheet.duringtest.length <= 0) {
      sheet["during_test_done"] = true;
    }
    if (!sheet.document && !sheet.video) {
      sheet["learn_done"] = true;
    }

    const res = await fetchUserTestStatus(lesson_id);
    console.log(res);
    if (res.pretest_data && res.pretest_data.length > 0) {
      sheet["pretest_done"] = true;
    }
    if (res.posttest_data && res.posttest_data.length > 0) {
      sheet["post_test_done"] = true;
    }
    if (res.duringtest_data && res.duringtest_data.length > 0) {
      sheet["during_test_done"] = true;
    }
    if (res.document_viewed && res.video_watched) {
      sheet["learn_done"] = true;
      sheet["video_watched"] = true;
      sheet["document_viewed"] = true;
    }
    setAnswerSheet(sheet);
    setCurrentLesson(lesson_id);
  };

  const fetchUserTestStatus = (lesson_id: any) => {
    let headers = authHeader();
    let formdata = new FormData();
    formdata.append("lesson_data_id", lesson_id);

    const requestOptions: object = {
      method: "POST",
      headers: headers,
      body: formdata,
    };
    const config = {
      apiUrl: AppConfig.apiUrl,
    };

    //remaining code left to be done
    return fetch(
      `${config.apiUrl}/lesson_data/fetch_user_test_data`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Fetch Data.");
      });
  };

  const markLearnStatus = async (type: string) => {
    let tempAnswerSheet = answerSheet;
    if (type === "video") {
      // @ts-ignore
      tempAnswerSheet["video_watched"] = true;
    }
    if (type === "document") {
      // @ts-ignore
      tempAnswerSheet["document_viewed"] = true;
    }
    // @ts-ignore
    setAnswerSheet({ ...tempAnswerSheet });
  };

  const markTestDone = async (type: string) => {
    let tempAnswerSheet = answerSheet;
    // console.log(type)
    if (type === "pretest") {
      // @ts-ignore
      const pretestdata = tempAnswerSheet["pretest"];

      const percentage = calculatePercentage(pretestdata);

      console.log("percentage" + percentage);

      if (percentage >= 70) {
        // @ts-ignore
        // tempAnswerSheet['pretest']['percentage'] = percentage;
        const res = await postTestData(
          tempAnswerSheet["id"],
          "pre",
          pretestdata
        );
        console.log(res);
        // @ts-ignore
        tempAnswerSheet["pretest_done"] = true;
      } else {
        setAlertMessage(
          "You obtained " +
            percentage +
            "%. \nMinimum 70% is required to pass. Please, Try Again."
        );
        // <AlertModalComponent alertMessage={"Failed. You got "+percentage+"Minimum %\n70%+ required to pass. Try Again."} />
        // alert("Failed. You got "+percentage+"%\n70%+ required to pass. Try Again.");
      }
    } else if (type === "posttest") {
      // @ts-ignore
      const posttestdata = tempAnswerSheet["posttest"];

      const percentage = calculatePercentage(posttestdata);

      if (percentage >= 70) {
        // @ts-ignore
        const res = await postTestData(
          tempAnswerSheet["id"],
          "post",
          posttestdata
        );
        console.log(res);
        // @ts-ignore
        tempAnswerSheet["post_test_done"] = true;
      } else {
        alert(
          "Failed. You got " +
            percentage +
            "%\n70%+ required to pass. Try Again."
        );
      }
    } else if (type === "duringtest") {
      // @ts-ignore
      const duringtestdata = tempAnswerSheet["duringtest"];

      const percentage = calculatePercentage(duringtestdata);

      if (percentage >= 70) {
        // @ts-ignore
        const res = await postTestData(
          tempAnswerSheet["id"],
          "during",
          duringtestdata
        );
        console.log(res);
        // @ts-ignore
        tempAnswerSheet["during_test_done"] = true;
      } else {
        alert(
          "Failed. You got " +
            percentage +
            "%\n70%+ required to pass. Try Again."
        );
      }
    } else if (type === "learnnow") {
      // @ts-ignore
      const res = await postTestData(tempAnswerSheet["id"], "learn", {
        video_watched: true,
        document_viewed: true,
      });
      console.log(res);
      // @ts-ignore
      tempAnswerSheet["learn_done"] = true;
    }
    // @ts-ignore
    setAnswerSheet({ ...tempAnswerSheet });
  };

  //calculate percentage of each testdata submitted
  const calculatePercentage = (test_data: string) => {
    const totalQuestions = test_data.length;
    console.log("Total Q" + totalQuestions);
    let correctAnswers = 0; //ok
    let percentage = 0;

    for (let i = 0; i < test_data.length; i++) {
      let correct_ids = test_data[i]["answers"]
        .filter((a: any) => {
          return a.correct;
        })
        .map((a: any) => {
          return a.id;
        });
      if (test_data[i]["selected_answer"].length > 0) {
        let correct = test_data[i]["selected_answer"].every((a_id: any) => {
          return correct_ids.indexOf(a_id) >= 0;
        });
        if (correct) {
          correctAnswers++;
        }
      }
    }

    console.log("Total Corr" + correctAnswers);

    percentage = (correctAnswers / totalQuestions) * 100;

    return percentage;
  };

  //send POST request to store the test data of logged in user
  const postTestData = async (
    lesson_data_id: string,
    type: string,
    data: object
  ) => {
    // this.props.setloading(true);
    let headers = authHeader();
    let formdata = new FormData();
    formdata.append("lesson_data_id", lesson_data_id);
    formdata.append("test_type", type);
    formdata.append("test_data", JSON.stringify(data));
    const requestOptions: object = {
      method: "POST",
      headers: headers,
      body: formdata,
    };
    const config = {
      apiUrl: AppConfig.apiUrl,
    };

    //remaining code left to be done
    return fetch(
      `${config.apiUrl}/lesson_data/store_data_frontend`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
        alert("Failed To Save Data.");
      });
  };

  let rootPanels = [
    // { key: 'panel-1', title: 'Level 1', content: { content: Level1Content } },
  ];

  let generatePanes = (data: any) => {
    let panes: any = [];

    if (data.pretest) {
      panes.push({
        menuItem: (
          // @ts-ignore
          <Menu.Item key="pretest">Pre Test</Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            {/* SEND answerSheet.pretest_done to show answers/results */}
            <ResultView
              done={
                // @ts-ignore
                answerSheet.pretest_done
              }
              type={"pretest"}
              data={data.pretest}
              id={data.id}
              handleAnswerChange={handleAnswerChange}
              markTestDone={markTestDone}
            />
          </Tab.Pane>
        ),
      });
    }

    panes.push({
      menuItem: (
        // @ts-ignore
        <Menu.Item key="learn_more" disabled={!answerSheet.pretest_done}>
          Learn More
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          {data.video && (
            <h4>
              <Button
                primary
                target="_blank"
                rel="noopener noreferrer"
                href={data.video}
                icon
                onClick={(e) => markLearnStatus("video")}
                labelPosition="right"
              >
                Watch Video
                <Icon name="arrow right" />
              </Button>
            </h4>
          )}
          {data.document && (
            <h4>
              <Button
                secondary
                target="_blank"
                rel="noopener noreferrer"
                href={data.document}
                icon
                onClick={(e) => markLearnStatus("document")}
                labelPosition="right"
              >
                Read Documents/Manual
                <Icon name="arrow right" />
              </Button>
            </h4>
          )}
          {
            // @ts-ignore
            answerSheet.learn_done ? (
              <div>
                <br />
                <h3>
                  <Icon name="check" /> Great Job - Learning Task Completed
                </h3>
                <br />
              </div>
            ) : (
              <div>
                <br />
                <Button
                  color={"green"}
                  disabled={
                    // @ts-ignore
                    !answerSheet.video_watched || !answerSheet.document_viewed
                  }
                  onClick={(e) => markTestDone("learnnow")}
                >
                  Submit & Continue
                </Button>
              </div>
            )
          }
        </Tab.Pane>
      ),
    });

    if (data.duringtest) {
      panes.push({
        menuItem: (
          // @ts-ignore
          <Menu.Item
            key="during_test"
            disabled={!answerSheet.pretest_done || !answerSheet.learn_done}
          >
            During Test
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <ResultView
              done={
                // @ts-ignore
                answerSheet.during_test_done
              }
              type={"duringtest"}
              data={data.duringtest}
              id={data.id}
              handleAnswerChange={handleAnswerChange}
              markTestDone={markTestDone}
            />
          </Tab.Pane>
        ),
      });
    }

    if (data.posttest) {
      panes.push({
        menuItem: (
          // @ts-ignore
          <Menu.Item
            key="post_test"
            disabled={
              !answerSheet.pretest_done ||
              !answerSheet.learn_done ||
              !answerSheet.during_test_done
            }
          >
            Post Test
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <ResultView
              done={
                // @ts-ignore
                answerSheet.post_test_done
              }
              type={"posttest"}
              data={data.posttest}
              id={data.id}
              handleAnswerChange={handleAnswerChange}
              markTestDone={markTestDone}
            />
          </Tab.Pane>
        ),
      });
    }

    return panes;
  };

  if (apps && apps.apps) {
    for (let a = 0; a < apps.apps.length; a++) {
      let lesson_data_panel = [];
      let lesson_type_panel = [];
      let module_lesson_panel = [];
      // let apps_panel = []
      for (let ml = 0; ml < apps.apps[a].module_lessons.length; ml++) {
        lesson_type_panel = [];
        for (
          let lt = 0;
          lt < apps.apps[a].module_lessons[ml].lesson_types.length;
          lt++
        ) {
          lesson_data_panel = [];
          for (
            let ld = 0;
            ld <
            apps.apps[a].module_lessons[ml].lesson_types[lt].lesson_datas
              .length;
            ld++
          ) {
            let data =
              apps.apps[a].module_lessons[ml].lesson_types[lt].lesson_datas[ld];
            lesson_data_panel.push({
              key: "ld_" + data.id,
              title: "Lesson #" + (ld + 1),
              content: {
                content: (
                  <div>
                    <br />
                    {/* {JSON.stringify(data)} */}
                    <div>
                      {currentLesson &&
                      currentLesson === data.id &&
                      answerSheet ? (
                        <Tab
                          panes={generatePanes(data)}
                          defaultActiveIndex={0}
                        />
                      ) : (
                        <Button
                          primary
                          onClick={(e) => startThisLesson(data.id, data)}
                        >
                          Start This Lesson
                        </Button>
                      )}
                      <br />
                      {
                        // @ts-ignore
                        answerSheet &&
                        // @ts-ignore
                        answerSheet.pretest_done &&
                        // @ts-ignore
                        answerSheet.learn_done &&
                        // @ts-ignore
                        answerSheet.during_test_done &&
                        // @ts-ignore
                        answerSheet.post_test_done ? (
                          <div>
                            <h2>
                              Congratulations! You completed all the tests for
                              this module.
                            </h2>
                            {/* <p>Want to Generate a Certificate ?</p>
                            <Button primary>Yes Generate Now</Button> */}
                          </div>
                        ) : (
                          ""
                        )
                      }
                    </div>
                  </div>
                ),
              },
            });
          }
          let data = apps.apps[a].module_lessons[ml].lesson_types[lt];
          // lesson_type_panel.push({ key: 'lt_'+data.id, title: data.name, content: data.description })
          let panel = (
            <div>
              {data.image && (
                <Image src={data.image} size="small" verticalAlign="bottom" />
              )}
              <p> {data.description}</p>
              <Accordion.Accordion panels={lesson_data_panel} />
            </div>
          );
          lesson_type_panel.push({
            key: "lt_" + data.id,
            title: data.name,
            content: {
              content: panel,
            },
          });
        }
        let data = apps.apps[a].module_lessons[ml];
        // module_lesson_panel.push({ key: 'ml_'+data.id, title: data.name, content: data.description })
        let panel = (
          <div>
            {data.image && (
              <Image src={data.image} size="small" verticalAlign="bottom" />
            )}
            <p> {data.description}</p>
            <Accordion.Accordion panels={lesson_type_panel} />
          </div>
        );
        module_lesson_panel.push({
          key: "ml_" + data.id,
          title: data.name,
          content: {
            content: panel,
          },
        });
      }
      let data = apps.apps[a];
      // apps_panel.push({ key: 'app_'+data.id, title: data.id, content: data.name })
      let app_panel = (
        <div>
          {data.image && (
            <Image src={data.image} size="small" verticalAlign="bottom" />
          )}
          <p> {data.description}</p>
          <Accordion.Accordion panels={module_lesson_panel} />
        </div>
      );
      rootPanels.push({
        key: "app_" + data.id,
        title: data.name,
        content: { content: app_panel },
      });
    }
  }

  ////

  const openNewWindow = () => {
    const w = 1200;
    const h = 850;
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      "/training_start",
      "Popup",
      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, width=" +
        w / systemZoom +
        ", height=" +
        h / systemZoom +
        ", top=" +
        top +
        ", left=" +
        left
    );
    // @ts-ignore
    if (window.focus) newWindow.focus();
  };

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Start Training</Breadcrumb.Section>
      </Breadcrumb>
      <AlertModalComponent
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        alertMessageHeader="Test Failed!!!"
      />
    </div>,
    <div style={{ marginTop: "1rem" }} key="99">
      <Button primary onClick={openNewWindow}>
        Start Training
      </Button>
    </div>,
    <div style={{ marginTop: "1rem" }} key="100">
      <Message negative>
        <Message.Header>
          This page has been deprecated. Please use our new page by clicking the
          button above.
        </Message.Header>
      </Message>
    </div>,
    <Segment key="2" style={{ opacity: 0.2, pointerEvents: "none" }}>
      <Header>Start Training</Header>

      {apps.apps === undefined ? (
        <Loader active inverted size="big" />
      ) : (
        <div className="tree_acc" style={{ position: "relative" }}>
          <Accordion panels={rootPanels} styled style={{ width: "100%" }} />
        </div>
      )}
      {/* {console.log(rootPanels)} */}
    </Segment>,
  ];
}

type QuestionAnswerType = {
  id: number;
  title: string;
  image: string;
  answers: {
    id: number;
    title: string;
    correct: boolean;
    image: string;
  }[];
}[];

export interface QuestionAnswerProps {
  data: QuestionAnswerType;
  handleAnswerChange: any;
  markTestDone: any;
  type: string;
  id: any;
  done: boolean;
}

export interface QuestionAnswerState {}

class ResultView extends React.Component<
  QuestionAnswerProps,
  QuestionAnswerState
> {
  // state = { :  }
  render() {
    // console.log(this.props.data)

    if (this.props.done) {
      return (
        <div>
          <br />
          <h3>
            <Icon name="check" /> Great Job - Test Completed
          </h3>
          <p>You Passed.</p>
          <b>Now you can proceed to next Test.</b>
          <br />
        </div>
      );
    }

    return (
      <div>
        {/* {JSON.stringify(this.props.data)} */}
        {this.props.data &&
          this.props.data.map((q: any, index) => {
            q = this.props.data[index];
            return (
              <div key={q.id} className="mb-3">
                <Segment color="blue" style={{ backgroundColor: "aliceblue" }}>
                  <Segment style={{ backgroundColor: "lavender" }}>
                    <p style={{ display: "flex", alignItems: "center" }}>
                      <h4 style={{ display: "flex" }}>
                        <span style={{ marginTop: "7px" }}>
                          {index + 1 + ")   "} {q.title}
                        </span>
                      </h4>
                      <span
                        className={
                          q.image
                            ? "ml-3 badge badge-info"
                            : "ml-3 badge badge-danger"
                        }
                        title={q.image ? q.image : "NO IMAGE"}
                      >
                        {q.image ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => window.open(q.image)}
                          >
                            VIEW IMAGE
                          </span>
                        ) : (
                          "NO IMAGE"
                        )}
                      </span>

                      <span style={{ position: "absolute", right: "25px" }}>
                        <Label
                          as="a"
                          style={{
                            backgroundColor: "var(--color-blue)",
                            borderColor: "var(--color-blue)",
                            color: "white",
                          }}
                          tag
                        >
                          {q.question_type === "single"
                            ? "Single Choice Question"
                            : "Multiple Choice Question"}
                        </Label>
                      </span>
                    </p>
                  </Segment>

                  <ul className="mt-1" style={{ listStyleType: "upper-alpha" }}>
                    {q.answers.map((a: any, a_index: number) => {
                      a = q.answers[a_index];
                      return (
                        <Segment
                          style={{
                            paddingTop: "10px",
                            paddingLeft: "35px",
                            paddingBottom: "0px",
                            marginBottom: "10px",
                            backgroundColor: "#ddd",
                          }}
                        >
                          <li key={q.id + "-" + a_index}>
                            <p
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              {q.question_type === "single" ? (
                                <input
                                  className={"ml-1 mr-2"}
                                  style={{
                                    zoom: 1.3,
                                    transform: "translate(0,2px)",
                                  }}
                                  type="radio"
                                  name={q.id + "-radio"}
                                  onChange={(e) =>
                                    this.props.handleAnswerChange(
                                      this.props.type,
                                      q.question_type,
                                      a.id,
                                      q.id
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  className={"ml-1 mr-2"}
                                  style={{
                                    zoom: 1.3,
                                    transform: "translate(0,2px)",
                                  }}
                                  type="checkbox"
                                  name={q.id + "-check"}
                                  onChange={(e) =>
                                    this.props.handleAnswerChange(
                                      this.props.type,
                                      q.question_type,
                                      a.id,
                                      q.id
                                    )
                                  }
                                />
                              )}
                              <p style={{ display: "flex" }}>
                                <b>{a.title}</b>
                              </p>
                              <span
                                className={
                                  a.image
                                    ? "ml-3 badge badge-info"
                                    : "ml-3 badge badge-danger"
                                }
                                title={a.image ? a.image : "NO IMAGE"}
                              >
                                {a.image ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => window.open(a.image)}
                                  >
                                    VIEW IMAGE
                                  </span>
                                ) : (
                                  "NO IMAGE"
                                )}
                              </span>
                              {/* <span style={{position: 'absolute', right: '35px', fontSize: '1.8em'}}>
                            {q.selected_answer.includes(a.id) ? <Icon name="user" /> : '' }
                            {q.selected_answer.includes(a.id) ? 
                                                                <span style={{marginLeft: '20px'}}>
                                                                   <Icon name="arrow right" style={a.correct ? {marginRight: '20px', fontSize: '20px'} : {marginRight: '25px', fontSize: '20px'}}></Icon>  {a.correct ? <Icon name="checkmark box"></Icon> : <Icon name="close"></Icon> } 
                                                                </span> : ''
                            }
                            
                            </span> */}
                              {/* <span>{q.selected_answer.includes(a.id) &&  a.correct ?  : <Icon name="cross"></Icon> }</span> */}
                            </p>
                          </li>
                        </Segment>
                      );
                    })}
                  </ul>
                </Segment>
              </div>
            );
          })}
        <Button
          color={"green"}
          onClick={(e) => this.props.markTestDone(this.props.type)}
        >
          Submit
        </Button>
      </div>
    );
  }
}
