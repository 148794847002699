import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from '@formio/react';
import settingsForm from './GeoLocation.settingsForm';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const Geo = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      longitude: 0,
      latitude: 0,
    }
  }

  componentDidUpdate() {
    
    
    if (this.state.value != null && this.state.longitude=== 0 && this.state.latitude=== 0) {
      let arr = (this.state.value).split(",");
      let latitude = arr[0] ?? '0'
      let longitude = arr[1] ?? '0'
      this.setState({
        latitude: latitude,
        longitude: longitude,
        value: latitude + ',' + longitude
      }
      );
    }
  }



  setValue = (e) => {

    if (e.target.name === 'longitude') {
      if (this.state.latitude)
        this.setState({
          longitude: e.target.value,
          value: this.state.latitude + ',' + e.target.value
        },
          () => this.props.onChange(this.state.value)
        );

    } else {

      this.setState({
        latitude: e.target.value,
        value: e.target.value + ',' + this.state.longitude
      },
        () => this.props.onChange(this.state.value)

      );

    }
    // this.setState({
    //   value: e.target.value
    // },
    //   () => this.props.onChange(null, this.state.value)
    // );
  }

  findGPS = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition, this.errors, { timeout: 10000 });
    } else {
      var data = "Geolocation is not supported by this browser.";
      console.log(data);

    }

  }
  showPosition = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      value: position.coords.latitude + ',' + position.coords.longitude
    },
      () => this.props.onChange(this.state.value)
    );


  }
  errors = (error) => {
    console.log(error);
  }

  render() {
    let {value} = this.state
    value = value || ''
    let arr = (value).split(",")
    let latitude = arr[0] ?? '0'
    let longitude = arr[1] ?? '0'
    return (
      <div className="row " style={{ margin: '0 5px' }}>


        <input className="form-control col-4" type="number" step="any" placeholder="Latitude" onChange={this.setValue} name='latitude' value={latitude} />
      &nbsp;
        <input className="form-control col-4 " type="number" step="any" placeholder="Longitude" onChange={this.setValue}  name='longitude' value={longitude} />
      &nbsp;
        <input className="form-control btn btn-info col-3" type="button" onClick={this.findGPS} value="Find GPS" />
      </div>
    );
  }
};

export default class GeoLocation extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Geo Location',
      icon: 'square',
      group: 'basic',
      documentation: '',
      weight: -10,
      schema: GeoLocation.schema()
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'geolocation',
      label: 'Geo Location',
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('attachReact', this.updateValue);
    return ReactDOM.render(
      <Geo
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    //console.log('detachReact', element);
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
