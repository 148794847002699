import React, { useEffect, useState, useCallback } from "react";
import {
  Breadcrumb,
  Header,
  Button,
  Icon,
  Image,
  Message,
  Grid,
  Pagination,
  Loader,
  Popup,
  Modal,
  Form,
  List,
  Divider,
  Input,
  Label,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  loadFormbuilderPageMeta,
  unloadFormbuilderPageMeta,
  removeFormbuilderMeta,
} from "../../redux/action/formbuilderMetaAction";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";

import { AppConfig } from "../../config";

type indFormbuilderType = {
  name: string;
  _id: string;
  slug: string;
  organization_id: string;
  order: number;
  title: string;
  users: {
    id: number;
  }[];
  tags?:string[],
  is_meta: string,
  meta_record_type: string,
};
interface RootState {
  formbuilder_meta: {
    forms: {
      data: Array<indFormbuilderType>;
      last_page: number;
      per_page: number;
    };
    organization: any[];
    modules?: any[];
    projects?: any[];
  };
}

interface RenderPropsType {
  data: {
    data: indFormbuilderType[];
  };
}

export default function FormbuilderMeta(props: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [slug, setSlug] = useState<string>();
  
  const [openImport, setOpenImport] = useState<boolean>(false);


  const [deleteId, setDeleteId] = useState<string>("");
  const [activePage, setActivePage] = useState<number>(1);
  
  const [organization_id, setOrganizationId] = useState<string | null>(new URLSearchParams(props.location.search).get("organization_id") || null);
  const [apiUrl, setApiUrl] = useState<string>(
    organization_id 
      ? "/formBuilder/meta/?organization_id="+organization_id
      : "/formBuilder/meta"
  );
  
  const [total, setTotal] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const formbuilder_metas = useSelector((state: RootState) => state.formbuilder_meta); // of is_meta=true type
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    dispatch(
      loadFormbuilderPageMeta(apiUrl, () => {
        setLoading(false);
      })
    );
    return () => {
      dispatch(unloadFormbuilderPageMeta());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (formbuilder_metas.forms !== undefined) {
      //console.log(formbuilders.last_page)
      setTotal(formbuilder_metas.forms.last_page);
      setPerPage(formbuilder_metas.forms.per_page || 5);
    }
  }, [formbuilder_metas]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);

    setApiUrl(
      "/formBuilder/meta/?page=" + pageInfo.activePage.toString() + "&per_page=" + (perPage || 5) + "&q=" + search
      + (organization_id ? `&organization_id=${organization_id}` : '')
    );
  };

  const onSearch = (event: any) => {
    // console.log(event.key)
    if (event.key === "Enter") {
      setApiUrl(
        "/formBuilder/meta/?q=" + search + "&per_page=" + (perPage || 5)
        + (organization_id ? `&organization_id=${organization_id}` : '')
      );
    }
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage(data.value as number || 5);

    setApiUrl(
      "/formBuilder/meta/?page=" + activePage + "&per_page=" + (data.value || 5) + "&q=" + search
      + (organization_id ? `&organization_id=${organization_id}` : '')
    );
  }

  const importForm = () => {
    setOpenImport(true);
  };

  const processExcel = () => {
    apiService.save(`/formBuilderImport`, { file: file }).then(
      (data) => {
        //dispatch(alertActions.success(data.message));
        setOpenImport(false);
        // console.log({data})
        history.push("/formbuilder/meta/create", data);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  const handleFileChange = async (event: any, result: any) => {
    let value = event.target.files[0];
    setFile(value);
  };

  const deleteData = (slug: string) => {
    setOpen(true);
    setDeleteId(slug);
  };

  const confirmDelete = () => {
    // alert(delteId)
    setLoading(true);
    dispatch(
      removeFormbuilderMeta(
        deleteId,
        () => {
          dispatch(
            loadFormbuilderPageMeta(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };
  
  const clearFilters = ({organization_id, updateApiUrl}: any) => {
    setOrganizationId(organization_id || null);
    setSearch('');

    if(updateApiUrl === true) {
      setApiUrl(
        "/formBuilder/meta/?page=" + 1
        + (organization_id ? `&organization_id=${organization_id}` : '')
      )
    }
  }

  const RenderList = useCallback((): any => {
    if (formbuilder_metas.forms === undefined) {
      return <Message>No Meta Records Found</Message>;
    }
    if (formbuilder_metas.forms.data.length === 0) {
      return <Message>No Meta Records Found</Message>;
    }

    return formbuilder_metas.forms.data.map((item, index: number) => {
      return (
        <List divided verticalAlign="middle" key={index}>
          <List.Item>
            <List.Content floated="left">
              <List.Header className="text-uppercase" style={{fontSize: '1.2em'}}>{item.name}</List.Header>

              <List.Description style={{ marginTop: "8px", opacity: "0.7" }}>
                {item.meta_record_type ?? ""}
              </List.Description>
            </List.Content>

            <List.Content floated="right">
              <Popup
                trigger={
                  <Button
                    icon="pencil"
                    as={Link}
                    to={`/formbuilder/meta/edit/${item.slug}`}
                    className="formbuilder-buttons"
                    circular
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  ></Button>
                }
                content="Edit this Form"
                position="top center"
              />

              <Popup
                trigger={
                  <Button
                    className="formbuilder-buttons"
                    style={{
                      backgroundColor: "white",
                      color: "var(--color-red)",
                    }}
                    circular
                    onClick={() => deleteData(item.slug)}
                    icon="trash"
                  ></Button>
                }
                content="Delete this Form"
                position="top right"
              />
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
    // eslint-disable-next-line
  }, [formbuilder_metas]);

  return [
    <div key={1}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        
        <Breadcrumb.Section link as={Link} to="/formbuilder" active>
          <FormattedMessage id="Formbuilder" defaultMessage="Formbuilder" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />

        <Breadcrumb.Section active>
          {" "}
          <FormattedMessage id="MetaDataFields" defaultMessage="Meta Data Fields" />
        </Breadcrumb.Section>
      </Breadcrumb>
      
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
      />

      <Modal
        size="small"
        open={openImport}
        onClose={() => setOpenImport(false)}
        style={{ position: "relative" }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <h4>Import form using excel</h4>
            <Button
              onClick={() => {
                window.open(
                  `${AppConfig.apiUrl.replace("api", "")}static/bUXn1gEZ.xlsx`
                );
              }}
              className="createButton"
            >
              Download Sample
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Form.Input
              fluid
              type="file"
              name="excel"
              label="Upload Excel file"
              onChange={handleFileChange}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              required
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button className="clearButton" onClick={() => setOpenImport(false)}>
            Cancel
          </Button>
          <Button className="submitButton" onClick={() => processExcel()}>
            Import
          </Button>
        </Modal.Actions>
      </Modal>
    </div>,

    <div className="app-card-org" key={3}>
      <Button.Group>
        <Button as={Link} to="/formbuilder/meta/create" className="createButton">
          New Form
        </Button>

        <Button className="importButton" onClick={() => importForm()}>
          Import Form
        </Button>
      </Button.Group>

      <Header>
        <FormattedMessage id="Meta Data" defaultMessage="Meta Data" /> {" "}
        <FormattedMessage id="Forms" defaultMessage="Forms" />

        {
          organization_id ? (
            <FormattedMessage id="organization_id_filter" defaultMessage={
              " / Filtering By Organization: " + organization_id + (
                " (" + (formbuilder_metas?.organization?.[organization_id] ?? '*') + ")"
              )
            } />
          ) : ""
        }
        {
          (organization_id)
          ? (
            <Button size="mini" circular compact basic icon="close" onClick={() => clearFilters({updateApiUrl: true})}
              title="Clear Filter"
              style={{
                padding: "4px",
                fontSize: "1em",
                marginLeft: "10px",
                marginTop: "-3px",
              }}
            />
          ) : ""
        }
        
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small"
          action={{ icon: "search" }}
          value={search}
          placeholder="Search..."
        />
      </Header>

      {formbuilder_metas.forms === undefined ? (
        <Loader active inverted size="big" />
      ) : (
        <div style={{marginTop: '30px'}}>
          <RenderList />
        </div>
      )}

      <br/>
      <Grid>
        <Grid.Column>
          <div style={{ marginRight: '5px', display: 'inline-block' }}>
            <p style={{ display: 'inline-block' }}>Per Page: </p>
            <Dropdown
              options={[
                { key: 5, text: '5', value: 5 },
                { key: 10, text: '10', value: 10 },
                { key: 15, text: '15', value: 15 },
                { key: 25, text: '25', value: 25 },
                { key: 50, text: '50', value: 50 },
                { key: 100, text: '100', value: 100 },
              ]}
              placeholder='Per Page'
              compact
              selection
              onChange={onPerPageChange}
              style={{ marginLeft: '5px' }}
              value={perPage}
            />
          </div>

          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
  ];
}
