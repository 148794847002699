import React from "react";
import * as Sentry from "@sentry/react";

interface MyState {
  error: any;
  errorInfo: any;
}

export default class ErrorBoundary extends React.Component<any, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    if (error) {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                marginBottom: "50px",
                fontFamily: "'Lato', Arial, sans-serif",
                fontSize: "50px",
              }}
              title="Drastically..."
            >
              Something Went Wrong
            </h1>
            <p
              style={{
                display: "inline-block",
                lineHeight: "1.2",
                transition: "line-height .2s, width .2s",
                overflow: "hidden",
                fontFamily: "'Roboto Slab', Arial, serif",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              {this.state.error && this.state.error.toString()}
            </p>
            <p
              onClick={() => {
                window.location.href = window.location.origin;
              }}
              style={{
                textAlign: "center",
                color: "teal",
                letterSpacing: "2px",
                fontWeight: 500,
                border: "1px solid teal",
                borderRadius: "15px",
                padding: "5px 20px",
                cursor: "pointer",
                background: "#eee",
              }}
            >
              Reload
            </p>
            <div>
              {process.env.NODE_ENV == "production" ? (
                ""
              ) : (
                <pre
                  style={{
                    background: "#323232",
                    color: "#eee",
                    padding: "10px 15px 20px 0",
                    borderBottom: "#ff0000 10px solid",
                    borderRadius: "0 0 30px 30px",
                  }}
                >
                  {this.state.errorInfo.componentStack}
                </pre>
              )}
            </div>
          </div>
        </div>
      );
    }
    // {this.state.error && this.state.error.toString()}
    // {this.state.errorInfo.componentStack}

    return this.props.children;
  }
}
