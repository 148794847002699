import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const tileTypes = {
  TILE_LOADED: "TILE_LOADED",
  TILE_UNLOADED: "TILE_UNLOADED",
  ADD_TILE: "ADD_TILE",
  EDIT_TILE: "EDIT_TILE",
  DELETE_TILE: "DELETE_TILE",
};

export const loadTilePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: tileTypes.TILE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadTilePage = () => {
  return {
    type: tileTypes.TILE_UNLOADED,
    payload: {},
  };
};

export const addTile = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/tile", payload).then(
      (data) => {
        dispatch({
          type: tileTypes.ADD_TILE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editTile = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/tile/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: tileTypes.EDIT_TILE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeTile = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/tile/delete/${id}`).then(
      (data) => {
        dispatch({
          type: tileTypes.DELETE_TILE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
