import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const galleryTypes = {
  GALLERY_LOADED: "GALLERY_LOADED",
  GALLERY_UNLOADED: "GALLERY_UNLOADED",
  ADD_GALLERY: "ADD_GALLERY",
  EDIT_GALLERY: "EDIT_GALLERY",
  DELETE_GALLERY: "DELETE_GALLERY",
};

export const loadGalleryPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: galleryTypes.GALLERY_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadGalleryPage = () => {
  return {
    type: galleryTypes.GALLERY_UNLOADED,
    payload: {},
  };
};

export const addGallery = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/gallery", {...payload,extra}).then(
      (data) => {
        dispatch({
          type: galleryTypes.ADD_GALLERY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editGallery = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/gallery/update/${payload.id}`, {...payload,extra}).then(
      (data) => {
        dispatch({
          type: galleryTypes.EDIT_GALLERY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeGallery = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/gallery/delete/${id}`).then(
      (data) => {
        dispatch({
          type: galleryTypes.DELETE_GALLERY,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
