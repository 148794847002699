import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const plannerTypes = {
  PLANNER_LOADED: "PLANNER_LOADED",
  PLANNER_UNLOADED: "PLANNER_UNLOADED",
  ADD_PLANNER: "ADD_PLANNER",
  EDIT_PLANNER: "EDIT_PLANNER",
  DELETE_PLANNER: "DELETE_PLANNER",
};

export const loadPlannerPage = (url: string, afterSuccess: any = null, afterError: any = null, afterFinally: any = null) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: plannerTypes.PLANNER_LOADED,
          payload: data,
        });
        if(afterSuccess){
          afterSuccess();
        }
        if(afterFinally){
          afterFinally();
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if(afterError){
          afterError();
        }
        if(afterFinally){
          afterFinally();
        }
      }
    );
  };
};

export const unloadPlannerPage = () => {
  return {
    type: plannerTypes.PLANNER_UNLOADED,
    payload: {},
  };
};

export const addPlanner = (payload: object, afterSuccess: any = null, afterError: any = null, afterFinally: any = null) => {
  return async (dispatch: any) => {
    apiService.save("/planner", payload).then(
      (data) => {
        dispatch({
          type: plannerTypes.ADD_PLANNER,
          payload: data,
        });
        dispatch(alertActions.success("Data added successfully"));
        if(afterSuccess){
          afterSuccess();
        }
        if(afterFinally){
          afterFinally();
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if(afterError){
          afterError();
        }
        if(afterFinally){
          afterFinally();
        }
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editPlanner = (payload: payloadProps, afterSuccess: any = null, afterError: any = null, afterFinally: any = null) => {
  return async (dispatch: any) => {
    apiService.update(`/planner/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: plannerTypes.EDIT_PLANNER,
          payload: data,
        });
        dispatch(alertActions.success("Data Edited successfully"));
        if(afterSuccess){
          afterSuccess();
        }
        if(afterFinally){
          afterFinally();
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if(afterError){
          afterError();
        }
        if(afterFinally){
          afterFinally();
        }
      }
    );
  };
};

export const removePlanner = (id: number, afterSuccess: any = null, afterError: any = null, afterFinally: any = null) => {
  return async (dispatch: any) => {
    apiService._delete(`/planner/delete/${id}`).then(
      (data) => {
        dispatch({
          type: plannerTypes.DELETE_PLANNER,
        });
        dispatch(alertActions.success("Data Deleted successfully"));
        if(afterSuccess){
          afterSuccess();
        }
        if(afterFinally){
          afterFinally();
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        if(afterError){
          afterError();
        }
        if(afterFinally){
          afterFinally();
        }
      }
    );
  };
};

export const addOrUpdatePlannerData = (payload: payloadProps) => {
  return async (dispatch: any) => {
    apiService.update(`/planner/update/data/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: plannerTypes.EDIT_PLANNER,
          payload: data,
        });
        dispatch(alertActions.success("Planner Data Edited successfully"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

