// @flow

/* eslint no-process-env:0 */
import React from "react";

import Done from "../components/auth/Done";
import Dashboard from "../views/dashboard/Dashboard";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute, DefaultLayout } from "../components/layout/Layout";
import Login from "../components/auth/Login";
import Country from "../views/Country";
import Role from "../views/Role";
import Formbuilder from "../views/Formbuilder";
import FormBuilderApp from "../views/Formbuilder/FormBuilderApp";
import FormbuilderCreate from "../views/Formbuilder/create";
import FormbuilderFill from "../views/Formbuilder/fill";
import FormbuilderEdit from "../views/Formbuilder/edit";
import FormbuilderData from "../views/Formbuilder/Data/data";
import EditFormData from "../views/Formbuilder/Data/edit";
import Organization from "../views/Organization";
import User from "../views/User";
import Language from "../views/Language";
import Project from "../views/Project";
import Stage from "../views/Stage";
import ProjectReport from "../views/Project/ProjectReport";

import Form from "../views/Form";
import Step from "../views/Step";
import StepCreate from "../views/Step/create";
import StepEdit from "../views/Step/edit";
import StepDetail from "../views/StepSkeleton";
import StepDetailDuplicate from "../views/StepSkeleton/indexDuplicate";
import FormView from "../views/StepSkeleton/FormView";
import FormViewV2 from "../views/StepSkeleton/FormViewV2";
import TrainingModule from "../views/TrainingModule";
import TrainingModuleInfo from "../views/TrainingModule/info";
import ModuleLesson from "../views/ModuleLesson";
import ModuleLessonInfo from "../views/ModuleLesson/info";
import LessonData from "../views/LessonData";
import AwarenessModule from "../views/AwarenessModule";
import AwarenessModuleInfo from "../views/AwarenessModule/info";
import Tile from "../views/Tile";
import TileInfo from "../views/Tile/info";
import AwarenessNews from "../views/AwarenessNews";
import AwarenessDocument from "../views/AwarenessDocument";
import AwarenessVideo from "../views/AwarenessVideo";
import AwarenessTsc from "../views/AwarenessTsc";
import App from "../views/App";
import AppInfo from "../views/App/info";
import AboutUs from "../views/AboutUs";
import AppUser from "../views/Referral/AppUsers/index";
import AppPoint from "../views/Referral/AppPoints/index";
import Transaction from "../views/Referral/Transactions/index";
import Contact from "../views/Contact";
import Office from "../views/Office";
import AwarenessMessage from "../views/Message";
import SiteSafety from "../views/WorkSiteSafety";
import Category from "../views/Category";
import HousePart from "../views/SafeMyHouse/HousePart";
import HouseImage from "../views/SafeMyHouse/HouseImage";
import HouseImageQuality from "../views/MaterialsQuality/HouseImageQuality";
import StrengthenHouseCategory from "../views/StrengthenMyHouse/HouseCategory";
import Floor from "../views/BOQ/Floor";
import Roof from "../views/BOQ/Roof";
import MaterialCategory from "../views/BOQ/MaterialCategory";
import MaterialList from "../views/BOQ/MaterialList";
import Faq from "../views/Faq";
import UnitPrice from "../views/BOQ/UnitPrice";
import BoqDrawing from "../views/BOQ/BOQDrawing";
import BoqRoof from "../views/BOQ/BOQRoof";
import Report from "../views/BOQ/Report";
import Gallery from "../views/Gallery";
import SliderImage from "../views/SliderImage";
import Sponsor from "../views/Sponsor";
import Library from "../views/Library";
import LibraryInfo from "../views/Library/info";
import UserCreate from "../views/User/create";
import UserEdit from "../views/User/edit";
import CovidTrace from "../views/CovidTrace";
import FormCreate from "../views/Form/create";
import FormEdit from "../views/Form/edit";
import Planner from "../views/Planner";
import PlannerInfo from "../views/Planner/info";
import FileManager from "../views/FileManager";
import ViewFormData from "../views/Formbuilder/Data/detail";
import ProjectDashboard from "../views/dashboard/ProjectDashboard";
import StartTraining from "../views/StartTraining";
import LiveTraining from "../views/LiveTraining";
import TrainingStart from "../views/StartTraining/start";
import SocialAnalytics from "../views/SocialAnalytics";
import SocialAnalyticsInfo from "../views/SocialAnalytics/info";
import { Role as RoleConstant } from "../helper/role";
import { Module as ModuleConstant } from "../helper/modules";
import Module from "../views/Module";
import HouseCategory from "../views/MaterialsQuality/HouseCategory";

import OrganizationDashboard from "../views/dashboard/OrganizationDashboard";
import UserDashboard from "../views/dashboard/UserDashboard";
import DraftData from "../views/StepSkeleton/DraftList";
import Deactivated from "../views/Formbuilder/Data/Deactivated";
import { useSelector } from "react-redux";
import StrengthenHousePart from "../views/StrengthenMyHouse/HousePart";
import StrengthenHouseImage from "../views/StrengthenMyHouse/HouseImage";
import UserProfile from "../views/User/UserProfile";
import TrainingUserDetails from "../views/TrainingUserDetails";
import TrainingUserDetailsDataInfo from "../views/TrainingUserDetails/info";
import WebsiteFormbuilderFill from "../views/Website";
import Sharing from "../views/Sharing";
import SharingInfo from "../views/Sharing/info";
import PublicPortal from "../views/Sharing/share";
import Documentation from "../views/Documentation";
import Docs from "../views/Documentation/info";
import DocsAll from "../views/Documentation/all";
import StaticPage from "../views/StaticPage";
import StaticPageInfo from "../views/StaticPage/info";

import SeismicCoin from "../views/SeismicCoin";
import CreateCover from "../views/Project/CreateCover";
import CreateHeaderFooter from "../views/Project/CreateHeaderFooter";
import FormReportView from "../views/Formbuilder/Data/FormReportView";
import FormbuilderReport from "../views/Formbuilder/Data/FormbuilderReport";
import FormbuilderExcelReport from "../views/Formbuilder/Data/FormbuilderExcelReport";
import LiveTrainingInfo from "../views/LiveTraining/info";
import AppUserInfo from "../views/Referral/AppUsers/info";
import DatatableList from "../views/Formbuilder/Data/DatatableList";
import Support from "../views/support";
import GoogleDataStudio from "../views/google-data-studio";
import RheatFill from "../views/Website/rheat";
import OrganizationReport from "../views/Organization/OrganizationReport";
import FormbuilderMeta from "../views/FormbuilderMeta";
import FormbuilderMetaCreate from "../views/FormbuilderMeta/create";
import FormbuilderMetaEdit from "../views/FormbuilderMeta/edit";
import FormFunction from "../views/Formbuilder/Data/FormFunction";
import Finance from "../views/Finance";
import Tranche from "../views/Finance/Tranche";
import TrancheTransaction from "../views/Finance/Tranche/Transaction";
import CountryReport from "../views/Country/CountryReport";

import * as Sentry from "@sentry/react";
import Files from "../views/Files";
import AdminSettings from "../views/User/AdminSettings";

export const MainRoutes = () => {
  const authentication = useSelector((state: any) => state.authentication);

  // Create Custom Sentry Route component
  // const SentryRoute = Sentry.withSentryRouting(Route);

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/website/:slug" component={WebsiteFormbuilderFill} />
      <Route path="/rheat/:slug" component={RheatFill} />
      {authentication.user && authentication.user.role === "Admin" ? (
        <PrivateRoute exact path="/" component={Dashboard} />
      ) : authentication.user && authentication.user.role === "User" ? (
        <PrivateRoute exact path="/" component={UserDashboard} />
      ) : (
        <PrivateRoute exact path="/" component={OrganizationDashboard} />
      )}
      <PrivateRoute
        exact
        path="/formbuilder"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={Formbuilder}
      />
      <PrivateRoute
        exact
        path="/formbuilder/formbuilderapp/:slug"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormBuilderApp}
      />
      <PrivateRoute
        exact
        path="/formbuilder/create"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormbuilderCreate}
      />
      <PrivateRoute
        exact
        path="/formbuilder/fill/:slug"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormbuilderFill}
      />
      <PrivateRoute
        exact
        path="/formbuilder/:slug/edit"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormbuilderEdit}
      />
      <PrivateRoute
        exact
        path="/formbuilder/:slug/function"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormFunction}
      />
      <PrivateRoute
        exact
        path="/formbuilder/data/:slug"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormbuilderData}
      />
      <PrivateRoute
        exact
        path="/formbuilder/datatable/:slug"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={DatatableList}
      />
      <PrivateRoute
        exact
        path="/formbuilder/data/:slug/view/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={FormViewV2}
        // component={FormView}
      />
      <PrivateRoute
        exact
        path="/formbuilder/data/:slug/edit/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={EditFormData}
      />
      <PrivateRoute
        exact
        path="/formbuilder/data/:slug/detail/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={ViewFormData}
      />
      <PrivateRoute
        exact
        path="/formbuilder/draft/:slug"
        component={DraftData}
      />
      <PrivateRoute
        exact
        path="/formbuilder/deactivated/:slug"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={Deactivated}
      />
      <PrivateRoute
        exact
        path="/formbuilder/reportList/:slug"
        component={FormbuilderReport}
      />
      <PrivateRoute
        exact
        path="/formbuilder/gsheetList/:slug"
        component={FormbuilderExcelReport}
      />
      <PrivateRoute
        exact
        path="/formbuilder/report/:slug/:id"
        component={FormReportView}
      />
      <PrivateRoute
        exact
        path="/formbuilder/meta"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={FormbuilderMeta}
      />
      <PrivateRoute
        exact
        path="/formbuilder/meta/create"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormbuilderMetaCreate}
      />
      <PrivateRoute
        exact
        path="/formbuilder/meta/edit/:slug"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormbuilderMetaEdit}
      />
      <PrivateRoute
        exact
        path="/country"
        roles={[RoleConstant.Admin]}
        component={Country}
      />
      <PrivateRoute
        exact
        path="/country/:id"
        roles={[RoleConstant.Admin]}
        component={CountryReport}
      />
      <PrivateRoute
        exact
        path="/module"
        roles={[RoleConstant.Admin]}
        component={Module}
      />
      <PrivateRoute
        exact
        path="/role"
        roles={[RoleConstant.Admin, RoleConstant.OrganizationAdmin]}
        component={Role}
      />
      <PrivateRoute
        exact
        path="/organization"
        roles={[RoleConstant.Admin]}
        component={Organization}
      />
      <PrivateRoute
        exact
        path="/organization/organizationReport/:id"
        roles={[RoleConstant.Admin]}
        component={OrganizationReport}
      />
      <PrivateRoute exact path="/userProfile" component={UserProfile} />
      <PrivateRoute
        exact
        path="/admin-settings"
        roles={[RoleConstant.Admin]}
        component={AdminSettings}
      />
      <PrivateRoute
        exact
        path="/user"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={User}
      />
      .
      <PrivateRoute
        exact
        path="/user/create"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={UserCreate}
      />
      <PrivateRoute
        exact
        path="/user/edit/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={UserEdit}
      />
      <PrivateRoute
        exact
        path="/project"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={Project}
      />
      <PrivateRoute
        exact
        path="/project/projectReport/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={ProjectReport}
      />
      <PrivateRoute
        exact
        path="/createCover/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={CreateCover}
      />
      <PrivateRoute
        exact
        path="/createHeaderFooter/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={CreateHeaderFooter}
      />
      <PrivateRoute
        exact
        path="/stage"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Formbuilder}
        component={Stage}
      />
      <PrivateRoute
        exact
        path="/project/dashboard/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Formbuilder}
        component={ProjectDashboard}
      />
      <PrivateRoute
        exact
        path="/step/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Formbuilder}
        component={Step}
      />
      <PrivateRoute
        exact
        path="/step/:id/create"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Formbuilder}
        component={StepCreate}
      />
      <PrivateRoute
        exact
        path="/step/:id/edit/:slug"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Formbuilder}
        component={StepEdit}
      />
      <PrivateRoute
        exact
        path="/step/form/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={StepDetail}
      />
      <PrivateRoute
        exact
        path="/step/form/duplicate/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={StepDetailDuplicate}
      />
      <PrivateRoute
        exact
        path="/form"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={Form}
      />
      <PrivateRoute
        exact
        path="/form/create"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormCreate}
      />
      <PrivateRoute
        exact
        path="/form/edit/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={FormEdit}
      />
      <PrivateRoute
        exact
        path="/language"
        roles={[RoleConstant.Admin]}
        component={Language}
      />
      <PrivateRoute
        exact
        path="/training"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={TrainingModule}
      />
      <PrivateRoute
        exact
        path="/training/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={TrainingModuleInfo}
      />
      <PrivateRoute
        exact
        path="/module_lesson"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={ModuleLesson}
      />
      <PrivateRoute
        exact
        path="/module_lesson/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={ModuleLessonInfo}
      />
      <PrivateRoute
        exact
        path="/lesson_data"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={LessonData}
      />
      <PrivateRoute
        exact
        path="/start_training"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={StartTraining}
      />
      <PrivateRoute
        exact
        path="/training_start"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={TrainingStart}
      />
      <PrivateRoute
        exact
        path="/live_training"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={LiveTraining}
      />
      <PrivateRoute
        exact
        path="/live_training/:slug"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={LiveTrainingInfo}
      />
      <PrivateRoute
        exact
        path="/user_details"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={TrainingUserDetails}
      />
      <PrivateRoute
        exact
        path="/user_details/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={TrainingUserDetailsDataInfo}
      />
      <PrivateRoute
        exact
        path="/awareness_module"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AwarenessModule}
      />
      <PrivateRoute
        exact
        path="/awareness_module/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AwarenessModuleInfo}
      />
      <PrivateRoute
        exact
        path="/tile"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Tile}
      />
      <PrivateRoute
        exact
        path="/tile/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={TileInfo}
      />
      <PrivateRoute
        exact
        path="/awareness_news"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AwarenessNews}
      />
      <PrivateRoute
        exact
        path="/awareness_document"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AwarenessDocument}
      />
      <PrivateRoute
        exact
        path="/awareness_video"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AwarenessVideo}
      />
      <PrivateRoute
        exact
        path="/awareness_tsc"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AwarenessTsc}
      />
      <PrivateRoute
        exact
        path="/office"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Office}
      />
      <PrivateRoute
        exact
        path="/message"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AwarenessMessage}
      />
      <PrivateRoute
        exact
        path="/site_safety"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={SiteSafety}
      />
      <PrivateRoute
        exact
        path="/category"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Category}
      />
      <PrivateRoute
        exact
        path="/gallery"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Gallery}
      />
      <PrivateRoute
        exact
        path="/slider_image"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={SliderImage}
      />
      <PrivateRoute
        exact
        path="/sponsor"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Sponsor}
      />
      <PrivateRoute
        exact
        path="/contact"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Contact}
      />
      <PrivateRoute
        exact
        path="/faq"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Faq}
      />
      <PrivateRoute
        exact
        path="/aboutus"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AboutUs}
      />
      <PrivateRoute
        exact
        path="/app_user"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AppUser}
      />
      <PrivateRoute
        exact
        path="/app_user/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Training}
        component={AppUserInfo}
      />
      <PrivateRoute
        exact
        path="/app_point"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={AppPoint}
      />
      <PrivateRoute
        exact
        path="/house_part"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={HousePart}
      />
      <PrivateRoute
        exact
        path="/house_category"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={HouseCategory}
      />
      <PrivateRoute
        exact
        path="/house_image"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={HouseImage}
      />
      <PrivateRoute
        exact
        path="/house_image_quality"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={HouseImageQuality}
      />
      <PrivateRoute
        exact
        path="/strengthen_house_category"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={StrengthenHouseCategory}
      />
      <PrivateRoute
        exact
        path="/strengthen_house_part"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={StrengthenHousePart}
      />
      <PrivateRoute
        exact
        path="/strengthen_house_image"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={StrengthenHouseImage}
      />
      <PrivateRoute
        exact
        path="/material_category"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={MaterialCategory}
      />
      <PrivateRoute
        exact
        path="/material_list"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={MaterialList}
      />
      <PrivateRoute
        exact
        path="/floor"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Floor}
      />
      <PrivateRoute
        exact
        path="/roof"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Roof}
      />
      <PrivateRoute
        exact
        path="/unit_price"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={UnitPrice}
      />
      <PrivateRoute
        exact
        path="/boq_drawing"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={BoqDrawing}
      />
      <PrivateRoute
        exact
        path="/boq_roof"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={BoqRoof}
      />
      <PrivateRoute
        exact
        path="/library"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Library}
      />
      <PrivateRoute
        exact
        path="/library/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={LibraryInfo}
      />
      <PrivateRoute
        exact
        path="/boq_report"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Report}
      />
      <PrivateRoute
        exact
        path="/transaction"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Awareness}
        component={Transaction}
      />
      <PrivateRoute
        exact
        path="/covid_trace"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Contact_trace}
        component={CovidTrace}
      />
      <PrivateRoute
        exact
        path="/planner"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Planner}
        component={Planner}
      />
      <PrivateRoute
        exact
        path="/planner/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        module={ModuleConstant.Planner}
        component={PlannerInfo}
      />
      {/* <PrivateRoute
        exact
        path="/file_manager"
        roles={[RoleConstant.Admin]}
        component={FileManager}
      /> */}
      <PrivateRoute
        exact
        path="/app"
        roles={[RoleConstant.Admin, RoleConstant.OrganizationAdmin]}
        component={App}
      />
      <PrivateRoute
        exact
        path="/app/:id"
        roles={[RoleConstant.Admin, RoleConstant.OrganizationAdmin]}
        component={AppInfo}
      />
      <PrivateRoute
        exact
        path="/social_analytics/"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={SocialAnalytics}
      />
      <PrivateRoute
        exact
        path="/social_analytics/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={SocialAnalyticsInfo}
      />
      <PrivateRoute
        exact
        path="/sharing/"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={Sharing}
      />
      <PrivateRoute
        exact
        path="/sharing/:id"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={SharingInfo}
      />
      <PrivateRoute
        exact
        path="/portal/:public_id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={PublicPortal}
      />
      {/* <PrivateRoute
        exact
        path="/documentation/"
        roles={[RoleConstant.Admin]}
        component={Documentation}
      /> */}
      {/* <PrivateRoute
        exact
        path="/guide/"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={DocsAll}
      /> */}
      {/* <PrivateRoute
        exact
        path="/guide/:slug"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={Docs}
      /> */}
      <PrivateRoute exact path="/static_page/" component={StaticPage} />
      <PrivateRoute
        exact
        path="/page/:id"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={StaticPageInfo}
      />
      <PrivateRoute
        exact
        path="/seismic/"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={SeismicCoin}
      />
      <PrivateRoute
        exact
        path="/finance/"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={Finance}
      />
      <PrivateRoute
        exact
        path="/finance/:id/tranche"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={Tranche}
      />
      <PrivateRoute
        exact
        path="/finance/:finance_id/tranche/:tranche_id/transaction"
        roles={[RoleConstant.OrganizationAdmin, RoleConstant.Admin]}
        component={TrancheTransaction}
      />
      <PrivateRoute
        exact
        path="/files/"
        roles={[
          RoleConstant.OrganizationAdmin,
          RoleConstant.Admin,
          RoleConstant.User,
        ]}
        component={Files}
      />
      <Route exact path="/support/" component={Support} />
      <Route exact path="/google-data-studio/" component={GoogleDataStudio} />
      <DefaultLayout path="*" component={Done} />
    </Switch>
  );
};

export default MainRoutes;
