import React, { ReactElement } from "react";
import {
  Card,
  Icon,
  Grid,
  Header,
  Label,
  SemanticCOLORS,
  SemanticICONS,
  Segment,
} from "semantic-ui-react";
import Question from "./Question";

interface Props {
  data?: any;
  sheet?: any;
  cardHeader?: string;
  handleAnswerChange: Function;
  iconName?: SemanticICONS;
  color?: SemanticCOLORS;
  headerColor?: string;
  //   number: number;
}

export default function Test(props: Props): ReactElement {
  if (!props.sheet || !props.data) {
    return (
      <div>
        <br />
        <h3>
          <Icon name="spinner" /> Loading
        </h3>
      </div>
    );
  }

  if (props.sheet && props.sheet[props.data.type]) {
    return (
      <div className="finished-congratulations-pre">
        <br />
        <h3
          style={{
            display: "inline-block",
            padding: "8px 15px",
            borderRadius: "20px",
          }}
        >
          <Icon name="check" /> Great Job - {props.data.test_type} Completed
        </h3>

        <b style={{ padding: "0 5px" }}>Now you can proceed ahead.</b>
        <br />
      </div>
    );
  }

  return (
    <Segment.Group>
      <Segment style={{ fontWeight: "bold", letterSpacing: 3 }}>
        {props.data.test_type}
      </Segment>
      <Segment.Group>
        {props.data.relevant_data.map((question: any) => {
          return (
            <Segment key={question.id}>
              <Label
                as="a"
                style={{
                  backgroundColor: "var(--color-blue)",
                  borderColor: "var(--color-blue)",
                  color: "white",
                }}
                ribbon="right"
              >
                {question.question_type === "single"
                  ? "Single Choice Question"
                  : "Multiple Choice Question"}
              </Label>
              <Question
                question={question}
                handleAnswerChange={props.handleAnswerChange}
                type={
                  props.data.type === "pretest_done"
                    ? "pretest"
                    : props.data.type === "post_test_done"
                    ? "posttest"
                    : "duringtest"
                }
              />
            </Segment>
          );
        })}
      </Segment.Group>
    </Segment.Group>
  );
}
