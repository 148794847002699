import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadBoqDrawingPage,
  unloadBoqDrawingPage,
  addBoqDrawing,
  editBoqDrawing,
  removeBoqDrawing,
} from "../../../redux/action/boqDrawingAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../../redux/action/alertAction";
interface RootState {
  boq_drawings: {
    boq_drawings: {
      data: {
        id: number;
        floor_id: number;
        library_id: number;
      }[];
      last_page: number;
    };
    floors: {
      id: number;
      name: string;
      name_lang: string;
    }[];
    libraries: {
      id: number;
      library_code: string;
    }[];
    material_lists: {
      id: number;
      name: string;
      name_lang: string;
    }[];
  };
}

type floorOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type libraryOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function BoqDrawing() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      floor_id: 0,
      library_id: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/boq_drawing");
  const boq_drawings = useSelector((state: RootState) => state.boq_drawings);
  const [total, setTotal] = useState(1);
  const [floorOptions, setFloorOptions] = useState<floorOptionType>([]);
  const [libraryOptions, setLibraryOptions] = useState<libraryOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadBoqDrawingPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadBoqDrawingPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (boq_drawings.boq_drawings !== undefined) {
      setTotal(boq_drawings.boq_drawings.last_page);
      var final_stage_floor = boq_drawings.floors.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name + " / " + data.name_lang,
        };
      });

      setFloorOptions(final_stage_floor);

      var final_stage_library = boq_drawings.libraries.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.library_code,
        };
      });

      setLibraryOptions(final_stage_library);
    }
  }, [boq_drawings]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/boq_drawing?page=" + pageInfo.activePage.toString());
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        floor_id: 0,
        library_id: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let boq_drawing = boq_drawings.boq_drawings.data.find((v) => v.id === id);
    // material list
    let dynamic_data = {};
    if (boq_drawings && boq_drawings.material_lists) {
      // eslint-disable-next-line
      boq_drawings.material_lists.map((material) => {
        dynamic_data[material.name.split(" ").join("_")] = boq_drawing![
          material.name.split(" ").join("_")
        ]
          ? boq_drawing![material.name.split(" ").join("_")]
          : "";
      });
    }

    setData({
      newData: {
        id: boq_drawing!.id,
        floor_id: boq_drawing!.floor_id,
        library_id: boq_drawing!.library_id,
        ...dynamic_data,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addBoqDrawing(fdata.newData, () => {
      dispatch(loadBoqDrawingPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

    else dispatch(editBoqDrawing(fdata.newData, () => {
      dispatch(loadBoqDrawingPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {

    setLoading(true);

    dispatch(removeBoqDrawing(deleteId, () => {
      dispatch(loadBoqDrawingPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));
  };

  function RenderList(datas: any) {
    if (datas.data.boq_drawings === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.boq_drawings.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.boq_drawings.data.map((data: any) => {
      let floor = datas.data.floors.find((a: any) => a.id === data.floor_id);
      let library = datas.data.libraries.find(
        (a: any) => a.id === data.library_id
      );
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{library ? library.library_code : ""}</Table.Cell>
          <Table.Cell>{floor ? floor.name : ""}</Table.Cell>
          {boq_drawings && boq_drawings.material_lists
            ? boq_drawings.material_lists.map((material, index) => {
                return (
                  <Table.Cell key={index}>
                    {data[material.name.split(" ").join("_")]}
                  </Table.Cell>
                );
              })
            : ""}

          <Table.Cell>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              con
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>BOQ</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>BOQ Drawing</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create BOQ Drawing
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>BOQ Drawing</Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Library Code</Table.HeaderCell>
            <Table.HeaderCell>Floor</Table.HeaderCell>
            {boq_drawings && boq_drawings.material_lists
              ? boq_drawings.material_lists.map((material, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {material.name}/{material.name_lang}
                    </Table.HeaderCell>
                  );
                })
              : ""}

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {boq_drawings && <RenderList data={boq_drawings} />}
        </Table.Body>
      </Table>

      {boq_drawings.boq_drawings === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="tiny"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} BOQ Drawing
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select Library"
              required
              options={libraryOptions}
              label="Library"
              value={fdata.newData.library_id}
              name="library_id"
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <Form.Select
              fluid
              placeholder="Select Floor"
              required
              options={floorOptions}
              label="Floor"
              value={fdata.newData.floor_id}
              name="floor_id"
              onChange={handleChange}
            />
          </Form.Field>
          {boq_drawings && boq_drawings.material_lists
            ? boq_drawings.material_lists.map((material, index) => {
                return (
                  <Form.Input
                    key={index}
                    fluid
                    required
                    type="number"
                    name={material.name.split(" ").join("_")}
                    label={"Enter quantity for " + material.name}
                    value={fdata.newData[material.name.split(" ").join("_")]}
                    onChange={handleChange}
                    placeholder={"Enter quantity for " + material.name}
                  />
                );
              })
            : ""}
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
