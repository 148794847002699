import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Segment,
  Header,
  Form,
  Breadcrumb,
  Message,
  Loader,
  Popup as PopOver,
  Icon,
  Modal,
} from "semantic-ui-react";
import { apiService } from "../../services/api.service";

import { useSelector, useDispatch } from "react-redux";
import {
  loadCovidTracePage,
  unloadCovidTracePage,
} from "../../redux/action/covidTraceAction";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Map, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import Leaflet from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
Leaflet.Icon.Default.imagePath = "../../node_modules/leaflet";
// @ts-ignore {doesNotExist}
delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

interface RootState {
  covid_traces: {
    users: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  };
}

type userOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function Report() {
  const [fdata, setData] = useState({
    newData: {
      user_id: 0,
      from: "",
    },
  });
  const [creating, setCreating] = useState(false);

  const [apiUrl] = useState("/covid_trace/user");
  const covid_traces = useSelector((state: RootState) => state.covid_traces);
  const [userOptions, setUserOptions] = useState<userOptionType>([]);
  const [reportData, setReportData] = useState<any>([]);
  const [curUser, setCurUser] = useState<string>("");
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadCovidTracePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadCovidTracePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (covid_traces !== undefined && covid_traces.users) {
      var final_stage_library = covid_traces.users.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: (data.first_name + " " + data.last_name).trim(),
        };
      });

      setUserOptions(final_stage_library);
    }
  }, [covid_traces]);

  const clearForm = () => {
    setData({
      newData: {
        user_id: 0,
        from: "",
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.user_id === 0) {
      return false;
    }

    if (userOptions && userOptions !== undefined) {
      setCurUser(
        userOptions.find((x: any) => x.value === fdata.newData.user_id)!.text
      );
    } else {
      setCurUser("User ID: " + fdata.newData.user_id);
    }

    let report_data = await apiService.getAll(
      `/covid_trace/info?user_id=${fdata.newData.user_id}&from=${fdata.newData.from}`
    );
    clearForm();
    setOpenInfo(false);
    setReportData(report_data);
  };

  function RenderList(datas: any) {
    if (datas.data === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (!datas.data || datas.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.map((item: any, index: number) => {
      return (
        <Table.Row key={item.id}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>{item.phone ? item.phone : "-"}</Table.Cell>
          <Table.Cell>{item.email ? item.email : "-"}</Table.Cell>
          <Table.Cell>
            <PopOver
              trigger={
                <a
                  style={{ color: "orange" }}
                  href={"https://maps.google.com?q=" + item.gps}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.gps}
                </a>
              }
              content="Open in Google Map"
              position="right center"
            />
          </Table.Cell>
          <Table.Cell>{item.purpose}</Table.Cell>
          <Table.Cell>{item.created_at}</Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Covid Trace</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Filter</Breadcrumb.Section>
      </Breadcrumb>
    </div>,

    <div key="2" style={{ marginTop: "1rem" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Report Filter Form
        <Icon name="magnify" className="iconSize" />
      </Button>
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="tiny"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">Report Filter Form</Modal.Header>
        <Modal.Content className="modalContent">
          <Form>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select User"
                required
                options={userOptions}
                label="Select User"
                value={fdata.newData.user_id}
                name="user_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                max={new Date().toISOString().split("T")[0]}
                type="date"
                name="from"
                value={fdata.newData.from}
                onChange={handleChange}
                label="Date From"
                placeholder="Select Date From"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
      ,
    </div>,

    <Segment
      style={
        reportData && reportData.length > 0
          ? { overflowX: "scroll" }
          : { display: "none" }
      }
      key="3"
    >
      <Header>Covid Trace For {curUser}</Header>
      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Gps</Table.HeaderCell>
            <Table.HeaderCell>Purpose</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {reportData && <RenderList data={reportData} />}
        </Table.Body>
      </Table>
      <br />
      <b>Total Contact: </b> {reportData ? reportData.length : 0}
      {covid_traces === undefined || loading ? <Loader active inverted size="big" /> : ""}
    </Segment>,

    <div
      className="app-card-org"
      style={!reportData || reportData.length <= 0 ? {} : { display: "none" }}
      key="4"
    >
      <b>No Data to Show.</b>
    </div>,

    <div className="app-card-org" key="5">
      <div style={{ height: "400px" }}>
        <Map
          center={
            reportData.length > 0 && reportData[0].gps
              ? [
                  reportData[0].gps.split(",")[0],
                  reportData[0].gps.split(",")[1],
                ]
              : [0, 0]
          }
          zoom={3}
          worldCopyJump={true}
          minZoom={2}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {reportData.map((d: any, i: any) => {
              // console.log(i);
              let latlng = d.gps.split(",");
              let next_latlng = null;
              if (i + 1 < reportData.length) {
                next_latlng = reportData[i + 1].gps.split(",");
              }
              return (
                <div key={"marker_" + d.id}>
                  <Marker position={[latlng[0], latlng[1]]}>
                    <Popup>
                      <h5>{d.name}</h5>
                      <p style={{ margin: "0" }}>
                        <a
                          title="Call"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={"tel:" + d.phone}
                        >
                          {d.phone}
                        </a>
                      </p>
                      <p style={{ margin: "0" }}>
                        <a
                          title="Send Email"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={"mailto:" + d.email}
                        >
                          {d.email}
                        </a>
                      </p>
                      <small
                        style={{ display: d.purpose ? "inline-block" : "none" }}
                      >
                        Purpose: {d.purpose}
                      </small>
                    </Popup>
                  </Marker>

                  {next_latlng && (
                    <Polyline
                      key={"polyline_" + d.id}
                      positions={[
                        [latlng[0], latlng[1]],
                        [next_latlng[0], next_latlng[1]],
                      ]}
                      color={"orangered"}
                      opacity={0.6}
                      weight={2.5}
                      dashArray={"20, 5"}
                      dashOffset={"20"}
                    />
                  )}
                </div>
              );
            })}
          </MarkerClusterGroup>
        </Map>
      </div>
    </div>,
  ];
}
