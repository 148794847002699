import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const houseImageTypes = {
  HOUSE_IMAGE_LOADED: "HOUSE_IMAGE_LOADED",
  HOUSE_IMAGE_UNLOADED: "HOUSE_IMAGE_UNLOADED",
  ADD_HOUSE_IMAGE: "ADD_HOUSE_IMAGE",
  EDIT_HOUSE_IMAGE: "EDIT_HOUSE_IMAGE",
  DELETE_HOUSE_IMAGE: "DELETE_HOUSE_IMAGE",
};

export const loadHouseImagePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: houseImageTypes.HOUSE_IMAGE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadHouseImagePage = () => {
  return {
    type: houseImageTypes.HOUSE_IMAGE_UNLOADED,
    payload: {},
  };
};

export const addHouseImage = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/house_image", payload).then(
      (data) => {
        dispatch({
          type: houseImageTypes.ADD_HOUSE_IMAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editHouseImage = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/house_image/update/${payload.id}`,payload).then(
      (data) => {
        dispatch({
          type: houseImageTypes.EDIT_HOUSE_IMAGE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeHouseImage = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/house_image/delete/${id}`).then(
      (data) => {
        dispatch({
          type: houseImageTypes.DELETE_HOUSE_IMAGE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
