import { awarenessTscTypes } from "../action/awarenessTscAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case awarenessTscTypes.AWARENESS_TSC_LOADED:

            return action.payload;

        case awarenessTscTypes.AWARENESS_TSC_UNLOADED:
            return initialState;
        case awarenessTscTypes.ADD_AWARENESS_TSC:
           
             return { ...state};
        case awarenessTscTypes.EDIT_AWARENESS_TSC:

            return state;
        case awarenessTscTypes.DELETE_AWARENESS_TSC:
           
            return state;

        default:
            return state;
    }
};
