import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  Select,
  Table,
  TextArea,
} from "semantic-ui-react";
import { getLanguageName, languages } from "../../helper/languages";
import { alertActions } from "../../redux/action/alertAction";

interface Props {
  languageData: {
    [key: string]: any;
  };
  setLanguageData: Function;
  setLanguageDataModalOpen: Function;
  component?: any;
}

export default function FormBuilderTranslate(props: Props) {
  const [languageData, setLanguageData]: any = useState(
    props.languageData ?? { en: {} }
  );

  const [selectedLanguageCode, setSelectedLanguageCode] = useState("en");

  const [newLanguageCode, setNewLanguageCode]: any = useState("");

  const [newLanguageKey, setNewLanguageKey]: any = useState("");
  const [newLanguageValue, setNewLanguageValue]: any = useState("");

  const [pasteModalOpen, setPasteModalOpen] = useState(false);
  const [pasteContent, setPasteContent] = useState("");

  const [refreshView, setRefreshView] = useState(new Date().getTime());

  useEffect(() => {
    setLanguageData(props.languageData);
  }, [props.languageData]);

  const deleteLanguageData = (k: string) => {
    if (window.confirm("Are you sure you want to delete this key?")) {
      let lang = Object.assign({}, languageData);
      delete lang[selectedLanguageCode][k];
      setLanguageData(lang);
    }
  };

  const handleLanguageData = (
    k: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let lang = Object.assign({}, languageData);
    lang[selectedLanguageCode][k] = event.target.value;

    setLanguageData(lang);
  };

  const addLanguageData = () => {
    if (
      newLanguageKey === "" ||
      newLanguageValue === "" ||
      !newLanguageKey ||
      !newLanguageValue
    )
      return;

    let lang = Object.assign({}, languageData);

    lang[selectedLanguageCode][newLanguageKey] = newLanguageValue;

    setLanguageData(lang);
    setNewLanguageKey("");
    setNewLanguageValue("");

    if (document.getElementById("newLanguageInputKey")) {
      document.getElementById("newLanguageInputKey")!.focus();
    }
  };

  const addNewLanguageCode = () => {
    if (newLanguageCode === "" || !newLanguageCode) return;

    let lang = Object.assign({}, languageData);
    let code = newLanguageCode.trim();

    if (lang[code]) {
      alert(`\"${code}\" already exists. Failed to add.`);
      return;
    }

    lang[code] =
      Object.assign({}, lang["en"]) ??
      Object.assign({}, Object.values(lang)[0] ?? {});

    setLanguageData(lang);

    setNewLanguageCode("");

    setSelectedLanguageCode(code);

    alertActions.success(`\"${code}\" added successfully.`);

    if (document.getElementById("newLanguageInputKey")) {
      document.getElementById("newLanguageInputKey")!.focus();
    }
  };

  const pasteLanguageDate = () => {
    if (pasteContent === "" || !pasteContent) {
      return alert("Paste content is empty.");
    }

    try {
      let data = JSON.parse(pasteContent);
      setRefreshView(new Date().getTime());
      setLanguageData(data);

      setPasteContent("");
      setPasteModalOpen(false);
    } catch (error) {
      alert("Invalid JSON");
    }
  };

  const saveLanguageData = () => {
    props.setLanguageData(languageData);
    props.setLanguageDataModalOpen(false);
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={() => {
          props.setLanguageDataModalOpen(false);
        }}
        style={{ position: "relative" }}
        size="large"
        closeOnDimmerClick={false}
      >
        <Modal.Header
          style={{
            padding: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button className="submitButton" onClick={() => saveLanguageData()}>
            Save
          </Button>

          <div>
            <Button
              positive
              onClick={() => {
                const data = JSON.stringify(languageData);
                console.log(data);
                navigator.clipboard.writeText(data);
              }}
            >
              <Icon name="copy outline" />
              Copy
            </Button>

            <Button
              positive
              onClick={() => {
                setPasteModalOpen(true);
              }}
            >
              <Icon name="clipboard check" />
              Paste
            </Button>
          </div>
        </Modal.Header>

        <hr />

        <Modal.Content
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Form.Field style={{ width: "300px" }}>
            <label>Select Language</label>
            <br />
            <Select
              fluid
              options={Object.keys(languageData).map((lang) => ({
                key: lang,
                value: lang,
                text: `${lang} - ${getLanguageName(lang, "-")}`,
              }))}
              value={selectedLanguageCode}
              onChange={(e, { value }) => setSelectedLanguageCode(value as any)}
            />
          </Form.Field>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              flexWrap: "wrap",
            }}
          >
            <Form.Field style={{ width: "300px" }}>
              <label>Add Language Code *</label>
              <br />

              <Form.Select
                fluid
                value={newLanguageCode}
                onChange={(e, data) => setNewLanguageCode(data.value)}
                required
                placeholder="e.g. en, fr, es, ne, etc."
                search
                options={Object.keys(languages).map((code) => ({
                  key: code,
                  value: code,
                  text: `${code} - ${languages[code]}`,
                }))}
              ></Form.Select>

              {/* <Input
                fluid
                value={newLanguageCode}
                onChange={(e) => setNewLanguageCode(e.target.value)}
                required
                placeholder="e.g. en, fr, es, np, etc."
              /> */}
            </Form.Field>

            <Button
              primary
              style={{ marginLeft: "10px" }}
              onClick={() => addNewLanguageCode()}
            >
              Add
            </Button>
          </div>
        </Modal.Content>

        <hr />

        <Modal.Content scrolling>
          <Grid columns="one">
            <Grid.Row>
              <Grid.Column>
                <Table unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Key</Table.HeaderCell>
                      <Table.HeaderCell>Value</Table.HeaderCell>
                      <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <Form.Input
                          fluid
                          clearable
                          placeholder="Key"
                          value={newLanguageKey}
                          name="languageKey"
                          onChange={(e) => setNewLanguageKey(e.target.value)}
                          id="newLanguageInputKey"
                        />
                      </Table.Cell>

                      <Table.Cell>
                        <Form.Input
                          fluid
                          clearable
                          placeholder="Value"
                          value={newLanguageValue}
                          name="languageValue"
                          onChange={(e) => setNewLanguageValue(e.target.value)}
                        />
                      </Table.Cell>

                      <Table.Cell width="2">
                        <Button primary onClick={() => addLanguageData()}>
                          Add
                        </Button>
                      </Table.Cell>
                    </Table.Row>

                    {Object.keys(languageData[selectedLanguageCode] ?? []).map(
                      (k, i) => {
                        return (
                          <Table.Row
                            key={
                              k +
                              "_" +
                              i +
                              "_" +
                              selectedLanguageCode +
                              "_" +
                              refreshView
                            }
                          >
                            <Table.Cell width={8}>
                              <div>
                                <i
                                  style={{
                                    marginRight: "3px",
                                  }}
                                >
                                  {i + 1}){" "}
                                </i>
                                <strong>{k}</strong>
                              </div>
                            </Table.Cell>

                            <Table.Cell width={8}>
                              <Input
                                fluid
                                key={k + "_" + i}
                                defaultValue={
                                  languageData[selectedLanguageCode][k]
                                }
                                onChange={(e) => handleLanguageData(k, e)}
                              />
                            </Table.Cell>

                            <Table.Cell width="2">
                              <Button
                                icon
                                circular
                                className="deleteButton"
                                onClick={() => deleteLanguageData(k)}
                              >
                                <Icon name="trash" />
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        );
                      }
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>

      <Modal
        open={pasteModalOpen}
        onClose={() => {
          setPasteModalOpen(false);
        }}
        size="small"
      >
        <Modal.Header>Paste Copied Language Content:</Modal.Header>
        <Modal.Content
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <TextArea
            style={{
              width: "100%",
              border: "2px solid #323232",
              borderRadius: "10px",
              padding: "8px",
              marginBottom: "25px",
            }}
            placeholder="Paste Copied Language Content Here..."
            value={pasteContent}
            rows={20}
            onChange={(e, data) => setPasteContent(String(data.value ?? ""))}
          ></TextArea>

          <Button onClick={() => pasteLanguageDate()}>Paste & Replace</Button>
        </Modal.Content>
      </Modal>
    </div>
  );
}
