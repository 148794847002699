import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const awarenessVideoTypes = {
  AWARENESS_VIDEO_LOADED: "AWARENESS_VIDEO_LOADED",
  AWARENESS_VIDEO_UNLOADED: "AWARENESS_VIDEO_UNLOADED",
  ADD_AWARENESS_VIDEO: "ADD_AWARENESS_VIDEO",
  EDIT_AWARENESS_VIDEO: "EDIT_AWARENESS_VIDEO",
  DELETE_AWARENESS_VIDEO: "DELETE_AWARENESS_VIDEO",
};

export const loadAwarenessVideoPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: awarenessVideoTypes.AWARENESS_VIDEO_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAwarenessVideoPage = () => {
  return {
    type: awarenessVideoTypes.AWARENESS_VIDEO_UNLOADED,
    payload: {},
  };
};

export const addAwarenessVideo = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/awareness_video", {...payload,extra}).then(
      (data) => {
        dispatch({
          type: awarenessVideoTypes.ADD_AWARENESS_VIDEO,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAwarenessVideo = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/awareness_video/update/${payload.id}`, {...payload,extra}).then(
      (data) => {
        dispatch({
          type: awarenessVideoTypes.EDIT_AWARENESS_VIDEO,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAwarenessVideo = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/awareness_video/delete/${id}`).then(
      (data) => {
        dispatch({
          type: awarenessVideoTypes.DELETE_AWARENESS_VIDEO,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
