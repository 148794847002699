import { appModuleTypes } from "../action/appAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case appModuleTypes.APP_MODULE_LOADED:

            return action.payload;

        case appModuleTypes.APP_MODULE_UNLOADED:
            return initialState;
        case appModuleTypes.ADD_APP_MODULE:
           
             return { ...state};
        case appModuleTypes.EDIT_APP_MODULE:

            return state;
        case appModuleTypes.DELETE_APP_MODULE:
           
            return state;

        default:
            return state;
    }
};
