import React, { useState, useEffect } from "react";
import { Grid, Item, Menu } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import MenuComponent from "./MenuComponent";
import { navigation } from "../navigation";
import { sideMenuAction } from "../../redux/action/sideMenuAction";
import { Module_name } from "../../helper/modules";

interface Props {
  dark?: boolean;
  children: any;
}

interface RootState {
  authentication: {
    user: {
      role: string;
      modules: string[];
    };
  };
  sideMenu: {
    smallMenu: boolean;
    module: string;
  };
}

type MenuChildType = readonly {
  name: string;
  linkTo?: string;
  IconName?: string;
  type?: string;
  onClick?: Function;
  list?:
    | {
        name: string;
        linkTo: string;
        IconName?: string;
      }[]
    | MenuChildType;
}[];

export default function SideMenu(props: Props) {
  const [activeItem, setActiveItem] = useState("dashboard");
  const [projectList, setprojectList] = useState<Array<any>>([]);
  const [modules, setModules] = useState<any>([]);

  const dispatch = useDispatch();

  const handleItemClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    { name }: any
  ) => {
    e.stopPropagation();
    if (activeItem !== name) {
      setActiveItem(name);
    }
    dispatch(sideMenuAction.openMenu());
    ScrollSideItemToView(e.currentTarget.getAttribute("id"));
  };

  const sideMenu = useSelector((state: RootState) => state.sideMenu);

  const authentication = useSelector(
    (state: RootState) => state.authentication
  );
  const dashboard = useSelector((state: any) => state.dashboard);
  const { total_project } = dashboard;
  const { user } = authentication;
  const { role } = user;
  let { smallMenu, module } = sideMenu;

  useEffect(() => {
    if (user !== undefined) {
      setModules(user.modules);
    }
  }, [user]);

  useEffect(() => {
    if (total_project !== undefined) {
      const project_arr = Object.keys(total_project).map((item, key) => {
        return {
          name: total_project[item]["name"],
          linkTo: `project/dashboard/${total_project[item]["id"]}`,
          IconName: "terminal",
        };
      });

      setprojectList(project_arr);
    }
  }, [total_project]);

  const ModuleHandler = (module: string) => {
    dispatch(sideMenuAction.ModuleChange(module));
  };

  const GetModule = ({ data }: any) => {
    return data.map((item: string, index: number) => (
      <Menu.Item
        key={index}
        onClick={() => ModuleHandler(item)}
        active={module === item}
      >
        {smallMenu ? (
          <span style={{ fontWeight: "bolder" }}>
            {Module_name[item].slice(0, 1)}
          </span>
        ) : (
          Module_name[item]
        )}
      </Menu.Item>
    ));
  };

  function ScrollSideItemToView(id: any) {
    if (id) {
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView();
      }, 50);
    }
  }

  const sideMenuHandler = () => {
    dispatch(sideMenuAction.closeMenu());
  };

  const GetMenu = () => {
    let menuOptions: MenuChildType = [];
    if (module === "main") {
      switch (role) {
        case "Admin":
          menuOptions = navigation.admin;
          break;

        case "Organization Admin":
          menuOptions = navigation.orgadmin;
          break;

        case "User":
          menuOptions = navigation.user;
          break;

        default:
          break;
      }
    } else {
      let arr = navigation[module] || [];
      if (module === "formbuilder") {
        arr = [...arr, ...projectList];
      } else if (module === "training") {
        // FOR USERS WITH ONLY TRAINING MODULE ASSIGNED
        // ASSUMED THEY ARE JUST HERE TO TRAIN
        if (user.modules.length === 1 && user.modules[0] === "training") {
          arr = [...navigation["only_training"]];
        } else {
          arr = [...arr, ...navigation["only_training"]];
        }
      }
      menuOptions = arr;
    }

    return (
      <Menu
        style={{ overflow: "auto" }}
        fixed="left"
        borderless
        className={(smallMenu ? "small-side" : "") + " side"}
        vertical
      >
        {menuOptions.map((item, index) => {
          if (item.onClick) {
            return (
              <MenuComponent
                key={index}
                smallMenu={smallMenu}
                activeItem={activeItem}
                uniqueId={index}
                handleItemClick={(e) => {
                  if (item.onClick) {
                    item.onClick();
                  } else {
                    console.log("Missing onClick function.");
                  }
                }}
                name={item.name}
                IconName={item.IconName ? item.IconName : ""}
              />
            );
          }
          return (
            <MenuComponent
              key={index}
              smallMenu={smallMenu}
              activeItem={activeItem}
              handleItemClick={handleItemClick}
              name={item.name}
              IconName={item.IconName ? item.IconName : ""}
              linkTo={item.linkTo}
              list={
                item.type && item.type === "group" && item.name && item.list
                  ? item.list
                  : []
              }
            />
          );
        })}
        <hr className="moduleDivider" />
        {module && !smallMenu && (
          <Menu.Item>
            <b>MODULES</b>
          </Menu.Item>
        )}
        {modules && <GetModule data={modules} />}
      </Menu>
    );
  };

  return (
    <div className="parent">
      <div>
        <GetMenu />
      </div>

      <div
        className={(smallMenu ? "small-content1 " : "") + "content1"}
        onClick={sideMenuHandler}
      >
        {props.children}
        <p className="color-blue copyright">
          {" "}
          &copy; 2023 Build Change. All rights reserved
        </p>
      </div>
    </div>
  );
}
