import moment from "moment";
import React from "react";
import { Header, Table, Label } from "semantic-ui-react";

interface Props {
  data: RenderProps;
}

interface RenderProps {
  log: LogProps[];
  log_count: number;
}

interface LogProps {
  extra: { user_name: string };
  message: string;
  created_at: Date;
}
export default function LogDetail({ data }: Props) {
  const RenderTable = ({ log }: any) => {
    if (log.length === 0) {
      return (
        <Table.Row>
          <Table.Cell textAlign="center">
            <b>No Log Found</b>
          </Table.Cell>
        </Table.Row>
      );
    }
    return log.map((item: LogProps, index: number) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>
            {" "}
            <Label color="grey" horizontal>
              {item.extra.user_name}
            </Label>
          </Table.Cell>
          <Table.Cell>{item.message}</Table.Cell>
          <Table.Cell title={moment(item.created_at).toLocaleString()}>{moment(item.created_at).fromNow()}</Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <Header>Activity Log</Header>
      <Table basic="very" unstackable selectable striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User Name</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{data.log && <RenderTable log={data.log} />}</Table.Body>
      </Table>

      {
        data.log && data.log.length > 0 && data.log.length < data.log_count ? (
          <small><i>+ {data.log_count - data.log.length} More Log</i></small>
        ) : ""
      }
    </div>
  );
}
