import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
//import { fetchFormbuilder } from '../../../redux/action/formbuilderAction';

import { fetchFormdata, unloadFormdataPage, removeFormdata } from '../../redux/action/formDataAction';
import { Table, Segment, Breadcrumb, Message, Button, Header, Icon, Loader, Grid, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ModalComponent from '../../components/Modal'
import { FormattedMessage } from 'react-intl';

interface Props{
    match: { params: { slug: any; };};
    history:any
}

export default function DraftData(props:Props) {
    const [open, setOpen] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<string>('')
    const [activePage, setActivePage] = useState<number>(1);
    const [apiUrl, setApiUrl] = useState<string>(`/formBuilder/draft/${props.match.params.slug}`);
    const [total, setTotal] = useState<number>(1);


    const formdata = useSelector((state:any) => state.formdata);

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(fetchFormdata(apiUrl))
        return () => {
            dispatch(unloadFormdataPage())
        }
        // eslint-disable-next-line
    }, [apiUrl])

    useEffect(() => {
        if (formdata.data !== undefined) {
            //console.log(formbuilders.last_page)
            setTotal(formdata.data.last_page)

        }

    }, [formdata]);



    const onChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, pageInfo: any) => {
        setActivePage(pageInfo.activePage);
        //console.log(pageInfo)
        setApiUrl(`/formBuilder/draft/${props.match.params.slug}?page=` + pageInfo.activePage.toString());
    };

    const deleteData = (slug:string) => {
        setOpen(true)
        setDeleteId(slug)
    };

    const confirmDelete = () => {
        // alert(delteId)

        dispatch(removeFormdata(`/formData/${props.match.params.slug}/delete/${deleteId}`));
        dispatch(fetchFormdata(apiUrl))
        setOpen(false)
    }

    function RenderHeader(datas: any) {
        //console.log(datas.data)
        if (datas.data.field === undefined) {

            return (
                <Table.HeaderCell> Loading.....</Table.HeaderCell>
            )
        }
        // console.log(datas.data)
        return datas.data.field.map((data: React.ReactNode, index: number) => {

            return (
                <Table.HeaderCell key={index + 1}> {data}</Table.HeaderCell>

            )
        })
    }

    const goBack= ()=>{
        props.history.goBack();
     }
    function RenderList({ data }:any) {

        if (data.data === undefined) {

            return (
                <Table.Row key={1}>
                    <Table.Cell> <Message>Loading ....</Message></Table.Cell>
                </Table.Row>
            )
        }

        if ((data.data.data).length === 0) {
            return (
                <Table.Row key='1'>
                    <Table.Cell> <Message>No data Found</Message></Table.Cell>
                </Table.Row>
            )
        }
        // console.log(datas.data)
        return data.data.data.map((item:any, index: any) => {


            return (
                <Table.Row key={item._id.$oid}>
                    <Table.Cell >{item._id.$oid}</Table.Cell>
                    <Table.Cell>
                        <Button size="mini" primary as={Link}  to={`/formbuilder/data/${props.match.params.slug}/detail/${item._id.$oid}`} icon>
                            <Icon name="eye" />
                        </Button>
                        <Button size="mini" as={Link} to={`/formbuilder/data/${props.match.params.slug}/edit/${item._id.$oid}`} icon>
                            <Icon name="pencil" />
                        </Button>


                        <Button color="red" size="mini" onClick={() => deleteData(item._id.$oid)} icon>
                            <Icon name="delete" />
                        </Button>
                    </Table.Cell>
                    <Table.Cell>{item.state}</Table.Cell>

                    {
                        data.key ?
                            data.key
                                .filter((itm: string) => itm !== '_id')
                                .map((members: string, index1: string | number | undefined) => {

                                    return (
                                        <Table.Cell key={index1}>{(members in item) ? (item[members]['label']??item[members]) : ''}</Table.Cell>
                                    )

                                })
                            : <Table.Cell>No data Found</Table.Cell>
                    }

                </Table.Row>

            )
        })
    }



    return [
        <Segment key={1}>
            <Breadcrumb>
               <Breadcrumb.Section link as={Link} to="/">
                    <FormattedMessage
                        id="Home"
                        defaultMessage="Home"
                    />
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>

                <Breadcrumb.Divider icon='right arrow' />
                <Breadcrumb.Section active>Draft Data</Breadcrumb.Section>
            </Breadcrumb>
            <ModalComponent open={open} setOpen={setOpen} confirmDelete={confirmDelete} />
        </Segment>,

        <Segment key={2} style={{overflowX: "scroll"}}>


            <Header>Draft Data List</Header>
            <div style={{ overflowY: 'scroll' }}>


                <Table basic="very" unstackable striped  role="grid">
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell >ID</Table.HeaderCell>
                            <Table.HeaderCell> Actions</Table.HeaderCell>
                            <Table.HeaderCell> State</Table.HeaderCell>

                            {formdata && (<RenderHeader data={formdata} />)}

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {formdata.data && (<RenderList data={formdata} />)}
                    </Table.Body>


                </Table>
            </div>
            {formdata.data === undefined ? (<Loader active inverted size='big' />) : ''}

            <Grid  >

                <Grid.Column >
                    <Pagination
                        floated='right'

                        activePage={activePage}
                        onPageChange={onChange}
                        totalPages={total}
                        ellipsisItem={null}
                    />
                </Grid.Column>
            </Grid>
         

        </Segment>,
    ]
}
