import React from "react";
import { Grid } from "semantic-ui-react";

export default function SeismicCoin() {
  // @ts-ignore
  let sesmic_token =
    // @ts-ignore
    (JSON.parse(localStorage.getItem("user") ?? null)
      ? // @ts-ignore
        JSON.parse(localStorage.getItem("user")).sesmic_token
      : null) ?? null;

  return (
    // <Grid container padded>
    // <Grid.Column>
    <div className="seismic-container">
      <iframe
        title="SeismicCoin"
        src={"https://seismic-app.bctap.net/connect?token=" + sesmic_token}
        name="myFrame"
        frameBorder="0"
        className="myFrame"
      ></iframe>
    </div>
    // </Grid.Column>
    // </Grid>
  );
}
