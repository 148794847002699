import { apiService } from "../../services";
import { alertActions } from "./alertAction";
import { history } from "../../helper";

export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",
};

export const authActions = {
  login,
  logout,
};

function login(
  email: string,
  password: string,
  from: string = "/",
  onSuccess: Function = () => {},
  onError: Function = () => {}
) {
  return (dispatch: any) => {
    dispatch(request({ email }));

    apiService.login(email, password).then(
      (user: any) => {
        if (user.message === "error") {
          //console.log(user,'oooooooo')
          dispatch(failure(user.data));
          dispatch(alertActions.error(user.data));
          onError(user);
        } else {
          dispatch(success(user));
          dispatch(alertActions.success("Login Successful"));
          history.push(from);
          onSuccess(user);
        }
      },
      (error: any) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        onError(error);
      }
    );
  };

  function request(user: any) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user: any) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch: any) => {
    dispatch(logoutss());
    apiService.logout();

    dispatch(alertActions.success("Logout Successful"));
    // return { type: userConstants.LOGOUT };
  };

  function logoutss() {
    return { type: userConstants.LOGOUT };
  }
}
