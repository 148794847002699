import { staticPageTypes } from "../action/staticPageAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case staticPageTypes.STATIC_PAGE_LOADED:
      return action.payload;

    case staticPageTypes.STATIC_PAGE_UNLOADED:
      return initialState;
    case staticPageTypes.ADD_STATIC_PAGE:
      return { ...state };
    case staticPageTypes.EDIT_STATIC_PAGE:
      return state;
    case staticPageTypes.DELETE_STATIC_PAGE:
      return state;

    default:
      return state;
  }
};
