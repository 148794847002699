import NestedComponent from "../../../../node_modules/formiojs/components/_classes/nested/NestedComponent";
import { hasInvalidComponent } from "../../../../node_modules/formiojs/utils/utils";
import FormComponent from "../../../../node_modules/formiojs/components/form/Form";

export default class PanelComponent extends NestedComponent {
  static schema(...extend) {
    return NestedComponent.schema(
      {
        label: "Collapsible",
        type: "collapsible",
        key: "collapsible",
        title: "Collapsible",
        theme: "default",
        breadcrumb: "default",
        components: [],
        clearOnHide: false,
        input: false,
        tableView: false,
        persistent: false,
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: "Collapsible",
      icon: "list-alt",
      group: "layout",
      documentation: "/userguide/#panels",
      weight: 30,
      schema: PanelComponent.schema(),
    };
  }

  get defaultSchema() {
    return PanelComponent.schema();
  }

  get templateName() {
    return "panel";
  }

  constructor(...args) {
    super(...args);
    this.noField = true;
    this.on("componentError", () => {
      // change collapsed value only when the panel is collapsed to avoid additional redrawing that prevents validation messages
      if (hasInvalidComponent(this) && this.collapsed) {
        this.collapsed = false;
      }
    });
    this.component.title = this.label;
    // console.log(this);
  }

  getComponent(path, fn, originalPath) {
    if (this.root?.parent instanceof FormComponent) {
      path = path.replace(this._parentPath, "");
    }
    return super.getComponent(path, fn, originalPath);
  }
}
