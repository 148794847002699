import { awarenessVideoTypes } from "../action/awarenessVideoAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case awarenessVideoTypes.AWARENESS_VIDEO_LOADED:

            return action.payload;

        case awarenessVideoTypes.AWARENESS_VIDEO_UNLOADED:
            return initialState;
        case awarenessVideoTypes.ADD_AWARENESS_VIDEO:
           
             return { ...state};
        case awarenessVideoTypes.EDIT_AWARENESS_VIDEO:

            return state;
        case awarenessVideoTypes.DELETE_AWARENESS_VIDEO:
           
            return state;

        default:
            return state;
    }
};
