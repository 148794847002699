import React, { Component } from "react";
import { Form, Header } from "semantic-ui-react";
import "./auth.css";
import logo from "../../assets/images/BuildChange_Logo.png";
import background from "../../assets/images/background.png";

interface Props {
  header: string;
  children: any;
}
interface State {}

class Layout extends Component<Props, State> {
  render() {
    return (
      <div className="auth-main">
        <div className="lines"></div>
        <div>
          <img src={logo} alt="Build Change Login" className="auth-logo" />
        </div>

        <div>
          <img
            src={background}
            alt="Build Change Login"
            className="auth-buildchange"
          />
        </div>
        <div className="auth-content">
          <Header as="h2" color="black" textAlign="center">
            Log In
          </Header>
          <p>Welcome back. Please Log in to your account.</p>
          <div className="auth-card">
            {/* <img src={logo} alt="Logo" className="auth-logo" /> */}
            <Form.Group size="large" className="auth-form" autoComplete="off">
              {this.props.children}
            </Form.Group>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
