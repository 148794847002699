import React, { ReactElement } from "react";
import { Menu, Accordion, Segment, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import TextIcon from "../extension/TextIcon";

import { FormattedMessage } from "react-intl";

type MenuChildType = {
  name: string;
  linkTo?: string;
  IconName?: string;
  type?: string;
  list?:
    | {
        name: string;
        linkTo: string;
        IconName?: string;
      }[]
    | MenuChildType;
}[];

interface Props {
  handleItemClick: (
    e: React.MouseEvent<Element, MouseEvent>,
    { name }: any
  ) => void;
  smallMenu: boolean;
  name: string;
  dark?: boolean;
  linkTo?: string;
  IconName: string;
  activeItem: string;
  uniqueId?: any;
  list?:
    | {
        name: string;
        linkTo: string;
        IconName?: string;
      }[]
    | MenuChildType
    | any;
}

function matchLinkTo(list: Array<any>, match: string) {
  let flat_list = list.map((i) => {
    if (i.type && i.type === "group") {
      let temp = [i, ...i.list];
      return temp;
    }
    return i;
  });
  flat_list = [].concat.apply([], flat_list);

  return flat_list.find((i) => {
    if (i.linkTo === match || i.name === match) {
      return true;
    }
    return false;
  });
}

function MenuComponent(props: Props): ReactElement {
  return (
    <React.Fragment>
      {props.list && props.list.length > 0 ? (
        // TREE MENU ITEM
        <Accordion
          id={props.name + "_acc"}
          style={{
            marginLeft: "10px",
            paddingTop: "0",
            paddingBottom: "0",
            cursor: "pointer",
            zIndex: "9999",
            fontSize: "12px",
          }}
          className={matchLinkTo(props.list, props.activeItem) ? "active" : ""}
          onClick={(e: any) => props.handleItemClick(e, { name: props.name })}
        >
          <Accordion.Title
            active={props.activeItem === props.linkTo}
            index={props.linkTo}
            style={{ pointerEvents: "none" }}
          >
            <TextIcon hideText={props.smallMenu} name={props.IconName}>
              <FormattedMessage
                id={props.name}
                defaultMessage={props.name}
                description={props.name}
              />
            </TextIcon>
            &nbsp;&nbsp;&nbsp;
            {props.activeItem === props.name ? (
              <Icon style={{ pointerEvents: "none" }} name="triangle down" />
            ) : matchLinkTo(props.list, props.activeItem) ? (
              <Icon style={{ pointerEvents: "none" }} name="triangle down" />
            ) : (
              <Icon style={{ pointerEvents: "none" }} name="triangle right" />
            )}
          </Accordion.Title>

          <Accordion.Content
            active={
              props.activeItem === props.name
                ? true
                : matchLinkTo(props.list, props.activeItem)
                ? true
                : false
            }
          >
            {props.list.map((l: any, index: number) => {
              if (l.type && l.type === "group") {
                return (
                  <MenuComponent
                    key={l.linkTo ? l.linkTo : l.name}
                    smallMenu={props.smallMenu}
                    activeItem={props.activeItem}
                    handleItemClick={props.handleItemClick}
                    name={l.name}
                    IconName={l.IconName ? l.IconName : ""}
                    linkTo={l.linkTo}
                    list={l.name && l.list ? l.list : []}
                  />
                );
              }

              return (
                <Menu.Item
                  as={Link}
                  to={l.linkTo ? `/${l.linkTo}` : ""}
                  id={l.name + "_itm"}
                  name={l.linkTo || l.uniqueId}
                  key={l.linkTo || l.uniqueId}
                  style={{ padding: "5px", overflow: "hidden" }}
                  active={l.linkTo ? props.activeItem === l.linkTo : false}
                  onClick={props.handleItemClick}
                  title={l.name}
                  // className={"fade-in"}
                >
                  <TextIcon hideText={props.smallMenu} name={l.IconName}>
                    <FormattedMessage
                      id={l.name}
                      defaultMessage={l.name}
                      description={l.name}
                    />
                  </TextIcon>
                </Menu.Item>
              );
            })}
          </Accordion.Content>
        </Accordion>
      ) : (
        //   SINGLE MENU ITEM
        <Accordion>
          <Accordion.Content>
            <Menu.Item
              as={Link}
              to={props.linkTo ? `/${props.linkTo}` : ""}
              id={props.name + "_sitm"}
              name={props.linkTo || props.uniqueId}
              style={{ padding: "10px 11px" }}
              active={props.linkTo ? props.activeItem === props.linkTo : false}
              onClick={props.handleItemClick}
            >
              <TextIcon hideText={props.smallMenu} name={props.IconName}>
                <FormattedMessage
                  id={props.name}
                  defaultMessage={props.name}
                  description={props.name}
                />
              </TextIcon>
            </Menu.Item>
          </Accordion.Content>
        </Accordion>
      )}
    </React.Fragment>
  );
}

export default MenuComponent;
