import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  Pagination,
  Grid,
  Header,
  Icon,
  Message,
  Loader,
  Input,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadStepPage,
  unloadStepPage,
  removeStep,
} from "../../redux/action/stepAction";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { normalizeStepTypeName } from "../../helper";

interface Props {
  match: { params: { id: string } };
}

export default function Step(props: Props) {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/step/" + props.match.params.id);
  const steps = useSelector((state: any) => state.steps);
  const [total, setTotal] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [stage, setStage] = useState(0);
  const [stageData, setStageData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadStepPage(apiUrl));

    return () => {
      dispatch(unloadStepPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (steps.step !== undefined) {
      setTotal(steps.step.last_page);
      setStage(steps.stage);
      setStageData(steps.stage_data);
    }
  }, [steps]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      `/step/${props.match.params.id}?page=` +
        pageInfo.activePage.toString() +
        "&q=" +
        search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl(`/step/${props.match.params.id}?q=` + search);
    }
  };

  function RenderList(datas: any) {
    if (datas.data.step === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading .... </Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.step.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    // console.log(datas.data)
    return datas.data.step.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>
            <span title={data.type}>{normalizeStepTypeName(data.type)}</span>
            {data.type === "sync_parent_form_identifier_to_fulcrum_choices" ? (
              <small style={{ display: "block" }}>
                {data.fulcrum_choice_list_id}
              </small>
            ) : (
              ""
            )}

            {data.type === "training_module" ? (
              <small style={{ display: "block" }}>
                {data.training_module_name ?? "Training Module Not Found"}
              </small>
            ) : (
              ""
            )}
          </Table.Cell>
          <Table.Cell>{stage}</Table.Cell>
          <Table.Cell>{data.order}</Table.Cell>
          <Table.Cell>{data.pre_status ?? "-"}</Table.Cell>
          <Table.Cell style={{ maxWidth: "220px" }}>
            {(data.status ?? "-").split(",").join(", ")}{" "}
            {data.filter_by_status ? (
              <Icon
                color="green"
                name="filter"
                title={
                  "Filtering by this Status" +
                  (data.filtering_status
                    ? (" - \n" + data.filtering_status).split(",").join("\n")
                    : "")
                }
              />
            ) : (
              ""
            )}
            <br />
            <small style={{ fontStyle: "italic", fontWeight: "700" }}>
              Field Name: {data.status_field}
            </small>
          </Table.Cell>

          <Table.Cell>
            <Button
              as={Link}
              to={`/step/${props.match.params.id}/edit/${data.id}`}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    dispatch(removeStep(deleteId));
    dispatch(loadStepPage(apiUrl));
    setOpen(false);
  };

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section link as={Link} to="/stage">
          Stage
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Steps: ({stage})</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
      />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        as={Link}
        to={`/step/${props.match.params.id}/create`}
        labelPosition="left"
      >
        Create Step
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Steps List: ({stage})
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small"
          action={{ icon: "search" }}
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Stage</Table.HeaderCell>
            <Table.HeaderCell>Order</Table.HeaderCell>
            <Table.HeaderCell>Pre Status</Table.HeaderCell>
            <Table.HeaderCell style={{ maxWidth: "220px" }}>
              Status
            </Table.HeaderCell>

            <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{steps && <RenderList data={steps} />}</Table.Body>
      </Table>
      {steps.step === undefined ? <Loader active inverted size="big" /> : ""}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
  ];
}
