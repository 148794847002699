import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  Divider,
  Header,
  Card,
  Input,
  Checkbox,
  Grid,
  Image,
  Segment,
  Radio,
} from "semantic-ui-react";
import { MentionsInput, Mention } from "react-mentions";
import _, { startCase } from "lodash";

interface PropType {
  initialStats: StatType[];
  onChange?: (stats: StatType[]) => any;
  autosuggest?: string[];
}

export interface StatType {
  label: string;
  formula: string;
  is_rhiab: boolean;
  is_rhiab_type: "stat" | "total" | "percentage" | "secondary_stat";
}

export default function StatCalculations(props: PropType) {
  const [stats, setStats] = useState<StatType[]>(props.initialStats ?? []);

  useEffect(() => {
    emitChanges();
  }, [stats]);

  useEffect(() => {
    if (stats !== props.initialStats) {
      setStats(props.initialStats);
    }
  }, [props.initialStats]);

  const emitChanges = () => {
    if (props.onChange) {
      props.onChange(stats);
    }
  };

  const addNewStat = () => {
    setStats([
      ...stats,
      {
        label: `New Label ${stats.length + 1}`,
        formula: "{{go}} + {{no_go}}",
        is_rhiab: true,
        is_rhiab_type: "stat",
      },
    ]);
  };

  const deleteStatByIndex = (index: number) => {
    let statsClone = [...stats];
    statsClone.splice(index, 1);
    setStats(statsClone);
  };

  const updateValue = (index: number, key: string, value: any) => {
    let statsClone = [...stats].map((val, i) => {
      if (index === i) {
        val[key] = value;
      }

      return val;
    });
    setStats(statsClone);
  };

  return (
    <div>
      <br />
      <Divider horizontal>
        <Header as="h4">
          <Icon name="chart bar outline" />
          Stats Calculation
        </Header>
      </Divider>

      <Card fluid>
        {stats && stats.length > 0
          ? stats.map((stat: any, index) => (
              <Card.Content
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "stretch",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "stretch",
                    flex: 1,
                  }}
                >
                  <Input
                    type="text"
                    label="Label"
                    value={stat.label}
                    style={{ flex: "1", marginRight: "50%" }}
                    onChange={(e, data) => {
                      updateValue(index, "label", data.value);
                    }}
                  ></Input>

                  <MentionsInput
                    value={stat.formula}
                    placeholder={
                      'Calculation Formula... Start with " {{ " for auto-suggestion.'
                    }
                    style={{
                      marginTop: "5px",
                      boxSizing: "border-box",
                      flex: "2",
                      alignSelf: "stretch",

                      control: {
                        backgroundColor: "#fff",
                        fontSize: 14,
                        fontWeight: "normal",
                        height: "100%",
                      },

                      "&multiLine": {
                        display: "inline-block",
                        width: "100%",
                        control: {
                          fontFamily: "monospace",
                        },
                        highlighter: {
                          padding: "5px 10px",
                          height: "100%",
                          minWidth: "100%",
                          minHeight: "100%",
                          lineHeight: "2em",
                        },
                        input: {
                          padding: "5px 10px",
                          height: "100%",
                          minWidth: "100%",
                          minHeight: "100%",
                          lineHeight: "2em",
                        },
                      },

                      "&singleLine": {
                        display: "inline-block",
                        width: 180,

                        control: {
                          fontFamily: "monospace",
                        },
                        highlighter: {
                          padding: "5px 10px",
                          height: "100%",
                          // lineHeight: "2.5em",
                        },
                        input: {
                          padding: "5px 10px",
                          height: "100%",
                          // lineHeight: "2em",
                        },
                      },

                      suggestions: {
                        list: {
                          backgroundColor: "white",
                          border: "1px solid rgba(0,0,0,0.15)",
                          fontSize: 14,
                          marginTop: "-14px",
                        },
                        item: {
                          cursor: "pointer",
                          padding: "5px 15px",
                          borderBottom: "1px solid rgba(0,0,0,0.15)",
                          boxShadow: "1px 2px 5px #ddd",
                          maxWidth: "300px",
                          whiteSpace: "pre",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          "&focused": {
                            backgroundColor: "#c5ebf0",
                          },
                        },
                      },
                    }}
                    singleLine={false}
                    spellCheck="false"
                    autoCapitalize="false"
                    autoComplete="false"
                    autoCorrect="false"
                    onChange={(
                      event,
                      newValue,
                      newPlainTextValue,
                      mentions
                    ) => {
                      updateValue(index, "formula", newValue);
                    }}
                    allowSuggestionsAboveCursor={true}
                  >
                    <Mention
                      trigger="{{"
                      markup="{{__id__}}"
                      data={(search: string, callback: Function) => {
                        let localKnownVariables = Array.from(
                          new Set([
                            ...stats
                              .map((s) => s.formula)
                              .join("\n")
                              .replaceAll(/[\n\\+\/\*\-\(\)]/g, "")
                              .split(/[{{}}]+/g)
                              .filter((s) => s.trim().length > 0),
                            ...(props.autosuggest ?? []),
                          ])
                        );

                        callback(
                          localKnownVariables
                            .filter((s) => s.indexOf(search) >= 0)
                            .map((s) => ({
                              id: s,
                              display: s,
                            }))
                        );
                      }}
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay,
                        index,
                        focused
                      ) => <div key={suggestion.id}>{highlightedDisplay}</div>}
                      displayTransform={(id: string, display: string) => {
                        return `{{${id}}}`;
                        // return _.truncate(display, { length: 50 });
                      }}
                      appendSpaceOnAdd={true}
                      style={{
                        color: "#9ec74e",
                        // lineHeight: "2em",
                        zIndex: "99999",
                        opacity: "0.8",
                      }}
                    />
                  </MentionsInput>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "start",
                    flex: 1,
                    marginLeft: "15px",
                  }}
                >
                  <Checkbox
                    label="Show in RHIAB"
                    checked={stat.is_rhiab}
                    onChange={(e, data) => {
                      updateValue(index, "is_rhiab", data.checked);
                    }}
                  ></Checkbox>

                  <div
                    style={{
                      flex: "1",
                      display: stat.is_rhiab === true ? "flex" : "none",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    {["stat", "total", "percentage", "secondary_stat"].map(
                      (type) => {
                        return (
                          <Radio
                            key={type}
                            label={`As ${startCase(type)}`}
                            name={"is_rhiab_type_" + index}
                            value={type}
                            checked={stat.is_rhiab_type === type}
                            onChange={(e, data) => {
                              updateValue(index, "is_rhiab_type", data.value);
                            }}
                          />
                        );
                      }
                    )}
                  </div>

                  <Button
                    type="button"
                    color="red"
                    onClick={() => deleteStatByIndex(index)}
                    icon="trash alternate outline"
                    style={{ marginTop: "3px" }}
                    size="tiny"
                  />
                </div>
              </Card.Content>
            ))
          : ""}

        <Card.Content>
          <Button type="button" onClick={addNewStat}>
            <Icon name="plus" />
            Add Stat
          </Button>
        </Card.Content>
      </Card>
    </div>
  );
}
