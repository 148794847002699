import { allTypes } from "../action/simpleAction";

export default (state = {}, action: any) => {
  switch (action.type) {
    case allTypes.SIMPLE_FETCH:
      return action.payload;

    case allTypes.SIMPLE_POST:
      return { ...state };
    
    case allTypes.SIMPLE_DELETE:
        return { ...state };

    case allTypes.SIMPLE_UNLOAD:
      return {};

    default:
      return state;
  }
};
