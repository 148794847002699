import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Segment,
} from "semantic-ui-react";
import logo from "../assets/images/BuildChange_Logo.png";
import dataShare from "../assets/images/data-share.png";

export default function GoogleDataStudio() {
  return (
    <div style={{ minHeight: "100vh", padding: "25px", position: "relative" }}>
      <Header
        as="h1"
        content="BCTAP API"
        style={{
          fontWeight: "bold",
          margin: 0,
          fontSize: "2.5rem",
        }}
      />
      <Header
        as="h1"
        content="+"
        style={{
          fontWeight: "bold",
          margin: 0,
          fontSize: "2rem",
          left: "6px",
          top: "54px",
          position: "absolute",
          animation: "10s linear 0s infinite normal none running spin",
        }}
      />
      <Header
        as="h1"
        content="Google Data Studio"
        style={{
          fontWeight: "bold",
          margin: 0,
          fontSize: "2.5rem",
        }}
      />
      <Header
        as="h3"
        content="Now you can generate up-to-date reports faster."
        style={{
          fontWeight: "normal",
          margin: "10px 0",
        }}
      />
      <Button as={Link} to="/" primary size="tiny">
        Generate some APIs
        <Icon name="arrow right" />
      </Button>

      <hr />

      <Segment style={{ padding: "2em 0em" }} vertical>
        <Grid stackable verticalAlign="middle">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Grid.Column style={{ width: "65%" }}>
              <Header as="h3" style={{ fontSize: "2em", fontWeight: "200" }}>
                Generate Google Data Studio report and dashboard easily.
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                With Google Data Studio you can generate informative reports and
                dashboards using real-time data from spreadsheets, databases and
                many other connectors.
                <br />
                <i>
                  <b>BCTAP Report</b>
                </i>
                , is such connector with which you can call any BCTAP APIs to
                get <b>records, forms, organization</b> info and use it to
                create custom charts and reports.
              </p>
              <Header as="h3" style={{ fontSize: "2em", fontWeight: "200" }}>
                How does it work?
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                It's simple —
                <br />
                <ul
                  style={{
                    listStylePosition: "inside",
                    padding: "0 0 0 10px",
                    listStyleType: "circle",
                  }}
                >
                  <li>First, in BCTAP enable Data Share for any module.</li>
                  <li>Create a new Google Data Studio project.</li>
                  <li>
                    Connect to{" "}
                    <i>
                      <b>BCTAP Report</b>
                    </i>{" "}
                    connector / data source.
                  </li>
                  <li>Paste the API generated by BCTAP.</li>
                  <li>
                    Start using data source output in charts, tables and stats.
                  </li>
                </ul>
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={4}>
              <Image
                bordered
                rounded
                size="large"
                style={{
                  width: "458px",
                  height: "300px",
                  boxShadow: "0 2px 15px 2px #323232",
                }}
                src={dataShare}
              />
            </Grid.Column>
          </div>
        </Grid>
      </Segment>

      <Segment style={{ padding: "2em 0em" }} vertical>
        <Header as="h3" style={{ fontSize: "2em", fontWeight: "200" }}>
          Terms of Use
        </Header>
        <p style={{ fontSize: "1.33em" }}>......</p>
      </Segment>

      <Segment style={{ padding: "2em 0em" }} vertical>
        <Header as="h3" style={{ fontSize: "2em", fontWeight: "200" }}>
          Privacy Policy
        </Header>
        <p style={{ fontSize: "1.33em" }}>......</p>
      </Segment>

      <div style={{ marginTop: "10px" }}>
        <Label
          as={Link}
          to={"/support"}
          tag
          style={{ textDecoration: "none" }}
          color="teal"
        >
          <Icon name="chat" />
          Get Support and Feedback
        </Label>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: "0",
          right: "0",
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          color: "white",
          padding: "10px",
          textAlign: "center",
          background: "#111",
          borderRadius: "25px 0 0 0",
        }}
      >
        <img
          src={logo}
          alt="Build Change Logo"
          style={{ width: "150px", marginBottom: "5px" }}
        />
        <Label
          color="black"
          as={Link}
          to={`/`}
          style={{ textDecoration: "none" }}
        >
          BCTAP
        </Label>
      </div>
    </div>
  );
}
