import { roofTypes } from "../action/roofAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case roofTypes.ROOF_LOADED:
      return action.payload;

    case roofTypes.ROOF_UNLOADED:
      return initialState;
    case roofTypes.ADD_ROOF:
      return { ...state };
    case roofTypes.EDIT_ROOF:
      return state;
    case roofTypes.DELETE_ROOF:
      return state;

    default:
      return state;
  }
};
