import { alertActions } from './alertAction';
import { apiService } from '../../services';
export const roleTypes = {
   
    ROLE_PAGE_LOADED: "ROLE_PAGE_LOADED",
    ROLE_PAGE_UNLOADED: "ROLE_PAGE_UNLOADED",
    ADD_ROLE:"ADD_ROLE",
    EDIT_ROLE:"EDIT_ROLE",
    DELETE_ROLE:"DELETE_ROLE",


}

export const loadRolePage =  (url:string, onFinally: Function) => {
    
    return async (dispatch:any) =>{

        apiService.getAll(url)
        .then(
            data => { 
               
                dispatch({
                    type: roleTypes.ROLE_PAGE_LOADED,
                    payload: data
                })
                onFinally();
               
            },
            error => {
               
                dispatch(alertActions.error(error.toString()));
                onFinally();
            }
        );
        

    };
}



export const unloadRolePage = () => {
    return {
        type: roleTypes.ROLE_PAGE_UNLOADED,
        payload: {}

    };
}

export const addRole = (payload:object, onSuccess: Function, onError: Function) => {
    

    return async (dispatch:any) =>{

        apiService.save('/role',payload)
        .then(
            data => { 
               
                dispatch({
                    type: roleTypes.ADD_ROLE,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data added successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };

   
}
interface payloadProps {
    id: number,
   // [all:any]:any
}
export const editRole = (payload:payloadProps, onSuccess: Function, onError: Function) => {

    return async (dispatch:any) =>{

        apiService.update(`/role/update/${payload.id}`,payload)
        .then(
            data => { 
               
                dispatch({
                    type: roleTypes.EDIT_ROLE,
                    payload: data
                })
                onSuccess();
                // dispatch(alertActions.success('Data Edited successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
}



  export const removeRole= (id:number, onSuccess: Function, onError: Function) =>  {
    
   
    return async (dispatch:any) =>{

        apiService._delete(`/role/delete/${id}`)
        .then(
            data => { 
               
                dispatch({
                    type: roleTypes.DELETE_ROLE,
                   
                })
                onSuccess();
                // dispatch(alertActions.success('Data Deleted successfully'))
               
            },
            error => {
                onError();
                dispatch(alertActions.error(error.toString()));
            }
        );
        

    };
    
  }

  

