import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import { loadTrainingUserDetailsPage } from "../../redux/action/trainingUserDetailsAction";
import { Link } from "react-router-dom";

import { FormattedMessage } from "react-intl";

interface RootState {
  organization_users: {
    organization_users: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
    }[];
  };
}

export default function TrainingUserDetails() {
  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/user_details");
  const [search, setSearch] = useState<string>("");
  const organization_users = useSelector(
    (state: RootState) => state.organization_users
  );
  const [total] = useState(1);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadTrainingUserDetailsPage(apiUrl, () => { 
      setLoading(false);
    }));

    // return () => {
    //   dispatch(unloadTrainingModulePage());
    // };
    // eslint-disable-next-line
  }, [apiUrl]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/user_details?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/user_details?q=" + search);
    }
  };

  function RenderList(datas: any) {
    if (datas.data === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.map((data: any) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.first_name + " " + data.last_name}</Table.Cell>
          <Table.Cell>{data.email}</Table.Cell>

          <Table.Cell>
            <Button
              as={Link}
              to={`/user_details/${data.id}`}
              icon
              circular
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
            >
              <Icon name="info circle" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Training User Details</Breadcrumb.Section>
      </Breadcrumb>
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Header>
        Training User Details
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-3"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table compact celled>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>User Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {organization_users && <RenderList data={organization_users} />}
        </Table.Body>
      </Table>

      {organization_users === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
  ];
}
