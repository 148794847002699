export const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  INFO: "ALERT_INFO",
  WARNING: "ALERT_WARNING",
  CLEAR: "ALERT_CLEAR",
};

export const alertActions = {
  success,
  error,
  info,
  warning,
  clear,
};

function success(message: string) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message: string) {
  return { type: alertConstants.ERROR, message };
}

function info(message: string) {
  return { type: alertConstants.INFO, message };
}

function warning(message: string) {
  return { type: alertConstants.WARNING, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}
