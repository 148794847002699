import React, { useEffect, useRef, useState } from "react";
import { AppConfig } from "../../config";
import {
  fetchFormdata,
  unloadFormdataPage,
} from "../../redux/action/formDataAction";
import { useDispatch, useSelector } from "react-redux";

import ReactDOM from "react-dom";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "./DataList.scss";
import {
  Header,
  Button,
  Checkbox,
  Segment,
  Popup as SPopup,
} from "semantic-ui-react";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { useHistory } from "react-router-dom";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import MarkerPopup from "../../components/Modal/MarkerPopup";
import ModalComponent from "../../components/Modal";

const $ = require("jquery");
$.DataTable = require("datatables.net");
$.DataTable = require("datatables.net-fixedcolumns-bs4/js/fixedColumns.bootstrap4");

require("datatables.net-buttons")();

require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();

interface UserType {
  access_token: string;
  role: string;
}

export default function DataList(props: {
  slug: string;
  id: any;
  status?: string;
  filter_by_status?: boolean;
  hideStepForms?: boolean;
  status_field?: string;
  setFormName?: any;
}) {
  const filteringStatus =
    (props.filter_by_status ? props.status : null) ?? null;
  const status_field = props.status_field ?? "status";

  const history = useHistory();
  const formdata = useSelector((state: any) => state.formdata);

  const [selected, setSelected] = useState<string[]>([]);
  const [access_token, setAccess_token] = useState<string>("");

  const [visibility, setVisibility] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [latitude, setLatitude] = useState(27.7047954);
  const [longitude, setLongitude] = useState(85.3042305);
  const [dataId, setDataId] = useState("");

  const [hideMap, setHideMap] = useState(false);
  const [fullScreenMap, setFullScreenMap] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [isOpenBulkDeleteConfirmModal, setIsOpenBulkDeleteConfirmModal] =
    useState(false);

  const [isBulkDeleting, setIsBulkDeleting] = useState(false);

  const dispatch = useDispatch();
  const inputEl = useRef<HTMLTableElement>(null);

  // show hide map handler
  const showHidemapHandler = () => {
    if (hideMap) {
      setHideMap(false);
    } else {
      setHideMap(true);
    }
  };

  // full screen map handler
  const fullScreenMapHandler = () => {
    if (fullScreenMap) {
      setFullScreenMap(false);
    } else {
      setFullScreenMap(true);
    }
  };

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let user_info: UserType = JSON.parse(user);
    setAccess_token(user_info ? user_info.access_token : "");
    if (user_info !== undefined) {
      if (user_info.role === "User") {
        setVisibility(true);
      }
    }

    if (props.slug) {
      GetReport(props.slug);

      dispatch(
        fetchFormdata(
          `/formBuilder/data/${props.slug}` +
            (filteringStatus
              ? `?status=${props.status ?? ""}&status_field=${status_field}`
              : "")
        )
      );
    }

    return () => {
      dispatch(unloadFormdataPage());
      $("#datatable1").find("table").DataTable().destroy(true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formdata.field !== undefined) {
      callDatatable();
    }
    if (formdata.form && formdata.form.name && props.setFormName) {
      props.setFormName(formdata.form.name);
    }
    // eslint-disable-next-line
  }, [formdata]);

  const SelectData = (event: React.FormEvent<HTMLInputElement>, data: any) => {
    const value = data.checked;

    if (value) {
      setSelected((oldArray) => [...oldArray, data.name]);
    } else {
      setSelected((oldArray) => oldArray.filter((item) => item !== data.name));
    }
  };

  const DeactivateHandler = () => {
    apiService
      .save(`/formData/deactivate`, { toDelete: selected, slug: props.slug })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          $("#datatable1").DataTable().ajax.reload();
          setSelected([]);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const PreDeleteHandler = () => {
    setIsOpenBulkDeleteConfirmModal(true);
  };

  const DeleteHandler = () => {
    setIsBulkDeleting(true);

    apiService
      .save1(`/formData/${props.slug}/records/delete`, { ids: selected })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          $("#datatable1").DataTable().ajax.reload();
          setIsOpenBulkDeleteConfirmModal(false);
          setIsBulkDeleting(false);
          setSelected([]);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          setIsOpenBulkDeleteConfirmModal(false);
          setIsBulkDeleting(false);
        }
      );
  };

  const EditHandler = () => {
    if (selected.length === 1) {
      history.push(`/formbuilder/data/${props.slug}/edit/${selected.shift()}`);
    } else {
      dispatch(
        alertActions.error(
          "To edit atleast and only one data have to be selected"
        )
      );
    }
  };

  const QuickViewHandler = () => {
    if (selected.length === 1) {
      let id = selected.shift();
      if (id !== undefined) {
        setDataId(id);
        setOpenModal(true);
      }
    } else {
      dispatch(
        alertActions.error(
          "To Quick view atleast and only one data have to be selected"
        )
      );
    }
  };

  const GetReport = (data: string) => {
    if (data !== "") {
      apiService
        .save1(
          `/GetLocation/${data}` +
            (filteringStatus
              ? `?status=${props.status ?? ""}&status_field=${status_field}`
              : ""),
          {}
        )
        .then(
          (data) => {
            if (data.data !== "undefined") {
              setFilteredData(data.data);

              if (data.data[0] !== "undefined") {
                let geo = data.data[0] || null;

                if (geo) {
                  setLatitude(geo._latitude === "-" ? 0 : geo._latitude || 0);
                  setLongitude(
                    geo._longitude === "-" ? 0 : geo._longitude || 0
                  );
                } else {
                  setLatitude(0);
                  setLongitude(0);
                }
              }
            }
          },

          (error) => {
            dispatch(alertActions.error(error.toString()));
          }
        );
    }
  };

  const callDatatable = () => {
    $("#datatable1 thead tr").clone(true).appendTo("#datatable1 thead");
    $("#datatable1 thead tr:eq(1) th").each(function (this: any, i: number) {
      //var title = $(this).text();
      $(this).html(
        '<input class="form-control" type="text" placeholder="Search" />'
      );

      $("input", this).on("keyup change", function (this: HTMLInputElement) {
        if (table1.column(i).search() !== this.value) {
          table1.column(i).search(this.value).draw();
        }
      });
    });

    // let dup_on_btn = false;
    var table1 = $(inputEl.current)
      .on("preXhr.dt", function (e: any, settings: any, data: any) {
        if (settings.ajax.url.indexOf("duplicate") >= 0) {
          $("#duplicate-or-not").html("Showing Duplicate Results");
        } else {
          $("#duplicate-or-not").html("");
        }
      })
      .DataTable({
        autoWidth: true,
        lengthMenu: [
          [5, 10, 25, 500, 1000, 1500, -1],
          [5, 10, 25, 500, 1000, 1500, "All"],
        ],
        processing: true,
        serverSide: true,
        scrollX: true,
        responsive: true,
        scrollCollapse: true,

        order: [[2, "desc"]],
        fixedColumns: {
          leftColumns: 1,
        },
        columnDefs: [
          {
            targets: 1,
            createdCell: (
              td: any,
              cellData: any,
              rowData: any,
              row: any,
              col: any
            ) =>
              ReactDOM.render(
                <Checkbox
                  name={cellData.$oid}
                  onChange={SelectData}
                ></Checkbox>,
                td
              ),
          },

          {
            targets: 0,
            data: "id",
            render: function (data: any, type: any, full: any, meta: any) {
              return `<a style={{ cursor: 'pointer' }} href="/formbuilder/data/${
                props.slug
              }/view/${data.$oid}${`?step_id=${props.id}`}${
                props.hideStepForms ? "&hide_step_forms=true" : ""
              }" >${data.$oid}</a>`;
            },
          },
          {
            targets: "_all",
            render: function (data: any, type: any, full: any, meta: any) {
              return `<div   class='data_container' >${data}</div>`;
            },
          },
        ],
        dom:
          "<'row'<'col-sm-12'<'pull-right'B>>>" +
          "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",

        orderCellsTop: true,
        buttons: [
          {
            extend: "collection",
            text: "Export",
            buttons: [
              {
                extend: "copyHtml5",
                exportOptions: {
                  columns: [0, ":visible"],
                },
              },
              {
                extend: "excelHtml5",
                exportOptions: {
                  columns: ":visible",
                },
              },
              {
                extend: "pdfHtml5",
                orientation: "landscape",
                pageSize: "A0",
                customize: function (doc: {
                  pageMargins: number[];
                  defaultStyle: { fontSize: number };
                  styles: { tableHeader: { fontSize: number } };
                  content: { layout: {} }[];
                }) {
                  //Remove the title created by datatTables

                  doc.pageMargins = [20, 60, 20, 30];
                  // Set the font size fot the entire document
                  doc.defaultStyle.fontSize = 7;
                  // Set the fontsize for the table header
                  doc.styles.tableHeader.fontSize = 7;

                  // Change dataTable layout (Table styling)
                  // To use predefined layouts uncomment the line below and comment the custom lines below
                  // doc.content[0].layout = 'lightHorizontalLines'; // noBorders , headerLineOnly
                  var objLayout: any = {};
                  objLayout["hLineWidth"] = function (i: any) {
                    return 0.5;
                  };
                  objLayout["vLineWidth"] = function (i: any) {
                    return 0.5;
                  };
                  objLayout["hLineColor"] = function (i: any) {
                    return "#aaa";
                  };
                  objLayout["vLineColor"] = function (i: any) {
                    return "#aaa";
                  };
                  objLayout["paddingLeft"] = function (i: any) {
                    return 4;
                  };
                  objLayout["paddingRight"] = function (i: any) {
                    return 4;
                  };
                  doc.content[0].layout = objLayout;
                },
              },
            ],
          },
          {
            extend: "colvis",
            text: "Column Visibility",
            collectionLayout: "fixed four-column",
          },
          {
            extend: "colvisGroup",
            text: "Show all",
            show: ":hidden",
          },
          {
            text: "Check Duplicate",
            action: function (e: any, dt: any) {
              if (props.id) {
                history.push(`/step/form/duplicate/${props.id}`);
              } else {
                alert(
                  "Action unavailable from this page. Only step page can perform this action."
                );
              }
              // dup_on_btn = true;
              // table1.ajax.url(`${AppConfig.apiUrl}/serverSides/${props.slug}?duplicate=true`).load();
              // setTimeout(() => {
              //     table1.ajax.url(`${AppConfig.apiUrl}/serverSides/${props.slug}`);
              // }, 1000);

              // DUPLICATE FORM DATA - CODE with JS VERSION (replaced by serverside) (CANNOT BE USED here BECAUSE REACT + DATATABLE IS BAD BOOO..)
              // let duplicate_data:any = [];
              // console.log(formdata)

              // // Generate strinigy of only useful data (ignore date, id etc.)
              // let data_with_only_property_to_compare_with:string[] = [];
              // formdata.data.data.forEach((currentObject:any) => {
              //     let data_obj = {};
              //     for(let i = 1 ; i < formdata.key.length ; i++){
              //         data_obj[formdata.key[i]] = currentObject.hasOwnProperty(formdata.key[i]) ? currentObject[formdata.key[i]] : null
              //     }
              //     data_with_only_property_to_compare_with.push(JSON.stringify(data_obj));
              // });

              // data_with_only_property_to_compare_with.filter((item, index) => {
              //     let dup_index = data_with_only_property_to_compare_with.indexOf(item);
              //     if(dup_index != index){
              //         duplicate_data.push(formdata.data.data[dup_index]);
              //         duplicate_data.push(formdata.data.data[index]);
              //         return true;
              //     }else{
              //         return false;
              //     }
              // });
              // console.log(duplicate_data)
              // let temp_formdata = formdata;
              // temp_formdata.data.data = duplicate_data;
              // temp_formdata.data.total = duplicate_data.length;
              // dispatch({
              //     type: 'FETCH_FORMDATA',
              //     payload: temp_formdata
              // })
              // table1.draw();
            },
          },
        ],
        ajax: {
          url: `${AppConfig.apiUrl}/serverSides/${props.slug}`,
          type: "POST",
          dataType: "json",
          headers: { Authorization: "Bearer " + access_token },
          data: {
            fields: JSON.stringify(formdata.key),
            step: props.id,
            state: "submitted",
            type: "detail",
            status: filteringStatus ?? null,
            status_field: status_field,
          },
        },
      });
    $("#datatable1 tbody").on("click", "a", function (this: any, event: any) {
      event.preventDefault();
      var data = table1.row($(this).parents("tr")).data();
      showDetail(data[0].$oid);
    });
  };
  const relatedForm = () => {
    if (selected.length === 1) {
      history.push(`/formbuilder/fill/${props.slug}`, { id: selected.shift() });
    } else {
      dispatch(
        alertActions.error(
          "To edit atleast and only one data have to be selected"
        )
      );
    }
  };

  function showDetail(data: any) {
    history.push(
      `/formbuilder/data/${props.slug}/view/${data}${`?step_id=${props.id}`}${
        props.hideStepForms ? "&hide_step_forms=true" : ""
      }`
    );
  }

  function RenderHeader(datas: { data: { field: any[] | undefined } }): any {
    //console.log(datas.data)
    if (datas.data.field === undefined) {
      return <th> Loading.....</th>;
    }
    // console.log(datas.data)
    return datas.data.field.map((data, index) => {
      return <th key={index + 1}> {data}</th>;
    });
  }

  return (
    <div className="app-card-org">
      <ModalComponent
        open={isOpenBulkDeleteConfirmModal}
        setOpen={setIsOpenBulkDeleteConfirmModal}
        confirmDelete={DeleteHandler}
        strict={true}
        loading={isBulkDeleting}
        text="Permanently delete all selected records?"
        strict_text="This will permanently delete all selected records and cannot be recovered?"
      />
      <div key={2}>
        <div>
          {hideMap ? (
            <SPopup
              content="Show Map"
              trigger={<Button icon="unhide" onClick={showHidemapHandler} />}
            />
          ) : (
            <SPopup
              content="Hide Map"
              trigger={<Button icon="hide" onClick={showHidemapHandler} />}
            />
          )}

          <SPopup
            content={
              fullScreenMap ? "Click For Half Screen" : "Click for Full Screen"
            }
            trigger={<Button icon="map" onClick={fullScreenMapHandler} />}
          />
        </div>
      </div>
      <Segment
        key={3}
        style={{
          display: hideMap ? "none" : "block",
        }}
      >
        <Map
          style={{
            height: fullScreenMap ? "600px" : "250px",
            zIndex: 99,
            display: hideMap ? "none" : "block",
          }}
          center={[latitude, longitude]}
          zoom={5}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {filteredData.map(
              (
                item: {
                  _latitude: any;
                  _longitude: any;
                  _id: { $oid: string };
                },
                index: number
              ) => (
                <Marker
                  key={index}
                  position={[
                    item._latitude === "-" ? 0 : item._latitude || 0,
                    item._longitude === "-" ? 0 : item._longitude || 0,
                  ]}
                  onClick={() => {
                    setDataId(item._id.$oid || "");
                    setOpenModal(true);
                  }}
                ></Marker>
              )
            )}
          </MarkerClusterGroup>
        </Map>
      </Segment>

      <MarkerPopup
        openModal={openModal}
        formId={props.slug}
        dataId={dataId}
        setOpenModal={setOpenModal}
      />
      <Header style={{ marginTop: "5px" }}>
        {selected.length > 0 ? (
          <>
            <Button
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-black)",
              }}
              disabled={visibility}
              onClick={DeactivateHandler}
            >
              Deactivate ({selected.length})
            </Button>

            <Button
              className="formbuilder-buttons deleteButton"
              disabled={visibility}
              onClick={PreDeleteHandler}
            >
              Delete ({selected.length})
            </Button>
          </>
        ) : (
          ""
        )}

        <Button
          className="formbuilder-buttons"
          style={{
            backgroundColor: "white",
            color: "var(--color-blue)",
          }}
          disabled={visibility}
          onClick={EditHandler}
        >
          {" "}
          Edit
        </Button>

        <Button
          hidden={formdata.related === false}
          color="green"
          onClick={relatedForm}
        >
          Fill Related form
        </Button>
        <Button
          className="formbuilder-buttons"
          style={{
            backgroundColor: "white",
            color: "var(--color-green)",
          }}
          onClick={QuickViewHandler}
        >
          {" "}
          Quick View
        </Button>
      </Header>
      <table
        ref={inputEl}
        className="table table-striped "
        id="datatable1"
        style={{ width: "100%", margin: "5px" }}
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>
              <div className="pretty p-default">
                <div className="state">
                  <label>Check</label>
                </div>
              </div>
            </th>
            <th>Date Received</th>
            <th>Status</th>

            <th>Submitted By</th>
            {formdata && <RenderHeader data={formdata} />}
          </tr>
        </thead>
      </table>
    </div>
  );
}
