import React, { ReactElement } from "react";
import { Image } from "semantic-ui-react";

interface Props {
  data: string;
  label: string;
}

function SignatureComponent({ data, label }: Props): ReactElement {
  const ListItem = () => {
    if (data === null || data === "") return null;

    return (
      <div>
        <Image
          src={data}
          style={{ height: "80px", width: "auto" }}
          size="medium"
          alt="Signature"
        />
      </div>
    );
  };

  return (
    <div className="rows" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        <ListItem />
      </div>
    </div>
  );
}

export default SignatureComponent;
