import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Form,
  Grid,
  Table,
  Header,
  Icon,
  Message,
  Loader,
  Pagination,
  Flag,
  FlagNameValues,
  Modal,
  Input,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  loadLanguagePage,
  unloadLanguagePage,
  addLanguage,
  editLanguage,
  removeLanguage,
} from "../../redux/action/languageAction";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";

interface DataType {
  newData: {
    id: number;
    language: string;
    language_code: string;
    language_logo: string;
  };
}
type LanguageType = {
  language: string;
  language_code: string;
  language_logo: FlagNameValues;
  id: number;
}[];
interface RootState {
  language: {
    data: LanguageType;
    last_page: number;
  };
}

interface RenderProps {
  data: LanguageType;
}

const Language = () => {
  const [fdata, setData] = useState<DataType>({
    newData: {
      id: 0,
      language: "",
      language_code: "",
      language_logo: "",
    },
  });
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState<number>(1);
  const [apiUrl, setApiUrl] = useState<string>("/language");
  const languages = useSelector((state: RootState) => state.language);
  const [total, setTotal] = useState<number>(1);
  const dispatch = useDispatch();

  const [langCode, setLangCode] = useState("");
  const [languageData, setLanguageData] = useState({});
  const [openModal, setOpenModal]: any = useState(false);
  const [secondOpen, setSecondOpen]: any = useState(false);
  const [keyValue, setKeyValue]: any = useState({
    key: "",
    value: "",
  });

  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      loadLanguagePage(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadLanguagePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (languages.last_page !== undefined) {
      setTotal(languages.last_page);
    }
    // eslint-disable-next-line
  }, [languages]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl("/language?page=" + pageInfo.activePage.toString());
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        language: "",
        language_code: "",
        language_logo: "",
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let language = languages.data.find((v) => v.id === id);

    setData({
      newData: {
        id: language!.id,
        language: language!.language,
        language_code: language!.language_code,
        language_logo: language!.language_logo,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        addLanguage(
          fdata.newData,
          () => {
            dispatch(
              loadLanguagePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data added successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        editLanguage(
          fdata.newData,
          () => {
            dispatch(
              loadLanguagePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data Edited successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      removeLanguage(
        deleteId,
        () => {
          dispatch(
            loadLanguagePage(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  const tryRequire = (path: string) => {
    // console.log(require(`${path}`))
    try {
      return require(`../../language/${path}/translate.json`);
    } catch (e) {}
    return require(`../../language/en/translate.json`);
  };

  const handleLanguageDataLoad = (code: string) => {
    if (code !== langCode) {
      setLangCode(code);
      let translate = tryRequire(code);

      setLanguageData(translate);
    }
    setOpenModal(true);
  };

  const handleLanguageChanged = () => {
    console.log({ languageData });

    var el = document.createElement("a");
    document.body.appendChild(el);

    var data_string =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(languageData, null, 2));
    el.setAttribute("href", data_string);
    el.setAttribute("download", langCode.toLowerCase() + "-translate.json");
    el.click();
  };

  const handleKeyValueChange = (
    k: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setKeyValue({
      ...keyValue,
      [k]: event.target.value,
    });
  };

  const updateLanguageData = () => {
    setLanguageData({
      ...languageData,
      [keyValue["key"]]: keyValue["value"],
    });
    setKeyValue({
      key: "",
      value: "",
    });
  };

  const deleteLanguageData = (k: string) => {
    let newState = Object.assign({}, languageData);
    delete newState[k];
    setLanguageData(newState);
  };

  const handleLanguageData = (
    k: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLanguageData({
      ...languageData,
      [k]: event.target.value,
    });
  };

  function RenderList(datas: RenderProps): any {
    if (datas.data === undefined) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (datas.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    // console.log(datas.data)
    return datas.data.map((data) => {
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.language}</Table.Cell>
          <Table.Cell>{data.language_code}</Table.Cell>
          <Table.Cell>
            <Flag name={data.language_logo} />
          </Table.Cell>

          <Table.Cell>
            <Button
              circular
              onClick={() => {
                handleLanguageDataLoad(data.language_code);
              }}
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "blue",
              }}
              icon
            >
              <Icon name="eye" />
            </Button>

            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <React.Fragment>
      <div>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            <FormattedMessage id="Language" defaultMessage="Language" />
          </Breadcrumb.Section>
        </Breadcrumb>

        <ModalComponent
          open={open}
          setOpen={setOpen}
          confirmDelete={confirmDelete}
          strict={true}
          loading={loading}
        />
      </div>
      ,
      <div className="app-card-org" style={{ overflowX: "scroll" }}>
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create Language
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>
          <FormattedMessage id="Language" defaultMessage="Language" /> &nbsp;
          <FormattedMessage id="List" defaultMessage="List" />
        </Header>

        <Table basic="very" unstackable striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="ID" defaultMessage="ID" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="Language" defaultMessage="Language" />{" "}
                Name
              </Table.HeaderCell>
              <Table.HeaderCell> Code</Table.HeaderCell>
              <Table.HeaderCell> Logo</Table.HeaderCell>

              <Table.HeaderCell width={2}>
                <FormattedMessage id="Actions" defaultMessage="Actions" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {languages && <RenderList data={languages.data} />}
          </Table.Body>
        </Table>
        <Modal
          open={openModal === false ? false : true}
          onClose={() => {
            setOpenModal(false);
          }}
          onOpen={() => setOpenModal(languageData)}
          style={{ position: "relative" }}
          size="large"
        >
          <Modal.Header>
            <Button
              className="createButton"
              onClick={() => setSecondOpen(true)}
            >
              Add
            </Button>
            <Button
              className="submitButton"
              onClick={handleLanguageChanged}
              floated="right"
            >
              Save
            </Button>
            <Modal
              onClose={() => setSecondOpen(false)}
              open={secondOpen}
              size="mini"
              style={{ position: "relative" }}
            >
              <Modal.Header>Add Key Value</Modal.Header>
              <Modal.Content>
                <Input
                  placeholder="Key"
                  onChange={(e) => handleKeyValueChange("key", e)}
                />
                <br />
                <Input
                  placeholder="Value"
                  onChange={(e) => handleKeyValueChange("value", e)}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  icon="check"
                  content="Update"
                  onClick={() => updateLanguageData()}
                />
              </Modal.Actions>
            </Modal>
          </Modal.Header>
          <Modal.Content scrolling>
            <Grid columns="two">
              <Grid.Row>
                <Grid.Column>
                  <Table unstackable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Key</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Object.keys(languageData)
                        .slice(0, Object.keys(languageData).length / 2)
                        .map((k, i) => {
                          return (
                            <Table.Row key={k}>
                              <Table.Cell>
                                <strong>{k}</strong>
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  key={k + "_"}
                                  defaultValue={languageData[k]}
                                  onChange={(e) => handleLanguageData(k, e)}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  icon
                                  circular
                                  className="deleteButton"
                                  onClick={() => deleteLanguageData(k)}
                                >
                                  <Icon name="trash" />
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column>
                  <Table unstackable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Key</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Object.keys(languageData)
                        .slice(
                          Object.keys(languageData).length / 2,
                          Object.keys(languageData).length
                        )
                        .map((k, i) => {
                          return (
                            <Table.Row key={k}>
                              <Table.Cell>
                                <strong>{k}</strong>
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  key={k + "_"}
                                  defaultValue={languageData[k]}
                                  onChange={(e) => handleLanguageData(k, e)}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  icon
                                  circular
                                  className="deleteButton"
                                  onClick={() => deleteLanguageData(k)}
                                >
                                  <Icon name="trash" />
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
        {languages.data === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column>
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>
      ,
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="tiny"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Language
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={2}>
              <Form.Field style={{ width: "100%" }}>
                <label>
                  <FormattedMessage id="Language" defaultMessage="Language" />{" "}
                  &nbsp;
                  <FormattedMessage id="Name" defaultMessage="Name" />
                </label>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="language"
                  value={fdata.newData.language}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Language name..."
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field style={{ width: "100%" }}>
                <label>
                  <FormattedMessage id="Language" defaultMessage="Language" />{" "}
                  Code
                </label>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="language_code"
                  value={fdata.newData.language_code}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Language code..."
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field style={{ width: "100%" }}>
                <label>
                  <FormattedMessage id="Language" defaultMessage="Language" />{" "}
                  Logo
                </label>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="language_logo"
                  value={fdata.newData.language_logo}
                  onChange={(e) => handleChange(e)}
                  placeholder="Enter Language logo..."
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
      ,
    </React.Fragment>
  );
};

export default Language;
