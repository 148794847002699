import { sharingTypes } from "../action/sharingAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case sharingTypes.SHARING_TYPE_LOADED:

            return action.payload;

        case sharingTypes.SHARING_TYPE_UNLOADED:
            return initialState;
        case sharingTypes.ADD_SHARING_TYPE:
           
             return { ...state};
        case sharingTypes.EDIT_SHARING_TYPE:

            return state;
        case sharingTypes.DELETE_SHARING_TYPE:
           
            return state;
        case sharingTypes.COLLAB_SHARING_TYPE:
        
            return state;

        default:
            return state;
    }
};
