import React, { useState, useEffect } from "react";
import {
  Segment,
  Header,
  Breadcrumb,
  Message,
  Divider,
  Button,
  Label,
  Icon,
  Loader,
} from "semantic-ui-react";
import {
  loadLiveTrainingPage,
  unloadLiveTrainingPage,
} from "../../redux/action/liveTrainingAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Jitsi from "react-jitsi";
import { AppConfig } from "../../config";
import { authHeader } from "../../helper";
import { AlertModalComponent } from "../../components/Modal";

interface Props {
  match: {
    params: {
      slug: string;
    };
  };
}

interface RootState {
  live_trainings: {
    training_name: string;
    training_name_slug: string;
    id: number;
    training_module_id: number;
    training_creator_name: string;
    training_creator_id: number;
    training_date_end_time: string;
    password: string;
    training_module?: {
      name: string;
      description: string;
    };
  };
}

export default function LiveTrainingInfo(props: Props) {
  const [apiUrl] = useState(`/live_training/${props.match.params.slug}`);
  const live_trainings = useSelector(
    (state: RootState) => state.live_trainings
  );

  const [timeDifference, setTimeDifference] = useState(0);

  const [showLiveTrainingDetails, setShowLiveTrainingDetails] = useState(false);
  const [trainingDetailsMondaUsers, setTrainingDetailsMondaUsers] = useState(
    []
  );
  const [trainingDetailsExternalUsers, setTrainingDetailsExternalUsers] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [mailSendloadingMonda, setMailSendLoadingMonda] = useState(false);
  const [mailSendloadingExternal, setMailSendLoadingExternal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // let moment = require('moment');
  let moment = require("moment-timezone");

  var zone_name = moment.tz.guess();
  // var timezone = moment.tz(zone_name).zoneAbbr()
  // let dateTime = moment().format("YYYY-MM-DD HH:mm:ss");
  // var UTC = moment.utc(live_trainings.training_date_end_time, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

  useEffect(() => {
    setTimeDifference(
      moment.tz(live_trainings.training_date_end_time, "GMT").diff(moment())
    );
  }, [live_trainings.training_date_end_time]);

  const [jitsiOn, setJitsiOn] = useState(false);
  const is_creator =
    JSON.parse(localStorage.getItem("user") || "{}").data?.id ==
    live_trainings.training_creator_id
      ? true
      : false;
  const config = () => {
    return {
      defaultLanguage: "en",
      prejoinPageEnabled: false,
      toolbarButtons: is_creator
        ? [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "embedmeeting",
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "profile",
            "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "shareaudio",
            "settings",
            "raisehand",
            "videoquality",
            "filmstrip",
            "invite",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "select-background",
            "download",
            "help",
            "mute-everyone",
            "mute-video-everyone",
            "security",
          ]
        : [
            "microphone",
            "camera",
            "closedcaptions",
            "fullscreen",
            "hangup",
            "chat",
            "raisehand",
            "videoquality",
            "tileview",
            "select-background",
            "help",
          ],
      apiLogLevels: ["error"],
      dynamicBrandingUrl: localStorage.getItem("api_url") + "/brand",
      remoteVideoMenu: {
        disableKick: true,
      },
    };
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      loadLiveTrainingPage(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadLiveTrainingPage());
    };
  }, [apiUrl, dispatch]);

  const jitsiMeetInitiator = () => {
    setJitsiOn(true);
  };

  const jitsiMeetHelper = (JitsiMeetAPI: any) => {
    // console.log('Good Morning everyone!')
    // when local user is trying to enter in a locked room
    JitsiMeetAPI.addEventListener("passwordRequired", () => {
      JitsiMeetAPI.executeCommand("password", live_trainings.password);
    });

    // when local user has joined the video conference
    JitsiMeetAPI.addEventListener("videoConferenceJoined", () => {
      JitsiMeetAPI.executeCommand("password", live_trainings.password);
    });
    setTimeout(async () => {
      if (!is_creator) {
        let headers = authHeader();
        const requestOptions: object = {
          method: "GET",
          headers: headers,
        };
        try {
          let response = await fetch(
            `${AppConfig.apiUrl}/live_training/attendance/${live_trainings.id}`,
            requestOptions
          );
          let data = await response.json();
          if (data) {
            console.log(data.message);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, 10000);
  };

  const sendInvitationMail = async (type: string) => {
    type === "monda_users"
      ? setMailSendLoadingMonda(true)
      : setMailSendLoadingExternal(true);

    let headers = authHeader();
    const requestOptions: object = {
      method: "GET",
      headers: headers,
    };

    if (is_creator) {
      // /jitsi-meet/mail/{meet_id}/{type}
      try {
        let response = await fetch(
          `${AppConfig.apiUrl}/jitsi-meet/mail/${live_trainings.id}/${type}`,
          requestOptions
        );
        let data = await response.json();

        if (response.ok && data) {
          setAlertMessage("Invitation Mail Sent Successfully.");
          type === "monda_users"
            ? setMailSendLoadingMonda(false)
            : setMailSendLoadingExternal(false);
        } else {
          alert("⚠ Invitation Failed");
          type === "monda_users"
            ? setMailSendLoadingMonda(false)
            : setMailSendLoadingExternal(false);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getLiveTrainingDetails = async () => {
    let headers = authHeader();
    const requestOptions: object = {
      method: "GET",
      headers: headers,
    };

    if (is_creator) {
      // /jitsi-meet/mail/{meet_id}/{type}
      try {
        let response = await fetch(
          `${AppConfig.apiUrl}/live_training/details/${live_trainings.id}`,
          requestOptions
        );
        let data = await response.json();
        if (data) {
          setShowLiveTrainingDetails(true);
          setTrainingDetailsMondaUsers(data.participants);
          setTrainingDetailsExternalUsers(data.external_participants);

          console.log(trainingDetailsExternalUsers);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section link as={Link} to={`/live_training`}>
          Live Training
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {live_trainings === undefined ||
        Object.keys(live_trainings).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.slug}
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {live_trainings.training_name}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
      <AlertModalComponent
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        alertMessageHeader="Operation Success!"
        type="positive"
      />
    </div>,
    <Segment key="2">
      {loading ? <Loader active inverted size="big" /> : ""}
      <Header>Live Training Info</Header>
      <Divider />
      {live_trainings === undefined ||
      Object.keys(live_trainings).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <div>
          <b>Live Training Title: {live_trainings.training_name}</b>
          <br />
          <b>Training Module: </b>
          {live_trainings.training_module
            ? live_trainings.training_module.name
            : "No Training Module Linked"}
          <p>
            <b>Description: </b>
            {live_trainings.training_module
              ? live_trainings.training_module.description
              : "No Description Available"}
          </p>
          <hr />
          {jitsiOn ? (
            <Jitsi
              roomName={live_trainings.training_name_slug}
              // @ts-ignore
              displayName={
                is_creator
                  ? live_trainings.training_creator_name
                  : // @ts-ignore
                    JSON.parse(localStorage.getItem("user")).data.first_name +
                    " " +
                    // @ts-ignore
                    JSON.parse(localStorage.getItem("user")).data.last_name
              }
              password={live_trainings.password}
              loadingComponent={() => (
                <div>
                  <Loader active inverted size="big" />
                  Loading Meeting...
                </div>
              )}
              onAPILoad={jitsiMeetHelper}
              config={config()}
              // @ts-ignore
              userInfo={{
                // @ts-ignore
                email: JSON.parse(localStorage.getItem("user")).data.email,
              }}
              interfaceConfig={{
                SHOW_JITSI_WATERMARK: false,
                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
              }}
            />
          ) : is_creator ? (
            timeDifference > 0 ? (
              <>
                <Button
                  onClick={() => sendInvitationMail("monda_users")}
                  className="createButton"
                  disabled={mailSendloadingMonda}
                  loading={mailSendloadingMonda}
                >
                  Send Invitation Link to BCTAP Users
                </Button>
                <Button
                  onClick={() => sendInvitationMail("external_participants")}
                  className="createButton"
                  disabled={mailSendloadingExternal}
                  loading={mailSendloadingExternal}
                >
                  Send Invitation Link to External Participants
                </Button>
                <Button onClick={jitsiMeetInitiator} className="createButton">
                  Start Meeting
                </Button>
              </>
            ) : (
              <>
                <Label color="blue">
                  Meeting ended {moment.duration(timeDifference).humanize(true)}
                </Label>
                <br />
                <br />
                <Button color="teal" onClick={() => getLiveTrainingDetails()}>
                  View Live Training Details
                </Button>
              </>
            )
          ) : timeDifference > 0 ? (
            <Button onClick={jitsiMeetInitiator}>Join Meeting</Button>
          ) : (
            <>
              <Label color="blue">
                Meeting ended {moment.duration(timeDifference).humanize(true)}
              </Label>
              <br />
              <br />
              <Button color="red">Meeting Ended Already!</Button>
            </>
          )}
        </div>
      )}
    </Segment>,

    <Segment key={3} style={{ display: showLiveTrainingDetails ? "" : "none" }}>
      <Header>
        <b>{live_trainings.training_name} : Details</b>
      </Header>
      <Divider />
      {trainingDetailsMondaUsers === undefined ||
      Object.keys(trainingDetailsMondaUsers).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <div>
          <div>
            <div>
              <h6>BCTAP Users Details</h6>
            </div>
            <table className="table border table-striped col-sm-12 col-lg-6">
              <thead
                style={{ backgroundColor: "var(--color-blue)", color: "white" }}
              >
                <tr>
                  <td className="same">S.N.</td>

                  <td className="same">Name of User</td>

                  <td className="same">Email</td>

                  <td className="same">Invitation Link</td>

                  <td className="same">Attendance Status</td>
                </tr>
              </thead>
              <tbody>
                {trainingDetailsMondaUsers.map((monda_user: any, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{monda_user.name}</td>
                      <td>{monda_user.email}</td>
                      <td>
                        {monda_user.invitation_link ? (
                          <Label
                            color={"green"}
                            className={"training_info_tags"}
                          >
                            <Icon name="check circle" /> Sent via email
                          </Label>
                        ) : (
                          <Label
                            style={{ backgroundColor: "#dd4b39" }}
                            className={"training_info_tags"}
                          >
                            <Icon name="times circle" /> Not Sent
                          </Label>
                        )}
                      </td>
                      <td>
                        {monda_user.attendance ? (
                          <Label
                            color={"green"}
                            className={"training_info_tags_attendance"}
                          >
                            <Icon name="check circle" /> Present
                          </Label>
                        ) : (
                          <Label
                            style={{ backgroundColor: "#dd4b39" }}
                            className={"training_info_tags_attendance"}
                          >
                            <Icon name="times circle" /> Absent
                          </Label>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "3rem" }}>
            <div>
              <h6>External Users Details</h6>
            </div>
            <table className="table border table-striped col-sm-8 col-lg-6">
              <thead
                style={{ backgroundColor: "var(--color-blue)", color: "white" }}
              >
                <tr>
                  <td className="same">S.N.</td>

                  <td className="same">Email Address</td>

                  <td className="same">Invitation Link</td>
                </tr>
              </thead>
              <tbody>
                {trainingDetailsExternalUsers.map((monda_user: any, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{monda_user.email}</td>
                      <td>
                        {monda_user.invitation_link ? (
                          <Label
                            color={"green"}
                            className={"training_info_tags"}
                          >
                            <Icon name="check circle" /> Sent via email
                          </Label>
                        ) : (
                          <Label
                            style={{ backgroundColor: "#dd4b39" }}
                            className={"training_info_tags"}
                          >
                            <Icon name="times circle" /> Not Sent
                          </Label>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Segment>,
  ];
}
