import { Viewer, Worker } from "@react-pdf-viewer/core";
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Grid,
  Icon,
  Tab,
} from "semantic-ui-react";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

// Import styles
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/php/php";
import { apiService } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../../redux/action/alertAction";
import { fetchData, unloadData } from "../../../redux/action/allAction";

interface Props {
  history: any;
  match: any;
}

export default function FormFunction(props: any): ReactElement {
  const dispatch = useDispatch();
  const report = useSelector((state: any) => state.all_ind);

  const [apiList, setApiList]=useState<string[]>([])
  const [apiListSearch, setApiListSearch]=useState<string>('')
  const handle = useFullScreenHandle();

  const [viewFormFields, setViewFormFields] = useState<boolean>(true);
  const [viewSetting, setViewSetting] = useState<boolean>(false);

  const handleRightSideMenu = (view: string) => {
    if (view === "formfields") {
      if (!viewFormFields) {
        setViewSetting(false);
      }
      setViewFormFields(!viewFormFields);
    } else if (view === "setting") {
      if (!viewSetting) {
        setViewFormFields(false);
      }
      setViewSetting(!viewSetting);
    }
  };

  const sampleFunction =
`$result['foo'] = 'bar';

return $result;`;

  const [values, setValues] = useState({
    name: '',
    slug: props.match.params.slug,
    function: '',
  });

  const codeMirrorOptions = {
    theme: "material",
    lineNumbers: true,
    scrollbarStyle: "native",
    viewportMargin: 99,
    autocorrect: true,
  };

  useEffect(() => {
    dispatch(fetchData(`/formBuilder/function/${props.match.params.slug}`));
    return () => {
      dispatch(unloadData());
    };
  }, []);

  useEffect(() => {
    if (report !== undefined) {
        if (report.fieldApi !== undefined) {
            setApiList([...report.fieldApi, '_latitude', '_longitude'])
        }
      
        setValues({
            slug: props.match.params.slug,
            name: report.name,
            function: ((report.function ?? sampleFunction) + '\n')
        });
    }
  }, [report]);

  const goBack = () => {
    props.history.goBack();
  };

  const SaveFunction = () => {
    apiService
      .save(
        `/formBuilder/function/${props.match.params.slug}`,
        values
      )
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const appendVariable = (apiFieldName: string) => {
    setValues((prevState) => ({
        ...prevState,
        function: (`${prevState.function}\n\$data[\'${apiFieldName}\']\n`),
    }));
  }

  const panes = [
    {
      menuItem: "FUNCTION",
      render: () => (
        <Tab.Pane>
          <CodeMirror
            key={'codemirror-function'}
            value={values.function}
            className="code-editor"
            options={{
              mode: "text/x-php",
              ...codeMirrorOptions,
            }}
            onBeforeChange={(editor, data, value) => {
              setValues((prevState) => ({
                ...prevState,
                function: value,
              }));
            }}
            onChange={(editor, data, value) => {}}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "EXAMPLES",
      render: () => (
        <Tab.Pane>
          <CodeMirror
            key={'codemirror-examples'}
            value={
`// The Code are Server-Side Processed. On each sync or manual submission.
// It can be used to modify data, add metadata, perform calculations etc.

// You have access to $data array, which contains all the record filled by the user.
// You can access the properties using the Property Name (Field/Key) of the input fields. Like this:
$data['first_name'];

// When accessing data and performing calculations, it is better to use Null Coalescing Operator.
// So that, when the key does not exist in the array, we can fallback, without crashing.
$data['first_name'] ?? 'N/A';

// You also have access to $result array, which should contain all the calculated data, metadata and should be returned.
// Then the content of $result returned by your code, is merged together with $data in server-side and stored.
$result['full_name'] = ( $data['first_name'] ?? 'N/A' ) . ( $data['last_name'] ?? '' );
$result['total_score'] = ( $data['exam_1_score'] ?? 0 ) + ( $data['exam_2_score'] ?? 0 );
return $result;

// Note that: String Concatenation is done with dot (.)

// You can of course use complex logical checks as well.

if(($data['state'] ?? false) && $data['state'] === 'State 1') {
  $result['headquarter_address'] = 'The Address for 1';
}

// Or match, switch/case, loops etc. can be used as well.

// You also have access to basic PHP functions like date, preg_replace, sha1, strtoupper, round, and many more.
// You also have access to basic PHP Classes like stdClass, DateTime, DateInterval, NumberFormatter, and few more.

// You can insert data, by searching and clicking on the form fields list in the left menu.
// Always make sure the code is correct and has no syntax errors.
`         }
            className="code-editor"
            options={{
              mode: "text/x-php",
              readOnly: 'nocursor',
              ...codeMirrorOptions,
            }}
            onBeforeChange={() => {}}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            Formbuilder Function ({values.name ?? '...'})
          </Breadcrumb.Section>
        </Breadcrumb>

        <div>
          {/* Full Screen */}
          <Button className="export-button" onClick={handle.enter}>
            <svg
              className="rpv-core-icon"
              height="16px"
              viewBox="0 0 24 24"
              width="16px"
            >
              <path
                d="M15.5,8.499l8-8
                M0.5,23.499l8-8
                M5.5,23.499h-5v-5
                M23.5,5.499v-5h-5
                M15.5,15.499l8,8
                M0.5,0.499l8,8
                M0.5,5.499v-5h5
                M18.5,23.499h5v-5"
              ></path>
            </svg>
          </Button>

          <Button
            className="export-button"
            onClick={SaveFunction}
            color="twitter"
          >
            Save
          </Button>
        </div>
      </div>

      <FullScreen handle={handle}>
        <div className="afterFullScreen">
          <Grid st columns="equal" stackable>
            {/* <Divider vertical /> */}
            <div className="formbuilder-header">
              <div>
                <h2>Formbuilder Function</h2>
              </div>
              <div>
                <Button
                  className="export-button"
                  onClick={SaveFunction}
                  color="twitter"
                >
                  Save
                </Button>
              </div>
            </div>

            <Grid.Row>
              <Grid.Column width={1} className="gridColumnNoPaddingRight">
                <div className="report-side-menu">
                  <div onClick={() => handleRightSideMenu("formfields")}>
                    <Icon name="bars" size="large" />
                  </div>

                  {/* <div onClick={() => handleRightSideMenu("setting")}>
                    <Icon name="setting" size="large" />
                  </div> */}
                </div>
              </Grid.Column>

              <Grid.Column
                width={2}
                className={`formfields ${viewFormFields ? "active" : ""}`}
              >
                <h3>Form Fields</h3>
                <input type="text" value={apiListSearch} onChange={(e) => setApiListSearch(e.target.value)} />
                <ul className="tags">
                  {apiList.filter((api) => {
                    if(!apiListSearch) return true;

                    return api.toLowerCase().indexOf(apiListSearch.toLowerCase()) >= 0;
                  }).map((api) => <li style={{cursor: "pointer"}} onClick={() => appendVariable(api)}>{api}</li>)}
                </ul>
              </Grid.Column>

              {/* <Grid.Column
                width={2}
                className={`formfields ${viewSetting ? "active" : ""}`}
              >
                <h3>Setting</h3>
              </Grid.Column> */}

              <Grid.Column>
                <Tab panes={panes} />
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </div>
      </FullScreen>
    </React.Fragment>
  );
}
