import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Input,
  Label,
  Modal,
  Popup,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadTrainingModulePage,
  unloadTrainingModulePage,
  addTrainingModule,
  editTrainingModule,
  removeTrainingModule,
} from "../../redux/action/trainingModuleAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { ImageModalComponent } from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  training_modules: {
    training_modules: {
      data: {
        name: string;
        id: number;
        app_id: number;
        project_id: number;
        description: string;
        image: string;
        visibility_before_login: string;
        visibility_after_login: string;
        visibility_status: string;
        roles: any[];
        tiles: any[];
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
      project_id: number;
      status: string;
    }[];
    projects: {
      id: number;
      name: string;
      slug: string;
    }[];
    roles: {
      id: number;
      name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

type visibilityBeforeLoginType = {
  key: string | number;
  value: string;
  text: string;
}[];

type visibilityAfterLoginType = {
  key: string | number;
  value: string;
  text: string;
}[];

type optionType = {
  key: string | number;
  value: number | string;
  text: string;
}[];

type visibilityStatusOptionType = {
  key: string | number;
  value: string;
  text: string;
}[];

export default function TrainingModule() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      name: "",
      app_id: undefined as number | undefined,
      project_id: undefined as number | undefined,
      description: "",
      image: "",
      visibility_before_login: "",
      visibility_after_login: "",
      visibility_status: "",
      roles: [],
      tiles: [],
    },
  });
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [url, setUrl] = useState("");
  const [search, setSearch] = useState<string>("");
  const [deleteId, setDeleteId] = useState<number>(0);
  const [openInfo, setOpenInfo]: any = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/training_module");
  const training_modules = useSelector(
    (state: RootState) => state.training_modules
  );
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [projectOptions, setProjectOptions] = useState<optionType>([]);

  const [roleOptions, setRoleOptions] = useState<optionType>([]);
  const [tileOptions, setTileOptions] = useState<optionType>([]);
  const [visibilityBeforeLoginOptions, setVisibilityBeforeLoginOptions] =
    useState<visibilityBeforeLoginType>([]);
  const [visibilityAfterLoginOptions, setVisibilityAfterLoginOptions] =
    useState<visibilityAfterLoginType>([]);
  const [visibilityStatusOptions, setVisibilityStatusOptions] =
    useState<visibilityStatusOptionType>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      loadTrainingModulePage(apiUrl, () => {
        setLoading(false);
      })
    );

    return () => {
      dispatch(unloadTrainingModulePage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (training_modules.training_modules !== undefined) {
      setTotal(training_modules.training_modules.last_page);
      var final_stage = training_modules.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(final_stage);

      var project_opts = training_modules.projects.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.name + " - " + data.slug,
        };
      });

      setProjectOptions(project_opts);

      if (training_modules.roles !== undefined) {
        let final_roles = training_modules.roles.map((data) => {
          return {
            key: data.id,
            value: data.id,
            text: data.name,
          };
        });

        setRoleOptions(final_roles);
      }

      setVisibilityBeforeLoginOptions([
        {
          key: "true",
          value: "true",
          text: "True",
        },
        {
          key: "false",
          value: "false",
          text: "False",
        },
      ]);

      setVisibilityAfterLoginOptions([
        {
          key: "true",
          value: "true",
          text: "True",
        },
        {
          key: "false",
          value: "false",
          text: "False",
        },
      ]);

      setVisibilityStatusOptions([
        {
          key: "true",
          value: "true",
          text: "True",
        },
        {
          key: "false",
          value: "false",
          text: "False",
        },
      ]);
    }
  }, [training_modules]);

  useEffect(() => {});

  const getTileData = (app_id: any) => {
    if (app_id) {
      apiService.getAll(`/training_module/tiles/` + app_id).then(
        (data) => {
          // let fetched_tiles: any = []

          let final_tiles = data.map((tile: any) => {
            return {
              key: tile.id,
              value: tile.id,
              text: tile.name,
            };
          });

          setTileOptions(final_tiles);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
    }
  };

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/training_module?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/training_module?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        name: "",
        app_id: undefined,
        project_id: undefined,
        description: "",
        image: "",
        visibility_before_login: "",
        visibility_after_login: "",
        visibility_status: "",
        roles: [],
        tiles: [],
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let training_module = training_modules.training_modules.data.find(
      (v) => v.id === id
    );

    setData({
      newData: {
        id: training_module!.id,
        name: training_module!.name,
        app_id: training_module!.app_id ?? undefined,
        project_id: training_module!.project_id ?? undefined,
        description: training_module!.description,
        image: "",
        visibility_before_login: training_module!.visibility_before_login,
        visibility_after_login: training_module!.visibility_after_login,
        visibility_status: training_module!.visibility_status,
        //@ts-ignore
        roles: training_module.roles.map((role) => {
          return role.role_id;
        }),
        //@ts-ignore
        tiles: training_module.tiles.map((tile) => {
          return tile.tile_id;
        }),
      },
    });

    getTileData(training_module!.app_id);
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    let additional: any = {};

    if (name === "app_id") {
      getTileData(result.value);

      if (value) {
        additional.project_id =
          training_modules.apps.find((a) => a.id === value)?.project_id ??
          undefined;
      } else {
        additional.project_id = undefined;
      }
    }

    if (name === "project_id") {
      if (value) {
        additional.app_id = undefined;
      }
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
        ...additional,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        addTrainingModule(
          fdata.newData,
          () => {
            dispatch(
              loadTrainingModulePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data added successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        editTrainingModule(
          fdata.newData,
          () => {
            dispatch(
              loadTrainingModulePage(apiUrl, () => {
                setLoading(false);
                dispatch(alertActions.success("Data Edited successfully"));
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      removeTrainingModule(
        deleteId,
        () => {
          dispatch(
            loadTrainingModulePage(apiUrl, () => {
              dispatch(alertActions.success("Data Deleted successfully"));
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  function RenderList(datas: any) {
    if (datas.data.training_modules === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.training_modules.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.training_modules.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      let project = datas.data.projects.find(
        (p: any) => p.id === data.project_id
      );

      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.name}</Table.Cell>
          <Table.Cell>{app ? app.app_name : "-"}</Table.Cell>
          <Table.Cell>{project ? project.name : "-"}</Table.Cell>
          <Table.Cell>
            {data.image ? (
              <Button onClick={() => setUrl(data.image)}>View</Button>
            ) : (
              <i>-</i>
            )}
          </Table.Cell>
          <Table.Cell>
            {data.visibility_before_login === "true" ? "Visible" : "Invisible"}
          </Table.Cell>
          <Table.Cell>
            {data.visibility_after_login === "true" ? "Visible" : "Invisible"}
          </Table.Cell>
          <Table.Cell>
            {data.visibility_status === "true" ? "Yes" : "No"}
          </Table.Cell>
          <Table.Cell>
            {data.roles.length > 0
              ? data.roles.map((role: any) => {
                  return training_modules.roles.map((r) => {
                    if (r.id === role.role_id) {
                      return (
                        <Label
                          style={{
                            backgroundColor: "var(--color-blue)",
                            borderColor: "var(--color-blue)",
                            color: "white",
                          }}
                          horizontal
                          className="mb-2"
                        >
                          {r.name}
                        </Label>
                      );
                    }

                    return "";
                  });
                })
              : "N/A"}
          </Table.Cell>

          <Table.Cell>
            <Popup
              trigger={
                <Button
                  as={Link}
                  to={`/training/${data.id}`}
                  icon
                  circular
                  className="formbuilder-buttons"
                  style={{
                    backgroundColor: "white",
                    color: "var(--color-blue)",
                  }}
                >
                  <Icon name="info circle" />
                </Button>
              }
              content="Info"
              position="top center"
            />

            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="trash" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Training Module</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <ImageModalComponent setUrl={setUrl} url={url} />
    </div>,
    <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
      <Button
        className="createButton"
        icon
        onClick={() => {
          clearForm();
          setOpenInfo(true);
          setCreating(true);
        }}
        labelPosition="left"
      >
        Create Training Module
        <Icon name="plus" className="iconSize" />
      </Button>
      <Header>
        Training Modules
        <Input
          onChange={(e) => setSearch(e.target.value)}
          onKeyPress={onSearch}
          className="pull-right small mb-3"
          icon="search"
          placeholder="Search..."
        />
      </Header>

      <Table basic="very" unstackable striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>Project</Table.HeaderCell>
            <Table.HeaderCell>Image</Table.HeaderCell>
            <Table.HeaderCell>Before Login</Table.HeaderCell>
            <Table.HeaderCell>After Login</Table.HeaderCell>
            <Table.HeaderCell>Visible</Table.HeaderCell>
            <Table.HeaderCell>User Roles</Table.HeaderCell>

            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {training_modules && <RenderList data={training_modules} />}
        </Table.Body>
      </Table>

      {training_modules.training_modules === undefined || loading ? (
        <Loader active inverted size="big" />
      ) : (
        ""
      )}
      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,

    <Modal
      as={Form}
      onSubmit={(e: any) => handleSubmit(e)}
      open={openInfo === false ? false : true}
      onClose={() => setOpenInfo(false)}
      size="large"
      className="modal-customized"
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Modal.Header className="modalHeader">
        {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
        {creating ? "Create" : "Edit"} Training Module
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Form>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="name"
                label="Training Module Name"
                value={fdata.newData.name}
                onChange={handleChange}
                placeholder="Enter Training Module name..."
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                placeholder="Select tiles(s) for the training module"
                fluid
                multiple
                search
                selection
                name="tiles"
                label="Select tile(s) for the training module"
                onChange={handleChange}
                value={fdata.newData.tiles}
                options={tileOptions}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                options={appOptions}
                label="Choose an App"
                value={fdata.newData.app_id}
                name="app_id"
                onChange={handleChange}
                disabled={
                  fdata.newData.project_id && !fdata.newData.app_id
                    ? true
                    : false
                }
                clearable
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Project"
                required
                options={projectOptions}
                label="OR - a Project"
                value={fdata.newData.project_id}
                name="project_id"
                onChange={handleChange}
                disabled={fdata.newData.app_id ? true : false}
                clearable
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="description"
                label="Description"
                value={fdata.newData.description}
                onChange={handleChange}
                placeholder="Description"
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                type="file"
                name="image"
                label="Select Image"
                onChange={handleChange}
                accept="image/*"
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Select
              fluid
              required
              placeholder="Select Visibilty Status Before Login"
              options={visibilityBeforeLoginOptions}
              label="Visibility Before Login"
              value={fdata.newData.visibility_before_login}
              name="visibility_before_login"
              onChange={handleChange}
            />
            <Form.Select
              fluid
              required
              placeholder="Select Visibilty Status After Login"
              options={visibilityAfterLoginOptions}
              label="Visibility After Login"
              value={fdata.newData.visibility_after_login}
              name="visibility_after_login"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Select
              fluid
              required
              placeholder="Select Visibilty Status For This Training Module"
              options={visibilityStatusOptions}
              label="Visibility For The Training Module"
              value={fdata.newData.visibility_status}
              name="visibility_status"
              onChange={handleChange}
            />

            <Form.Field>
              <Form.Select
                placeholder="Select roles"
                fluid
                multiple
                search
                selection
                name="roles"
                label="Select user roles to allow usability"
                onChange={handleChange}
                value={fdata.newData.roles}
                options={roleOptions}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          icon
          type="button"
          onClick={() => clearForm()}
          labelPosition="left"
          className="clearButton"
        >
          Clear
          <Icon name="eraser" className="clearButtonIconSize" />
        </Button>

        <Button
          icon
          type="submit"
          labelPosition="left"
          className="submitButton"
          disabled={loading}
          loading={loading}
        >
          Submit
          <Icon name="checkmark" className="iconSize" />
        </Button>
      </Modal.Actions>
    </Modal>,
  ];
}
