import React, { useState, useEffect, Component } from "react";
import {
  Segment,
  
  Breadcrumb,
  Message,

} from "semantic-ui-react";
import { loadAppModulePage, unloadAppModulePage } from "../../redux/action/appAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  apps:{
    app: {
      id: number;
      app_name: string;
      country: {
        id: number;
        country_name: string;
      };
      organization: {
        id: number;
        name: string;
      };
      project: {
        id: number;
        name: string;
        slug: string;
      };
      awareness_module: {
        id: number;
        name: string;
        description: string;
        type: string;
        slug: string;
      }[];
    }
  }
}

export default function AppInfo(props: Props) {
  const [apiUrl, setApiUrl] = useState(`/app/${props.match.params.id}`);
  const apps = useSelector((state: RootState) => state.apps);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setApiUrl(`/app/${props.match.params.id}`);
    dispatch(loadAppModulePage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAppModulePage());
    };
  }, [apiUrl, dispatch, props.match.params.id]);

  return [
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>App</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right arrow" />
        {apps === undefined || Object.keys(apps).length <= 0 ? (
          <Breadcrumb.Section active>
            {props.match.params.id}..
          </Breadcrumb.Section>
        ) : (
          <Breadcrumb.Section active>
            {apps.app ? apps.app.app_name : props.match.params.id}
          </Breadcrumb.Section>
        )}
      </Breadcrumb>
    </div>,
    <Segment key="2" padded textAlign="center">
      {/* <Header>App Info</Header>
      <Divider /> */}
      {apps === undefined || Object.keys(apps).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <Info apps={apps} />
      )}
    </Segment>,
  ];
}

class Info extends Component<RootState> {
  render() {
    const data = this.props.apps;
    if (!data || !data.app) {
      return <Message>Loading ....</Message>;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <h3>{data.app.app_name}</h3>
          <p>Country: {data.app.country.country_name}
          <span style={{marginLeft:'20px', marginRight: '20px'}}>Organization: {data.app.organization.name}</span>
          <span style={{marginLeft:'0', marginRight: '20px'}}>Project: {data.app.project?.name ?? '-'}</span>
          <span style={{marginRight: '20px'}}>Total Awareness Modules: {data.app.awareness_module.length}</span>
          </p>
          <hr />
          <br/>
          <h4>Awareness Modules</h4>
          
          <table className="table border table-striped col-sm-12" style={{marginTop:'20px'}}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Slug</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data.app.awareness_module) && data.app.awareness_module.length > 0 ? (
                data.app.awareness_module.map((i) => {
                  return (
                    <tr key={`awareness_module_children_${i.id}`}>
                      <td title={i.description}><Link to={"/awareness_module/"+i.id}>{i.name}</Link></td>
                      <td>{i.type}</td>
                      <td><i>{i.slug}</i></td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={4}>
                    NO DATA
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
      </div>
    );
  }
}
