import React, { ReactElement } from "react";

interface Props {
  data: number | boolean;
  label: string;
}

function CheckBoxComponent({ data, label }: Props): ReactElement {
  const ListItem = () => {
    if (data === 1 || data === true) {
      return <React.Fragment>Yes</React.Fragment>;
    } else {
      return <React.Fragment>No</React.Fragment>;
    }
  };

  return (
    <div className="rows" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        <ListItem />
      </div>
    </div>
  );
}

export default CheckBoxComponent;
