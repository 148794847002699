import React, { useState, useEffect, Component } from "react";
import {
  Segment,
  Header,
  Message,
} from "semantic-ui-react";
import {
  loadPlannerPage,
  unloadPlannerPage,

} from "../../redux/action/plannerAction";
import { useDispatch, useSelector } from "react-redux";


interface Props {
  match: {
    params: {
      id: number;
    };
  };
}

interface RootState {
  planners: {
    id: number;
    title: string;
    project_id: number;
    data: string;
    project?: {
      name: string;
    };
  };
}

export default function PlannerInfo(props: Props) {
  const [apiUrl] = useState(`/planner/info/${props.match.params.id}`);
  const planners = useSelector((state: RootState) => state.planners);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadPlannerPage(apiUrl));

    return () => {
      dispatch(unloadPlannerPage());
    };
  }, [apiUrl, dispatch]);

  return [
    <Segment key="2">
      <Header>
        Planner (Readonly)
        <div style={{ float: "right", fontSize: "0.7em", marginTop: "3px" }}>
          <p>
            <b>Title:</b> {planners.title ? planners.title + " /" : ''}{" "}
            {planners.project ? planners.project.name : ""}
          </p>
        </div>
      </Header>
      {planners === undefined || Object.keys(planners).length <= 0 ? (
        <Message>Loading ....</Message>
      ) : (
        <Info planners={planners} />
      )}
    </Segment>,
  ];
}

class Info extends Component<RootState> {
  render() {
    const data = this.props.planners;
    return (
      <div>
        <iframe
        title='Planner'
          p-data={data.data ? data.data : '{"data":[],"floors":1}'}
          style={{
            opacity: "0",
            animation: "fade 0.3s 2s ease forwards",
            background: "#323232",
            width: "100%",
            height: "77vh",
            border: "1px solid #eee"
          }}
          src={"/plan/index.html"}
        ></iframe>
      </div>
    );
  }
}
