import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const faqTypes = {
  FAQ_LOADED: "FAQ_LOADED",
  FAQ_UNLOADED: "FAQ_UNLOADED",
  ADD_FAQ: "ADD_FAQ",
  EDIT_FAQ: "EDIT_FAQ",
  DELETE_FAQ: "DELETE_FAQ",
};

export const loadFaqPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: faqTypes.FAQ_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadFaqPage = () => {
  return {
    type: faqTypes.FAQ_UNLOADED,
    payload: {},
  };
};

export const addFaq = (payload: object,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/faq", {...payload,extra}).then(
      (data) => {
        dispatch({
          type: faqTypes.ADD_FAQ,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editFaq = (payload: payloadProps,extra:object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/faq/update/${payload.id}`, {...payload,extra}).then(
      (data) => {
        dispatch({
          type: faqTypes.EDIT_FAQ,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeFaq = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/faq/delete/${id}`).then(
      (data) => {
        dispatch({
          type: faqTypes.DELETE_FAQ,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
