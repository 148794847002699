import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  List,
  Loader,
  Message,
  Modal,
  Segment,
  Select,
} from "semantic-ui-react";
import ModalComponent from "../../../components/Modal";
import { alertActions } from "../../../redux/action/alertAction";
import { fetchData, unloadData } from "../../../redux/action/allAction";
import { apiService } from "../../../services";
import _ from "lodash";

interface Props {
  history: any;
  match: any;
}
type OptionType = {
  key: string | number;
  value: string;
  text: string;
}[];
const typeOptions = [
  {
    key: "custom",
    value: "custom",
    text: "Custom",
  },
  {
    key: "daily",
    value: "daily",
    text: "Daily",
  },
  {
    key: "weekly",
    value: "weekly",
    text: "Weekly",
  },
  {
    key: "monthly",
    value: "monthly",
    text: "Monthly",
  },
];
export default function FormbuilderExcelReport(props: Props): ReactElement {
  const report = useSelector((state: any) => state.all_ind);
  const dispatch = useDispatch();
  const [fdata, setData] = useState({
    newData: {
      id: "",
      name: "",
      type: "custom",
      subtype: "",
      email: [],
    },
  });
  const [subTypeOptions, SetSubTypeOptions] = useState<OptionType>([]);
  const [emailOptions, SetEmailOptions] = useState<OptionType>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);

  useEffect(() => {
    dispatch(
      fetchData(`/formBuilder/reportgsheetList/${props.match.params.slug}`)
    );

    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, []);

  const clearForm = () => {
    setData({
      newData: {
        id: "",
        name: "",
        type: "custom",
        subtype: "",
        email: [],
      },
    });
  };

  const goBack = () => {
    props.history.goBack();
  };

  const deleteData = (id: string) => {
    //alert(id)
    setOpen(true);
    setDeleteId(id);
  };
  const handleAddItem = (event: any, data: any) => {
    // console.log({data})
    SetEmailOptions([
      ...emailOptions,
      { key: data.value, text: data.value, value: data.value },
    ]);
    // emailOptions.push({ key: data.value, text: data.value, value: data.value });
  };

  const handleChange = (event: any, result?: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    let name = result.name;
    //console.log({value})
    if (name === "type") {
      let final_data: any = [];
      if (value === "weekly") {
        final_data = [
          { key: "monday", value: "monday", text: "Monday" },
          { key: "tuesday", value: "tuesday", text: "Tuesday" },

          { key: "wednesday", value: "wednesday", text: "Wednesday" },
          { key: "thursday", value: "thursday", text: "Thursday" },
          { key: "friday", value: "friday", text: "Friday" },
          { key: "saturday", value: "saturday", text: "Saturday" },
          { key: "sunday", value: "sunday", text: "Sunday" },
        ];
      } else if (value === "monthly") {
        let number = 30;
        final_data = _.times(number, (index) => ({
          key: index + 1,
          text: `${index + 1}`,
          value: index + 1,
        }));

        final_data.push({
          key: "end",
          text: `end`,
          value: "End",
        });
      }
      SetSubTypeOptions(final_data);
    }

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    apiService
      .save(
        `/formBuilder/reportgsheet/${props.match.params.slug}
      `,
        fdata.newData
      )
      .then(
        (data) => {
          dispatch(
            fetchData(
              `/formBuilder/reportgsheetList/${props.match.params.slug}`
            )
          );
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );

    // if (fdata.newData.id === 0) dispatch(addOrganization(fdata.newData));
    // else dispatch(editOrganization(fdata.newData));

    clearForm();
    //dispatch(loadOrganizationPage(apiUrl));
  };

  const handleSync = (id: string) => {
    // /formBuilder/excel_upload_drive/

    apiService
      .getAll(
        `/formBuilder/excel_upload_drive/${id}
      `
      )
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const selectDataForEditing = (data: any) => {
    let report_data = data;

    setData({
      newData: {
        id: report_data!._id ?? "",
        name: report_data!.name,
        type: report_data!.type,
        subtype: report_data!.subtype,
        email: report_data!.email ?? [],
      },
    });

    let email_temp = report_data!.email.map((item: string) => {
      return { key: item, text: item, value: item };
    });
    SetEmailOptions(email_temp);
    let final_data: any = [];
    if (report_data!.type === "weekly") {
      final_data = [
        { key: "monday", value: "monday", text: "Monday" },
        { key: "tuesday", value: "tuesday", text: "Tuesday" },

        { key: "wednesday", value: "wednesday", text: "Wednesday" },
        { key: "thursday", value: "thursday", text: "Thursday" },
        { key: "friday", value: "friday", text: "Friday" },
        { key: "saturday", value: "saturday", text: "Saturday" },
        { key: "sunday", value: "sunday", text: "Sunday" },
      ];
    } else if (report_data!.type === "monthly") {
      let number = 30;
      final_data = _.times(number, (index) => ({
        key: index + 1,
        text: `${index + 1}`,
        value: `${index + 1}`,
      }));

      final_data.push({
        key: "end",
        text: `end`,
        value: "End",
      });
    }
    SetSubTypeOptions(final_data);
  };

  const confirmDelete = () => {
    // alert(deleteId)

    apiService
      ._delete(
        `/formBuilder/reportgsheet/${props.match.params.slug}/${deleteId}
        `
      )
      .then(
        (data) => {
          setOpen(false);
          dispatch(
            fetchData(
              `/formBuilder/reportgsheetList/${props.match.params.slug}`
            )
          );
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function RenderList(datas: any) {
    if (datas.data.report === undefined) {
      return (
        <List divided verticalAlign="middle">
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              {" "}
              <Message>Loading ....</Message>
            </List.Content>
          </List.Item>
        </List>
      );
    }

    if (datas.data.report.length === 0) {
      return (
        <List divided verticalAlign="middle">
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              {" "}
              <Message>No data Found</Message>
            </List.Content>
          </List.Item>
        </List>
      );
    }

    return datas.data.report.map((data: any, index: number) => {
      return (
        <List divided verticalAlign="middle" key={index}>
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              <Button
                circular
                hidden={data.type !== "custom"}
                className="formbuilder-buttons"
                style={{
                  backgroundColor: "white",
                  color: "red",
                }}
                icon
                onClick={() => handleSync(data._id ?? "")}
              >
                <Icon name="refresh" />
              </Button>
              <Button
                onClick={() => {
                  selectDataForEditing(data);
                  setOpenInfo(true);
                  setCreating(false);
                }}
                icon
                circular
                className="editButton"
              >
                <Icon name="pencil" />
              </Button>

              <Button
                onClick={() => deleteData(data._id ?? "")}
                icon
                circular
                className="deleteButton"
              >
                <Icon name="trash" />
              </Button>
            </List.Content>

            <List.Content>
              <List.Header className="text-uppercase">
                <a target="_blank" href={data.link ?? ""} rel="noopener noreferrer">
                  {data.name}
                </a>
              </List.Header>
              <List.Description>{data.type ?? ""}</List.Description>
              <List.Description>
                {data.last_sync
                  ? `Last Synced On  ${data.last_sync ?? ""}`
                  : ""}
              </List.Description>
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>

          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            Formbuilder G-Sheet Report List
          </Breadcrumb.Section>
        </Breadcrumb>
        <ModalComponent
          open={open}
          setOpen={setOpen}
          confirmDelete={confirmDelete}
          strict={true}
        />
        <div></div>
      </div>

      <div className="app-card-org">
        <Button
          className="createButton"
          icon
          labelPosition="left"
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
        >
          Create G-Sheet Report
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>Formbuilder G-Sheet Report List</Header>
        {report && <RenderList data={report} />}

        {report.report === undefined ? (
          <Loader active inverted size="big" />
        ) : (
          ""
        )}
      </div>

      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="tiny"
        className="modal-customized"
      >
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} G-Sheet Report
        </Modal.Header>
        <Modal.Content>
          <Form.Field>
            <label>Name</label>
            <Form.Input
              fluid
              required
              type="text"
              name="name"
              value={fdata.newData.name}
              onChange={handleChange}
              placeholder="Enter  name..."
            />
          </Form.Field>

          <Form.Field>
            <label>Type</label>
            <Select
              fluid
              placeholder="Select type"
              options={typeOptions}
              label=" type"
              value={fdata.newData.type}
              name="type"
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <label
              hidden={
                fdata.newData.type === "custom" ||
                fdata.newData.type === "daily"
              }
            >
              Every
            </label>
            <Form.Select
              fluid
              hidden={
                fdata.newData.type === "custom" ||
                fdata.newData.type === "daily"
              }
              required
              search
              clearable
              selection
              options={subTypeOptions}
              value={fdata.newData.subtype}
              name="subtype"
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <label>Email</label>
            <Dropdown
              search
              selection
              multiple
              value={fdata.newData.email}
              name="email"
              onChange={handleChange}
              allowAdditions
              onAddItem={handleAddItem}
              options={emailOptions}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
}
