import { moduleLessonTypes } from "../action/moduleLessonAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case moduleLessonTypes.MODULE_LESSON_LOADED:

            return action.payload;

        case moduleLessonTypes.MODULE_LESSON_UNLOADED:
            return initialState;
        case moduleLessonTypes.ADD_MODULE_LESSON:
           
             return { ...state};
        case moduleLessonTypes.EDIT_MODULE_LESSON:

            return state;
        case moduleLessonTypes.DELETE_MODULE_LESSON:
           
            return state;

        default:
            return state;
    }
};
