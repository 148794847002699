import React, { useEffect, useRef, useState } from "react";
import { AppConfig } from "../../config";
import {
  fetchFormdata,
  unloadFormdataPage,
} from "../../redux/action/formDataAction";
import { useDispatch, useSelector } from "react-redux";

import ReactDOM from "react-dom";
import {
  Header,
  Button,
  Checkbox,
  Segment,
  Popup as SPopup,
} from "semantic-ui-react";
import { apiService } from "../../services";
import { alertActions } from "../../redux/action/alertAction";
import { useHistory } from "react-router-dom";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import MarkerPopup from "../../components/Modal/MarkerPopup";
import ModalComponent from "../../components/Modal";
import SimpleTable from "./SimpleTable";

interface UserType {
  access_token: string;
  role: string;
}

export default function DataListWithSimpleTable(props: {
  slug: string;
  id: any;
  status?: string;
  filter_by_status?: boolean;
  hideStepForms?: boolean;
  status_field?: string;
  setFormName?: any;
}) {
  const filteringStatus =
    (props.filter_by_status ? props.status : null) ?? null;
  const status_field = props.status_field ?? "status";

  const history = useHistory();

  const [selected, setSelected] = useState<string[]>([]);
  const [access_token, setAccess_token] = useState<string>("");

  const [visibility, setVisibility] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [latitude, setLatitude] = useState(27.7047954);
  const [longitude, setLongitude] = useState(85.3042305);
  const [dataId, setDataId] = useState("");

  const [form, setForm] = useState<any>(null);

  const [hideMap, setHideMap] = useState(false);
  const [fullScreenMap, setFullScreenMap] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [isOpenBulkDeleteConfirmModal, setIsOpenBulkDeleteConfirmModal] =
    useState(false);

  const [isBulkDeleting, setIsBulkDeleting] = useState(false);

  const dispatch = useDispatch();
  const inputEl = useRef<HTMLTableElement>(null);

  // show hide map handler
  const showHidemapHandler = () => {
    if (hideMap) {
      setHideMap(false);
    } else {
      setHideMap(true);
    }
  };

  // full screen map handler
  const fullScreenMapHandler = () => {
    if (fullScreenMap) {
      setFullScreenMap(false);
    } else {
      setFullScreenMap(true);
    }
  };

  useEffect(() => {
    if (form && form.form && form.form.name) {
      props.setFormName(form.form.name);
    }
  }, [form]);

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let user_info: UserType = JSON.parse(user);
    setAccess_token(user_info ? user_info.access_token : "");
    if (user_info !== undefined) {
      if (user_info.role === "User") {
        setVisibility(true);
      }
    }

    if (props.slug) {
      GetReport(props.slug);

      dispatch(
        fetchFormdata(
          `/formBuilder/data/${props.slug}` +
            (filteringStatus
              ? `?status=${props.status ?? ""}&status_field=${status_field}`
              : "")
        )
      );
    }

    return () => {
      dispatch(unloadFormdataPage());
    };
    // eslint-disable-next-line
  }, []);

  const DeactivateHandler = () => {
    apiService
      .save(`/formData/deactivate`, { toDelete: selected, slug: props.slug })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          setSelected([]);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const PreDeleteHandler = () => {
    setIsOpenBulkDeleteConfirmModal(true);
  };

  const DeleteHandler = () => {
    setIsBulkDeleting(true);

    apiService
      .save1(`/formData/${props.slug}/records/delete`, { ids: selected })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          setIsOpenBulkDeleteConfirmModal(false);
          setIsBulkDeleting(false);
          setSelected([]);
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          setIsOpenBulkDeleteConfirmModal(false);
          setIsBulkDeleting(false);
        }
      );
  };

  const EditHandler = () => {
    if (selected.length === 1) {
      history.push(`/formbuilder/data/${props.slug}/edit/${selected.shift()}`);
    } else {
      dispatch(
        alertActions.error(
          "To edit atleast and only one data have to be selected"
        )
      );
    }
  };

  const QuickViewHandler = () => {
    if (selected.length === 1) {
      let id = selected.shift();
      if (id !== undefined) {
        setDataId(id);
        setOpenModal(true);
      }
    } else {
      dispatch(
        alertActions.error(
          "To Quick view atleast and only one data have to be selected"
        )
      );
    }
  };

  const GetReport = (data: string) => {
    if (data !== "") {
      apiService
        .save1(
          `/GetLocation/${data}` +
            (filteringStatus
              ? `?status=${props.status ?? ""}&status_field=${status_field}`
              : ""),
          {}
        )
        .then(
          (data) => {
            if (data.data !== "undefined") {
              setFilteredData(data.data);

              if (data.data[0] !== "undefined") {
                let geo = data.data[0] || null;

                if (geo) {
                  setLatitude(geo._latitude === "-" ? 0 : geo._latitude || 0);
                  setLongitude(
                    geo._longitude === "-" ? 0 : geo._longitude || 0
                  );
                } else {
                  setLatitude(0);
                  setLongitude(0);
                }
              }
            }
          },

          (error) => {
            dispatch(alertActions.error(error.toString()));
          }
        );
    }
  };

  const relatedForm = () => {
    if (selected.length === 1) {
      history.push(`/formbuilder/fill/${props.slug}`, { id: selected.shift() });
    } else {
      dispatch(
        alertActions.error(
          "To edit atleast and only one data have to be selected"
        )
      );
    }
  };

  return (
    <div className="app-card-org">
      <ModalComponent
        open={isOpenBulkDeleteConfirmModal}
        setOpen={setIsOpenBulkDeleteConfirmModal}
        confirmDelete={DeleteHandler}
        strict={true}
        loading={isBulkDeleting}
        text="Permanently delete all selected records?"
        strict_text="This will permanently delete all selected records and cannot be recovered?"
      />
      <div key={2}>
        <div>
          {hideMap ? (
            <SPopup
              content="Show Map"
              trigger={<Button icon="unhide" onClick={showHidemapHandler} />}
            />
          ) : (
            <SPopup
              content="Hide Map"
              trigger={<Button icon="hide" onClick={showHidemapHandler} />}
            />
          )}

          <SPopup
            content={
              fullScreenMap ? "Click For Half Screen" : "Click for Full Screen"
            }
            trigger={<Button icon="map" onClick={fullScreenMapHandler} />}
          />
        </div>
      </div>
      <Segment
        key={3}
        style={{
          display: hideMap ? "none" : "block",
        }}
      >
        <Map
          style={{
            height: fullScreenMap ? "600px" : "250px",
            zIndex: 99,
            display: hideMap ? "none" : "block",
          }}
          center={[latitude, longitude]}
          zoom={5}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {filteredData.map(
              (
                item: {
                  _latitude: any;
                  _longitude: any;
                  _id: { $oid: string };
                },
                index: number
              ) => (
                <Marker
                  key={index}
                  position={[
                    item._latitude === "-" ? 0 : item._latitude || 0,
                    item._longitude === "-" ? 0 : item._longitude || 0,
                  ]}
                  onClick={() => {
                    setDataId(item._id.$oid || "");
                    setOpenModal(true);
                  }}
                ></Marker>
              )
            )}
          </MarkerClusterGroup>
        </Map>
      </Segment>

      <MarkerPopup
        openModal={openModal}
        formId={props.slug}
        dataId={dataId}
        setOpenModal={setOpenModal}
      />
      <Header style={{ marginTop: "5px" }}>
        {selected.length > 0 ? (
          <>
            <Button
              className="formbuilder-buttons"
              style={{
                backgroundColor: "white",
                color: "var(--color-black)",
              }}
              disabled={visibility}
              onClick={DeactivateHandler}
            >
              Deactivate ({selected.length})
            </Button>

            <Button
              className="formbuilder-buttons deleteButton"
              disabled={visibility}
              onClick={PreDeleteHandler}
            >
              Delete ({selected.length})
            </Button>
          </>
        ) : (
          ""
        )}

        <Button
          className="formbuilder-buttons"
          style={{
            backgroundColor: "white",
            color: "var(--color-blue)",
          }}
          disabled={visibility}
          onClick={EditHandler}
        >
          {" "}
          Edit
        </Button>

        <Button
          hidden={form === null || form?.related === false}
          color="green"
          onClick={relatedForm}
        >
          Fill Related form
        </Button>
        <Button
          className="formbuilder-buttons"
          style={{
            backgroundColor: "white",
            color: "var(--color-green)",
          }}
          onClick={QuickViewHandler}
        >
          {" "}
          Quick View
        </Button>
      </Header>

      <SimpleTable slug={props.slug} setForm={setForm} hideFormTitle={false} />
    </div>
  );
}
