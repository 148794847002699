import { siteSafetyTypes } from "../action/siteSafetyAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case siteSafetyTypes.SITE_SAFETY_LOADED:
      return action.payload;

    case siteSafetyTypes.SITE_SAFETY_UNLOADED:
      return initialState;
    case siteSafetyTypes.ADD_SITE_SAFETY:
      return { ...state };
    case siteSafetyTypes.EDIT_SITE_SAFETY:
      return state;
    case siteSafetyTypes.DELETE_SITE_SAFETY:
      return state;

    default:
      return state;
  }
};
