import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import languageEN from "../language/en/translate.json";
import languageJP from "../language/jp/translate.json";
import languageSP from "../language/spa/translate.json";

const data: any = {
  en: languageEN,
  jp: languageJP,
  spa: languageSP,
};

const Context = React.createContext<any>("");

interface Props {
  children: any;
}

const IntlProviderWrapper = (props: Props) => {
  const [locale, setlocale] = useState("spa");
  const [messages, setmessages] = useState<any>(languageEN);

  const switchLang = (val: string) => {
    setlocale(val);
    setmessages(data[val]);
  };

  return (
    <Context.Provider value={{ switchLang }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
        onError={(err) => {
          if (process.env.NODE_ENV !== "production") {
            console.info("🌐" + err.message);
          }
        }}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export { IntlProviderWrapper, Context as IntlContext };
