import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const siteSafetyTypes = {
  SITE_SAFETY_LOADED: "SITE_SAFETY_LOADED",
  SITE_SAFETY_UNLOADED: "SITE_SAFETY_UNLOADED",
  ADD_SITE_SAFETY: "ADD_SITE_SAFETY",
  EDIT_SITE_SAFETY: "EDIT_SITE_SAFETY",
  DELETE_SITE_SAFETY: "DELETE_SITE_SAFETY",
};

export const loadSiteSafetyPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: siteSafetyTypes.SITE_SAFETY_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadSiteSafetyPage = () => {
  return {
    type: siteSafetyTypes.SITE_SAFETY_UNLOADED,
    payload: {},
  };
};

export const addSiteSafety = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/site_safety", payload).then(
      (data) => {
        dispatch({
          type: siteSafetyTypes.ADD_SITE_SAFETY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editSiteSafety = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/site_safety/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: siteSafetyTypes.EDIT_SITE_SAFETY,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeSiteSafety = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/site_safety/delete/${id}`).then(
      (data) => {
        dispatch({
          type: siteSafetyTypes.DELETE_SITE_SAFETY,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
