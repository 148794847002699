import React, { ReactElement } from "react";

interface Props {
  data: any[];
  label: string;
}

function ResourceComponent({ data, label }: Props): ReactElement {
  const ListItem = () => (
    <React.Fragment>
      {Object.keys(data).map((key, index) => {
        if (key !== "id") {
          return (
            <React.Fragment key={index}>
              <strong style={{ textTransform: "capitalize" }}>
                {key.split(/(?:[a-z])(?=[A-Z])/)}
              </strong>
              : {data[key]} <br />
            </React.Fragment>
          );
        } else {
          return null;
        }
      })}{" "}
    </React.Fragment>
  );

  return (
    <div className="rows" key={label}>
      <div className="row-head">{label}</div>
      <div className="row-body">
        <ListItem />
      </div>
    </div>
  );
}

export default ResourceComponent;
