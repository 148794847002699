import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const awarenessTscTypes = {
  AWARENESS_TSC_LOADED: "AWARENESS_TSC_LOADED",
  AWARENESS_TSC_UNLOADED: "AWARENESS_TSC_UNLOADED",
  ADD_AWARENESS_TSC: "ADD_AWARENESS_TSC",
  EDIT_AWARENESS_TSC: "EDIT_AWARENESS_TSC",
  DELETE_AWARENESS_TSC: "DELETE_AWARENESS_TSC",
};

export const loadAwarenessTscPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: awarenessTscTypes.AWARENESS_TSC_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAwarenessTscPage = () => {
  return {
    type: awarenessTscTypes.AWARENESS_TSC_UNLOADED,
    payload: {},
  };
};

export const addAwarenessTsc = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/awareness_tsc", payload).then(
      (data) => {
        dispatch({
          type: awarenessTscTypes.ADD_AWARENESS_TSC,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAwarenessTsc = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/awareness_tsc/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: awarenessTscTypes.EDIT_AWARENESS_TSC,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAwarenessTsc = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/awareness_tsc/delete/${id}`).then(
      (data) => {
        dispatch({
          type: awarenessTscTypes.DELETE_AWARENESS_TSC,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
