import { organizationTypes } from "../action/organizationAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
   
    switch (action.type) {
        case organizationTypes.ORGANIZATION_PAGE_LOADED:

            return action.payload;

        case organizationTypes.ORGANIZATION_PAGE_UNLOADED:
            return [];
        case organizationTypes.ADD_ORGANIZATION:
           
             return { ...state};
        case organizationTypes.EDIT_ORGANIZATION:

            return state;
        case organizationTypes.DELETE_ORGANIZATION:
           
            return state;

        default:
            return state;
    }
};
