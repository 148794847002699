import React from 'react'
import UserForm from './UserForm'



export default function UserCreate() {
    
    return (
        <UserForm type="Create" />
    )
}
