import { projectTypes } from "../action/projectAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case projectTypes.PROJECT_PAGE_LOADED:

            return action.payload;

        case projectTypes.PROJECT_PAGE_UNLOADED:
            return initialState;
        case projectTypes.ADD_PROJECT:
           
             return { ...state};
        case projectTypes.EDIT_PROJECT:

            return state;
        case projectTypes.DELETE_PROJECT:
           
            return state;

        default:
            return state;
    }
};
