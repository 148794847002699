import { houseImageTypes } from "../action/houseImageAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case houseImageTypes.HOUSE_IMAGE_LOADED:
      return action.payload;

    case houseImageTypes.HOUSE_IMAGE_UNLOADED:
      return initialState;
    case houseImageTypes.ADD_HOUSE_IMAGE:
      return { ...state };
    case houseImageTypes.EDIT_HOUSE_IMAGE:
      return state;
    case houseImageTypes.DELETE_HOUSE_IMAGE:
      return state;

    default:
      return state;
  }
};
