import { libraryTypes } from "../action/libraryAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case libraryTypes.LIBRARY_LOADED:

            return action.payload;

        case libraryTypes.LIBRARY_UNLOADED:
            return initialState;
        case libraryTypes.ADD_LIBRARY:
           
             return { ...state};
        case libraryTypes.EDIT_LIBRARY:

            return state;
        case libraryTypes.DELETE_LIBRARY:
           
            return state;

        default:
            return state;
    }
};
