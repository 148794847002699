import React, { useEffect, useState } from 'react'
import { Segment, Breadcrumb, Header, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, unloadData } from '../../redux/action/allAction';
import { Formio } from '@formio/react';
import { FormattedMessage } from 'react-intl'
import { addFormdata } from '../../redux/action/formDataAction';
import { AppConfig } from '../../config';
import DataListDuplicate from './DataListDuplicate';



export default function StepDetailDuplicate(props: { match: { params: { id: number; }; },history:any }) {

    const [type, setType] = useState('')
    const step_details = useSelector((state:any) => state.all_ind)
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchStep(props.match.params.id,props.match.params.slug))
        dispatch(fetchData(`/step/edit/${props.match.params.id}`))


        return () => {
            dispatch(unloadData())
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // dispatch(fetchStep(props.match.params.id,props.match.params.slug))
        if (step_details != null) {
            setType(step_details.type);
            if (step_details.type === 'form') {
                formio();
            } else if (step_details.type === 'details') {
                //fo
            } else {
                //
            }
        }



        // eslint-disable-next-line
    }, [step_details])

   

    const formio = () => {
        if (step_details && step_details.form_link) {
            Formio.createForm(document.getElementById('formio'), `${AppConfig.apiUrl}/formBuilders/${step_details.form_link}`,
            {
                draft:true
            }
            )
                .then(function (form: { nosubmit: boolean; on: (arg0: string, arg1: (submission: any) => void) => void; }) {
                    // Prevent the submission from going to the form.io server.
                    form.nosubmit = true;
    
                    // Triggered when they click the submit button.
                    form.on('submit', function (submission) {
                        console.log(submission);
                        dispatch(addFormdata(step_details.form_link, submission));
                        formio();
                        //alert('Submission sent to custom endpoint. See developer console.');
    
                    });
                });
        }
    }
   

    return [
        <Segment key='1'>
            <Breadcrumb>
               <Breadcrumb.Section link as={Link} to="/">
                    <FormattedMessage
                        id="Home"
                        defaultMessage="Home"
                    />
                    </Breadcrumb.Section>
                    

                <Breadcrumb.Divider icon='right arrow' />
                <Breadcrumb.Section active>{step_details && step_details.name}</Breadcrumb.Section>
            </Breadcrumb>

           {step_details.form_link && <Button color='vk' as={Link} to={`/formbuilder/draft/${step_details.form_link}`} floated='right'>
      <Icon name='vk' /> Draft Form
    </Button> } 
        </Segment>,
        <Segment key='2'>

            <Header>{step_details && step_details.name} ({step_details.type}) <p style={{textAlign: "center", color: "orange"}} id="duplicate-or-not"></p> </Header>
            <div id="formio"></div>
            {(type==='details' )?(
            <DataListDuplicate  slug={step_details.data_link} id={props.match.params.id}/>
            ):''
            }

        </Segment>

    ]
}
