import { moduleTypes } from "../action/moduleAction";


const initialState:Array<any> = [];
export default (state = initialState, action:any) => {
    switch (action.type) {
        case moduleTypes.MODULE_PAGE_LOADED:

            return action.payload;

        case moduleTypes.MODULE_PAGE_UNLOADED:
            return initialState;
        case moduleTypes.ADD_MODULE:
           
             return { ...state};
        case moduleTypes.EDIT_MODULE:

            return state;
        case moduleTypes.DELETE_MODULE:
           
            return state;

        default:
            return state;
    }
};
