import React, { ReactElement, useState } from "react";
import {
  Modal,
  Header,
  Button,
  Icon,
  ButtonProps,
  Image,
  Input,
  Message,
  Loader
} from "semantic-ui-react";

interface Props {
  setOpen: Function;
  task?: string;
  open: boolean;
  text?:string;
  strict_text?: string;
  strict?: boolean;
  loading?: boolean;
  confirmDelete?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => void;
}

interface ImageModalProps {
  setUrl: Function;
  url: string;
}

interface MultipleImageModalProps {
  setUrlArray: Function;
  urlArray: string[];
}

interface AlertModalProps {
  setAlertMessage: Function;
  alertMessageHeader: string;
  alertMessage: string;
  type?: string;
}

export default function ModalComponent(props: Props): ReactElement {
  const handleCancel = () => {
    props.setOpen(false);
  };
  const loading = props.loading || false;
  const [confirm, setConfirm] = useState(process.env.NODE_ENV != "production" ? "CONFIRM" : "");
  

  return (
    
    <Modal
      open={props.open}
      size="tiny"
      onClose={handleCancel}
      centered={true}
      style={{ position: "relative" }}
    >
      <Loader active disabled={!loading} inverted size="big" />
      <Header
        icon="archive"
        content={`${
          props.task
            ? props.task === "Reset"
              ? props.task + " All"
              : props.task + " Item"
            : "Delete Item"
        }`}
      />
      {props.strict ? (
        <Modal.Content>
          {props.strict_text ? <p style={{color: 'red', marginBottom: '5px', fontSize: '0.9em'}}>{props.strict_text}</p> : ''}
          <p>Are you sure? Write CONFIRM below:</p>
          <Input
            style={{ width: "100%", opacity: "0.8" }}
            onChange={(e) => setConfirm(e.target.value)}
            value={confirm}
            placeholder="Write CONFIRM"
          />
        </Modal.Content>
      ) : (
        <Modal.Content>
          {props.text?(<p>{props.text}</p>):props.task === "Reset" ? (
            <p>Are you sure you want to reset points of the user?</p>
          ) : (
            <p>Are you sure?</p>
          )}
        </Modal.Content>
      )}

      <Modal.Actions>
        <Button className="clearButton" onClick={handleCancel}>
          <Icon name="trash" /> Cancel
        </Button>
        <Button
          className="submitButton"
          disabled={(props.strict && confirm !== "CONFIRM") || loading}
          loading={loading}
          onClick={()=>{
            //@ts-ignore
            props.confirmDelete();
            // process.env.NODE_ENV != "production" ? "CONFIRM" : "";
            if(process.env.NODE_ENV === "production"){
              setConfirm("");
            }
            
          } }
        >
          <Icon name="checkmark" /> Yes {props.task || "Delete"} It!
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export function ImageModalComponent(props: ImageModalProps): ReactElement {
  // console.log('Here too');
  const handleCancel = () => {
    if (typeof props.setUrl == "function") {
      props.setUrl("");
    }
  };

  return (
    <Modal
      open={props.url ? true : false}
      basic
      size="small"
      onClose={handleCancel}
      centered={true}
      style={{ position: "relative" }}
    >
      <Modal.Content image>
        <Image wrapped src={props.url} alt="Failed to Load Image" />
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" inverted onClick={handleCancel}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export function MultipleImageModalComponent(
  props: MultipleImageModalProps
): ReactElement {
  console.log(props.urlArray);
  const handleCancel = () => {
    if (typeof props.setUrlArray == "function") {
      props.setUrlArray([]);
    }
  };

  return (
    <Modal
      open={props.urlArray.length > 0 ? true : false}
      size="large"
      onClose={handleCancel}
      centered={true}
      style={{ position: "relative" }}
    >
      <Modal.Header>
        Photos Uploaded By User{" "}
        <Icon name="hand point down outline" size={"large"} />
      </Modal.Header>
      <Modal.Content>
        {props.urlArray.map((imageUrl: string) => {
          return (
            <div>
              <Image
                wrapped
                src={imageUrl}
                alt="Failed to Load Image"
                style={{ display: "block" }}
              />{" "}
              <br />
            </div>
          );
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" inverted onClick={handleCancel}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export function AlertModalComponent(props: AlertModalProps): ReactElement {
  const handleCancel = () => {
    if (typeof props.setAlertMessage == "function") {
      props.setAlertMessage("");
    }
  };

  return (
    <Modal
      open={props.alertMessage ? true : false}
      size="small"
      onClose={handleCancel}
      centered={true}
      style={{ position: "relative" }}
    >
      <Modal.Header>{props.alertMessageHeader}</Modal.Header>
      <Modal.Content>
        {props.type === "positive" ? (<Message positive> 
          <Message.Header><Icon name='check circle' /> {props.alertMessage}</Message.Header>
        </Message>)
        : 
        (<Message negative> 
          <Message.Header>{props.alertMessage}</Message.Header>
        </Message>)
      }
        
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" inverted onClick={handleCancel}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
