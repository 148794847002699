import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const awarenessModuleTypes = {
  AWARENESS_MODULE_LOADED: "AWARENESS_MODULE_LOADED",
  AWARENESS_MODULE_UNLOADED: "AWARENESS_MODULE_UNLOADED",
  ADD_AWARENESS_MODULE: "ADD_AWARENESS_MODULE",
  EDIT_AWARENESS_MODULE: "EDIT_AWARENESS_MODULE",
  DELETE_AWARENESS_MODULE: "DELETE_AWARENESS_MODULE",
};

export const loadAwarenessModulePage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: awarenessModuleTypes.AWARENESS_MODULE_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadAwarenessModulePage = () => {
  return {
    type: awarenessModuleTypes.AWARENESS_MODULE_UNLOADED,
    payload: {},
  };
};

export const addAwarenessModule = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/awareness_module", payload).then(
      (data) => {
        dispatch({
          type: awarenessModuleTypes.ADD_AWARENESS_MODULE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editAwarenessModule = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/awareness_module/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: awarenessModuleTypes.EDIT_AWARENESS_MODULE,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeAwarenessModule = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/awareness_module/delete/${id}`).then(
      (data) => {
        dispatch({
          type: awarenessModuleTypes.DELETE_AWARENESS_MODULE,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
