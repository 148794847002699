export const languages: {
  [key: string]: string;
} = {
  af: "Afrikaans",
  sq: "Albanian",
  "ar-DZ": "Arabic (Algeria)",
  "ar-BH": "Arabic (Bahrain)",
  "ar-EG": "Arabic (Egypt)",
  "ar-IQ": "Arabic (Iraq)",
  "ar-JO": "Arabic (Jordan)",
  "ar-KW": "Arabic (Kuwait)",
  "ar-LB": "Arabic (Lebanon)",
  "ar-LY": "Arabic (Libya)",
  "ar-MA": "Arabic (Morocco)",
  "ar-OM": "Arabic (Oman)",
  "ar-QA": "Arabic (Qatar)",
  "ar-SA": "Arabic (Saudi Arabia)",
  ar: "Arabic (Standard)",
  "ar-SY": "Arabic (Syria)",
  "ar-TN": "Arabic (Tunisia)",
  "ar-AE": "Arabic (U.A.E.)",
  "ar-YE": "Arabic (Yemen)",
  an: "Aragonese",
  hy: "Armenian",
  as: "Assamese",
  ast: "Asturian",
  az: "Azerbaijani",
  eu: "Basque",
  be: "Belarusian",
  bn: "Bengali",
  bs: "Bosnian",
  br: "Breton",
  bg: "Bulgarian",
  my: "Burmese",
  ca: "Catalan",
  ch: "Chamorro",
  ce: "Chechen",
  ceb: "Cebuano (Bisaya)",
  zh: "Chinese",
  "zh-HK": "Chinese (Hong Kong)",
  "zh-CN": "Chinese (PRC)",
  "zh-SG": "Chinese (Singapore)",
  "zh-TW": "Chinese (Taiwan)",
  cv: "Chuvash",
  co: "Corsican",
  cr: "Cree",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  "nl-BE": "Dutch (Belgian)",
  nl: "Dutch (Standard)",
  en: "English",
  "en-AU": "English (Australia)",
  "en-BZ": "English (Belize)",
  "en-CA": "English (Canada)",
  "en-IE": "English (Ireland)",
  "en-JM": "English (Jamaica)",
  "en-NZ": "English (New Zealand)",
  "en-PH": "English (Philippines)",
  "en-ZA": "English (South Africa)",
  "en-TT": "English (Trinidad & Tobago)",
  "en-GB": "English (United Kingdom)",
  "en-US": "English (United States)",
  "en-ZW": "English (Zimbabwe)",
  eo: "Esperanto",
  et: "Estonian",
  fo: "Faeroese",
  fj: "Fijian",
  fi: "Finnish",
  fil: "Filipino",
  "fr-BE": "French (Belgium)",
  "fr-CA": "French (Canada)",
  "fr-FR": "French (France)",
  "fr-LU": "French (Luxembourg)",
  "fr-MC": "French (Monaco)",
  fr: "French (Standard)",
  "fr-CH": "French (Switzerland)",
  fy: "Frisian",
  fur: "Friulian",
  mk: "FYRO Macedonian",
  "gd-IE": "Gaelic (Irish)",
  gl: "Galacian",
  ka: "Georgian",
  "de-AT": "German (Austria)",
  "de-DE": "German (Germany)",
  "de-LI": "German (Liechtenstein)",
  "de-LU": "German (Luxembourg)",
  de: "German (Standard)",
  "de-CH": "German (Switzerland)",
  el: "Greek",
  gu: "Gujurati",
  ht: "Haitian",
  he: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  iu: "Inuktitut",
  ga: "Irish",
  it: "Italian (Standard)",
  "it-CH": "Italian (Switzerland)",
  ja: "Japanese",
  kn: "Kannada",
  ks: "Kashmiri",
  kk: "Kazakh",
  km: "Khmer",
  ky: "Kirghiz",
  tlh: "Klingon",
  ko: "Korean",
  "ko-KP": "Korean (North Korea)",
  "ko-KR": "Korean (South Korea)",
  la: "Latin",
  lv: "Latvian",
  lt: "Lithuanian",
  lb: "Luxembourgish",
  ms: "Malay",
  ml: "Malayalam",
  mt: "Maltese",
  mi: "Maori",
  mr: "Marathi",
  mo: "Moldavian",
  nv: "Navajo",
  ng: "Ndonga",
  ne: "Nepali",
  no: "Norwegian",
  nb: "Norwegian (Bokmal)",
  nn: "Norwegian (Nynorsk)",
  oc: "Occitan",
  or: "Oriya",
  om: "Oromo",
  fa: "Persian/Farsi",
  "fa-IR": "Persian/Iran",
  pl: "Polish",
  pt: "Portuguese",
  "pt-BR": "Portuguese (Brazil)",
  pa: "Punjabi",
  "pa-IN": "Punjabi (India)",
  "pa-PK": "Punjabi (Pakistan)",
  qu: "Quechua",
  rm: "Rhaeto-Romanic",
  ro: "Romanian",
  "ro-MO": "Romanian (Moldavia)",
  ru: "Russian",
  "ru-MO": "Russian (Moldavia)",
  sz: "Sami (Lappish)",
  sg: "Sango",
  sa: "Sanskrit",
  sc: "Sardinian",
  gd: "Scots Gaelic",
  sr: "Serbian",
  sd: "Sindhi",
  si: "Singhalese",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somani",
  sb: "Sorbian",
  es: "Spanish",
  "es-AR": "Spanish (Argentina)",
  "es-BO": "Spanish (Bolivia)",
  "es-CL": "Spanish (Chile)",
  "es-CO": "Spanish (Colombia)",
  "es-CR": "Spanish (Costa Rica)",
  "es-DO": "Spanish (Dominican Republic)",
  "es-EC": "Spanish (Ecuador)",
  "es-SV": "Spanish (El Salvador)",
  "es-GT": "Spanish (Guatemala)",
  "es-HN": "Spanish (Honduras)",
  "es-MX": "Spanish (Mexico)",
  "es-NI": "Spanish (Nicaragua)",
  "es-PA": "Spanish (Panama)",
  "es-PY": "Spanish (Paraguay)",
  "es-PE": "Spanish (Peru)",
  "es-PR": "Spanish (Puerto Rico)",
  "es-ES": "Spanish (Spain)",
  "es-UY": "Spanish (Uruguay)",
  "es-VE": "Spanish (Venezuela)",
  sx: "Sutu",
  sw: "Swahili",
  sv: "Swedish",
  "sv-FI": "Swedish (Finland)",
  "sv-SV": "Swedish (Sweden)",
  ta: "Tamil",
  tt: "Tatar",
  te: "Teluga",
  th: "Thai",
  tig: "Tigre",
  ts: "Tsonga",
  tn: "Tswana",
  tr: "Turkish",
  tk: "Turkmen",
  uk: "Ukrainian",
  hsb: "Upper Sorbian",
  ur: "Urdu",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapuk",
  wa: "Walloon",
  cy: "Welsh",
  xh: "Xhosa",
  ji: "Yiddish",
  zu: "Zulu",
};

export const getLanguageName = (
  code: string,
  defaultName: string | null = null
) => {
  return languages[code] ?? defaultName;
};
