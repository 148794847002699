import { contactTypes } from "../action/contactAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case contactTypes.CONTACT_LOADED:
      return action.payload;

    case contactTypes.CONTACT_UNLOADED:
      return initialState;
    case contactTypes.ADD_CONTACT:
      return { ...state };
    case contactTypes.EDIT_CONTACT:
      return state;
    case contactTypes.DELETE_CONTACT:
      return state;

    default:
      return state;
  }
};
