import React, { useState, useEffect } from "react";
import {
  Pagination,
  Table,
  Icon,
  Button,
  
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  Modal,
  Input,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import {
  loadAboutUsPage,
  unloadAboutUsPage,
  addAboutUs,
  editAboutUs,
  removeAboutUs,
} from "../../redux/action/aboutUsAction";
import { Link } from "react-router-dom";
import ModalComponent from "../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../redux/action/alertAction";
interface RootState {
  about_us: {
    about_us: {
      data: {
        title: string;
        title_lang: string;
        description: string;
        description_lang: string;
        id: number;
        app_id: number;
      }[];
      last_page: number;
    };
    apps: {
      id: number;
      app_name: string;
    }[];
  };
}

type appOptionType = {
  key: string | number;
  value: number;
  text: string;
}[];

export default function AboutUs() {
  const [fdata, setData] = useState({
    newData: {
      id: 0,
      title: "",
      title_lang: "",
      description: "",
      description_lang: "",
      app_id: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [creating, setCreating] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [apiUrl, setApiUrl] = useState("/aboutus");
  const [search, setSearch] = useState<string>("");
  const about_us = useSelector((state: RootState) => state.about_us);
  const [total, setTotal] = useState(1);
  const [appOptions, setAppOptions] = useState<appOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [loading, setLoading]: any = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(loadAboutUsPage(apiUrl, () => { 
      setLoading(false);
    }));

    return () => {
      dispatch(unloadAboutUsPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (about_us.about_us !== undefined) {
      setTotal(about_us.about_us.last_page);
      var final_stage = about_us.apps.map((data) => {
        return {
          key: data.id,
          value: data.id,
          text: data.app_name,
        };
      });

      setAppOptions(final_stage);
    }
  }, [about_us]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/aboutus?page=" + pageInfo.activePage.toString() + "&q=" + search
    );
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      setApiUrl("/aboutus?q=" + search);
    }
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        title: "",
        title_lang: "",
        description: "",
        description_lang: "",
        app_id: 0,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let aboutus = about_us.about_us.data.find((v) => v.id === id);

    setData({
      newData: {
        id: aboutus!.id,
        title: aboutus!.title,
        title_lang: aboutus!.title_lang,
        description: aboutus!.description,
        description_lang: aboutus!.description_lang,
        app_id: aboutus!.app_id,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////
    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0) dispatch(addAboutUs(fdata.newData, () => {
      dispatch(loadAboutUsPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data added successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));
    else dispatch(editAboutUs(fdata.newData, () => {
      dispatch(loadAboutUsPage(apiUrl, () => { 
        setLoading(false);
        dispatch(alertActions.success("Data Edited successfully"));
      }));
      clearForm();
      setOpenInfo(false);
      
    }, () => {
      setLoading(false);
    }));

  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    
    setLoading(true);

    dispatch(removeAboutUs(deleteId, () => {
      dispatch(loadAboutUsPage(apiUrl, () => {
        dispatch(alertActions.success("Data Deleted successfully"));
        setLoading(false);
      }));
      setOpen(false);
    },
    () => setLoading(false)));  
  };

  function RenderList(datas: any) {
    if (datas.data.about_us === undefined) {
      //confusion
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    if (datas.data.about_us.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    return datas.data.about_us.data.map((data: any) => {
      let app = datas.data.apps.find((a: any) => a.id === data.app_id);
      return (
        <Table.Row key={data.id}>
          <Table.Cell>{data.id}</Table.Cell>
          <Table.Cell>{data.title}</Table.Cell>
          <Table.Cell>{data.title_lang}</Table.Cell>
          <Table.Cell>{data.description}</Table.Cell>
          <Table.Cell>{data.description_lang}</Table.Cell>
          <Table.Cell>{app ? app.app_name : ""}</Table.Cell>

          <Table.Cell>
            <Button
              as={Link}
              to={`/aboutus/${data.id}`}
              icon
              className="formbuilder-buttons"
              circular
              style={{
                backgroundColor: "white",
                color: "var(--color-blue)",
              }}
            >
              <Icon name="info circle" />
            </Button>
            <Button
              onClick={() => {
                selectDataForEditing(data.id);
                setOpenInfo(true);
                setCreating(false);
              }}
              icon
              circular
              className="editButton"
            >
              <Icon name="pencil" />
            </Button>

            <Button
              onClick={() => deleteData(data.id)}
              icon
              circular
              className="deleteButton"
            >
              <Icon name="delete" />
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <div key="1">
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>About Us</Breadcrumb.Section>
      </Breadcrumb>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict={true}
        loading={loading}
      />
      <div className="app-card-org" key="2" style={{ overflowX: "scroll" }}>
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Create About Us
          <Icon name="plus" className="iconSize" />
        </Button>
        <Header>
          About Us
          <Input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={onSearch}
            className="pull-right small mb-2"
            icon="search"
            placeholder="Search..."
          />
        </Header>

        <Table basic="very" unstackable striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Title Lang</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Description Lang</Table.HeaderCell>
              <Table.HeaderCell>App</Table.HeaderCell>

              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{about_us && <RenderList data={about_us} />}</Table.Body>
        </Table>

        {about_us === undefined || loading ? <Loader active inverted size="big" /> : ""}
        <Grid>
          <Grid.Column>
            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>
      ,
      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="small"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />
        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Create" : "Edit"} Abous Us
        </Modal.Header>
        <Modal.Content className="modalContent">
          <Form>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select App"
                required
                options={appOptions}
                label="App"
                value={fdata.newData.app_id}
                name="app_id"
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="title"
                  label="Title"
                  value={fdata.newData.title}
                  onChange={handleChange}
                  placeholder="Enter Title..."
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="title_lang"
                  label="Title Lang"
                  value={fdata.newData.title_lang}
                  onChange={handleChange}
                  placeholder="Enter Title in Your Preferred Language..."
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="description"
                  label="Description"
                  value={fdata.newData.description}
                  onChange={handleChange}
                  placeholder="Enter Desscription..."
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  fluid
                  required
                  type="text"
                  name="description_lang"
                  label="Description Lang"
                  value={fdata.newData.description_lang}
                  onChange={handleChange}
                  placeholder="Enter Description in Your Preferred Language..."
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
