import { alertActions } from "./alertAction";
import { apiService } from "../../services";
export const strengthenHousePartTypes = {
  STRENGTHEN_HOUSE_PART_LOADED: "STRENGTHEN_HOUSE_PART_LOADED",
  STRENGTHEN_HOUSE_PART_UNLOADED: "STRENGTHEN_HOUSE_PART_UNLOADED",
  ADD_STRENGTHEN_HOUSE_PART: "ADD_STRENGTHEN_HOUSE_PART",
  EDIT_STRENGTHEN_HOUSE_PART: "EDIT_STRENGTHEN_HOUSE_PART",
  DELETE_STRENGTHEN_HOUSE_PART: "DELETE_STRENGTHEN_HOUSE_PART",
};

export const loadStrengthenHousePartPage = (url: string, onFinally: Function) => {
  return async (dispatch: any) => {
    apiService.getAll(url).then(
      (data) => {
        dispatch({
          type: strengthenHousePartTypes.STRENGTHEN_HOUSE_PART_LOADED,
          payload: data,
        });
        onFinally();
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        onFinally();
      }
    );
  };
};

export const unloadStrengthenHousePartPage = () => {
  return {
    type: strengthenHousePartTypes.STRENGTHEN_HOUSE_PART_UNLOADED,
    payload: {},
  };
};

export const addStrengthenHousePart = (payload: object, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.save("/strengthen_house_part", payload).then(
      (data) => {
        dispatch({
          type: strengthenHousePartTypes.ADD_STRENGTHEN_HOUSE_PART,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data added successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
interface payloadProps {
  id: number;
  // [all:any]:any
}
export const editStrengthenHousePart = (payload: payloadProps, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService.update(`/strengthen_house_part/update/${payload.id}`, payload).then(
      (data) => {
        dispatch({
          type: strengthenHousePartTypes.EDIT_STRENGTHEN_HOUSE_PART,
          payload: data,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Edited successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};

export const removeStrengthenHousePart = (id: number, onSuccess: Function, onError: Function) => {
  return async (dispatch: any) => {
    apiService._delete(`/strengthen_house_part/delete/${id}`).then(
      (data) => {
        dispatch({
          type: strengthenHousePartTypes.DELETE_STRENGTHEN_HOUSE_PART,
        });
        onSuccess();
        // dispatch(alertActions.success("Data Deleted successfully"));
      },
      (error) => {
        onError();
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
};
