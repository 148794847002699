import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchFormdata,
  unloadFormdataPage,
} from "../../../redux/action/formDataAction";
import {
  Table,
  Segment,
  Breadcrumb,
  Message,
  Button,
  Header,
  Icon,
  Loader,
  Grid,
  Pagination,
  Checkbox,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { apiService } from "../../../services";
import { alertActions } from "../../../redux/action/alertAction";

interface Props {
  match: { params: { slug: any } };
  history: any;
}

export default function Deactivated(props: Props) {
  const [activePage, setActivePage] = useState<number>(1);
  const [selected, setSelected] = useState<string[]>([]);
  const [apiUrl, setApiUrl] = useState<string>(
    `/formBuilder/deactivated/${props.match.params.slug}`
  );
  const [total, setTotal] = useState<number>(1);

  const formdata = useSelector((state: any) => state.formdata);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFormdata(apiUrl));
    return () => {
      dispatch(unloadFormdataPage());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (formdata.data !== undefined) {
      //console.log(formbuilders.last_page)
      setTotal(formdata.data.last_page);
    }
  }, [formdata]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      `/formBuilder/deactivated/${props.match.params.slug}?page=` +
        pageInfo.activePage.toString()
    );
  };

  function RenderHeader(datas: any) {
    //console.log(datas.data)
    if (datas.data.field === undefined) {
      return <Table.HeaderCell> Loading.....</Table.HeaderCell>;
    }
    // console.log(datas.data)
    return datas.data.field.map((data: React.ReactNode, index: number) => {
      return <Table.HeaderCell key={index + 1}> {data}</Table.HeaderCell>;
    });
  }
  const ActivateHandler = () => {
    apiService
      .save(`/formData/activate`, {
        toActivate: selected,
        slug: props.match.params.slug,
      })
      .then(
        (data) => {
          dispatch(alertActions.success(data.message));
          dispatch(fetchFormdata(apiUrl));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  const SelectData = (event: React.FormEvent<HTMLInputElement>, data: any) => {
    const value = data.checked;

    if (value) {
      setSelected((oldArray) => [...oldArray, data.name]);
    } else {
      setSelected((oldArray) => oldArray.filter((item) => item !== data.name));
    }
  };
  const goBack = () => {
    props.history.goBack();
  };
  function RenderList({ data }: any) {
    if (data.data === undefined) {
      return (
        <Table.Row key={1}>
          <Table.Cell>
            {" "}
            <Message>Loading ....</Message>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (data.data.data.length === 0) {
      return (
        <Table.Row key="1">
          <Table.Cell>
            {" "}
            <Message>No data Found</Message>
          </Table.Cell>
        </Table.Row>
      );
    }
    // console.log(datas.data)
    return data.data.data.map((item: any, index: any) => {
      return (
        <Table.Row key={item._id.$oid}>
          <Table.Cell>{item._id.$oid}</Table.Cell>
          <Table.Cell>
            <Checkbox
              name={item._id.$oid}
              checked={selected.includes(item._id.$oid)}
              onChange={SelectData}
            ></Checkbox>
          </Table.Cell>
          <Table.Cell>
            <Button
              size="mini"
              primary
              as={Link}
              to={`/formbuilder/data/${props.match.params.slug}/detail/${item._id.$oid}`}
              icon
            >
              <Icon name="eye" />
            </Button>
          </Table.Cell>
          <Table.Cell>{item.state}</Table.Cell>

          {data.key ? (
            data.key
              .filter((itm: string) => itm !== "_id")
              .map((members: string, index1: string | number | undefined) => {
                return (
                  <Table.Cell key={index1}>
                    {members in item ? item[members] : ""}
                  </Table.Cell>
                );
              })
          ) : (
            <Table.Cell>No data Found</Table.Cell>
          )}
        </Table.Row>
      );
    });
  }

  return [
    <div key={1}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section onClick={goBack}>Go Back</Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />
        <Breadcrumb.Section active>Deactivated Data</Breadcrumb.Section>
      </Breadcrumb>
    </div>,

    <div className="app-card-org" key={2} style={{ overflowX: "scroll" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Header>Deactivated Data List </Header>
        <Button className="createButton" onClick={ActivateHandler}>
          Activate
        </Button>
      </div>

      <div style={{ overflowY: "scroll" }}>
        <Table basic="very" unstackable striped role="grid">
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell> Check</Table.HeaderCell>
              <Table.HeaderCell> Actions</Table.HeaderCell>
              <Table.HeaderCell> State</Table.HeaderCell>

              {formdata && <RenderHeader data={formdata} />}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {formdata.data && <RenderList data={formdata} />}
          </Table.Body>
        </Table>
      </div>
      {formdata.data === undefined ? <Loader active inverted size="big" /> : ""}

      <Grid>
        <Grid.Column>
          <Pagination
            floated="right"
            activePage={activePage}
            onPageChange={onChange}
            totalPages={total}
            ellipsisItem={null}
          />
        </Grid.Column>
      </Grid>
    </div>,
  ];
}
