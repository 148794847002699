import { unitPriceTypes } from "../action/unitPriceAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case unitPriceTypes.UNIT_PRICE_LOADED:
      return action.payload;

    case unitPriceTypes.UNIT_PRICE_UNLOADED:
      return initialState;
    case unitPriceTypes.ADD_UNIT_PRICE:
      return { ...state };
    case unitPriceTypes.EDIT_UNIT_PRICE:
      return state;
    case unitPriceTypes.DELETE_UNIT_PRICE:
      return state;

    default:
      return state;
  }
};
