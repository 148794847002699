import React, { useEffect } from 'react'
import FormForm from './FormForm'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'

import { fetchData, unloadData } from '../../redux/action/allAction';
import { Loader } from 'semantic-ui-react';

interface Props {
    match: {
        params:
        {
            slug: string;
            id: number;
        };
    };
}
export default function FormEdit(props: Props) {
    const form = useSelector((state: any) => state.all_ind);
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchForm(props.match.params.id,props.match.params.slug))
        dispatch(fetchData(`/forms/edit/${props.match.params.id}`))
        return () => {
            dispatch(unloadData())
        }
        // eslint-disable-next-line
    }, [])

    if (_.isEmpty(form)) {
        return (
            <Loader />
        )
    }
    return (
        <FormForm type="Edit" data={form}   />
    )
}
