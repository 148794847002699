import React, { useState, useEffect } from "react";
import {
  Pagination,
  Icon,
  Button,
  Header,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Loader,
  List,
  Divider,
  Image,
  Label,
  Modal,
  DropdownProps,
  Dropdown,
} from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";

import {
  simpleFetch,
  simplePost,
  simpleUnload,
  simpleDelete,
} from "../../../../redux/action/simpleAction";

import { Link, useHistory } from "react-router-dom";
import ModalComponent from "../../../../components/Modal";
import { FormattedMessage } from "react-intl";
import { alertActions } from "../../../../redux/action/alertAction";

interface RootState {
  simpleReducer: {
    finance: {
      id: number;
      name: string;
      description: string;
      project_id: number;
      form_id: string;
      budget: string;
      created_at: string;
      updated_at: string;
      project: {
        id: number;
        name: string;
      };
    };
    tranche: {
      id: number;
      name: string;
      finance_id: string | number;
      amount: string;
      order: number;
      transactions_count: number;
    };
    records: {
      _id: {
        $oid: string;
      };
      status: string;
      updated_at: string;
      name?: string;
      homeowner_name?: string;
      homeowner_id?: string;
    }[];
    transactions: {
      data: {
        id: number;
        amount: number;
        owner_id: string;
        owner_name: string;
        description: string;
        status: string;
        initiated_by: string;
      }[];
      last_page: number;
      per_page: number;
    };
  };
}

type ownerOptionType = {
  key: string;
  value: string;
  text: string;
}[];

export default function TrancheTransaction(props: any) {
  const finance_id = props.match.params.finance_id;
  const tranche_id = props.match.params.tranche_id;

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const [fdata, setData] = useState({
    newData: {
      id: 0,
      amount: 0,
      owner_id: "",
      owner_name: "",
      description: "",
      status: "",
      initiated_by: user?.data?.email,
    },
  });

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [apiUrl, setApiUrl] = useState(
    `/finance/${finance_id}/tranche/${tranche_id}/transaction`
  );
  const [url, setUrl] = useState("");
  const transactions = useSelector((state: RootState) => state.simpleReducer);
  const [total, setTotal] = useState(1);
  const [ownerOptions, setOwnerOptions] = useState<ownerOptionType>([]);
  const [openInfo, setOpenInfo]: any = useState(false);
  const [creating, setCreating]: any = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(
      simpleFetch(
        apiUrl,
        () => setLoading(false),
        () => setLoading(false)
      )
    );

    return () => {
      dispatch(simpleUnload());
    };
    // eslint-disable-next-line
  }, [apiUrl]);

  useEffect(() => {
    if (transactions && transactions.transactions !== undefined) {
      setTotal(transactions.transactions.last_page);
      setPerPage(transactions.transactions.per_page || 5);

      setOwnerOptions(
        transactions.records.map((data) => {
          return {
            key: data._id.$oid,
            value: data._id.$oid,
            text:
              data._id.$oid +
              (data.name
                ? ` (${data.name})`
                : data.homeowner_name
                ? ` (${data.homeowner_name})`
                : ""),
            // text: data._id.$oid,
            content: (
              <Header
                size="tiny"
                content={data.name || data.homeowner_name}
                subheader={
                  <div>
                    <small>ID: {data._id.$oid}</small>
                    <br />
                    {data.homeowner_id ? (
                      <>
                        <small>HOME OWNER ID: {data.homeowner_id}</small>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <small style={{ fontSize: "60%" }}>{data.status}</small>
                  </div>
                }
              />
            ),
          };
        })
      );
    }
  }, [transactions]);

  const onChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setActivePage(pageInfo.activePage);
    //console.log(pageInfo)
    setApiUrl(
      "/finance/" +
        finance_id +
        "/tranche/" +
        tranche_id +
        "/transaction?page=" +
        pageInfo.activePage.toString() +
        "&per_page=" +
        perPage
    );
  };

  const onPerPageChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setPerPage((data.value as number) || 5);

    setApiUrl(
      "/finance/" +
        finance_id +
        "/tranche/" +
        tranche_id +
        "/transaction?page=" +
        activePage +
        "&per_page=" +
        (data.value || 5)
    );
  };

  const clearForm = () => {
    setData({
      newData: {
        id: 0,
        amount: 0,
        owner_id: "",
        owner_name: "",
        description: "",
        status: "",
        initiated_by: user?.data?.email,
      },
    });
  };

  const selectDataForEditing = (id: number) => {
    let transaction = transactions.transactions.data.find((v) => v.id === id);

    if (!transaction) {
      return dispatch(alertActions.error("Transaction not available to edit."));
    }

    setData({
      newData: {
        id: transaction.id,
        amount: transaction.amount,
        owner_id: transaction.owner_id,
        owner_name: transaction.owner_name,
        description: transaction.description,
        status: transaction.status,
        initiated_by: transaction.initiated_by ?? user?.data?.email,
      },
    });
  };

  const handleChange = async (event: any, result: any) => {
    // console.log(result)
    let value = result.type === "checkbox" ? result.checked : result.value;
    //////
    if (result.type === "file") {
      value = event.target.files[0];
    }
    //////

    let name = result.name;

    setData({
      newData: Object.assign({}, fdata.newData, {
        [name]: value,
      }),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (fdata.newData.id === 0)
      dispatch(
        simplePost(
          `/finance/${finance_id}/tranche/${tranche_id}/transaction`,
          fdata.newData,
          () => {
            dispatch(
              simpleFetch(apiUrl, () => {
                setLoading(false);
                dispatch(
                  alertActions.success("Transaction Added successfully")
                );
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    else
      dispatch(
        simplePost(
          `/finance/${finance_id}/tranche/${tranche_id}/transaction/${fdata.newData.id}`,
          fdata.newData,
          () => {
            dispatch(
              simpleFetch(apiUrl, () => {
                setLoading(false);
                dispatch(
                  alertActions.success("Transaction Edited successfully")
                );
              })
            );
            clearForm();
            setOpenInfo(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
  };

  const deleteData = (id: number) => {
    setOpen(true);
    setDeleteId(id);
  };

  const confirmDelete = () => {
    setLoading(true);

    dispatch(
      simpleDelete(
        `/finance/${finance_id}/tranche/${tranche_id}/transaction/${deleteId}`,
        () => {
          dispatch(
            simpleFetch(apiUrl, () => {
              dispatch(
                alertActions.success("Transaction Deleted successfully")
              );
              setLoading(false);
            })
          );
          setOpen(false);
        },
        () => setLoading(false)
      )
    );
  };

  const RenderList = React.useCallback((): any => {
    if (transactions.transactions === undefined) {
      return <Message>Loading ....</Message>;
    }

    if (transactions.transactions.data.length === 0) {
      return <Message>No data Found</Message>;
    }

    return transactions.transactions.data.map((data) => {
      let record = transactions.records.find(
        (record) => record._id.$oid === data.owner_id
      );

      return (
        <List
          divided
          verticalAlign="middle"
          key={data.id}
          className="hover-bg-gray-100"
        >
          <List.Item>
            <List.Content floated="right" style={{ marginTop: "5px" }}>
              <Button
                onClick={() => {
                  selectDataForEditing(data.id);
                  setOpenInfo(true);
                  setCreating(false);
                }}
                icon
                circular
                className="editButton"
              >
                <Icon name="pencil" />
              </Button>

              <Button
                onClick={() => deleteData(data.id)}
                icon
                circular
                className="deleteButton"
              >
                <Icon name="delete" />
              </Button>
            </List.Content>

            <List.Content>
              <List.Header className="text-uppercase">
                <span style={{ color: "var(--color-blue)" }}>
                  <strong>{data.owner_name}</strong>
                  <Breadcrumb.Divider
                    icon="right arrow "
                    style={{
                      color: "green",
                      marginLeft: "7px",
                      marginRight: "7px",
                    }}
                  />
                  <strong>{data.amount}</strong>

                  <Label
                    style={{ marginLeft: "8px", padding: "3px 8px" }}
                    color={
                      data.status === "paid"
                        ? "green"
                        : data.status === "reviewed"
                        ? "blue"
                        : "yellow"
                    }
                  >
                    {data.status.toUpperCase()}
                  </Label>
                </span>
              </List.Header>

              <small>
                <List.Description>
                  <strong>Record Owner:</strong> {record?._id.$oid ?? "N/A"}{" "}
                  {record?.name ? `(${record.name})` : ""}
                </List.Description>

                <List.Description>{data.description}</List.Description>

                <List.Description>
                  <strong>Reviewed By:</strong> {data.initiated_by ?? "N/A"}
                </List.Description>
              </small>
            </List.Content>
          </List.Item>
          <Divider />
        </List>
      );
    });
    // eslint-disable-next-line
  }, [transactions]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          <FormattedMessage id="Home" defaultMessage="Home" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />

        <Breadcrumb.Section link as={Link} to="/finance">
          <FormattedMessage id="Finance" defaultMessage="Finance" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />

        <Breadcrumb.Section
          link
          as={Link}
          to={`/finance/${finance_id}/tranche/`}
        >
          <FormattedMessage id="Tranche" defaultMessage="Tranche" />
        </Breadcrumb.Section>

        <Breadcrumb.Divider icon="right arrow" />

        <Breadcrumb.Section active>Transactions</Breadcrumb.Section>
      </Breadcrumb>

      <ModalComponent
        open={open}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        strict_text="You are removing a Financial Transaction History."
        strict={true}
        loading={loading}
      />

      <div className="app-card-org" key="2">
        <Button
          className="createButton"
          icon
          onClick={() => {
            clearForm();
            setOpenInfo(true);
            setCreating(true);
          }}
          labelPosition="left"
        >
          Add Transaction
          <Icon name="plus" className="iconSize" />
        </Button>

        <Header>Transactions List</Header>

        {!transactions || transactions.transactions === undefined || loading ? (
          <Loader active inverted size="big" />
        ) : (
          <div>
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "80px",
              }}
            >
              <div>
                <p style={{ margin: 0 }}>
                  <strong>Finance Name: </strong> {transactions.finance.name}
                </p>
                <p style={{ margin: 0 }}>
                  <strong>Total Finance Budget: </strong>{" "}
                  {transactions.finance.budget}
                </p>
              </div>
              <div>
                <p style={{ margin: 0 }}>
                  <strong>Tranche Name: </strong> {transactions.tranche.name}
                </p>
                <p style={{ margin: 0 }}>
                  <strong>Tranche Amount: </strong>{" "}
                  {transactions.tranche.amount}
                </p>
              </div>
            </div>

            <Divider />

            <RenderList />
          </div>
        )}

        <Grid>
          <Grid.Column>
            <div style={{ marginRight: "5px", display: "inline-block" }}>
              <p style={{ display: "inline-block" }}>Per Page: </p>
              <Dropdown
                options={[
                  { key: 5, text: "5", value: 5 },
                  { key: 10, text: "10", value: 10 },
                  { key: 15, text: "15", value: 15 },
                  { key: 25, text: "25", value: 25 },
                  { key: 50, text: "50", value: 50 },
                  { key: 100, text: "100", value: 100 },
                ]}
                placeholder="Per Page"
                compact
                selection
                upward
                onChange={onPerPageChange}
                style={{ marginLeft: "5px" }}
                value={perPage}
              />
            </div>

            <Pagination
              floated="right"
              activePage={activePage}
              onPageChange={onChange}
              totalPages={total}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid>
      </div>

      <Modal
        as={Form}
        onSubmit={(e: any) => handleSubmit(e)}
        open={openInfo === false ? false : true}
        onClose={() => setOpenInfo(false)}
        size="small"
        className="modal-customized"
      >
        <Loader active disabled={!loading} inverted size="big" />

        <Modal.Header className="modalHeader">
          {creating ? <Icon name="plus" /> : <Icon name="edit" />} {"  "}
          {creating ? "Add" : "Edit"} Transaction
        </Modal.Header>

        <Modal.Content>
          <Form.Group widths={2}>
            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Owner"
                required
                search
                options={ownerOptions}
                label="Owner ID"
                value={fdata.newData.owner_id}
                name="owner_id"
                onChange={handleChange}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                required
                type="text"
                name="owner_name"
                label="Owner Name"
                value={fdata.newData.owner_name}
                onChange={handleChange}
                placeholder="Enter Owner Name to identify..."
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Field>
              <Form.Input
                fluid
                required
                type="number"
                name="amount"
                label="Amount"
                value={fdata.newData.amount}
                onChange={handleChange}
                placeholder="Enter Amount"
                min={0}
                max={transactions?.tranche?.amount ?? 9999999}
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                fluid
                placeholder="Select Status"
                required
                options={[
                  { key: "pending", value: "pending", text: "Pending" },
                  { key: "reviewed", value: "reviewed", text: "Reviewed" },
                  { key: "paid", value: "paid", text: "Paid" },
                ]}
                label="Status"
                value={fdata.newData.status}
                name="status"
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Form.TextArea
              fluid
              required
              name="description"
              label="Description"
              value={fdata.newData.description}
              onChange={handleChange}
              placeholder="Detail/Remarks about this transaction..."
              max="255"
            />
          </Form.Field>
        </Modal.Content>

        <Modal.Actions className="modalActions">
          <Button
            icon
            type="button"
            onClick={() => clearForm()}
            labelPosition="left"
            className="clearButton"
          >
            Clear
            <Icon name="eraser" className="clearButtonIconSize" />
          </Button>

          <Button
            icon
            type="submit"
            labelPosition="left"
            className="submitButton"
            disabled={loading}
            loading={loading}
          >
            Submit
            <Icon name="checkmark" className="iconSize" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
