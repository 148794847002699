import { strengthenHousePartTypes } from "../action/strengthenHousePartAction";

const initialState: Array<any> = [];
export default (state = initialState, action: any) => {
  switch (action.type) {
    case strengthenHousePartTypes.STRENGTHEN_HOUSE_PART_LOADED:
      return action.payload;

    case strengthenHousePartTypes.STRENGTHEN_HOUSE_PART_UNLOADED:
      return initialState;
    case strengthenHousePartTypes.ADD_STRENGTHEN_HOUSE_PART:
      return { ...state };
    case strengthenHousePartTypes.EDIT_STRENGTHEN_HOUSE_PART:
      return state;
    case strengthenHousePartTypes.DELETE_STRENGTHEN_HOUSE_PART:
      return state;

    default:
      return state;
  }
};
