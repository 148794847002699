import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, Card, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { FormattedMessage } from "react-intl";
import { fetchData, unloadData } from "../../redux/action/allAction";
import { normalizeStepTypeName } from "../../helper";

export default function ProjectDashboard(props: any) {
  const project = useSelector((state: any) => state.all_ind);

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(fetchForm(props.match.params.id,props.match.params.slug))
    dispatch(fetchData(`/project/dashboard/${props.match.params.id}`));
    return () => {
      dispatch(unloadData());
    };
    // eslint-disable-next-line
  }, [props.match.params.id]);

  const Users = ({
    data,
  }: {
    data: { first_name: string; last_name: string }[];
  }): any => {
    if (!data) {
      return "";
    }

    return data.map((item, index) => {
      return (
        <p key={index} style={{ textTransform: "capitalize" }}>
          {item.first_name} {item.last_name}
        </p>
      );
    });
  };

  const Steps = ({
    data,
  }: {
    data: { name: string; id: number; type: string; stage: { name: string } }[];
  }): any => {
    if (!data) {
      return "";
    }

    return data.map((item, index) => {
      let type = normalizeStepTypeName(item.type);

      return (
        // <div key={index} style={{ margin: "10px" }}>
        //   <div>
        //     <h4>{item.name}</h4>
        //   </div>
        //   <div>
        //     <p>{`Stage: ${item.stage && item.stage.name}`}</p>
        //     <p>{type}</p>
        //   </div>
        // </div>

        <Card
          key={index}
          as={Link}
          to={`/step/form/${item.id}`}
          style={{ marginTop: 0, marginBottom: 0, textDecoration: "none" }}
          raised
          link
        >
          <Card.Content
            textAlign="center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "0.9rem",
              padding: "8px",
            }}
          >
            <Card.Header>{item.name}</Card.Header>
          </Card.Content>

          <Card.Content extra raised="true">
            {`Stage: ${item.stage ? item.stage.name : "-"}`}
          </Card.Content>

          <Card.Content extra>{type}</Card.Content>
        </Card>
      );
    });
  };

  return (
    <React.Fragment>
      <div>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section active>
            <FormattedMessage id="Project" defaultMessage="Project" />{" "}
            <FormattedMessage id="Detail" defaultMessage="Detail" />
          </Breadcrumb.Section>{" "}
          ({project && project.project_name})
        </Breadcrumb>
      </div>

      <div className="app-card-org">
        <div className="project-title-formbuilder">
          <h3>
            {project && project.project_name && ` ${project.project_name}`}
          </h3>
          <p>
            {project && project.organization && ` ${project.organization.name}`}
          </p>
        </div>
        <hr className="moduleDivider" />

        <div className="formbuilder-users">
          <div className="project-managers">
            <h4>Project Managers</h4>
            {project.project_managers && (
              <Users data={project.project_managers} />
            )}
          </div>
          <div className="project-users">
            <h4>Users</h4>
            {project.users && <Users data={project.users} />}
          </div>
        </div>
        {project.project_name === undefined ? (
          <Loader active inverted size="massive" />
        ) : (
          ""
        )}
      </div>

      <div className="app-card-org">
        <div className="demo-project-list">
          {project.steps && <Steps data={project.steps} />}
        </div>
      </div>
    </React.Fragment>
  );
}
